/* eslint-disable no-unreachable */
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import * as Monaco from '@monaco-editor/react';
import { initMonaco } from '../components/CodeEditor/CodeEditorHelper';
import { listVariablesRequest } from '../store/variables/actions';
import { createToastRequest } from '../store/toasts/actions';
//  const variables ={data:  [{id: 1, name: 'asd'}, {id: 2, name: 'qwe'}]};

export const useVariables = () => {
  const { variables, activeProject } = useSelector(({ variables, activeProject }) => ({
    variables,
    activeProject,
  }));
  const dispatch = useDispatch();
  useEffect(() => {
    if (variables.data) return;
    dispatch(
      listVariablesRequest(
        {
          id: activeProject.id,
        },
        () => {},
      ),
    );
  }, []);

  return { variables };
};

export const useMonaco = (params) => {
  if (params && typeof params === 'object' && params.hideVariables) {
    initMonaco([]);
    return;
  }

  const { variables } = useVariables();
  useEffect(() => {
    if (!variables.data) return;
    initMonaco(variables.data);
  }, [variables]);
};

export const useMonacoErrors = () => {
  const monaco = Monaco.useMonaco();
  const [errors, setErrors] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (monaco) {
      monaco.editor.onDidChangeMarkers(([uri]) => {
        const markers = monaco.editor.getModelMarkers({ resource: uri });
        setErrors(markers);
      });
    }
  }, [monaco]);

  const showMonacoErrors = () => {
    // temporary disable showing errors
    return false;

    if (errors.length > 0) {
      dispatch(
        createToastRequest({
          type: 'error',
          text: errors
            .map(
              ({ message, startLineNumber, startColumn, endLineNumber, endColumn }) =>
                `${message} [${startLineNumber}:${startColumn}-${endLineNumber}:${endColumn}]`,
            )
            .join('\n'),
        }),
      );
      return true;
    }
    return false;
  };
  return { monacoErrors: errors, showMonacoErrors };
};

/* eslint-disable import/prefer-default-export */
export const ActiveAuthKey = {
  activeAuthKeyRequest: 'ACTIVE_AUTH_KEY_PENDING',
  activeAuthKeySuccess: 'ACTIVE_AUTH_KEY_SUCCESS',
  activeAuthKeyError: 'ACTIVE_AUTH_KEY_ERROR',
  
  getAccessUsersAuthKeySuccess: 'GET_ACCESS_USERS_AUTH_KEY_SUCCESS',

  getConnectedEndpointsToAuthKeysRequest: 'GET_CONNECTED_ENDPOINTS_TO_AUTH_KEYS_REQUEST',
  getConnectedEndpointsToAuthKeysSuccess: 'GET_CONNECTED_ENDPOINTS_TO_AUTH_KEYS_SUCCESS',
};

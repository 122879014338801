/* eslint-disable max-len */
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import cn from 'classnames';
import ActionIcon from '../../components/ActionIcon/ActionIcon';
import ActionText from '../../components/ActionText/ActionText';
import RemoveModal from '../../components/RemoveProjectsModal';
import { createToastRequest } from '../../store/toasts/actions';
import InviteToResourceModal from '../../components/InviteToResourceModal/InviteToResourceModal';
import Directory from './atoms/Directory/Directory';
import Loader from '../../components/Loader';
import Button from '../../components/Button/Button';
import Input from '../../components/Input/Input';
import { useCopyToClipboard } from '../../hooks/hooks';
import Checkbox from '../../components/Checkbox/Checkbox';
import { searchProjectsRequest } from '../../store/searchProjects/actions';
import { invitesRequest, addAccessRequest } from '../../store/invites/actions';
import { resetProjectData } from '../../store/activeProject/actions';
import {
  checker
} from '../../helpers/utils';
import { isRoleAction } from '../../helpers/hocs';

import SortIcon from '../../pictures/sort.svg';

import 'react-tooltip/dist/react-tooltip.css';
import * as shared from '../../styles/shared.module.scss';
import { projectsHeightPixels, folderHeightPixels, itemsWidthPixels } from '../../components/ContextMenu';

const headers = {
  // checkbox: { title: '' },
  name: { title: 'Name' },
  shortName: { title: 'short-name' },
  owner: { title: 'Owner' },
  status: { title: 'Status' },
  action: { title: 'Created' },
};

let searchTimeout = null;

function ProjectList({
  getProjects,
  activeProject,
  isRoleAction,
  addAccess,
  resetProject,
  createToast,
  searchProjects: projectsData = []
}) {
  const [loading, setLoading] = useState(true);
  const [activeModel, setActiveModel] = useState({});
  const [inputVisible, setInputVisible] = useState(false);

  const [copiedText, copyText] = useCopyToClipboard();

  const [searchValue, setSearchValue] = useState('');
  const [seachActive, setSearchActive] = useState(false);
  const [removeModalOpened, setRemoveModalOpened] = useState(false);
  const [shareModalOpened, setShareModalOpened] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const ref = useRef(null);
  const tableRef = useRef(null);
  const { shortName } = useParams();
  const navigate = useNavigate();

  const [contextMenuModelActive, setContextMenuModelActive] = useState(false);
  const [contextMenuTop, setContextMenuTop] = useState(0);
  const [contextMenuLeft, setContextMenuLeft] = useState(0);

  const handleContextMenu = (event, id, isFolder) => {
    event.preventDefault();

    setContextMenuModelActive(id);

    const { clientX, clientY } = event;

    const neededHeight = isFolder ? folderHeightPixels : projectsHeightPixels;
    const neededWidth = itemsWidthPixels;

    const w = window.innerWidth;
    const h = window.innerHeight;

    const isHeightFit = (h - clientY) > neededHeight;
    const isWidthFit = (w - clientX) > neededWidth;

    setContextMenuTop(isHeightFit ? clientY : clientY - neededHeight);
    setContextMenuLeft(isWidthFit ? clientX : clientX - neededWidth);
  };

  useEffect(() => {
    setLoading(true);
    if (activeProject?.id) {
      setSelectedItems([]);

      getProjects(null, () => {
        setLoading(false);
      });
    }
  }, [activeProject?.id, shortName]);

  const createProject = () => navigate(`/${shortName}/projects/create`, { state: { secure: true } });
  const modifyProject = (projectId) => navigate(`/${shortName}/projects/${projectId}`, { state: { secure: true } });
  const openProject = (navigateShortname) => {
    if (shortName !== navigateShortname) return navigate(`/${navigateShortname}/endpoints`);
    return null;
  };

  const changeSearch = (e) => {
    setSearchValue(e.target.value);
    if (searchTimeout) clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => {
      setLoading(true);
      setSelectedItems([]);
      if (e.target.value) {
        getProjects({
          query: e.target.value,
        },() => {
          setLoading(false);
          setSearchActive(true);
        });
      } else {
        getProjects(null, () => {
          setLoading(false);
          setSearchActive(false);
        });
      }
    }, 400);
  };

  const clear = () => {
    setSearchValue('');
    setLoading(true);
    setSelectedItems([]);
    getProjects(null,() => {
      setLoading(false);
      setSearchActive(false);
    });
    setInputVisible(false);
  };

  const removeOne = (type, model) => {
    setActiveModel({
      projects: type === 'project' ? [model.id] : [],
    });
    setRemoveModalOpened(true);
  };

  const removeSelected = () => {
    setActiveModel({
      projects: selectedItems,
    });
    setRemoveModalOpened(true);
  };

  const clickShare = (model) => {
    setActiveModel(model);
    setShareModalOpened(true);
  };

  const handleClickOutside = (event) => {
    if (ref && ref.current && !ref.current.contains(event.target)) {
      const input = ref.current.getElementsByTagName('input')[0];
      if (input.value) return;
      setInputVisible(false);
    }

    if (tableRef && tableRef.current && !tableRef.current.contains(event.target)) {
      setContextMenuModelActive(false);
    }
  };

  const sendInviteAction = (data) => {
    // sendInvite(data, () => {
    //   setShareModalOpened(false);
    // });
  };

  const selectAll = (isChecked) => {
    if (isChecked) {
      const allIdsAuthKeys = projectsData?.map(el => el.id);
      setSelectedItems(allIdsAuthKeys);
    } else {
      setSelectedItems([]);
    }
  };

  const selectOne = (isChecked, id) => {
    if (isChecked) {
      setSelectedItems([...selectedItems, id]);
    } else {
      setSelectedItems(selectedItems.filter((c) => c !== id));
    }
  };

  const copyShortname = (name) => {
    copyText(name);
    createToast({ type: 'success', text: 'Copied' });
  };


  const selectItems = (folders, items) => {
    setSelectedItems([...selectedItems, ...items]);
  };

  const renderHeader = (h) => {
    if (h === 'action' && (selectedItems.length > 0)) {
      return (
        <th key={headers[h].title} style={{ justifyContent: 'flex-end' }}>
          <ActionIcon
            id="deleteSelected"
            icon="trash"
            onClick={removeSelected}
            tooltip={{
              content: 'Delete',
            }}
          />
        </th>
      );
    } if (h !== 'checkbox') {
      return (
        <th key={headers[h].title}>
          <span>{headers[h].title}</span>
          {' '}
          <SortIcon />
        </th>
      );
    }
    return null;
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return (
    <div className={shared.page}>
      <div className={shared.header}>
        <div className={shared.headerTitleGroup}>
          <h1 className={shared.headerTitle}>Projects List</h1>
          <p className={shared.headerDescription}>Manage projects</p>
        </div>
        <div className={shared.headerButtonGroup}>
           {inputVisible ? (
            <div style={{
                flexGrow: 1,
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <div ref={ref} style={{ width: '100%', maxWidth: '426px'}}>
              <Input
                placeholder="Search"
                iconLeft="search"
                autoFocus
                iconRight={searchValue && 'close'}
                handleChange={changeSearch}
                value={searchValue}
                handleAction={clear}
              />
              </div>
            </div>
          ) : (
            <Button type="secondary" iconLeft="search" onClick={() => setInputVisible(true)} />
          )}
          {isRoleAction({ key: 'project', role: 'create' })
            && <Button title="Create project" onClick={createProject} />}
        </div>
      </div>
      <div className={shared.table}>
        <table className={shared.connectionTable}>
          <thead>
            <tr className={shared.projectsTableRowKeys}>
              {/* <th key="checkbox">
                <Checkbox
                  handleChange={selectAll}
                  size="small"
                  value={
                    (projectsData?.length)
                    && checker(selectedItems, projectsData)
                  }
                  style={{ marginRight: '0' }}
                />
              </th> */}
              {Object.keys(headers).map((h, i, arr) => renderHeader(h, i, arr))}
            </tr>
          </thead>
          {(projectsData?.length) && !loading ? (
            <tbody
              ref={tableRef}
              onClick={() => setContextMenuModelActive(false)}
              className={cn({ [shared.hideScroll]: contextMenuModelActive })}
            >
              <Directory
                modifyProject={modifyProject}
                activeProject={activeProject}
                projectsData={projectsData}
                selectedItems={selectedItems}
                handleContextMenu={handleContextMenu}
                setContextMenuModelActive={setContextMenuModelActive}
                selectItems={selectItems}
                contextMenuModelActive={contextMenuModelActive}
                contextMenuTop={contextMenuTop}
                contextMenuLeft={contextMenuLeft}
                openProject={openProject}
                selectOne={selectOne}
                clickShare={clickShare}
                isRoleAction={isRoleAction}
                removeOne={removeOne}
                getFolder={getProjects}
                searchActive={seachActive}
                copyShortname={copyShortname}
              />
            </tbody>
          ) : null}
        </table>
        {loading && (
        <div className={shared.emptyContent}>
          <Loader size="large" />
        </div>
        )}
        {(!projectsData?.length) && searchValue && !loading && (
          <div className={shared.emptyContent}>
            <h3 className={shared.caption}>No projects found</h3>
            <p>
              Please change your search query and try again
            </p>
          </div>
        )}
        {(!projectsData?.length) && !searchValue && !loading && (
          <div className={shared.emptyContent}>
              <h3 className={shared.caption}>This project has no Projects yet</h3>
                <p>
                   Please create your Project to continue.
                </p>
                <div className={shared.action}>
                  <ActionText
                    title="Click here to create Project"
                    onClick={createProject}
                  />
                </div>
          </div>
        )}
        {removeModalOpened && (
          <RemoveModal
            onClose={() => setRemoveModalOpened(false)}
            onSubmit={() => {
              setRemoveModalOpened(false);
              setSelectedItems([]);
            }}
            model={activeModel}
            collection={projectsData}
            activeProject={activeProject}
          />
        )}
        {shareModalOpened && (
          <InviteToResourceModal
            id={activeModel.id}
            onClose={() => setShareModalOpened(false)}
            onSubmit={sendInviteAction}
            model={activeModel}
            type="project"
            inviteList={[]}
          />
        )}
      </div>
    </div>
  );
}

const mapStateToProps = ({
  activeProject, auth: { currentUser }, roles, searchProjects
}) => ({
  searchProjects,
  activeProject,
  roles,
  currentUser
});

const mapDispatchToProps = (dispatch) => ({
  addAccess: (data, callback) => dispatch(addAccessRequest(data, callback)),
  getProjects: (data, callback) => dispatch(searchProjectsRequest(data, callback)),
  sendInvite: (data, callback) => dispatch(invitesRequest(data, callback)),
  resetProject: () => dispatch(resetProjectData()),
  createToast: (data) => dispatch(createToastRequest(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(isRoleAction(ProjectList));

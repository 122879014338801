@use "sass:map";
@import '../../styles/variables.scss';

.actionText {
    border: none;
    background-color: inherit;
    padding: 0;
    margin: 0;
    display: inline-block;
    cursor: pointer;
    color: map.get($colors-basic, "primaryBlue");
    font-size: map.get($font-sizes, "body");

    &[disabled] {
        cursor: initial;
        color: map.get($colors-basic, "textDisabled");
    }

    &.typeBlack {
        color: map.get($colors-basic, "textBlack");
        font-weight: map.get($font-weights, "semibold");
    
        &:hover {
            color: map.get($colors-basic, "primaryBlue");
        }
    }
    &.typeBold {
        font-weight: map.get($font-weights, "semibold");
    }
}

export const ViewModes = {
  Inline: 'inline',
  Multiline: 'multiline',
};

export const InlineClasses = {
  [ViewModes.Inline]: 'inline',
  [ViewModes.Multiline]: '',
};

export const codeEditorOptions = {
  renderLineHighlight: 'none',
  showFoldingControls: 'never',
  lineNumbers: 'off',
  glyphMargin: false,
  folding: false,
  lineDecorationsWidth: 5,
  lineNumbersMinChars: 0,
  overviewRulerLanes: 0,
  dragAndDrop: true,
  dropIntoEditor: {
    enabled: true,
    showDropSelector: 'never',
  },
  contextmenu: false,
  wordWrap: 'on',
  minimap: {
    enabled: false,
  },
  scrollbar: {
    alwaysConsumeMouseWheel: false,
    vertical: 'auto',
    verticalScrollbarSize: 6,
    horizontalSliderSize: 6,
  },
  quickSuggestions: false,
  fixedOverflowWidgets: true,
  bracketPairColorization: {
    enabled: true,
  },
  suggest: {
    showFiles: false,
    showVariables: false,
    showFunctions: false,
    showModules: false,
    showProperties: false,
    showValues: false,
    maxVisibleSuggestions: 5 // Set the maximum number of suggestions to 5
  },
  wordBasedSuggestions: false,
  matchBrackets: "never"
};

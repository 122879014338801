import { all, fork } from 'redux-saga/effects';
import projectsSaga from './projects/saga';
import searchProjectsSaga from './searchProjects/saga';
import activeSearchProjectSaga from './activeSearchProject/saga';
import endpointsSaga from './endpoints/saga';
import historySaga from './history/saga';
import connectionsSaga from './connections/saga';
import authKeysSaga from './authKeys/saga';
import activeAuthKey from './activeAuthKey/saga';
import usersSaga from './users/saga';
import rolesSaga from './roles/saga';
import authSaga from './auth/saga';
import toastsSaga from './toasts/saga';
import trashSaga from './trash/saga';
import activeConnectionSaga from './activeConnection/saga';
import activeEndpointSaga from './activeEndpoint/saga';
import activeUserSaga from './activeUser/saga';
import activeProjectSaga from './activeProject/saga';
import invitesSaga from './invites/saga';
import directorySaga from './directory/saga';
import errorsSaga from './errors/saga';
import databaseSchemeSaga from './databaseScheme/saga';
import variablesSchemeSaga from './variables/saga';
import activeVariableSaga from './activeVariable/saga';

// eslint-disable-next-line func-names
const rootSaga = function* () {
  yield all([
    fork(projectsSaga),
    fork(searchProjectsSaga),
    fork(activeSearchProjectSaga),
    fork(endpointsSaga),
    fork(connectionsSaga),
    fork(authKeysSaga),
    fork(usersSaga),
    fork(rolesSaga),
    fork(authSaga),
    fork(toastsSaga),
    fork(activeAuthKey),
    fork(activeConnectionSaga),
    fork(activeEndpointSaga),
    fork(activeUserSaga),
    fork(activeProjectSaga),
    fork(activeVariableSaga),
    fork(invitesSaga),
    fork(directorySaga),
    fork(historySaga),
    fork(trashSaga),
    fork(errorsSaga),
    fork(databaseSchemeSaga),
    fork(variablesSchemeSaga),
  ]);
};

export default rootSaga;

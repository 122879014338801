/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import Loader from '../Loader';
import Search from '../../pictures/search.svg';
import Folder from '../../pictures/create-folder.svg';
import Export from '../../pictures/export.svg';
import Import from '../../pictures/import.svg';
import Checked from '../../pictures/icon-checked.svg';
import Alert from '../../pictures/icon-alert.svg';
import * as styles from './Button.module.scss';

const iconEnum = {
  search: Search,
  export: Export,
  import: Import,
  folder: Folder,
  checked: Checked,
  alert: Alert
};

const sizeEnum = {
  M: 'M',
  L: 'L',
};

const typeEnum = {
  primary: 'primary',
  secondary: 'secondary',
  red: 'red',
};

function Button({
  id,
  onClick,
  title,
  iconRight,
  iconLeft,
  type,
  size,
  style = {},
  disabled,
  tooltip = null,
  loading = false,
  color
}) {
  const RightIcon = iconRight && iconEnum[iconRight];
  const LeftIcon = iconLeft && iconEnum[iconLeft];
  return (
    <button
      id={id}
      className={cn(
        styles.button,
        { [styles.secondary]: type === typeEnum.secondary },
        { [styles.red]: type === typeEnum.red },
        { [styles.short]: !title },
        { [styles.M]: size === sizeEnum.M },
        { [styles.gap]: loading },
        { [styles.hiddenFill]: iconLeft === 'export' || iconLeft === 'import' },
        { [styles.colorRed]: color === 'red' },
        { [styles.colorGreen]: color === 'green' },
      )}
      style={style}
      onClick={onClick}
      type="button"
      disabled={!!disabled}
    >
      {iconLeft && (
      <LeftIcon />
      )}
      <span>{title}</span>
      {loading && (
      <Loader disabled />
      )}
      {iconRight && (
      <RightIcon />
      )}
      {tooltip && !disabled && (
      <ReactTooltip
        anchorId={id}
        place="bottom"
        variant="info"
        style={{ backgroundColor: '#4149f7', color: '#fff', maxWidth: '350px' }}
        {...tooltip}
      />
      )}
    </button>
  );
}

Button.propTypes = {
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string,
  iconRight: PropTypes.string,
  iconLeft: PropTypes.string,
  type: PropTypes.string,
  size: PropTypes.string,
  style: PropTypes.object,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  color: PropTypes.string
};

export default Button;

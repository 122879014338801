import { ActiveProject } from './constants';

const initialState = {};

export const activeProjectReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case ActiveProject.activeProjectSuccess:
      return action.data;
    case ActiveProject.activeProjectReset:
      return initialState;
    default:
      return state;
  }
};

import { Variables } from './constants';
import {
  injectChunk, removeObj,
  createObj,
} from '../../helpers/utils';
import { ActiveProject } from '../activeProject/constants';

const initialState = {};

export const variablesReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case Variables.listVariablesSuccess:
      return { ...state, data: action.data };
    case Variables.searchVariablesSuccess:
      return { ...state, data: action.data?.variables };
    case Variables.getVariableFolderContentSuccess:
      return action.data?.merge ? injectChunk(state, action.data) : {
        ...state,
        data: action.data?.data,
        foldersData: action.data?.folders,
        breadscrumbData: action.data?.breadscrumbs,
      };
    case Variables.createVariableSuccess: return state.data ? createObj(state, action.data) : {
      ...state, data: action.data,
    };
    case Variables.removeVariablesListSuccess: return removeObj(state, action.data);
    case Variables.updateVariableSuccess:
      return state.data ? {
        ...state,
        data: [...state.data.map(
          (content) => (action.data.id === content.id ? { ...content, ...action.data }
            : content),
        )],
      } : state;
    case ActiveProject.activeProjectReset:
      return initialState;
    default:
      return state;
  }
};

/* eslint-disable no-extra-boolean-cast */
/* eslint-disable max-len */
import React, { useEffect, useState, memo } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import debounce from 'lodash.debounce';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import cn from 'classnames';
import ActionText from '../../components/ActionText/ActionText';
import ActionIcon from '../../components/ActionIcon/ActionIcon';
import ValidationErrorList from '../../components/ValidationErrorList/ValidationErrorList';
import { useCopyToClipboard } from '../../hooks/hooks';
import CodeEditor from '../../components/CodeEditor';
import Button from '../../components/Button/Button';
import ChipsStatus from '../../components/ChipsStatus/ChipsStatus';
import Input from '../../components/Input/Input';
import Select from '../../components/Select/Select';
import RemoveAccessModal from '../../components/RemoveAccessModal/RemoveAccessModal';
import ConnectedAPI from '../../components/ConnectedAPI';
import InviteToResourceModal from '../../components/InviteToResourceModal/InviteToResourceModal';
import { getProjectRoles, calcPlaceholder, stringifyIfJSON,
  limitsInit, parseIfJSON, formatNumber } from '../../helpers/utils';
import {
  loadStatus, listRolesName,
} from '../../helpers/const';
import { isRoleAction } from '../../helpers/hocs';
import { updateAuthKeyRequest } from '../../store/authKeys/actions';
import { removeTrashItemsRequest, restoreTrashItemsRequest } from '../../store/trash/actions';
import { activeAuthKeyRequest, getConnectedEndpointsToAuthKeysRequest } from '../../store/activeAuthKey/actions';
import { getAccessUsersRequest } from '../../store/users/actions';
import { addAccessRequest } from '../../store/invites/actions';
import { createToastRequest } from '../../store/toasts/actions';
import Checkbox from '../../components/Checkbox/Checkbox';

import * as shared from '../../styles/shared.module.scss';
import { useMonaco, useMonacoErrors } from '../../hooks/useMonaco';

const MemoizedCodeEditor = memo((props) => 
  // Your component logic here
   (
    <CodeEditor
      value={props.value}
      handleChange={props.handleChange}
      handleBlurCallback={props.handleBlurCallback}
      name={props.name}
      id={props.id}
      type={props.type}
      placeholder={props.placeholder}
      error={props.error}
      style={props.style}
      height={props.height}
      withVariables={props.withVariables}
    />
  )
);

const maxAllowedLength = 64;

const addToParam =
  [
    { value: 'header', title: 'Header' },
    { value: 'query', title: 'Query parameters' },
  ];

const SUBROUTING = {
  ACCESS: 'access',
  LIMITS: 'limits',
};

const subrouteMapping = {};

const AuthSchema = ({ connectLimit }) => Yup.object().shape({
  name: Yup.string().min(2, 'Name length should be at least 2 characters').max(64).matches(/^(?!\s)(?!.* {2})/)
    .required('Name required'),
  authorizationMethod: Yup.string().required('AuthMethod required'),
  RPS: Yup.number()
  .test('rps-connect-limit', `Max. system RPS limit: ${formatNumber(connectLimit)}`, (value) => {
    if (value > connectLimit) {
      return false;
    }
    return true;
  }),
RPMONTH: Yup.number()
  .when('activeRpmType', {
    is: 'mon',
    then: Yup.number()
      .test('rps-value-limit-month', function (value) {
        const { RPS } = this.parent;
        const calculatedLimit = RPS * 60 * 60 * 24 * 30;
        if (value > calculatedLimit) {
          return this.createError({ message: `Max. RPM are: ${formatNumber(calculatedLimit)}` });
        }
        return true;
      })
      .test('rps-connect-limit-month', `Max. system RPM are: ${formatNumber(connectLimit * 60 * 60 * 24 * 30)}`, (value) => {
        if (value > (connectLimit * 60 * 60 * 24 * 30)) {
          return false;
        } 
        return true;
      })
      ,
  }),
RPMONTHCALENDAR: Yup.number()
  .when('activeRpmType', {
    is: 'cmon',
    then: Yup.number()
      .test('rps-value-limit-month-calendar', function (value) {
        const { RPS } = this.parent;
        const calculatedLimit = RPS * 60 * 60 * 24 * 30;
        if (value > calculatedLimit) {
          return this.createError({ message: `Max. RPM are: ${formatNumber(calculatedLimit)}` });
        }
        return true;
      })
      .test('rps-connect-limit-month-calendar', `Max. system RPM are: ${formatNumber(connectLimit * 60 * 60 * 24 * 30)}`, (value) => {
        if (value > (connectLimit * 60 * 60 * 24 * 30)) {
          return false;
        } 
        return true;
      })
      ,
  }),
  authBasicLogin: Yup.string().when(['authorizationMethod'], {
    is: (authorizationMethod) => authorizationMethod === 'basic',
    then: Yup.string().required('Login required'),
  }),
  authBasicPassword: Yup.string().when(['authorizationMethod'], {
    is: (authorizationMethod) => authorizationMethod === 'basic',
    then: Yup.string().required('Password required'),
  }),
  authAPIKeyHeaderName: Yup.string().when(['authorizationMethod'], {
    is: (authorizationMethod) => authorizationMethod === 'APIKey',
    then: Yup.string().required('HeaderName required'),
  }),
  authAPIKeyToken: Yup.string().when(['authorizationMethod'], {
    is: (authorizationMethod) => authorizationMethod === 'APIKey',
    then: Yup.string().required('Token required'),
  }),
  authAPIKeyAddTo: Yup.string().when(['authorizationMethod'], {
    is: (authorizationMethod) => authorizationMethod === 'APIKey',
    then: Yup.string().required('Add to required'),
  }),
  authBearerToken: Yup.string().when(['authorizationMethod'], {
    is: (authorizationMethod) => authorizationMethod === 'bearer',
    then: Yup.string().required('Token required'),
  }),
  authJwtClientAddTo: Yup.string().when(['authorizationMethod'], {
    is: (authorizationMethod) => authorizationMethod === 'JWT',
    then: Yup.string().required('Add to required'),
  }),
  authJwtClientAlgorithm: Yup.string().when(['authorizationMethod'], {
    is: (authorizationMethod) => authorizationMethod === 'JWT',
    then: Yup.string().required('Algorithm required'),
  }),
  authJwtClientPrivateKey: Yup.string().when(['authorizationMethod'], {
    is: (authorizationMethod) => authorizationMethod === 'JWT',
    then: Yup.string().required('Private Key required'),
  }),
  authJwtClientPayload: Yup.string().when(['authorizationMethod'], {
    is: (authorizationMethod) => authorizationMethod === 'JWT',
    then: Yup.string().required('Payload required'),
  }),
});

function AuthKeyModify({
  connectedEndpoints,
  getConnectedEndpoints,
  updateAuthKey,
  getActiveAuthKey,
  activeAuthKey,
  currentUser,
  createToast,
  activeProject,
  getAccessUsers,
  addAccess,
  roles,
  isRoleAction,
  storedInviteList,
  JWTAlgorithm,
  connectLimit
}) {
  const {showMonacoErrors} = useMonacoErrors();
  useMonaco();
  const navigate = useNavigate();
  const [testStatus, setTestStatus] = useState(loadStatus.INITIAL);
  const [subroute, setSubroute] = useState(SUBROUTING.LIMITS);
  const [placeholders, setPlaceholders] = useState(limitsInit);
  const { shortName, resourceId } = useParams();
  const { state } = useLocation();
  const { redirectFolder } = state || {};
  const [shareModalOpened, setShareModalOpened] = useState(false);
  const [inviteList, setInviteList] = useState([]);
  const [removeModalOpened, setRemoveModalOpened] = useState(false);
  const [activeUser, setActiveUser] = useState({});
  const [, copyText] = useCopyToClipboard();
  const [isChange, setIsChange] = useState(false);
  const isDeleted = Boolean(activeAuthKey?.deleted);
  const viewRead = !isRoleAction({ key: 'key', role: 'update', modelRole: activeAuthKey?.role }) || isDeleted;
  const dispatch = useDispatch();
  const algorithms = JWTAlgorithm ? JWTAlgorithm.map(v => ({ value: v, title: v })) : [];
  const [submitTriggered, setSubmitTriggered] = useState(false);

  useEffect(() => {
    getAccessUsers({
      id: +resourceId,
      resource: 'key',
    });
  }, []);

  useEffect(() => {
      setInviteList(storedInviteList || []);
  }, [storedInviteList]);


  const {
    submitForm, handleChange: handleChange2, setFieldValue: setFieldValue2, 
    values, errors, validateForm, setValues
  } = useFormik({
    initialValues: {
      authorizationMethod: '',
      authJwtClientKeyBase64Encoded: false,
      name: '',
      description: '',
      RPS: '',
      RPM: '',
      RPH: '',
      RPD: '',
      RPMONTH: '',
      RPMONTHCALENDAR: '',
      activeRpmType: 'cmon',
    },
    validationSchema: AuthSchema({ connectLimit}),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (v, { setFieldValue }) => {
      const req = {
        name: v.name,
        description: v.description,
        method: v.authorizationMethod,
        projectId: +activeProject.id,
        folderId: activeAuthKey?.folderId || 0,
        id: activeAuthKey?.id,
        data: {},
        limit: {
          sec: +v.RPS || 0,
          mon: v.activeRpmType === 'mon' ? (+v.RPMONTH || 0) : 0,
          cmon: v.activeRpmType === 'cmon' ? (+v.RPMONTHCALENDAR || 0) : 0,
        },
      };

        if (v.authorizationMethod === 'basic') {
          req.data = {
            login: v.authBasicLogin,
            password: v.authBasicPassword,
          }
        }

        if (v.authorizationMethod === 'APIKey') {
          req.data = {
            headerName: v.authAPIKeyHeaderName,
            token: v.authAPIKeyToken,
            addTo: v.authAPIKeyAddTo,
          }
        }

        if (v.authorizationMethod === 'bearer') {
          req.data = {
            token: v.authBearerToken,
          }
        }

        if (v.authorizationMethod === 'JWT') {
          req.data = {
            addTo: v.authJwtClientAddTo,
            algorithm: v.authJwtClientAlgorithm,
            key: v.authJwtClientPrivateKey,
            isBase64Key: v.authJwtClientKeyBase64Encoded,
            payload: v.authJwtClientPayload,
            headerPrefix: v.authJwtClientHeaderPrefix,
            headers: parseIfJSON(v.authJwtClientHeaders)
          }
      }

      updateAuthKey(req, () => {
        setIsChange(false);
        if (!(+v.RPS)) setFieldValue('RPS', '');
      });
    },
  });

  const debouncedValidateForm = debounce(() => {
    validateForm();
  }, 100);

  const setFieldValue = (...args) => {
    setFieldValue2(...args);
    if (submitTriggered) {
      debouncedValidateForm();
    }
  }

  const handleChange = (...args) => {
    handleChange2(...args);
    if (submitTriggered) {
      debouncedValidateForm();
    }
  }

  const handleSubmitWithCallback = (e) => {
    if(showMonacoErrors()) return
    e.preventDefault();
    setSubmitTriggered(true);
    submitForm(e);
  };

  useEffect(() => {
    getActiveAuthKey({ id: resourceId });
  }, [resourceId]);


  useEffect(() => {
    if (activeAuthKey && activeAuthKey.deleted === false) {
      getConnectedEndpoints( {id: resourceId });
    }
  }, [activeAuthKey]);

  useEffect(() => {
    if (activeAuthKey) {
      const {
        name,
        description,
        method,
        limit = {
          sec: 0, min: 0, hour: 0, day: 0, mon: 0,
        },
        data
      } = activeAuthKey || {};

      const baseParam = {
        RPS: limit.sec || '',
        RPMONTH: limit.mon || '',
        RPMONTHCALENDAR: limit.cmon || '',
        name,
        description,
        activeRpmType: limit.mon ? 'mon' : 'cmon',
      }

      setValues({
        ...baseParam,
        authorizationMethod: method,
        authBasicLogin: data.login,
        authBasicPassword: data.password,
        authAPIKeyHeaderName: data.headerName,
        authAPIKeyToken: data.token,
        authAPIKeyAddTo: data.addTo,
        authBearerToken: data.token,
        authJwtClientAddTo: data.addTo,
        authJwtClientAlgorithm: data.algorithm,
        authJwtClientPrivateKey: data.key,
        authJwtClientKeyBase64Encoded: data.isBase64Key,
        authJwtClientPayload: data.payload,
        authJwtClientHeaderPrefix: data.headerPrefix,
        authJwtClientHeaders: stringifyIfJSON(data.headers)
      });

      setPlaceholders(calcPlaceholder(baseParam));
    }
  }, [activeAuthKey]);

  const redirectToList = (redFolder, parentFolder) => {
    const isNumber = typeof redFolder === 'number';
    if (isNumber) {
      if (redFolder) navigate(`/${shortName}/auth-keys/folder/${redFolder}`);
      if (!redFolder) navigate(`/${shortName}/auth-keys`);
    } else {
      if (parentFolder) navigate(`/${shortName}/auth-keys/folder/${parentFolder}`);
      if (!parentFolder) navigate(`/${shortName}/auth-keys`);
    }
  };

  const redirectToTrash = (redFolder, parentFolder) => {
    const isNumber = typeof redFolder === 'number';
    if (isNumber) {
      if (redFolder) navigate(`/${shortName}/trash/auth-keys/folder/${redFolder}`);
      if (!redFolder) navigate(`/${shortName}/trash`);
    } else {
      if (parentFolder) navigate(`/${shortName}/trash/auth-keys/folder/${parentFolder}`);
      if (!parentFolder) navigate(`/${shortName}/trash`);
    }
  };

  const listAPI = () => redirectToList(redirectFolder, activeAuthKey?.folder);
  const backToTrash = () => redirectToTrash(redirectFolder, activeAuthKey?.folder);

  const handleChangeProxy = (...arg) => {
    if (testStatus !== loadStatus.INITIAL) setTestStatus(loadStatus.INITIAL);
    setIsChange(true);
    return handleChange(...arg);
  };

  const handleChangeNumber = (e) => {
    const { value, name } = e.target;
    if (Number.isNaN(+value) || !Number.isInteger(+value) || (+value) < 0) return;
    setIsChange(true);
    setFieldValue(name, value ? Math.round(+value) : value);
    setTestStatus(loadStatus.INITIAL);
  };

  const setFieldValueProxy = (...arg) => {
    if (testStatus !== loadStatus.INITIAL) setTestStatus(loadStatus.INITIAL);
    setIsChange(true);
    return setFieldValue(...arg);
  };

  const setFieldValueSkipCheck = (...arg) => {
    setFieldValue(...arg);
  };


  const deleteAccess = (user) => {
    const newInviteList = inviteList.filter((el) => el.email !== user.email);
    setInviteList(newInviteList);
    setRemoveModalOpened(false);
    setActiveUser({});
  };

  const handleBlurLimits = (e) => {
    const { value, name } = e.target;
    if (Number.isNaN(+value) || !Number.isInteger(+value) || (+value) < 0) {
      return;
    }
    switch (name) {
      case 'RPS': {
        setFieldValue(name, value);
        if (+values.RPMONTH && +value && +values.RPMONTH < +value) setFieldValue('RPMONTH', value);
        if (+values.RPMONTHCALENDAR && +value && +values.RPMONTHCALENDAR < +value) setFieldValue('RPMONTHCALENDAR', value);
        break;
      }
      case 'RPMONTH': {
        if (+values.RPS && +value && +values.RPS > +value) setFieldValue('RPS', value);
        setFieldValue(name, value);
        break;
      }
      case 'RPMONTHCALENDAR': {
        if (+values.RPS && +value && +values.RPS > +value) setFieldValue('RPS', value);
        setFieldValue(name, value);
        break;
      }
      default: {
        setFieldValue(name, value);
      }
    }

    setPlaceholders(calcPlaceholder({ ...values, [name]: value }));
  };

  const handeChangeRole = (user, role) => {
    if (role === 'delete') {
      setRemoveModalOpened(true);
      setActiveUser(user);
      return;
    }

    const newInviteList = inviteList.map((el) => (el.email === user.email ? { ...el, role } : el));
    addAccess({
      id: +resourceId,
      resource: 'key',
      projectId: +activeProject.id,
      role,
      userId: user.id,
    }, () => {
      setInviteList(newInviteList);
    });
  };

  const addUserOfApiKey = () => {
    setShareModalOpened(true);
  };

  const getRoles = () => {
    const rolesList = [];
    const filteredRoles = getProjectRoles(roles, activeProject.id).filter((r) => r.name !== 'owner');
    if (isRoleAction({ key: 'key', role: 'share', modelRole: activeAuthKey?.role })) {
      rolesList.push(...filteredRoles.map((r) => ({ value: r.name, title: listRolesName[r.name] || r.name })));
    } else {
      rolesList.push(...filteredRoles.map((r) => ({ value: r.name, title: listRolesName[r.name] || r.name, disabled: true })));
    }
    if (isRoleAction({ key: 'key', role: 'delete', modelRole: activeAuthKey?.role })) {
      rolesList.push({ value: 'delete', title: 'Delete', hidden: currentUser.role === 'viewer', red: true  });
    }
    return rolesList;
  };

  const saveInviteList = () => {
    // const filtered = data.filter((el) => el.id);
    // const unique = Object.values(
    //   [...inviteList, ...filtered].reduce((acc, obj) => ({ ...acc, [obj.email]: obj }), {}),
    // );
    // setInviteList(unique);
    getAccessUsers({
      id: +resourceId,
      resource: 'key',
    });
    setShareModalOpened(false);
  };

  const handleChangeName = ({ target }) => {
    const regex = /^(?!\s)(?!.* {2})/;
    if (regex.test(target.value) && target.value.length <= maxAllowedLength) {
      setIsChange(true);
      setFieldValue(target.name, target.value);
    }
  };

  const handleRemoveAuthKey = () => {
    dispatch(removeTrashItemsRequest({ type: 'keys', ids: [activeAuthKey?.id] }, ({ errors, successList }) => {
      if (errors && errors.length > 0) {
        errors.forEach((error) => dispatch(createToastRequest({ type: 'error', text: error.error })));
      }
      if (successList && successList.length > 0) {
        dispatch(createToastRequest({ type: 'success', title: activeAuthKey?.instanceName, text: 'auth key has been deleted successfully' }));
        backToTrash();
      }
    }));
  };

  const handleRestoreAuthKey = () => {
    dispatch(restoreTrashItemsRequest({ type: 'keys', items: [{ ...activeAuthKey, deleted: false }] }, ({ errors, successList }) => {
      if (errors && errors.length > 0) {
        errors.forEach((error) => dispatch(createToastRequest({ type: 'error', text: error.error })));
      }
      if (successList && successList.length > 0) {
        dispatch(createToastRequest({ type: 'success', title: activeAuthKey?.instanceName, text: 'auth key has been restored successfully' }));
        backToTrash();
      }
    }));
  };

  return (
    <div className={shared.page} style={{ overflow: 'auto' }}>
      <div className={cn(shared.header, shared.borderBottom)} style={{ position: 'sticky', top: 0, zIndex: 9999, backgroundColor: '#fff' }}>
        <div className={shared.headerTitleGroup}>
          <h1 className={shared.headerTitle}>Edit Auth Key</h1>
          <p className={shared.headerDescription}>{isDeleted ? 'Read only' : ''}</p>
        </div>
        <div className={shared.headerButtonGroup}>
          {!isDeleted && (
            <>
              <ValidationErrorList 
                errors={errors} 
                subrouteMapping={subrouteMapping}
                setSubroute={setSubroute}
              />
              <Button title="Cancel" type="secondary" onClick={listAPI} />
              <Button title="Edit auth key" onClick={handleSubmitWithCallback} disabled={viewRead || !isChange} />
            </>
          )}
          {isDeleted && (
            <>
              <Button title="Cancel" type="secondary" onClick={backToTrash} />
              <Button title="Delete" type="red" onClick={handleRemoveAuthKey} />
              <Button title="Restore" onClick={handleRestoreAuthKey} />
            </>
          )}
        </div>
      </div>
      <div className={cn(shared.body, shared.compactLayout)} style={{ border: 0 }}>
        <div className={cn(shared.mainContent, shared.compactLayout)}>
        <section className={shared.section}>
            <h4 className={shared.sectionHeader}>General Settings</h4>
            <div className={shared.sectionContainer}>
              <div className={shared.inputWrapper}>
                <span className={shared.inputLabel}>Name</span>
                <Input
                  placeholder="Enter name"
                  handleChange={handleChangeName}
                  value={values.name}
                  name="name"
                  id="name"
                  error={!!errors.name}
                  disabled={viewRead}
                />
              </div>
              <div className={shared.inputWrapper}>
                <span className={shared.inputLabel}>
                  Description 
                  {' '}
                  <span className={shared.inputLabelDetails}> (optional)</span>
                </span>
                <Input
                  placeholder="Enter description"
                  handleChange={handleChangeProxy}
                  value={values.description}
                  name="description"
                  id="description"
                  disabled={viewRead}
                />
              </div>
            </div>
          </section>
          <hr />
          <section className={shared.section}>
            <h4 className={shared.sectionHeader}>Authorization Settings</h4>
            <div className={shared.sectionContainer}>
              <div className={shared.inputWrapper}>
                        <span className={shared.inputLabel}>Authorization method</span>
                        <Select
                          list={[
                            { value: 'basic', title: 'Basic' },
                            { value: 'APIKey', title: 'API Key' },
                            { value: 'bearer', title: 'Bearer' },
                            { value: 'JWT', title: 'JWT' },
                          ]}
                          value={values.authorizationMethod}
                          handleChange={setFieldValueProxy}
                          placeholder="Select authorization method"
                          name="authorizationMethod"
                          id="authorizationMethod"
                          error={!!errors.authorizationMethod}
                          disabled={viewRead}
                        />
                      </div>
                      {values.authorizationMethod === 'basic' ? (
                        <>
                          <div className={shared.inputWrapper} />
                          <div className={shared.inputWrapper}>
                            <span className={shared.inputLabel}>Login</span>
                            <Input
                              placeholder="Enter login"
                              handleChange={handleChangeProxy}
                              value={values.authBasicLogin}
                              iconRight={values.authBasicLogin && 'copy'}
                              handleAction={() => {
                                copyText(values.authBasicLogin) && createToast({ type: 'success', text: 'Copied' });
                              }}
                              name="authBasicLogin"
                              id="authBasicLogin"
                              autoComplete="none"
                              error={!!errors.authBasicLogin}
                              disabled={viewRead}
                            />
                          </div>
                          <div className={shared.inputWrapper}>
                            <span className={shared.inputLabel}>Password</span>
                            <Input
                              placeholder="Enter password"
                              handleChange={handleChangeProxy}
                              value={values.authBasicPassword}
                              iconRight={values.authBasicPassword && 'copy'}
                              isPassword
                              handleAction={() => {
                                copyText(values.authBasicPassword) && createToast({ type: 'success', text: 'Copied' });
                              }}
                              name="authBasicPassword"
                              id="authBasicPassword"
                              type="password"
                              autoComplete="new-password"
                              error={!!errors.authBasicPassword}
                              disabled={viewRead}
                            />
                          </div>
                        </>
                      ) : null}
                      {values.authorizationMethod === 'APIKey' ? (
                        <>
                          <div className={shared.inputWrapper}>
                            <span className={shared.inputLabel}>Add API Key to</span>
                            <Select
                              list={addToParam}
                              value={values.authAPIKeyAddTo}
                              handleChange={setFieldValueProxy}
                              placeholder="Select where to place key"
                              name="authAPIKeyAddTo"
                              id="authAPIKeyAddTo"
                              error={!!errors.authAPIKeyAddTo}
                              disabled={viewRead}
                            />
                          </div>
                          <div className={shared.inputWrapper}>
                            <span className={shared.inputLabel}>Key</span>
                            <Input
                              placeholder="Enter key"
                              handleChange={handleChangeProxy}
                              value={values.authAPIKeyHeaderName}
                              iconRight={values.authAPIKeyHeaderName && 'copy'}
                              handleAction={() => {
                                copyText(values.authAPIKeyHeaderName) && createToast({ type: 'success', text: 'Copied' });
                              }}
                              name="authAPIKeyHeaderName"
                              id="authAPIKeyHeaderName"
                              autoComplete="none"
                              error={!!errors.authAPIKeyHeaderName}
                              disabled={viewRead}
                            />
                          </div>
                          <div className={shared.inputWrapper}>
                            <span className={shared.inputLabel}>Value</span>
                            <Input
                              placeholder="Enter value"
                              handleChange={handleChangeProxy}
                              value={values.authAPIKeyToken}
                              iconRight={values.authAPIKeyToken && 'copy'}
                              handleAction={() => {
                                copyText(values.authAPIKeyToken) && createToast({ type: 'success', text: 'Copied' });
                              }}
                              name="authAPIKeyToken"
                              id="authAPIKeyToken"
                              autoComplete="none"
                              error={!!errors.authAPIKeyToken}
                              disabled={viewRead}
                            />
                          </div>
                        </>
                      ) : null}
                      {values.authorizationMethod === 'bearer' ? (
                          <div className={shared.inputWrapper}>
                            <span className={shared.inputLabel}>Token</span>
                            <Input
                              placeholder="Enter token"
                              handleChange={handleChangeProxy}
                              value={values.authBearerToken}
                              iconRight={values.authBearerToken && 'copy'}
                              handleAction={() => {
                                copyText(values.authBearerToken) && createToast({ type: 'success', text: 'Copied' });
                              }}
                              name="authBearerToken"
                              id="authBearerToken"
                              autoComplete="none"
                              error={!!errors.authBearerToken}
                              disabled={viewRead}
                            />
                          </div>
                      ) : null}
                      {values.authorizationMethod === 'JWT' ? (
                        <>
                          <div className={shared.inputWrapper} />
                          <div className={shared.inputWrapper}>
                              <span className={shared.inputLabel}>Add JWT to</span>
                              <Select
                                list={addToParam}
                                value={values.authJwtClientAddTo}
                                handleChange={setFieldValueProxy}
                                placeholder="Select where to add JWT"
                                name="authJwtClientAddTo"
                                id="authJwtClientAddTo"
                                error={!!errors.authJwtClientAddTo}
                                disabled={viewRead}
                              />
                          </div>
                          <div className={shared.inputWrapper}>
                            <span className={shared.inputLabel}>Algorithm</span>
                            <Select
                              list={algorithms}
                              value={values.authJwtClientAlgorithm}
                              handleChange={setFieldValueProxy}
                              placeholder="Select algorithm"
                              name="authJwtClientAlgorithm"
                              id="authJwtClientAlgorithm"
                              error={!!errors.authJwtClientAlgorithm}
                              disabled={viewRead}
                            />
                          </div>
                          <div className={shared.inputWrapper} />
                        </>
                      ) : null}
            </div>
            {values.authorizationMethod === 'JWT' ? (
                <div className={cn(shared.sectionContainer, shared.wide)} style={{ marginTop: 0 }}>
                    <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>
                      {(values.authJwtClientAlgorithm || '').startsWith('HS') ? (
                          'Secret Key'
                        ) : (
                          'Private Key'
                        )}
                    </span>
                    {(values.authJwtClientAlgorithm || '').startsWith('HS') ? (
                          <Input
                            placeholder="Paste Secret Key"
                            handleChange={handleChangeProxy}
                            value={values.authJwtClientPrivateKey}
                            iconRight={values.authJwtClientPrivateKey && 'copy'}
                            handleAction={() => {
                              copyText(values.authJwtClientPrivateKey) && createToast({ type: 'success', text: 'Copied' });
                            }}
                            name="authJwtClientPrivateKey"
                            id="authJwtClientPrivateKey"
                            autoComplete="none"
                            error={!!errors.authJwtClientPrivateKey}
                            disabled={viewRead}
                        />
                      ) : (
                        <Input
                          placeholder={`- - - - - BEGIN PRIVATE KEY - - - - -\nprivate key in PKCS#8 PEM format\n- - - - - END PRIVATE KEY - - - - `}
                          handleChange={handleChangeProxy}
                          value={values.authJwtClientPrivateKey}
                          iconRight={values.authJwtClientPrivateKey && 'copy'}
                          handleAction={() => {
                            copyText(values.authJwtClientPrivateKey) && createToast({ type: 'success', text: 'Copied' });
                          }}
                          name="authJwtClientPrivateKey"
                          id="authJwtClientPrivateKey"
                          multiline
                          autoComplete="none"
                          error={!!errors.authJwtClientPrivateKey}
                          style={{ lineHeight: '20px', paddingTop: '10px', paddingBottom: '10px' }}
                          disabled={viewRead}
                    />
                      )}
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <Checkbox
                            name="authJwtClientKeyBase64Encoded"
                            id="authJwtClientKeyBase64Encoded"
                            handleChange={(val) => setFieldValueProxy('authJwtClientKeyBase64Encoded', val)}
                            size="small"
                            value={values.authJwtClientKeyBase64Encoded}
                            style={{ marginRight: '0' }}
                            label="Key Base64 encoded"
                            disabled={viewRead}
                          />
                    </div>
                    <div className={shared.inputWrapper}>
                          <span className={shared.inputLabel}>Payload</span>
                          <MemoizedCodeEditor
                            value={values.authJwtClientPayload}
                            handleChange={(value) => setFieldValueProxy('authJwtClientPayload', value)}
                            name="authJwtClientPayload"
                            id="authJwtClientPayload"
                            type='json'
                            placeholder="{}"
                            error={!!errors.authJwtClientPayload}
                            style={{ maxWidth: 'calc(100vw - 651px + 48px)', minWidth: '400px' }}
                            readOnly={viewRead}
                            height={104}
                            withVariables={false}
                          />
                    </div>
                </div>
            ) : null}
          </section>
          {
           values.authorizationMethod === 'JWT' ? (
            <>
              <hr />
              <section className={shared.section}>
                <h4 className={shared.sectionHeader}>Advanced Settings</h4>
                <div className={shared.sectionContainer}>
                  <div className={shared.inputWrapper}>
                      <span className={shared.inputLabel}>
                        {values.authJwtClientAddTo === 'query' ? 'Query parameter name' : 'Request header prefix'}
                      </span>
                      <Input
                        placeholder={values.authJwtClientAddTo === 'query' ? 'Enter name' : 'Enter prefix'}
                        handleChange={handleChangeProxy}
                        value={values.authJwtClientHeaderPrefix}
                        iconRight={values.authJwtClientHeaderPrefix && 'copy'}
                        handleAction={() => {
                          copyText(values.authJwtClientHeaderPrefix) && createToast({ type: 'success', text: 'Copied' });
                        }}
                        name="authJwtClientHeaderPrefix"
                        id="authJwtClientHeaderPrefix"
                        autoComplete="none"
                        error={!!errors.authJwtClientHeaderPrefix}
                        disabled={viewRead}
                      />
                  </div>
                  <div className={shared.inputWrapper} />
                </div>
                <div className={cn(shared.sectionContainer, shared.wide)}>
                    <div className={shared.inputWrapper}>
                        <span className={shared.inputLabel}>JWT headers</span>
                        <MemoizedCodeEditor
                          value={values.authJwtClientHeaders}
                          handleChange={(value) => setFieldValueProxy('authJwtClientHeaders', value)}
                          name="authJwtClientHeaders"
                          id="authJwtClientHeaders"
                          type='json'
                          placeholder="{}"
                          error={!!errors.authJwtClientHeaders}
                          style={{ maxWidth: 'calc(100vw - 651px + 48px)', minWidth: '400px' }}
                          height={104}
                          readOnly={viewRead}
                          withVariables={false}
                        />
                    </div>
                </div>
              </section>
            </>
           ) : null
          }
        </div>
        <div className={cn(shared.sidepanel, shared.compactLayout)}>
          <div className={shared.sidepanelGroup}>
          <div className={shared.sidepanelHeading}>
          <div className={shared.subHeaders}>
              <h4
                onClick={() => setSubroute(SUBROUTING.LIMITS)}
                className={
                  cn(shared.sectionHeader, 
                    { [shared.clickable]: true }, 
                    { [shared.passive]: subroute !== SUBROUTING.LIMITS }
                  )}
              >
                Limits
              </h4>
              <h4
                onClick={() => setSubroute(SUBROUTING.ACCESS)}
                className={
                  cn(shared.sectionHeader,
                    { [shared.clickable]: true },
                    { [shared.passive]: subroute !== SUBROUTING.ACCESS }
                  )}
              >
                Access
              </h4>
              {subroute === SUBROUTING.ACCESS && !isDeleted && isRoleAction({ key: 'key', role: 'share', modelRole: activeAuthKey?.role })
                && (
                  <div className={shared.textAction}>
                    <ActionText title="Share access" onClick={addUserOfApiKey} />
                  </div>
                )}
            </div>
          </div>
            {subroute === SUBROUTING.LIMITS && (
              <div className={shared.sidepanelForm}>
                  <div className={cn(shared.limitSection)}>
                    <div className={shared.inputWrapper}>
                      <span className={cn(shared.inputLabel, shared.spanWrapper)}>Requests / second (RPS)</span>
                      <Input
                        maxLength={15}
                        placeholder={placeholders.RPS}
                        handleChange={handleChangeNumber}
                        onBlur={handleBlurLimits}
                        value={values.RPS}
                        name="RPS"
                        disabled={viewRead}
                        error={!!errors.RPS}
                      />
                      {(!!errors.RPS) ? (
                        <span className={shared.errorPlaceholder}>{errors.RPS}</span>
                      ) : null}
                    </div>

                    <div className={shared.inputWrapper}>
                      <span className={cn(
                        shared.inputLabel, shared.spanWrapper
                        )}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          flexDirection: 'row',
                          whiteSpace: 'nowrap',
                          overflow: 'visible',
                        }}
                        >
                          <span style={{ 
                            display: 'flex',
                            alignItems: 'center'
                          }}>
                          <span>Requests / month (RPM)</span>
                          {values.activeRpmType === 'cmon' ? (
                            <ActionText
                              title={
                                <ActionIcon
                                  id="rpmonth_tooltip1-cmon"
                                  icon="info"
                                  style={{ marginLeft: '8px' }}
                                  tooltip={{
                                    children: (
                                      <div style={{ textAlign: 'left' }}>
                                        The limit is checked by counting requests <br />
                                        starting from the <span style={{ fontWeight: 600 }}>first day of the month</span>. <br />
                                        <span style={{ fontStyle: 'italic'}}>
                                        Example: <br />
                                        Today is May 10th, 09:30 AM. < br />
                                        When a new request is received, requests will <br />
                                        be counted in the period from May 1, 12:00 AM <br />
                                        to the current moment.
                                        </span>

                                      </div>
                                    ),
                                    place: 'top',
                                    openOnClick: false,
                                    clickable: false,
                                  }}
                                />
                              }
                          />
                         ) : 
                         (
                          <ActionText
                          title={
                            <ActionIcon
                              id="rpmonth_tooltip1-mon"
                              icon="info"
                              style={{ marginLeft: '8px' }}
                              tooltip={{
                                children: (
                                  <div style={{ textAlign: 'left' }}>
                                    The limit is checked by counting requests <br/>
                                    over the <span style={{ fontWeight: 600}}>last 30 days</span>. <br />
                                    <span style={{ fontStyle: 'italic'}}>
                                    Example: <br />
                                    Today is May 10th, 09:30 AM. < br />
                                    When a new request is received, requests will <br />
                                    
                                     be counted in the period from April 10, 09:30 AM <br />
                                     to the current moment.
                                    </span>
                                  </div>
                                ),
                                place: 'top',
                                openOnClick: false,
                                clickable: false,
                              }}
                            />
                          }
                      />
                         )}
                          </span>
                          <span>
                            <Select
                            type="textColor"
                            list={[
                              { value: 'mon', title: 'Sliding window' },
                              { value: 'cmon', title: 'Calendar' },
                            ]}
                            value={values.activeRpmType}
                            handleChange={(key, value) => {
                              setFieldValueSkipCheck(key, value);
                            }}
                            name="activeRpmType"
                          />
                          </span>
                      </span>
                      {values.activeRpmType === 'mon' ? (
                        <>
                          <Input
                            maxLength={15}
                            placeholder={placeholders.RPMONTH}
                            handleChange={handleChangeNumber}
                            onBlur={handleBlurLimits}
                            value={values.RPMONTH}
                            name="RPMONTH"
                            disabled={viewRead}
                            error={!!errors.RPMONTH}
                          />
                          {(!!errors.RPMONTH) ? (
                            <span className={shared.errorPlaceholder}>{errors.RPMONTH}</span>
                          ) : null}
                        </>
                      ) : (
                        <>
                        <Input
                          maxLength={15}
                          placeholder={placeholders.RPMONTHCALENDAR}
                          handleChange={handleChangeNumber}
                          onBlur={handleBlurLimits}
                          value={values.RPMONTHCALENDAR}
                          name="RPMONTHCALENDAR"
                          disabled={viewRead}
                          error={!!errors.RPMONTHCALENDAR}
                        />
                        {(!!errors.RPMONTHCALENDAR) ? (
                            <span className={shared.errorPlaceholder}>{errors.RPMONTHCALENDAR}</span>
                          ) : null}
                        </>
                      )}
                    </div>
                  </div>
              </div>
            )}
            {subroute === SUBROUTING.ACCESS && (
              <div className={shared.sidepanelTable}>
                <div className={shared.sidepanelTableHeading}>
                  <div className={shared.lineHeader}>
                    <div>Name</div>
                    <div />
                    <div>Role</div>
                  </div>
                </div>
                <div className={shared.sidepanelTableBodyContainer}>
                    <ul className={shared.sidepanelTableBody}>
                          {inviteList?.map((item) => (
                            <li className={shared.historyLine} key={item.email}>
                              <div title={item.email}>
                                {item.email === currentUser.email ? (
                                  <span>{`${item.firstName} ${item.lastName}`}</span>
                                ) : item.firstName || item.lastName ? (
                                  <span>{`${item.firstName} ${item.lastName} (${item.email})`}</span>
                                ) : (
                                  <span>{item.email}</span>
                                )}
                              </div>
                              <div />
                              <div>
                                {viewRead ? (
                                  <ChipsStatus
                                    title={listRolesName[item.role] || item.role}
                                    type="input"
                                    className={shared.chipsStatus}
                                    style={{ width: '90px', justifyContent: 'left', paddingLeft: '12px' }}
                                  />
                                ) : item.role === 'owner' ? (
                                  <ChipsStatus
                                    title="Owner"
                                    type="input"
                                    className={shared.chipsStatus}
                                    style={{ width: '90px', justifyContent: 'left', paddingLeft: '12px' }}
                                  />
                                ) : (
                                  <Select
                                    style={{ width: 90 }}
                                    list={getRoles(item.role)}
                                    value={item.role}
                                    placeholder={item.role}
                                    handleChange={(email, value) => handeChangeRole(item, value)}
                                    name={item.email}
                                  />
                                )}
                              </div>
                            </li>
                          ))}
                    </ul>
                </div>
              </div>
            )}
          </div>
        </div>
        {shareModalOpened && (
          <InviteToResourceModal
            id={resourceId}
            onClose={() => setShareModalOpened(false)}
            onSubmit={saveInviteList}
            model={values}
            type="key"
            inviteList={inviteList?.map((i) => i.email) || []}
          />
        )}
        {removeModalOpened && (
          <RemoveAccessModal
            setIsOpen={setRemoveModalOpened}
            deleteAccessUser={deleteAccess}
            user={activeUser}
            resource="key"
            id={resourceId}
          />
        )}
      </div>
      <ConnectedAPI list={connectedEndpoints} activeProject={activeProject} activeAuthKey={values} hideWithoutList />
    </div>
  );
}

const mapStateToProps = ({
  activeAuthKey,
  auth: { currentUser },
  directory,
  activeProject,
  roles,
}) => ({
  roles,
  activeAuthKey: activeAuthKey?.activeAuthKey,
  connectedEndpoints: activeAuthKey?.connectedEndpoints,
  storedInviteList: activeAuthKey?.inviteList,
  activeProject,
  currentUser,
  JWTAlgorithm: directory?.JWTAlgorithm,
  connectLimit: directory?.connectLimit || 100,
});

const mapDispatchToProps = (dispatch) => ({
  updateAuthKey: (data, callback) => dispatch(updateAuthKeyRequest(data, callback)),
  getActiveAuthKey: (data) => dispatch(activeAuthKeyRequest(data)),
  getConnectedEndpoints: (data) => dispatch(getConnectedEndpointsToAuthKeysRequest(data)),
  getAccessUsers: (data) => dispatch(getAccessUsersRequest(data)),
  addAccess: (data, callback) => dispatch(addAccessRequest(data, callback)),
  createToast: (data, callback) => dispatch(createToastRequest(data, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(isRoleAction(AuthKeyModify));

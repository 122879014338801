/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-autofocus */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Input from '../Input/Input';
import { createEndpointFolderRequest } from '../../store/endpoints/actions';
import { createConnectionFolderRequest } from '../../store/connections/actions';
import { createAuthKeyFolderRequest } from '../../store/authKeys/actions';
import Button from '../Button/Button';

import * as styles from './CreateFolderModal.module.scss';

const CreateFolderModalSchema = Yup.object().shape({
  name: Yup.string().min(2).max(64).required('Name required'),
});
const maxAllowedLength = 64;

function CreateFolderModal({
  setIsOpen, createEndpointFolder, createConnectionFolder, createAuthKeyFolder, parent,
  activeProject, type = 'endpoints',
}) {
  const [loading, setLoading] = useState(false);

  const {
    submitForm, setFieldValue, values, errors, setTouched,
  } = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema: CreateFolderModalSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (v) => {
      setLoading(true);
      if (type === 'endpoints') {
        createEndpointFolder({
          type,
          name: v.name,
          parentId: parent || 0,
          projectId: activeProject?.id,
        }, () => {
          setIsOpen(false);
        });
      } else if (type === 'connections') {
        createConnectionFolder({
          type,
          name: v.name,
          parentId: parent || 0,
          projectId: activeProject?.id,
        }, () => {
          setIsOpen(false);
        });
      } else {
        createAuthKeyFolder({
          type,
          name: v.name,
          parentId: parent || 0,
          projectId: activeProject?.id,
        }, () => {
          setIsOpen(false);
        });
      }
    },
  });

  const handleChangeFolderName = ({ target }) => {
    const regex = /^(?!\s)(?!.* {2})/;
    if (regex.test(target.value) && target.value.length <= maxAllowedLength) {
      setFieldValue(target.name, target.value);
    }
    setTouched('name');
  };

  const renderMainModal = () => (
    <div className={styles.modalContent}>
      <div className={styles.header}>
        <div className={styles.title}>Create new folder</div>
      </div>
      <div className={styles.body}>
        <div className={styles.mainContent}>
          <Input
            style={{ width: '100%' }}
            placeholder="Enter folder name"
            handleChange={handleChangeFolderName}
            value={values.name}
            name="name"
            autoFocus
            error={!!errors.name && values.name?.length < 2}
          />

        </div>
        <div className={styles.buttonGroupCol}>
          <Button
            style={{ width: '85px' }}
            title="Cancel"
            type="secondary"
            onClick={() => setIsOpen(false)}
          />
          <Button
            style={{ width: '154px', marginLeft: '12px' }}
            disabled={loading}
            title="Create"
            onClick={submitForm}
          />
        </div>
      </div>
    </div>
  );

  return (
    <div className={styles.modal}>
      {renderMainModal()}
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  createEndpointFolder: (data, callback) => dispatch(createEndpointFolderRequest(data, callback)),
  createConnectionFolder: (data, callback) => dispatch(createConnectionFolderRequest(data, callback)),
  createAuthKeyFolder: (data, callback) => dispatch(createAuthKeyFolderRequest(data, callback)),
});

export default connect(null, mapDispatchToProps)(CreateFolderModal);

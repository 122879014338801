@use "sass:map";
@import '../../styles/variables.scss';

.collapsible {
    width: 100%;

    &:last-child {
        margin-bottom: 19px;
    }
}
  
  .collapsibleHeader {
    display: flex;
    align-items: center;
    padding: 10px;
    gap: 12px;
    font-weight: 600;
    cursor: pointer;

    .iconGroup {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 6px;
    }
  }
  
  .collapsibleHeader span {
    flex-grow: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  
  .arrow::before {
    content: '\25BC';
    font-size: 24px;
    transform: rotate(0deg);
    transition: transform 0.3s ease-in-out;
  }
  
  .arrow.up::before {
    transform: rotate(-180deg);
  }
  
  .collapsed .collapsibleContent {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
  }
  
  .collapsibleContent {
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;

    &.collapsed {
      max-height: 0;
    }
  }

  .collapsibleTable {
    display: flex;
    flex-direction: column;

    li {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 8px;
        padding: 5px 16px 5px 36px;

        &.foreignKeyItem {
          color: map.get($colors-basic, "textGray");
          cursor: pointer;

          &:hover {
            color: map.get($colors-basic, "primaryBlue");

            .foreignKeyItemGroup {
              svg * {
                stroke: map.get($colors-basic, "primaryBlue");
              }
            }

            .typeGroup {
              color: map.get($colors-basic, "primaryBlue");
              svg * {
                fill: map.get($colors-basic, "primaryBlue");
              }
            }
          }
        }

        .foreignKeyItemGroup {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 8px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .primaryKey {
            position: absolute;
            width: 16px;
            height: 16px;
            left: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        span {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        &:hover {
           background-color: map.get($colors-basic, "hover");
        }
    }

    .typeGroup {
        text-transform: lowercase;
        color: map.get($colors-basic, "textGray");
        display: flex;
        gap: 8px;
        flex-direction: row;
        align-items: center;

        &.activeLink {
          color: map.get($colors-basic, "primaryBlue"); 

          svg * {
            fill: map.get($colors-basic, "primaryBlue");
          }
        }
    }
  }

  .arrowAngle {
    width: 16px;
    height: 16px;
    transform: rotate(0deg);
    transition: all 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;

    &.openAngle {
      transform: rotate(-90deg);
      transition: all 0.3s;
    }
  }

  .highlighted {
    color: map.get($colors-green, 100);
    background-color: map.get($colors-green, 30);

    .typeGroup {
      color: map.get($colors-green, 100);
      svg * {
        fill: map.get($colors-green, 100);
      }
    }
  }
  


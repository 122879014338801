import { Connections } from './constants';
import {
  injectChunk, removeFolder, removeObj, createFolder,
  updateFolder, createObj, moveFolder, moveObj,
} from '../../helpers/utils';
import { ActiveProject } from '../activeProject/constants';

const initialState = {};

export const connectionsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case Connections.listConnectionsSuccess:
      return { ...state, data: action.data };
    case Connections.searchConnectionsSuccess:
      return { ...state, data: action.data?.connections, foldersData: action.data?.folders };
    case Connections.getConnectionFolderContentSuccess:
      return action.data?.merge ? injectChunk(state, action.data) : {
        ...state,
        data: action.data?.data,
        foldersData: action.data?.folders,
        breadscrumbData: action.data?.breadscrumbs,
      };
    case Connections.moveConnectionFolderSuccess: return moveFolder(state, action.data);
    case Connections.moveConnectionSuccess: return moveObj(state, action.data);
    case Connections.createConnectionFolderSuccess:
      return state.foldersData
        ? { ...state, foldersData: [...state.foldersData, action.data] }
        : { ...state, foldersData: action.data };
    case Connections.copyConnectionFolderSuccess: return createFolder(state, action.data);
    case Connections.createConnectionSuccess: return state.data ? createObj(state, action.data) : {
      ...state, data: action.data,
    };
    case Connections.removeConnectionFolderSuccess: return removeFolder(state, action.data);
    case Connections.removeConnectionsListSuccess: return removeObj(state, action.data);
    case Connections.updateConnectionFolderSuccess:
      return updateFolder(state, action.data);
    case Connections.updateConnectionSuccess:
      return state.data ? {
        ...state,
        data: [...state.data.map(
          (content) => (action.data.id === content.id ? { ...content, ...action.data }
            : content),
        )],
      } : state;
    case ActiveProject.activeProjectReset:
      return initialState;
    default:
      return state;
  }
};

export const backendHost = process.env.REACT_APP_BACKEND_HOST || `${window.location.origin}/api`;
export const callbackHost = process.env.REACT_APP_CALLBACK_HOST || window.location.origin;
export const wsHost = process.env.REACT_APP_WS_HOST || `wss://${window.location.hostname}/ws`;

export const kaptchaKey = '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI';

export const loadStatus = {
  INITIAL: 'INITIAL',
  LOAD: 'LOAD',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
};

export const typesAPI = {
  postgres: 'PostgreSQL',
  mysql: 'MySQL',
  mssql: 'MsSQL',
  corezoid: 'Corezoid',
  mongo: 'MongoDB',
  oracle: 'Oracle',
  aws: 'AWS S3',
  chatGpt: 'ChatGPT',
  apiCall: 'API',
  ftp: 'FTP',
};

export const APIMethods = {
  basic: 'Basic',
  APIKey: 'API Key',
  JWT: 'JWT',
  bearer: 'Bearer'
};

export const typesConnectionType = {
  'synchronous': 'Sync',
  'asynchronous': 'Async',
};

export const listRolesName = {
  owner: 'Owner',
  editor: 'Editor',
  viewer: 'Viewer',
};

export const canSelectRole = {
  owner: ['owner', 'editor', 'viewer'],
  // owner: ['editor', 'viewer'],
  editor: ['editor', 'viewer'],
  viewer: ['viewer'],
};

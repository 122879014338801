import { ActiveVariable } from './constants';

const initialState = {
  activeVariable: null,
  connectedEndpoints: [],
  connectedConnections: [],
  inviteList: [],
};

export const activeVariableReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case ActiveVariable.activeVariableSuccess:
      return {
        ...state,
        activeVariable: action.data
      }
    case ActiveVariable.getAccessUsersVariableSuccess:
      return {
        ...state,
        inviteList: action.data,
      }
    case ActiveVariable.getConnectedModelsToVariablesSuccess:
      return {
        ...state,
        connectedEndpoints: action.data?.endpoints || [],
        connectedConnections: action.data?.connections || [],
      }
    default:
      return state;
  }
};

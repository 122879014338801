/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-autofocus */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ThreeDots } from 'react-loader-spinner';
import Button from '../Button/Button';
import { 
  getFolderPath, getDataPath, filterItemsByExclude,
  getAllfoldersDataObjects, getAllDataObjects, isSafari,
  getFoldersDataInFolder, getDataInFolder
 } from '../../helpers/utils';
import { 
  getAuthKeyFolderTreeRequest,
  removeAuthKeysListRequest,
  removeAuthKeyFolderSuccess, 
  removeAuthKeysListSuccess
} from '../../store/authKeys/actions';

import * as styles from './RemoveAuthKeysModal.module.scss';

function RemoveAuthKeysModal({
  onClose,
  onSubmit,
  model,
  collection,
}) {
  const dispatch = useDispatch();
  const { authKeys = [], folders = [] } = model || {};
  const [filteredFolders, setFilteredFolders] = useState([]);
  const [filteredAuthKeys, setFilteredAuthKeys] = useState([]);
  const [nestedFolders, setNestedFolders] = useState([]);
  const [nestedAuthKeys, setNestedAuthKeys] = useState([]);

  const [loadDetails, setLoadDetails] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    const calcPaths = () => {
      const f = folders.map((el) => ({ id: el, path: getFolderPath(collection, el) }));
      const c = authKeys.map((el) => ({ id: el, path: getDataPath(collection, el) }));

      const fltF = filterItemsByExclude(f, folders) || [];
      const fltC = filterItemsByExclude(c, folders) || [];

      setFilteredFolders(fltF.map((el) => el.id));
      setFilteredAuthKeys(fltC.map((el) => el.id));
    };

    calcPaths();
  }, [model, collection, folders, authKeys]);

  useEffect(() => {
    setLoadDetails(true);

    const fetchData = () => {
      if (!filteredFolders.length) {
        setLoadDetails(false);
        setNestedAuthKeys([]);
        setNestedFolders([]);
      } else {
        dispatch(getAuthKeyFolderTreeRequest({ ids: filteredFolders }, (result) => {
          setLoadDetails(false);
          if (result) {
            const f = result.filter((el) => el.folders).map((el) => el.folders).flat() || [];
            const c = result.filter((el) => el.keys).map((el) => el.keys).flat() || [];
            setNestedAuthKeys(c);
            setNestedFolders(f);
          } else {
            setNestedAuthKeys([]);
            setNestedFolders([]);
          }
        }));
      }
    };

    fetchData();
  }, [filteredFolders, dispatch]);

  const sendDeleteRequest = (data) => {
    const { folders: fld = [], authKeys: conn = [] } = data || {};
    const allFolders = getAllfoldersDataObjects(collection);
    const allAuthKeys = getAllDataObjects(collection);

    let transformedFolders = [];
    let transformedAuthKeys = [];

    if (fld?.length) {
      transformedFolders = fld.map(f => allFolders.find(a => a.id === f)).filter(el => el);
    }

    if (conn?.length) {
      transformedAuthKeys = conn.map(f => allAuthKeys.find(a => a.id === f)).filter(el => el);
    }

    const excludedFolders = folders.filter(f => !fld.includes(f));
    const excludedAuthKeys = authKeys.filter(c => !conn.includes(c));

    if (transformedFolders?.length || transformedAuthKeys?.length) {
      setSubmitting(true);
      dispatch(removeAuthKeysListRequest({ 
       authKeys: transformedAuthKeys,
       folders: transformedFolders
      }, (result) => {
        const { successFolders, successAuthKeys } = result || {};
        if (result && (successFolders?.length || successAuthKeys?.length)) {
          if (successFolders?.length) {
            successFolders.forEach((f) => {
              const nestedFolders = getFoldersDataInFolder(collection, f.id);
              const nestedData = getDataInFolder(collection, f.id);
  
              excludedFolders?.forEach(f => {
                if (nestedFolders.includes(f)) dispatch(removeAuthKeyFolderSuccess({ id: f }));
              });
              excludedAuthKeys?.forEach(c => {
                if (nestedData.includes(c)) dispatch(removeAuthKeysListSuccess({ id: c }));
              });
            });
          }
          setSubmitting(false);
          onSubmit();
        } else {
          setSubmitting(false);
        }
      }));
    }
  };

  const numFilteredFolders = nestedFolders.length || filteredFolders.length;
  const numFilteredAuthKeys = filteredAuthKeys.length + nestedAuthKeys.length;

  return (
    <div className={styles.modal}>
      <div className={styles.modalContent}>
        <div className={styles.header}>
          <div className={styles.title}>Delete Items</div>
        </div>
        <div className={styles.body}>
          <div className={styles.mainContent}>
            <div>
              <div>
              {loadDetails && !isSafari() && (
                  <span className={styles.modelTitle}
                  style={{ marginRight: '12px' }}
                  >
                  <ThreeDots
                    height="13"
                    width="13"
                    color="#4149F7"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{
                      display: 'inline',
                      transform: 'translateY(-20px)'
                    }}
                    visible
                  />
                  </span>
                )}
                {(numFilteredFolders && !loadDetails) ? (
                  <span className={styles.modelTitle}>
                    {numFilteredFolders}
                    {' '}
                    {numFilteredFolders > 1 ? 'Folders' : 'Folder'}
                  </span>
                ) : null}

                {(numFilteredAuthKeys && numFilteredFolders && !loadDetails) ? ', ' : null}

                {(numFilteredAuthKeys && !loadDetails) ? (
                  <span className={styles.modelTitle}>
                    {numFilteredAuthKeys}
                    {' '}
                    {numFilteredAuthKeys > 1 ? 'Auth Keys' : 'Auth Key'}
                  </span>
                ) : null}
                {' '}
                will be deleted. Are you sure?
              </div>
            </div>
          </div>
          <div className={styles.buttonGroup}>
              <Button style={{ width: 85 }} title="Cancel" type="secondary" onClick={onClose} />
              <Button
                style={{ width: 120, marginLeft: 12 }}
                type="red"
                title="Delete"
                disabled={submitting}
                onClick={() => {
                  sendDeleteRequest({ folders: filteredFolders, authKeys: filteredAuthKeys });
                }}
              />
          </div>
        </div>
      </div>
    </div>
  );
}

export default RemoveAuthKeysModal;

/* eslint-disable no-unreachable */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable no-undef */
/* eslint-disable no-prototype-builtins */
/* eslint-disable prefer-spread */
import { loader } from '@monaco-editor/react';

// const keywords = ['variable1', 'variable2', 'variable3'];
const languages = ['javascript', 'sql', 'text'];

export const notFocusedTheme = {
  name: 'notFocused',
  params: {
    base: 'vs',
    inherit: true,
    rules: [
      {
        token: 'jsonKey',
        foreground: 'A31515',
      },
      {
        token: 'jsonValue',
        foreground: '0551A5',
      },
      {
        token: 'tokenClass',
        foreground: '000001',
      },
    ],
    colors: {
      'editor.background': '#F8F8F9',
    },
  },
};

export const initMonaco = async (keywords) => {
  const monaco = await loader.init();
  // eslint-disable-next-line no-underscore-dangle

  if (monaco.t_loaded) return;
  monaco.t_loaded = true;

    // Register new language 'text'
  monaco.languages.register({ id: 'text' });

  // console.log('init monaco', keywords)
  monaco.editor.defineTheme(notFocusedTheme.name, notFocusedTheme.params);

  // disable {{}} lint
  monaco.languages.typescript.javascriptDefaults.setDiagnosticsOptions({
    diagnosticCodesToIgnore: [1128, 1136, 2809],
  });

  // set custom tokenizer
  const extendLanguageTokenizer = (customTokenizer) => {
    const allLangs = monaco.languages.getLanguages();
    ['javascript', 'sql'].forEach(async (language) => {
        const { language: lang } = await allLangs.find(({ id }) => id === language).loader();
        for (const key in customTokenizer) {
            const value = customTokenizer[key];
            if (key === 'tokenizer') {
                for (const category in value) {
                    const tokenDefs = value[category];
                    if (!lang.tokenizer.hasOwnProperty(category)) {
                        lang.tokenizer[category] = [];
                    }
                    if (Array.isArray(tokenDefs)) {
                        lang.tokenizer[category].unshift.apply(lang.tokenizer[category], tokenDefs);
                    }
                }
            } else if (Array.isArray(value)) {
                if (!lang.hasOwnProperty(key)) {
                    lang[key] = [];
                }
                lang[key].unshift.apply(lang[key], value);
            }
        }
    });
};

  const tokens = keywords.map((k) => [`{{env.${k.name}}}`, 'tokenClass']);
  const customTokenizer = {
    tokenizer: {
      root: [{ include: 'custom' }],
      custom: tokens,
    },
  };
  extendLanguageTokenizer(customTokenizer);

  // monaco.languages.json.jsonDefaults.setModeConfiguration({ tokens: false, validate: true });
  // json tokens
  // monaco.languages.setMonarchTokensProvider('json', {
  //       tokenizer: {
  // eslint-disable-next-line max-len
  //         root: [/* { include: 'custom' }, */ [`"([^"]+)":\s*`, 'jsonKey'], [`"[^{}]*(?!(\{\{.*\}\}))"`, 'jsonValue'],],
  //         // custom: tokens,
  //       },
  //     });

  // env suggestions
  const { dispose } = monaco.languages.registerCompletionItemProvider(languages, {
    triggerCharacters: ['.'],
    autoIndent: true,
    provideCompletionItems: (model, position) => {
      const lineContent = model.getLineContent(position.lineNumber);
      const cursorIndex = position.column - 2;
      if (cursorIndex < 2) return;
      if (!lineContent.includes('env.')) return;

      const positionWord = lineContent.slice(cursorIndex - 3, cursorIndex + 1);
      if (positionWord !== 'env.') return;

      const suggestions = keywords.map((k) => ({
        label: k.name,
        kind: monaco.languages.CompletionItemKind.Keyword,
        insertText: k.name,
      }));
      return { suggestions };
    },
  });

  // json suggestions

  monaco.languages.registerCompletionItemProvider('json', {
    autoIndent: true,
    provideCompletionItems: () => {
      const jsonSuggestions = keywords.map((k) => ({
        label: k.name,
        kind: monaco.languages.CompletionItemKind.Keyword,
        insertText: `env.${k.name}`,
      }));
      return { suggestions: jsonSuggestions };
    },
  });

  return { dispose };
};

/* eslint-disable max-len */
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import Button from '../../components/Button/Button';
import ActionText from '../../components/ActionText/ActionText';
import ActionIcon from '../../components/ActionIcon/ActionIcon';
import ChipsStatus from '../../components/ChipsStatus/ChipsStatus';
import RemoveUserModal from '../../components/RemoveUserModal/RemoveUserModal';
import Loader from '../../components/Loader';
import Input from '../../components/Input/Input';
import { canSelectRole } from '../../helpers/const';
import { listUsersRequest, searchUsersRequest } from '../../store/users/actions';
import { shortenText, formatTimestamp } from '../../helpers/utils';
import { isRoleAction } from '../../helpers/hocs';

import SortIcon from '../../pictures/sort.svg';

import * as shared from '../../styles/shared.module.scss';

const headers = {
  id: { title: 'ID', width: '10%' },
  firstName: { title: 'First name', width: '17%' },
  lastName: { title: 'Last name', width: '17%' },
  email: { title: 'Email', width: '24%' },
  role: { title: 'Role', width: '9%' },
  status: { title: 'Status', width: '9%' },
  action: { title: 'Created', width: '10%' }
}

let searchTimeout = null;

function UsersList({ getUsers, searchUsers, users, roles, currentUser, activeProject, isRoleAction }) {
  const [loading, setLoading] = useState(true);
  const [inputVisible, setInputVisible] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [activeModel, setActiveModel] = useState({});
  const [removeModalOpened, setRemoveModalOpened] = useState(false);
  const ref = useRef(null);
  const navigate = useNavigate();
  const { shortName } = useParams();

  useEffect(() => {
    setLoading(true);
    activeProject?.id && getUsers({ id: activeProject.id }, () => {
      setLoading(false);
    });
  }, [activeProject?.id]);

  const createUser = () => navigate(`/${shortName}/users/create`);

  const modifyUser = (userId) => navigate(`/${shortName}/users/${userId}`);

  const deleteUser = (model) => {
    setActiveModel(model);
    setRemoveModalOpened(true);
  }

  const changeSearch = (e) => {
    setSearchValue(e.target.value);
    if (searchTimeout) clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => {
      if (e.target.value) {
        searchUsers({
          query: e.target.value,
          projectId: activeProject.id
        })
      } else {
        getUsers({
          id: activeProject?.id
        })
      }
    }, 400);
  };

  const clear = () => {
    setSearchValue('');
    getUsers({
      id: activeProject?.id
    });
    setInputVisible(false);
  };

  const handleClickOutside = (event) => {
    if (ref && ref.current && !ref.current.contains(event.target)) {
      const input = ref.current.getElementsByTagName('input')[0];
      if (input.value) return;
      setInputVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return (
    <div className={shared.page}>
      <div className={shared.header}>
        <div className={shared.headerTitleGroup}>
          <h1 className={shared.headerTitle}>Users</h1>
          <p className={shared.headerDescription}>Manage users</p>
        </div>
        <div className={shared.headerButtonGroup}>
          {inputVisible ? (
            <div style={{
                flexGrow: 1,
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <div ref={ref} style={{ width: '100%', maxWidth: '426px'}}>
              <Input
                placeholder="Search"
                iconLeft="search"
                autoFocus
                iconRight={searchValue && 'close'}
                handleChange={changeSearch}
                value={searchValue}
                handleAction={clear}
              />
              </div>
            </div>
          ) : (
            <Button type="secondary" iconLeft="search" onClick={() => setInputVisible(true)} />
          )}
          <Button title="Add user" onClick={createUser} />
        </div>
      </div>
      <div className={shared.table}>
        <table className={shared.usersTable}>
          <thead>
            <tr>
              {Object.keys(headers).map((h, i, arr) => (
                <th key={h} width={headers[h].width}>
                  <span>{headers[h].title}</span>
                  {' '}
                  {i < arr.length - 1 && <SortIcon />}
                </th>
              ))}
            </tr>
          </thead>
          {(users?.length && !loading) ? (
            <tbody>
              {users.map((model) => (
                (
                  <tr key={model.id} role="button" tabIndex={0} onDoubleClick={() => modifyUser(model.id)}>
                    <td width={headers.id.width}><span title={model.id}>{shortenText(model.id)}</span></td>
                    <td width={headers.firstName.width}><span>{model.firstName}</span></td>
                    <td width={headers.lastName.width}><span>{model.lastName}</span></td>
                    <td width={headers.email.width}><span>{model.email}</span></td>
                    <td width={headers.role.width}><span className={shared.capitalized}>{model.role}</span></td>
                    <td width={headers.status.width} style={{ overflow: 'visible'}}>
                      {model.enabled ? (
                        <ChipsStatus title="Active" type="success" />
                      ) : (
                        <ChipsStatus title="Inactive" type="error" />
                      )}
                    </td>
                    <td width={headers.action.width}>
                      <div className={shared.controlIcons}>
                        <ActionIcon
                          icon="edit"
                          onClick={() => modifyUser(model.id)}
                          hidden={!isRoleAction({ key: 'project', role: 'update' })}
                        />
                        <ActionIcon
                          icon="trash"
                          onClick={() => deleteUser(model)}
                          hidden={!isRoleAction({ key: 'project', role: 'delete' })}
                        />
                      </div>
                      <div className={shared.controlIconsHideList}>
                          <span>{model.createdAt ? formatTimestamp(model.createdAt) : ''}</span>
                      </div>
                    </td>
                  </tr>
                )
              ))}
            </tbody>
          ) : null}
        </table>
        {loading && (
        <div className={shared.emptyContent}>
          <Loader size="large" />
        </div>
        )}
        {!users?.length && searchValue && !loading && (
          <div className={shared.emptyContent}>
            <h3 className={shared.caption}>No Users found</h3>
            <p>
              Please change your search query and try again
            </p>
          </div>
        )}
        {!users?.length && !searchValue && !loading && (
          <div className={shared.emptyContent}>
            <h3 className={shared.caption}>This project hasn’t Users yet</h3>
            <p>
              The available Users for this project will be displayed here.
            </p>
            <div className={shared.action}>
              <ActionText
                title="Click here to add User"
                onClick={createUser}
              />
            </div>
          </div>
        )}
        {removeModalOpened && (
          <RemoveUserModal
            setIsOpen={setRemoveModalOpened}
            user={{
              id: activeProject.id,
              userId: activeModel.id,
              firstName: activeModel.firstName,
              lastName: activeModel.lastName,
              email: activeModel.email
            }}
          />
        )}
      </div>
    </div>
  );
}

const mapStateToProps = ({ users, auth: { currentUser }, activeProject, roles }) => ({
  users,
  roles,
  currentUser,
  activeProject
});

const mapDispatchToProps = (dispatch) => ({
  getUsers: (data, callback) => dispatch(listUsersRequest(data, callback)),
  searchUsers: (data) => dispatch(searchUsersRequest(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(isRoleAction(UsersList));

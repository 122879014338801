/* eslint-disable no-restricted-syntax */
import {
  call, put, takeEvery, all, fork, select, takeLatest,
} from 'redux-saga/effects';
import { api, apiWorker, apiWorkerFile } from '../../helpers/api';
import {
  listAuthKeysRequest, listAuthKeysSuccess,
  searchAuthKeysRequest, searchAuthKeysSuccess,
  createAuthKeyRequest, createAuthKeySuccess,
  removeAuthKeysListRequest, removeAuthKeysListSuccess,
  updateAuthKeyRequest, updateAuthKeySuccess,
  configAuthKeysRequest, configAuthKeysSuccess,
  exportAuthKeyRequest, importAuthKeyRequest,
  removeAuthKeyFolderRequest, removeAuthKeyFolderSuccess,
  updateAuthKeyFolderRequest, updateAuthKeyFolderSuccess,
  createAuthKeyFolderRequest, createAuthKeyFolderSuccess,
  getAuthKeyFolderContentRequest, getAuthKeyFolderContentSuccess,
  moveAuthKeyFolderRequest, moveAuthKeyFolderSuccess,
  moveAuthKeyRequest, moveAuthKeySuccess,
  copyAuthKeyFolderRequest, copyAuthKeyFolderSuccess,
  getAuthKeyFolderTreeRequest, getAuthKeyFolderTreeSuccess,
} from './actions';
import { createToastRequest } from '../toasts/actions';
import {
  refreshRequest,
} from '../auth/actions';
import { AuthKeys } from './constants';
import history from '../../history';

const getAuth = (state) => state.auth?.auth;

export function* fetchAuthKeys(ops) {
  const { data, callback } = ops;
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(api, `/auth-keys?projectId=${data.id}`, 'GET', null, authData.access_token);
      if (response.ok) {
        const parsed = yield response.json();
        yield put(listAuthKeysSuccess(parsed.items));
        if (callback) callback();
      } else if (response.status === 401) {
        yield put(refreshRequest(listAuthKeysRequest, data, callback));
        if (callback) callback(true);
      } else {
        const parsed = yield response.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
        if (callback) callback(true);
      }
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* searchAuthKeys(ops) {
  const { data, callback } = ops;
  const { projectId, query } = data;
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(api, `/search?projectId=${projectId}&query=${query}&deleted=false&filter=folderId,key&folderType=keys`, 'GET', null, authData.access_token);
      if (response.ok) {
        const parsed = yield response.json();
        yield put(searchAuthKeysSuccess(parsed));
        if (callback) callback(true);
      } else if (response.status === 401) {
        yield put(refreshRequest(searchAuthKeysRequest, data, callback));
      } else {
        const parsed = yield response.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
        if (callback) callback(false);
      }
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
    if (callback) callback(false);
  }
}

export function* createAuthKey(ops) {
  const { data, callback } = ops;
  const { rootAction, ...otherData } = data || {};
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(api, '/auth-keys', 'POST', otherData, authData.access_token);
      if (response.ok) {
        const parsed = yield response.json();
        parsed.role = 'owner';
        yield put(createAuthKeySuccess({ ...parsed, rootAction }));
        yield put(createToastRequest({
          type: 'success',
          title: parsed.name,
          text: 'auth key has been created successfully',
        }));
        if (callback) callback(parsed);
      } else if (response.status === 401) {
        yield put(refreshRequest(createAuthKeyRequest, data, callback));
      } else {
        const parsed = yield response.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
      }
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* removeAuthKeysList(ops) {
  const { data, callback } = ops;
  const { authKeys: authKey, folders: fld } = data || {};
  const authData = yield select(getAuth);
  let successFolders;
  let successAuthKeys;

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const [folders, authKeys] = yield all([
        fld?.length ? call(api, '/folders', 'PUT', { items: fld.map(f => ({ ...f, deleted: true, items: undefined })) }, authData.access_token) : null,
        authKey?.length ? call(api, '/auth-keys', 'PUT', { items: authKey.map(c => ({ ...c, deleted: true })) }, authData.access_token) : null,
      ]);
      if (folders || authKeys) {
        if (folders?.ok) {
          const parsed = yield folders.json();
          successFolders = fld;
          if (parsed.errors && parsed.errors.length > 0) {
            for (const e of parsed.errors) {
              successFolders = successFolders.filter((folder) => folder.id !== e.id);
              yield put(createToastRequest({ type: 'error', text: e.error }));
            }
          }
          if (successFolders && successFolders.length) {
            for (const el of successFolders) {
              yield put(removeAuthKeyFolderSuccess(el));;
            }
            if (successFolders.length > 1) {
              yield put(createToastRequest({ type: 'success', title: successFolders.length, text: 'folders were removed' }));
            } else {
              yield put(createToastRequest({ type: 'success', title: successFolders[0].name, text: 'folder was removed' }));
            }
          }
        }
        if (authKeys?.ok) {
          const parsed = yield authKeys.json();
          successAuthKeys = authKey;
          if (parsed.errors && parsed.errors.length > 0) {
            for (const e of parsed.errors) {
              successAuthKeys = successAuthKeys.filter((key) => key.id !== e.id);
              yield put(createToastRequest({ type: 'error', text: e.error }));
            }
          }
          if (successAuthKeys && successAuthKeys.length) {
            for (const el of successAuthKeys) {
              yield put(removeAuthKeysListSuccess(el));
            }
            if (successAuthKeys.length > 1) {
              yield put(createToastRequest({ type: 'success', title: successAuthKeys.length, text: 'auth keys were removed' }));
            } else {
              yield put(createToastRequest({ type: 'success', title: successAuthKeys[0].name, text: 'auth key was removed' }));
            }
          }
        }
        if (callback) callback({ successFolders, successAuthKeys });
      } else if (folders?.status === 401 || authKeys?.status === 401) {
        yield put(refreshRequest(removeAuthKeysListRequest, data, callback));
      } else {
        const parsedFolders = yield folders ? folders.json() : null;
        const parsedAuthKeys = yield authKeys ? authKeys.json() : null;

        if (callback) callback(false);
        if (parsedFolders) yield put(createToastRequest({ type: 'error', text: parsedFolders?.error, code: parsedFolders?.code }));
        if (parsedAuthKeys) yield put(createToastRequest({ type: 'error', text: parsedAuthKeys?.error, code: parsedAuthKeys?.code }));
      }
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* updateAuthKey(ops) {
  const { data, callback } = ops;
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(api, '/auth-keys', 'PUT', data, authData.access_token);
      if (response.ok) {
        const parsed = yield response.json();
        yield put(updateAuthKeySuccess(parsed));
        yield put(createToastRequest({
          type: 'success',
          title: data.name,
          text: 'auth key was updated',
        }));
        if (callback) callback();
      } else if (response.status === 401) {
        yield put(refreshRequest(updateAuthKeyRequest, data, callback));
      } else {
        const parsed = yield response.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
      }
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* copyAuthKey(ops) {
  const { data, callback } = ops;
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      // get access
      const response = yield call(api, `/access/users?resource=key&id=${data.id}`, 'GET', null, authData.access_token);
      if (response.ok) {
        const parsedAccess = yield response.json();
        // create endpoint
        yield put(createAuthKeyRequest(
          {
            ...data,
            name: `${data.name}(copy)`,
          },
          (connection) => {
            callback && callback(parsedAccess, connection.id);
          },
        ));
      } else if (response.status === 401) {
        yield put(refreshRequest(createAuthKeyRequest, data));
      } else {
        const parsed = yield response.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
      }
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* exportAuthKey(ops) {
  const { data, callback } = ops;
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(apiWorker, '/export', 'POST', data, authData.access_token);
      if (response.ok) {
        const parsedAccess = yield response.json();
        if (parsedAccess.file) {
          yield put(createToastRequest({ type: 'success', text: 'Export in progress... Don\'t refresh this page.' }));
          const file = yield call(apiWorker, `/export/file/${parsedAccess.file}`, 'GET', null, authData.access_token);
          const contentDisposition = file.headers.get('Content-Disposition');
          const filename = contentDisposition.match(/filename="(.+)"/)[1];
          file.blob().then((blob) => {
            callback && callback({file: blob, name: filename });
          });
        } else {
          yield put(createToastRequest({ type: 'error', text: 'Error export on first step' }));
        }
      } else if (response.status === 401) {
        yield put(refreshRequest(exportAuthKeyRequest, data));
      } else {
        const parsed = yield response.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
      }
    }
  } catch (e) {
    console.log('e', e);
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* importAuthKey(ops) {
  const { data, callback } = ops;
  const {
    projectId, linkApi, authKeys, file,
  } = data;
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(apiWorkerFile, `/import/file?projectId=${projectId}&linkApi=${linkApi}&id=${authKeys}&resource=key`, authData.access_token, file);
      const parsed = JSON.parse(response);
      if (parsed.status === 'success') {
        yield put(createToastRequest({ type: 'success', text: 'AuthKeys have been added to the list' }));
        callback && callback(parsed);
      } else if (parsed.status === 401) {
        yield put(refreshRequest(importAuthKeyRequest, data));
      } else {
        yield put(createToastRequest({ type: 'error', text: parsed.msg }));
      }
    }
  } catch (e) {
    console.log('e', e);
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* configAuthKeys() {
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(api, '/connections-types', 'GET', null, authData.access_token);
      if (response.ok) {
        const parsed = yield response.json();
        yield put(configAuthKeysSuccess(parsed));
      } else if (response.status === 401) {
        yield put(refreshRequest(configAuthKeysRequest));
      } else {
        const parsed = yield response.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
      }
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* createAuthKeyFolder(ops) {
  const { data, callback } = ops;
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(api, '/folders', 'POST', data, authData.access_token);
      if (response.ok) {
        const parsed = yield response.json();

        yield put(createAuthKeyFolderSuccess(parsed));

        yield put(createToastRequest({
          type: 'success',
          title: parsed.name,
          text: 'folder has been created successfully',
        }));
        if (callback) callback(parsed);
      } else if (response.status === 401) {
        yield put(refreshRequest(createAuthKeyFolderRequest, data, callback));
      } else {
        const parsed = yield response.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
      }
    }
  } catch (e) {
    console.log('e', e);
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* getAuthKeyFolder(ops) {
  const { data, callback } = ops;
  const { merge } = data || {};
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const [folders, connections] = yield all([
        call(api, `/folders?folderId=${data.folder}&type=keys&projectId=${data.projectId}`, 'GET', null, authData.access_token),
        call(api, `/auth-keys?folderId=${data.folder}&projectId=${data.projectId}`, 'GET', null, authData.access_token),
      ]);
      if (folders.ok && connections.ok) {
        const parsedFolders = yield folders.json();
        const parsedAuthKeys = yield connections.json();

        yield put(getAuthKeyFolderContentSuccess({
          merge,
          folderId: data.folder,
          folders: parsedFolders.next,
          data: parsedAuthKeys.items,
          breadscrumbs: parsedFolders.prev,
        }));

        if (callback) {
          callback({
            merge,
            folderId: data.folder,
            folders: parsedFolders.next,
            data: parsedAuthKeys.items,
            breadscrumbs: parsedFolders.prev,
          });
        }
      } else if (folders.status === 401 || connections.status === 401) {
        yield put(refreshRequest(getAuthKeyFolderContentRequest, data, callback));
      } else {
        const parsedFolders = yield folders.json();
        const parsedAuthKeys = yield connections.json();
        yield put(createToastRequest({
          type: 'error',
          text: parsedFolders?.error || parsedAuthKeys?.error,
          code: parsedFolders?.code || parsedAuthKeys?.error,
        }));
      }
    }
  } catch (e) {
    console.log('e', e);
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* updateAuthKeyFolder(ops) {
  const { data, callback } = ops;
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(api, '/folders', 'PUT', {
        id: data.id,
        projectId: data.projectId,
        name: data.name,
        type: data.type,
        parentId: data.parentId,
      }, authData.access_token);
      if (response.ok) {
        yield put(createToastRequest({
          type: 'success',
          title: data.name,
          text: 'folder was updated',
        }));
        yield put(updateAuthKeyFolderSuccess(data));
        if (callback) callback();
      } else if (response.status === 401) {
        yield put(refreshRequest(updateAuthKeyFolderRequest, data, callback));
      } else {
        const parsed = yield response.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
      }
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* moveAuthKeyFolder(ops) {
  const { data, callback } = ops;
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(api, '/folders', 'PUT', {
        id: data.id,
        projectId: data.projectId,
        name: data.name,
        type: data.type,
        parentId: data.parentId,
      }, authData.access_token);
      if (response.ok) {
        yield put(createToastRequest({
          type: 'success',
          title: data.name,
          text: 'folder was moved',
        }));
        yield put(moveAuthKeyFolderSuccess(data));
        if (callback) callback();
      } else if (response.status === 401) {
        yield put(refreshRequest(moveAuthKeyFolderRequest, data, callback));
      } else {
        const parsed = yield response.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
      }
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* moveAuthKey(ops) {
  const { data, callback } = ops;
  const { rootAction, ...otherData } = data || {};
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(api, '/auth-keys', 'PUT', otherData, authData.access_token);
      if (response.ok) {
        yield put(moveAuthKeySuccess({ ...otherData, rootAction }));
        yield put(createToastRequest({
          type: 'success',
          title: otherData.name,
          text: 'auth key was moved',
        }));
        if (callback) callback();
      } else if (response.status === 401) {
        yield put(refreshRequest(moveAuthKeyRequest, data, callback));
      } else {
        const parsed = yield response.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
      }
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* copyAuthKeyFolder(ops) {
  const { data, callback } = ops;
  const { rootAction } = data || {};
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(api, '/folders/copy', 'POST', {
        folderId: data.id,
        toFolderId: data.parentId,
        name: `${data.name}(copy)`,
      }, authData.access_token);
      if (response.ok) {
        const parsed = yield response.json();
        yield put(createToastRequest({
          type: 'success',
          title: data.name,
          text: 'folder was copied',
        }));
        yield put(copyAuthKeyFolderSuccess({ ...parsed.folderId, rootAction }));
        if (callback) callback();
      } else if (response.status === 401) {
        yield put(refreshRequest(copyAuthKeyFolderRequest, data, callback));
      } else {
        const parsed = yield response.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
      }
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* getTree(ops) {
  const { data, callback } = ops;
  const { ids } = data || {};
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const parsedResponses = [];

      for (const id of ids) {
        const response = yield call(api, `/tree?id=${id}`, 'GET', null, authData.access_token);

        if (response.ok) {
          const parsed = yield response.json();
          parsedResponses.push(parsed);
          yield put(getAuthKeyFolderTreeSuccess(parsed));
        } else if (response.status === 401) {
          yield put(refreshRequest(getAuthKeyFolderTreeRequest, data, callback));
          break;
        } else {
          const parsed = yield response.json();
          yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
          if (callback) callback();
          break;
        }
      }

      if (ids.length === parsedResponses.length && callback) {
        if (callback) callback(parsedResponses);
      } else if (callback) callback();
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
    if (callback) callback();
  }
}

function* AuthKeysSaga() {
  yield takeEvery(AuthKeys.listAuthKeysRequest, fetchAuthKeys);
  yield takeEvery(AuthKeys.searchAuthKeysRequest, searchAuthKeys);
  yield takeEvery(AuthKeys.createAuthKeyRequest, createAuthKey);
  yield takeEvery(AuthKeys.copyAuthKeyRequest, copyAuthKey);
  yield takeEvery(AuthKeys.exportAuthKeyRequest, exportAuthKey);
  yield takeEvery(AuthKeys.importAuthKeyRequest, importAuthKey);
  yield takeEvery(AuthKeys.removeAuthKeysListRequest, removeAuthKeysList);
  yield takeEvery(AuthKeys.updateAuthKeyRequest, updateAuthKey);
  yield takeEvery(AuthKeys.configAuthKeysRequest, configAuthKeys);
  yield takeEvery(AuthKeys.createAuthKeyFolderRequest, createAuthKeyFolder);
  yield takeEvery(AuthKeys.updateAuthKeyFolderRequest, updateAuthKeyFolder);
  yield takeLatest(AuthKeys.getAuthKeyFolderContentRequest, getAuthKeyFolder);
  yield takeEvery(AuthKeys.moveAuthKeyFolderRequest, moveAuthKeyFolder);
  yield takeEvery(AuthKeys.moveAuthKeyRequest, moveAuthKey);
  yield takeEvery(AuthKeys.copyAuthKeyFolderRequest, copyAuthKeyFolder);
  yield takeEvery(AuthKeys.getAuthKeyFolderTreeRequest, getTree);
}

function* configSaga() {
  yield all([fork(AuthKeysSaga)]);
}

export default configSaga;

@use "sass:map";
@import '../../styles/variables.scss';

.inputWrapper {
    position: relative;

    &.inputVariables {
        height: 36px;
    }
}

.iconLeft {
    position: absolute;
    left: 11px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
}

.iconRight {
    position: absolute;
    right: 11px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    display: flex;
    align-items: center;

    &:hover {
        svg * {
            fill: map.get($colors-basic, "primaryBlue");
        }
    }

    &.iconRightMultiline {
        top: 11px;
        transform: translateY(0);
    }
}

.iconRightStatus {
    position: absolute;
    right: 11px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
}

.isPassword {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    display: flex;
    align-items: center;

    &:hover {
        svg * {
            stroke: map.get($colors-basic, "primaryBlue");
        }
    }
}


// for clear autofill useragent styles
input {
    outline: none;
}

input:-webkit-autofill {
    -webkit-box-shadow: inset 0 0 0 50px map.get($colors-basic, "inputGrey") !important;
}

.input {
    width: 100%;
    box-sizing: border-box;
    color: map.get($colors-basic, "textBlack");
    border: none;
    outline: none;
    font-size: map.get($font-sizes, "body");
    background-color: map.get($colors-basic, "inputGrey");
    line-height: 34px;
    padding: 0 12px;
    border: 1px solid map.get($colors-basic, "inputGrey");
    border-radius: 4px;

    &:disabled {
        &:hover {
            background-color: map.get($colors-basic, "inputGrey") !important;
            border: 1px solid map.get($colors-basic, "inputGrey") !important;
        }
    }

    &.filled {
        &:hover {
            border: 1px solid map.get($colors-basic, "inputGrey");
        }

        &:focus {
            background-color: white;
            border: 1px solid map.get($colors-basic, "primaryBlue");
        }
    }

    &::placeholder {
        color: map.get($colors-basic, "textGray")
    }

    &:hover {
        background-color: map.get($colors-basic, "selectedOption");
        border: 1px solid map.get($colors-basic, "primaryBlue");
    }

    &:focus {
        background-color: white;
        border: 1px solid map.get($colors-basic, "primaryBlue");
    }

    &.error {
        border: 1px solid map.get($colors-red, 100) !important;
        background-color: #FEF4F4 !important;
    }

    svg * {
        fill: white;
    }

    &.leftPadding {
        padding-left: 36px;
    }

    &.rightPadding {
        padding-right: 36px;
    }
}

.fakePassword {
    text-security: disc;
    -webkit-text-security: disc;
    -moz-text-security: disc;
}

@use "sass:map";
@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.modal {
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.7); /* Overlay effect: translucent background: black w/ partial opacity */
    z-index: 9999; /* Overlay effect: positioned over other containers */
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    position: fixed; /* Fix position on the top-left corner*/
    top: 0;
    left: 0;
    overflow: auto; /* Enable scroll if needed */
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        width: 0;
        height: 0;
    }
    padding-top: 60px; /* Location of the content container */
}
/* Modal content */
.modalContent {
    background-color: #fff;
    width: 70%; /* Width in proportion to its parent container*/
    max-width: 640px; /* Max width where it stops expanding */
    height: auto; /* Height in proportion to its parent container */
    margin: auto; /* Auto margin according to the element width */
    border-radius: 10px;

    @include zoomInAnimation;
}

.header {
    padding: 18px 20px;
    border-bottom: 1px solid map.get($colors-basic, "divider");
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
        font-weight: map.get($font-weights, "semibold");
    }

    .steps {

        .activeStep {
            font-weight: map.get($font-weights, "semibold");
            color: map.get($colors-basic, "primaryBlue");
        }

    }
}

.body {
    padding: 16px 20px 20px 20px;
    background-color: map.get($colors-basic, "sideMenu");
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    .mainContent {
        display: flex;
        flex-direction: column;
        padding: 8px 0 51px 0;
        position: relative;

        .inviteLabel {
            position: absolute;
            top: 17px;
            left: 0;
            font-weight: map.get($font-weights, "semibold");
        }

        .selectRole {
            width: 95px;
            position: absolute;
            right: 12px;
            top: 8px;
        }

        .inputWrapper {
            padding-top: 10px;
            max-height: 200px;
            overflow: auto;
            border-bottom: 1px solid map.get($colors-basic, "textDisabled");
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 8px;
            box-sizing: border-box;
            margin-bottom: 20px;

            &.inputFocused {
                border-bottom: 1px solid map.get($colors-basic, "primaryBlue");
            }

            &.withControls {
                padding-right: 96px;
                padding-left: 30px;

                input {
                    width: unset;
                    flex-grow: 1;
                }
            }

        }

        .listTitle {
            font-weight: 600;
            font-size: 14px;
            line-height: 19px;
        }

        .listWrapper {
            max-height: 300px;
            overflow: auto;
            -ms-overflow-style: none;
            scrollbar-width: none;
            &::-webkit-scrollbar {
                width: 0;
                height: 0;
            }
            width: calc(100% + 40px);
            margin-left: -20px;

            .listItem {
                display: flex;
                flex-direction: row;
                align-items: center;
                width: 100%;
                padding: 12px 20px 13px 20px;
                border-bottom: 1px solid map.get($colors-basic, "divider");
                cursor: pointer;
                box-sizing: border-box;
                
                &:hover {
                    background-color: map.get($colors-basic, "selectedOption");
                }
            }

            .listChip {
                margin-right: 20px;
            }
        }

        input {
            width: 100%;
            max-width: 100%;
            border: 0;
            outline: 0;
            padding-bottom: 19px;
            padding-left: 8px;
            background-color: transparent;

            &::placeholder {
                color: map.get($colors-basic, "textGray");
            }
        }
    }

    .buttonGroup {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .buttonGroupCol {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }
}
/* Close button */
/* eslint-disable max-len */
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import cn from 'classnames';
import Button from '../../components/Button/Button';
import ActionIcon from '../../components/ActionIcon/ActionIcon';
import ActionText from '../../components/ActionText/ActionText';
import Directory from './atoms/Directory/Directory';
import CreateFolderModal from '../../components/CreateFolderModal';
import RenameFolderModal from '../../components/RenameFolderModal';
import Breadscrumbs from '../../components/Breadscrumbs';
import RemoveModal from '../../components/RemoveEndpointsModal';
import InviteToResourceModal from '../../components/InviteToResourceModal/InviteToResourceModal';
import ExportModal from '../../components/ExportEndpointModal/ExportEndpointModal';
import Loader from '../../components/Loader';
import Input from '../../components/Input/Input';
import Checkbox from '../../components/Checkbox/Checkbox';
import {
  searchEndpointsRequest,
  copyEndpointRequest,
  exportEndpointRequest,
  getEndpointFolderContentRequest,
  moveEndpointFolderRequest,
  copyEndpointFolderRequest,
  moveEndpointRequest,
} from '../../store/endpoints/actions';
import { invitesRequest, addAccessRequest } from '../../store/invites/actions';
import { createToastRequest } from '../../store/toasts/actions';
import {
  getAllData, getAllfoldersData,
  getFoldersDataInFolder, getDataInFolder,
  checker, getDataPath, getFolderPath
} from '../../helpers/utils';
import { backendHost } from '../../helpers/const';
import { useCopyToClipboard } from '../../hooks/hooks';
import { isRoleAction } from '../../helpers/hocs';

import SortIcon from '../../pictures/sort.svg';

import 'react-tooltip/dist/react-tooltip.css';
import * as shared from '../../styles/shared.module.scss';
import * as style from './ApisList.module.scss';
import { folderHeightPixels, itemsHeightPixels, itemsWidthPixels } from '../../components/ContextMenu';
import Select from '../../components/Select/Select';

import PostmanImg from '../../pictures/export/postman.svg';
import SwaggerImg from '../../pictures/export/swagger.svg';
import JSONImg from '../../pictures/export/json.svg';

const headers = {
  checkbox: { title: '', width: '7%' },
  name: { title: 'Name', width: '20%' },
  method: { title: 'Method', width: '11%' },
  endpoint: { title: 'Endpoint', width: '14%' },
  connection: { title: 'Connection', width: '12%' },
  owner: { title: 'Owner', width: '12%' },
  status: { title: 'Status', width: '9%' },
  action: { title: 'Created', width: '11%' },
};

let searchTimeout = null;

const exportList = [
  {
  title: 'Export to Postman',
  value: 'postman',
  icon: <PostmanImg />,
},
 {
  title: 'Export to Swagger',
  value: 'swagger',
  icon: <SwaggerImg />,
},
{
  title: 'Export to JSON',
  value: 'json',
  icon: <JSONImg />,
},].map((item) => ({ ...item, component: <div className={style.exportItem}>{item.icon} {item.title}</div> }));

function ApisList({
  endpoints,
  getFolder,
  copyFolder,
  sendInvite,
  createToast,
  activeProject,
  searchEndpoints,
  isRoleAction,
  copyEndpoint,
  exportEndpoint,
  currentUser,
  addAccess,
  moveEndpointFolder,
  moveEndpoint,
}) {
  const [loading, setLoading] = useState(true);
  const { shortName, folderId = 0 } = useParams();
  const [activeModel, setActiveModel] = useState({});

  const [createFolderModalOpened, setCreateFolderModalOpened] = useState(false);
  const [renameFolderModalOpened, setRenameFolderModalOpened] = useState(false);

  const [removeModalOpened, setRemoveModalOpened] = useState(false);
  const [shareModalOpened, setShareModalOpened] = useState(false);
  const [inputVisible, setInputVisible] = useState(false);
  const [copiedText, copyText] = useCopyToClipboard();
  const [exportModalOpened, setExportModalOpened] = useState({opened: false, type: ''});
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedFolders, setSelectedFolders] = useState([]);
  const { data: endpointsData = [], foldersData = [], breadscrumbData = [] } = endpoints || {};
  const [sortBy, setSortBy] = useState('name');
  const [searchValue, setSearchValue] = useState('');
  const [seachActive, setSearchActive] = useState(false);
  const ref = useRef(null);
  const tableRef = useRef(null);
  const navigate = useNavigate();

  const [contextMenuModelActive, setContextMenuModelActive] = useState(false);
  const [contextMenuTop, setContextMenuTop] = useState(0);
  const [contextMenuLeft, setContextMenuLeft] = useState(0);

  const handleContextMenu = (event, id, isFolder) => {
    event.preventDefault();

    setContextMenuModelActive(id);

    const { clientX, clientY } = event;

    const neededHeight = isFolder ? folderHeightPixels : itemsHeightPixels;
    const neededWidth = itemsWidthPixels;

    const w = window.innerWidth;
    const h = window.innerHeight;

    const isHeightFit = (h - clientY) > neededHeight;
    const isWidthFit = (w - clientX) > neededWidth;

    setContextMenuTop(isHeightFit ? clientY : clientY - neededHeight);
    setContextMenuLeft(isWidthFit ? clientX : clientX - neededWidth);
  };

  useEffect(() => {
    setLoading(true);
    if (activeProject?.id) {
      setSelectedItems([]);
      setSelectedFolders([]);

      getFolder({
        folder: folderId || 0,
        projectId: activeProject.id,
      }, () => {
        setLoading(false);
      });
    }
  }, [activeProject?.id, folderId, shortName]);

  const copyCon = (path) => {
    copyText(`${backendHost}/${activeProject.shortName}${path}`)
            && createToast({ type: 'success', text: 'Copied' });
  };

  const createFolder = () => setCreateFolderModalOpened(true);

  const createAPI = () => (folderId ? navigate(`/${shortName}/endpoints/folder/${folderId}/create`) : navigate(`/${shortName}/endpoints/create`));
  const importAPI = () => navigate(`/${shortName}/endpoints/import`);
  const modifyAPI = (endpointId) => navigate(`/${shortName}/endpoints/${endpointId}`, { state: { redirectFolder: +folderId || 0 } });
  const openFolder = (f) => navigate(`/${shortName}/endpoints/folder/${f}`);

  const renameFolder = (model) => {
    setContextMenuModelActive(false);
    setActiveModel(model);
    setRenameFolderModalOpened(true);
  };

  const changeSearch = (e) => {
    setSearchValue(e.target.value);
    if (searchTimeout) clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => {
      setLoading(true);
      setSelectedItems([]);
      setSelectedFolders([]);
      if (e.target.value) {
        searchEndpoints({
          query: e.target.value,
          projectId: activeProject.id,
        }, () => {
          setLoading(false);
          setSearchActive(true);
        });
      } else {
        getFolder({
          folder: folderId || 0,
          projectId: activeProject?.id,
        },() => {
          setLoading(false);
          setSearchActive(false);
        });
      }
    }, 400);
  };

  const copyAPI = (model) => {
    copyEndpoint({
      ...model,
      rootAction: +model.folderId === +folderId,
    });
  };

  const copyFolderAction = (model) => {
    copyFolder({
      ...model,
      rootAction: +model.parentId === +folderId,
    });
  };

  const removeOne = (type, model) => {
    setActiveModel({
      endpoints: type === 'endpoint' ? [model.id] : [],
      folders: type === 'folder' ? [model.id] : [],
    });
    setRemoveModalOpened(true);
  };

  const removeSelected = () => {
    setActiveModel({
      endpoints: selectedItems,
      folders: selectedFolders,
    });
    setRemoveModalOpened(true);
  };

  const clickShare = (model) => {
    setActiveModel(model);
    setShareModalOpened(true);
  };

  const exportSelected = (value) => {
    setActiveModel({
      endpoints: selectedItems,
      folders: selectedFolders,
    });
    setExportModalOpened({
      opened: true,
      type: value || 'json',
    });
  };

  const exportOne = (type, model) => {
    setActiveModel({
      endpoints: type === 'endpoint' ? [model.id] : [],
      folders: type === 'folder' ? [model.id] : [],
    });
    setExportModalOpened({
      opened: true,
      type: 'json',
    });
  };

  const clear = () => {
    setSearchValue('');
    setLoading(true);
    setSelectedItems([]);
    setSelectedFolders([]);
    getFolder({
      folder: folderId || 0,
      projectId: activeProject?.id,
    }, () => {
      setLoading(false);
      setSearchActive(false);
    });
    setInputVisible(false);
  };

  const sendInviteAction = (data) => {
    // sendInvite(data, () => {
    //   setShareModalOpened(false);
    // });
  };

  const handleClickOutside = (event) => {
    if (ref && ref.current && !ref.current.contains(event.target)) {
      const input = ref.current.getElementsByTagName('input')[0];
      if (input.value) return;
      setInputVisible(false);
    }

    if (tableRef && tableRef.current && !tableRef.current.contains(event.target)) {
      setContextMenuModelActive(false);
    }
  };

  const exportE = (list, linkConnections = false, linkKeys = false, linkVariables = false, type = 'json') => {
    const { endpoints: endp, folders: fld } = list || {};
    exportEndpoint({
      projectId: activeProject?.id,
      linkConnections,
      linkKeys,
      linkVariables,
      resource: 'endpoint',
      objects: [
        ...endp.map((el) => ({ id: el, type: 'endpoint' })),
        ...fld.map((el) => ({ id: el, type: 'folder' })),
      ],
      type
    }, async (props) => {
      const { file, name } = props || {};
      const url = window.URL.createObjectURL(file);
      const a = document.createElement('a');
      a.href = url;
      a.download = name;
      a.click();
      });
  };

  const selectAll = (isCheked) => {
    if (isCheked) {
      const allIds = getAllData(endpoints);
      const allIdsFolders = getAllfoldersData(endpoints);
      setSelectedItems(allIds);
      setSelectedFolders(allIdsFolders);
    } else {
      setSelectedItems([]);
      setSelectedFolders([]);
    }
  };

  const selectOne = (isCheked, id) => {
    if (isCheked) {
      setSelectedItems([...selectedItems, id]);
    } else {
      const path = getDataPath(endpoints, id) || [];
      setSelectedItems(selectedItems.filter((c) => c !== id));
      setSelectedFolders(selectedFolders.filter((c) => !path.includes(c)));
    }
  };

  const selectOneFolder = (isCheked, id) => {
    const nestedFolders = getFoldersDataInFolder(endpoints, id) || [];
    const nestedObjs = getDataInFolder(endpoints, id) || [];

    if (isCheked) {
      setSelectedFolders([...selectedFolders, ...[id, ...nestedFolders]]);

      setSelectedItems([...selectedItems, ...nestedObjs]);
    } else {
      const path = getFolderPath(endpoints, id) || [];
      setSelectedFolders(selectedFolders.filter((c) => !nestedFolders.includes(c) && c !== id && !path.includes(c)));
      setSelectedItems(selectedItems.filter((c) => !nestedObjs.includes(c)));
    }
  };

  const selectItems = (folders, items) => {
    setSelectedFolders([...selectedFolders, ...folders]);
    setSelectedItems([...selectedItems, ...items]);
  };

  const renderHeader = (h, i, arr) => {
    if (h === 'action' && (selectedItems.length > 0 || selectedFolders.length > 0)) {
      return (
        <th key={headers[h].title} style={{ justifyContent: 'flex-end' }}>
          <ActionIcon
            id="exportSelected"
            icon="export"
            style={{ width: '20px', marginRight: '16px' }}
            hiddenFill
            onClick={() => exportSelected()}
            tooltip={{
              content: 'Export',
            }}
          />
          <ActionIcon
            id="deleteSelected"
            icon="trash"
            onClick={() => removeSelected()}
            tooltip={{
              content: 'Delete',
            }}
          />
        </th>
      );
    } if (h !== 'checkbox') {
      return (
        <th key={headers[h].title}>
          <span>{headers[h].title}</span>
          {' '}
          <SortIcon />
        </th>
      );
    }
    return null;
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const moveFolder = (f, destination) => {
    setSelectedItems([]);
    moveEndpointFolder({
      ...f,
      projectId: activeProject?.id,
      parentId: destination,
      rootAction: +destination === +folderId,
    });
  };

  const moveObject = (o, destination) => {
    setSelectedItems([]);
    moveEndpoint({
      ...o,
      folderId: destination,
      rootAction: +destination === +folderId,
    });
  };

  const openFolderAction = (id) => {
    setSearchValue('');
    setSearchActive(false);
    setInputVisible(false);
    selectAll(false);
    openFolder(id);
  };

  const handleDropRoot = (e) => {
    const destinationId = +folderId || 0;
    e.preventDefault();
    e.stopPropagation();
    const data = e.dataTransfer.getData('model');
    const parsed = JSON.parse(data);

    if (parsed.type === 'endpoints' && parsed.id !== destinationId && parsed.parentId !== destinationId && parsed.folder !== destinationId) {
      moveFolder(parsed, destinationId);
    } else if (parsed.id !== destinationId && parsed.parentId !== destinationId && parsed.folder !== destinationId) {
      moveObject(parsed, destinationId);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <div className={shared.page}>
      <div className={shared.header}>
        {!folderId ? (
          <div className={shared.headerTitleGroup}>
            <h1 className={shared.headerTitle}>API List</h1>
            <p className={shared.headerDescription}>Manage API endpoints</p>
          </div>
        ) : <Breadscrumbs folders={breadscrumbData} type="endpoints" />}
        <div className={shared.headerButtonGroup}>
          {inputVisible ? (
            <div style={{
                flexGrow: 1,
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <div ref={ref} style={{ width: '100%', maxWidth: '426px'}}>
              <Input
                placeholder="Search"
                iconLeft="search"
                autoFocus
                iconRight={searchValue && 'close'}
                handleChange={changeSearch}
                value={searchValue}
                handleAction={clear}
              />
              </div>
            </div>
          ) : (
            <Button type="secondary" iconLeft="search" onClick={() => setInputVisible(true)} />
          )}
          {/* <Button
            id="export_btn"
            type="secondary"
            iconLeft="export"
            onClick={() => (selectedItems.length > 0 || selectedFolders.length > 0) && exportSelected()}
            style={{ width: '36px', padding: '0px', opacity: (selectedItems.length > 0 || selectedFolders.length > 0) ? '1' : '0.4' }}
            tooltip={{
              content: 'Export',
            }}
          /> */}
          <Button
            id="import_btn"
            type="secondary"
            title="Import"
            onClick={importAPI}
            tooltip={{
              content: 'Import',
            }}
          />
          <Select
            selectedTitleClass={style.exportItem}
            list={exportList}
            placeholder="Export"
            type="button"
            handleClickOption={(value) => (selectedItems.length > 0 || selectedFolders.length > 0) && exportSelected(value)}
            name="export"
            disabled={!(selectedItems.length > 0 || selectedFolders.length > 0)}
            style={{ width: '102px', opacity: !(selectedItems.length > 0 || selectedFolders.length > 0) ? 0.4 : 1 }}
          />
          <Button
            id="folder_btn"
            type="secondary"
            iconLeft="folder"
            onClick={createFolder}
            style={{ width: '36px', padding: '0px' }}
            tooltip={{
              content: 'Create folder',
            }}
          />
          {isRoleAction({ key: 'endpoint', role: 'create' })
            && <Button title="Create API" onClick={createAPI} />}
        </div>
      </div>
      <div className={shared.table}>
        <table
          className={shared.apiTable}
        >
          <thead>
            <tr className={shared.connectionTableRowEndpoints}>
              <th key="checkbox">
                <Checkbox
                  handleChange={selectAll}
                  size="small"
                  value={
                    (endpointsData?.length || foldersData?.length)
                    && checker(selectedItems, endpointsData)
                    && checker(selectedFolders, foldersData)
                  }
                  style={{ marginRight: '0' }}
                  disabled={!(endpointsData?.length || foldersData?.length)}
                />
              </th>
              {Object.keys(headers).map((h, i, arr) => renderHeader(h, i, arr))}
            </tr>
          </thead>
          {loading && (
            <div className={shared.emptyContent}>
              <Loader size="large" />
            </div>
          )}
          {(foldersData?.length || endpointsData?.length) && !loading ? (
            <tbody
              ref={tableRef}
              onDrop={(e) => handleDropRoot(e)}
              onDragOver={(e) => handleDragOver(e)}
              onClick={() => setContextMenuModelActive(false)}
              className={cn({ [shared.hideScroll]: contextMenuModelActive })}
            >
              <Directory
                foldersData={foldersData}
                endpointsData={endpointsData}
                selectedFolders={selectedFolders}
                selectedItems={selectedItems}
                handleContextMenu={handleContextMenu}
                setContextMenuModelActive={setContextMenuModelActive}
                openFolderAction={openFolderAction}
                selectOneFolder={selectOneFolder}
                copyFolder={copyFolderAction}
                selectItems={selectItems}
                contextMenuModelActive={contextMenuModelActive}
                contextMenuTop={contextMenuTop}
                contextMenuLeft={contextMenuLeft}
                modifyEndpoint={modifyAPI}
                copyEndpoint={copyAPI}
                selectOne={selectOne}
                renameFolder={renameFolder}
                clickShare={clickShare}
                isRoleAction={isRoleAction}
                exportOne={exportOne}
                removeOne={removeOne}
                getFolder={getFolder}
                moveFolder={moveFolder}
                moveObject={moveObject}
                copyCon={copyCon}
                activeProject={activeProject}
                searchActive={seachActive}
              />
            </tbody>
          ) : null}
        </table>
        {(!endpointsData?.length && !foldersData?.length) && searchValue && !loading && (
          <div className={shared.emptyContent}>
            <h3 className={shared.caption}>No APIs found</h3>
            <p>
              Please change your search query and try again
            </p>
          </div>
        )}
        {(!endpointsData?.length && !foldersData?.length) && !searchValue && !loading && (
          <div className={shared.emptyContent}>
            {folderId ? (
              <>
                <h3 className={shared.caption}>This folder is empty</h3>
                <p>
                   The available objects will be displayed here.
                </p>
              </>
            ) : (
              <>
                <h3 className={shared.caption}>This project has no APIs yet</h3>
                <p>
                  The available APIs for this project will be displayed here.
                </p>
                <div className={shared.action}>
                  <ActionText
                    title="Click here to create API"
                    onClick={createAPI}
                  />
                </div>
              </>
            )}

          </div>
        )}
        {createFolderModalOpened && (
          <CreateFolderModal
            setIsOpen={(status) => {
              setCreateFolderModalOpened(status);
            }}
            parent={Number(folderId)}
            activeProject={activeProject}
            type="endpoints"
          />
        )}
        {renameFolderModalOpened && (
          <RenameFolderModal
            setIsOpen={(status) => {
              setRenameFolderModalOpened(status);
            }}
            model={activeModel}
            activeProject={activeProject}
            type="endpoints"
          />
        )}
        {removeModalOpened && (
          <RemoveModal
            onClose={() => setRemoveModalOpened(false)}
            onSubmit={() => {
              setRemoveModalOpened(false);
              setSelectedItems([]);
              setSelectedFolders([]);
            }}
            model={activeModel}
            collection={endpoints}
          />
        )}
        {shareModalOpened && (
          <InviteToResourceModal
            id={activeModel.id}
            onClose={() => setShareModalOpened(false)}
            onSubmit={sendInviteAction}
            model={activeModel}
            type={activeModel?.type === 'endpoints' ? 'folder' : 'endpoint'}
            inviteList={[]}
          />
        )}
        {exportModalOpened.opened && (
          <ExportModal
            onClose={() => setExportModalOpened({
              opened: false,
              type: '',
            })}
            onSubmit={exportE}
            model={activeModel}
            collection={endpoints}
            type={exportModalOpened.type}
          />
        )}
      </div>
    </div>
  );
}

const mapStateToProps = ({
  endpoints, activeProject, auth: { currentUser }, roles,
}) => ({
  endpoints,
  roles,
  currentUser,
  activeProject,
});

const mapDispatchToProps = (dispatch) => ({
  getFolder: (data, callback) => dispatch(getEndpointFolderContentRequest(data, callback)),
  addAccess: (data, callback) => dispatch(addAccessRequest(data, callback)),
  copyEndpoint: (data, callback) => dispatch(copyEndpointRequest(data, callback)),
  copyFolder: (data, callback) => dispatch(copyEndpointFolderRequest(data, callback)),
  exportEndpoint: (data, callback) => dispatch(exportEndpointRequest(data, callback)),
  searchEndpoints: (data, callback) => dispatch(searchEndpointsRequest(data, callback)),
  sendInvite: (data, callback) => dispatch(invitesRequest(data, callback)),
  createToast: (data) => dispatch(createToastRequest(data)),
  moveEndpointFolder: (data, callback) => dispatch(moveEndpointFolderRequest(data, callback)),
  moveEndpoint: (data, callback) => dispatch(moveEndpointRequest(data, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(isRoleAction(ApisList));

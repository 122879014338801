@use "sass:map";
@import '../../styles/variables.scss';


.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.scrollConfig {
  ::-webkit-scrollbar {
    width: 14px;
    height: 14px;
  }

  ::-webkit-scrollbar-track {
    background-color: map.get($colors-basic, "inputGrey");
    width: 6px;
  }

  ::-webkit-scrollbar-corner {
    background-color: map.get($colors-basic, "inputGrey");
  }

  ::-webkit-scrollbar-thumb {
    background-color: map.get($colors-basic, "divider");
    border: 4px solid map.get($colors-basic, "inputGrey");
    border-radius: 24px;
  }

  scrollbar-width: thin;
  scrollbar-color: map.get($colors-basic, "divider") map.get($colors-basic, "inputGrey");
}

.scrollConfigInline {
  ::-webkit-scrollbar {
    width: 14px;
    height: 6px;
  }
}

.codeEditor {
  border: 1px solid map.get($colors-basic, "inputGrey");
  background-color: map.get($colors-basic, "inputGrey");
  border-radius: 4px;
  padding: 8px 0 4px 6px;
  position: relative;
  @extend .scrollConfig;

  &.error {
    border: 1px solid map.get($colors-red, 100);
    background-color: #FEF4F4;
    border-radius: 4px;
  }

  * {
    font-family: Menlo, monospace;
    font-size: 14px;
    line-height: 23px;
  }

  .expandWrapper {
    position: absolute;
    bottom: 12px;
    right: 12px;
    z-index: 100;
  }
}

.focusedEditor {
  border: 1px solid map.get($colors-basic, "primaryBlue");
  background-color: #fff;

  :global(.ace_content) {
    background-color: #fff !important;
  }

}


/* eslint-disable jsx-a11y/no-autofocus */
import React, { useEffect, useMemo, useState } from 'react';
import Button from '../Button/Button';
import * as styles from './ImportModal.module.scss';
import Checkbox from '../Checkbox/Checkbox';
import {
  KindBasedMap,
  TypesMap,
  PluralApiMap,
  PluralConnectionsMap,
  PluralKeyMap,
  PluralVariableMap,
  SingularApiMap,
} from './constants';

function ImportModal({
  onClose,
  onSubmit,
  list,
  typeImport
}) {
  const [linkApi, setLinkApi] = useState(false);
  const [linkConnection, setLinkConnection] = useState(false);
  const [linkKey, setLinkKey] = useState(false);
  const [linkVariable, setLinkVariable] = useState(false);

  const getMainText = (itemsList, kind) => {
    const isPlural = itemsList.length > 1;
    const TextEnum = isPlural ? KindBasedMap[kind].plural : KindBasedMap[kind].singular;
    return (
      <>
        <span className={styles.modelTitle}>
          {itemsList.length}
          {' '}
          {TextEnum.title}
        </span>
        {' '}
        <span>
          {TextEnum.toBe}
          {' '}
          selected for import.
        </span>
      </>
    );
  };

  const conflictedItems = useMemo(() => list[typeImport]
    .filter((item) => item.status === 'conflicted'), [list, typeImport]);

  const hasLinkedKeys = useMemo(
    () => list.keys?.length > 0,
    [list],
  );

  const hasLinkedConnections = useMemo(
    () => list?.connections?.length > 0,
    [list],
  );

  const hasLinkedApis = useMemo(
    () => list?.apis?.length > 0,
    [list],
  );

  const hasLinkedVariables = useMemo(
    () => list?.variables?.length > 0,
    [list],
  );

  const handleSubmit = () => {
    if (typeImport === 'apis') onSubmit(linkConnection, linkKey, linkVariable);
    if (typeImport === 'connections') onSubmit(linkApi, linkVariable);
    if  (typeImport === 'keys') onSubmit(linkApi);
    if (typeImport === 'variables') onSubmit(linkApi, linkConnection, linkKey);
    onClose();
  }

  const getTitle = (typeImport) => {
    if (typeImport === 'apis') return 'Import APIs';
    if (typeImport === 'connections') return 'Import Connections';
    if (typeImport === 'variables') return 'Import Variables';
    return 'Import Auth Keys';
  }

  useEffect(() => {
    if (hasLinkedApis) setLinkApi(hasLinkedConnections);
    if (hasLinkedConnections) setLinkConnection(hasLinkedConnections);
    if (hasLinkedKeys) setLinkKey(hasLinkedKeys);
    if (hasLinkedVariables) setLinkVariable(hasLinkedVariables);
  }, [hasLinkedKeys, hasLinkedConnections, hasLinkedApis, hasLinkedVariables]);

  return (
    <div className={styles.modal}>
      <div className={styles.modalContent}>
        <div className={styles.header}>
          <div className={styles.title}>
           {getTitle(typeImport)}
          </div>
        </div>
        <div className={styles.body}>
          <div className={styles.mainContent}>
            <div>
              <div>
                {getMainText(list.apis, TypesMap.Apis)}
              </div>
              <div>
                {getMainText(list.connections, TypesMap.Connections)}
              </div>
              <div>
                {getMainText(list.keys, TypesMap.Keys)}
              </div>
              <div>
                {getMainText(list.variables, TypesMap.Variables)}
              </div>
              <div>
                <span className={styles.modelTitle}>
                  {conflictedItems.length}
                  {' '}
                  {conflictedItems.length > 1 ? 
                   KindBasedMap[typeImport]?.plural?.title : 
                   KindBasedMap[typeImport]?.singular?.title}
                </span>
                <span>
                  {' '}
                  {' '}
                  {conflictedItems.length > 1 ? PluralApiMap.have : SingularApiMap.have}
                  {' '}
                  {conflictedItems.length > 1 ? PluralApiMap.conflict : SingularApiMap.conflict}
                  .
                </span>
              </div>
              <br />
              <span>Once you import models, they will be overrided with the new instances.</span>
            </div>
          </div>
          <div className={styles.buttonGroup}>
            <div className={styles.buttonGroup}>
              <div className={styles.checkboxGroupTitle}>
                <strong>
                  Import linked:
                </strong>
              </div>
              {typeImport !== 'apis' ? (
                <Checkbox
                    handleChange={hasLinkedApis ? setLinkApi : () => null}
                    label={(
                      <div>
                        {PluralApiMap.title}
                      </div>
                    )}
                    size="small"
                    value={linkApi}
                    disabled={!hasLinkedApis}
                    style={{ marginRight: 14 }}
                />
              ) : null}
              {(typeImport !== 'connections' && typeImport !== 'keys') ? (
                <Checkbox
                  handleChange={hasLinkedConnections ? setLinkConnection : () => null}
                  label={(
                    <div>
                      {PluralConnectionsMap.title}
                    </div>
                  )}
                  size="small"
                  value={linkConnection}
                  disabled={!hasLinkedConnections}
                  style={{ marginRight: 14 }}
                />
              ) : null}
              {(typeImport !== 'connections' && typeImport !== 'keys' && typeImport !== 'variables') ? (
                <Checkbox
                  handleChange={hasLinkedKeys ? setLinkKey : () => null}
                  label={(
                    <div>
                      {PluralKeyMap.title}
                    </div>
                  )}
                  size="small"
                  value={linkKey}
                  disabled={!hasLinkedKeys}
                  style={{ marginRight: 14 }}
                />
              ) : null}
              {(typeImport !== 'keys' && typeImport !== 'variables') ? (
                <Checkbox
                  handleChange={hasLinkedVariables ? setLinkVariable : () => null}
                  label={(
                    <div>
                      {PluralVariableMap.title}
                    </div>
                  )}
                  size="small"
                  value={linkVariable}
                  disabled={!hasLinkedVariables}
                  style={{ marginRight: 14 }}
                />
              ) : null}
            </div>
            <div className={styles.buttonGroup}>
              <Button style={{ width: 85 }} title="Cancel" type="secondary" onClick={onClose} />
              <Button
                style={{ width: typeImport === 'apis' ? 160 : 200, marginLeft: 12 }}
                title={getTitle(typeImport)}
                onClick={() => {
                  handleSubmit();
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ImportModal;

@use "sass:map";
@import './variables.scss';

.page {
    width: calc(100% - 200px);
}

.capitalized {
    text-transform: capitalize;
}

.uppercased {
    text-transform: uppercase;
}

.successText {
    color: map.get($colors-green, 100);
}

.errorText {
    color: map.get($colors-red, 100);
}

.verticalLine {
    border-left: 1px solid map.get($colors-basic, "divider");
    height: 20px;
}

.header {
    padding: 22px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;

    .headerTitleGroup {
        display: flex;
        justify-content: flex-start;
        align-items: baseline;
        flex-direction: row;
        gap: 12px;
    }

    .headerButtonGroup {
        display: flex;
        justify-content: flex-end;
        flex-grow: 1;
        align-items: center;
        flex-direction: row;
        gap: 12px;
    }

    .headerTitle {
        font-size: map.get($font-sizes, "h5");
    }

    .headerSubTitle {
        font-size: 18px;
        line-height: 25px;
        font-weight: 600;
    }

    .headerSubTitleCounter {
        font-size: 15px;
        color: map.get($colors-basic, "textGray");
    }

    .headerDescription {
        font-size: map.get($font-sizes, "body");
        color: map.get($colors-basic, "textGray");
    }
}

.table {
    width: 100%;
    display: flex;
    flex-direction: column;

    table {
        display: flex;
        width: 100%;
        flex-direction: column;

        tbody {
            height: calc(100vh - 119px);
            width: 100%;
            overflow: auto;
            -ms-overflow-style: none;
            scrollbar-width: none;
            &::-webkit-scrollbar {
                width: 0;
                height: 0;
            }
        }

        .hideScroll {
          overflow: hidden;
        }

        thead, tbody {
            width: 100%;
            display: flex;
            flex-direction: column;

            tr {
                display: flex;
                width: 100%;
            }

            th {
                cursor: pointer;
                color: map.get($colors-basic, "textGray");

            }

            td {
                &:last-child {
                    display: flex;
                    align-items: center;
                }
            }

            th, td {
                display: flex;
                justify-content: flex-start;
                align-items: center;

                span {
                    margin-right: 8px;
                }

            &:first-child {
                padding-left: 20px;
            }

            &:last-child {
                padding-right: 20px;

            }
          }
        }

        thead {
            tr {
                padding: 8px 0;
                border-top: 1px solid map.get($colors-basic, "divider");
                border-bottom: 1px solid map.get($colors-basic, "divider");
            }
        }

        tbody {
            tr {
                padding: 12px 0 12px 0;
                border-bottom: 1px solid map.get($colors-basic, "divider");
                cursor: pointer;
                min-height: 57px;
                box-sizing: border-box;

                .controlIcons {
                    display: none;
                }

                .selectedIcons {
                    display: none;
                }

                .controlIconsHideList {
                    color: #93939F;

                    > span {
                        white-space: pre-wrap;
                    }
                }

                &:hover {
                    background-color: map.get($colors-basic, "selectedOption");

                    td:last-child {
                        justify-content: flex-start;
                    }

                    .controlIcons {
                        display: flex;
                        flex-direction: row;
                        gap: 16px;
                    }
                    .controlIconsHideList {
                        display: none;
                    }
                }
            }
        }

        .activated {
            background-color: map.get($colors-blue, 10);

            &:before {
                content: "";
                width: 2px;
                height: 100%;
                background:  map.get($colors-basic, "primaryBlue");
                border-radius: 1px;
                position: absolute;
                bottom: 0;
                left: 0;
            }
        }


        .selected {
            background-color: map.get($colors-basic, "hover");

            &:hover {
                .selectedIcons {
                    display: flex;
                    flex-direction: row;
                    gap: 16px;
                }

                // .controlIcons {
                //     display: none;
                // }
            }
        }

        .connectionTableRowConnections {
            display: grid;
            grid-template-rows: auto;
            grid-template-columns: 36px 19fr 11fr 11fr 11fr 8fr 180px;
            gap: 10px;

            th:nth-child(2) {
                margin-left: 32px;
            }

            td {
                overflow: hidden;
            }

            td:nth-child(2) {
                > svg {
                    min-width: 24px;
                    min-height: 24px;
                }
            }
        }

        .keysTableRowKeys {
            display: grid;
            grid-template-rows: auto;
            grid-template-columns: 36px 21fr 28fr 11fr 8fr 180px;
            gap: 10px;

            th:nth-child(2) {
                margin-left: 32px;
            }

            td {
                overflow: hidden;
            }

            td:nth-child(2) {
                > svg {
                    min-width: 24px;
                    min-height: 24px;
                }
            }
        }

        .projectsTableRowKeys {
            display: grid;
            grid-template-rows: auto;
            grid-template-columns: 21fr 28fr 11fr 8fr 180px;
            gap: 10px;

            td {
                overflow: hidden;
            }

            td:nth-child(2) {
                > svg {
                    min-width: 24px;
                    min-height: 24px;
                }
            }
        }

        tbody {
            .connectionTableRowConnections {
                position: relative;
            }

            .keysTableRowKeys, .projectsTableRowKeys {
                position: relative;
            }
        }

        .connectionTableRowEndpoints {
            display: grid;
            grid-template-rows: auto;
            grid-template-columns: 36px 15fr 6fr 15fr 12fr 11fr 8fr 180px;
            gap: 10px;

            th:nth-child(2) {
                margin-left: 32px;
            }

            td {
                overflow: hidden;
            }

            td:nth-child(2) {
                > svg {
                    min-width: 24px;
                    min-height: 24px;
                }
            }
        }

        tbody {
            .connectionTableRowEndpoints {
                position: relative;
            }
        }

        .connectionTableRowTrash {
          display: grid;
          grid-template-rows: auto;
          grid-template-columns: 36px 20fr 8fr 8fr 12fr 10fr 8fr 180px;
          gap: 10px;

          th:nth-child(2) {
            margin-left: 32px;
          }

          .aligned {
            justify-self: flex-start;
            margin: -1px 0;
            padding: 1px 0
          }

          th:last-child {
            justify-self: flex-start;
          }

          td {
            overflow: hidden;
          }

          td:nth-child(2) {
            > svg {
              min-width: 24px;
              min-height: 24px;
            }
          }
        }

        tbody {
          .connectionTableRowTrash {
            position: relative;
          }
        }

        &.apiTable, &.connectionTable, &.usersTable {
            thead, tbody {
                th, td {
                    span {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }
        }
    }

    .emptyContent {
        display: flex;
        margin-top: 164px;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .iconWrap {
            width: 180px;
            height: 180px;
            background-color: map.get($colors-blue, 10);
            border-radius: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 32px;
            cursor: pointer;

            svg {
                width: 120px;
                height: 120px;

                * {
                    stroke: map.get($colors-basic, "primaryBlue");
                }
            }
        }

        .caption {
            font-weight: map.get($font-weights, "semibold");
            margin-bottom: 8px;
        }

        .action {
            margin-top: 8px;
        }

        a {
            border: none;
            background-color: inherit;
            text-decoration: none;
            padding: 0;
            margin: 0;
            display: inline-block;
            cursor: pointer;
            color: map.get($colors-basic, "primaryBlue");
            font-size: map.get($font-sizes, "body");
        }
    }
}

.body {
    position: relative;
    border-top: 1px solid map.get($colors-basic, "divider");
    display: flex;
    flex-direction: row;
    min-height: calc(100vh - 84px);
    max-height: calc(100vh - 84px);
    overflow-y: hidden;

    &.vertical {
        flex-direction: column;

        .mainContent {
            flex-grow: initial;
        }
    }

    .sectionHeader {
        font-size: map.get($font-sizes, "subtitle");
        font-weight: map.get($font-weights, "semibold");
        & > :last-child {
            color: map.get($colors-basic, "textGray");
            font-size: map.get($font-sizes, "body");
            margin-left: 6px;
        }
    }

    .clickable {
      &:hover {
        cursor: pointer;
      }
    }

    .passive {
        color: map.get($colors-basic, "textDisabled");
    }

    .errorState {
        color: map.get($colors-red, 100);
    }

    .passive.errorState {
        color: map.get($colors-red, 70);
    }

    .sectionContainer {
        margin-top: 20px;
        display: grid;
        grid-template-rows: auto;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 20px;

        &.triple {
            grid-template-columns: 94px 2fr 3fr 94px 74px 50px;
        }

        &.tripleAlternate {
            grid-template-columns: 2fr 2fr 3fr 74px 50px;
        }

        .testConnectionBtn {
          display: flex;
          & > button {
            width: 100%;
            height: 36px;
            box-sizing: border-box;
            padding: 0;
            margin: 0;
            border: 0;
          }
        }

        .inputWrapper {
            display: flex;
            flex-direction: column;
            position: relative;

            &.mergeColumns {
             grid-column: span 2;
            }

            .errorPlaceholder {
                position: absolute;
                bottom: -15px;
                left: 0;
                font-size: 11px;
                color: map.get($colors-red, 100);
            }

            .inputTabs {
                display: flex;
                flex-direction: row;
                gap: 16px;

                > * {
                    cursor: pointer;
                }
            }

            .codeEditorWrap > div {
                height: 30px;
                overflow: auto;
                padding: 4px 2px 0 6px;
                & > :first-child {
                    height: 31px;
                    & > div {
                        height: 100%;
                        padding-right: 34px;
                    }
                }

                & div {
                    outline: none;
                }

                & > :last-child {
                    bottom: 10px;
                }
            }

            .codeEditorWrapInline > div {
              & > :last-child {
                bottom: 9px;
              }
            }

            &.btn {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
            }
        }

        .actionParamContainer {
            position: relative;
            display: flex;
            align-items: flex-end;

            .actionParam {
                position: absolute;
                bottom: 10px;
            }

            .errorConnection {
                color: map.get($colors-red, 100);
                margin-right: 8px;
            }
        }

        .inputLabel {
            margin-bottom: 8px;
            display: flex;
            flex-direction: row;
            align-items: center;

            &.flexBetween {
                justify-content: space-between;
            }

            button {
                color: map.get($colors-basic, "textBlack");
            }
        }

        .inputLabelDetails {
            color: map.get($colors-basic, "textGray"); 
            white-space: pre;
        }

        .inputLabelDisabled {
            color: map.get($colors-basic, "textGray");
        }

        &.wide {
            grid-template-columns: 1fr;
            max-width: calc(100vw - 651px + 48px);
            min-width: 400px;

            .inputWrapper {
                width: 100%;
                max-width: calc(100vw - 651px + 48px);
                overflow: hidden;
            }
        }
    }

    .limitSection {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      gap: 20px;
      width: 100%;
      margin-bottom: 20px;

      .inputWrapper {
        display: flex;
        position: relative;
        flex-direction: column;
        gap: 8px;

        .errorPlaceholder {
            position: absolute;
            bottom: -17px;
            left: 0;
            font-size: 11px;
            text-wrap: nowrap;
            color: map.get($colors-red, 100);
        }
      }
    }

    .sectionRightsWrap {
        margin-top: 20px;
        border-top: 1px solid map.get($colors-basic, "divider");
        padding-top: 20px;

        .sectionHeader {
            margin-bottom: 20px;
        }
    }

    .sectionRights {
        display: flex;
        flex-direction: column;

        .rightsList {
            display: flex;
            margin-bottom: 40px;
        }

        .checkboxWrap {
            margin-right: 40px;
        }

        .inputLabel {
            margin-bottom: 20px;
            font-weight: 600;
            display: block
        }
    }

    .sidepanel {
        min-height: calc(100vh - 84px);
        max-height: calc(100vh - 84px);
        box-sizing: border-box;
        flex-basis: 360px;
        min-width: 360px;
        border-left: 1px solid map.get($colors-basic, "divider");
        background-color: map.get($colors-basic, "sideMenu");
        display: flex;
        flex-direction: column;
    }

    .sidepanelGroup {
        overflow: hidden;
        flex-grow: 1;
        flex-basis: 0;
        margin: 0 20px;
        display: flex;
        flex-direction: column;

        &:nth-child(2) {
            border-top: 1px solid map.get($colors-basic, "divider");
        }

    }

    .sidepanelHeading {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        padding: 20px 0 0;
        position: relative;

        .subHeaders {
          display: flex;
          gap: 20px
        }

        .addButton {
            color: map.get($colors-basic, "primaryBlue");
        }

        .textAction {
            position: absolute;
            right: 0;
            top: 50%;
        }
    }

    .chipsStatus {
      width: 90px;
      justify-content: left;
      padding-left: 12px;
    }

    .sidepanelTable {
        overflow: hidden;
        height: 100%;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        height: 100%;


        hr {
            border: 0;
            border-top: 1px solid map.get($colors-basic, "divider");
        }

        .sidepanelTableHeading, .sidepanelTableBody {
            padding-bottom: 10px;
            display: flex;
            flex-direction: row;
            border-bottom: 1px solid map.get($colors-basic, "divider");

            .line {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                gap: 12px;
                width: 100%;

                > div {
                    &:nth-child(1) {
                        flex-grow: 1;
                        max-width: 272px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    &:nth-child(2) {
                        flex-basis: 72px;
                        max-width: 72px;
                    }

                    &:nth-child(3) {
                        margin-right: 2px;
                        max-width: 90px;
                        text-align: right;
                    }
                }
            }

          .activeVersion {
            background-color: map.get($colors-blue, 30);
            cursor: pointer;
          }

          .lineHeader {
            display: flex;
            flex-direction: row;
            align-items: center;
            color: map.get($colors-basic, "textGray");
            width: 100%;
            gap: 12px;
            > div {
              &:nth-child(1) {
                flex-grow: 1;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                margin-right: 18px;
              }

              &:nth-child(2) {
                flex-basis: 4px;
                width: 4px;
                min-width: 4px;
                max-width: 4px;
              }

              &:nth-child(3) {
                margin-right: 2px;
                max-width: 90px;
                text-align: left;
              }

              &:nth-child(4) {
                margin: auto;
                padding-top: 6px;
                padding-left: 8px;
              }
            }
          }

          .historyLine {
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            height: 44px;
            min-height: 44px;

            &.activeCommit {
                background-color: map.get($colors-blue, 30);
              }

            .bold {
              font-weight: 700;
            }

            .arrowUp {
              transition-duration: 300ms;
              transform: rotate(0deg);
            }

            .arrowDown {
              transition-duration: 300ms;
              transform: rotate(-90deg);
            }

            > div {
              &:nth-child(1) {
                flex-grow: 1;
                max-width: 272px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              &:nth-child(2) {
                flex-basis: 4px;
                max-width: 4px;
                min-width: 4px;
                width: 4px;
              }

              &:nth-child(3) {
                margin-right: 2px;
                max-width: 90px;
                text-align: center;
              }

              &:nth-child(4) {
                flex-grow: 1;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: 8px;
              }
            }

            &:hover {
                .historyLineControls:not(.historyLineControlsDisabled) {
                    width: 44px;
                    min-width: 44px;
                    padding-left: 8px;
                    transition: all .3s;
                }
            }
          }

          .historyLineControls {
            min-width: 0;
            width: 0;
            overflow: hidden;
            display: flex;
            flex-direction: row;
            gap: 12px;
            padding-left: 0;
            flex-grow: unset !important;
            margin: 0 !important;
            transition: all .3s;
          }
        }

        .commitSection {
          background-color: map.get($colors-basic, "hover");
           > li {
            display: flex;
            flex-direction: column;
           }
          .commitActiveBtn {
            background-color: map.get($colors-basic, "hover");
            padding: 20px;
          }
          .overrideActionContainer {
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;
            padding-bottom: 12px;
          }
        }

        .sidepanelTableBodyContainer {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            height: 100%;
            overflow: hidden;
        }

        .sidepanelTableBody {
            display: flex;
            flex-direction: column;
            padding-top: 8px;

            overflow-y: auto;
            -ms-overflow-style: none;
            scrollbar-width: none;
            height: 100%;

            &::-webkit-scrollbar {
                width: 0;
                height: 0;
            }
    

            .line {
                padding-top: 12px;
                padding-bottom: 12px;
                border-bottom: 1px solid map.get($colors-basic, "divider");

                &:last-child {
                    border-bottom: none;
                }
            }
        }

        .sidepanelTableBodyDivider {
            margin: 20px 0;
        }
    }

    .sidepanelForm {
       position: relative;
       height: 100%;
       width: 100%;
       display: flex;
       flex-direction: column;

       overflow-y: auto;
       -ms-overflow-style: none;
       scrollbar-width: none;

       &::-webkit-scrollbar {
           width: 0;
           height: 0;
       }
    }

    .mainContent {
        flex-grow: 1;
        padding: 20px;
        min-height: calc(100vh - 84px);
        max-height: calc(100vh - 84px);
        // max-width: calc(100vw - 608px);
        min-width: 400px;
        box-sizing: border-box;
        overflow-y: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            width: 0;
            height: 0;
        }

        &.mainContentUsers {
            min-height: unset;
            max-height: unset;
            overflow-y: visible;
            -ms-overflow-style: none;
            scrollbar-width: none;
            &::-webkit-scrollbar {
                width: 0;
                height: 0;
            }

            .sectionContainer {
                grid-template-columns: 1fr 1fr 1fr;
            }
        }

        .section {
            display: flex;
            flex-direction: column;

            .subHeaders {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 24px;
            }
        }

        hr {
            margin-top: 20px;
            margin-bottom: 20px;
            border: 0;
            border-top: 1px solid map.get($colors-basic, "divider");
        }
    }

    .bottomPanel {
        flex-grow: 1;
        border-top: 1px solid map.get($colors-basic, "divider");
        display: flex;
        flex-direction: row;

        .vertical {
            width: 0;
            border-right: 1px solid map.get($colors-basic, "divider");
        }

        .section {
            box-sizing: border-box;
            padding: 20px;
            display: flex;
            flex-direction: column;
            width: calc(50% - 1px);
        }
        .sidepanelTableBody {
            overflow: auto;
            box-sizing: border-box;
            height: calc(100vh - 415px);
            padding-bottom: 44px;
            width: 100%;
            -ms-overflow-style: none;
            scrollbar-width: none;
            &::-webkit-scrollbar {
                width: 0;
                height: 0;
            }
        }
        .sidepanelTableHeading, .sidepanelTableBody {
            .line {
                a {
                   color: map.get($colors-basic, "primaryBlue");
                   text-decoration: none;
                }
                > div {
                    &:nth-child(1) {
                        width: calc(100% - 200px - 130px);
                        max-width: none;
                        flex-basis: auto;
                    }

                    &:nth-child(2) {
                        width: 200px;
                        display: flex;
                        align-items: center;
                        max-width: none;
                        flex-basis: auto;
                    }

                    &:nth-child(3) {
                        width: 130px;
                        max-width: none;
                        flex-basis: auto;
                    }
                }
            }
        }
    }
}

.chatGptButtonContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .btnContainer {
        position: relative;
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        align-items: center;
        gap: 16px;
    }

    .statusContainer {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 12px;
    }
}

.contextMenu {
    position: fixed;
    z-index: 1;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 6px rgba(8,8,40, 0.1);
  }

  .contextMenuList {
    margin: 0;
    display: block;
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
  }

  .contextMenuItem {
    position: relative;
  }

  .contextMenuButton {
    cursor: pointer;
    font: inherit;
    border: 0;
    padding: 8px 8px;
    padding-right: 36px;
    width: 100%;
    border-radius: 8px;
    text-align: left;
    display: flex;
    align-items: center;
    position: relative;
    background-color: #fff;
    &:hover {
      background-color: map.get($colors-basic, "hover");
    }

  }

.spanWrapper {
  max-height: 20px;
  overflow: hidden;
}

.compactLayout {
    min-height: calc(100vh - 84px - 66px) !important;
    max-height: calc(100vh - 84px - 66px) !important;
}

.borderBottom {
    border-bottom: 1px solid map.get($colors-basic, "divider"); 
}

.borderTop {
    border-top: 1px solid map.get($colors-basic, "divider");  
}

.testConnectionContainerGroup {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
}

.testConnectionContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    gap: 10px;
}

import { AuthKeys } from './constants';
import {
  injectChunk, removeFolder, removeObj, createFolder,
  updateFolder, createObj, moveFolder, moveObj,
} from '../../helpers/utils';
import { ActiveProject } from '../activeProject/constants';

const initialState = {};

export const authKeysReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case AuthKeys.listAuthKeysSuccess:
      return { ...state, data: action.data };
    case AuthKeys.searchAuthKeysSuccess:
      return { ...state, data: action.data?.keys, foldersData: action.data?.folders };
    case AuthKeys.getAuthKeyFolderContentSuccess:
      return action.data?.merge ? injectChunk(state, action.data) : {
        ...state,
        data: action.data?.data,
        foldersData: action.data?.folders,
        breadscrumbData: action.data?.breadscrumbs,
      };
    case AuthKeys.moveAuthKeyFolderSuccess: return moveFolder(state, action.data);
    case AuthKeys.moveAuthKeySuccess: return moveObj(state, action.data);
    case AuthKeys.createAuthKeyFolderSuccess:
      return state.foldersData
        ? { ...state, foldersData: [...state.foldersData, action.data] }
        : { ...state, foldersData: action.data };
    case AuthKeys.copyAuthKeyFolderSuccess: return createFolder(state, action.data);
    case AuthKeys.createAuthKeySuccess: return state.data ? createObj(state, action.data) : {
      ...state, data: action.data,
    };
    case AuthKeys.removeAuthKeyFolderSuccess: return removeFolder(state, action.data);
    case AuthKeys.removeAuthKeysListSuccess: return removeObj(state, action.data);
    case AuthKeys.updateAuthKeyFolderSuccess:
      return updateFolder(state, action.data);
    case AuthKeys.updateAuthKeySuccess:
      return state.data ? {
        ...state,
        data: [...state.data.map(
          (content) => (action.data.id === content.id ? { ...content, ...action.data }
            : content),
        )],
      } : state;
    case ActiveProject.activeProjectReset:
      return initialState;
    default:
      return state;
  }
};

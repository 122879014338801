/* eslint-disable no-param-reassign */
import React, { useRef, useEffect } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import cn from 'classnames';
import ActionIcon from '../ActionIcon';
import Input from '../Input/Input';
import Select from '../Select/Select';

import * as shared from '../../styles/shared.module.scss';

export default function ParamentRow({
  id,
  param,
  index,
  moveParam,
  handleChangeProxy,
  setFieldValueProxy,
  removeParameter,
  errors,
  modelKey,
  viewRead = false,
}) {
  const ref = useRef(null);

  const allowDragRef = React.useRef(false);

  const [{ handlerId }, drop] = useDrop({
    accept: 'card',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      moveParam(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: 'card',
    item: () => ({ id, index }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: () => allowDragRef.current,
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  const handleClick = () => {
    allowDragRef.current = false;
  }

  useEffect(() => {
    document.addEventListener('dragend', handleClick);
  
    return () => {
      document.removeEventListener('dragend', handleClick);
    };
  }, []);

  return (
    <div
      className={cn(shared.sectionContainer, shared.tripleAlternate)}
      style={{ opacity }}
      ref={ref}
    >
      <div className={shared.inputWrapper}>
        <Input
          placeholder="Name"
          handleChange={handleChangeProxy}
          value={param.name}
          name={`name.${param.id}`}
          id={`${modelKey}.${index}.name`}
          error={errors && errors[modelKey] && errors[modelKey][index]?.name}
          disabled={viewRead}
        />
      </div>
      <div className={shared.inputWrapper}>
        <Input
          placeholder="Value"
          handleChange={(e)=>handleChangeProxy({ target: { value: e.target.value, name: `value.${param.id}` } })}
          value={param.value}
          name={`value.${param.id}`}
          id={`${modelKey}.${index}.value`}
          disabled={viewRead}
          error={errors && errors[modelKey] && errors[modelKey][index]?.value}
          withVariables
        />
      </div>
      <div className={shared.inputWrapper}>
        <Input
          placeholder="Description"
          handleChange={handleChangeProxy}
          value={param.description}
          name={`description.${param.id}`}
          id={`${modelKey}.${index}.description`}
          disabled={viewRead}
        />
      </div>
      <div className={shared.inputWrapper}>
        <Select
          list={[
            { value: 'yes', title: 'Yes' },
            { value: 'no', title: 'No' },
          ]}
          value={param.required}
          handleChange={setFieldValueProxy}
          name={`required.${param.id}`}
          id={`${modelKey}.${index}.required`}
          placeholder=""
          type="top"
          error={errors && errors[modelKey] && errors[modelKey][index]?.required}
          disabled={viewRead}
        />
      </div>
      <div className={cn(shared.inputWrapper, shared.btn)}>
        <div 
          role="button"
          tabIndex={0}
          data-handler-id={handlerId}
          onMouseDown={() => {
            allowDragRef.current = true;
          }}
        >
          <ActionIcon
            icon="list"
            style={{ marginRight: '16px', cursor: 'move' }}
          />
        </div>
        {!viewRead
          && (
          <ActionIcon
            icon="trash"
            onClick={() => removeParameter(param)}
          />
          )}
      </div>
    </div>
  );
}

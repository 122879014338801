/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-autofocus */
import React, { useState } from 'react';
import Button from '../Button/Button';
import Checkbox from '../Checkbox/Checkbox';

import * as styles from './ExportVariableModal.module.scss';

function ExportVariableModal({
  onClose,
  onSubmit,
  model,
  collection,
}) {
  const { variables = [] } = model || {};
  const [linkApi, setLinkApi] = useState(false);
  const [linkConnections, setLinkConnections] = useState(false);
  const [linkAuthKeys, setLinkAuthKeys] = useState(false);

  const numFilteredVariabels = variables.length;

  return (
    <div className={styles.modal}>
      <div className={styles.modalContent}>
        <div className={styles.header}>
          <div className={styles.title}>Export Variables</div>
        </div>
        <div className={styles.body}>
          <div className={styles.mainContent}>
            <div>
              <div>

                {(numFilteredVariabels) ? (
                  <span className={styles.modelTitle}>
                    {numFilteredVariabels}
                    {' '}
                    {numFilteredVariabels > 1 ? 'Variables' : 'Variable'}
                  </span>
                ) : null}
                {' '}
                will be scheduled for exporting.
              </div>
              <br />
              Once exporting process is completed, you`ll receive an email
              with the link to export the <span className={styles.modelTitle}>.json</span> file with all Variables.
            </div>
          </div>
          <div className={styles.buttonGroup}>
            <div className={styles.buttonGroup}>
                <div className={styles.checkboxGroupTitle}>
                    <strong>
                      Export linked:
                    </strong>
                </div>
                <Checkbox
                  handleChange={(v) => {
                    setLinkApi(v);
                    if (!v) setLinkConnections(false);
                  }}
                  label={(
                    <div>
                      APIs
                    </div>
                  )}
                  size="small"
                  value={linkApi}
                  style={{ marginRight: 14 }}
                />

              <Checkbox
                handleChange={setLinkConnections}
                label={(
                  <div>
                    Connections
                  </div>
                )}
                size="small"
                value={linkConnections}
                style={{ marginRight: 14 }}
              />

            </div>
            <div className={styles.buttonGroup}>
              <Button style={{ width: 85 }} title="Cancel" type="secondary" onClick={onClose} />
              <Button
                style={{ width: 200, marginLeft: 12 }}
                title="Export Variables"
                onClick={() => {
                  onSubmit({ variables }, linkApi, linkConnections, linkAuthKeys);
                  onClose();
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExportVariableModal;

import {
  put, takeEvery, all, fork, select, call
} from 'redux-saga/effects';
import {
  activeProjectRequest, activeProjectSuccess
} from './actions';
import { api } from '../../helpers/api';
import {
  refreshRequest,
} from '../auth/actions';
import { createToastRequest } from '../toasts/actions';
import { ActiveProject } from './constants';

import history from '../../history';

const getAuth = (state) => state.auth?.auth;

export function* fetchActiveProject(ops) {
  const authData = yield select(getAuth);
  const { data } = ops || {};
  const { shortName } = data || {};

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(api, `/projects/${shortName}`, 'GET', null, authData.access_token);
      if (response.ok) {
        const parsed = yield response.json();
        yield put(activeProjectSuccess(parsed));
      } else if (response.status === 401) {
        yield put(refreshRequest(activeProjectRequest, ops.data));
      } else {
        const parsed = yield response.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
      }
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

function* activeProjectSaga() {
  yield takeEvery(ActiveProject.activeProjectRequest, fetchActiveProject);
}

function* configSaga() {
  yield all([fork(activeProjectSaga)]);
}

export default configSaga;

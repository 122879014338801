import { ActiveSearchProject } from './constants';

const initialState = {
  active: null,
  inviteList: [],
  endpointsList: [],
  connectionsList: []
};

export const activeSearchProjectReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case ActiveSearchProject.activeSearchProjectSuccess:
      return {
        ...state,
        active: action.data,
      };
    case ActiveSearchProject.getAccessUsersProjectSuccess:
      return {
        ...state,
        inviteList: action.data,
      }
    case ActiveSearchProject.getConnectedEndpointsToProjectSuccess:
      return {
        ...state,
        endpointsList: action.data,
      }
    case ActiveSearchProject.getConnectedConnectionsToProjectSuccess:
      return {
        ...state,
        connectionsList: action.data,
      }
    default:
      return state;
  }
};

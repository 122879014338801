import {
  call, put, takeEvery, all, fork, select,
} from 'redux-saga/effects';
import { api } from '../../helpers/api';
import {
  activeVariableRequest, activeVariableSuccess,
  getConnectedModelsToVariablesRequest, getConnectedModelsToVariablesSuccess
} from './actions';
import { createToastRequest } from '../toasts/actions';
import {
  refreshRequest,
} from '../auth/actions';
import { ActiveVariable } from './constants';
import history from '../../history';

const getAuth = (state) => state.auth?.auth;

export function* fetchActiveVariable(ops) {
  const { data } = ops;
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(api, `/variables/${data.id}`, 'GET', null, authData.access_token);
      if (response.ok) {
        const parsed = yield response.json();
        yield put(activeVariableSuccess(parsed));
      } else if (response.status === 401) {
        yield put(refreshRequest(activeVariableRequest, data));
      } else {
        const parsed = yield response.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
      }
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* getConnectedModels(ops) {
  const { data } = ops;
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(api, `/variables/links?id=${data.id}`, 'GET', null, authData.access_token);
      if (response.ok) {
        const parsed = yield response.json();
        yield put(getConnectedModelsToVariablesSuccess(parsed));
      } else if (response.status === 401) {
        yield put(refreshRequest(getConnectedModelsToVariablesRequest, data));
      } else {
        const parsed = yield response.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
      }
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

function* activeVariableSaga() {
  yield takeEvery(ActiveVariable.activeVariableRequest, fetchActiveVariable);
  yield takeEvery(ActiveVariable.getConnectedModelsToVariablesRequest, getConnectedModels);
}

function* configSaga() {
  yield all([fork(activeVariableSaga)]);
}

export default configSaga;

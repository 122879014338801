/* eslint-disable max-len */
import { Endpoints } from './constants';

export const listEndpointsRequest = (data) => ({ type: Endpoints.listEndpointsRequest, data });
export const listEndpointsSuccess = (data) => ({ type: Endpoints.listEndpointsSuccess, data });
export const listEndpointsError = () => ({ type: Endpoints.listEndpointsError });

export const searchEndpointsRequest = (data, callback) => ({ type: Endpoints.searchEndpointsRequest, data, callback });
export const searchEndpointsSuccess = (data) => ({ type: Endpoints.searchEndpointsSuccess, data });
export const searchEndpointsError = () => ({ type: Endpoints.searchEndpointsError });

export const createEndpointRequest = (data, callback, options) => ({
  type: Endpoints.createEndpointRequest, data, callback, options,
});
export const createEndpointSuccess = (data) => ({ type: Endpoints.createEndpointSuccess, data });
export const createEndpointError = () => ({ type: Endpoints.createEndpointError });

export const removeEndpointsListRequest = (data, callback) => ({ type: Endpoints.removeEndpointsListRequest, data, callback });
export const removeEndpointsListSuccess = (data) => ({ type: Endpoints.removeEndpointsListSuccess, data });

export const updateEndpointRequest = (data, callback) => ({ type: Endpoints.updateEndpointRequest, data, callback });
export const updateEndpointSuccess = (data) => ({ type: Endpoints.updateEndpointSuccess, data });
export const updateEndpointError = () => ({ type: Endpoints.updateEndpointError });

export const copyEndpointRequest = (data, callback) => ({ type: Endpoints.copyEndpointRequest, data, callback });
export const copyEndpointSuccess = (data) => ({ type: Endpoints.copyEndpointSuccess, data });
export const copyEndpointError = () => ({ type: Endpoints.copyEndpointError });

export const exportEndpointRequest = (data, callback) => ({ type: Endpoints.exportEndpointRequest, data, callback });
export const exportEndpointSuccess = (data) => ({ type: Endpoints.exportEndpointSuccess, data });
export const exportEndpointError = () => ({ type: Endpoints.exportEndpointError });

export const importEndpointRequest = (data, callback) => ({ type: Endpoints.importEndpointRequest, data, callback });
export const importEndpointSuccess = (data) => ({ type: Endpoints.importEndpointSuccess, data });
export const importEndpointError = () => ({ type: Endpoints.importEndpointError });

export const testEndpointRequest = (data, callback) => ({ type: Endpoints.testEndpointRequest, data, callback });
export const testEndpointSuccess = (data) => ({ type: Endpoints.testEndpointSuccess, data });
export const testEndpointError = () => ({ type: Endpoints.testEndpointError });

export const gptRequest = (data, callback) => ({ type: Endpoints.gptRequest, data, callback });
export const gptSuccess = (data) => ({ type: Endpoints.gptSuccess, data });
export const gptError = () => ({ type: Endpoints.gptError });

export const createEndpointFolderRequest = (data, callback) => ({ type: Endpoints.createEndpointFolderRequest, data, callback });
export const createEndpointFolderSuccess = (data) => ({ type: Endpoints.createEndpointFolderSuccess, data });

export const updateEndpointFolderRequest = (data, callback) => ({ type: Endpoints.updateEndpointFolderRequest, data, callback });
export const updateEndpointFolderSuccess = (data) => ({ type: Endpoints.updateEndpointFolderSuccess, data });

export const removeEndpointFolderRequest = (data, callback) => ({ type: Endpoints.removeEndpointFolderRequest, data, callback });
export const removeEndpointFolderSuccess = (data) => ({ type: Endpoints.removeEndpointFolderSuccess, data });

export const getEndpointFolderContentRequest = (data, callback) => ({ type: Endpoints.getEndpointFolderContentRequest, data, callback });
export const getEndpointFolderContentSuccess = (data) => ({ type: Endpoints.getEndpointFolderContentSuccess, data });

export const moveEndpointFolderRequest = (data, callback) => ({ type: Endpoints.moveEndpointFolderRequest, data, callback });
export const moveEndpointFolderSuccess = (data) => ({ type: Endpoints.moveEndpointFolderSuccess, data });

export const copyEndpointFolderRequest = (data, callback) => ({ type: Endpoints.copyEndpointFolderRequest, data, callback });
export const copyEndpointFolderSuccess = (data) => ({ type: Endpoints.copyEndpointFolderSuccess, data });

export const moveEndpointRequest = (data, callback) => ({ type: Endpoints.moveEndpointRequest, data, callback });
export const moveEndpointSuccess = (data) => ({ type: Endpoints.moveEndpointSuccess, data });

export const getEndpointFolderTreeRequest = (data, callback) => ({ type: Endpoints.getEndpointFolderTreeRequest, data, callback });
export const getEndpointFolderTreeSuccess = (data) => ({ type: Endpoints.getEndpointFolderTreeSuccess, data });

export const connectAuthKeyToEndpointRequest = (data, callback) => ({ type: Endpoints.connectAuthKeyToEndpointRequest, data, callback });
export const connectAuthKeyToEndpointSuccess = (data) => ({ type: Endpoints.connectAuthKeyToEndpointSuccess, data });

export const disconnectAuthKeyToEndpointRequest = (data, callback) => ({ type: Endpoints.disconnectAuthKeyToEndpointRequest, data, callback });
export const disconnectAuthKeyToEndpointSuccess = (data) => ({ type: Endpoints.disconnectAuthKeyToEndpointSuccess, data });

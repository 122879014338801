/* eslint-disable import/prefer-default-export */
export const ActiveConnection = {
  activeConnectionRequest: 'ACTIVE_CONNECTION_PENDING',
  activeConnectionSuccess: 'ACTIVE_CONNECTION_SUCCESS',
  activeConnectionError: 'ACTIVE_CONNECTION_ERROR',
  
  getAccessUsersConnectionSuccess: 'GET_ACCESS_USERS_CONNECTION_SUCCESS',

  testActiveConnectionRequest: 'TEST_ACTIVE_CONNECTION_PENDING',
  testActiveConnectionSuccess: 'TEST_ACTIVE_CONNECTION_SUCCESS',
  testActiveConnectionError: 'TEST_ACTIVE_CONNECTION_ERROR',

  getConnectedEndpointsRequest: 'GET_CONNECTED_ENDPOINTS_REQUEST',
  getConnectedEndpointsSuccess: 'GET_CONNECTED_ENDPOINTS_SUCCESS',
};

/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import * as styles from './ActionText.module.scss';

function ActionText({
  onClick,
  title,
  style = {},
  type,
  disabled,
  target,
}) {
  if (target) {
    return (
      <a
        href={target}
        target="_blank"
        className={cn(
          styles.actionText,
          {
            [styles.typeBlack]: type === 'black',
          },
        )}
        onClick={onClick}
        style={{ ...style, textDecoration: 'none' }}
        disabled={disabled}
        rel="noreferrer"
      >
        {title}
      </a>
    );
  }

  return (
    <button
      className={cn(
        styles.actionText,
        {
          [styles.typeBlack]: type === 'black',
          [styles.typeBold]: type === 'bold',
        },
      )}
      onClick={onClick}
      type="button"
      style={style}
      disabled={disabled}
    >
      {title}
    </button>
  );
}

ActionText.propTypes = {
  onClick: PropTypes.func,
  style: PropTypes.object,
  title: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

export default ActionText;

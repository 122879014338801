/* eslint-disable max-len */
/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Checkbox from '../../../../components/Checkbox/Checkbox';
import ActionIcon from '../../../../components/ActionIcon/ActionIcon';
import DBIcon from '../../../../components/DBIcon/DBIcon';

import {
  formatTimestamp
} from '../../../../helpers/utils';

import {
  APIMethods
} from '../../../../helpers/const';

import ApiGraphIcon from '../../../../pictures/api-graph.svg';
import ConnectionIcon from '../../../../pictures/api-database.svg';
import KeyIcon from '../../../../pictures/key-logo.svg';
import FolderIcon from '../../../../pictures/folder.svg';
import * as shared from '../../../../styles/shared.module.scss';

function Directory({
  folderId,
  foldersData,
  endpointsData,
  selectedFolders,
  selectedItems,
  openFolderAction,
  selectOneFolder,
  selectItems,
  modifyEndpoint,
  selectOne,
  getFolder,
  depth = 0,
  copyCon,
  activeProject,
  searchActive,
}) {
  const [expanded, setExpanded] = useState({});

  // const sortEndpoints = (endpointsList) => endpointsList.sort((a, b) => {
  //   const fallback = 'instanceName';
  //   const first = a?.name ? a.name : a[fallback];
  //   const second = b?.name ? b.name : b[fallback];
  //   return first.localeCompare(second);
  // });

  // const sortFolders = (foldersList) => foldersList.sort((a, b) => a.name.localeCompare(b.name));

  const sortEndpoints = (endpointsList) => endpointsList;
  const sortFolders = (foldersList) => foldersList;

  const openDirectory = (id, f, type) => {
    if (f) {
      getFolder({
        folder: id || 0,
        merge: true,
        projectId: activeProject?.id,
        type,
      }, (res) => {
        const { folders, data } = res || {};
        const foldersIds = folders.map((fld) => fld.id);
        const dataIds = data.map((d) => d.id);
        const currentIdSelected = selectedFolders?.find((el) => el === (id || 0));

        if (currentIdSelected) selectItems(foldersIds, dataIds);
      });
    }
    setExpanded({ ...expanded, [id]: f });
  };

  const blockEvent = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const getCorrespondingIcon = (type) => {
   if (type === 'endpoint') return <ApiGraphIcon />;
   if (type === 'connection') return <ConnectionIcon />;
   if (type === 'key') return <KeyIcon />;
   return null;
  }

  return (
    <>
      {sortFolders(foldersData).map((model) => (
        <React.Fragment key={`folder_${model.id}`}>
          <tr
            className={cn(
              shared.connectionTableRowTrash,
              (selectedFolders.includes(model.id) && shared.selected),
            )}
            role="button"
            tabIndex={0}
            onDoubleClick={() => openFolderAction(model.type, model.id)}
          >
            <td>
              <div onDoubleClick={blockEvent}>
                <Checkbox
                  handleChange={(isChecked) => selectOneFolder(isChecked, model.id)}
                  value={selectedFolders.includes(model.id)}
                  size="small"
                />
              </div>
            </td>
            <td style={{ position: 'relative', gap: '8px' }}>
              <div
                onDoubleClick={blockEvent}
                style={{ marginLeft: `calc(${depth} * 32px)`, transform: !expanded[model.id] ? 'rotate(0)' : 'rotate(90deg)', transition: '.3s all', minWidth: 24 }}
              >
                {!searchActive && (
                  <ActionIcon
                    large
                    icon="openArrow"
                    onClick={() => openDirectory(model.id, !expanded[model.id], model.type)}
                  />
                )}
              </div>
              <FolderIcon />
              <span>
                {model.name}
              </span>
            </td>
            <td />
            <td />
            <td />
            <td />
            <td><span>{`${model.deletedBy?.firstName || ''} ${model.deletedBy?.lastName || ''}`}</span></td>
            <td style={{ justifyContent: 'flex-start', color: '#93939F' }}><span style={{ whiteSpace: 'pre-wrap' }}>{formatTimestamp(model.deletedAt)}</span></td>
          </tr>
          {expanded[model.id] && model.items && (
          <Directory
            folderId={model.id}
            key={`directory__${model.id}`}
            foldersData={model.items.foldersData}
            endpointsData={model.items.data}
            selectedFolders={selectedFolders}
            selectedItems={selectedItems}
            openFolderAction={openFolderAction}
            selectOneFolder={selectOneFolder}
            selectItems={selectItems}
            modifyEndpoint={modifyEndpoint}
            selectOne={selectOne}
            getFolder={getFolder}
            copyCon={copyCon}
            activeProject={activeProject}
            depth={depth + 1}
            searchActive={searchActive}
            merge
          />
          )}
        </React.Fragment>
      ))}
      {sortEndpoints(endpointsData).map((model, index) => (
        <tr
          style={{ position: 'relative' }}
          key={`endpoint_${model.id}`}
          className={cn(
            shared.connectionTableRowTrash,
            (selectedItems.includes(model.id) && shared.selected),
          )}
          role="button"
          tabIndex={0}
          onDoubleClick={() => modifyEndpoint(model.id, model.modelType)}
        >
          <td>
            <Checkbox
              handleChange={(isChecked) => selectOne(isChecked, model.id)}
              value={selectedItems.includes(model.id)}
              size="small"
              style={{ marginRight: '0' }}
            />
          </td>
          <td style={{ marginLeft: `calc(${depth + 1} * 32px)`, gap: '8px' }}>
            {getCorrespondingIcon(model.modelType)}
            <span>
              {model.name || model.instanceName}
            </span>
            {model.description ? (
              <div className={shared.controlIcons}>
                <ActionIcon
                  id={`${index}_${model.id}_tooltip`}
                  icon="info"
                  style={{ marginRight: '8px' }}
                  onClick={() => {}}
                  tooltip={{
                    content: model.description,
                    place: 'right',
                  }}
                />
              </div>
            ) : (
              <div className={shared.controlIcons} />
            )}
          </td>
          <td>
            {model.modelType === 'endpoint' ? (
               <span className={shared.uppercased}>{model.method}</span>
            ) : null}
          </td>
          <td>
            <span>{model.path}</span>
            <div className={shared.controlIcons}>
              {model.path && (
              <ActionIcon
                icon="copy"
                style={{ marginRight: '8px' }}
                onClick={() => copyCon(model.path)}
              />
              )}
            </div>
          </td>
          <td>
            {(model.connectionType || model.type) && <DBIcon type={model.connectionType || model.type} style={{ marginRight: '12px', minWidth: '32px' }} />}
            <span>{model.connectionName || model.type}</span>
          </td>
          <td>
            <span><span >{APIMethods[model.method] || ''}</span></span>
          </td>
          <td><span>{`${model.deletedBy?.firstName || ''} ${model.deletedBy?.lastName || ''}`}</span></td>
          <td style={{ justifyContent: 'flex-start', color: '#93939F' }}><span style={{ whiteSpace: 'pre-wrap' }}>{formatTimestamp(model.deletedAt)}</span></td>
        </tr>
      ))}
    </>
  );
}

Directory.propTypes = {
  selectedFolders: PropTypes.array,
  selectedItems: PropTypes.array,
  foldersData: PropTypes.array,
  endpointsData: PropTypes.array,
};

export default Directory;

@use "sass:map";
@import '../../styles/variables.scss';

.contextMenu {
  position: fixed;
  z-index: 1;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 6px rgba(8,8,40, 0.1);
  .contextMenuList {
    margin: 0;
    display: block;
    width: 100%;
    box-sizing: border-box;
    border-radius: 10px;
    .contextMenuItem {
      position: relative;
      border-radius: 8px;
      .contextMenuButton {
        cursor: pointer;
        font: inherit;
        border: 0;
        padding: 8px 36px 8px 8px;
        width: 100%;
        text-align: left;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 12px;
        position: relative;
        background-color: #fff;
        .iconContainer {
          width: 20px;
          height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        &:hover {
          background-color: map.get($colors-basic, "hover");
        }
      }
      .borderAlignTop {
        border-radius: 8px 8px 0 0;
        & > button {
          @extend .borderAlignTop;
        }
      }
      .borderAlignBottom {
        border-radius: 0 0 8px 8px;
        & > button {
          @extend .borderAlignBottom;
        }
      }
      .divider {
        border: 0;
        border-top: 1px solid map.get($colors-basic, "divider");
      }
   }
  }
}

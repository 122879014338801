/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import * as styles from './ChipsStatus.module.scss';

export const ChipsMap = {
  draft: 'invite',
  active: 'invite',
};

function ChipsStatus({
  title = '',
  type = 'success',
  style = {},
}) {
  return (
    <div
      className={cn(
        styles.chipsStatus,
        {
          [styles.error]: type === 'error',
          [styles.user]: type === 'user',
          [styles.invite]: type === 'invite',
          [styles.input]: type === 'input',
          [styles.draft]: type === 'draft',
          [styles.deleted]: type === 'deleted',
        },
      )}
      style={style}
    >
      <span className={styles.nested}>{title}</span>
    </div>
  );
}

ChipsStatus.propTypes = {
  title: PropTypes.string,
  type: PropTypes.oneOf(['success', 'error', 'user', 'invite', 'input', 'draft', 'deleted']),
  style: PropTypes.object,
};

export default ChipsStatus;

/* eslint-disable no-param-reassign */
export const  handleWebSocketEvents = (ws, success, err) => {
    ws.onopen = () => {
      console.log("WebSocket connection opened.");
      // Send a message to the server once the connection is open
      ws.send(JSON.stringify({ key: 'test' }));
    };
  
    ws.onmessage = (event) => {
      console.log("Received response:", event.data);
      const resp = JSON.parse(event.data);
      const { error } = resp || {};
      if (error) err();
      if (!error) success(); 
      // Close the WebSocket connection after receiving the response
      ws.close();
    };
  
    ws.onclose = () => {
      console.log("WebSocket connection closed.");
    };
  
    ws.onerror = (error) => {
      console.error("WebSocket error:", error);
      if (err) err();
    };
  }
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useRef, useEffect } from 'react';
import ContentEditable from 'react-contenteditable';
import cn from 'classnames';
import {
  getTokinized,
  getWithRemovedToken,
  getCaretPosition,
  getTextFromHtml,
  insert,
  transformTextWithVariables,
  searchSuggestions,
  updateSuggestionsNodePosition,
} from '../../helpers/tokenizeAutocompleteUtils';
import * as style from './TokenizeAutocompleteInput.module.scss';
import './TokenizeAutocompleteInput.scss';

function findNearestSubstringIndex(mainString, subString, index) {
  let nearestIndex = -1;
  let minDistance = Number.MAX_SAFE_INTEGER;

  let currentIndex = mainString.indexOf(subString);
  while (currentIndex !== -1) {
    const distance = Math.abs(currentIndex - index);
    if (distance < minDistance) {
      nearestIndex = currentIndex;
      minDistance = distance;
    }
    currentIndex = mainString.indexOf(subString, currentIndex + 1);
  }

  return nearestIndex;
}

export function TokenizeAutocompleteInput(props) {
  // needs refactor
  const {
    placeholder = '',
    tokens = [],
    multiline = false,
    initialHtml = '',
    suggestions = [],
    handleChange: handleParentChange,
    error,
    iconRight
  } = props;
  const [focused, setFocused] = useState(false);
  const [displayedSuggestions, setDisplayedSuggestions] = useState([]);
  const displayedSuggestionsRef = useRef(null);
  displayedSuggestionsRef.current = displayedSuggestions;
  const [html, setHtml] = useState(() => String(initialHtml || ''));
  const textInputRef = useRef(null);
  const caretPositionRef = useRef(null);
  const suggestionsNodeRef = useRef(null);
  const [selectedSuggestion, setSelectedSuggestion] = useState(0);
  const selectedSuggestionRef = useRef(null);
  selectedSuggestionRef.current = selectedSuggestion;
  const htmlRef = useRef();
  htmlRef.current = html;

  useEffect(() => {
    if (!initialHtml) return;
    const transformed = transformTextWithVariables(initialHtml, tokens, 'name', true, false);
    const tokenized = getTokinized(transformed, tokens);
    setHtml(tokenized);
  }, [initialHtml, tokens]);

  useEffect(() => {
    const text = getTextFromHtml(textInputRef.current);
    handleParentChange({ target: { value: text } });
  }, [html, ]); // tokens

  useEffect(() => {
    const listener = document.addEventListener('rmToken', (e) => {
      const newHtml = getWithRemovedToken(htmlRef.current, e.detail.id);
      setHtml(newHtml);
    });
    return () => {
      document.removeEventListener('rmToken', listener);
    };
  }, []);

  const handleChange = (e) => {
    const tokenized = getTokinized(e.target.value, tokens);
    setHtml(tokenized);
  };

  useEffect(() => {
    if (displayedSuggestions.length === 0) {
      setSelectedSuggestion(0);
    }
  }, [displayedSuggestions]);

  useEffect(() => {
    caretPositionRef.current = getCaretPosition(textInputRef.current);
    const position = caretPositionRef.current;
    const withoutSpace = html.replaceAll('&nbsp;', ' ');
    const lastLettersSlice = withoutSpace.slice(position - 4, position);

    if (lastLettersSlice === 'env.') {
      updateSuggestionsNodePosition(suggestionsNodeRef.current);
      setDisplayedSuggestions(suggestions);
      return;
    }

    const nearesEnvIndex = findNearestSubstringIndex(withoutSpace, 'env.', position);
    const match = withoutSpace.slice(nearesEnvIndex).match(/\benv\.(\w+)\b/);

    if (match) {
      const searchValue = match ? match[1] : null;
      if (position - searchValue.length - 4 !== nearesEnvIndex) return setDisplayedSuggestions([]);
      const filtered = searchSuggestions(suggestions, searchValue);
      setSelectedSuggestion(0);
      updateSuggestionsNodePosition(suggestionsNodeRef.current);
      setDisplayedSuggestions(filtered);
      return;
    }
    setDisplayedSuggestions([]);
  }, [html]);

  const handlePressArrow = (code) => {
    if (code === 'ArrowUp') {
      if (selectedSuggestionRef.current) return setSelectedSuggestion((prev) => prev - 1);
      return setSelectedSuggestion(displayedSuggestionsRef.current.length - 1);
    }
    // Pressed ArrowDown
    if (
      selectedSuggestionRef.current === null ||
      selectedSuggestionRef.current === displayedSuggestionsRef.current.length - 1
    ) {
      return setSelectedSuggestion(0);
    }
    setSelectedSuggestion((prev) => prev + 1);
  };

  const placeCaretAtEnd = (el) => {
    el.focus();
    if (typeof window.getSelection !== "undefined"
        && typeof document.createRange !== "undefined") {
      const range = document.createRange();
      range.selectNodeContents(el);
      range.collapse(false);
      const sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
    } else if (typeof document.body.createTextRange !== "undefined") {
      const textRange = document.body.createTextRange();
      textRange.moveToElementText(el);
      textRange.collapse(false);
      textRange.select();
    }
  }

  const handleSuggestionClick = (suggestion) => {
    const withoutSpace = htmlRef.current.replaceAll('&nbsp;', ' ');
    const nearesEnvIndex = findNearestSubstringIndex(withoutSpace, 'env.', caretPositionRef.current);
    const match = withoutSpace.slice(nearesEnvIndex).match(/\benv\.(\w+)\b/);
    const searchValue = match ? match[1] : '';
    const newHtml = insert(withoutSpace, caretPositionRef.current, suggestion.name.replace(searchValue, ''));
    const tokenized = getTokinized(newHtml, tokens);
    setHtml(tokenized);
    setDisplayedSuggestions([]);
    textInputRef.current.focus();
    placeCaretAtEnd(textInputRef.current);
  };

  const handleKeyDown = (e) => {
    switch (e.code) {
      case 'Tab':
        e.preventDefault();
        if (displayedSuggestionsRef.current.length === 0 || selectedSuggestionRef.current === null) break;
        handleSuggestionClick(displayedSuggestionsRef.current[selectedSuggestionRef.current || 0]);
        break;
      case 'ArrowDown':
      case 'ArrowUp':
        if (displayedSuggestionsRef.current.length === 0) break;
        e.preventDefault();
        handlePressArrow(e.code);
        break;
      case 'Enter':
        // disable next line on press "Enter"
        if (!multiline) e.preventDefault();
        // eslint-disable-next-line max-len
        if (displayedSuggestionsRef.current.length === 0 || selectedSuggestionRef.current === null) break;
        handleSuggestionClick(displayedSuggestionsRef.current[selectedSuggestionRef.current || 0]);
        break;
      default:
    }
  };

  // useOutsideClick
  const handleClickOutside = (e) => {
    if (!textInputRef.current.contains(e.target) && !suggestionsNodeRef.current.contains(e.target)) {
      setDisplayedSuggestions([]);
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
    <div className={cn(style.input, {
            // [style.leftPadding]: !!iconLeft,
            [style.rightPadding]: !!iconRight,
            [style.filled]: !!html,
            [style.error]: error,
            'single-line': !multiline,
            [style.focused]: focused,
          })}>
      <ContentEditable
      className={cn(style.input2, {
        // [style.leftPadding]: !!iconLeft,
        [style.rightPadding]: !!iconRight,
        [style.filled]: !!html,
        [style.error]: error,
        'single-line': !multiline,
      })}
          data-placeholder={placeholder}
          innerRef={textInputRef}
          html={html}
          disabled={false}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          tagName="pre"
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
        />
    </div>
      <ul
        ref={suggestionsNodeRef}
        className={style.suggestions}
        style={{ display: displayedSuggestions.length > 0 ? 'block' : 'none' }}
      >
        {displayedSuggestions.map((suggestion, i) => (
          <li
            className={cn(style.suggestion, { [style.selectedSuggestion]: i === selectedSuggestion })}
            key={suggestion.id}
            onClick={() => handleSuggestionClick(suggestion)}
          >
            {suggestion.name}
          </li>
        ))}
      </ul>
    </>
  );
}

export const Trash = {
  getTrashRequest: 'GET_TRASH_REQUEST',
  getTrashSuccess: 'GET_TRASH_SUCCESS',
  getTrashError: 'GET_TRASH_ERROR',

  getTrashFolderRequest: 'GET_TRASH_FOLDER_REQUEST',
  getTrashFolderSuccess: 'GET_TRASH_FOLDER_SUCCESS',
  getTrashFolderError: 'GET_TRASH_FOLDER_ERROR',

  removeTrashItemsRequest: 'REMOVE_TRASH_ITEMS_REQUEST',
  removeTrashItemsSuccess: 'REMOVE_TRASH_ITEM_SUCCESS',

  restoreTrashItemsRequest: 'RESTORE_TRASH_ITEMS_REQUEST',
  restoreTrashFoldersRequest: 'RESTORE_TRASH_FOLDERS_REQUEST',

  removeTrashFolderRequest: 'REMOVE_TRASH_FOLDER_REQUEST',
  removeTrashFolderSuccess: 'REMOVE_TRASH_FOLDER_SUCCESS',

  searchTrashRequest: 'SEARCH_TRASH_REQUEST',
  searchTrashSuccess: 'SEARCH_TRASH_SUCCESS',
};

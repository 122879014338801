/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ActionText from '../ActionText/ActionText';
import Error from '../../pictures/error-circle.svg';
import Alert from '../../pictures/icn-alert.svg';
import Close from '../../pictures/close.svg';

import styles from './ValidationErrorList.module.scss';

const transformErrors = (errors) => Object.keys(errors).reduce((acc, key) => {
    if (Array.isArray(errors[key])) {
      errors[key].forEach((item, index) => {
        if (item && typeof item === 'object') {
          Object.keys(item).forEach((subKey) => {
            acc[`${key}.${index}.${subKey}`] = item[subKey];
          });
        }
      });
    } else if (errors[key] !== null) {
      acc[key] = errors[key];
    }
    return acc;
  }, {});

function ValidationErrorList({ errors, setSubroute, subrouteMapping }) {
    const [position, setPosition] = useState({ x: -230, y: -10 });
    const [messageVisible, setMessageVisible] = useState(false);
    const transformedErrors = transformErrors(errors);
    const errorCount = Object.keys(transformedErrors).filter(key => transformedErrors[key]).length;
    const handleDragStart = (e) => {
        const offsetX = e.clientX - position.x;
        const offsetY = e.clientY - position.y;
    
        const handleDragMove = (e) => {
          const x = e.clientX - offsetX;
          const y = e.clientY - offsetY;
    
          setPosition({ x, y });
        };
    
        const handleDragEnd = (e) => {
          e.stopPropagation();
          document.removeEventListener('mousemove', handleDragMove);
          document.removeEventListener('mouseup', handleDragEnd);
        };
    
        document.addEventListener('mousemove', handleDragMove);
        document.addEventListener('mouseup', handleDragEnd);
      };
    useEffect(() => {

    }, [errorCount]);

    const scrollToKey = (key) => {
        if (subrouteMapping && subrouteMapping[key]) {
            setSubroute(subrouteMapping[key]);
            setTimeout(() => {
                const el = document.getElementById(key);
                if (el) {
                    el.scrollIntoView({ 
                        behavior: 'smooth', 
                        block: 'center',
                    });
                }
            }, 100)
        }
        const el = document.getElementById(key);
        if (el) {
            el.scrollIntoView({ 
                behavior: 'smooth', 
                block: 'center',
            });
        }
    }
    return (
        <div style={{ position: 'relative' }}>
            {errorCount > 0 ? (
                <div 
                  className={styles.iconWrapper} 
                  role="button" 
                  tabIndex={0}
                  onClick={() => setMessageVisible(true)}
                  onKeyDown={() => setMessageVisible(true)}
                >
                    <Error />
                    <div className={styles.errorCount}>
                        {errorCount}
                    </div>
                </div>
            ) : null}
                {(messageVisible && errorCount) ? (
                        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                        <div 
                         className={styles.messageWrapper}
                         style={{
                            position: 'absolute',
                            left: position.x,
                            top: position.y,
                         }}
                         onMouseDown={handleDragStart}
                        >
                            <div className={styles.message}>
                                <div className={styles.messageLeft}>
                                    <Alert />
                                    <div className={styles.messageContent}>
                                        <h4>
                                          {`${errorCount} form validations error:`}
                                        </h4>
                                        <ul>
                                            {Object.keys(transformedErrors).map((key, i) => {
                                                if (transformedErrors[key]) {
                                                    return (
                                                        <li key={key} className={styles.messageItem}>
                                                            {`${i + 1}.`}
                                                            {' '}
                                                            <span>
                                                               {transformedErrors[key]}
                                                               {': '}
                                                               <ActionText 
                                                                 style={{ fontWeight: 600 }} 
                                                                 onClick={() => scrollToKey(key)}
                                                                 title="Link" 
                                                                 />
                                                            </span>
                                                        </li>
                                                    );
                                                }
                                                return null;
                                            })}
                                        </ul>
                                    </div>
                                </div>
                                <div className={styles.messageRight}>
                                    <div 
                                        className={styles.closeIcon} 
                                        role="button" 
                                        tabIndex={0}
                                        onClick={() => setMessageVisible(false)}
                                        onKeyDown={() => setMessageVisible(false)}
                                        >
                                        <Close />
                                    </div>
                                </div>
                            </div>
                        </div>
                ) : null}
        </div>
    );
}

ValidationErrorList.propTypes = {
    errors: PropTypes.shape({
        [PropTypes.string]: PropTypes.any,
    }),
    subrouteMapping: PropTypes.shape({
        [PropTypes.string]: PropTypes.string, 
    }),
    setSubroute: PropTypes.func.isRequired
  };

export default ValidationErrorList;

/* eslint-disable max-len */
import { ActiveSearchProject } from './constants';

export const activeSearchProjectRequest = (data, callback) => ({ type: ActiveSearchProject.activeSearchProjectRequest, data, callback });
export const activeSearchProjectSuccess = (data) => ({ type: ActiveSearchProject.activeSearchProjectSuccess, data });
export const activeSearchProjectError = () => ({ type: ActiveSearchProject.activeSearchProjectError });

export const getAccessUsersProjectSuccess = (data) => ({ type: ActiveSearchProject.getAccessUsersProjectSuccess, data });

export const getConnectedConnectionsToProjectRequest = (data, callback) => ({ type: ActiveSearchProject.getConnectedConnectionsToProjectRequest, data, callback });
export const getConnectedConnectionsToProjectSuccess = (data) => ({ type: ActiveSearchProject.getConnectedConnectionsToProjectSuccess, data });

export const getConnectedEndpointsToProjectRequest = (data, callback) => ({ type: ActiveSearchProject.getConnectedEndpointsToProjectRequest, data, callback });
export const getConnectedEndpointsToProjectSuccess = (data) => ({ type: ActiveSearchProject.getConnectedEndpointsToProjectSuccess, data });

/* eslint-disable max-len */
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import cn from 'classnames';
import ActionIcon from '../../components/ActionIcon/ActionIcon';
import ActionText from '../../components/ActionText/ActionText';
import RemoveModal from '../../components/RemoveVariablesModal';
import { createToastRequest } from '../../store/toasts/actions';

import { useCopyToClipboard } from '../../hooks/hooks';
import InviteToResourceModal from '../../components/InviteToResourceModal/InviteToResourceModal';
import ExportModal from '../../components/ExportVariableModal';
import Directory from './atoms/Directory/Directory';
import Loader from '../../components/Loader';
import Button from '../../components/Button/Button';
import Input from '../../components/Input/Input';
import Checkbox from '../../components/Checkbox/Checkbox';
import {
  searchVariablesRequest,
  copyVariableRequest,
  exportVariableRequest,
  getVariableFolderContentRequest,
} from '../../store/variables/actions';
import { invitesRequest, addAccessRequest } from '../../store/invites/actions';
import {
  getAllData,
  checker,
} from '../../helpers/utils';
import { isRoleAction } from '../../helpers/hocs';

import SortIcon from '../../pictures/sort.svg';

import 'react-tooltip/dist/react-tooltip.css';
import * as shared from '../../styles/shared.module.scss';
import { itemsHeightPixels, folderHeightPixels, itemsWidthPixels } from '../../components/ContextMenu';

const headers = {
  checkbox: { title: '' },
  name: { title: 'Name' },
  value: { title: 'Value' },
  owner: { title: 'Owner' },
  status: { title: 'Status' },
  action: { title: 'Created' },
};

let searchTimeout = null;

function VariablesList({
  getFolder,
  searchVariables,
  variables,
  activeProject,
  isRoleAction,
  copyVariable,
  exportVariable,
  createToast,
  addAccess,
}) {
  const [loading, setLoading] = useState(true);
  const { data: variablesData = [] } = variables || {};
  const [activeModel, setActiveModel] = useState({});
  const [inputVisible, setInputVisible] = useState(false);

  const [, copyText] = useCopyToClipboard();

  const [searchValue, setSearchValue] = useState('');
  const [removeModalOpened, setRemoveModalOpened] = useState(false);
  const [shareModalOpened, setShareModalOpened] = useState(false);
  const [exportModalOpened, setExportModalOpened] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [sortBy, setSortBy] = useState('name');
  const ref = useRef(null);
  const tableRef = useRef(null);
  const { shortName } = useParams();
  const navigate = useNavigate();

  const [contextMenuModelActive, setContextMenuModelActive] = useState(false);
  const [contextMenuTop, setContextMenuTop] = useState(0);
  const [contextMenuLeft, setContextMenuLeft] = useState(0);

  const handleContextMenu = (event, id, isFolder) => {
    event.preventDefault();

    setContextMenuModelActive(id);

    const { clientX, clientY } = event;

    const neededHeight = isFolder ? folderHeightPixels : itemsHeightPixels;
    const neededWidth = itemsWidthPixels;

    const w = window.innerWidth;
    const h = window.innerHeight;

    const isHeightFit = (h - clientY) > neededHeight;
    const isWidthFit = (w - clientX) > neededWidth;

    setContextMenuTop(isHeightFit ? clientY : clientY - neededHeight);
    setContextMenuLeft(isWidthFit ? clientX : clientX - neededWidth);
  };

  useEffect(() => {
    setLoading(true);
    if (activeProject?.id) {
      setSelectedItems([]);

      getFolder({
        folder: 0,
        projectId: activeProject?.id,
      }, () => {
        setLoading(false);
      });
    }
  }, [activeProject?.id, shortName]);

  const createVariable = () => (navigate(`/${shortName}/variables/create`));
  const importVariable = () => navigate(`/${shortName}/variables/import`);
  const modifyVariable = (variableId) => navigate(`/${shortName}/variables/${variableId}`, { state: { redirectFolder: 0 } });

  const changeSearch = (e) => {
    setSearchValue(e.target.value);
    if (searchTimeout) clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => {
      setLoading(true);
      setSelectedItems([]);
      if (e.target.value) {
        searchVariables({
          query: e.target.value,
          projectId: activeProject.id,
        },() => {
          setLoading(false);
        });
      } else {
        getFolder({
          folder: 0,
          projectId: activeProject?.id,
        }, () => {
          setLoading(false);
        });
      }
    }, 400);
  };

  const copyCon = (model) => {
    copyVariable(
      {
        ...model,
        projectId: activeProject.id,
        rootAction: true,
      },
      (access) => {
        console.log('access', access);
      },
    );
  };

  const clear = () => {
    setSearchValue('');
    setLoading(true);
    setSelectedItems([]);
    getFolder({
      folder: 0,
      projectId: activeProject?.id,
    },() => {
      setLoading(false);
    });
    setInputVisible(false);
  };

  const removeOne = (type, model) => {
    setActiveModel({
      variables: [model.id],
    });
    setRemoveModalOpened(true);
  };

  const removeSelected = () => {
    setActiveModel({
      variables: selectedItems,
      folders: [],
    });
    setRemoveModalOpened(true);
  };

  const clickShare = (model) => {
    setActiveModel(model);
    setShareModalOpened(true);
  };

  const exportSelected = () => {
    setActiveModel({
      variables: selectedItems,
      folders: [],
    });
    setExportModalOpened(true);
  };

  const exportOne = (type, model) => {
    setActiveModel({
      variables: [model.id],
      folders: [],
    });
    setExportModalOpened(true);
  };

  const handleClickOutside = (event) => {
    if (ref && ref.current && !ref.current.contains(event.target)) {
      const input = ref.current.getElementsByTagName('input')[0];
      if (input.value) return;
      setInputVisible(false);
    }

    if (tableRef && tableRef.current && !tableRef.current.contains(event.target)) {
      setContextMenuModelActive(false);
    }
  };

  const sendInviteAction = (data) => {
    // sendInvite(data, () => {
    //   setShareModalOpened(false);
    // });
  };

  const exportC = (list, linkApi = false, linkConnections = false, linkAuthKeys = false) => {
    const { variables } = list || {};
    exportVariable({
      projectId: activeProject?.id,
      linkApi,
      linkConnections,
      linkAuthKeys,
      resource: 'variable',
      objects: [
        ...variables.map((el) => ({ id: el, type: 'variable' })),
      ],
    }, (props) => {
      const { file, name } = props || {};
      const url = window.URL.createObjectURL(file);
      const a = document.createElement('a');
      a.href = url;
      a.download = name;
      a.click();
    });
  };

  const selectAll = (isChecked) => {
    if (isChecked) {
      const allIdsVariables= getAllData(variables);
      setSelectedItems(allIdsVariables);
    } else {
      setSelectedItems([]);
    }
  };

  const selectOne = (isChecked, id) => {
    if (isChecked) {
      setSelectedItems([...selectedItems, id]);
    } else {
      setSelectedItems(selectedItems.filter((c) => c !== id));
    }
  };

  const clipboardCopy = (value) => {
    const result = copyText(value)
    if (result) createToast({ type: 'success', text: 'Copied' });
  };

  const renderHeader = (h) => {
    if (h === 'action' && selectedItems.length > 0) {
      return (
        <th key={headers[h].title} style={{ justifyContent: 'flex-end' }}>
          <ActionIcon
            id="exportSelected"
            icon="export"
            style={{ width: '20px', marginRight: '16px' }}
            hiddenFill
            onClick={exportSelected}
            tooltip={{
              content: 'Export',
            }}
          />
          <ActionIcon
            id="deleteSelected"
            icon="trash"
            onClick={removeSelected}
            tooltip={{
              content: 'Delete',
            }}
          />
        </th>
      );
    } if (h !== 'checkbox') {
      return (
        <th key={headers[h].title}>
          <span>{headers[h].title}</span>
          {' '}
          <SortIcon />
        </th>
      );
    }
    return null;
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return (
    <div className={shared.page}>
      <div className={shared.header}>
        <div className={shared.headerTitleGroup}>
          <h1 className={shared.headerTitle}>Variables</h1>
        </div>
        <div className={shared.headerButtonGroup}>
        {inputVisible ? (
            <div style={{
                flexGrow: 1,
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <div ref={ref} style={{ width: '100%', maxWidth: '426px'}}>
              <Input
                placeholder="Search"
                iconLeft="search"
                autoFocus
                iconRight={searchValue && 'close'}
                handleChange={changeSearch}
                value={searchValue}
                handleAction={clear}
              />
              </div>
            </div>
          ) : (
            <Button type="secondary" iconLeft="search" onClick={() => setInputVisible(true)} />
          )}
          <Button
            id="import_btn"
            type="secondary"
            title="Import"
            onClick={importVariable}
            tooltip={{
              content: 'Import',
            }}
          />
          <Button
            id="export_btn"
            type="secondary"
            title="Export"
            onClick={() => selectedItems.length > 0 && exportSelected()}
            style={{ opacity: selectedItems.length > 0 ? '1' : '0.4' }}
            tooltip={{
              content: 'Export',
            }}
            disabled={selectedItems.length === 0}
          />
          {isRoleAction({ key: 'key', role: 'create' })
            && <Button title="Create variable" onClick={createVariable} />}
        </div>
      </div>
      <div className={shared.table}>
        <table className={shared.connectionTable}>
          <thead>
            <tr className={shared.keysTableRowKeys}>
              <th key="checkbox">
                <Checkbox
                  handleChange={selectAll}
                  size="small"
                  value={
                    (variablesData?.length)
                    && checker(selectedItems, variablesData)
                  }
                  style={{ marginRight: '0' }}
                  disabled={!variablesData?.length}
                />
              </th>
              {Object.keys(headers).map((h, i, arr) => renderHeader(h, i, arr))}
            </tr>
          </thead>
          {(variablesData?.length) && !loading ? (
            <tbody
              ref={tableRef}
              onClick={() => setContextMenuModelActive(false)}
              className={cn({ [shared.hideScroll]: contextMenuModelActive })}
            >
              <Directory
                authKeysData={variablesData}
                selectedItems={selectedItems}
                handleContextMenu={handleContextMenu}
                setContextMenuModelActive={setContextMenuModelActive}
                contextMenuModelActive={contextMenuModelActive}
                contextMenuTop={contextMenuTop}
                contextMenuLeft={contextMenuLeft}
                modifyAuthKey={modifyVariable}
                selectOne={selectOne}
                clickShare={clickShare}
                isRoleAction={isRoleAction}
                exportOne={exportOne}
                removeOne={removeOne}
                copyCon={copyCon}
                clipboardCopy={clipboardCopy}
              />
            </tbody>
          ) : null}
        </table>
        {loading && (
        <div className={shared.emptyContent}>
          <Loader size="large" />
        </div>
        )}
        {(!variablesData?.length) && searchValue && !loading && (
          <div className={shared.emptyContent}>
            <h3 className={shared.caption}>No variables found</h3>
            <p>
              Please change your search query and try again
            </p>
          </div>
        )}
        {!variablesData?.length && !searchValue && !loading && (
          <div className={shared.emptyContent}>
            <h3 className={shared.caption}>This project has no Variables yet</h3>
            <p>
                Please create your variable to continue.
            </p>
            <div className={shared.action}>
              <ActionText
                title="Click here to create variable"
                onClick={createVariable}
              />
            </div>
          </div>
        )}
        {removeModalOpened && (
          <RemoveModal
            onClose={() => setRemoveModalOpened(false)}
            onSubmit={() => {
              setRemoveModalOpened(false);
              setSelectedItems([]);
            }}
            model={activeModel}
            collection={variables}
          />
        )}
        {shareModalOpened && (
          <InviteToResourceModal
            id={activeModel.id}
            onClose={() => setShareModalOpened(false)}
            onSubmit={sendInviteAction}
            model={activeModel}
            type="variable"
            inviteList={[]}
          />
        )}
        {exportModalOpened && (
          <ExportModal
            onClose={() => setExportModalOpened(false)}
            onSubmit={exportC}
            model={activeModel}
            collection={variables}
          />
        )}
      </div>
    </div>
  );
}

const mapStateToProps = ({
  variables, activeProject, roles, auth: { currentUser }
}) => ({
  activeProject,
  roles,
  variables,
  currentUser
});

const mapDispatchToProps = (dispatch) => ({
  getFolder: (data, callback) => dispatch(getVariableFolderContentRequest(data, callback)),
  addAccess: (data, callback) => dispatch(addAccessRequest(data, callback)),
  copyVariable: (data, callback) => dispatch(copyVariableRequest(data, callback)),
  exportVariable: (data, callback) => dispatch(exportVariableRequest(data, callback)),
  searchVariables: (data, callback) => dispatch(searchVariablesRequest(data, callback)),
  sendInvite: (data, callback) => dispatch(invitesRequest(data, callback)),
  createToast: (data) => dispatch(createToastRequest(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(isRoleAction(VariablesList));

/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import debounce from 'lodash.debounce';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import cn from 'classnames';
import ValidationErrorList from '../../components/ValidationErrorList/ValidationErrorList';
import { useCopyToClipboard } from '../../hooks/hooks';
import Button from '../../components/Button/Button';
import ActionText from '../../components/ActionText/ActionText';
import ChipsStatus from '../../components/ChipsStatus/ChipsStatus';
import Input from '../../components/Input/Input';
import Select from '../../components/Select/Select';
import { parseIfJSON , getProjectRoles, getVariableModelValue, convertValue } from '../../helpers/utils';
import RemoveAccessModal from '../../components/RemoveAccessModal/RemoveAccessModal';
import InviteToResourceModal from '../../components/InviteToResourceModal/InviteToResourceModal';
import {
  listRolesName, typesAPI,
} from '../../helpers/const';
import { isRoleAction } from '../../helpers/hocs';
import { updateVariableRequest } from '../../store/variables/actions';
import { activeVariableRequest, getConnectedModelsToVariablesRequest } from '../../store/activeVariable/actions';
import { getAccessUsersRequest } from '../../store/users/actions';
import { addAccessRequest } from '../../store/invites/actions';
import { createToastRequest } from '../../store/toasts/actions';
import CodeEditor from '../../components/CodeEditor';
import { useMonaco, useMonacoErrors } from '../../hooks/useMonaco';
import { ViewModes } from '../../components/CodeEditor/codeEditorConstants';


import * as shared from '../../styles/shared.module.scss';
import DBIcon from '../../components/DBIcon/DBIcon';

const maxAllowedLength = 64;

const varTypes = [
  { value: 'string', title: 'String' },
  { value: 'number', title: 'Number' },
  { value: 'boolean', title: 'Boolean' },
  { value: 'array', title: 'Array' },
  { value: 'object', title: 'Object' },
]

const SUBROUTING = {
  ACCESS: 'access',
};

const booleanList = [
  { value: 'true', title: 'true' },
  { value: 'false', title: 'false' }
];

const subrouteMapping = {};

const AuthSchema = () => Yup.object().shape({
  name: Yup
    .string()
    .min(2, 'Name length should be at least 2 characters')
    .max(64)
    .matches(/^[a-zA-Z0-9$_]+$/, 'Name can only contain alphanumeric characters, $ and _')
    .required('Name required'),
  type: Yup.string().required('Type required'),
  value: Yup.mixed().when('type', {
    is: 'string',
    then: Yup.string().required('Value required'),
    otherwise: Yup.mixed().when('type', {
      is: 'number',
      then: Yup.number('Provide valid number value').required('Value required'),
      otherwise: Yup.mixed().when('type', {
        is: 'object',
        then: Yup.string()
          .required('Value required')
          .test('is-json', 'Value must be a valid non Array JSON object', (value) => {
            try {
              const result = JSON.parse(value);
              return result && typeof result === 'object' && !Array.isArray(result) && result !== null;
            } catch {
              return false;
            }
          }),
        otherwise: Yup.mixed().when('type', {
          is: 'array',
          then: Yup.string()
            .required('Value required')
            .test('is-json', 'Value must be a valid JSON array', (value) => {
              try {
                const result = JSON.parse(value);
                return Array.isArray(result);
              } catch {
                return false;
              }
            }),
          otherwise: Yup.mixed().when('type', {
            is: 'boolean',
            then: Yup.boolean('Provide valid boolean value').required('Value required'),
            otherwise: Yup.mixed().notRequired(),
          }),
        }),
      }),
    }),
  }),
});


function VariableModify({
  connectedEndpoints: endpointsList,
  connectedConnections: connectionsList,
  getConnectedModels,
  updateVariable,
  getActiveVariable,
  activeVariable,
  currentUser,
  createToast,
  activeProject,
  getAccessUsers,
  addAccess,
  roles,
  isRoleAction,
  storedInviteList,
}) {
  useMonaco({ hideVariables: true });
  const { showMonacoErrors } = useMonacoErrors();
  const navigate = useNavigate();
  const [subroute, setSubroute] = useState(SUBROUTING.ACCESS);
  const { shortName, resourceId } = useParams();
  const [shareModalOpened, setShareModalOpened] = useState(false);
  const [inviteList, setInviteList] = useState([]);
  const [removeModalOpened, setRemoveModalOpened] = useState(false);
  const [activeUser, setActiveUser] = useState({});
  const [, copyText] = useCopyToClipboard();
  const [isChange, setIsChange] = useState(false);
  const viewRead = !isRoleAction({ key: 'variable', role: 'update', modelRole: activeVariable?.role });
  const [submitTriggered, setSubmitTriggered] = useState(false);

  useEffect(() => {
    getAccessUsers({
      id: +resourceId,
      resource: 'variable',
    });
  }, []);

  useEffect(() => {
      setInviteList(storedInviteList || []);
  }, [storedInviteList]);


  const {
    submitForm, handleChange: handleChange2, setFieldValue: setFieldValue2, 
    values, errors, validateForm, setValues
  } = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema: AuthSchema(),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (v) => {
      const req = {
        name: v.name,
        description: v.description,
        type: v.type,
        value: v.value,
        projectId: +activeProject.id,
        id: +resourceId,
      };

      updateVariable(req, () => {
        setIsChange(false);
      });
    },
  });

  const debouncedValidateForm = debounce(() => {
    validateForm();
  }, 100);

  const setFieldValue = (...args) => {
    setFieldValue2(...args);
    if (submitTriggered) {
      debouncedValidateForm();
    }
  }

  const handleChange = (...args) => {
    handleChange2(...args);
    if (submitTriggered) {
      debouncedValidateForm();
    }
  }

  const handleSubmitWithCallback = (e) => {
    if(showMonacoErrors()) return
    e.preventDefault();
    setSubmitTriggered(true);
    submitForm(e);
  };

  useEffect(() => {
    getActiveVariable({ id: resourceId });
  }, [resourceId]);


  useEffect(() => {
    if (activeVariable && !activeVariable.deleted) {
      getConnectedModels( {id: resourceId });
    }
  }, [activeVariable]);

  useEffect(() => {
    if (activeVariable) {
      const {
        name,
        description,
        type,
      } = activeVariable || {};

      const baseParam = {
        type,
        value: getVariableModelValue(activeVariable),
        name,
        description,
      }

      setValues({
        ...baseParam,
      });

    }
  }, [activeVariable]);

  const listAPI = () => navigate(`/${shortName}/variables`);

  const handleChangeProxy = (...arg) => {
    setIsChange(true);
    return handleChange(...arg);
  };

  const setFieldValueProxy = (...arg) => {
    setIsChange(true);
    return setFieldValue(...arg);
  };

  const deleteAccess = (user) => {
    const newInviteList = inviteList.filter((el) => el.email !== user.email);
    setInviteList(newInviteList);
    setRemoveModalOpened(false);
    setActiveUser({});
  };

  const handeChangeRole = (user, role) => {
    if (role === 'delete') {
      setRemoveModalOpened(true);
      setActiveUser(user);
      return;
    }

    const newInviteList = inviteList.map((el) => (el.email === user.email ? { ...el, role } : el));
    addAccess({
      id: +resourceId,
      resource: 'variable',
      projectId: +activeProject.id,
      role,
      userId: user.id,
    }, () => {
      setInviteList(newInviteList);
    });
  };

  const addUserOfApiKey = () => {
    setShareModalOpened(true);
  };

  const getRoles = () => {
    const rolesList = [];
    const filteredRoles = getProjectRoles(roles, activeProject.id).filter((r) => r.name !== 'owner');
    if (isRoleAction({ key: 'variable', role: 'share', modelRole: activeVariable?.role })) {
      rolesList.push(...filteredRoles.map((r) => ({ value: r.name, title: listRolesName[r.name] || r.name })));
    } else {
      rolesList.push(...filteredRoles.map((r) => ({ value: r.name, title: listRolesName[r.name] || r.name, disabled: true })));
    }
    if (isRoleAction({ key: 'variable', role: 'delete', modelRole: activeVariable?.role })) {
      rolesList.push({ value: 'delete', title: 'Delete', hidden: currentUser.role === 'viewer', red: true  });
    }
    return rolesList;
  };

  const saveInviteList = () => {
    // const filtered = data.filter((el) => el.id);
    // const unique = Object.values(
    //   [...inviteList, ...filtered].reduce((acc, obj) => ({ ...acc, [obj.email]: obj }), {}),
    // );
    // setInviteList(unique);
    getAccessUsers({
      id: +resourceId,
      resource: 'variable',
    });
    setShareModalOpened(false);
  };

  const handleChangeName = ({ target }) => {
    const regex = /^(?!\s)(?!.* {2})/;
    if (regex.test(target.value) && target.value.length <= maxAllowedLength) {
      setIsChange(true);
      setFieldValue(target.name, target.value);
    }
  };

  return (
    <div className={shared.page} style={{ overflow: 'auto' }}>
      <div className={cn(shared.header, shared.borderBottom)} style={{ position: 'sticky', top: 0, zIndex: 9999, backgroundColor: '#fff' }}>
        <div className={shared.headerTitleGroup}>
          <h1 className={shared.headerTitle}>Edit Variable</h1>
        </div>
        <div className={shared.headerButtonGroup}>
            <ValidationErrorList 
              errors={errors} 
              subrouteMapping={subrouteMapping}
              setSubroute={setSubroute}
            />
            <Button title="Cancel" type="secondary" onClick={listAPI} />
            <Button title="Edit variable" onClick={handleSubmitWithCallback} disabled={viewRead || !isChange} />
        </div>
      </div>
      <div className={cn(shared.body)} style={{ border: 0, flexDirection: 'column' }}>
        <div style={{ display: 'flex' }}>

          <div className={cn(shared.mainContent)} style={{ minHeight: 'unset', maxHeight: 'unset' }}>
          <section className={shared.section}>
              <h4 className={shared.sectionHeader}>Variable Settings</h4>
              <div className={shared.sectionContainer}>
                <div className={shared.inputWrapper}>
                  <span className={shared.inputLabel}>Name</span>
                  <Input
                    placeholder="Enter name"
                    handleChange={handleChangeName}
                    value={values.name}
                    name="name"
                    id="name"
                    error={!!errors.name}
                    disabled={viewRead}
                  />
                </div>
                <div className={shared.inputWrapper}>
                  <span className={shared.inputLabel}>
                    Description 
                    {' '}
                    <span className={shared.inputLabelDetails}> (optional)</span>
                  </span>
                  <Input
                    placeholder="Enter description"
                    handleChange={handleChangeProxy}
                    value={values.description}
                    name="description"
                    id="description"
                    disabled={viewRead}
                  />
                </div>

                <div className={shared.inputWrapper}>
                  <span className={shared.inputLabel}>
                    Type 
                  </span>
                  <Select
                    list={varTypes}
                    value={values.type}
                    handleChange={(...e) => {
                      setFieldValueProxy(...e);
                      setFieldValueProxy('value',
                        ['array', 'object'].includes(e[1])
                          ? JSON.stringify(convertValue(parseIfJSON(values.value), e[1]))
                          : convertValue(values.value, e[1]));
                    }}
                    placeholder="Select type"
                    name="type"
                    id="type"
                    error={!!errors.type}
                    disabled={viewRead}
                  />
                </div>

                <div className={shared.inputWrapper}>
                  <span className={shared.inputLabel}>
                    Value
                  </span>
                  {values.type === 'boolean' ? (
                  <Select
                    list={booleanList}
                    value={values.value}
                    handleChange={setFieldValueProxy}
                    name="value"
                    id="value"
                    placeholder="Select value"
                    error={!!errors.value}
                    disabled={viewRead}
                  />
                ) : values.type === 'object' || values.type === 'array' ? (
                  <div className={cn(shared.codeEditorWrap, shared.codeEditorWrapInline)}>
                    <CodeEditor
                      value={values.value}
                      handleBlurCallback={(value) => {
                        const name = 'value';
                        handleChangeProxy({ target: { value, name } });
                      }}
                      name="value"
                      id="value"
                      type="json"
                      placeholder="Initial value"
                      error={!!errors.value}
                      viewMode={ViewModes.Inline}
                      disabled={viewRead}
                      withVariables={false}
                    />
                  </div>
                ) : (
                  <Input
                    placeholder="Initial value"
                    handleChange={handleChangeProxy}
                    value={values.value}
                    name="value"
                    id="value"
                    type={values.type === 'number' ? 'number' : 'text'}
                    autoComplete="off"
                    iconRight={values.value && 'copy'}
                    error={!!errors.value}
                    handleAction={() => {
                      copyText(values.value);
                      createToast({ type: 'success', text: 'Copied' });
                    }}
                    disabled={viewRead}
                  />
                )}
                </div>
              </div>
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
            </section>
          </div>
          <div className={cn(shared.sidepanel)} style={{ minHeight: 'unset', maxHeight: 'unset' }}>
            <div className={shared.sidepanelGroup}>
            <div className={shared.sidepanelHeading}>
            <div className={shared.subHeaders}>
                <h4
                  role="button"
                  onClick={() => setSubroute(SUBROUTING.ACCESS)}
                  onKeyDown={() => setSubroute(SUBROUTING.ACCESS)}
                  tabIndex={0}
                  className={
                    cn(shared.sectionHeader,
                      { [shared.clickable]: true },
                      { [shared.passive]: false }
                    )}
                >
                  Access
                </h4>
                {subroute === SUBROUTING.ACCESS && isRoleAction({ key: 'variable', role: 'share', modelRole: activeVariable?.role })
                  && (
                    <div className={shared.textAction}>
                      <ActionText title="Share access" onClick={addUserOfApiKey} />
                    </div>
                  )}
              </div>
            </div>
              {subroute === SUBROUTING.ACCESS && (
                <div className={shared.sidepanelTable}>
                  <div className={shared.sidepanelTableHeading}>
                    <div className={shared.lineHeader}>
                      <div>Name</div>
                      <div />
                      <div>Role</div>
                    </div>
                  </div>
                  <div className={shared.sidepanelTableBodyContainer}>
                      <ul className={shared.sidepanelTableBody}>
                            {inviteList?.map((item) => (
                              <li className={shared.historyLine} key={item.email}>
                                <div title={item.email}>
                                  {item.email === currentUser.email ? (
                                    <span>{`${item.firstName} ${item.lastName}`}</span>
                                  ) : item.firstName || item.lastName ? (
                                    <span>{`${item.firstName} ${item.lastName} (${item.email})`}</span>
                                  ) : (
                                    <span>{item.email}</span>
                                  )}
                                </div>
                                <div />
                                <div>
                                  {viewRead ? (
                                    <ChipsStatus
                                      title={listRolesName[item.role] || item.role}
                                      type="input"
                                      className={shared.chipsStatus}
                                      style={{ width: '90px', justifyContent: 'left', paddingLeft: '12px' }}
                                    />
                                  ) : item.role === 'owner' ? (
                                    <ChipsStatus
                                      title="Owner"
                                      type="input"
                                      className={shared.chipsStatus}
                                      style={{ width: '90px', justifyContent: 'left', paddingLeft: '12px' }}
                                    />
                                  ) : (
                                    <Select
                                      style={{ width: 90 }}
                                      list={getRoles(item.role)}
                                      value={item.role}
                                      placeholder={item.role}
                                      handleChange={(email, value) => handeChangeRole(item, value)}
                                      name={item.email}
                                    />
                                  )}
                                </div>
                              </li>
                            ))}
                      </ul>
                  </div>
                </div>
              )}
            </div>
          </div>

        </div>
        <div className={shared.bottomPanel}>
          <section className={shared.section}>
            <div className={shared.sidepanelHeading} style={{ paddingTop: 0 }}>
              <h4 className={shared.sectionHeader}>
                <span>Used by API</span>
                <span>{`(${endpointsList?.length || 0})`}</span>
              </h4>
            </div>
            {endpointsList.length > 0 ? (
              <div className={shared.sidepanelTable}>
                <div className={shared.sidepanelTableHeading} style={{ borderBottom: 'none' }}>
                  <div className={shared.line}>
                    <div>Name</div>
                    <div>Method</div>
                    <div style={{ maxWidth: 90 }}>Role</div>
                  </div>
                </div>
                <hr />
                <ul className={shared.sidepanelTableBody}>
                  {endpointsList?.map((item) => (
                    <li className={shared.line} key={item.id}>
                      <div title={item.name}>
                        <a href={`/${shortName}/endpoints/${item.id}`} target="_blank" rel="noreferrer">
                          {item.name}
                        </a>
                      </div>
                      <div className={shared.uppercased}>
                        {item.method}
                      </div>
                      <div style={{ justifyContent: 'flex-end', maxWidth: 90 }}>
                        {item.role === 'owner' ? (
                          <Select
                            list={[{ value: 'owner', title: 'Owner' }]}
                            value={item.role}
                            handleChange={() => { }}
                            name={item.email}
                            disabled
                          />
                        ) : (
                          <Select
                            list={getRoles('endpoint')}
                            value={item.role}
                            handleChange={(email, value) => handeChangeRole(item, value, 'endpoint')}
                            name={item.email}
                            disabled
                          />
                        )}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            ) : (
              <div className={shared.table}>
                <div className={shared.emptyContent} style={{ marginTop: '60px' }}>
                  <h3 className={shared.caption}>This variable doesn &apos;t used in APIs yet</h3>
                </div>
              </div>
            )}
          </section>
          <div className={shared.vertical} />
          <section className={shared.section}>
            <div className={shared.sidepanelHeading} style={{ paddingTop: 0 }}>
              <h4 className={shared.sectionHeader}>
                <span>Used by Connections</span>
                <span>{`(${connectionsList?.length || 0})`}</span>
              </h4>
            </div>
            {connectionsList.length > 0 ? (
              <div className={shared.sidepanelTable}>
                <div className={shared.sidepanelTableHeading} style={{ borderBottom: 'none' }}>
                  <div className={shared.line}>
                    <div>Name</div>
                    <div>Type</div>
                    <div style={{ maxWidth: 90 }}>Role</div>
                  </div>
                </div>
                <hr />
                <ul className={shared.sidepanelTableBody}>
                  {connectionsList.map((item) => (
                    <li className={shared.line} key={item.id}>
                      <div title={item.name}>
                        <a href={`/${shortName}/connections/${item.id}`} target="_blank" rel="noreferrer">
                          {item.instanceName}
                        </a>
                      </div>
                      <div>
                        <DBIcon type={item.type} style={{ marginRight: '12px' }} />
                        <span>{typesAPI[item.type]}</span>
                      </div>
                      <div style={{ justifyContent: 'flex-end', maxWidth: 90 }}>
                        {item.role === 'owner' ? (
                          <Select
                            list={[{ value: 'owner', title: 'Owner' }]}
                            value={item.role}
                            handleChange={() => { }}
                            name={item.email}
                            disabled
                          />
                        ) : (
                          <Select
                            list={getRoles('connection')}
                            value={item.role}
                            handleChange={(email, value) => handeChangeRole(item, value, 'connection')}
                            name={item.email}
                            disabled
                          />
                        )}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            ) : (
              <div className={shared.table}>
                <div className={shared.emptyContent} style={{ marginTop: '60px' }}>
                  <h3 className={shared.caption}>This variable doesn &apos;t used in connections yet</h3>
                </div>
              </div>
            )}
          </section>
        </div >
        {shareModalOpened && (
          <InviteToResourceModal
            id={resourceId}
            onClose={() => setShareModalOpened(false)}
            onSubmit={saveInviteList}
            model={values}
            type="variable"
            inviteList={inviteList?.map((i) => i.email) || []}
          />
        )}
        {removeModalOpened && (
          <RemoveAccessModal
            setIsOpen={setRemoveModalOpened}
            deleteAccessUser={deleteAccess}
            user={activeUser}
            resource="variable"
            id={resourceId}
          />
        )}
      </div>
    </div>
  );
}

const mapStateToProps = ({
  activeVariable,
  auth: { currentUser },
  activeProject,
  roles,
}) => ({
  roles,
  activeVariable: activeVariable?.activeVariable,
  connectedEndpoints: activeVariable?.connectedEndpoints,
  connectedConnections: activeVariable?.connectedConnections,
  storedInviteList: activeVariable?.inviteList,
  activeProject,
  currentUser,
});

const mapDispatchToProps = (dispatch) => ({
  updateVariable: (data, callback) => dispatch(updateVariableRequest(data, callback)),
  getActiveVariable: (data) => dispatch(activeVariableRequest(data)),
  getConnectedModels: (data) => dispatch(getConnectedModelsToVariablesRequest(data)),
  getAccessUsers: (data) => dispatch(getAccessUsersRequest(data)),
  addAccess: (data, callback) => dispatch(addAccessRequest(data, callback)),
  createToast: (data, callback) => dispatch(createToastRequest(data, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(isRoleAction(VariableModify));

/* eslint-disable no-restricted-syntax */
import {
  call, put, takeEvery, all, fork, select, takeLatest,
} from 'redux-saga/effects';
import { api, apiWorker, apiWorkerFile } from '../../helpers/api';
import {
  listConnectionsRequest, listConnectionsSuccess,
  searchConnectionsRequest, searchConnectionsSuccess,
  createConnectionRequest, createConnectionSuccess,
  removeConnectionsListRequest, removeConnectionsListSuccess,
  updateConnectionRequest, updateConnectionSuccess,
  configConnectionsRequest, configConnectionsSuccess,
  exportConnectionRequest, importConnectionRequest,
  removeConnectionFolderRequest, removeConnectionFolderSuccess,
  updateConnectionFolderRequest, updateConnectionFolderSuccess,
  createConnectionFolderRequest, createConnectionFolderSuccess,
  getConnectionFolderContentRequest, getConnectionFolderContentSuccess,
  moveConnectionFolderRequest, moveConnectionFolderSuccess,
  moveConnectionRequest, moveConnectionSuccess,
  copyConnectionFolderRequest, copyConnectionFolderSuccess,
  getConnectionFolderTreeRequest, getConnectionFolderTreeSuccess,
} from './actions';
import { createToastRequest } from '../toasts/actions';
import {
  refreshRequest,
} from '../auth/actions';
import { Connections } from './constants';
import history from '../../history';

const getAuth = (state) => state.auth?.auth;

export function* fetchConnections(ops) {
  const { data, callback } = ops;
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(api, `/connections?projectId=${data.id}`, 'GET', null, authData.access_token);
      if (response.ok) {
        const parsed = yield response.json();
        yield put(listConnectionsSuccess(parsed.items));
        if (callback) callback();
      } else if (response.status === 401) {
        yield put(refreshRequest(listConnectionsRequest, data, callback));
        if (callback) callback(true);
      } else {
        const parsed = yield response.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
        if (callback) callback(true);
      }
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* searchConnections(ops) {
  const { data, callback } = ops;
  const { projectId, query } = data;
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(api, `/search?projectId=${projectId}&query=${query}&deleted=false&filter=folderId,connection&folderType=connections`, 'GET', null, authData.access_token);
      if (response.ok) {
        const parsed = yield response.json();
        yield put(searchConnectionsSuccess(parsed));
        if (callback) callback(true);
      } else if (response.status === 401) {
        yield put(refreshRequest(searchConnectionsRequest, data, callback));
      } else {
        const parsed = yield response.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
        if (callback) callback(false);
      }
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
    if (callback) callback(false);
  }
}

export function* createConnection(ops) {
  const { data, callback } = ops;
  const { rootAction, ...otherData } = data || {};
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(api, '/connections', 'POST', otherData, authData.access_token);
      if (response.ok) {
        const parsed = yield response.json();
        parsed.role = 'owner';
        yield put(createConnectionSuccess({ ...parsed, rootAction }));
        yield put(createToastRequest({
          type: 'success',
          title: parsed.instanceName,
          text: 'connection has been created successfully',
          linkTitle: `/connections/${parsed.id}`,
        }));
        if (callback) callback(parsed);
      } else if (response.status === 401) {
        yield put(refreshRequest(createConnectionRequest, data, callback));
      } else {
        const parsed = yield response.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
      }
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* removeConnectionsList(ops) {
  const { data, callback } = ops;
  const { connections: conn, folders: fld } = data || {};
  const authData = yield select(getAuth);
  let successFolders;
  let successConnections;

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const [folders, connections] = yield all([
        fld?.length ? call(api, '/folders', 'PUT', { items: fld.map(f => ({ ...f, deleted: true, items: undefined })) }, authData.access_token) : null,
        conn?.length ? call(api, '/connections', 'PUT', { items: conn.map(c => ({ ...c, deleted: true })) }, authData.access_token) : null,
      ]);
      if (folders || connections) {
        if (folders?.ok) {
          const parsed = yield folders.json();
          successFolders = fld;
          if (parsed.errors && parsed.errors.length > 0) {
            for (const e of parsed.errors) {
              successFolders = successFolders.filter((folder) => folder.id !== e.id);
              yield put(createToastRequest({ type: 'error', text: e.error }));
            }
          }
          if (successFolders && successFolders.length) {
            for (const el of successFolders) {
              yield put(removeConnectionFolderSuccess(el));
            }
            if (successFolders.length > 1) {
              yield put(createToastRequest({ type: 'success', title: successFolders.length, text: 'folders were removed' }));
            } else {
              yield put(createToastRequest({ type: 'success', title: successFolders[0].name, text: 'folder was removed' }));
            }
          }
        }
        if (connections?.ok) {
          const parsed = yield connections.json();
          successConnections = conn;
          if (parsed.errors && parsed.errors.length > 0) {
            for (const e of parsed.errors) {
              successConnections = successConnections.filter((connection) => connection.id !== e.id);
              yield put(createToastRequest({ type: 'error', text: e.error }));
            }
          }
          if (successConnections && successConnections.length) {
            for (const el of successConnections) {
              yield put(removeConnectionsListSuccess(el));
            }
            if (successConnections.length > 1) {
              yield put(createToastRequest({ type: 'success', title: successConnections.length, text: 'connections were removed' }));
            } else {
              yield put(createToastRequest({ type: 'success', title: successConnections[0].instanceName, text: 'connection was removed' }));
            }
          }
        }
        if (callback) callback({ successFolders, successConnections });
      } else if (folders?.status === 401 || connections?.status === 401) {
        yield put(refreshRequest(removeConnectionsListRequest, data, callback));
      } else {
        const parsedFolders = yield folders ? folders.json() : null;
        const parsedConnections = yield connections ? connections.json() : null;

        if (callback) callback(false);
        if (parsedFolders) yield put(createToastRequest({ type: 'error', text: parsedFolders?.error, code: parsedFolders?.code }));
        if (parsedConnections) yield put(createToastRequest({ type: 'error', text: parsedConnections?.error, code: parsedConnections?.code }));
      }
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* updateConnection(ops) {
  const { data, callback } = ops;
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(api, '/connections', 'PUT', data, authData.access_token);
      if (response.ok) {
        const parsed = yield response.json();
        yield put(updateConnectionSuccess(parsed));
        yield put(createToastRequest({
          type: 'success',
          title: data.instanceName,
          text: 'connection was updated',
          linkTitle: `/connections/${data.id}`,
        }));
        if (callback) callback();
      } else if (response.status === 401) {
        yield put(refreshRequest(updateConnectionRequest, data, callback));
      } else {
        const parsed = yield response.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
      }
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* copyConnection(ops) {
  const { data, callback } = ops;
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      // get access
      const response = yield call(api, `/access/users?resource=connection&id=${data.id}`, 'GET', null, authData.access_token);
      if (response.ok) {
        const parsedAccess = yield response.json();
        // create endpoint
        yield put(createConnectionRequest(
          {
            ...data,
            instanceName: `${data.instanceName}(copy)`,
          },
          (connection) => {
            callback && callback(parsedAccess, connection.id);
          },
        ));
      } else if (response.status === 401) {
        yield put(refreshRequest(createConnectionRequest, data));
      } else {
        const parsed = yield response.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
      }
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* exportConnection(ops) {
  const { data, callback } = ops;
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(apiWorker, '/export', 'POST', data, authData.access_token);
      if (response.ok) {
        const parsedAccess = yield response.json();
        if (parsedAccess.file) {
          yield put(createToastRequest({ type: 'success', text: 'Export in progress... Don\'t refresh this page.' }));
          const file = yield call(apiWorker, `/export/file/${parsedAccess.file}`, 'GET', null, authData.access_token);
          const contentDisposition = file.headers.get('Content-Disposition');
          const filename = contentDisposition.match(/filename="(.+)"/)[1];
          file.blob().then((blob) => {
            callback && callback({file: blob, name: filename });
          });
        } else {
          yield put(createToastRequest({ type: 'error', text: 'Error export on first step' }));
        }
      } else if (response.status === 401) {
        yield put(refreshRequest(exportConnectionRequest, data));
      } else {
        const parsed = yield response.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
      }
    }
  } catch (e) {
    console.log('e', e);
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* importConnection(ops) {
  const { data, callback } = ops;
  const {
    projectId, linkApi, connections, file, linkVariables
  } = data;
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(apiWorkerFile, `/import/file?projectId=${projectId}&linkApi=${linkApi}&linkVariables=${linkVariables}&id=${connections}&resource=connection`, authData.access_token, file);
      const parsed = JSON.parse(response);
      if (parsed.status === 'success') {
        yield put(createToastRequest({ type: 'success', text: 'Connections have been added to the list' }));
        callback && callback(parsed);
      } else if (parsed.status === 401) {
        yield put(refreshRequest(importConnectionRequest, data));
      } else {
        yield put(createToastRequest({ type: 'error', text: parsed.msg }));
      }
    }
  } catch (e) {
    console.log('e', e);
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* configConnections() {
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(api, '/connections-types', 'GET', null, authData.access_token);
      if (response.ok) {
        const parsed = yield response.json();
        yield put(configConnectionsSuccess(parsed));
      } else if (response.status === 401) {
        yield put(refreshRequest(configConnectionsRequest));
      } else {
        const parsed = yield response.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
      }
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* createConnectionFolder(ops) {
  const { data, callback } = ops;
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(api, '/folders', 'POST', data, authData.access_token);
      if (response.ok) {
        const parsed = yield response.json();

        yield put(createConnectionFolderSuccess(parsed));

        yield put(createToastRequest({
          type: 'success',
          title: parsed.name,
          text: 'folder has been created successfully',
        }));
        if (callback) callback(parsed);
      } else if (response.status === 401) {
        yield put(refreshRequest(createConnectionFolderRequest, data, callback));
      } else {
        const parsed = yield response.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
      }
    }
  } catch (e) {
    console.log('e', e);
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* getConnectionFolder(ops) {
  const { data, callback } = ops;
  const { merge } = data || {};
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const [folders, connections] = yield all([
        call(api, `/folders?folderId=${data.folder}&type=connections&projectId=${data.projectId}`, 'GET', null, authData.access_token),
        call(api, `/connections?folderId=${data.folder}&projectId=${data.projectId}`, 'GET', null, authData.access_token),
      ]);
      if (folders.ok && connections.ok) {
        const parsedFolders = yield folders.json();
        const parsedConnections = yield connections.json();

        yield put(getConnectionFolderContentSuccess({
          merge,
          folderId: data.folder,
          folders: parsedFolders.next,
          data: parsedConnections.items,
          breadscrumbs: parsedFolders.prev,
        }));

        if (callback) {
          callback({
            merge,
            folderId: data.folder,
            folders: parsedFolders.next,
            data: parsedConnections.items,
            breadscrumbs: parsedFolders.prev,
          });
        }
      } else if (folders.status === 401 || connections.status === 401) {
        yield put(refreshRequest(getConnectionFolderContentRequest, data, callback));
      } else {
        const parsedFolders = yield folders.json();
        const parsedConnections = yield connections.json();
        yield put(createToastRequest({
          type: 'error',
          text: parsedFolders?.error || parsedConnections?.error,
          code: parsedFolders?.code || parsedConnections?.error,
        }));
      }
    }
  } catch (e) {
    console.log('e', e);
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* updateConnectionFolder(ops) {
  const { data, callback } = ops;
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(api, '/folders', 'PUT', {
        id: data.id,
        projectId: data.projectId,
        name: data.name,
        type: data.type,
        parentId: data.parentId,
      }, authData.access_token);
      if (response.ok) {
        yield put(createToastRequest({
          type: 'success',
          title: data.name,
          text: 'folder was updated',
        }));
        yield put(updateConnectionFolderSuccess(data));
        if (callback) callback();
      } else if (response.status === 401) {
        yield put(refreshRequest(updateConnectionFolderRequest, data, callback));
      } else {
        const parsed = yield response.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
      }
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* moveConnectionFolder(ops) {
  const { data, callback } = ops;
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(api, '/folders', 'PUT', {
        id: data.id,
        projectId: data.projectId,
        name: data.name,
        type: data.type,
        parentId: data.parentId,
      }, authData.access_token);
      if (response.ok) {
        yield put(createToastRequest({
          type: 'success',
          title: data.name,
          text: 'folder was moved',
        }));
        yield put(moveConnectionFolderSuccess(data));
        if (callback) callback();
      } else if (response.status === 401) {
        yield put(refreshRequest(moveConnectionFolderRequest, data, callback));
      } else {
        const parsed = yield response.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
      }
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* moveConnection(ops) {
  const { data, callback } = ops;
  const { rootAction, ...otherData } = data || {};
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(api, '/connections', 'PUT', otherData, authData.access_token);
      if (response.ok) {
        yield put(moveConnectionSuccess({ ...otherData, rootAction }));
        yield put(createToastRequest({
          type: 'success',
          title: otherData.instanceName,
          text: 'connection was moved',
        }));
        if (callback) callback();
      } else if (response.status === 401) {
        yield put(refreshRequest(moveConnectionRequest, data, callback));
      } else {
        const parsed = yield response.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
      }
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* copyConnectionFolder(ops) {
  const { data, callback } = ops;
  const { rootAction } = data || {};
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(api, '/folders/copy', 'POST', {
        folderId: data.id,
        toFolderId: data.parentId,
        name: `${data.name}(copy)`,
      }, authData.access_token);
      if (response.ok) {
        const parsed = yield response.json();
        yield put(createToastRequest({
          type: 'success',
          title: data.name,
          text: 'folder was copied',
        }));
        yield put(copyConnectionFolderSuccess({ ...parsed.folderId, rootAction }));
        if (callback) callback();
      } else if (response.status === 401) {
        yield put(refreshRequest(copyConnectionFolderRequest, data, callback));
      } else {
        const parsed = yield response.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
      }
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* getTree(ops) {
  const { data, callback } = ops;
  const { ids } = data || {};
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const parsedResponses = [];

      for (const id of ids) {
        const response = yield call(api, `/tree?id=${id}`, 'GET', null, authData.access_token);

        if (response.ok) {
          const parsed = yield response.json();
          parsedResponses.push(parsed);
          yield put(getConnectionFolderTreeSuccess(parsed));
        } else if (response.status === 401) {
          yield put(refreshRequest(getConnectionFolderTreeRequest, data, callback));
          break;
        } else {
          const parsed = yield response.json();
          yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
          if (callback) callback();
          break;
        }
      }

      if (ids.length === parsedResponses.length && callback) {
        if (callback) callback(parsedResponses);
      } else if (callback) callback();
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
    if (callback) callback();
  }
}

function* ConnectionsSaga() {
  yield takeEvery(Connections.listConnectionsRequest, fetchConnections);
  yield takeEvery(Connections.searchConnectionsRequest, searchConnections);
  yield takeEvery(Connections.createConnectionRequest, createConnection);
  yield takeEvery(Connections.copyConnectionRequest, copyConnection);
  yield takeEvery(Connections.exportConnectionRequest, exportConnection);
  yield takeEvery(Connections.importConnectionRequest, importConnection);
  yield takeEvery(Connections.removeConnectionsListRequest, removeConnectionsList);
  yield takeEvery(Connections.updateConnectionRequest, updateConnection);
  yield takeEvery(Connections.configConnectionsRequest, configConnections);
  yield takeEvery(Connections.createConnectionFolderRequest, createConnectionFolder);
  yield takeEvery(Connections.updateConnectionFolderRequest, updateConnectionFolder);
  yield takeLatest(Connections.getConnectionFolderContentRequest, getConnectionFolder);
  yield takeEvery(Connections.moveConnectionFolderRequest, moveConnectionFolder);
  yield takeEvery(Connections.moveConnectionRequest, moveConnection);
  yield takeEvery(Connections.copyConnectionFolderRequest, copyConnectionFolder);
  yield takeEvery(Connections.getConnectionFolderTreeRequest, getTree);
}

function* configSaga() {
  yield all([fork(ConnectionsSaga)]);
}

export default configSaga;

@use "sass:map";
@import '../../styles/variables.scss';

.iconWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: visible;
    margin-right: 8px;
    cursor: pointer;

    &:hover {
        svg * {
            stroke: map.get($colors-basic, "primaryBlue");
        }
    }
}

.errorCount {
    position: absolute;
    top: -4px;
    right: -8px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px 6px 3px 6px;
    border-radius: 50%;
    background-color: #E4315F;
    color: #fff;
    font-size: 11px;
    font-weight: 600;
    line-height: 14px;
    z-index: 1;
}

.messageWrapper {
    z-index: 999999;
    display: flex;
    width: 470px;
    padding: 12px 8px;
    justify-content: space-between;
    align-items: flex-start;
    border-radius: 4px;
    border: 1px solid #F9C0C1;
    background: #FFF5F5;
    user-select: none;
    cursor: grab;
}

.message {
    position: relative;
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
}

.messageLeft {
   display: flex;
   flex-direction: column;
   flex-grow: 1;
   display: flex;
   flex-direction: row;
   gap: 10px;

   > svg {
        min-width: 20px;
        min-height: 20px;
   }
}

.messageContent {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 2px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    h4, li, a, a:visited {
        color: #080828;
        font-family: "Open Sans";
        font-size: 14px;
        font-style: normal;
        line-height: 18px;
    }

    h4 {
        font-weight: 600;
    }

    a, a:visited {
        color:  #4149F7;
        font-weight: 600;
        text-decoration: none;
    }
}

.closeIcon {
    cursor: pointer;
}
import { ActiveConnection } from './constants';

const initialState = {
  activeConnection: null,
  connectedEndpoints: [],
  inviteList: [],
};

export const activeConnectionReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case ActiveConnection.activeConnectionSuccess:
      return {
        ...state,
        activeConnection: action.data
      }
    case ActiveConnection.getAccessUsersConnectionSuccess:
      return {
        ...state,
        inviteList: action.data,
      }
    case ActiveConnection.getConnectedEndpointsSuccess:
      return {
        ...state,
        connectedEndpoints: action.data
      }
    default:
      return state;
  }
};

import React, {
  useEffect, useRef, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import * as shared from '../../styles/shared.module.scss';
import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';
import Checkbox from '../../components/Checkbox/Checkbox';
import {
  checker,
  getAllData, getAllDataObjects,
  getAllfoldersDataObjects,
  getAllfoldersData, getDataInFolder,
  getFoldersDataInFolder,
  getFolderPath, getDataPath,
  filterItemsByExclude,
} from '../../helpers/utils';
import Loader from '../../components/Loader';
import Directory from './components/Derectory/Directory';
import SortIcon from '../../pictures/sort.svg';
import { useCopyToClipboard } from '../../hooks/hooks';
import { backendHost } from '../../helpers/const';
import { createToastRequest } from '../../store/toasts/actions';
import {
  getTrashFolderRequest,
  getTrashRequest,
  removeTrashItemsRequest,
  removeTrashFolderRequest,
  restoreTrashFoldersRequest,
  restoreTrashItemsRequest,
  searchTrashRequest,
  removeTrashFolderSuccess,
  removeEndpointListSuccess
} from '../../store/trash/actions';
import Breadscrumbs from '../../components/Breadscrumbs';

const headers = {
  checkbox: { title: '', width: '7%' },
  name: { title: 'Name', width: '14%' },
  method: { title: 'Method', width: '11%' },
  endpoint: { title: 'Endpoint', width: '14%' },
  connection: { title: 'Connection', width: '12%' },
  auth: { title: 'Auth type', width: '12%' },
  deletedBy: { title: 'Deleted by', width: '9%' },
  deletedAt: { title: 'Deleted at', width: '9%' },
};

let searchTimeout = null;

function Trash() {
  const { shortName, folderId = 0, folderType } = useParams();

  const [selectedFolders, setSelectedFolders] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [inputVisible, setInputVisible] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [seachActive, setSearchActive] = useState(false);

  const tableRef = useRef(null);
  const ref = useRef(null);
  const navigate = useNavigate();
  const [, copyText] = useCopyToClipboard();
  const dispatch = useDispatch();

  const { endpoints, connections, keys } = useSelector((state) => state.trash);
  const activeProject = useSelector((state) => state.activeProject);
  const {
    data: endpointsData = [],
    foldersData: endpointsFoldersData = [],
    breadscrumbData: endpointsBreadscrumbData = [],
  } = endpoints || {};
  const {
    data: connectionsData = [],
    foldersData: connectionsFoldersData = [],
    breadscrumbData: connectionsBreadscrumbData = [],
  } = connections || {};

  const {
    data: keysData = [],
    foldersData: keysFoldersData = [],
    breadscrumbData: keysBreadscrumbData = [],
  } = keys || {};

  const breadscrumbData = [...endpointsBreadscrumbData, ...connectionsBreadscrumbData, ...keysBreadscrumbData];

  const folders = [...connectionsFoldersData, ...endpointsFoldersData, ...keysFoldersData];

  const items = [
    ...endpointsData.map((el) => ({ ...el, modelType: 'endpoint' })),
    ...connectionsData.map((el) => ({ ...el, modelType: 'connection' })),
    ...keysData.map((el) => ({ ...el, modelType: 'key' }))
  ];

  const data = {
    breadscrumbData: [],
    data: items,
    foldersData: folders,
  };

  const renderHeader = (h) => {
    if (h === 'checkbox') {
      return null;
    }
    return (
      <th key={headers[h].title}>
        <span style={{ marginRight: 8 }}>{headers[h].title}</span>
        {' '}
        <SortIcon />
      </th>
    );
  };

  const getCorrespondingRoute = (type) => {
    if (type === 'connection') return 'connections';
    if (type === 'endpoint') return 'endpoints';
    if (type === 'key') return 'auth-keys';
    return null;
  }

  const getCorrespondingKey = (type) => {
    if (type === 'connection') return 'connectionsList';
    if (type === 'endpoint') return 'endpointsList';
    if (type === 'key') return 'keysList';
    return null;
  }


  const modifyAPI = (id, type) => navigate(`/${shortName}/trash/${getCorrespondingRoute(type)}/${id}`, { state: { redirectFolder: +folderId || 0 } });
  const openFolder = (type, id) => navigate(`/${shortName}/trash/${type}/folder/${id}`);

  const clear = () => {
    setSearchValue('');
    setLoading(true);
    setSelectedItems([]);
    setSelectedFolders([]);
  
    dispatch(getTrashRequest({
      folder: folderId || 0,
      projectId: activeProject?.id,
    },() => {
      setLoading(false);
      setSearchActive(false);
    }));

    setInputVisible(false);
  };

  const changeSearch = (e) => {
    setSearchValue(e.target.value);
    if (searchTimeout) clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => {
      setLoading(true);
      setSelectedItems([]);
      setSelectedFolders([]);
      if (e.target.value) {
        dispatch(searchTrashRequest({
          query: e.target.value,
          projectId: activeProject.id,
        },() => {
          setLoading(false);
          setSearchActive(true);
        }));
      } else {
        dispatch(getTrashRequest({
          folder: folderId || 0,
          projectId: activeProject.id,
        }, () => {
          setLoading(false);
          setSearchActive(false);
        }));
      }
    }, 400);
  };

  const handleCreateToast = (toastData) => dispatch(createToastRequest(toastData));

  const onDeleteClick = () => {
    const allItems = getAllDataObjects(data);
    const allFolders = getAllfoldersDataObjects(data);
    const { endpointsList = [], connectionsList = [], keysList = [] } = allItems.reduce((acc, val) => {
      const key = getCorrespondingKey(val.modelType);
      return selectedItems.indexOf(val.id) >= 0
        ? {
          ...acc,
          [key]: [...acc[key], val.id],
        }
        : acc;
    }, { endpointsList: [], connectionsList: [], keysList: [] });

    const f = selectedFolders.map((el) => ({ id: el, path: getFolderPath(data, el) }));
    const c = connectionsList.map((el) => ({ id: el, path: getDataPath(data, el) }));
    const e = endpointsList.map((el) => ({ id: el, path: getDataPath(data, el) }));
    const k = keysList.map((el) => ({ id: el, path: getDataPath(data, el) }));

    const fltF = filterItemsByExclude(f, selectedFolders) || [];
    const fltC = filterItemsByExclude(c, selectedFolders) || [];
    const fltE = filterItemsByExclude(e, selectedFolders) || [];
    const fltK = filterItemsByExclude(k, selectedFolders) || [];

    const resF = fltF.map((el) => el.id);
    const resC = fltC.map((el) => el.id);
    const resE = fltE.map((el) => el.id);
    const resK = fltK.map((el) => el.id);

    const excludedFolders = selectedFolders.filter(f => !resF.includes(f)) || [];
    const excludedEndpoints = endpointsList.filter(e => !resE.includes(e)) || [];
    const excludedConnections = connectionsList.filter(c => !resC.includes(c)) || [];
    const excludedKeys = keysList.filter(k => !resK.includes(k)) || [];

    if (resE?.length > 0) {
      dispatch(removeTrashItemsRequest({ type: 'endpoints', ids: resE }, ({ errors, successList }) => {
        setSelectedItems([]);
        if (errors && errors.length > 0) {
          errors.forEach((error) => handleCreateToast({ type: 'error', text: error.error }));
        }
        if (successList && successList.length > 0) {
          successList.forEach((id) => {
            const name = allItems.find((endpoint) => endpoint.id === id)?.name || '';
            handleCreateToast({ type: 'success', title: name, text: 'API has been deleted successfully' });
          });
        }
      }));
    }
    if (resC?.length > 0) {
      dispatch(removeTrashItemsRequest({ type: 'connections', ids: resC }, ({ errors, successList }) => {
        setSelectedItems([]);
        if (errors && errors.length > 0) {
          errors.forEach((error) => handleCreateToast({ type: 'error', text: error.error }));
        }
        if (successList && successList.length > 0) {
          successList.forEach((id) => {
            const name = allItems.find((endpoint) => endpoint.id === id)?.instanceName || '';
            handleCreateToast({ type: 'success', title: name, text: 'connection has been deleted successfully' });
          });
        }
      }));
    }
    if (resK?.length > 0) {
      dispatch(removeTrashItemsRequest({ type: 'keys', ids: resK }, ({ errors, successList }) => {
        setSelectedItems([]);
        if (errors && errors.length > 0) {
          errors.forEach((error) => handleCreateToast({ type: 'error', text: error.error }));
        }
        if (successList && successList.length > 0) {
          successList.forEach((id) => {
            const name = allItems.find((key) => key.id === id)?.name || '';
            handleCreateToast({ type: 'success', title: name, text: 'auth key has been deleted successfully' });
          });
        }
      }));
    }
    if (resF?.length > 0) {
      dispatch(removeTrashFolderRequest({ ids: resF }, ({ errors, successList }) => {
        setSelectedFolders([]);
        if (errors && errors.length > 0) {
          errors.forEach((error) => handleCreateToast({ type: 'error', text: error.error }));
        }
        if (successList && successList.length > 0) {
          successList.forEach((id) => {
            const name = allFolders.find((folder) => folder.id === id)?.name || '';
            handleCreateToast({ type: 'success', title: name, text: 'folder has been deleted successfully' });
          });

          if (excludedFolders?.length) dispatch(removeTrashFolderSuccess({ ids: excludedFolders }));
          if (excludedEndpoints?.length) dispatch(removeEndpointListSuccess({ type: 'endpoints', ids: excludedEndpoints }));
          if (excludedConnections?.length) dispatch(removeEndpointListSuccess({ type: 'connections', ids: excludedConnections }));
          if (excludedKeys?.length) dispatch(removeEndpointListSuccess({ type: 'keys', ids: excludedKeys }));
        }
      }));
    }
  };

  const onRestoreClick = () => {
    const allItems = getAllDataObjects(data);
    const allFolders = getAllfoldersDataObjects(data);
    const { endpointsList = [], connectionsList = [], keysList = [] } = allItems.reduce((acc, val) => {
      const key = getCorrespondingKey(val.modelType);
      return selectedItems.indexOf(val.id) >= 0
        ? {
          ...acc,
          [key]: [...acc[key], { ...val, deleted: false }],
        }
        : acc;
    }, { endpointsList: [], connectionsList: [], keysList: [] });

    const foldersToRestore = allFolders.reduce(
      (acc, val) => (selectedFolders.indexOf(val.id) >= 0
        ? [...acc, { ...val, deleted: false }]
        : acc),
      [],
    ) || [];

    const f = foldersToRestore.map((el) => ({ id: el.id, path: getFolderPath(data, el.id), item: el }));
    const c = connectionsList.map((el) => ({ id: el.id, path: getDataPath(data, el.id), item: el }));
    const e = endpointsList.map((el) => ({ id: el.id, path: getDataPath(data, el.id), item: el }));
    const k = keysList.map((el) => ({ id: el.id, path: getDataPath(data, el.id), item: el }));

    const fltF = filterItemsByExclude(f, selectedFolders) || [];
    const fltC = filterItemsByExclude(c, selectedFolders) || [];
    const fltE = filterItemsByExclude(e, selectedFolders) || [];
    const fltK = filterItemsByExclude(k, selectedFolders) || [];

    const resF = fltF.map((el) => el.item);
    const resC = fltC.map((el) => el.item);
    const resE = fltE.map((el) => el.item);
    const resK = fltK.map((el) => el.item);

    const resFIds = fltF.map((el) => el.id);
    const resCIds = fltC.map((el) => el.id);
    const resEIds = fltE.map((el) => el.id);
    const resKIds = fltK.map((el) => el.id);

    const excludedFolders = f.filter(f => !resFIds.includes(f.id));
    const excludedEndpoints = e.filter(e => !resEIds.includes(e.id));
    const excludedConnections = c.filter(c => !resCIds.includes(c.id));
    const excludedKeys = c.filter(c => !resKIds.includes(c.id));

    const excludedFoldersIds = excludedFolders.map(f => f.id);
    const excludedEndpointsIds = excludedEndpoints.map(e => e.id);
    const excludedConnectionsIds = excludedConnections.map(c => c.id);
    const excludedKeysIds = excludedKeys.map(c => c.id);

    if (resE?.length > 0) {
      dispatch(restoreTrashItemsRequest({ type: 'endpoints', items: resE }, ({ errors, successList }) => {
        setSelectedItems([]);
        if (errors && errors.length > 0) {
          errors.forEach((error) => handleCreateToast({ type: 'error', text: error.error }));
        }
        if (successList && successList.length > 0) {
          successList.forEach((id) => {
            const name = allItems.find((endpoint) => endpoint.id === id)?.name || '';
            handleCreateToast({ type: 'success', title: name, text: 'API has been restored successfully' });
          });
        }
      }));
    }
    if (resC?.length > 0) {
      dispatch(restoreTrashItemsRequest({ type: 'connections', items: resC }, ({ errors, successList }) => {
        setSelectedItems([]);
        if (errors && errors.length > 0) {
          errors.forEach((error) => handleCreateToast({ type: 'error', text: error.error }));
        }
        if (successList && successList.length > 0) {
          successList.forEach((id) => {
            const name = allItems.find((connection) => connection.id === id)?.instanceName || '';
            handleCreateToast({ type: 'success', title: name, text: 'connection has been restored successfully' });
          });
        }
      }));
    }
    if (resK?.length > 0) {
      dispatch(restoreTrashItemsRequest({ type: 'keys', items: resK }, ({ errors, successList }) => {
        setSelectedItems([]);
        if (errors && errors.length > 0) {
          errors.forEach((error) => handleCreateToast({ type: 'error', text: error.error }));
        }
        if (successList && successList.length > 0) {
          successList.forEach((id) => {
            const name = allItems.find((key) => key.id === id)?.name || '';
            handleCreateToast({ type: 'success', title: name, text: 'key has been restored successfully' });
          });
        }
      }));
    }
    if (resF?.length > 0) {
      const data = { items: resF };
      dispatch(restoreTrashFoldersRequest(data, ({ errors, successList }) => {
        setSelectedFolders([]);
        if (errors && errors.length > 0) {
          errors.forEach((error) => handleCreateToast({ type: 'error', text: error.error }));
        }
        if (successList && successList.length > 0) {
          successList.forEach((id) => {
            const name = allFolders.find((folder) => folder.id === id)?.name || '';
            handleCreateToast({ type: 'success', title: name, text: 'folder has been restored successfully' });
          });

          if (excludedFoldersIds?.length) dispatch(removeTrashFolderSuccess({ ids: excludedFoldersIds }));
          if (excludedEndpointsIds?.length) dispatch(removeEndpointListSuccess({ type: 'endpoints', ids: excludedEndpointsIds }));
          if (excludedConnectionsIds?.length) dispatch(removeEndpointListSuccess({ type: 'connections', ids: excludedConnectionsIds }));
          if (excludedKeysIds?.length) dispatch(removeEndpointListSuccess({ type: 'keys', ids: excludedKeysIds }));
        }
      }));
    }
  };

  const selectAll = (isChecked) => {
    if (isChecked) {
      const allIds = getAllData(data);
      const allIdsFolders = getAllfoldersData(data);
      setSelectedItems(allIds);
      setSelectedFolders(allIdsFolders);
    } else {
      setSelectedItems([]);
      setSelectedFolders([]);
    }
  };

  const openFolderAction = (type, id) => {
    setSearchValue('');
    setSearchActive(false);
    setInputVisible(false);
    selectAll(false);
    openFolder(type, id);
  };

  const selectOneFolder = (isChecked, id) => {
    const nestedFolders = getFoldersDataInFolder(data, id) || [];
    const nestedObjs = getDataInFolder(data, id) || [];

    if (isChecked) {
      setSelectedFolders([...selectedFolders, ...[id, ...nestedFolders]]);
      setSelectedItems([...selectedItems, ...nestedObjs]);
    } else {
      const path = getFolderPath(data, id) || [];
      setSelectedFolders(selectedFolders.filter((c) => !nestedFolders.includes(c) && c !== id && !path.includes(c)));
      setSelectedItems(selectedItems.filter((c) => !nestedObjs.includes(c)));
    }
  };

  const selectItems = (folders, items) => {
    setSelectedFolders([...selectedFolders, ...folders]);
    setSelectedItems([...selectedItems, ...items]);
  };

  useEffect(() => {
    setLoading(true);
    if (activeProject?.id) {
      setSelectedItems([]);
      setSelectedFolders([]);

        dispatch(getTrashRequest({
          folder: folderId || 0,
          projectId: activeProject.id,
          type: folderType
        }, () => {
          setLoading(false);
        }));
    }
  }, [activeProject?.id, folderId]);

  const selectOne = (isChecked, id) => {
    if (isChecked) {
      setSelectedItems([...selectedItems, id]);
    } else {
      const path = getDataPath(data, id) || [];
      setSelectedItems(selectedItems.filter((c) => c !== id));
      setSelectedFolders(selectedFolders.filter((c) => !path.includes(c)));
    }
  };

  const handleClickOutside = (event) => {
    if (ref && ref.current && !ref.current.contains(event.target)) {
      const input = ref.current.getElementsByTagName('input')[0];
      if (input.value) return;
      setInputVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const onGetFolder = (data, callback) => dispatch(getTrashFolderRequest(data, callback));

  const copyCon = (path) => {
    copyText(`${backendHost}/${activeProject.shortName}${path}`)
    && dispatch(createToastRequest({ type: 'success', text: 'Copied' }));
  };

  const anySelected = !(selectedFolders?.length || selectedItems?.length);

  return (
    <div className={shared.page}>
      <div className={shared.header}>
        {!folderId ? (
          <div className={shared.headerTitleGroup}>
            <h1 className={shared.headerTitle}>Trash</h1>
            <p className={shared.headerDescription}>Manage deleted items</p>
          </div>
        ) : <Breadscrumbs folders={breadscrumbData} type="trash" />}
        <div className={shared.headerButtonGroup}>
          {inputVisible ? (
            <div style={{
                flexGrow: 1,
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <div ref={ref} style={{ width: '100%', maxWidth: '426px'}}>
              <Input
                placeholder="Search"
                iconLeft="search"
                autoFocus
                iconRight={searchValue && 'close'}
                handleChange={changeSearch}
                value={searchValue}
                handleAction={clear}
              />
              </div>
            </div>
          ) : (
            <Button type="secondary" iconLeft="search" onClick={() => setInputVisible(true)} />
          )}
          <Button 
            title="Delete" 
            type="red" 
            onClick={onDeleteClick}
            disabled={anySelected}
          />
          <Button 
            title="Restore" 
            onClick={onRestoreClick} 
            disabled={anySelected}
          />
        </div>
      </div>
      <div className={shared.table}>
        <table
          className={shared.apiTable}
        >
          <thead>
            <tr className={shared.connectionTableRowTrash}>
              <th key="checkbox">
                <Checkbox
                  handleChange={selectAll}
                  size="small"
                  style={{ marginRight: '0' }}
                  value={
                    (items?.length || folders?.length)
                    && checker(selectedItems, items)
                    && checker(selectedFolders, folders)
                  }
                />
              </th>
              {Object.keys(headers).map((h, i, arr) => renderHeader(h, i, arr))}
            </tr>
          </thead>
          {loading && (
            <div className={shared.emptyContent}>
              <Loader size="large" />
            </div>
          )}
          {(folders?.length > 0 || items?.length > 0) && !loading && (
            <tbody
              ref={tableRef}
            >
              <Directory
                foldersData={folders}
                endpointsData={items}
                selectedFolders={selectedFolders}
                selectedItems={selectedItems}
                openFolderAction={openFolderAction}
                selectOneFolder={selectOneFolder}
                selectItems={selectItems}
                modifyEndpoint={modifyAPI}
                selectOne={selectOne}
                getFolder={onGetFolder}
                copyCon={copyCon}
                activeProject={activeProject}
                searchActive={seachActive}
              />
            </tbody>
          )}
          {(!folders?.length && !items?.length) && !searchValue && !loading && (
          <div className={shared.emptyContent}>
            <h3 className={shared.caption}>This folder is empty</h3>
            <p>
              The available objects will be displayed here.
            </p>
          </div>
        )}
        {(!folders?.length && !items?.length) && searchValue && !loading && (
          <div className={shared.emptyContent}>
            <h3 className={shared.caption}>No Items found</h3>
            <p>
              Please change your search query and try again
            </p>
          </div>
        )}
        </table>
      </div>
    </div>
  );
}

export default Trash;

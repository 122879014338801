/* eslint-disable no-restricted-syntax */
import {
  call, put, takeEvery, all, fork, select, takeLatest,
} from 'redux-saga/effects';
import { api, apiWorker, apiWorkerFile } from '../../helpers/api';
import {
  listVariablesRequest, listVariablesSuccess,
  searchVariablesRequest, searchVariablesSuccess,
  createVariableRequest, createVariableSuccess,
  exportVariableRequest, importVariableRequest,
  removeVariablesListRequest, removeVariablesListSuccess,
  updateVariableRequest, updateVariableSuccess,
  getVariableFolderContentRequest, getVariableFolderContentSuccess,

} from './actions';
import { createToastRequest } from '../toasts/actions';
import {
  refreshRequest,
} from '../auth/actions';
import { Variables } from './constants';
import history from '../../history';

const getAuth = (state) => state.auth?.auth;

export function* fetchVariables(ops) {
  const { data, callback } = ops;
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(api, `/variables?projectId=${data.id}`, 'GET', null, authData.access_token);
      if (response.ok) {
        const parsed = yield response.json();
        yield put(listVariablesSuccess(parsed.items));
        if (callback) callback();
      } else if (response.status === 401) {
        yield put(refreshRequest(listVariablesRequest, data, callback));
        if (callback) callback(true);
      } else {
        const parsed = yield response.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
        if (callback) callback(true);
      }
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* searchVariables(ops) {
  const { data, callback } = ops;
  const { projectId, query } = data;
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(api, `/search?projectId=${projectId}&query=${query}&deleted=false&filter=variable`, 'GET', null, authData.access_token);
      if (response.ok) {
        const parsed = yield response.json();
        yield put(searchVariablesSuccess(parsed));
        if (callback) callback(true);
      } else if (response.status === 401) {
        yield put(refreshRequest(searchVariablesRequest, data, callback));
      } else {
        const parsed = yield response.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
        if (callback) callback(false);
      }
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
    if (callback) callback(false);
  }
}

export function* createVariable(ops) {
  const { data, callback } = ops;
  const { rootAction, ...otherData } = data || {};
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(api, '/variables', 'POST', otherData, authData.access_token);
      if (response.ok) {
        const parsed = yield response.json();
        parsed.role = 'owner';
        yield put(createVariableSuccess({ ...parsed, rootAction }));
        yield put(createToastRequest({
          type: 'success',
          title: parsed.name,
          text: 'variable has been created successfully',
        }));
        if (callback) callback(parsed);
      } else if (response.status === 401) {
        yield put(refreshRequest(createVariableRequest, data, callback));
      } else {
        const parsed = yield response.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
      }
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* removeVariablesList(ops) {
  const { data, callback } = ops;
  const { variables: variable } = data || {};
  const authData = yield select(getAuth);
  let successVariables;

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const variables = yield call(api, '/variables', 'DELETE', { items: variable.map(c => c.id) }, authData.access_token);
      if (variables?.ok) {
          const parsed = yield variables.json();
          successVariables = variable;
          if (parsed.errors && parsed.errors.length > 0) {
            for (const e of parsed.errors) {
              successVariables = successVariables.filter((key) => key.id !== e.id);
              yield put(createToastRequest({ type: 'error', text: e.error }));
            }
          }
          if (successVariables && successVariables.length) {
            for (const el of successVariables) {
              yield put(removeVariablesListSuccess(el));
            }
            if (successVariables.length > 1) {
              yield put(createToastRequest({ type: 'success', title: successVariables.length, text: 'variables were removed completely' }));
            } else {
              yield put(createToastRequest({ type: 'success', title: successVariables[0].name, text: 'variable was removed completely' }));
            }
          }
        if (callback) callback({ successVariables });
      } else if (variables?.status === 401) {
        yield put(refreshRequest(removeVariablesListRequest, data, callback));
      } else {
        const parsedVariables = yield variables ? variables.json() : null;

        if (callback) callback(false);
        if (parsedVariables) yield put(createToastRequest({ type: 'error', text: parsedVariables?.error, code: parsedVariables?.code }));
      }
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* updateVariable(ops) {
  const { data, callback } = ops;
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(api, '/variables', 'PUT', data, authData.access_token);
      if (response.ok) {
        const parsed = yield response.json();
        yield put(updateVariableSuccess(parsed));
        yield put(createToastRequest({
          type: 'success',
          title: data.name,
          text: 'variable was updated',
        }));
        if (callback) callback();
      } else if (response.status === 401) {
        yield put(refreshRequest(updateVariableRequest, data, callback));
      } else {
        const parsed = yield response.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
      }
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* copyVariable(ops) {
  const { data, callback } = ops;
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(api, `/access/users?resource=variable&id=${data.id}`, 'GET', null, authData.access_token);
      if (response.ok) {
        const parsedAccess = yield response.json();
        yield put(createVariableRequest(
          {
            ...data,
            name: `${data.name}_copy`,
          },
          (variable) => {
            if (callback) callback(parsedAccess, variable.id);
          },
        ));
      } else if (response.status === 401) {
        yield put(refreshRequest(createVariableRequest, data));
      } else {
        const parsed = yield response.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
      }
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* exportVariable(ops) {
  const { data, callback } = ops;
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(apiWorker, '/export', 'POST', data, authData.access_token);
      if (response.ok) {
        const parsedAccess = yield response.json();
        if (parsedAccess.file) {
          yield put(createToastRequest({ type: 'success', text: 'Export in progress... Don\'t refresh this page.' }));
          const file = yield call(apiWorker, `/export/file/${parsedAccess.file}`, 'GET', null, authData.access_token);
          const contentDisposition = file.headers.get('Content-Disposition');
          const filename = contentDisposition.match(/filename="(.+)"/)[1];
          file.blob().then((blob) => {
            callback && callback({file: blob, name: filename });
          });
        } else {
          yield put(createToastRequest({ type: 'error', text: 'Error export on first step' }));
        }
      } else if (response.status === 401) {
        yield put(refreshRequest(exportVariableRequest, data));
      } else {
        const parsed = yield response.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
      }
    }
  } catch (e) {
    console.log('e', e);
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* importVariable(ops) {
  const { data, callback } = ops;
  const {
    projectId, linkApi, variables, file,
    linkConnections, linkKeys,
  } = data;
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(apiWorkerFile, `/import/file?projectId=${projectId}&linkApi=${linkApi}&linkConnections=${linkConnections}&linkKeys=${linkKeys}&id=${variables}&resource=variable`, authData.access_token, file);
      const parsed = JSON.parse(response);
      if (parsed.status === 'success') {
        yield put(createToastRequest({ type: 'success', text: 'Variables have been added to the list' }));
        if (callback) callback(parsed);
      } else if (parsed.status === 401) {
        yield put(refreshRequest(importVariableRequest, data));
      } else {
        yield put(createToastRequest({ type: 'error', text: parsed.msg }));
      }
    }
  } catch (e) {
    console.log('e', e);
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* getVariableFolder(ops) {
  const { data, callback } = ops;
  const { merge } = data || {};
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const [variables] = yield all([
        call(api, `/variables?&projectId=${data.projectId}`, 'GET', null, authData.access_token),
      ]);
      if (variables.ok) {
        const parsedVariables = yield variables.json();

        yield put(getVariableFolderContentSuccess({
          merge,
          folderId: data.folder,
          folders: [],
          data: parsedVariables.items,
          breadscrumbs: [],
        }));

        if (callback) {
          callback({
            merge,
            folderId: 0,
            folders: [],
            data: parsedVariables.items,
            breadscrumbs: [],
          });
        }
      } else if (variables.status === 401) {
        yield put(refreshRequest(getVariableFolderContentRequest, data, callback));
      } else {
        const parsedVariables = yield variables.json();
        yield put(createToastRequest({
          type: 'error',
          text: parsedVariables?.error,
          code: parsedVariables?.error,
        }));
      }
    }
  } catch (e) {
    console.log('e', e);
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

function* VariablesSaga() {
  yield takeEvery(Variables.listVariablesRequest, fetchVariables);

  yield takeEvery(Variables.searchVariablesRequest, searchVariables);
  yield takeEvery(Variables.createVariableRequest, createVariable);
  yield takeEvery(Variables.copyVariableRequest, copyVariable);
  yield takeEvery(Variables.exportVariableRequest, exportVariable);
  yield takeEvery(Variables.importVariableRequest, importVariable);
  yield takeEvery(Variables.removeVariablesListRequest, removeVariablesList);
  yield takeEvery(Variables.updateVariableRequest, updateVariable);
  yield takeLatest(Variables.getVariableFolderContentRequest, getVariableFolder);
}

function* configSaga() {
  yield all([fork(VariablesSaga)]);
}

export default configSaga;

import {
  put, takeEvery, all, fork, select, call,
} from 'redux-saga/effects';
import {
  getTrashSuccess,
  getTrashRequest,
  getTrashFolderSuccess,
  removeEndpointListSuccess,
  removeTrashItemsRequest,
  getTrashFolderRequest,
  removeTrashFolderSuccess,
  removeTrashFolderRequest,
  restoreTrashItemsRequest,
  restoreTrashFoldersRequest,
  searchTrashRequest, searchTrashSuccess
} from './actions';
import { Trash } from './constants';
import history from '../../history';
import { api } from '../../helpers/api';
import { refreshRequest } from '../auth/actions';
import { createToastRequest } from '../toasts/actions';
import { authSelector } from '../selectors';

const typeMapping = {
  keys: 'key',
  endpoints: 'endpoint',
  connections: 'connection'
}

export function* getTrash({ data, callback }) {
  const { projectId, folder, type } = data;
  const { access_token = null } = yield select(authSelector);
  const fetchConnections = !type || type === 'connections';
  const fetchEndpoints = !type || type === 'endpoints';
  const fetchKeys = !type || type === 'keys';

  try {
    if (!access_token) {
      history.push('/login');
    } else {
      const [endpointsFolders, endpoints, connectionsFolders, connections, keysFolders, keys] = yield all([
        fetchEndpoints ? call(api, `/folders?folderId=${folder}&type=endpoints&projectId=${projectId}&deleted=true`, 'GET', null, access_token) : null,
        fetchEndpoints ? call(api, `/endpoints?folderId=${folder}&projectId=${projectId}&deleted=true`, 'GET', null, access_token) : null,
        fetchConnections ? call(api, `/folders?folderId=${folder}&type=connections&projectId=${projectId}&deleted=true`, 'GET', null, access_token) : null,
        fetchConnections ? call(api, `/connections?folderId=${folder}&projectId=${projectId}&deleted=true`, 'GET', null, access_token) : null,
        fetchKeys ? call(api, `/folders?folderId=${folder}&type=keys&projectId=${projectId}&deleted=true`, 'GET', null, access_token) : null,
        fetchKeys ? call(api, `/auth-keys?folderId=${folder}&projectId=${projectId}&deleted=true`, 'GET', null, access_token) : null,
      ]);
      if ((endpointsFolders?.ok && endpoints?.ok) || 
      (connectionsFolders?.ok && connections?.ok) || 
      (keysFolders?.ok && keys?.ok)) {
        const endpointsFoldersParsed = fetchEndpoints ? yield endpointsFolders.json() : null;
        const endpointsParsed = fetchEndpoints ? yield endpoints.json() : null;
        const connectionsFoldersParsed = fetchConnections ? yield connectionsFolders.json() : null;
        const connectionsParsed = fetchConnections ? yield connections.json() : null;
        const keysFoldersParsed = fetchKeys ? yield keysFolders.json() : null;
        const keysParsed = fetchKeys ? yield keys.json() : null;
        const endpointsBusinessObject = {
          folderId: folder,
          folders: endpointsFoldersParsed?.next || [],
          data: endpointsParsed?.items || [],
          breadscrumbs: endpointsFoldersParsed?.prev || [],
        };
        const connectionsBusinessObject = {
          folderId: folder,
          folders: connectionsFoldersParsed?.next || [],
          data: connectionsParsed?.items || [],
          breadscrumbs: connectionsFoldersParsed?.prev || [],
        };
        const keysBusinessObject = {
          folderId: folder,
          folders: keysFoldersParsed?.next || [],
          data: keysParsed?.items || [],
          breadscrumbs: keysFoldersParsed?.prev || [],
        }
        yield put(getTrashSuccess({
          endpoints: endpointsBusinessObject,
          connections: connectionsBusinessObject,
          keys: keysBusinessObject
        }));
        if (callback) callback('SUCCESS');
      } else if (endpointsFolders.status === 401
        || endpoints.status === 401
        || connectionsFolders.status === 401
        || connections.status === 401
        || keysFolders.status === 401
        || keys.status === 401
        ) {
        yield put(refreshRequest(getTrashRequest, data, callback));
      } else {
        const parsedFolders = yield endpointsFolders?.json();
        const parsedEndpoints = yield endpoints?.json();
        const parsedConnectionFolders = yield connectionsFolders?.json();
        const parsedConnections = yield connections?.json();
        const parsedKeysFolders = yield keysFolders?.json();
        const parsedKeys = yield keys?.json();
        yield put(createToastRequest({
          type: 'error',
          text: parsedFolders?.error
            || parsedEndpoints?.error
            || parsedConnectionFolders?.error
            || parsedConnections?.error
            || parsedKeysFolders?.error
            || parsedKeys?.error,
          code: parsedFolders?.code
            || parsedEndpoints?.code
            || parsedConnectionFolders?.code
            || parsedConnections?.code
            || parsedKeysFolders?.code
            || parsedKeys?.code
        }));
      }
    }
  } catch (e) {
    console.log('e', e);
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* getFolder(ops) {
  const { data, callback } = ops;
  const authData = yield select(authSelector);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const [folders, items] = yield all([
        call(api, `/folders?folderId=${data.folder}&type=${data.type}&projectId=${data.projectId}&deleted=true`, 'GET', null, authData.access_token),
        call(api, `/${data.type === 'keys' ? 'auth-keys' : `${data.type}`}?folderId=${data.folder}&projectId=${data.projectId}&deleted=true`, 'GET', null, authData.access_token),
      ]);
      if (folders.ok && items.ok) {
        const parsedFolders = yield folders.json();
        const parsedItems = yield items.json();
        const transformedParsedItems = parsedItems.items?.length 
         ? parsedItems.items.map(el => ({ ...el, modelType: typeMapping[data.type] })) 
         : []

        yield put(getTrashFolderSuccess({
          type: data.type,
          folderId: data.folder,
          folders: parsedFolders.next,
          data: transformedParsedItems,
          breadscrumbs: parsedFolders.prev,
        }));
        if (callback) {
          callback({
            type: data.type,
            folderId: data.folder,
            folders: parsedFolders.next,
            data: transformedParsedItems,
            breadscrumbs: parsedFolders.prev,
          });
        }
      } else if (folders.status === 401 || items.status === 401) {
        yield put(refreshRequest(getTrashFolderRequest, data, callback));
      } else {
        const parsedFolders = yield folders.json();
        const parsedItems = yield items.json();
        yield put(createToastRequest({
          type: 'error',
          text: parsedFolders?.error || parsedItems?.error,
          code: parsedFolders?.code || parsedItems?.code,
        }));
      }
    }
  } catch (e) {
    console.log('e', e);
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* removeItems(ops) {
  const { data, callback } = ops;
  const { type, ids } = data;
  const authData = yield select(authSelector);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(api, `/${data.type === 'keys' ? 'auth-keys' : `${data.type}`}`, 'DELETE', { items: ids }, authData.access_token);
      if (response.ok) {
        const parsed = yield response.json();
        let successList = ids;
        if (parsed.errors && parsed.errors.length > 0) {
          parsed.errors.forEach((e) => {
            successList = successList.filter((id) => id !== e.id);
          });
        }
        yield put(removeEndpointListSuccess({ type, ids: successList }));
        if (callback) callback({ errors: parsed.errors, successList });
      } else if (response.status === 401) {
        yield put(refreshRequest(removeTrashItemsRequest, data, callback));
      } else {
        const parsed = yield response.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
      }
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* removeFolders(ops) {
  const { data, callback } = ops;
  const { ids } = data;
  const authData = yield select(authSelector);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(api, '/folders', 'DELETE', { items: ids }, authData.access_token);
      if (response.ok) {
        const parsed = yield response.json();
        let successList = ids;
        if (parsed.errors && parsed.errors.length > 0) {
          parsed.errors.forEach((e) => {
            successList = successList.filter((id) => id !== e.id);
          });
        }
        yield put(removeTrashFolderSuccess(data));
        if (callback) callback({ errors: parsed.errors, successList });
      } else if (response.status === 401) {
        yield put(refreshRequest(removeTrashFolderRequest, data, callback));
      } else {
        const parsed = yield response.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
      }
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* restoreItems(ops) {
  const { data, callback } = ops;
  const { items, type } = data;
  const authData = yield select(authSelector);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(api, `/${data.type === 'keys' ? 'auth-keys' : `${data.type}`}`, 'PUT', { 
        items: items?.map(i => ({ ...i, folderId: i.previousFolderId }))
      }, authData.access_token);
      if (response.ok) {
        const parsed = yield response.json();
        let successList = items.map((item) => item.id);
        if (parsed.errors && parsed.errors.length > 0) {
          parsed.errors.forEach((e) => {
            successList = successList.filter((id) => id !== e.id);
          });
        }
        yield put(removeEndpointListSuccess({ type, ids: successList }));
        if (callback) callback({ errors: parsed.errors, successList });
      } else if (response.status === 401) {
        yield put(refreshRequest(restoreTrashItemsRequest, data, callback));
      } else {
        const parsed = yield response.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
      }
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* restoreFolders(ops) {
  const { data, callback } = ops;
  const { items } = data || {};
  const authData = yield select(authSelector);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(api, '/folders', 'PUT', { 
        items: items?.map(i => ({ ...i, parentId: i.previousParentId }))
      }, authData.access_token);
      if (response.ok) {
        const parsed = yield response.json();
        let successList = data.items.map((item) => item.id);
        if (parsed.errors && parsed.errors.length > 0) {
          parsed.errors.forEach((e) => {
            successList = successList.filter((id) => id !== e.id);
          });
        }
        yield put(removeTrashFolderSuccess({ ids: successList }));
        if (callback) callback({ errors: parsed.errors, successList });
      } else if (response.status === 401) {
        yield put(refreshRequest(restoreTrashFoldersRequest, data, callback));
      } else {
        const parsed = yield response.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
      }
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* searchTrash(ops) {
  const { data, callback } = ops;
  const { projectId, query } = data;
  const authData = yield select(authSelector);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(api, `/search?projectId=${projectId}&query=${query}&deleted=true&filter=folderId,endpoint,connection,key`, 'GET', null, authData.access_token);
      if (response.ok) {
        const parsed = yield response.json();
        const { folders = [], endpoints, connections, keys} = parsed || {};

        const endpointsFolders = folders.filter(f => f.type === 'endpoints');
        const connectionsFolders = folders.filter(f => f.type === 'connections');
        const keysFolders = folders.filter(f => f.type === 'keys');
  
        yield put(searchTrashSuccess({
          endpoints: {
            folders: endpointsFolders,
            data: endpoints
          },
          connections: {
            folders: connectionsFolders,
            data: connections
          },
          keys: {
            folders: keysFolders,
            data: keys
          },
        }));
        if (callback) callback(true);
      } else if (response.status === 401) {
        yield put(refreshRequest(searchTrashRequest, data, callback));
      } else {
        const parsed = yield response.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
        if (callback) callback(false);
      }
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
    if (callback) callback(false);
  }
}

function* trashSaga() {
  yield takeEvery(Trash.getTrashRequest, getTrash);
  yield takeEvery(Trash.getTrashFolderRequest, getFolder);
  yield takeEvery(Trash.removeTrashItemsRequest, removeItems);
  yield takeEvery(Trash.removeTrashFolderRequest, removeFolders);
  yield takeEvery(Trash.restoreTrashItemsRequest, restoreItems);
  yield takeEvery(Trash.restoreTrashFoldersRequest, restoreFolders);
  yield takeEvery(Trash.searchTrashRequest, searchTrash);
}

function* configSaga() {
  yield all([fork(trashSaga)]);
}

export default configSaga;

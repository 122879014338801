/* eslint-disable */
import React from 'react';
import './InputWithVariables.scss';
import { TokenizeAutocompleteInput } from '../TokenizeAutocompleteInput/TokenizeAutocompleteInput';
import { connect } from 'react-redux';
import {
  searchVariablesRequest,
  listVariablesRequest,
} from '../../store/variables/actions';
import { transformTextWithVariables } from '../../helpers/tokenizeAutocompleteUtils';

function InputWithVariables(props) {
  const { placeholder = '', value, handleChange, variables, error, iconRight } = props;

  const handleTransformChange = (e) => {
    let toId = e.target.value;
    if(variables.data) {
      toId = transformTextWithVariables(e.target.value, variables.data, 'id', true, false);
    };
    handleChange({ target: { value: toId } });
  };

  return (
    <TokenizeAutocompleteInput
      placeholder={placeholder}
      tokens={variables.data}
      initialHtml={value}
      suggestions={variables.data}
      handleChange={handleTransformChange}
      error={error}
      iconRight={iconRight}
    />
  );
}

const mapStateToProps = ({
  variables, activeProject
}) => ({
  activeProject,
  variables,
});

const mapDispatchToProps = (dispatch) => ({
  searchVariables: (data, callback) => dispatch(searchVariablesRequest(data, callback)),
  fetchVariables: (data, callback) => dispatch(listVariablesRequest(data, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InputWithVariables)
/* eslint-disable jsx-a11y/no-autofocus */
import React from 'react';
import { connect } from 'react-redux';
import { removeUserRequest } from '../../store/users/actions';
import Button from '../Button/Button';
import * as styles from './RemoveUserModal.module.scss';

function RemoveUserModal({ removeUser, user, setIsOpen, deleteUser }) {
  const handleRemove = () => {
    removeUser({
      id: user.id,
      resource: 'project',
      userId: user.userId,
      email: user.email
    }, (res) => {
      setIsOpen(false);
      deleteUser && deleteUser();
    });
  };

  return (
    <div className={styles.modal}>
      <div className={styles.modalContent}>
        <div className={styles.header}>
          <div className={styles.title}>Delete User</div>
        </div>
        <div className={styles.body}>
          <div className={styles.mainContent}>
            <div>
              Are you sure you want to delete
              {' '}
              <span className={styles.modelTitle}>
                {`${user.firstName} ${user.lastName}`}
              </span>
              ?
            </div>
          </div>
          <div className={styles.buttonGroup}>
            <Button style={{ width: '85px' }} title="Close" type="secondary" onClick={() => setIsOpen(false)} />
            <Button style={{ width: '120px', marginLeft: '12px' }} type="red" title="Delete" onClick={handleRemove} />
          </div>
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  removeUser: (data, callback) => dispatch(removeUserRequest(data, callback)),
});

export default connect(null, mapDispatchToProps)(RemoveUserModal);

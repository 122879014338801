import { Trash } from './constants';
import { injectChunk, removeFolder, removeObj } from '../../helpers/utils';

export const initialState = {
  endpoints: {
    data: [],
    foldersData: [],
    breadscrumbData: [],
  },
  connections: {
    data: [],
    foldersData: [],
    breadscrumbData: [],
  },
  keys: {
    data: [],
    foldersData: [],
    breadscrumbData: [],
  },
};

export const trashReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case Trash.getTrashSuccess: {
      const { endpoints, connections, keys } = action.data;
      return {
        ...state,
        endpoints: {
          ...state.endpoints,
          data: endpoints.data,
          foldersData: endpoints.folders,
          breadscrumbData: endpoints.breadscrumbs,
        },
        connections: {
          ...state.connections,
          data: connections.data,
          foldersData: connections.folders,
          breadscrumbData: connections.breadscrumbs,
        },
        keys: {
          ...state.keys,
          data: keys.data,
          foldersData: keys.folders,
          breadscrumbData: keys.breadscrumbs,
        },
      };
    }
    case Trash.searchTrashSuccess: {
      const { endpoints, connections, keys } = action.data;
      return {
        ...state,
        endpoints: {
          ...state.endpoints,
          data: endpoints.data,
          foldersData: endpoints.folders,
        },
        connections: {
          ...state.connections,
          data: connections.data,
          foldersData: connections.folders,
        },
        keys: {
          ...state.keys,
          data: keys.data,
          foldersData: keys.folders,
        },
      };
    }
    case Trash.getTrashFolderSuccess: {
      const { type } = action.data;
      return {
        ...state,
        [type]: {
          ...state[type],
          ...injectChunk(state[type], action.data),
        },
      };
    }
    case Trash.removeTrashItemsSuccess: {
      const { ids, type } = action.data;
      return {
        ...state,
        [type]: {
          ...state[type],
          ...ids.reduce((acc, id) => removeObj(acc, { id }), state[type]),
        },
      };
    }
    case Trash.removeTrashFolderSuccess: {
      const { ids } = action.data;
      return {
        ...state,
        endpoints: {
          ...state.endpoints,
          ...ids.reduce((acc, id) => removeFolder(acc, { id }), state.endpoints),
        },
        connections: {
          ...state.connections,
          ...ids.reduce((acc, id) => removeFolder(acc, { id }), state.connections),
        },
        keys: {
          ...state.keys,
          ...ids.reduce((acc, id) => removeFolder(acc, { id }), state.keys),
        },
      };
    }
    default:
      return state;
  }
};

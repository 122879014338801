/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import * as styles from './CommitDiff.module.scss';
import { capitalize } from '../../helpers/utils';

const keysMap = {
  path: 'Endpoint',
  connectionType: 'Con. type',
};

const extendedWhiteList = ['auth'];

function CommitDiff({ data }) {
  const keys = Object.keys(data);
  return (
    <div className={styles.commitDiff}>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Parameter</th>
            <th>Before</th>
            <th>After</th>
          </tr>
        </thead>
        <tbody>
          {keys?.map((k) => (
            <tr key={k} className={cn({ [styles.extendedText]: extendedWhiteList.includes(k) })}>
              <td>{keysMap[k] || capitalize(k)}</td>
              <td>
                {data[k].oldValue || '-'}
              </td>
              <td>
                {data[k].newValue || '-'}
              </td>
            </tr>
          ))}
          {keys.length === 0 && (
            <tr>
              <td />
              <td colSpan="1">
                No diff found
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

CommitDiff.propTypes = {
  data: PropTypes.object,
};

export default CommitDiff;

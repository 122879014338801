/* eslint-disable no-useless-escape */
/* eslint-disable no-restricted-syntax */
/* eslint-disable max-len */
const { Parser } = require("acorn");

export const convertOldNames = (name) => {
  if (name === 'AWS') return 'aws';
  if (name === 'chatGPT') return 'chatGpt';

  return name;
}
export const checker = (arr, target) => target.every((v) => arr.includes(v?.id)) || (!arr?.length && !target?.length);

export const capitalize = (word) => `${word.charAt(0).toUpperCase()}${word.slice(1)}`;

export const filterItemsByExclude = (array, exclude) =>
  array.filter((item) => !exclude.some((excludedId) => String(item?.path || '').includes(excludedId)));

export const shortenText = (str) => {
  if (typeof str === 'string' && str.length > 8) {
    return `${str.slice(0, 4)}...${str.slice(-4)}`;
  }
  return str;
};

export const cutString = (string, length) => {
  if (string.length <= length) {
    return string;
  } 
    return `${string.slice(0, length)}...`;
};

export const isSafari = () => /^((?!chrome|android).)*Safari/i.test(navigator.userAgent);


export const isUrlWithPlaceholder = (url) => {
  const urlRegex = /^(https?:\/\/)?([\w.-{(.*?)}]+\.[a-zA-Z]{2,})(\/\S*)?$/i;
  const placeholderRegex = /{{\w+}}/g;

  if (placeholderRegex.test(url)) {
    return true;
  }

  return urlRegex.test(url);
};


export const shortenEmail = (email, length) => {
  if (typeof email === 'string' && email.length > length) {
    const [part, ...emailPart] = email.split('@');
    return `${part.slice(0, length - 3)}...@${emailPart.join('')}`;
  }
  return email;
};

export const formatNumber = (number) => number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");

export const limitsInit = {
  RPS: 'Unlimited',
  RPMONTH: 'Unlimited',
  RPMONTHCALENDAR: 'Unlimited',
}

const cutLimits = (value, result = {}) => {
  const returnValue = { ...value };

  for (const [name, val] of Object.entries(value)) {
    const [baseName, id] = name.includes('-') ? name.split('-') : [name, ''];

    switch (baseName) {
      case 'RPS': {
        const rpmName = id ? `RPMONTH-${id}` : 'RPMONTH';
        const rpmCalendarName = id ? `RPMONTHCALENDAR-${id}` : 'RPMONTHCALENDAR';
        if (result[name] && result[name] !== '') {
          returnValue[name] = 'Unlimited';
        } else if (val !== 'Unlimited') {
          returnValue[name] = formatNumber(Math.min(+val, ...[+result[rpmCalendarName], +result[rpmName]].filter(Number)));
        }
        break;
      }
      case 'RPMONTH':
      case 'RPMONTHCALENDAR': {
        if (result[name] && result[name] !== '') {
          returnValue[name] = 'Unlimited';
        } else if (val !== 'Unlimited') {
          returnValue[name] = `Approx. ${formatNumber(val)}`;
        }
        break;
      }
      default: {
        returnValue[name] = val;
      }
    }
  }

  return returnValue;
}

export const calcPlaceholder = (values, selectedModel) => {
  // connection -> one model, authKeys -> array of models
  const { connection, authKeys } = selectedModel || {};
  const result = {};

  // Initialize authKeysLimits object
  const authKeysLimits = {};

  // Iterate over authKeys array and write its limits to authKeysLimits
  if (authKeys) {
    for (const authKey of authKeys) {
      if (authKey.id) {
        authKeysLimits[authKey.id] = {
          RPS: authKey.limit?.sec || 0,
          RPMONTH: authKey.limit?.mon || 0,
          RPMONTHCALENDAR: authKey.limit?.cmon || 0
        };
      }
    }
  }

  for (const [name, value] of Object.entries(values)) {
    const [baseName, id] = name.includes('-') ? name.split('-') : [name, ''];

    switch (baseName) {
      case 'RPS': 
        result[name] = 'Unlimited';
      break;
      case 'RPMONTH':
      case 'RPMONTHCALENDAR': {
        result[name] = Number(value) ? value : 'Unlimited';
        const rpsName = id ? `RPS-${id}` : 'RPS';
        if (!Number(value)) {
          if (+values[rpsName]) { result[name] = Math.max(Math.round(+values[rpsName] * 60 * 60 * 24 * 30), 1); }
          else { result[name] = 'Unlimited'; }
        }
        break;
      }
      default: {
        result[name] = value;
      }
    }
  }

  const final = cutLimits(result, values);

  if (connection?.limit?.sec) final.RPS = `Connection limit: ${formatNumber(connection?.limit?.sec)}`;
  if (connection?.limit?.mon) final.RPMONTH = `Connection limit: ${formatNumber(connection?.limit?.mon)}`;
  if (connection?.limit?.cmon) final.RPMONTHCALENDAR = `Connection limit: ${formatNumber(connection?.limit?.cmon)}`;

  // If values.RPS is set, change the placeholders of the constructed fields
  for (const [name, value] of Object.entries(values)) {
    const [baseName, id] = name.includes('-') ? name.split('-') : [name, ''];
    if (id) {
      const apiLimit = Number(values[baseName]) || 0;
      const authKeyLimit = Number(authKeysLimits[id]?.[baseName]) || 0;
      if (apiLimit && authKeyLimit) {
        final[name] = authKeyLimit < apiLimit ? 
        `Auth Key limit: ${formatNumber(authKeyLimit)}` : 
        `API limit: ${formatNumber(apiLimit)}`;
      } else if (apiLimit) {
        final[name] = `API limit: ${formatNumber(apiLimit)}`;
      } else if (authKeyLimit) {
        final[name] = `Auth Key limit: ${formatNumber(authKeyLimit)}`;
      }
    }
  }

  return final;
};

export const formatTimestamp = (timestamp) => {
  const date = new Date(timestamp);
  const now = new Date();
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  if (
    (date.getDate() === now.getDate()) &&
    date.getMonth() === now.getMonth() &&
    date.getFullYear() === now.getFullYear()
  ) {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    return `Today     ${formattedTime}`;
  }

  if (
    (date.getDate() === yesterday.getDate()) &&
    date.getMonth() === yesterday.getMonth() &&
    date.getFullYear() === yesterday.getFullYear()
  ) {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    return `Yesterday ${formattedTime}`;
  }
  

  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear().toString().slice(-2);

  const formattedDate = `${day.toString().padStart(2, '0')}.${month.toString().padStart(2, '0')}.${year} ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

  return formattedDate;
}

export const roleMapper = (roleId) => {
  switch (roleId) {
    case 2:
      return 'Owner';
    case 3:
      return 'Editor';
    case 4:
      return 'Viewer';
    default:
      return 'Viewer';
  }
};

export const sqlPlaceholder = (isJS, isSqlGptMode, isGptError) => {
  if (isJS) return 'Enter query';
  if (isGptError && isSqlGptMode) return 'Please copy and paste SQL from ChatGPT';
  return 'Enter SQL query';
};

export const shorNameGenerator = (name) => {
  if (!name) return '';
  const matched = (name.match(/[a-zA-Z- ]/gm) || []).join('').toLowerCase();
  return matched.replace(/ /ig, '-');
};

export const paramsParser = (name) => {
  const matched = (name || '').match(/\{{(.*?)\}}/g) || [];
  return matched.map((m) => m.replace('{{', '').replace('}}', ''));
};

export const typeParser = (name) => {
  if (!name) return null;
  const [type, string] = name.split('.');
  const types = ['query', 'header', 'body', 'path'];
  
  if (type && string && !types.includes(type)) {
    return null;
  }
  if (type && !string) {
    return [null, type];
  }
  return [type, string];
};

export const getProjectRoles = (rolesList = [], projectId) => rolesList.filter((r) => r.projects?.length === 0 || r.projects?.includes(projectId)) || [];

export const formatingJSONToRender = (value) => {
  let tab = 0;
  let newRow = [];
  const getTab = () => {
    let n = 0;
    let s = '';
    while (n < tab) {
      n++;
      s += '  ';
    }
    return s;
  };

  const isArray = (arr) => Array.isArray(arr);

  const isObject = (obj) => Object.keys(obj) && Object.getPrototypeOf(obj) === Object.prototype;

  const createArray = (key, arr, isLast) => {
    const newRowArr = [];
    if (arr.length === 0) {
      return isLast ? `"${key}": []\n` : `"${key}": [],\n${getTab()}`;
    }
    arr.forEach((a, index) => {
      if (index === 0 && key) {
        tab += 1;
        newRowArr.push(`"${key}": [\n${getTab()}`);
      } else if (index === 0) {
        tab += 1;
        newRowArr.push(`[\n${getTab()}`);
      }

      if (index === arr.length - 1) {
        tab -= 1;
      }
      if (typeof a === 'number') {
        if (index === arr.length - 1) {
          newRowArr.push(`${a}\n${getTab()}`);
        } else {
          newRowArr.push(`${a},\n${getTab()}`);
        }
      } else if (isObject(a)) {
        tab += 1;
        newRowArr.push(createObj(a, index === arr.length - 1));
      } else if (isArray(a)) {
        newRowArr.push(createArray('', a, index === arr.length - 1));
      } else if (index === arr.length - 1) {
        newRowArr.push(`"${a}"\n${getTab()}`);
      } else {
        newRowArr.push(`"${a}",\n${getTab()}`);
      }
      if (index === arr.length - 1) {
        if (isLast) {
          tab -= 1;
          newRowArr.push(`]\n${getTab()}`);
        } else {
          newRowArr.push(`],\n${getTab()}`);
        }
      }
    });
    return newRowArr.join('');
  };

  const createObj = (obj, isLast) => {
    const newRowObj = [];
    const arr = Object.keys(obj);
    if (arr.length === 0) {
      return isLast ? '{}\n' : `{},\n${getTab()}`;
    }
    arr.forEach((key, index) => {
      if (index === 0) {
        tab += 1;
        newRowObj.push(`{\n${getTab()}`);
      }
      if (typeof obj[key] === 'number') {
        if (index === arr.length - 1) {
          tab -= 1;
          newRowObj.push(`"${key}": ${obj[key]}\n${getTab()}`);
        } else {
          newRowObj.push(`"${key}": ${obj[key]},\n${getTab()}`);
        }
      } else if (isObject(obj[key])) {
        newRowObj.push(`"${key}": ${createObj(obj[key], index === arr.length - 1)}`);
      } else if (isArray(obj[key])) {
        newRowObj.push(createArray(key, obj[key], index === arr.length - 1));
      } else if (index === arr.length - 1) {
        tab -= 1;
        newRowObj.push(`"${key}": "${obj[key]}"\n${getTab()}`);
      } else {
        newRowObj.push(`"${key}": "${obj[key]}",\n${getTab()}`);
      }

      if (index === arr.length - 1) {
        if (isLast) {
          tab -= 1;
          newRowObj.push(`}\n${getTab()}`);
        } else {
          newRowObj.push(`},\n${getTab()}`);
        }
      }
    });
    return newRowObj.join('');
  };

  try {
    newRow = createObj(JSON.parse(value), true);
  } catch (e) {
    newRow = value;
  }

  return newRow;
};

export const animatedScrollToBottom = (container, duration) => {
  const { scrollHeight } = container || {};
  const scrollStep = Math.PI / (duration / 15);
  let count = 0;
  let scrollCount = 0;
  const scrollInterval = setInterval(() => {
    if (scrollCount < scrollHeight) {
      count += 1;
      const newScrollTop = scrollHeight - (scrollHeight * Math.cos(count * scrollStep));
      // eslint-disable-next-line no-param-reassign
      container.scrollTop = newScrollTop;
      scrollCount = newScrollTop;
    } else {
      clearInterval(scrollInterval);
    }
  }, 15);
};

const debounceTimeout = {};

export const debounce = (id, fn, timeout = 500) => {
  if (debounceTimeout[id]) clearTimeout(debounceTimeout[id]);
  debounceTimeout[id] = setTimeout(fn, timeout);
};

export const convertValue = (value, type) => {
  const mapTypesToDefaultValues = {
    string: '',
    number: '0',
    object: {},
    array: [],
    boolean: 'false'
  };
  function realTypeChecker(value) {
    if (value === null) {
        return 'null';
    }
  
    const type = typeof value;
  
    if (type === 'object') {
        const constructorType = value.constructor ? value.constructor.name.toLowerCase() : 'object';
        return constructorType;
    }
  
    return type.toLowerCase();
  }
  const valueType = realTypeChecker(value);

  // If the type is the same as the desired type, return the value as is
  if (valueType === type) {
    return value;
  }

  // If the type is not the same, try to convert it
  switch (type) {
    case 'string':  {
      if (valueType === 'number') {
        return value === 0 ? "" : String(value);  
    }
    if (valueType === 'object' || valueType === 'array') {
      try {
        return JSON.stringify(value);
      } catch (e) {
        return mapTypesToDefaultValues[type];
      }
    }
    return String(value);
  }
    case 'number': {
      if (valueType === 'boolean') {
        return (value === true || value === 'true') ? 1 : 0;
      }
      if (valueType === 'string') {
        const number = value === 'true' ? 1 : value;
        return Number.isNaN(Number(number)) ? mapTypesToDefaultValues[type] : number;
      }
      return mapTypesToDefaultValues[type];
    }
    case 'object': {
      if (valueType === 'string') {
        try {
          const parsed = JSON.parse(value);
          if (parsed === true || parsed === false || parsed === null) return mapTypesToDefaultValues[type];
          if (!Object.keys(parsed)) return mapTypesToDefaultValues[type];
          return parsed;
        } catch (e) {
          return mapTypesToDefaultValues[type];
        }
      }
      return mapTypesToDefaultValues[type];
    }
    case 'array': {
      if (valueType === 'string') {
        try {
          const parsed = JSON.parse(value);
          return Array.isArray(parsed) ? parsed : mapTypesToDefaultValues[type];
        } catch (e) {
          return mapTypesToDefaultValues[type];
        }
    }
    return mapTypesToDefaultValues[type];
  }
    case 'boolean': {
      if (valueType === 'string') {
        return String(value === 'true' || value === '1');
      }
      if (valueType === 'number') {
        return String(value !== 0);
    }
      return String(Boolean(value));
  }
    default:
      return mapTypesToDefaultValues[type];
  }
};

export const parseSqlTags = (content) => {
  const regex = /<sql>(.*?)<\/sql>|```(sql\s)?(.*?)```/gs;
  const matches = content.match(regex) || [];
  const finalText = matches.map((match) => {
    if (match.includes('<sql>')) {
      return `${match.replace(/<\/?sql>/g, '')}\n`;
    }
    return `${match.replace(/```(sql)?/g, '')}\n`;
  }).join('');

  return finalText;
};


export const isJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const parseIfJSON = (str) => {
  let parsed;
  try {
    parsed = JSON.parse(str);
  } catch (e) {
    return str;
  }
  return parsed;
};

export const removeLatencyKey = (obj) => {
  if (typeof obj === 'object' && obj.latency) {
    const result = obj;
    delete result.latency;
    return result.data ? result.data : result;
  }
  return obj;
};

export function mergeObjects(obj1, obj2) {
  const mergedObj = { ...obj1 };
  for (const key in obj2) {
    if (!(key in mergedObj)) {
      mergedObj[key] = obj2[key];
    }
  }
  return mergedObj;
}

export const stringifyIfJSON = (str, spaces = 2) => {
  let parsed;
  try {
    parsed = JSON.stringify(str, null, spaces);
  } catch (e) {
    return str;
  }
  return parsed;
};

export const getVariableModelValue = (model) => {
  if (model.type === 'object' || model.type === 'array') {
    return stringifyIfJSON(model.value, 0);
  }
  return String(model.value);
}

export function isValidJsCode(code) {
  if (!code || !code.length) return true;
  try {
    // Replace 'env.*' and 'params.*' with valid identifiers
    const preprocessedCode = code
      .replace(/env\..*?\b/g, 'validIdentifier')
      .replace(/request\..*?\b/g, 'validIdentifier')
      .replace(/response\..*?\b/g, 'validIdentifier');
    Parser.parse(preprocessedCode, {
      ecmaVersion: 'latest',
      globalReturn: true,
    });
    return true;
  } catch (error) {
    return false;
  }
}
// mode (brackets or no-brackets)
export function checkEnvExistense(variables, mode = 'brackets') {
  return function (value) {
    const { createError } = this;
    const stringValue = typeof value === 'object' ? stringifyIfJSON(value, 0) : String(value);
    const regex = mode === 'brackets' ? /\{\{env\.(\w+)\}\}/g : /env\.(\w+)/g;
    const envVars = stringValue.match(regex) || [];
    const envIds = envVars.map(v => v.slice(mode === 'brackets' ? 6 : 4, mode === 'brackets' ? -2 : undefined));
    for (const id of envIds) {
      if (!variables.find(v => String(v.id) === String(id))) {
        return createError({ message: `Field includes broken variables links: env.${id}` });
      }
    }
    return true;
  };
}

export const isEmptyObject = (el) => typeof el === 'object' && !Object.keys(el).length;

export const isEmptyArray = (arr) => Array.isArray(arr) && arr.length === 0;

export const injectChunk = (obj, update) => {
  const { folderId } = update;

  // check if the current object has a "foldersData" property
  if (obj.foldersData) {
    // iterate over each folder object
    const updatedFoldersData = obj.foldersData.map((folder) => {
      // check if the folder has the specified update ID
      if (folder.id === folderId) {
        // if it does, update it and return the updated folder
        return {
          ...folder,
          items: {
            data: update.data,
            foldersData: update.folders,
            breadscrumbData: update.breadscrumbs,
          },
        };
      } if (folder.items && folder.items.foldersData) {
        return {
          ...folder,
          items: injectChunk(folder.items, update),
        };
      }
      return folder;
    });

    // return a copy of the original object with the updated "foldersData" property
    return {
      ...obj,
      foldersData: updatedFoldersData,
    };
  }

  return obj;
};

export const injectChunkEndpoint = (obj, update) => {
  const { folderId } = update;

  // check if the current object has a "foldersData" property
  if (obj.foldersData) {
    // iterate over each folder object
    const updatedFoldersData = obj.foldersData.map((folder) => {
      // check if the folder has the specified update ID
      if (folder.id === folderId) {
        // if it does, update it and return the updated folder
        return {
          ...folder,
          items: {
            data: update.endpoints,
            foldersData: update.folders,
            breadscrumbData: update.breadscrumbs,
          },
        };
      } if (folder.items && folder.items.foldersData) {
        return {
          ...folder,
          items: injectChunkEndpoint(folder.items, update),
        };
      }
      return folder;
    });

    // return a copy of the original object with the updated "foldersData" property
    return {
      ...obj,
      foldersData: updatedFoldersData,
    };
  }

  return obj;
};

export const addFolder = (obj, update) => {
  const { parentId, rootAction } = update;
  if (rootAction) {
    return {
      ...obj,
      foldersData: [
        ...obj.foldersData,
        update,
      ],
    };
  }

  if (obj.foldersData) {
    const updatedFoldersData = obj.foldersData.map((folder) => {
      if (folder.id === parentId) {
        return {
          ...folder,
          items: {
            ...(folder.items ? folder.items : { data: [], breadscrumbData: [] }),
            foldersData: [
              ...(folder.items ? folder.items.foldersData : []),
              update,
            ],
          },
        };
      } if (folder.items && folder.items.foldersData) {
        return {
          ...folder,
          items: {
            ...folder.items,
            foldersData: addFolder(folder.items, update).foldersData,
          },
        };
      }
      return folder;
    });

    return {
      ...obj,
      foldersData: [
        ...updatedFoldersData,
        // ...((found && !reccursive) ? [] : [update]),
      ],
    };
  }

  return {
    ...obj,
    foldersData: [update],
  };
};

export const removeFolder = (obj, update) => {
  if (obj.foldersData) {
    const updatedFoldersData = obj.foldersData
      .filter((folder) => folder.id !== update.id)
      .map((folder) => {
        if (folder.items && folder.items.foldersData) {
          return {
            ...folder,
            items: {
              ...folder.items,
              foldersData: removeFolder(folder.items, update).foldersData,
            },
          };
        }
        return folder;
      });

    return {
      ...obj,
      foldersData: updatedFoldersData,
    };
  }

  return obj;
};

export const updateFolder = (obj, update) => {
  if (obj.foldersData) {
    const updatedFoldersData = obj.foldersData
      .map((folder) => {
        if (folder.id === update.id) {
          return {
            ...folder,
            name: update.name,
          };
        }
        if (folder.items && folder.items.foldersData) {
          return {
            ...folder,
            items: {
              ...folder.items,
              foldersData: updateFolder(folder.items, update).foldersData,
            },
          };
        }
        return folder;
      });

    return {
      ...obj,
      foldersData: updatedFoldersData,
    };
  }

  return obj;
};

export const removeObj = (obj, update) => {
  const { id } = update;

  if (obj.foldersData) {
    const updatedFoldersData = obj.foldersData.map((folder) => {
      if (folder.items && folder.items.foldersData) {
        return {
          ...folder,
          items: {
            ...folder.items,
            data: folder.items.data.filter((item) => item.id !== id),
            foldersData: removeObj(folder.items, update).foldersData,
          },
        };
      }
      return folder;
    });

    return {
      ...obj,
      data: obj.data.filter((item) => item.id !== id),
      foldersData: updatedFoldersData,
    };
  }

  return obj;
};

export const createFolder = (obj, update) => {
  const { parentId: folderId, rootAction } = update;

  if (rootAction) {
    return {
      ...obj,
      foldersData: [
        ...obj.foldersData,
        update,
      ],
    };
  }

  const newFoldersData = obj.foldersData.map((folder) => {
    if (folder.id === folderId) {
      return {
        ...folder,
        items: {
          ...(folder.items ? folder.items : { foldersData: [], breadscrumbData: [] }),
          foldersData: [
            ...(folder.items ? folder.items.foldersData : []),
            update,
          ],
        },
      };
    } if (folder.items && folder.items.foldersData) {
      return {
        ...folder,
        items: {
          ...folder.items,
          foldersData: createFolder(folder.items, update).foldersData,
        },
      };
    }
    return folder;
  });

  return {
    ...obj,
    foldersData: newFoldersData,
  };
};

export const createObj = (obj, update) => {
  const { folderId, rootAction } = update;

  if (rootAction) {
    return {
      ...obj,
      data: [
        ...obj.data,
        update,
      ],
    };
  }

  const newFoldersData = obj.foldersData.map((folder) => {
    if (folder.id === folderId) {
      return {
        ...folder,
        items: {
          ...(folder.items ? folder.items : { foldersData: [], breadscrumbData: [] }),
          data: [
            ...(folder.items ? folder.items.data : []),
            update,
          ],
        },
      };
    } if (folder.items && folder.items.foldersData) {
      return {
        ...folder,
        items: {
          ...folder.items,
          foldersData: createObj(folder.items, update).foldersData,
        },
      };
    }
    return folder;
  });

  return {
    ...obj,
    foldersData: newFoldersData,
  };
};

export const moveFolder = (obj, update) => {
  const result = removeFolder(obj, update);

  return addFolder(result, update);
};

export const moveObj = (obj, update) => {
  const result = removeObj(obj, update);

  return createObj(result, update);
};

export const getAllDataObjects = (obj) => {
  let items = [];

  if (obj && Object.prototype.hasOwnProperty.call(obj, 'data')) {
    items = [...items, ...obj.data];
  }

  if (obj && Object.prototype.hasOwnProperty.call(obj, 'foldersData')) {
    obj.foldersData.forEach((folder) => {
      items = items.concat(getAllDataObjects(folder.items));
    });
  }

  return items;
};

export const getAllfoldersDataObjects = (obj) => {
  const folderIds = [];

  if (obj && Object.prototype.hasOwnProperty.call(obj, 'id')) {
    folderIds.push(obj);
  }

  if (obj && Object.prototype.hasOwnProperty.call(obj, 'foldersData')) {
    obj.foldersData.forEach((folder) => {
      folderIds.push(...getAllfoldersDataObjects(folder));
    });
  }

  if (obj && Object.prototype.hasOwnProperty.call(obj, 'items')) {
    folderIds.push(...getAllfoldersDataObjects(obj.items));
  }

  return folderIds;
};

export const getAllData = (obj) => {
  let items = [];

  if (obj && Object.prototype.hasOwnProperty.call(obj, 'data')) {
    items = obj.data.map((el) => el.id);
  }

  if (obj && Object.prototype.hasOwnProperty.call(obj, 'foldersData')) {
    obj.foldersData.forEach((folder) => {
      items = items.concat(getAllData(folder.items));
    });
  }

  return items;
};

export const getAllfoldersData = (obj) => {
  const folderIds = [];

  if (obj && Object.prototype.hasOwnProperty.call(obj, 'id')) {
    folderIds.push(obj.id);
  }

  if (obj && Object.prototype.hasOwnProperty.call(obj, 'foldersData')) {
    obj.foldersData.forEach((folder) => {
      folderIds.push(...getAllfoldersData(folder));
    });
  }

  if (obj && Object.prototype.hasOwnProperty.call(obj, 'items')) {
    folderIds.push(...getAllfoldersData(obj.items));
  }

  return folderIds;
};

export const getFolderData = (obj, id) => {
  if (!obj) {
    return null;
  }

  const { foldersData = [] } = obj;

  for (const folder of foldersData) {
    if (folder.id === id) {
      return folder.items;
    }

    if (folder.items) {
      const subFolderData = getFolderData(folder.items, id);
      if (subFolderData) {
        return subFolderData;
      }
    }
  }

  return null;
};

export const getFoldersDataInFolder = (obj, id) => {
  const scheme = getFolderData(obj, id);

  return getAllfoldersData(scheme, id);
};

export const getDataInFolder = (obj, id) => {
  const scheme = getFolderData(obj, id);

  return getAllData(scheme, id);
};

export const getFolderPath = (obj, id) => {
  if (!obj) {
    return null;
  }

  const { foldersData = [], breadscrumbData = [] } = obj;

  for (const folder of foldersData) {
    if (folder.id === id) {
      return folder.folderPath?.length ? folder.folderPath.map(el => el.id).reverse() : breadscrumbData.map((el) => el.id).reverse();
    }

    if (folder.items) {
      const subFolderPath = getFolderPath(folder.items, id);
      if (subFolderPath) {
        return subFolderPath;
      }
    }
  }

  return null;
};

export const getDataPath = (obj, id) => {
  if (!obj) {
    return null;
  }

  const { foldersData = [], breadscrumbData = [], data = [] } = obj;

  for (const item of data) {
    if (item.id === id) return item.folderPath?.length ? item.folderPath.map(el => el.id).reverse() : breadscrumbData.map((el) => el.id).reverse();
  }

  for (const folder of foldersData) {
    if (folder.items) {
      const subFolderPath = getDataPath(folder.items, id);
      if (subFolderPath) {
        return subFolderPath;
      }
    }
  }

  return null;
};

export const convertToType = (value, type) => {
  if (!value || value === '' || (typeof value === 'string' && /^\{\{env\..*?\}\}$/g.test(value))) return value;
  switch (type) {
    case 'boolean':
      return value === "true";
    case 'number':
      return !Number.isNaN(Number(value)) ? Number(value) : value;
    case 'object':
    case 'array':
      try {
        return JSON.parse(value);
      } catch {
        return value;
      }
    default:
      return value;
  }
};

export const convertToString = (value) => {
  if (typeof value === 'object') {
    return JSON.stringify(value, null, 2);
  }
  return String(value);
};

/* eslint-disable max-len */
import { Variables } from './constants';

export const listVariablesRequest = (data, callback) => ({ type: Variables.listVariablesRequest, data, callback });
export const listVariablesSuccess = (data) => ({ type: Variables.listVariablesSuccess, data });
export const listVariablesError = () => ({ type: Variables.listVariablesError });

export const searchVariablesRequest = (data, callback) => ({ type: Variables.searchVariablesRequest, data, callback });
export const searchVariablesSuccess = (data) => ({ type: Variables.searchVariablesSuccess, data });
export const searchVariablesError = () => ({ type: Variables.searchVariablesError });

export const createVariableRequest = (data, callback) => ({ type: Variables.createVariableRequest, data, callback });
export const createVariableSuccess = (data) => ({ type: Variables.createVariableSuccess, data });
export const createVariableError = () => ({ type: Variables.createVariableError });

export const removeVariablesListRequest = (data, callback) => ({ type: Variables.removeVariablesListRequest, data, callback });
export const removeVariablesListSuccess = (data) => ({ type: Variables.removeVariablesListSuccess, data });

export const updateVariableRequest = (data, callback) => ({ type: Variables.updateVariableRequest, data, callback });
export const updateVariableSuccess = (data) => ({ type: Variables.updateVariableSuccess, data });
export const updateVariableError = () => ({ type: Variables.updateVariableError });

export const copyVariableRequest = (data, callback) => ({ type: Variables.copyVariableRequest, data, callback });
export const copyVariableSuccess = (data) => ({ type: Variables.copyVariableSuccess, data });
export const copyVariableError = () => ({ type: Variables.copyVariableError });

export const exportVariableRequest = (data, callback) => ({ type: Variables.exportVariableRequest, data, callback });
export const exportVariableSuccess = (data) => ({ type: Variables.exportVariableSuccess, data });
export const exportVariableError = () => ({ type: Variables.exportVariableError });

export const importVariableRequest = (data, callback) => ({ type: Variables.importVariableRequest, data, callback });
export const importVariableSuccess = (data) => ({ type: Variables.importVariableSuccess, data });
export const importVariableError = () => ({ type: Variables.importVariableError });

export const getVariableFolderContentRequest = (data, callback) => ({ type: Variables.getVariableFolderContentRequest, data, callback });
export const getVariableFolderContentSuccess = (data) => ({ type: Variables.getVariableFolderContentSuccess, data });


/* eslint-disable consistent-return */
import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import JSZip from 'jszip';
import SwaggerParser from '@apidevtools/swagger-parser';
import Button from '../../components/Button/Button';
import ActionIcon from '../../components/ActionIcon/ActionIcon';
import ActionText from '../../components/ActionText/ActionText';
import ChipsStatus from '../../components/ChipsStatus/ChipsStatus';
import DBIcon from '../../components/DBIcon/DBIcon';
import Checkbox from '../../components/Checkbox/Checkbox';
import ImportModal from '../../components/ImportModal/ImportModal';
import { listEndpointsRequest, importEndpointRequest } from '../../store/endpoints/actions';
import { createToastRequest } from '../../store/toasts/actions';
import { shortenText, convertOldNames } from '../../helpers/utils';
import { backendHost } from '../../helpers/const';
import { useCopyToClipboard } from '../../hooks/hooks';
import { isRoleAction } from '../../helpers/hocs';

import SortIcon from '../../pictures/sort.svg';
import Import from '../../pictures/import.svg';

import 'react-tooltip/dist/react-tooltip.css';
import * as shared from '../../styles/shared.module.scss';

const headers = {
  checkbox: { title: '', width: '3%' },
  id: { title: 'ID', width: '9%' },
  name: { title: 'Name', width: '15%' },
  method: { title: 'Method', width: '11%' },
  endpoint: { title: 'Endpoint', width: '15%' },
  connection: { title: 'Connection', width: '12%' },
  auth: { title: 'Auth type', width: '12%' },
  status: { title: 'Status', width: '9%' },
  action: { title: 'Actions', width: '10%' },
};

const allowedZipFormats = ['application/zip', 'application/x-zip-compressed'];

function ApisList({ activeProject, getEndpoints, importEndpoint, createToast, endpoints }) {
  const { shortName } = useParams();
  const { data: endpointsData = [] } = endpoints || {};
  const [importEndpointModalOpened, setImportEndpointModalOpened] = useState(false);
  const [copiedText, copyText] = useCopyToClipboard();
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedConnections, setSelectedConnections] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [selectedVariables, setSelectedVariables] = useState([]);
  const [file, setFile] = useState(null);
  const [endpointsIm, setEndpointsIm] = useState([]);
  const [connectionsIm, setConnectionsIm] = useState([]);
  const [keysIm, setKeysIm] = useState([]);
  const [variablesIm, setVariablesIm] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    activeProject?.id && getEndpoints({ id: activeProject?.id });
  }, [activeProject?.id]);

  const getChipByStatus = (status) => {
    if (status === 'conflicted') {
      return <ChipsStatus title="Conflict" type="error" />;
    }
    if (status === 'draft') {
      return <ChipsStatus title="Draft" type="draft" />;
    }
    return <ChipsStatus title="New" type="success" />;
  };

  const goToListAPI = () => navigate(`/${shortName}/endpoints`);

  const getUrlFromBlob = (blob) => new Promise ( (res) => {
      const blobUrl = URL.createObjectURL(blob);

      const xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
  
      xhr.onload = () => {
        const recoveredBlob = xhr.response;
  
        const reader = new FileReader();
  
        reader.onload = () => {
          const blobAsDataUrl = reader.result;
          res(blobAsDataUrl);
          // window.location = blobAsDataUrl;
        };
  
        reader.readAsDataURL(recoveredBlob);
      };
  
      xhr.open('GET', blobUrl);
      xhr.send();
    });

  const validateSwaggerFile = async (file) => {
    try {
      await SwaggerParser.validate(file);
      // console.log('API name: %s, Version: %s', api.info.title, api.info.version);
      return true;
    } catch (err) {
      // console.log(err);
      return false;
    }
  };

  const parseSwaggerFile = async (file) => {
    try {
      const api = await SwaggerParser.parse(file);
      return api;
    } catch (err) {
      console.log(err);
      return false;
    }
  }

  const onDrop = useCallback(
    async (acceptedFiles) => {
      const file = acceptedFiles[0];
      const reader = new FileReader();

      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.readAsArrayBuffer(file);

      const fileExt = file.name.split('.').pop();
  
      setFile(file);

      if (fileExt === 'yaml') {
        const reader = new FileReader();
        reader.onload = (event) => {
          const fileBlob = event.target.result;

          importEndpoint(
            {
              projectId: activeProject?.id,
              linkConnections: true,
              file: new Blob([fileBlob], { type: 'application/yaml' }),
              onlyFile: true,
              type: 'swagger',
            },
            goToListAPI,
          );
        };
        reader.readAsText(file);
        return;
      }

      if (fileExt === 'json') {
        const reader = new FileReader();
        reader.onload = (event) => {
          const fileBlob = event.target.result;
      
          importEndpoint(
            {
              projectId: activeProject?.id,
              linkConnections: false,
              linkVariables: false,
              linkKeys: false,
              file: new Blob([fileBlob], { type: 'application/json' }),
              onlyFile: true,
              type: 'postman',
            },
            goToListAPI,
          );
        };
        reader.readAsText(file);
        return;
      }

      if (!allowedZipFormats.includes(file.type)) {
        createToast({ type: 'error', text: 'File format must be zip' });
        return;
      }

      const zip = new JSZip();
      const extractedFiles = await zip.loadAsync(file);
      extractedFiles.forEach(async (relativePath, file) => {
        const f = await file.async('string');
        const parsedFile = JSON.parse(f);
        if (!parsedFile?.endpoints || parsedFile?.endpoints?.length === 0) {
          createToast({ type: 'error', text: 'endpoints not found' });
          return;
        }

        const listEndpoints = parsedFile.endpoints.map((c) => ({
          ...c,
          status: endpointsData.find((con) => con.id === c.id) ? 'conflicted' : 'new',
        }));

        setEndpointsIm(listEndpoints);

        if (parsedFile?.connections && parsedFile.connections?.length > 0) {
          const listConntetions = parsedFile.connections.map((c) => ({
            ...c,
            status: endpointsData.find((con) => con.id === c.id) ? 'conflicted' : 'new',
          }));

          setConnectionsIm(listConntetions);
        }

        if (parsedFile?.keys && parsedFile.keys?.length > 0) {
          const listKeys = parsedFile.keys.map((c) => ({
            ...c,
            status: endpointsData.find((con) => con.id === c.id) ? 'conflicted' : 'new',
          }));

          setKeysIm(listKeys);
        }

        if (parsedFile?.variables && parsedFile.variables?.length > 0) {
          const listVariables = parsedFile.variables.map((c) => ({
            ...c,
            status: endpointsData.find((con) => con.id === c.id) ? 'conflicted' : 'new',
          }));

          setVariablesIm(listVariables);
        }
      });
    },
    [endpointsData],
  );

  const { getRootProps, getInputProps, open } = useDropzone({ onDrop, noClick: true });

  const selectAll = (isChecked) => {
    setSelectedItems(isChecked ? endpointsIm : []);
    setSelectedConnections(isChecked ? connectionsIm : []);
    setSelectedKeys(isChecked ? keysIm : []);
    setSelectedVariables(isChecked ? variablesIm : []);
  };

  const handleChangePickedConnections = (newEndpoints) => {
    setSelectedConnections(() =>
      connectionsIm.reduce((acc, val) => {
        if (newEndpoints.some((endpointItem) => val.id === endpointItem.connectionId)) {
          return [...acc, val];
        }
        return acc;
      }, []),
    );
  };

  const handleChangePickedKey = (newKeys) => {
    setSelectedKeys(() =>
      keysIm.reduce((acc, val) => {
        if (newKeys.some((endpointItem) => val.id === endpointItem.authId)) {
          return [...acc, val];
        }
        return acc;
      }, []),
    );
  };

  const selectOne = (isChecked, model) => {
    setSelectedItems((prev) => {
      const newEndpoints = isChecked ? [...prev, model] : prev.filter((c) => c.id !== model.id);
      handleChangePickedConnections(newEndpoints);
      setSelectedKeys(newEndpoints.length ? keysIm : []);
      setSelectedVariables(newEndpoints.length ? variablesIm : []);
      return newEndpoints;
    });
  };

  const importE = () => {
    setImportEndpointModalOpened(true);
  };

  const importEndpoints = (linkConnections, linkKeys, linkVariables) => {
    let endpointsList = '';
    selectedItems.forEach((item) => (endpointsList += endpointsList ? `,${item.id}` : `${item.id}`));
    importEndpoint(
      {
        file,
        endpoints: endpointsList,
        linkConnections,
        linkVariables,
        linkKeys,
        projectId: activeProject?.id,
      },
      goToListAPI,
    );
  };

  return (
    <div className={shared.page}>
      <div className={shared.header}>
        <div className={shared.headerTitleGroup}>
          <h1 className={shared.headerTitle}>Import APIs</h1>
        </div>
        <div className={shared.headerButtonGroup}>
          <Button title="Import API" onClick={importE} disabled={selectedItems.length === 0} />
        </div>
      </div>
      <div className={shared.table}>
        <table className={shared.apiTable}>
          <thead>
            <tr>
              <th key="checkbox" width={headers.checkbox.width}>
                <Checkbox
                  handleChange={selectAll}
                  size="small"
                  value={endpointsIm?.length > 0 && selectedItems.length === endpointsIm?.length}
                />
              </th>
              {Object.keys(headers).map(
                (h, i, arr) =>
                  h !== 'checkbox' && (
                    <th key={h} width={headers[h].width}>
                      <span>{headers[h].title}</span> {i < arr.length - 1 && <SortIcon />}
                    </th>
                  ),
              )}
            </tr>
          </thead>
          {endpointsIm?.length ? (
            <tbody>
              {endpointsIm.map((model, index) => (
                <tr
                  key={`${index}_${model.id}`}
                  className={`${selectedItems.find((s) => s.id === model.id) && shared.selected}`}
                  role="button"
                  tabIndex={0}
                  // onDoubleClick={() => modifyAPI(model.id)}
                >
                  <td width={headers.checkbox.width}>
                    <Checkbox
                      handleChange={(isCheked) => selectOne(isCheked, model)}
                      value={selectedItems.find((s) => s.id === model.id)}
                      size="small"
                    />
                  </td>
                  <td width={headers.id.width}>
                    <span>{shortenText(model.id)}</span>
                  </td>
                  <td width={headers.name.width}>
                    <span>{model.name}</span>
                    {model.description && (
                      <div className={shared.controlIcons}>
                        <ActionIcon
                          id={`${index}_${model.id}_tooltip`}
                          icon="info"
                          style={{ marginRight: '8px' }}
                          onClick={() => {}}
                          tooltip={{
                            content: model.description,
                          }}
                        />
                      </div>
                    )}
                  </td>
                  <td width={headers.method.width}>
                    <span className={shared.uppercased}>{model.method}</span>
                  </td>
                  <td width={headers.endpoint.width}>
                    <span>{model.path}</span>
                    <div className={shared.controlIcons}>
                      <ActionIcon
                        icon="copy"
                        style={{ marginRight: '8px' }}
                        onClick={() => {
                          copyText(`${backendHost}/${activeProject.shortName}${model.path}`) &&
                            createToast({ type: 'success', text: 'Copied' });
                        }}
                      />
                    </div>
                  </td>
                  <td width={headers.connection.width}>
                    <DBIcon
                      type={convertOldNames(model.connectionType)}
                      style={{ marginRight: '12px', minWidth: '32px' }}
                    />
                    <span>{model.connectionName}</span>
                  </td>
                  <td width={headers.auth.width}>
                    <span>
                      {model.auth?.authType &&
                        `${model.auth?.authType[0].toUpperCase()}${model.auth?.authType.substr(1)}`}
                    </span>
                  </td>
                  <td width={headers.status.width}>{getChipByStatus(model.status)}</td>
                  <td width={headers.action.width} />
                </tr>
              ))}
            </tbody>
          ) : null}
        </table>
        {!endpointsIm?.length && (
          <div className={shared.emptyContent} {...getRootProps()}>
            <input {...getInputProps()} type="file" style={{ display: 'none' }} accept=".zip" />
            <div className={shared.iconWrap} role="button" onClick={open} onKeyDown={open} tabIndex="0">
              <Import />
            </div>
            <p>Drop your .zip or swagger file with APIs to start import process</p>
            <p>
              or{' '}
              <div className={shared.action} style={{ display: 'inline' }}>
                <ActionText title="Select file" onClick={open} />
              </div>
            </p>
          </div>
        )}
        {importEndpointModalOpened && (
          <ImportModal
            onClose={() => setImportEndpointModalOpened(false)}
            onSubmit={importEndpoints}
            list={{ 
              apis: selectedItems, 
              connections: selectedConnections, 
              keys: selectedKeys, 
              variables: selectedVariables 
            }}
            typeImport="apis"
          />
        )}
      </div>
    </div>
  );
}

const mapStateToProps = ({ endpoints, activeProject }) => ({
  endpoints,
  activeProject,
});

const mapDispatchToProps = (dispatch) => ({
  getEndpoints: (data) => dispatch(listEndpointsRequest(data)),
  importEndpoint: (data, callback) => dispatch(importEndpointRequest(data, callback)),
  createToast: (data) => dispatch(createToastRequest(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(isRoleAction(ApisList));

import { api } from "../helpers/api"

export const fetchAppVersions = async () => {
    try{
        const res = await api("/app-versions", "GET")
        const data =  await res.json()
        return data;
    }
    catch(e){
        return null
    }
} 
import { Endpoints } from './constants';
import {
  injectChunk, removeFolder, removeObj, createFolder,
  updateFolder, createObj, moveFolder, moveObj,
} from '../../helpers/utils';
import { ActiveProject } from '../activeProject/constants';

const initialState = {};

export const endpointsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case Endpoints.listEndpointsSuccess:
      return { ...state, data: action.data };
    case Endpoints.searchEndpointsSuccess:
      return { ...state, data: action.data?.endpoints, foldersData: action.data?.folders };
    case Endpoints.getEndpointFolderContentSuccess:
      return action.data?.merge ? injectChunk(state, action.data) : {
        ...state,
        data: action.data?.data,
        foldersData: action.data?.folders,
        breadscrumbData: action.data?.breadscrumbs,
      };
    case Endpoints.moveEndpointFolderSuccess: return moveFolder(state, action.data);
    case Endpoints.moveEndpointSuccess: return moveObj(state, action.data);
    case Endpoints.createEndpointFolderSuccess:
      return state.foldersData
        ? { ...state, foldersData: [...state.foldersData, action.data] }
        : { ...state, foldersData: action.data };
    case Endpoints.createEndpointSuccess: return state.data ? createObj(state, action.data) : {
      ...state, data: action.data,
    };
    case Endpoints.copyEndpointFolderSuccess: return createFolder(state, action.data);
    case Endpoints.removeEndpointFolderSuccess: return removeFolder(state, action.data);
    case Endpoints.removeEndpointsListSuccess: return removeObj(state, action.data);
    case Endpoints.updateEndpointFolderSuccess:
      return updateFolder(state, action.data);
    case Endpoints.updateEndpointSuccess:
      return state.data ? {
        ...state,
        data: [...state.data.map(
          (content) => (action.data.id === content.id ? { ...content, ...action.data }
            : content),
        )],
      } : state;
    case ActiveProject.activeProjectReset:
      return initialState;
    default:
      return state;
  }
};

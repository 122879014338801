@use "sass:map";
@import '../../styles/variables.scss';

.input * {
   white-space: nowrap;
}
.input {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    color: map.get($colors-basic, "textBlack");
    border: none;
    outline: none;
    font-size: map.get($font-sizes, "body");
    background-color: map.get($colors-basic, "inputGrey");
    line-height: 34px;
    padding: 0 0 0 0;
    border: 1px solid map.get($colors-basic, "inputGrey");
    border-radius: 4px;

    &:disabled {
        &:hover {
            background-color: map.get($colors-basic, "inputGrey") !important;
            border: 1px solid map.get($colors-basic, "inputGrey") !important;
        }
    }

    &.filled {
        &:hover {
            border: 1px solid map.get($colors-basic, "inputGrey");
        }

        &:focus {
            background-color: white;
            border: 1px solid map.get($colors-basic, "primaryBlue");
        }
    }

    &::placeholder {
        color: map.get($colors-basic, "textGray")
    }

    &:hover {
        background-color: map.get($colors-basic, "selectedOption");
        border: 1px solid map.get($colors-basic, "primaryBlue");
    }
    &.focused {
        background-color: white;
        border: 1px solid map.get($colors-basic, "primaryBlue");
    }

    &.filled.focused {
        background-color: white;
        border: 1px solid map.get($colors-basic, "primaryBlue");
    }

    &:focus {
        background-color: white;
        border: 1px solid map.get($colors-basic, "primaryBlue");
    }

    &.error {
        border: 1px solid map.get($colors-red, 100) !important;
        background-color: #FEF4F4 !important;
    }

    svg * {
        fill: white;
    }

    &.leftPadding {
        padding-left: 36px;
    }

    &.rightPadding {
        padding-right: 36px;
    }
}

.input2 {
    width: 100%;
    box-sizing: border-box;
    color: map.get($colors-basic, "textBlack");
    border: none;
    outline: none;
    font-size: map.get($font-sizes, "body");
    background-color: map.get($colors-basic, "inputGrey");
    line-height: 34px;
    padding: 0 12px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    cursor: text;

    &:disabled {
        &:hover {
            background-color: map.get($colors-basic, "inputGrey") !important;
        }
    }

    &.filled {
        &:hover {

        }

        &:focus {
            background-color: white;
        }
    }

    &::placeholder {
        color: map.get($colors-basic, "textGray")
    }

    &:hover {
        background-color: map.get($colors-basic, "selectedOption");
    }

    &:focus {
        background-color: white;
    }

    &.error {
        background-color: #FEF4F4 !important;
    }

    svg * {
        fill: white;
    }

    &.leftPadding {
        padding-left: 36px;
    }

    &.rightPadding {
        padding-right: 36px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
}

[data-placeholder]:empty:before {
    content: attr(data-placeholder);
    color: map.get($colors-basic, "textGray");
}

.suggestion{
    display: flex;
    align-items: center;
    gap: 8px;

  cursor: pointer;
  padding: 4px 12px;
  border-radius: 4px;
  &:hover {
    background-color: map.get($colors-basic, "selectedOption");
  }
  
}
.suggestion:before {
    display: block;
    content: " " !important;
    width: 16px;
    height: 16px;
    background-size: 16px 16px;
    margin-top: 3px;
    background-image: url('../../../static/suggestionIcon.png')
}

.suggestions{
    position: absolute;
    z-index: 999;
    border: 1px solid #E8E9ED;
    border-radius: 4px;
    background-color: #fff;
    padding: 3px;
    min-width: 200px;
}

.selectedSuggestion{
    background-color: map.get($colors-basic, "selectedOption");
}


@use "sass:map";
@import '../../styles/variables.scss';

.button {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: map.get($font-sizes, "body");
    font-weight: map.get($font-weights, "semibold");
    background-color: map.get($colors-basic, "primaryBlue");
    height: 36px;
    padding-right: 19px;
    padding-left: 19px;
    border: 1px solid map.get($colors-basic, "primaryBlue");
    border-radius: 4px;
    cursor: pointer;
    transition: all .2s;

    &[disabled] {
        background-color: map.get($colors-basic, "secondary");
        border: 1px solid map.get($colors-basic, "secondary");
        cursor: default;
        color: map.get($colors-basic, "textDisabled");

        &:hover, &:active {
            background-color: map.get($colors-basic, "secondary"); 
            border: 1px solid map.get($colors-basic, "secondary");
        }
    }

    &.M {
        height: 32px;
    }

    &.gap {
        gap: 12px;
    }

    &:hover {
        background-color: map.get($colors-basic, "primaryHovered");
        border: 1px solid map.get($colors-basic, "primaryHovered");
    }

    &:active {
        background-color: map.get($colors-basic, "primaryPressed");
        border: 1px solid map.get($colors-basic, "primaryPressed");
    }

    svg * {
        fill: white;
    }

    &.secondary {
        background-color: map.get($colors-basic, "secondary");
        border: 1px solid map.get($colors-basic, "secondary");
        color: map.get($colors-basic, "textBlack");
        svg * {
            fill: map.get($colors-basic, "textBlack");
        }

        &[disabled] {
            &:hover, &:active {
                background-color: map.get($colors-basic, "secondary"); 
                border: 1px solid map.get($colors-basic, "secondary");
            }
        }

        &:hover {
            background-color: map.get($colors-basic, "secondary");
            border: 1px solid map.get($colors-basic, "textGray");
        }

        &:active {
            background-color: map.get($colors-basic, "secondary");
            border: 1px solid map.get($colors-basic, "divider");
        }
    }

    &.colorRed {
        color: map.get($colors-red, 100);

        svg * {
            fill: map.get($colors-red, 100);
        }
    }

    &.colorGreen {
        color: map.get($colors-green, 100);

        svg * {
            fill: map.get($colors-green, 100);
        }
    }

    &.hiddenFill {
        svg {
            width: 24px;
            height: 24px;
            * {
                fill: none;
            }
        }
    }

    &.red {
        background-color: map.get($colors-red, 100);
        border: 1px solid map.get($colors-red, 100);

        &:hover {
            background-color: map.get($colors-basic, "redHovered");
            border: 1px solid map.get($colors-basic, "redHovered");
        }

        &:active {
            background-color: map.get($colors-basic, "redPressed");
            border: 1px solid map.get($colors-basic, "redPressed");
        }

        &[disabled] {
            opacity: 0.2;
            cursor: default;
            color: white;

            &:hover {
                background-color: map.get($colors-red, 100);
                border: 1px solid map.get($colors-red, 100);
            }
        }
    }

    &.short {
        padding-right: 9px;
        padding-left: 9px;  
    }
}
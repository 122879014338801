/* eslint-disable no-extra-boolean-cast */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable quote-props */
/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState, memo, useMemo } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { uid } from 'uid';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import cn from 'classnames';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import debounce from 'lodash.debounce';
import { format, parseISO } from 'date-fns';
import ValidationErrorList from '../../components/ValidationErrorList/ValidationErrorList';
import DatabaseSchemeLayout from '../../containers/DatabaseSchemeLayout/DatabaseSchemeLayout';
import { createToastRequest } from '../../store/toasts/actions';
import Button from '../../components/Button/Button';
import CommitDiff from '../../components/CommitDiff';
import { useCopyToClipboard, useOutsideClick } from '../../hooks/hooks';
import Input from '../../components/Input/Input';
import ChipsStatus, { ChipsMap } from '../../components/ChipsStatus/ChipsStatus';
import Select from '../../components/Select/Select';
import {
  typesAPI,
  loadStatus,
  backendHost,
  typesConnectionType,
  listRolesName,
  wsHost,
  callbackHost,
  APIMethods,
} from '../../helpers/const';
import { handleWebSocketEvents } from '../../helpers/websocket';
import ActionText from '../../components/ActionText/ActionText';
import ActionIcon from '../../components/ActionIcon/ActionIcon';
import InviteToResourceModal from '../../components/InviteToResourceModal/InviteToResourceModal';
import RemoveAccessModal from '../../components/RemoveAccessModal/RemoveAccessModal';
import CodeEditor from '../../components/CodeEditor';
import DebugConsole from '../../components/DebugConsole';
import Collapsible from '../../components/Collapsible';
import DBIcon from '../../components/DBIcon/DBIcon';
import Loader from '../../components/Loader';
import {
  updateEndpointRequest,
  testEndpointRequest,
  gptRequest as gptRequestData,
} from '../../store/endpoints/actions';
import {
  activeEndpointRequest,
  getEndpointConnectedAuthKeysRequest,
  activateOriginVersion,
  displayVersion,
  modifyEndpointConnectedAuthKeysLimitRequest,
  getEndpointConnectedAuthKeysLimitRequest,
} from '../../store/activeEndpoint/actions';
import { listConnectionsRequest } from '../../store/connections/actions';
import { listAuthKeysRequest } from '../../store/authKeys/actions';
import { getAccessUsersRequest } from '../../store/users/actions';
import { addAccessRequest } from '../../store/invites/actions';
import { isRoleAction } from '../../helpers/hocs';
import {
  getProjectRoles,
  formatingJSONToRender,
  paramsParser,
  isJsonString,
  parseSqlTags,
  parseIfJSON,
  typeParser,
  stringifyIfJSON,
  isEmptyObject,
  sqlPlaceholder,
  capitalize,
  calcPlaceholder,
  limitsInit,
  formatNumber,
  removeLatencyKey,
  mergeObjects,
  isValidJsCode,
  checkEnvExistense,
  convertToType,
  convertToString
} from '../../helpers/utils';
import IconChecked from '../../pictures/icon-checked.svg';
import IconAlert from '../../pictures/icon-alert.svg';
import { ParamentRows } from '../../components/ParametrRows/ParamentRows';
import * as shared from '../../styles/shared.module.scss';
import {
  createVersionRequest,
  historyRequest,
  overrideVersionRequest,
  userRequest,
  diffVersionRequest,
  clearHistoryData,
} from '../../store/history/actions';
import ArrowIcon from '../../pictures/icon-arrow.svg';
import InfoIcon from '../../pictures/info.svg';
import BlueInfoIcon from '../../pictures/blue-info.svg';
import { WarningModal } from '../../components/WarningModal';
import Modal from '../../components/Modal/Modal';
import { ConfigsEnum, WarningsConfigsMap } from '../../components/WarningModal/constants';
import { removeTrashItemsRequest, restoreTrashItemsRequest } from '../../store/trash/actions';
import { getCorrespondingMethod, ftpTypesList } from '../../constans/ftpTypes';
import { useMonaco, useMonacoErrors, useVariables } from '../../hooks/useMonaco';
import { transformValueWithVariables } from '../../helpers/tokenizeAutocompleteUtils';

const MemoizedCodeEditor = memo((props) => (
  // Your component logic here
  <CodeEditor
    value={props.value}
    handleChange={props.handleChange}
    handleBlurCallback={props.handleBlurCallback}
    name={props.name}
    id={props.id}
    type={props.type}
    placeholder={props.placeholder}
    error={props.error}
    style={props.style}
    readOnly={props.readOnly}
    height={props.height}
  />
));

const prepareLimitsObject = (endpointId, values, iterateArray = []) => iterateArray.map(model => {
  const keyId = model.id;
  const sec = values[`RPS-${model.id}`] || 0;
  const cmon = values[`activeRpmType-${model.id}`] === 'cmon' ? (values[`RPMONTHCALENDAR-${model.id}`] || 0) : 0;
  const mon = values[`activeRpmType-${model.id}`] === 'mon' ? (values[`RPMONTH-${model.id}`] || 0) : 0;

  return {
    endpointId: Number(endpointId),
    keyId: Number(keyId),
    limit: {
      sec: Number(sec),
      cmon: Number(cmon),
      mon: Number(mon),
    },
  };
});

const getGptButtonText = (status) => {
  switch (status) {
    case loadStatus.LOAD:
      return 'Generating SQL';
    case loadStatus.SUCCESS:
      return 'Regenerate SQL';
    case loadStatus.ERROR:
      return 'Try again';
    default:
      return 'Generate SQL';
  }
};

const defaultValues = {
  string: '',
  number: 0,
  object: {},
  array: [],
};

const emptyParam = {
  name: '',
  description: '',
  defaultValue: '',
  type: 'string',
  id: uid(8),
  required: 'no',
  source: '',
};

const validResponseExample = (val) => isJsonString(val) || val === '' || !val;
const maxAllowedLength = 64;

const DEFAULT_DATE_FORMAT = 'dd.MM.yy HH:mm:ss';

export const formatISODate = (isoDate, dateFormat) => {
  if (!isoDate) return '-';
  return format(parseISO(isoDate.toISOString()), dateFormat ?? DEFAULT_DATE_FORMAT);
};

const SUBROUTING = {
  ACCESS: 'access',
  AUTHORIZATION: 'authorization',
  HISTORY: 'history',
  LIMITS: 'limits',
};

const subrouteMapping = {
  'RPS': SUBROUTING.LIMITS,
}

const SCRIPTS = {
  PRE: 'pre',
  POST: 'post',
};

const connectionTypeList = [
  {
    value: 'database',
    title: 'Database',
    icon: <DBIcon type="database" style={{ marginRight: '12px', width: '24px', height: '24px' }} />,
  },
  {
    value: 'corezoid',
    title: 'Corezoid',
    icon: <DBIcon type="corezoid" style={{ marginRight: '12px', width: '24px', height: '24px' }} />,
  },
  {
    value: 'aws',
    title: 'AWS S3',
    icon: <DBIcon type="aws" style={{ marginRight: '12px', width: '24px', height: '24px' }} />,
  },
  {
    value: 'chatGpt',
    title: 'ChatGPT',
    icon: <DBIcon type="chatGpt" style={{ marginRight: '12px', width: '24px', height: '24px' }} />,
  },
  // {
  //   value: 'ftp',
  //   title: 'FTP',
  //   icon: <DBIcon type="ftp" style={{ marginRight: '12px', width: '24px', height: '24px' }} />,
  // },
  {
    value: 'apiCall',
    title: 'API',
    icon: <DBIcon type="apiCall" style={{ marginRight: '12px', width: '24px', height: '24px' }} />,
  },
  {
    value: 'none',
    title: 'None',
    icon: <DBIcon type="none" style={{ marginRight: '12px', width: '24px', height: '24px' }} />,
  },
];

const ApisModifySchema = ({ connectionsData, wsMaxQueueSize, wsMaxMessageTTL, variables, connectLimit, activeLimits }) => {

  let schema =   Yup.object().shape({
    name: Yup.string()
      .min(2, 'Name must be at least 2 characters')
      .max(64, 'Name reached limit 64 characters')
      .matches(/^(?!\s)(?!.* {2})/, 'Name regexpr validation failed')
      .required('Name required'),
    endpoint: Yup.string()
      .min(2, 'Endpoint must be at least 2 characters')
      .max(64, 'Endpoint reached limit 64 characters')
      .matches(/^[/{}_&=?\-.\w]+$/g, 'Endpoint regexpr validation failed')
      .test('env-exists', 'All env variables must exist', checkEnvExistense(variables))
      .required('Endpoint required'),
    method: Yup.string().required('Method required'),
    connectionId: Yup.string().when('connectionType', {
      is: (connectionType) => ['database', 'corezoid', 'chatGpt', 'aws', 'ftp'].includes(connectionType),
      then: Yup.string().required('Connection required'),
    }),
    processId: Yup.string().when(['connectionType', 'connectionId'], {
      is: (connectionType, connectionId) => {
        const activeModel = connectionsData?.find((el) => +el.id === +connectionId) || {};
        return connectionType === 'corezoid' && activeModel.requestType === 'synchronous';
      },
      then: Yup.string().required('Process ID required'),
    }),
    bucketName: Yup.string().when('connectionType', {
      is: (connectionType) => connectionType === 'aws',
      then: Yup.string().required('Bucket Name required'),
    }),
    fileName: Yup.string().when(['connectionType', 'ftpMethod'], {
      is: (connectionType, ftpMethod) =>
        connectionType === 'aws' || (connectionType === 'ftp' && ftpMethod !== 'getTree' && ftpMethod !== 'getList'),
        then: Yup.string().required('FileName required'),
    }),
    s3Method: Yup.string().when('connectionType', {
      is: (connectionType) => connectionType === 'aws',
      then: Yup.string().required('S3 method required'),
    }),
    ftpObject: Yup.string().when('connectionType', {
      is: (connectionType) => connectionType === 'ftp',
      then: Yup.string().required('FTP object required'),
    }),
    ftpMethod: Yup.string().when('connectionType', {
      is: (connectionType) => connectionType === 'ftp',
      then: Yup.string().required('FTP method required'),
    }),
    file: Yup.string().when(['connectionType', 's3Method', 'ftpMethod'], {
      is: (connectionType, s3Method, ftpMethod) =>
        (connectionType === 'aws' && ['put', 'post'].includes(s3Method)) ||
        (connectionType === 'ftp' && ['update', 'upload'].includes(ftpMethod)),
      then: Yup.string().required('File required'),
    }),
    newName: Yup.string().when(['connectionType', 'ftpMethod'], {
      is: (connectionType, ftpMethod) => connectionType === 'ftp' && ftpMethod === 'rename',
      then: Yup.string().required('New name required'),
    }),
    query: Yup.string().when('connectionType', {
      is: (connectionType) => connectionType === 'database',
      then: Yup.string()
        .test('env-exists', 'All env variables must exist', checkEnvExistense(variables))
        .required('Query required'),
    }),
    gptData: Yup.string().when('connectionType', {
      is: (connectionType) => connectionType === 'chatGpt',
      then: Yup.string()
        .test('env-exists', 'All env variables must exist', checkEnvExistense(variables))
        .required('gpt Data required'),
    }),
    context: Yup.string().when('connectionType', {
      is: (connectionType) => connectionType === 'chatGpt',
      then: Yup.string()
        .test('env-exists', 'All env variables must exist', checkEnvExistense(variables))
    }),
    gptConnectionId: Yup.string().when(['connectionType', 'sqlType'], {
      is: (connectionType, sqlType) => connectionType === 'database' && sqlType === 'chatGptSql',
      then: Yup.string().required('gpt ConnectionID required'),
    }),
    gptRequest: Yup.string().when(['connectionType', 'sqlType'], {
      is: (connectionType, sqlType) => connectionType === 'database' && sqlType === 'chatGptSql',
      then: Yup.string()
        .test('env-exists', 'All env variables must exist', checkEnvExistense(variables))
        .required('gpt Request required'),
    }),
    gptContext: Yup.string().when(['connectionType', 'sqlType'], {
      is: (connectionType, sqlType) => connectionType === 'database' && sqlType === 'chatGptSql',
      then: Yup.string()
        .test('env-exists', 'All env variables must exist', checkEnvExistense(variables))
    }),
    queueSize: Yup.number()
      .nullable(true)
      .when('method', {
        is: (method) => method === 'ws',
        then: Yup.number().max(wsMaxQueueSize, `Maximum queue size limit: ${formatNumber(wsMaxQueueSize)}`),
      }),
    messageTTL: Yup.number()
      .nullable(true)
      .when('method', {
        is: (method) => method === 'ws',
        then: Yup.number().max(wsMaxMessageTTL, `Maximum message TTL limit: ${formatNumber(wsMaxMessageTTL)}`),
      }),
    responseExample: Yup.string().when(['connectionType'], {
        is: (connectionType) => (connectionType === 'none' || !connectionType?.length),
        then: 
          Yup.string()
            .test('is-json', 'Value must be a valid JSON object', (value) => {
              if (!value || value === '' || /^"{0,1}\{\{env\..*?\}\}"{0,1}$/g.test(value)) return true;
              try {
                const result = JSON.parse(value);
                return result && typeof result === 'object' && result !== null;
              } catch {
                return false;
              }
            })
            .test('env-exists', 'All env variables must exist', checkEnvExistense(variables)),
      }),
      RPS: Yup.number()
        .test('rps-connect-limit', `Max. system RPS limit: ${formatNumber(connectLimit)}`, (value) => {
          if (value > connectLimit) {
            return false;
          }
          return true;
        })
        .test('rps-limit',
          function test(value) {
          const { connectionId } = this.parent;
          const activeModel = connectionsData?.find((el) => +el.id === +connectionId) || {};
          if (activeModel?.limit?.sec && activeModel?.limit?.sec < value) {
            return this.createError({ message: `Max. linked Connection RPS limit: ${formatNumber(activeModel.limit.sec)}` });
          }
          return true;
        })
        ,
      RPMONTH: Yup.number()
        .when('activeRpmType', {
          is: 'mon',
          then: Yup.number()
            .test('rps-value-limit-month', function (value) {
              const { RPS } = this.parent;
              const calculatedLimit = RPS * 60 * 60 * 24 * 30;
              if (value > calculatedLimit) {
                return this.createError({ message: `Max. RPM are: ${formatNumber(calculatedLimit)}` });
              }
              return true;
            })
            .test('rps-connect-limit-month', `Max. system RPM are: ${formatNumber(connectLimit * 60 * 60 * 24 * 30)}`, (value) => {
              if (value > (connectLimit * 60 * 60 * 24 * 30)) {
                return false;
              } 
              return true;
            })
            .test('rpmonth-limit',
              function test(value) {
                const { connectionId } = this.parent;
                const activeModel = connectionsData?.find((el) => +el.id === +connectionId) || {};
                if (activeModel?.limit?.mon && activeModel?.limit?.mon < value) {
                  return this.createError({ message: `Max. linked Connection RPM are: ${formatNumber(activeModel.limit.mon)}` });
                }
                return true;
              })
            ,
        }),
      RPMONTHCALENDAR: Yup.number()
          .when('activeRpmType', {
            is: 'cmon',
            then: Yup.number()
              .test('rps-value-limit-month-calendar', function (value) {
                const { RPS } = this.parent;
                const calculatedLimit = RPS * 60 * 60 * 24 * 30;
                if (value > calculatedLimit) {
                  return this.createError({ message: `Max. RPM are: ${formatNumber(calculatedLimit)}` });
                }
                return true;
              })
              .test('rps-connect-limit-month-calendar', `Max. system RPM are: ${formatNumber(connectLimit * 60 * 60 * 24 * 30)}`, (value) => {
                if (value > (connectLimit * 60 * 60 * 24 * 30)) {
                  return false;
                } 
                return true;
              })
              .test('rpmonthcalendar-limit',
                function test(value) {
                  const { connectionId } = this.parent;
                  const activeModel = connectionsData?.find((el) => +el.id === +connectionId) || {};
                  if (activeModel?.limit?.cmon && activeModel?.limit?.cmon < value) {
                    return this.createError({ message: `Max. linked Connection RPM are: ${formatNumber(activeModel.limit.cmon)}` });
                  }
                  return true;
                })
              ,
          }),
    postScript: Yup.string()
        .test('is-valid-js', 'Post-request script must be valid JavaScript', isValidJsCode)
        .test('env-exists', 'All env variables must exist', checkEnvExistense(variables, 'no-brackets')),
    preScript: Yup.string()
        .test('is-valid-js', 'Pre-request script must be valid JavaScript', isValidJsCode)
        .test('env-exists', 'All env variables must exist', checkEnvExistense(variables, 'no-brackets')),
    params: Yup.array().of(
        Yup.object().shape({
            name: Yup.string().required('Name required'),
            type: Yup.string().required('Type required'),
            source: Yup.string().required('Source required'),
            defaultValue: Yup.mixed()
              .test('env-exists', 'All env variables must exist', checkEnvExistense(variables))
              .when('type', {
              is: 'string',
              then: Yup.string('Field value must be a string'),
              otherwise: Yup.mixed().when('type', {
                is: 'number',
                then: Yup.string()
                  .test('is-number-or-env', 'Field value must be a number or match {{env.variable}} pattern', (value) => {
                    if (!value || value === '' || /^\{\{env\..*?\}\}$/g.test(value) || !Number.isNaN(Number(value))) return true;
                    return false;
                  }),
                otherwise: Yup.mixed().when('type', {
                  is: 'object',
                  then: Yup.string()
                    .test('is-json', 'Value must be a valid non Array JSON object', (value) => {
                      if (!value || value === '' || /^\{\{env\..*?\}\}$/g.test(value)) return true;
                      try {
                        const result = JSON.parse(value);
                        return result && typeof result === 'object' && !Array.isArray(result) && result !== null;
                      } catch {
                        return false;
                      }
                    }),
                  otherwise: Yup.mixed().when('type', {
                    is: 'array',
                    then: Yup.string()
                      .test('is-json', 'Value must be a valid JSON array', (value) => {
                        if (!value || value === '' || /^\{\{env\..*?\}\}$/g.test(value)) return true;
                        try {
                          const result = JSON.parse(value);
                          return Array.isArray(result);
                        } catch {
                          return false;
                        }
                      }),
                    otherwise: Yup.mixed().when('type', {
                      is: 'boolean',
                      then: Yup.string()
                        .test('is-boolean-or-env', 'Value must be a boolean or match {{env.variable}} pattern', (value) => {
                          if (!value || value === '' || /^\{\{env\..*?\}\}$/g.test(value) || value === 'true' || value === 'false') return true;
                          return false;
                        }),
                      otherwise: Yup.mixed().notRequired(),
                    }),
                  }),
                }),
              }),
            }),
          }).test('unique-name', 'Name must be unique', function (item) {
            const { path, createError } = this;
            const items = this.parent;
            const duplicate = items.filter(i => i.name === item.name);
            if (duplicate.length > 1) {
              return createError({ path: `${path}.name`, message: 'The parameter must include unique Key name' });
            }
            return true;
          }),
        ),
  });

  activeLimits.forEach((limit) => {
    schema = schema.shape({
      [`RPS-${limit.id}`]: Yup.number()
      .test('rps-connect-limit', `Max. system RPS limit: ${formatNumber(connectLimit)}`, (value) => {
        if (value > connectLimit) {
          return false;
        }
        return true;
      })
      .test('rps-limit-nested',
        function test(value) {
          const { RPS: apiRPS } = this.parent;
          const { limit: lim } = limit || {};
          const { sec: AuthRPS } = lim || {};
      
          const apiLimit = Number(apiRPS) || 0;
          const authKeyLimit = Number(AuthRPS) || 0;
      
          if (apiLimit && authKeyLimit) {
            if (value > Math.min(apiLimit, authKeyLimit)) {
              return this.createError({ message: authKeyLimit < apiLimit ? `Max. linked Auth Key RPS limit: ${formatNumber(authKeyLimit)}` : `Max. linked API RPS limit: ${formatNumber(apiLimit)}` });
            }
          } else if (apiLimit && value > apiLimit) {
            return this.createError({ message: `Max. linked API RPS limit: ${formatNumber(apiLimit)}` });
          } else if (authKeyLimit && value > authKeyLimit) {
            return this.createError({ message: `Max. linked Auth Key RPS limit: ${formatNumber(authKeyLimit)}` });
          }
      
          return true;
        })
      ,
      [`RPMONTH-${limit.id}`]: Yup.number()
        .when(`activeRpmType-${limit.id}`, {
          is: 'mon',
          then: Yup.number()
            .test('rps-value-limit-month', function (value) {
              const RPS = this.parent[`RPS-${limit.id}`];
              const calculatedLimit = RPS * 60 * 60 * 24 * 30;
              if (value > calculatedLimit) {
                return this.createError({ message: `Max. RPM are: ${formatNumber(calculatedLimit)}` });
              }
              return true;
            })
            .test('rps-connect-limit-month', `Max. system RPM are: ${formatNumber(connectLimit * 60 * 60 * 24 * 30)}`, (value) => {
              if (value > (connectLimit * 60 * 60 * 24 * 30)) {
                return false;
              } 
              return true;
            })
            .test('rpmonth-limit-nested',
              function test(value) {
                const { RPMONTH: apiRPMONTH } = this.parent;
                const { limit: lim } = limit || {};
                const { mon: AuthMONTH } = lim || {};
      
                const apiLimit = Number(apiRPMONTH) || 0;
                const authKeyLimit = Number(AuthMONTH) || 0;
      
                if (apiLimit && authKeyLimit) {
                  if (value > Math.min(apiLimit, authKeyLimit)) {
                    return this.createError({ message: authKeyLimit < apiLimit ? `Max. linked Auth Key RPM limit: ${formatNumber(authKeyLimit)}` : `Max. linked API RPM limit: ${formatNumber(apiLimit)}` });
                  }
                } else if (apiLimit && value > apiLimit) {
                  return this.createError({ message: `Max. linked API RPM limit: ${formatNumber(apiLimit)}` });
                } else if (authKeyLimit && value > authKeyLimit) {
                  return this.createError({ message: `Max. linked Auth Key RPM limit: ${formatNumber(authKeyLimit)}` });
                }
      
                return true;
              })
            ,
        }),
      [`RPMONTHCALENDAR-${limit.id}`]: Yup.number()
        .when(`activeRpmType-${limit.id}`, {
          is: 'cmon',
          then: Yup.number()
            .test('rps-value-limit-month-calendar', function (value) {
              const RPS = this.parent[`RPS-${limit.id}`];
              const calculatedLimit = RPS * 60 * 60 * 24 * 30;
              if (value > calculatedLimit) {
                return this.createError({ message: `Max. RPM are: ${formatNumber(calculatedLimit)}` });
              }
              return true;
            })
            .test('rps-connect-limit-month-calendar', `Max. system RPM are: ${formatNumber(connectLimit * 60 * 60 * 24 * 30)}`, (value) => {
              if (value > (connectLimit * 60 * 60 * 24 * 30)) {
                return false;
              } 
              return true;
            })
            .test('rpmonthcalendar-limit-nested',
              function test(value) {
                const { RPMONTHCALENDAR: apiRPMONTH } = this.parent;
                const { limit: lim } = limit || {};
                const { cmon: AuthMONTH } = lim || {};
      
                const apiLimit = Number(apiRPMONTH) || 0;
                const authKeyLimit = Number(AuthMONTH) || 0;
      
                if (apiLimit && authKeyLimit) {
                  if (value > Math.min(apiLimit, authKeyLimit)) {
                    return this.createError({ message: authKeyLimit < apiLimit ? `Max. linked Auth Key RPM limit: ${formatNumber(authKeyLimit)}` : `Max. linked API RPM limit: ${formatNumber(apiLimit)}` });
                  }
                } else if (apiLimit && value > apiLimit) {
                  return this.createError({ message: `Max. linked API RPM limit: ${formatNumber(apiLimit)}` });
                } else if (authKeyLimit && value > authKeyLimit) {
                  return this.createError({ message: `Max. linked Auth Key RPM limit: ${formatNumber(authKeyLimit)}` });
                }
      
                return true;
              })
            ,
        }),
    });
  });

  return schema;
}

let timingAddParametr = new Date().getTime();

function ApisModify({
  updateEndpoint,
  getConnections,
  connections,
  currentUser,
  getActiveEndpoint,
  activeEndpoint,
  createToast,
  activeProject,
  getAccessUsers,
  addAccess,
  testEndpoint,
  roles,
  connectionTypes,
  isRoleAction,
  gptSendRequest,
  getHistoryData,
  historyList,
  getUserData,
  createVersion,
  displayVersion,
  activateVersion,
  diffVersion,
  activateOriginVersion,
  storedInviteList,
  storedAuthKeysList,
  getAuthKeys,
  authKeys,
  getConnectedAuthKeys,
  setConnectedLimits,
  getConnectedLimits,
  storedAuthKeysLimits,
  connectLimit
}) {
  const isLimitsTriggeredRef = React.useRef(false);
  const {showMonacoErrors} = useMonacoErrors();
  const {variables} = useVariables();
  useMonaco()
  const [selectedAuthKeyId, setSelectedAuthKeyId] = useState(null);
  const [createKeyVisible, setCreateKeyVisible] = useState(true);
  const [authListData, setAuthListData] = useState([]);
  const [activeCategoryFilter, setActiveCategoryFilter] = useState(null);

  const [networkLatency, setNetworkLatency] = useState({});
  const { data: connectionsData = [] } = connections || {};
  const { data: authKeysData = [] } = authKeys || {};
  const [placeholders, setPlaceholders] = useState(limitsInit);
  const dataTypes =
    connectionTypes?.reduce((obj, key) => {
      obj[key.type] = key.dataTypes || ['string'];
      return obj;
    }, {}) || {};
  const { wsMaxMessageTTL, wsMaxQueueSize } = connectionTypes?.find((c) => c.type === 'corezoid') || {};
  const [gptResponseStatus, setGptResponseStatus] = useState(loadStatus.INITIAL);
  const [chatGptQueryTriggered, setChatGptQueryTrigerred] = useState(false);

  const [testDebugStatus, setTestDebugStatus] = useState(loadStatus.INITIAL);
  const navigate = useNavigate();
  const { state } = useLocation();
  const { redirectFolder } = state || {};
  const { shortName, endpointId } = useParams();
  const [shareModalOpened, setShareModalOpened] = useState(false);
  const [createVersionModalOpened, setCreateVersionOpened] = useState(false);
  const [inviteList, setInviteList] = useState([]);
  const [initialAuthKeysList, setInitialAuthKeysList] = useState([]);
  const [parameterType, setParameterType] = useState('desription');
  const [removeModalOpened, setRemoveModalOpened] = useState(false);
  const [activeUser, setActiveUser] = useState({});
  const [editorModeJs, setEditorModeJs] = useState(false);
  const [socketIdentificator, setSocketIdentificator] = useState(null);
  const [copiedText, copyText] = useCopyToClipboard();
  const isDeleted = Boolean(activeEndpoint?.deleted);

  const viewRead = !isRoleAction({ key: 'endpoint', role: 'update', modelRole: activeEndpoint?.role }) || isDeleted;

  const [initSQLExist, setInitSQLExist] = useState(false);

  const [requestExample, setRequestExample] = useState('');
  const [requestExampleData, setRequestExampleData] = useState('');
  const [submitTriggered, setSubmitTriggered] = useState(false);
  const [isChange, setIsChange] = useState(false);

  const [typesList, setTypesList] = useState([
    { value: 'string', title: 'String' },
    { value: 'number', title: 'Number' },
    { value: 'object', title: 'Object' },
    { value: 'boolean', title: 'Boolean' },
    { value: 'array', title: 'Array' },
  ]);

  const [gptResponseError, setGptResponseError] = useState(false);

  useEffect(() => {
    if (activeProject?.id) {
      getConnections({ id: activeProject.id });
      getAuthKeys({ id: activeProject.id });
    }
  }, [activeProject?.id]);

  useEffect(() => {
    if (currentUser?.id) {
      setSocketIdentificator(uid(12));
    }
  }, [currentUser?.id]);

  const dispatch = useDispatch();

  useEffect(() => {
    getAccessUsers({
      id: +endpointId,
      resource: 'endpoint',
    });
    getConnectedAuthKeys({
      id: +endpointId,
    });
    return () => {
      dispatch(clearHistoryData());
    };
  }, []);

  useEffect(() => {
    setInviteList(storedInviteList || []);
  }, [storedInviteList]);

  useEffect(() => {
    setInitialAuthKeysList(storedAuthKeysList || []);
    if (storedAuthKeysList && storedAuthKeysList.length) {
      setAuthListData(storedAuthKeysList);
      setCreateKeyVisible(false);

      getConnectedLimits({ id: +endpointId });
    } else {
      setAuthListData([]);
      setCreateKeyVisible(true);
    }
  }, [storedAuthKeysList]);

  useEffect(() => {
    getActiveEndpoint({ id: endpointId }, () => {
      getHistoryData({ id: +endpointId, resource: 'endpoint' });
    });
  }, [endpointId]);

  const [areHandlersBlocked, setHandlersBlock] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: '',
      description: '',
      endpoint: '',
      method: '',
      s3Method: '',
      outputFormat: 'json',
      status: 'active',
      authLogin: '',
      authPassword: '',
      connectionId: '',
      connectionType: '',
      processId: '',
      query: '',
      params: [],
      newName: '',
      ftpMethod: '',
      ftpObject: 'file',
      RPS: '',
      RPMONTH: '',
      RPMONTHCALENDAR: '',
      activeRpmType: 'cmon',

      responseExample: '',
      responseExampleActiveCode: '200',
      responseRequestCustom: {},
    },
    validationSchema: ApisModifySchema({ 
      connectionsData, 
      wsMaxMessageTTL, 
      wsMaxQueueSize, 
      variables: variables?.data,
      connectLimit,
      activeLimits: authListData
    }),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (v, { setFieldValue }) => {
      const newElements =
        authListData.filter((el) => (initialAuthKeysList ? !initialAuthKeysList.some((e) => e.id === el.id) : true)) ||
        [];
      const removedElements =
        initialAuthKeysList.filter((el) => (authListData ? !authListData.some((e) => e.id === el.id) : true)) || [];

      const req = {
        connectedAuthKeys: newElements.map((el) => el.id),
        disconnectedAuthKeys: removedElements.map((el) => el.id),
        folderId: activeEndpoint?.folderId || 0,
        connectionId: v.connectionId,
        name: v.name,
        preScript: v.method !== 'ws' ? v.preScript : null,
        postScript: v.method !== 'ws' ? v.postScript : null,
        description: v.description,
        httpRequestParams: v.params.map((p) => ({
          name: p.name,
          description: p.description,
          defaultValue: convertToType(p.defaultValue, p.type),
          type: p.type,
          required: p.required === 'yes',
          source: p.source,
        })),
        method: v.method,
        path: v.endpoint,
        limit: {
          sec: +v.RPS || 0,
          mon: v.activeRpmType === 'mon' ? (+v.RPMONTH || 0) : 0,
          cmon: v.activeRpmType === 'cmon' ? (+v.RPMONTHCALENDAR || 0) : 0,
        },
        projectId: +activeProject.id,
        id: endpointId,
      };

      if (v.connectionType === 'corezoid') {
        req.connectionId = v.connectionId;
        if (v.method === 'ws') {
          req.messageTTL = v.messageTTL === '' ? undefined : v.messageTTL;
          req.queueSize = v.queueSize === '' ? undefined : v.queueSize;
        } else {
          req.processId = v.processId;
          req.httpCode = v.httpCode;
        }
      } else if (v.connectionType === 'aws') {
        req.connectionId = v.connectionId;
        req.bucketName = v.bucketName;
        req.folderName = v.folderName;
        req.fileName = v.fileName;
        req.file = v.file;
      } else if (v.connectionType === 'chatGpt') {
        req.connectionId = v.connectionId;
        req.query = v.gptData;
        req.context = v.context;
      } else if (v.connectionType === 'database') {
        req.connectionId = v.connectionId;
        req.dbQueryParams = {
          query: v.query,
        };
        req.gptConnectionId = v.sqlType === 'chatGptSql' ? v.gptConnectionId : 0;
        req.gptRequest = v.sqlType === 'chatGptSql' ? v.gptRequest : undefined;
        req.gptContext = v.sqlType === 'chatGptSql' ? v.gptContext : undefined;
      } else if (v.connectionType === 'ftp') {
        req.connectionId = v.connectionId;
        req.ftpMethod = v.ftpMethod;
        req.ftpObject = v.ftpObject;
        req.folderName = v.folderName;
        if (v.ftpMethod !== 'getList' && v.ftpMethod !== 'getTree') {
          req.fileName = v.fileName;
        }
        if (v.ftpMethod === 'update' || v.ftpMethod === 'upload') {
          req.file = v.file;
        }
        if (v.ftpMethod === 'rename') {
          req.newName = v.newName;
        }
      }

      const getResponseExample = () => {
        const result = Object.entries(v.responseRequestCustom).reduce(
          (acc, [code, jsonResp]) => (jsonResp === '' ? acc : { ...acc, [code]: jsonResp }),
          {},
        );
        return Object.keys(result).length === 1 ? {} : result;
      };

      if (!v.connectionType?.length || v.connectionType === 'none') {
        req.status = 'draft';
        req.responseExample = getResponseExample();
      } else {
        req.responseExample = {};
        req.status = 'active';
      }

      setHandlersBlock(true);
      updateEndpoint(req, () => {
        const endId = +endpointId;
        const prepared = prepareLimitsObject(endId, v, authListData);

        if (prepared?.length && isLimitsTriggeredRef.current) setConnectedLimits(prepared);
        setIsChange(false);
        if (!+v.RPS) setFieldValue('RPS', '');
        setInitialAuthKeysList(authListData || []);
        dispatch(clearHistoryData());
        clearTempState();
        getActiveEndpoint({ id: +endpointId }, () => {
          // getHistoryData({ id: +endpointId, resource: 'endpoint' }, () => setHandlersBlock(false));
        });
      });
    },
  });

  const { submitForm, handleChange: handleChange2, setFieldValue: setFieldValue2, validateForm, values, errors, setValues } = formik;

  const debouncedValidateForm = debounce(() => {
    validateForm();
  }, 100);

  const setFieldValue = (...args) => {
    setFieldValue2(...args);
    if (submitTriggered) {
      debouncedValidateForm();
    }
  }

  const handleChange = (...args) => {
    handleChange2(...args);
    if (submitTriggered) {
      debouncedValidateForm();
    }
  }

  const setFieldValueProxy = (...arg) => {
    setTestDebugStatus(loadStatus.INITIAL);
    setIsChange(true);
    setFieldValue(...arg);
  };

  const setFieldValueSkipCheck = (...arg) => {
    setFieldValue(...arg);
  }

  useEffect(() => {
    if (storedAuthKeysLimits) {
     // Assuming you have access to setFieldValue from Formik
     Object.keys(storedAuthKeysLimits).forEach((key) => {
       const limits = storedAuthKeysLimits[key];
       setFieldValueSkipCheck(`RPS-${key}`, limits.sec || '');
       setFieldValueSkipCheck(`activeRpmType-${key}`, limits.mon !== 0 ? 'mon' : 'cmon');
       setFieldValueSkipCheck(`RPMONTH-${key}`, limits.mon || '');
       setFieldValueSkipCheck(`RPMONTHCALENDAR-${key}`, limits.cmon || '');
     });
    }
 
   }, [storedAuthKeysLimits]); // Add setFieldValue to the dependency array

  useEffect(() => {
    setFieldValueSkipCheck('responseRequestCustom', ({
      ...values.responseRequestCustom,
      [values.responseExampleActiveCode]: values.responseExample?.length ? parseIfJSON(values.responseExample) : '',
    }));
  }, [values.responseExample]);

  useEffect(() => {
    const updatedState = {
      ...values.responseRequestCustom,
      active: values.responseExampleActiveCode,
    };
    setFieldValueSkipCheck('responseExample', updatedState[values.responseExampleActiveCode] ? stringifyIfJSON(updatedState[values.responseExampleActiveCode]) : '');
    setFieldValueSkipCheck('responseRequestCustom', updatedState);
  }, [values.responseExampleActiveCode]);

  const handleSubmitWithCallback = (e) => {
    if(showMonacoErrors()) return
    e.preventDefault();
    setSubmitTriggered(true);
    submitForm(e);
  };

  const handleChangeProxy = (...arg) => {
    setTestDebugStatus(loadStatus.INITIAL);
    setIsChange(true);
    handleChange(...arg);
  };

  const handleChangeNumber = (e) => {
    const { value, name } = e.target;
    if (Number.isNaN(+value) || !Number.isInteger(+value) || +value < 0) return;
    setIsChange(true);
    setFieldValue(name, value ? Math.round(+value) : value);
    setTestDebugStatus(loadStatus.INITIAL);
  };

  const handleBlurField = (e, sourceType) => {
    parseParams(e.target.value, sourceType);
  };

  const changeTypesList = (it) => {
    let list = [
      { value: 'string', title: 'String' },
      { value: 'number', title: 'Number' },
    ];
    if (it && dataTypes && dataTypes[it]) {
      list = dataTypes[it].map((t) => ({ value: t, title: capitalize(t) }));
    }
    return list;
  };

  const redirectToList = (redFolder, parentFolder) => {
    const isNumber = typeof redFolder === 'number';
    if (isNumber) {
      if (redFolder) navigate(`/${shortName}/endpoints/folder/${redFolder}`);
      if (!redFolder) navigate(`/${shortName}/endpoints`);
    } else {
      if (parentFolder) navigate(`/${shortName}/endpoints/folder/${parentFolder}`);
      if (!parentFolder) navigate(`/${shortName}/endpoints`);
    }
  };

  const redirectToTrash = (redFolder, parentFolder) => {
    const isNumber = typeof redFolder === 'number';
    if (isNumber) {
      if (redFolder) navigate(`/${shortName}/trash/endpoints/folder/${redFolder}`);
      if (!redFolder) navigate(`/${shortName}/trash`);
    } else {
      if (parentFolder) navigate(`/${shortName}/trash/endpoints/folder/${parentFolder}`);
      if (!parentFolder) navigate(`/${shortName}/trash`);
    }
  };

  const listAPI = () => redirectToList(redirectFolder, activeEndpoint?.folder);
  const backToTrash = () => redirectToTrash(redirectFolder, activeEndpoint?.folder);

  const {
    connectionsCollection,
    chatGptCollection,
    syncCorezoidCollection,
    asyncCorezoidCollection,
    awsCollection,
    ftpCollection,
    apiCallCollection,
  } = useMemo(() => {
    const collections = connectionsData.reduce(
      (collections, data) => {
        if (
          data.type !== 'corezoid' &&
          data.type !== 'aws' &&
          data.type !== 'chatGpt' &&
          data.type !== 'ftp' &&
          data.type !== 'apiCall'
        ) {
          collections.connectionsCollection.push(data);
        } else if (data.type === 'chatGpt') {
          collections.chatGptCollection.push(data);
        } else if (data.type === 'corezoid') {
          if (data.requestType === 'asynchronous') collections.asyncCorezoidCollection.push(data);
          if (data.requestType === 'synchronous') collections.syncCorezoidCollection.push(data);
        } else if (data.type === 'aws') {
          collections.awsCollection.push(data);
        } else if (data.type === 'ftp') {
          collections.ftpCollection.push(data);
        } else if (data.type === 'apiCall') {
          collections.apiCallCollection.push(data);
        }
        return collections;
      },
      {
        connectionsCollection: [],
        chatGptCollection: [],
        syncCorezoidCollection: [],
        asyncCorezoidCollection: [],
        awsCollection: [],
        ftpCollection: [],
        apiCallCollection: [],
      },
    );

    return collections;
  }, [connectionsData]);

  const selectedConnection = useMemo(() => connectionsData?.find((el) => el.id === values.connectionId), [
    connectionsData,
    values.connectionId,
  ]);

  useEffect(() => {
    if (connectionsCollection?.length && values.connectionId) {
      const find = connectionsCollection.find((el) => el.id === values.connectionId);
      const isMongo = find && find.type === 'mongo';

      setEditorModeJs(isMongo);
    }
  }, [connectionsCollection, values.connectionId]);

  useEffect(() => {
    if (values.connectionId && selectedConnection) {
      setPlaceholders(calcPlaceholder(values, { 
        connection: selectedConnection,
        authKeys: authListData,
      }));
    }
  }, [selectedConnection, values.connectionId]);

  useEffect(() => {
    if (activeEndpoint?.id && variables?.data) {
      const {
        name,
        description,
        path,
        method,
        dbQueryParams,
        httpRequestParams = [],
        context,
        connectionType,
        processId,
        connectionId,
        bucketName,
        folderName,
        fileName,
        file,
        query: gptData,
        ftpMethod,
        ftpObject,
        limit,
        gptConnectionId,
        gptRequest,
        gptContext,
        httpCode,
        status,
        queueSize,
        messageTTL,
        preScript,
        postScript,
        responseExample: resExample,
      } = activeEndpoint || {};
      const { query } = dbQueryParams || {};
      const transformed = httpRequestParams.map((f) => ({ 
         ...f,
         defaultValue: convertToString(f.defaultValue),
         id: uid(8), 
         required: f.required ? 'yes' : 'no' 
      }));

      const getConnectionType = (n) => {
        let name = 'database';
        if (n === 'corezoid') name = 'corezoid';
        if (n === 'aws') name = 'aws';
        if (n === 'chatGpt') name = 'chatGpt';
        if (n === 'ftp') name = 'ftp';
        if (n === 'apiCall') name = 'apiCall';
        return name;
      };

      const params = {
        name,
        description,
        endpoint: path,
        method,
        s3Method: connectionType === 'aws' ? method : '',
        processId,
        httpCode,
        outputFormat: 'json',
        status: status === 'active' ? status : 'draft',
        connectionId,
        instanceType: connectionType,
        connectionType: connectionId ? getConnectionType(connectionType) : connectionType || 'none',
        gptData: connectionType === 'chatGpt' ? gptData : '',
        bucketName,
        context,
        folderName,
        preScript,
        postScript,
        fileName,
        file,
        query,
        params: transformed,
        sqlType: gptConnectionId ? 'chatGptSql' : 'rawSql',
        queueSize,
        messageTTL,
        gptConnectionId,
        gptRequest,
        gptContext,
        ftpMethod,
        ftpObject,
        RPS: limit.sec || '',
        RPMONTH: limit.mon || '',
        RPMONTHCALENDAR: limit.cmon || '',
        activeRpmType: limit.mon ? 'mon' : 'cmon',
      };

      let respExampleChunk = {
        responseExample: '',
        responseExampleActiveCode: '200',
        responseRequestCustom: {},
      }

      let authLimitsChunk = {};

      authListData?.forEach((el) => {
          authLimitsChunk = {
            ...authLimitsChunk,
            [`RPS-${el.id}`]: values[`RPS-${el.id}`] || '',
            [`RPMONTH-${el.id}`]: values[`RPMONTH-${el.id}`] || '',
            [`RPMONTHCALENDAR-${el.id}`]: values[`RPMONTHCALENDAR-${el.id}`] || '',
            [`activeRpmType-${el.id}`]: values[`activeRpmType-${el.id}`] === 'mon' ? 'mon' : 'cmon',
          };
      });

      if (resExample && !isEmptyObject(resExample) && (!connectionType?.length || connectionType === 'none')) {
        respExampleChunk = {
          responseExample: resExample[resExample.active] ? stringifyIfJSON(resExample[resExample.active]) : '',
          responseRequestCustom: resExample,
          responseExampleActiveCode: resExample.active,
        };
      }

      setValues({ ...params, ...respExampleChunk, ...authLimitsChunk });

      setPlaceholders(calcPlaceholder({ ...params, ...authLimitsChunk }, { 
        connection: selectedConnection,
        authKeys: authListData,
      }));

      if (query && query.length) setInitSQLExist(true);
    }
  }, [activeEndpoint, variables?.data]);

  useEffect(() => {
    if (activeEndpoint?.id && connectionsData) {
      const iT = connectionsData?.find((c) => c.id === activeEndpoint?.connectionId)?.type;
      if (iT) setTypesList(changeTypesList(iT));
    }
  }, [activeEndpoint, connectionsData]);

  const addUserOfApiKey = () => {
    setShareModalOpened(true);
  };

  // const saveInviteList = (data) => {
  //   const filtered = data.accessList.filter((el) => el.userEmail !== currentUser.email);
  //   const unique = Object.values(
  //     [...inviteList, ...filtered].reduce((acc, obj) => ({ ...acc, [obj.userEmail]: obj }), {}),
  //   );
  //   setInviteList(unique);
  //   setShareModalOpened(false);
  // };

  // const handeChangeRole = (email, role) => {
  //   const newInviteList = inviteList.map((el) => (el.userEmail === email ? { ...el, roleId: role } : el));
  //   setInviteList(newInviteList);
  // };

  const addParameter = () => {
    if (new Date().getTime() - timingAddParametr > 500) {
      const prev = values.params;
      setFieldValueProxy('params', [...prev, { ...emptyParam, id: uid(8) }]);
    }
  };

  const updateRequestExample = () => {
    const requestData = {};

    const filteredParams = values?.params?.filter((p) => p.source === 'body');

    filteredParams?.forEach((p) => {
      const transformed = transformValueWithVariables(p.defaultValue, variables.data, 'value', true);
      if (p.type === 'boolean') requestData[p.name] = transformed === "true";
      if (p.type === 'object' || p.type === 'array') requestData[p.name] = parseIfJSON(transformed) || defaultValues[p.type];
      if (p.type === 'number') requestData[p.name] = !Number.isNaN(Number(transformed)) ? Number(transformed) : transformed;
      if (p.type === 'string') requestData[p.name] = transformed || defaultValues[p.type];
    });

    const newRequestExample = JSON.stringify(mergeObjects(requestData, {}), null, 2);

    if (newRequestExample !== requestExampleData) {
      setRequestParams(newRequestExample);
    }
  };

  const deleteAccess = (user) => {
    const newInviteList = inviteList.filter((el) => el.email !== user.email);
    setInviteList(newInviteList);
    setRemoveModalOpened(false);
    setActiveUser({});
  };

  const handeChangeRole = (user, role) => {
    if (role === 'delete') {
      setRemoveModalOpened(true);
      setActiveUser(user);
      return;
    }

    const newInviteList = inviteList.map((el) => (el.email === user.email ? { ...el, role } : el));
    addAccess(
      {
        id: +endpointId,
        resource: 'endpoint',
        projectId: +activeProject.id,
        role,
        userId: user.id,
      },
      () => {
        getAccessUsers({ id: +endpointId, resource: 'endpoint' });
        setInviteList(newInviteList);
      },
    );
  };

  const authKeysList = (keys, method, excludeList) => {
    if (keys) {
      const filtered = keys.filter((k) => k.method === method);
      const filtered2 = filtered.filter((d) => !excludeList.some((filterEl) => filterEl.id === d.id));
      return filtered2.map((d) => ({
        value: d.id,
        title: d.name,
      }));
    }
    return [];
  };

  const connectionsList = (dbs) => {
    if (dbs) {
      return [
        ...dbs.map((d) => ({
          value: d.id,
          title: `${typesAPI[d.type] === 'Corezoid' ? ` [${typesConnectionType[d.requestType]}] ` : ''}${
            d.instanceName
          }${typesAPI[d.type] !== 'Corezoid' ? ` (${typesAPI[d.type] || d.type})` : ''}`,
          icon: <DBIcon type={d.type} style={{ marginRight: '12px', width: '24px', height: '24px' }} />,
        })),
      ];
    }
    return [];
  };

  const handleChangeConnectionType = (key, value) => {
    if (value === 'corezoid' && asyncCorezoidCollection.length > 0 && values.method === 'ws') {
      setFieldValueProxy('connectionId', asyncCorezoidCollection[0].id);
      changeInstanceType(asyncCorezoidCollection[0].id);
    } else if (value === 'corezoid' && syncCorezoidCollection.length > 0 && values.method !== 'ws') {
      setFieldValueProxy('connectionId', syncCorezoidCollection[0].id);
      changeInstanceType(syncCorezoidCollection[0].id);
    } else if (value === 'aws' && awsCollection.length > 0) {
      setFieldValueProxy('connectionId', awsCollection[0].id);
      changeInstanceType(awsCollection[0].id);
    } else if (value === 'chatGpt' && chatGptCollection.length > 0) {
      setFieldValueProxy('connectionId', chatGptCollection[0].id);
      changeInstanceType(chatGptCollection[0].id);
    } else if (value === 'database' && connectionsCollection.length > 0) {
      setFieldValueProxy('connectionId', connectionsCollection[0].id);
      changeInstanceType(connectionsCollection[0].id);
    } else if (value === 'ftp' && ftpCollection.length > 0) {
      setFieldValueProxy('connectionId', ftpCollection[0].id);
      changeInstanceType(ftpCollection[0].id);
    } else if (value === 'apiCall' && apiCallCollection.length > 0) {
      setFieldValueProxy('connectionId', apiCallCollection[0].id);
      changeInstanceType(apiCallCollection[0].id);
    } else {
      setFieldValueProxy('connectionId', '');
    }
    setFieldValueProxy([key], value);
  };

  const handleChangeEndpoint = (key, value) => {
    setIsChange(true);
    let newValue = value;
    if (value && value[0] !== '/') {
      newValue = `/${value}`;
    }
    const validationRegex = /^[\/{}_&=?\-.\w]+$/g;
    // const validationRegex = /^[\/{}_&=?\-\w]+$/g;
    // console.log(validationRegex.test(value))
    if (value === '' || (validationRegex.test(value) && value.length <= maxAllowedLength)) {
      setFieldValueProxy([key], newValue.replace(/\s/g, ''));
    }
  };

  const handleChangeName = ({ target }) => {
    const validationRegex = /^(?!\s)(?!.* {2})/;
    if (target.value === '' || (validationRegex.test(target.value) && target.value.length <= maxAllowedLength)) {
      setFieldValueProxy(target.name, target.value);
      setIsChange(true);
    }
  };

  const handleBlurLimits = (e) => {
    const { value, name } = e.target;
    if (Number.isNaN(+value) || !Number.isInteger(+value)) {
      return;
    }
  
    // Check if the name is split or not
    const isSplit = name.includes('-');
    const [baseName, id] = isSplit ? name.split('-') : [name, ''];
  
    switch (baseName) {
      case 'RPS': {
        setFieldValue(name, value);
        const rpmName = isSplit ? `RPMONTH-${id}` : 'RPMONTH';
        const rpmCalendarName = isSplit ? `RPMONTHCALENDAR-${id}` : 'RPMONTHCALENDAR';
        if (+values[rpmName] && +value && +values[rpmName] < +value) setFieldValue(rpmName, value);
        if (+values[rpmCalendarName] && +value && +values[rpmCalendarName] < +value) setFieldValue(rpmCalendarName, value);
        break;
      }
      case 'RPMONTH':
      case 'RPMONTHCALENDAR': {
        const rpsName = isSplit ? `RPS-${id}` : 'RPS';
        if (+values[rpsName] && +value && +values[rpsName] > +value) setFieldValue(rpsName, value);
        setFieldValue(name, value);
        break;
      }
      default: {
        setFieldValue(name, value);
      }
    }
  
    setPlaceholders(calcPlaceholder({ ...values, [name]: value }, { 
      connection: selectedConnection,
      authKeys: authListData
     }));
  };

  const clearBodySource = () => {
    const { params } = values;
    const newParam = params.map((p) => ({
      ...p,
      source: p.source === 'body' ? '' : p.source,
    }));
    setFieldValueProxy('params', newParam);
  };
  
  const valuesRef = React.useRef(values);

  useEffect(() => {
    valuesRef.current = values;
  }, [values]);

  const parseParams = (valueEdit, sourceType) => {
    const par = paramsParser(valueEdit);
    const { params } = valuesRef.current;
    const unique = [...new Set(par)];
    const newParams = [];
    let resulting = [];
    unique.forEach((el) => {
      const [typeParserResult, parsedValue] = typeParser(el) || [];
      const searchingMethod = typeParserResult || sourceType;
      const foundParam = params.find((p) => p.name === parsedValue && p.source === searchingMethod);
      if (!foundParam && parsedValue) {
        newParams.push({
          name: parsedValue,
          description: '',
          defaultValue: '',
          type: 'string',
          id: uid(8),
          required: searchingMethod === 'path' ? 'yes' : 'no',
          source: searchingMethod,
        });
      }
    });
    if (newParams.length === 0 && params[0]?.name === '') {
      resulting = params;
    } else if (params.length === 1 && params[0].name === '') {
      timingAddParametr = new Date().getTime();
      resulting = newParams;
    } else {
      timingAddParametr = new Date().getTime();
      resulting = [...params, ...newParams];
    }
    if (JSON.stringify(resulting) !== JSON.stringify(values.params)) {
      setFieldValueProxy('params', resulting);
    }
  };

  const sendDebugRequest = async () => {
    validateForm(values).then((errors) => {
      if (Object.keys(errors).length) return;
      let requestData = requestExampleData.replace(/\n/g, '').replace(/ /g, '');

      if (requestExampleData.length) {
        try {
          requestData = JSON.parse(requestData);
        } catch (e) {
          console.log('failed to parse JSON from input');
        }
      }
      
      setTestDebugStatus(loadStatus.LOAD);

      const {
        connectionId,
        description,
        query,
        params,
        method,
        status,
        name,
        endpoint,
        connectionType,
        processId,
        gptData,
        context,
        bucketName,
        folderName,
        fileName,
        file,
        sqlType,
        gptConnectionId,
        gptRequest,
        gptContext,
        httpCode,
        ftpMethod,
        ftpObject,
        newName,
        preScript,
        postScript,
      } = values;

      const data = {
        api: {
          description,
          httpRequestParams: params.map((p) => ({
            name: p.name,
            description: p.description,
            defaultValue: p.defaultValue,
            type: p.type,
            required: p.required === 'yes',
            source: p.source,
          })),
          folder: activeEndpoint?.folder || 0,
          method,
          status,
          preScript,
          postScript,
          name,
          projectId: +activeProject.id,
          path: endpoint,
        },
        request: requestData,
      };

      if (connectionType === 'corezoid') {
        data.api.connectionId = connectionId;
        data.api.processId = processId;
        data.api.httpCode = httpCode;
      } else if (connectionType === 'aws') {
        data.api.connectionId = connectionId;
        data.api.bucketName = bucketName;
        data.api.folderName = folderName;
        data.api.fileName = fileName;
        data.api.file = file;
      } else if (connectionType === 'chatGpt') {
        data.api.connectionId = connectionId;
        data.api.dbQueryParams = {
          query: gptData,
        };
        data.api.query = gptData;
        data.api.context = context;
      } else if (connectionType === 'ftp') {
        data.api.connectionId = connectionId;
        data.api.ftpMethod = ftpMethod;
        data.api.ftpObject = ftpObject;
        data.api.folderName = folderName;
        if (ftpMethod !== 'getList' && ftpMethod !== 'getTree') {
          data.api.fileName = fileName;
        }
        if (ftpMethod === 'update' || ftpMethod === 'upload') {
          data.api.file = file;
        }
        if (ftpMethod === 'rename') {
          data.api.newName = newName;
        }
      } else if (connectionType === 'database') {
        data.api.connectionId = connectionId;
        data.api.dbQueryParams = {
          query,
        };
        data.api.gptConnectionId = sqlType === 'chatGptSql' ? gptConnectionId : 0;
        data.api.gptRequest = sqlType === 'chatGptSql' ? gptRequest : undefined;
        data.api.gptContext = sqlType === 'chatGptSql' ? gptContext : undefined;
      } else if (connectionType === 'apiCall') {
        data.api.connectionId = connectionId;
      }

      if (!connectionType?.length || connectionType === 'none') {
        data.api.status = 'draft';
        if (values.responseRequestCustom.length) data.api.responseExample = parseIfJSON(values.responseRequestCustom);
      } else {
        data.api.responseExample = {};
        data.api.status = 'active';
      }

      testEndpoint(data, (stats, response) => {
        if (stats !== 'ERROR') {
          setNetworkLatency(response.latency || {});
          setFieldValueProxy('responseExampleActiveCode', '200');
          setFieldValueProxy('responseExample', stringifyIfJSON(removeLatencyKey(parseIfJSON(response))));
        }
        if (stats === 'ERROR') {
          setFieldValueProxy('responseExampleActiveCode', '400');
          setFieldValueProxy('responseExample', formatingJSONToRender(response));
        }
        setTestDebugStatus(loadStatus[stats]);
      });
    });
  };

  const sendWebsocketDebug = () => {
    const webSocketURL = `${wsHost}/${shortName}${values.endpoint}?reference=${socketIdentificator}`;
    setTestDebugStatus(loadStatus.LOAD);

    try {
      const webSocket = new WebSocket(webSocketURL);
      handleWebSocketEvents(
        webSocket,
        () => setTestDebugStatus(loadStatus.SUCCESS),
        () => setTestDebugStatus(loadStatus.ERROR),
      );
    } catch (error) {
      console.error('Error opening WebSocket connection:', error);
    }
  };

  const setRequestParams = (param) => {
    setTestDebugStatus(loadStatus.INITIAL);
    setRequestExampleData(param);
    setRequestExample((param));
  };

  const setRequestParamsDirectly = (param) => {
    setTestDebugStatus(loadStatus.INITIAL);
    setRequestExampleData(param.replace(/\n/g, '').replace(/ /g, ''));
    setRequestExample(param);
  };


  const renderDebugReqStatus = (st, method) => {
    switch (st) {
      case loadStatus.INITIAL:
        return (
          <div className={shared.testConnectionContainer}>
            <ActionText title="Send test request" onClick={method === 'ws' ? sendWebsocketDebug : sendDebugRequest} />
          </div>
        );
      case loadStatus.SUCCESS:
        return (
          <>
            <div className={shared.testConnectionContainer}>
              <p className={shared.successText}>Success</p>
              <IconChecked />
            </div>
            <div className={shared.testConnectionContainer}>
              <ActionText
                title={
                  <ActionIcon
                    id="speedometer_tooltip"
                    icon="speedometer"
                    style={{ marginLeft: '8px' }}
                    tooltip={{
                      children: (
                        <div style={{ textAlign: 'left', fontSize: `11px`, lineHeight: `14px` }}>
                          <strong style={{ fontWeight: 600 }}>Latency monitoring</strong>
                          <br />
                          <span>TAG: {networkLatency.tag ? `${networkLatency.tag} ms` : '-'}</span>
                          <br />
                          <span>Network: {networkLatency.network ? `${networkLatency.network} ms` : '-'}</span>
                          <br />
                          <span>Pre-script: {networkLatency.preScript ? `${networkLatency.preScript} ms` : '-'}</span>
                          <br />
                          <span>Connection: {networkLatency.connection ? `${networkLatency.connection} ms` : '-'}</span>
                          <br />
                          <span>
                            Post-script: {networkLatency.postScript ? `${networkLatency.postScript} ms` : '-'}
                          </span>
                          <br />
                          <span>Total: {networkLatency.total ? `${networkLatency.total} ms` : '-'}</span>
                          <br />
                        </div>
                      ),
                      place: 'bottom-end',
                      openOnClick: false,
                      clickable: false,
                    }}
                  />
                }
              />
            </div>
          </>
        );
      case loadStatus.ERROR:
        return (
          <>
            <div className={shared.testConnectionContainer}>
              <p className={shared.errorText}>Error</p>
              <IconAlert />
            </div>
          </>
        );
      case loadStatus.LOAD:
        return (
          <div className={shared.testConnectionContainer}>
            <ActionText
              disabled
              title="Sending test request..."
              onClick={method === 'ws' ? sendWebsocketDebug : sendDebugRequest}
            />
            <Loader disabled />
          </div>
        );
      default:
        return (
          <div className={shared.testConnectionContainer}>
            <ActionText title="Send test request" onClick={method === 'ws' ? sendWebsocketDebug : sendDebugRequest} />
          </div>
        );
    }
  };

  const getRoles = (modelRole) => {
    const listRoles = [];
    const filredRoles = getProjectRoles(roles, activeProject.id).filter((r) => r.name !== 'owner');
    if (isRoleAction({ key: 'endpoint', role: 'share', modelRole: activeEndpoint?.role })) {
      listRoles.push(...filredRoles.map((r) => ({ value: r.name, title: listRolesName[r.name] || r.name })));
    } else {
      listRoles.push(
        ...filredRoles.map((r) => ({ value: r.name, title: listRolesName[r.name] || r.name, disabled: true })),
      );
    }
    if (isRoleAction({ key: 'endpoint', role: 'delete', modelRole: activeEndpoint?.role })) {
      listRoles.push({ value: 'delete', title: 'Delete', hidden: currentUser.role === 'viewer', red: true });
    }
    return listRoles;
  };

  const changeInstanceType = (id) => {
    const iT = connectionsData?.find((c) => c.id === id)?.type;
    if (!iT) return;
    setFieldValueProxy('instanceType', iT);
    const newParams = [];
    const checkType = (oldType) => {
      let type = 'string';
      if (dataTypes[iT]?.findIndex((t) => t.toLowerCase() === oldType) !== -1) {
        type = oldType;
      }
      return type;
    };
    values.params.forEach((p) => newParams.push({ ...p, type: checkType(p.type) }));
    setFieldValueProxy('params', newParams);
    setTypesList(changeTypesList(iT));
  };

  const saveInviteList = (data) => {
    // const filtered = data.filter(el => el.id);
    // const unique = Object.values(
    //   [...inviteList, ...filtered].reduce((acc, obj) => ({ ...acc, [obj.email]: obj }), {}),
    // );
    // setInviteList(unique);
    getAccessUsers({ id: +endpointId, resource: 'endpoint' });
    setShareModalOpened(false);
  };

  const handleGenerateSQL = () => {
    setGptResponseStatus(loadStatus.LOAD);
    gptSendRequest(
      {
        gptConnectionId: values.gptConnectionId,
        connectionId: values.connectionId,
        query: values.gptRequest,
        context: values.gptContext,
      },
      (result, res) => {
        setChatGptQueryTrigerred(true);
        if (result !== 'ERROR') {
          const sqlTags = parseSqlTags(res);
          setGptResponseStatus(loadStatus.SUCCESS);
          setFieldValueProxy('chatGptResponse', res);
          setFieldValueProxy('query', sqlTags);
          setGptResponseError(!sqlTags.length);
        } else {
          setGptResponseStatus(loadStatus.ERROR);
          setFieldValueProxy('chatGptResponse', 'Error!');
          setGptResponseError(true);
        }
      },
    );
  };

  const [subroute, setSubroute] = useState(SUBROUTING.AUTHORIZATION);
  const [requestActiveScript, setRequestActiveScript] = useState(SCRIPTS.PRE);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [commitId, setCommitId] = useState(null);
  const [versionCommitId, setVersionCommitId] = useState(null);
  const [openedHistory, setOpenedHistory] = useState({});
  const [knownUsers, setKnownUsers] = useState([]);

  const toggleCreateVersionModal = () => setCreateVersionOpened((prev) => !prev);

  const [warningConfig, setWarningConfig] = useState(ConfigsEnum.CREATE);
  const [versionToActivate, setVersionToActivate] = useState(0);

  const onOpenWarningModal = (warningConfigName, version = 0, commitID) => {
    setWarningConfig(warningConfigName);
    toggleCreateVersionModal();
    if (warningConfigName === ConfigsEnum.ACTIVATION) {
      setVersionToActivate(version);
      setVersionCommitId(commitID);
    }
  };

  const getUnknownUsersIds = (fatherId) => {
    const allChildUsersIds = historyList.children.reduce((acc, val) => {
      if (val.father === fatherId) {
        return [...acc, val.author];
      }
      return acc;
    }, []);
    const allUniqueChildUsersIds = [...new Set(allChildUsersIds)];
    const usersToRequest = allUniqueChildUsersIds.filter((element) => knownUsers.indexOf(element) < 0);
    setKnownUsers((prev) => [...prev, ...usersToRequest]);
    return usersToRequest;
  };

  const clearTempState = () => {
    setIsReadOnly(false);
    setOpenedHistory({});
    setKnownUsers([]);
    setCommitId(null);
    setVersionCommitId(null);
  };

  const handleHideVersionDetails = () => {
    activateOriginVersion();
    setIsReadOnly(false);
    setCommitId(null);
    setVersionCommitId(null);
    setOpenedHistory({});
  };

  const handleActivateCommit = (id) => {
    const commit = historyList.parents.find((commit) => commit.id === id);
    if (commit) {
      setIsReadOnly(true);
      displayVersion(commit.data);
    }
  };

  const saveAddAuthKey = (id) => {
    setIsChange(true);
    const activeKey = authKeysData.find((el) => el.id === id);
    setFieldValueProxy(`activeRpmType-${id}`, 'cmon'); // set corresponding form value for connected Auth key
    if (activeKey) {
      setAuthListData((prev) => [...prev, activeKey]);

      setActiveCategoryFilter(null);
      setSelectedAuthKeyId(null);
      setCreateKeyVisible(false);
    }
  };

  const checkKeys = (model) => {
    const { id } = model || {};
    const newTabUrl = `${window.location.origin}/${activeProject.shortName}/auth-keys/${id}`;

    const newTab = window.open(newTabUrl, '_blank', 'noopener,noreferrer');

    if (newTab) {
      newTab.focus();
    }
  };

  const deleteAuthKey = (id) => {
    setIsChange(true);
    setAuthListData((prev) => {
      if (prev.length === 1) setCreateKeyVisible(true);
      return prev.filter((el) => el.id !== id);
    });
  };

  const handlerCheckDetails = (versionId, versionAuthorId, fatherId) => {
    setOpenedHistory((prev) => {
      const wasOpen = prev[versionId];
      if (wasOpen) {
        activateOriginVersion();
        setIsReadOnly(false);
        setCommitId(null);
        setVersionCommitId(null);
        return {};
      }
      handleActivateCommit(versionId);
      setVersionCommitId(versionId);
      return { [versionId]: versionAuthorId };
    });
    if (!Object.keys(openedHistory).some((el) => el === versionId.toString())) {
      const usersArray = getUnknownUsersIds(fatherId);
      usersArray.map((userId) => getUserData({ id: userId }));
    }
  };

  const handleShowCommit = (commitID, ignoreDiffReq) => {
    if (commitID !== commitId && historyList.children.length > 1 && !ignoreDiffReq) {
      diffVersion({ id: +endpointId, resource: 'endpoint', commitId: commitID });
    }
    setCommitId((prev) => (prev === commitID ? null : commitID));
  };

  const outsideRef = useOutsideClick(() => {
    if (warningConfig !== ConfigsEnum.ACTIVATION) {
      handleHideVersionDetails();
    }
  });

  const onCreateNewVersion = () => {
    const reqData = { id: +endpointId, resource: 'endpoint' };
    createVersion(reqData, () => {
      clearTempState();
      getActiveEndpoint({ id: endpointId }, () => {
        getHistoryData(reqData, toggleCreateVersionModal);
      });
    });
  };

  const onActivateClick = () => {
    activateVersion({ id: +endpointId, resource: 'endpoint', commitId: versionCommitId }, () => {
      clearTempState();
      getActiveEndpoint({ id: +endpointId }, () => {
        getHistoryData({ id: +endpointId, resource: 'endpoint' }, toggleCreateVersionModal);
      });
    });
  };

  const getOnSaveWarningCallback = () => {
    switch (warningConfig) {
      case ConfigsEnum.ACTIVATION:
        return onActivateClick;
      default:
        return onCreateNewVersion;
    }
  };

  const handleRemoveApi = () => {
    dispatch(
      removeTrashItemsRequest({ type: 'endpoints', ids: [activeEndpoint?.id] }, ({ errors, successList }) => {
        if (errors && errors.length > 0) {
          errors.forEach((error) => dispatch(createToastRequest({ type: 'error', text: error.error })));
        }
        if (successList && successList.length > 0) {
          dispatch(
            createToastRequest({
              type: 'success',
              title: activeEndpoint?.name,
              text: 'API has been deleted successfully',
            }),
          );
          backToTrash();
        }
      }),
    );
  };

  const handleRestoreApi = () => {
    dispatch(
      restoreTrashItemsRequest(
        { type: 'endpoints', items: [{ ...activeEndpoint, deleted: false }] },
        ({ errors, successList }) => {
          if (errors && errors.length > 0) {
            errors.forEach((error) => dispatch(createToastRequest({ type: 'error', text: error.error })));
          }
          if (successList && successList.length > 0) {
            dispatch(
              createToastRequest({
                type: 'success',
                title: activeEndpoint?.name,
                text: 'API has been restored successfully',
              }),
            );
            backToTrash();
          }
        },
      ),
    );
  };

  useEffect(() => {
    if (authListData.length) {
      const valuesFields = authListData.reduce((acc, item) => ({
        ...acc,
        [`RPS-${item.id}`]: values[`RPS-${item.id}`] || '',
        [`RPMONTH-${item.id}`]: values[`RPMONTH-${item.id}`] || '',
        [`RPMONTHCALENDAR-${item.id}`]: values[`RPMONTHCALENDAR-${item.id}`] || '',
      }), {});
  
      const newValues = { ...values, ...valuesFields };
      setValues(newValues);
    }
  }, [authListData]);

  return (
    <div className={shared.page}>
      <div className={shared.header}>
        <div className={shared.headerTitleGroup}>
          <h1 className={shared.headerTitle}>Modify API</h1>
          <p className={shared.headerDescription}>{isDeleted ? 'Read only' : ''}</p>
        </div>
        <div className={shared.headerButtonGroup}>
          {!isDeleted && (
            <>
              <ValidationErrorList 
                errors={errors} 
                subrouteMapping={subrouteMapping}
                setSubroute={setSubroute}
              />
              <Button title="Cancel" type="secondary" onClick={listAPI} />
              <Button title="Modify API" onClick={handleSubmitWithCallback} disabled={isReadOnly || viewRead || !isChange} />
            </>
          )}
          {isDeleted && (
            <>
              <Button title="Cancel" type="secondary" onClick={backToTrash} />
              <Button title="Delete" type="red" onClick={handleRemoveApi} />
              <Button title="Restore" onClick={handleRestoreApi} />
            </>
          )}
        </div>
      </div>
      <div className={shared.body}>
        <div className={shared.mainContent}>
          <section className={shared.section}>
            <h4 className={shared.sectionHeader}>API Settings</h4>
            <div className={shared.sectionContainer}>
              <div className={shared.inputWrapper}>
                <span className={shared.inputLabel}>Name</span>
                <Input
                  placeholder="Enter API name"
                  handleChange={handleChangeName}
                  value={values.name}
                  name="name"
                  id="name"
                  error={!!errors.name}
                  disabled={viewRead || isReadOnly}
                  autoComplete="off"
                />
              </div>
              <div className={shared.inputWrapper}>
                <span className={shared.inputLabel}>
                  Description <span className={shared.inputLabelDetails}> (optional)</span>
                </span>
                <Input
                  placeholder="Describe API"
                  handleChange={handleChangeProxy}
                  value={values.description}
                  name="description"
                  id="description"
                  disabled={viewRead || isReadOnly}
                />
              </div>
              <div className={shared.inputWrapper}>
                <span className={shared.inputLabel}>Endpoint</span>
                <Input
                  placeholder="/endpoint"
                  handleChange={(e) => handleChangeEndpoint('endpoint', e.target.value)}
                  onBlur={(e) => handleBlurField(e, 'path')}
                  value={values.endpoint}
                  name="endpoint"
                  id="endpoint"
                  autoComplete="off"
                  iconRight={values.endpoint && 'copy'}
                  handleAction={() => {
                    copyText(`${backendHost}/${activeProject.shortName}${transformValueWithVariables(values.endpoint, variables.data, 'value', true)}`) &&
                      createToast({ type: 'success', text: 'Copied' });
                  }}
                  error={!!errors.endpoint}
                  disabled={viewRead || isReadOnly}
                  withVariables
                />
              </div>
              <div className={shared.inputWrapper}>
                <span className={shared.inputLabel}>Request method</span>
                <Select
                  list={[
                    { value: 'get', title: 'GET' },
                    { value: 'post', title: 'POST' },
                    { value: 'put', title: 'PUT' },
                    { value: 'patch', title: 'PATCH' },
                    { value: 'delete', title: 'DELETE' },
                    { value: 'ws', title: 'WebSocket (beta)' },
                  ]}
                  value={values.method}
                  handleChange={(key, value) => {
                    setFieldValueProxy(key, value);
                    if (value === 'get') clearBodySource();
                    if (value === 'ws' && values.connectionType && values.connectionType !== 'corezoid')
                      {setFieldValueProxy('connectionType', 'corezoid');}

                    if (value === 'ws' && values.method !== 'ws') {
                      if (asyncCorezoidCollection.length)
                        {setFieldValueProxy('connectionId', asyncCorezoidCollection[0].id);}
                      if (!asyncCorezoidCollection.length) setFieldValueProxy('connectionId', '');
                    }

                    if (value !== 'ws' && values.method === 'ws') {
                      if (syncCorezoidCollection.length)
                        {setFieldValueProxy('connectionId', syncCorezoidCollection[0].id);}
                      if (!syncCorezoidCollection.length) setFieldValueProxy('connectionId', '');
                    }
                  }}
                  name="method"
                  id="method"
                  placeholder="Select method"
                  error={!!errors.method}
                  disabled={
                    viewRead ||
                    isReadOnly ||
                    (values.s3Method?.length && values.connectionType === 'aws') ||
                    (values.ftpMethod?.length && values.connectionType === 'ftp')
                  }
                />
              </div>
              <div className={shared.inputWrapper}>
                <span className={shared.inputLabel}>Output format</span>
                <Select
                  list={[{ value: 'json', title: 'JSON' }]}
                  value={values.outputFormat}
                  placeholder="Select format"
                  handleChange={setFieldValueProxy}
                  name="outputFormat"
                  id="outputFormat"
                  disabled={viewRead || isReadOnly}
                />
              </div>
              <div className={shared.inputWrapper}>
                <span className={shared.inputLabel}>Status</span>
                <Select
                  list={[
                    { value: 'active', title: 'Active' },
                    { value: 'draft', title: 'Draft' },
                    { value: 'inactive', title: 'Inactive' },
                  ]}
                  value={values.status}
                  handleChange={setFieldValueProxy}
                  name="status"
                  id="status"
                  disabled
                />
              </div>
            </div>
          </section>
          <hr />
          <section className={shared.section}>
            <h4 className={shared.sectionHeader}>Connection Settings</h4>
            <div className={shared.sectionContainer}>
              <div className={shared.inputWrapper}>
                <span className={shared.inputLabel}>Connection type</span>
                <Select
                  list={connectionTypeList}
                  value={values.connectionType}
                  placeholder="Select connetion type"
                  handleChange={(key, value) => {
                    handleChangeConnectionType(key, value);
                    // method and s3 method must be equeal when AWS connection used
                    if (value === 'aws' && values.s3Method?.length) setFieldValueProxy('method', values.s3Method);
                    if (value !== 'corezoid' && values.method === 'ws') setFieldValueProxy('method', 'get');
                  }}
                  name="connectionType"
                  id="connectionType"
                  error={!!errors.connectionType}
                  disabled={viewRead || isReadOnly}
                />
              </div>
              {values.connectionType === 'aws' && awsCollection ? (
                <>
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>Connection instance</span>
                    <Select
                      list={[
                        ...connectionsList(awsCollection),
                        {
                          value: 'new',
                          component: (
                            <ActionText title="Create new connection" target={`/${shortName}/connections/create`} />
                          ),
                        },
                      ]}
                      value={values.connectionId}
                      placeholder="Select instance"
                      handleToggleOptions={() => getConnections({ id: activeProject?.id })}
                      handleChange={(key, value) => {
                        setFieldValueProxy(key, value);
                        changeInstanceType(value);
                      }}
                      name="connectionId"
                      id="connectionId"
                      error={!!errors.connectionId}
                      disabled={viewRead || isReadOnly}
                    />
                  </div>
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>
                      <span>S3 method</span>
                      <ActionText
                        title={
                          <ActionIcon
                            id="s3Method_tooltip"
                            icon="info"
                            style={{ marginLeft: '8px' }}
                            tooltip={{
                              children: (
                                <div style={{ textAlign: 'left' }}>Method that will be applied to the object.</div>
                              ),
                              place: 'right',
                              openOnClick: true,
                              clickable: true,
                            }}
                          />
                        }
                      />
                    </span>
                    <Select
                      list={[
                        { value: 'post', title: 'Save file' },
                        { value: 'get', title: 'Search file' },
                        { value: 'put', title: 'Update file' },
                        { value: 'delete', title: 'Delete file' },
                      ]}
                      value={values.s3Method}
                      handleChange={(key, value) => {
                        setFieldValueProxy(key, value);
                        setFieldValueProxy('method', value);
                      }}
                      name="s3Method"
                      id="s3Method"
                      placeholder="Select method"
                      error={!!errors.s3Method}
                      disabled={viewRead || isReadOnly}
                    />
                  </div>
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>
                      <span>Bucket name</span>
                      <ActionText
                        title={
                          <ActionIcon
                            id="bucketName_tooltip"
                            icon="info"
                            style={{ marginLeft: '8px' }}
                            tooltip={{
                              children: (
                                <div style={{ textAlign: 'left' }}>
                                  AWS S3 Bucket name.
                                  <br />{' '}
                                  <a
                                    href="https://docs.aws.amazon.com/AmazonS3/latest/userguide/UsingBucket.html"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    Learn more.
                                  </a>
                                </div>
                              ),
                              place: 'right',
                              openOnClick: true,
                              clickable: true,
                            }}
                          />
                        }
                      />
                    </span>
                    <Input
                      placeholder="Bucket name"
                      handleChange={handleChangeProxy}
                      onBlur={handleBlurField}
                      value={values.bucketName}
                      name="bucketName"
                      id="bucketName"
                      error={!!errors.bucketName}
                      disabled={viewRead || isReadOnly}
                    />
                  </div>
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>
                      <span>Folder name</span>
                      <ActionText
                        title={
                          <ActionIcon
                            id="folderName_tooltip"
                            icon="info"
                            style={{ marginLeft: '8px' }}
                            tooltip={{
                              children: (
                                <div style={{ textAlign: 'left' }}>
                                  Folder name that will be used as a part of the path to the file in AWS S3.
                                  <br />{' '}
                                  <a
                                    href="https://docs.aws.amazon.com/AmazonS3/latest/userguide/UsingObjects.html"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    Learn more.
                                  </a>
                                </div>
                              ),
                              place: 'right',
                              openOnClick: true,
                              clickable: true,
                            }}
                          />
                        }
                      />
                    </span>
                    <Input
                      placeholder="Folder name"
                      handleChange={handleChangeProxy}
                      onBlur={handleBlurField}
                      value={values.folderName}
                      name="folderName"
                      id="folderName"
                      error={!!errors.folderName}
                      disabled={viewRead || isReadOnly}
                    />
                  </div>
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>
                      <span>File name</span>
                      <ActionText
                        title={
                          <ActionIcon
                            id="s3fileName_tooltip"
                            icon="info"
                            style={{ maxWidth: '240px', marginLeft: '8px' }}
                            tooltip={{
                              children: (
                                <div style={{ maxWidth: '240px', textAlign: 'left' }}>
                                  A file name that will be used to store the file in AWS S3.
                                  <br />{' '}
                                  <a
                                    href="https://docs.aws.amazon.com/AmazonS3/latest/userguide/UsingObjects.html"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    Learn more.
                                  </a>
                                </div>
                              ),
                              place: 'right',
                              openOnClick: true,
                              clickable: true,
                            }}
                          />
                        }
                      />
                    </span>
                    <Input
                      placeholder="File name"
                      handleChange={handleChangeProxy}
                      value={values.fileName}
                      onBlur={handleBlurField}
                      name="fileName"
                      id="fileName"
                      error={!!errors.fileName}
                      disabled={viewRead || isReadOnly}
                    />
                  </div>
                  {values.s3Method === 'put' || values.s3Method === 'post' ? (
                    <div className={shared.inputWrapper}>
                      <span className={shared.inputLabel}>
                        <span>File</span>
                        <ActionText
                          title={
                            <ActionIcon
                              id="s3file_tooltip"
                              icon="info"
                              style={{ marginLeft: '8px' }}
                              tooltip={{
                                children: (
                                  <div style={{ maxWidth: '240px', textAlign: 'left' }}>
                                    Base64-encoded string should be passed to this field to store the file to AWS S3.
                                    <br />{' '}
                                    <a
                                      href="https://docs.aws.amazon.com/AmazonS3/latest/userguide/UsingObjects.html"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      Learn more.
                                    </a>
                                  </div>
                                ),
                                place: 'right',
                                openOnClick: true,
                                clickable: true,
                              }}
                            />
                          }
                        />
                      </span>
                      <Input
                        placeholder="File"
                        handleChange={handleChangeProxy}
                        value={values.file}
                        onBlur={handleBlurField}
                        name="file"
                        id="file"
                        error={!!errors.file}
                        disabled={viewRead || isReadOnly}
                      />
                    </div>
                  ) : null}
                </>
              ) : null}
              {values.connectionType === 'corezoid' && (syncCorezoidCollection || asyncCorezoidCollection) ? (
                <>
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>Connection instance</span>
                    <Select
                      list={[
                        ...connectionsList(values.method === 'ws' ? asyncCorezoidCollection : syncCorezoidCollection),
                        {
                          value: 'new',
                          component: (
                            <ActionText title="Create new connection" target={`/${shortName}/connections/create`} />
                          ),
                        },
                      ]}
                      value={values.connectionId}
                      placeholder="Select instance"
                      handleToggleOptions={() => getConnections({ id: activeProject?.id })}
                      handleChange={(key, value) => {
                        setFieldValueProxy(key, value);
                        changeInstanceType(value);
                      }}
                      name="connectionId"
                      id="connectionId"
                      error={!!errors.connectionId}
                      disabled={viewRead || isReadOnly}
                    />
                  </div>
                  {values.method !== 'ws' ? (
                    <div className={shared.inputWrapper}>
                      <span className={shared.inputLabel}>Corezoid Process ID</span>
                      <Input
                        placeholder="Enter process ID"
                        handleChange={handleChangeProxy}
                        value={values.processId}
                        name="processId"
                        id="processId"
                        iconRight={values.processId && 'copy'}
                        handleAction={() => {
                          copyText(values.processId) && createToast({ type: 'success', text: 'Copied' });
                        }}
                        error={!!errors.processId}
                        disabled={viewRead || isReadOnly}
                      />
                    </div>
                  ) : null}
                  {values.method !== 'ws' ? (
                    <div className={shared.inputWrapper}>
                      <span className={shared.inputLabel}>
                        <span>Response HTTP code</span>
                        <ActionText
                          title={
                            <ActionIcon
                              id="httpCode_tooltip"
                              icon="info"
                              style={{ marginLeft: '8px' }}
                              tooltip={{
                                children: (
                                  <div style={{ maxWidth: '240px', textAlign: 'left' }}>
                                    Custom HTTP code that will be sent in response to this API. If not specified, HTTP
                                    code 200 will be sent. This field accepts numbers or variables that will be sent via
                                    Corezoid.
                                    <br />
                                    Example: 400, &#123;&#123;customHTTPCode&#125;&#125;.
                                  </div>
                                ),
                                place: 'right',
                                openOnClick: true,
                                clickable: true,
                              }}
                            />
                          }
                        />
                      </span>
                      <Input
                        placeholder="Enter response HTTP code"
                        handleChange={handleChangeProxy}
                        value={values.httpCode}
                        name="httpCode"
                        id="httpCode"
                        iconRight={values.httpCode && 'copy'}
                        handleAction={() => {
                          copyText(values.httpCode) && createToast({ type: 'success', text: 'Copied' });
                        }}
                        error={!!errors.httpCode}
                        disabled={viewRead || isReadOnly}
                      />
                    </div>
                  ) : null}
                  {values.method === 'ws' ? (
                    <div className={shared.inputWrapper}>
                      <span className={shared.inputLabel}>
                        <span>Message TTL, seconds</span>
                        <ActionText
                          title={
                            <ActionIcon
                              id="messageTtl_tooltip"
                              icon="info"
                              style={{ marginLeft: '8px', maxWidth: '240px' }}
                              tooltip={{
                                children: (
                                  <div style={{ textAlign: 'left' }}>
                                    The number of seconds that messages will be waiting to be delivered until they are
                                    automatically deleted.
                                  </div>
                                ),
                                place: 'right',
                                openOnClick: true,
                                clickable: true,
                              }}
                            />
                          }
                        />
                      </span>
                      <Input
                        maxLength={15}
                        placeholder="0"
                        handleChange={handleChangeNumber}
                        value={values.messageTTL}
                        name="messageTTL"
                        id="messageTTL"
                        error={!!errors.messageTTL}
                        disabled={viewRead || isReadOnly}
                      />
                      {(!!errors.messageTTL) ? (
                        <span className={shared.errorPlaceholder}>{errors.messageTTL}</span>
                      ) : null}
                    </div>
                  ) : null}
                  {values.method === 'ws' ? (
                    <div className={shared.inputWrapper}>
                      <span className={shared.inputLabel}>
                        <span>Message queue size</span>
                        <ActionText
                          title={
                            <ActionIcon
                              id="messageQueueSize_tooltip"
                              icon="info"
                              style={{ marginLeft: '8px' }}
                              tooltip={{
                                children: (
                                  <div style={{ textAlign: 'left', maxWidth: '240px' }}>
                                    The number of messages will be stored until they are delivered.{' '}
                                    <a
                                      href="https://www.spiceworks.com/tech/devops/articles/fifo-vs-lifo/#_002"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      “First in, first out” (FIFO)
                                    </a>{' '}
                                    principle is used to manage the queue.
                                  </div>
                                ),
                                place: 'right',
                                openOnClick: true,
                                clickable: true,
                              }}
                            />
                          }
                        />
                      </span>
                      <Input
                        maxLength={15}
                        placeholder="0"
                        handleChange={handleChangeNumber}
                        value={values.queueSize}
                        name="queueSize"
                        id="queueSize"
                        error={!!errors.queueSize}
                        disabled={viewRead || isReadOnly}
                      />
                      {(!!errors.queueSize) ? (
                        <span className={shared.errorPlaceholder}>{errors.queueSize}</span>
                      ) : null}
                    </div>
                  ) : null}
                  {values.method === 'ws' ? (
                    <div className={cn(shared.inputWrapper, shared.mergeColumns)}>
                      <span className={shared.inputLabel}>
                        <span>Websocket Callback URL</span>
                        <ActionText
                          title={
                            <ActionIcon
                              id="wsCallbackUrl_tooltip"
                              icon="info"
                              style={{ marginLeft: '8px' }}
                              tooltip={{
                                children: (
                                  <div style={{ maxWidth: '240px', textAlign: 'left' }}>
                                    This URL will be used to send responses from Corezoid to websocket channel.
                                  </div>
                                ),
                                place: 'right',
                                openOnClick: true,
                                clickable: true,
                              }}
                            />
                          }
                        />
                      </span>
                      <DebugConsole
                        method="POST"
                        url={`${callbackHost}/callback/${shortName}${values.endpoint || ''}`}
                        onCopy={(text) => {
                          copyText(text) && createToast({ type: 'success', text: 'Copied' });
                        }}
                      />
                    </div>
                  ) : null}
                </>
              ) : null}
              {values.connectionType === 'chatGpt' && chatGptCollection ? (
                <>
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>Connection instance</span>
                    <Select
                      list={[
                        ...connectionsList(chatGptCollection),
                        {
                          value: 'new',
                          component: (
                            <ActionText title="Create new connection" target={`/${shortName}/connections/create`} />
                          ),
                        },
                      ]}
                      value={values.connectionId}
                      placeholder="Select instance"
                      handleToggleOptions={() => getConnections({ id: activeProject?.id })}
                      handleChange={(key, value) => {
                        setFieldValueProxy(key, value);
                        changeInstanceType(value);
                      }}
                      name="connectionId"
                      id="connectionId"
                      error={!!errors.connectionId}
                      disabled={viewRead || isReadOnly}
                    />
                  </div>
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>
                      <span>ChatGPT request</span>
                      <ActionText
                        title={
                          <ActionIcon
                            id="gtpRequest_tooltip"
                            icon="info"
                            style={{ marginLeft: '8px' }}
                            tooltip={{
                              children: (
                                <div style={{ maxWidth: '240px', textAlign: 'left' }}>
                                  Request that will be sent to ChatGPT as a prompt. You can enter it manually or pass
                                  &#123;&#123;variable&#125;&#125; from the request.
                                </div>
                              ),
                              place: 'right',
                              openOnClick: true,
                              clickable: true,
                            }}
                          />
                        }
                      />
                    </span>
                    <MemoizedCodeEditor
                      value={values.gptData}
                      handleChange={(value) => setFieldValueProxy('gptData', value)}
                      handleBlurCallback={parseParams}
                      name="gptData"
                      id="gptData"
                      type="text"
                      placeholder="Enter request to ChatGPT"
                      error={!!errors.gptData}
                      readOnly={viewRead || isReadOnly}
                      style={{ maxWidth: 'calc((100vw - 674px + 48px) / 2)', minWidth: '200px' }}
                    />
                  </div>
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>
                      <span>Context</span>
                      <ActionText
                        title={
                          <ActionIcon
                            id="gtpContext_tooltip"
                            icon="info"
                            style={{ marginLeft: '8px' }}
                            tooltip={{
                              children: (
                                <div style={{ maxWidth: '240px', textAlign: 'left' }}>
                                  Data from this field will enrich the ChatGPT context to make better response.
                                </div>
                              ),
                              place: 'right',
                              openOnClick: true,
                              clickable: true,
                            }}
                          />
                        }
                      />
                    </span>
                    <MemoizedCodeEditor
                      value={values.context}
                      handleChange={(value) => setFieldValueProxy('context', value)}
                      handleBlurCallback={parseParams}
                      name="context"
                      id="context"
                      type="text"
                      placeholder="Context"
                      error={!!errors.context}
                      readOnly={viewRead || isReadOnly}
                      style={{ maxWidth: 'calc((100vw - 674px + 48px) / 2)', minWidth: '200px' }}
                    />
                  </div>
                </>
              ) : null}
              {values.connectionType === 'ftp' && ftpCollection && (
                <>
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>Connection instance</span>
                    <Select
                      list={[
                        ...connectionsList(ftpCollection),
                        {
                          value: 'new',
                          component: (
                            <ActionText title="Create new connection" target={`/${shortName}/connections/create`} />
                          ),
                        },
                      ]}
                      value={values.connectionId}
                      placeholder="Select instance"
                      handleToggleOptions={() => getConnections({ id: activeProject?.id })}
                      handleChange={(key, value) => {
                        setFieldValueProxy(key, value);
                        changeInstanceType(value);
                      }}
                      name="connectionId"
                      id="connectionId"
                      error={!!errors.connectionId}
                      disabled={viewRead || isReadOnly}
                    />
                  </div>
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>
                      <span>FTP method</span>
                      <ActionText
                        disabled
                        title={
                          <ActionIcon
                            id="ftpMethod_tooltip"
                            icon="info"
                            style={{ marginLeft: '8px' }}
                            tooltip={{
                              content: 'Select FTP method',
                              place: 'right',
                            }}
                          />
                        }
                      />
                    </span>
                    <Select
                      list={ftpTypesList}
                      value={values.ftpMethod}
                      placeholder="Select FTP method"
                      handleChange={(key, value) => {
                        setFieldValueProxy(key, value);
                        setFieldValueProxy('method', getCorrespondingMethod(value));
                        if (value === 'getTree') setFieldValueProxy('ftpObject', 'folder');
                        if (value === 'update' || value === 'upload') setFieldValueProxy('ftpObject', 'file');
                      }}
                      name="ftpMethod"
                      id="ftpMethod"
                      error={!!errors.ftpMethod}
                      disabled={viewRead || isReadOnly}
                    />
                  </div>
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>
                      <span>FTP object</span>
                      <ActionText
                        disabled
                        title={
                          <ActionIcon
                            id="ftpObject_tooltip"
                            icon="info"
                            style={{ marginLeft: '8px' }}
                            tooltip={{
                              content: 'Select FTP object',
                              place: 'right',
                            }}
                          />
                        }
                      />
                    </span>
                    <Select
                      list={[
                        { value: 'file', title: 'File' },
                        { value: 'folder', title: 'Folder' },
                      ]}
                      value={values.ftpObject}
                      handleChange={(key, value) => {
                        setFieldValueProxy(key, value);
                      }}
                      name="ftpObject"
                      id="ftpObject"
                      placeholder="Select ftp objectType"
                      error={!!errors.ftpObject}
                      disabled={viewRead || isReadOnly || ['getTree', 'update', 'upload'].includes(values.ftpMethod)}
                    />
                  </div>
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>
                      <span>Path</span>
                      <ActionText
                        disabled
                        title={
                          <ActionIcon
                            id="ftpPath_tooltip"
                            icon="info"
                            style={{ marginLeft: '8px' }}
                            tooltip={{
                              content: 'Enter file path',
                              place: 'right',
                            }}
                          />
                        }
                      />
                    </span>
                    <Input
                      placeholder="Path"
                      handleChange={handleChangeProxy}
                      onBlur={handleBlurField}
                      value={values.folderName}
                      name="folderName"
                      id="folderName"
                      error={!!errors.folderName}
                      disabled={viewRead || isReadOnly}
                    />
                  </div>
                  {values.ftpMethod && values.ftpMethod !== 'getList' && values.ftpMethod !== 'getTree' && (
                    <div className={shared.inputWrapper}>
                      <span className={shared.inputLabel}>
                        <span>
                          {values.ftpMethod === 'rename'
                            ? `Current ${values.ftpObject} name`
                            : `${capitalize(values.ftpObject)} name`}
                        </span>
                        <ActionText
                          disabled
                          title={
                            <ActionIcon
                              id="fileNameFtp_tooltip"
                              icon="info"
                              style={{ marginLeft: '8px' }}
                              tooltip={{
                                content: 'Enter name',
                                place: 'right',
                              }}
                            />
                          }
                        />
                      </span>
                      <Input
                        placeholder={
                          values.ftpMethod === 'rename'
                            ? `Current ${values.ftpObject} name`
                            : `${capitalize(values.ftpObject)} name`
                        }
                        handleChange={handleChangeProxy}
                        onBlur={handleBlurField}
                        value={values.fileName}
                        name="fileName"
                        id="fileName"
                        error={!!errors.fileName}
                        disabled={viewRead || isReadOnly}
                      />
                    </div>
                  )}
                  {values.ftpMethod === 'rename' && (
                    <div className={shared.inputWrapper}>
                      <span className={shared.inputLabel}>
                        <span>{`New ${values.ftpObject} name`}</span>
                        <ActionText
                          disabled
                          title={
                            <ActionIcon
                              id="newName_tooltip"
                              icon="info"
                              style={{ marginLeft: '8px' }}
                              tooltip={{
                                content: 'Enter name',
                                place: 'right',
                              }}
                            />
                          }
                        />
                      </span>
                      <Input
                        placeholder={`New ${values.ftpObject} name`}
                        handleChange={handleChangeProxy}
                        onBlur={handleBlurField}
                        value={values.newName}
                        name="newName"
                        id="newName"
                        error={!!errors.newName}
                        disabled={viewRead || isReadOnly}
                      />
                    </div>
                  )}
                  {(values.ftpMethod === 'update' || values.ftpMethod === 'upload') && (
                    <div className={shared.inputWrapper}>
                      <span className={shared.inputLabel}>
                        <span>{capitalize(values.ftpObject)}</span>
                        <ActionText
                          disabled
                          title={
                            <ActionIcon
                              id="fileFtp_tooltip"
                              icon="info"
                              style={{ marginLeft: '8px' }}
                              tooltip={{
                                content: 'Provide file',
                                place: 'right',
                              }}
                            />
                          }
                        />
                      </span>
                      <Input
                        placeholder={capitalize(values.ftpObject)}
                        handleChange={handleChangeProxy}
                        onBlur={handleBlurField}
                        value={values.file}
                        name="file"
                        id="file"
                        error={!!errors.file}
                        disabled={viewRead || isReadOnly}
                      />
                    </div>
                  )}
                </>
              )}
              {values.connectionType === 'apiCall' && apiCallCollection && (
                <>
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>Connection instance</span>
                    <Select
                      list={[
                        ...connectionsList(apiCallCollection),
                        {
                          value: 'new',
                          component: (
                            <ActionText title="Create new connection" target={`/${shortName}/connections/create`} />
                          ),
                        },
                      ]}
                      value={values.connectionId}
                      placeholder="Select instance"
                      handleToggleOptions={() => getConnections({ id: activeProject?.id })}
                      handleChange={(key, value) => {
                        setFieldValueProxy(key, value);
                        changeInstanceType(value);
                      }}
                      name="connectionId"
                      id="connectionId"
                      error={!!errors.connectionId}
                      disabled={viewRead || isReadOnly}
                    />
                  </div>
                </>
              )}
              {values.connectionType === 'database' && connectionsCollection ? (
                <>
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>Connection instance</span>
                    <Select
                      list={[
                        ...connectionsList(connectionsCollection),
                        {
                          value: 'new',
                          component: (
                            <ActionText title="Create new connection" target={`/${shortName}/connections/create`} />
                          ),
                        },
                      ]}
                      handleToggleOptions={() => getConnections({ id: activeProject?.id })}
                      value={values.connectionId}
                      placeholder="Select instance"
                      handleChange={(key, value) => {
                        setFieldValueProxy(key, value);
                        changeInstanceType(value);
                      }}
                      name="connectionId"
                      id="connectionId"
                      error={!!errors.connectionId}
                      disabled={viewRead || isReadOnly}
                    />
                  </div>
                </>
              ) : null}
            </div>
            {values.connectionType === 'database' ? (
              <>
                <div
                  className={cn(shared.sectionContainer, {
                    [shared.wide]: editorModeJs,
                  })}
                >
                  {!editorModeJs ? (
                    <div className={shared.inputWrapper}>
                      <span className={shared.inputLabel}>Request type</span>
                      <Select
                        list={[
                          { value: 'rawSql', title: 'Raw SQL' },
                          { value: 'chatGptSql', title: 'ChatGPT-generated SQL' },
                        ]}
                        value={values.sqlType}
                        placeholder="Select SQL mode"
                        handleChange={(key, value) => {
                          setFieldValueProxy(key, value);
                        }}
                        name="sqlType"
                        id="sqlType"
                        error={!!errors.sqlType}
                        disabled={viewRead || isReadOnly}
                      />
                    </div>
                  ) : null}
                  {values.sqlType === 'chatGptSql' && !editorModeJs ? (
                    <>
                      <div className={shared.inputWrapper}>
                        <span className={shared.inputLabel}>ChatGPT instance</span>
                        <Select
                          list={[
                            ...connectionsList(chatGptCollection),
                            {
                              value: 'new',
                              component: (
                                <ActionText title="Create new connection" target={`/${shortName}/connections/create`} />
                              ),
                            },
                          ]}
                          value={values.gptConnectionId}
                          placeholder="Select instance"
                          handleToggleOptions={() => getConnections({ id: activeProject?.id })}
                          handleChange={(key, value) => {
                            setFieldValueProxy(key, value);
                          }}
                          name="gptConnectionId"
                          id="gptConnectionId"
                          error={!!errors.gptConnectionId}
                          disabled={viewRead || isReadOnly}
                        />
                      </div>
                      <div className={shared.inputWrapper}>
                        <span className={shared.inputLabel}>
                          <span>ChatGPT request</span>
                          <ActionText
                            title={
                              <ActionIcon
                                id="gtpRequestDatabase_tooltip"
                                icon="info"
                                style={{ marginLeft: '8px' }}
                                tooltip={{
                                  children: (
                                    <div style={{ maxWidth: '240px', textAlign: 'left' }}>
                                      Request that will be sent to ChatGPT as a prompt. You can enter it manually or
                                      pass &#123;&#123;variable&#125;&#125; from the request.
                                    </div>
                                  ),
                                  place: 'right',
                                  openOnClick: true,
                                  clickable: true,
                                }}
                              />
                            }
                          />
                        </span>
                        <MemoizedCodeEditor
                          readOnly={viewRead || isReadOnly}
                          value={values.gptRequest}
                          handleChange={(value) => setFieldValueProxy('gptRequest', value)}
                          handleBlurCallback={parseParams}
                          name="gptRequest"
                          id="gptRequest"
                          type="text"
                          placeholder="Enter request to ChatGPT"
                          error={!!errors.gptRequest}
                          style={{
                            maxWidth: 'calc((100vw - 674px + 48px) / 2)',
                            minWidth: '200px',
                          }}
                          height={72}
                        />
                      </div>
                      <div className={shared.inputWrapper} style={{ gridRow: 'span 4', overflow: 'hidden' }}>
                        <span className={shared.inputLabel}>
                          Database structure <span className={shared.inputLabelDetails}> (beta)</span>
                        </span>
                        <div style={{ overflow: 'hidden' }}>
                          <DatabaseSchemeLayout connectionId={values.connectionId} activeProject={activeProject} />
                        </div>
                      </div>
                      <div className={shared.inputWrapper}>
                        <span className={shared.inputLabel}>
                          <span>Context</span>
                          <ActionText
                            title={
                              <ActionIcon
                                id="gtpContextDatabase_tooltip"
                                icon="info"
                                style={{ marginLeft: '8px' }}
                                tooltip={{
                                  children: (
                                    <div style={{ maxWidth: '240px', textAlign: 'left' }}>
                                      Data from this field will enrich the ChatGPT context to make better response.
                                    </div>
                                  ),
                                  place: 'right',
                                  openOnClick: true,
                                  clickable: true,
                                }}
                              />
                            }
                          />
                        </span>
                        <MemoizedCodeEditor
                          readOnly={viewRead || isReadOnly}
                          value={values.gptContext}
                          handleChange={(value) => setFieldValueProxy('gptContext', value)}
                          handleBlurCallback={parseParams}
                          name="gptContext"
                          id="gptContext"
                          type="text"
                          placeholder="Context"
                          error={!!errors.gptContext}
                          style={{
                            maxWidth: 'calc((100vw - 674px + 48px) / 2)',
                            minWidth: '200px',
                          }}
                          height={72}
                        />
                      </div>
                      <div className={shared.inputWrapper}>
                        <span className={shared.inputLabel}>ChatGPT response</span>
                        {!chatGptQueryTriggered ? (
                          <Input
                            disabled
                            value=""
                            placeholder="Waiting for request..."
                            handleChange={() => {}}
                            name="chatGptResponseFake"
                          />
                        ) : (
                          <MemoizedCodeEditor
                            readOnly
                            value={values.chatGptResponse}
                            handleChange={(value) => setFieldValueProxy('chatGptResponse', value)}
                            name="chatGptResponse"
                            id="chatGptResponse"
                            type="json"
                            placeholder=""
                            error={!!errors.chatGptResponse}
                            style={{
                              maxWidth: 'calc((100vw - 674px + 48px) / 2)',
                              minWidth: '200px',
                            }}
                            height={162}
                          />
                        )}
                      </div>
                    </>
                  ) : null}
                  {!editorModeJs && values.sqlType !== 'chatGptSql' ? (
                    <div className={shared.inputWrapper} style={{ gridRow: 'span 2', overflow: 'hidden' }}>
                      <span className={shared.inputLabel}>
                        Database structure <span className={shared.inputLabelDetails}> (beta)</span>
                      </span>
                      <div style={{ overflow: 'hidden' }}>
                        <DatabaseSchemeLayout connectionId={values.connectionId} activeProject={activeProject} />
                      </div>
                    </div>
                  ) : null}
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>{editorModeJs ? 'Query' : 'SQL'}</span>
                    {values.sqlType && values.sqlType === 'chatGptSql' && !chatGptQueryTriggered && !initSQLExist ? (
                      <Input
                        disabled
                        value=""
                        placeholder="Waiting for request..."
                        handleChange={() => {}}
                        name="queryFake"
                      />
                    ) : (
                      <MemoizedCodeEditor
                        readOnly={viewRead || isReadOnly}
                        value={values.query}
                        handleChange={(value) => {
                          setFieldValueProxy('query', value);
                        }}
                        handleBlurCallback={parseParams}
                        name="query"
                        id="query"
                        type={editorModeJs ? 'javascript' : 'sql'}
                        placeholder={sqlPlaceholder(editorModeJs, values.sqlType === 'chatGptSql', gptResponseError)}
                        error={!!errors.query}
                        style={
                          editorModeJs
                            ? { maxWidth: 'calc(100vw - 651px + 48px)', minWidth: '400px' }
                            : { maxWidth: 'calc((100vw - 674px + 48px) / 2)', minWidth: '200px' }
                        }
                        height={editorModeJs ? undefined : 302}
                      />
                    )}
                  </div>
                </div>
                <div className={cn(shared.sectionContainer, shared.wide)}>
                  {values.sqlType === 'chatGptSql' && !editorModeJs && (
                    <div className={cn(shared.chatGptButtonContainer)}>
                      <div className={shared.btnContainer}>
                        <Button
                          disabled={
                            !(values.gptRequest?.length && values.gptConnectionId) ||
                            gptResponseStatus === loadStatus.LOAD
                          }
                          title={getGptButtonText(gptResponseStatus)}
                          onClick={() => handleGenerateSQL()}
                          loading={gptResponseStatus === loadStatus.LOAD}
                        />
                        {!!errors.query && !values.query && !chatGptQueryTriggered ? (
                          <p className={shared.errorText}>Generate SQL to be able to create API</p>
                        ) : null}
                      </div>
                      <div className={shared.statusContainer}>
                        {gptResponseStatus === loadStatus.SUCCESS && (
                          <>
                            <p className={shared.successText}>Success</p>
                            <IconChecked />
                          </>
                        )}
                        {gptResponseStatus === loadStatus.ERROR && (
                          <>
                            <p className={shared.errorText}>Error</p>
                            <IconAlert />
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </>
            ) : null}
          </section>
          <hr />
          <section className={shared.section}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h4 className={shared.sectionHeader}>Input Parameters</h4>
              {!viewRead && <ActionText title="Add parameter" onClick={addParameter} />}
            </div>
            {values?.params?.length > 0 && (
              <div className={cn(shared.sectionContainer, shared.triple)} style={{ marginBottom: '-20px' }}>
                <div className={shared.inputWrapper}>
                  <span className={shared.inputLabel}>Source</span>
                </div>
                <div className={shared.inputWrapper}>
                  <span className={shared.inputLabel}>Name</span>
                </div>
                <div className={shared.inputWrapper}>
                  <div className={shared.inputLabel} style={{ whiteSpace: 'pre' }}>
                    <ActionText
                      title="Description"
                      onClick={() => setParameterType('desription')}
                      style={parameterType !== 'desription' ? { color: '#93939F' } : {}}
                    />
                    {' / '}
                    <ActionText
                      title="Default value"
                      onClick={() => setParameterType('default')}
                      style={parameterType !== 'default' ? { color: '#93939F' } : {}}
                    />
                  </div>
                </div>
                <div className={shared.inputWrapper}>
                  <span className={shared.inputLabel}>Type</span>
                </div>
                <div className={shared.inputWrapper}>
                  <span className={shared.inputLabel}>Required</span>
                </div>
                <div className={cn(shared.inputWrapper, shared.btn)} />
              </div>
            )}
            {values.params && (
              <DndProvider backend={HTML5Backend}>
                <ParamentRows
                  params={values.params}
                  errors={errors}
                  parameterType={parameterType}
                  viewRead={viewRead}
                  handleUpdate={setFieldValueProxy}
                  typesList={typesList}
                  updateRequestExample={updateRequestExample}
                  requestExample={requestExample}
                  activeMethod={values.method}
                />
              </DndProvider>
            )}
          </section>
          <hr />
          {values.method !== 'ws' ? (
            <section className={shared.section}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className={shared.subHeaders}>
                  <h4
                    onClick={() => setRequestActiveScript(SCRIPTS.PRE)}
                    className={cn(
                      shared.sectionHeader,
                      { [shared.clickable]: true },
                      { [shared.passive]: requestActiveScript !== SCRIPTS.PRE },
                    )}
                  >
                    Pre-request Script
                  </h4>
                  <h4
                    onClick={() => setRequestActiveScript(SCRIPTS.POST)}
                    className={cn(
                      shared.sectionHeader,
                      { [shared.clickable]: true },
                      { [shared.passive]: requestActiveScript !== SCRIPTS.POST },
                    )}
                  >
                    Post-request Script
                  </h4>
                </div>
              </div>
              <div className={cn(shared.sectionContainer, shared.wide)}>
                {requestActiveScript === SCRIPTS.PRE ? (
                  <div className={shared.inputWrapper}>
                    <MemoizedCodeEditor
                      value={values.preScript}
                      handleChange={(value) => setFieldValueProxy('preScript', value)}
                      name="preScript"
                      id="preScript"
                      type="javascript"
                      placeholder="Enter Pre-request Script"
                      error={!!errors.preScript}
                      readOnly={viewRead || isReadOnly}
                    />
                  </div>
                ) : null}
                {requestActiveScript === SCRIPTS.POST ? (
                  <div className={shared.inputWrapper}>
                    <MemoizedCodeEditor
                      value={values.postScript}
                      handleChange={(value) => setFieldValueProxy('postScript', value)}
                      name="postScript"
                      id="postScript"
                      type="javascript"
                      placeholder="Enter Post-request Script"
                      error={!!errors.postScript}
                      readOnly={viewRead || isReadOnly}
                    />
                  </div>
                ) : null}
              </div>
              <hr />
            </section>
          ) : null}
          <section className={shared.section}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h4 className={shared.sectionHeader}>Debug console</h4>
              {values.preScript?.length ||
              values.postScript?.length ||
              (values.connectionType && values.connectionType !== 'none') ? (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: '10px',
                  }}
                >
                  {!isDeleted ? (
                    <div className={shared.testConnectionContainerGroup}>
                      {renderDebugReqStatus(testDebugStatus, values.method)}
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              ) : null}
            </div>
            <div className={cn(shared.sectionContainer, shared.wide)}>
              <div className={shared.inputWrapper}>
                {shortName && values.endpoint && values.method && (
                  <>
                    {values.method === 'ws' ? (
                      <DebugConsole
                        method={values.method}
                        url={`${wsHost}/${shortName}${transformValueWithVariables(values.endpoint, variables.data, 'value', true)}`}
                        params={requestExample}
                        onCopy={(text) => {
                          copyText(text) && createToast({ type: 'success', text: 'Copied' });
                        }}
                      />
                    ) : (
                      <DebugConsole
                        method={values.method}
                        url={`${backendHost}/${shortName}${transformValueWithVariables(values.endpoint, variables.data, 'value', true)}`}
                        params={requestExample}
                        onCopy={(text) => {
                          copyText(text) && createToast({ type: 'success', text: 'Copied' });
                        }}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
            {values.method !== 'ws' ? (
              <div className={shared.sectionContainer}>
                <div className={shared.inputWrapper}>
                <span style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between' }}>
                    <span className={shared.inputLabel}>
                        Request example
                    </span>
                    {'  '}
                      <ActionText title="Update" onClick={updateRequestExample} />
                  </span>
                  <MemoizedCodeEditor
                    value={requestExample}
                    handleChange={setRequestParamsDirectly}
                    type="json"
                    placeholder=" "
                    style={{ maxWidth: 'calc((100vw - 674px + 48px) / 2)', minWidth: '200px' }}
                    readOnly={viewRead || isReadOnly}
                  />
                </div>
                <div className={shared.inputWrapper}>
                  <span className={cn(shared.inputLabel, shared.flexBetween)}>
                    <span>Response example</span>
                    {values.connectionType === 'none' || !values.connectionType?.length ? (
                      <span
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          flexDirection: 'row',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        <span>Status code:</span>
                        <Select
                          type="text"
                          list={[
                            { value: '200', title: '200' },
                            { value: '400', title: '400' },
                            { value: '404', title: '404' },
                            { value: '500', title: '500' },
                            { value: '503', title: '503' },
                          ]}
                          value={values.responseExampleActiveCode}
                          handleChange={(key, value) => {
                            setFieldValueProxy(key, value);
                            setIsChange(true);
                          }}
                          disabled={viewRead || isReadOnly}
                          name="responseExampleActiveCode"
                        />
                      </span>
                    ) : null}
                  </span>
                  <MemoizedCodeEditor
                    value={values.responseExample}
                    handleChange={(value) => {
                      if(value === values.responseExample) return;
                      setFieldValueProxy('responseExample', value)
                      setIsChange(true);
                    }}
                    name="responseExample"
                    id="responseExample"
                    type={/^"{0,1}\{\{env\..*?\}\}"{0,1}$/g.test(values.responseExample) ? 'string' : 'json'}
                    readOnly={isReadOnly || !(values.connectionType === 'none' || !values.connectionType?.length)}
                    placeholder=" "
                    style={{ maxWidth: 'calc((100vw - 674px + 48px) / 2)', minWidth: '200px' }}
                    error={!!errors.responseExample}
                  />
                </div>
              </div>
            ) : (
              <div className={shared.sectionContainer} />
            )}
          </section>
        </div>
        <div className={shared.sidepanel}>
          <div className={shared.sidepanelGroup}>
            <div className={shared.sidepanelHeading}>
              <div className={shared.subHeaders}>
                <h4
                  onClick={() => setSubroute(SUBROUTING.AUTHORIZATION)}
                  className={cn(
                    shared.sectionHeader,
                    { [shared.clickable]: true },
                    { [shared.passive]: subroute !== SUBROUTING.AUTHORIZATION },
                  )}
                >
                  Auth
                </h4>
                {false && (
                  <h4
                    onClick={() => setSubroute(SUBROUTING.HISTORY)}
                    className={cn(shared.sectionHeader, shared.clickable, {
                      [shared.passive]: subroute !== SUBROUTING.HISTORY,
                    })}
                  >
                    History
                  </h4>
                )}
                <h4
                  onClick={() => setSubroute(SUBROUTING.LIMITS)}
                  className={cn(
                    shared.sectionHeader,
                    { [shared.clickable]: true },
                    { [shared.passive]: subroute !== SUBROUTING.LIMITS },
                  )}
                >
                  Limits
                </h4>
                <h4
                  onClick={() => setSubroute(SUBROUTING.ACCESS)}
                  className={cn(
                    shared.sectionHeader,
                    { [shared.clickable]: true },
                    { [shared.passive]: subroute !== SUBROUTING.ACCESS },
                  )}
                >
                  Access
                </h4>
                {subroute === SUBROUTING.ACCESS &&
                  !isDeleted &&
                  isRoleAction({ key: 'endpoint', role: 'share', modelRole: activeEndpoint?.role }) && (
                    <div className={shared.textAction}>
                      <ActionText title="Share access" onClick={addUserOfApiKey} />
                    </div>
                  )}
              </div>
              {subroute === SUBROUTING.HISTORY && (
                <div>
                  <ActionText onClick={() => onOpenWarningModal(ConfigsEnum.CREATE)} title="Create new version" />
                </div>
              )}
              {subroute === SUBROUTING.AUTHORIZATION && !isDeleted && !(viewRead || isReadOnly) && !createKeyVisible && (
                <div className={shared.textAction}>
                  <ActionText title="Add Auth Key" onClick={() => setCreateKeyVisible(true)} />
                </div>
              )}
            </div>
            {subroute === SUBROUTING.AUTHORIZATION && (
              <div className={shared.sidepanelTable}>
                {authListData?.length ? (
                  <div className={shared.sidepanelTableHeading}>
                    <div className={shared.lineHeader}>
                      <div>Name</div>
                      <div />
                      <div>Type</div>
                    </div>
                  </div>
                ) : null}

                <div className={shared.sidepanelTableBodyContainer}>
                  <ul className={shared.sidepanelTableBody} style={{ paddingTop: 0 }}>
                    {authListData?.length
                      ? authListData?.map((model) => (
                          <li className={shared.historyLine} key={model.id}>
                            <div>
                              <span>{model.name}</span>
                            </div>
                            <div />
                            <div>
                              <ChipsStatus style={{ width: 90 }} title={APIMethods[model.method]} type="user" />
                            </div>
                            <div
                              className={cn(shared.historyLineControls, {
                                [shared.historyLineControlsDisabled]: viewRead || isReadOnly,
                              })}
                            >
                              <ActionIcon
                                id={`${model.id}_auth_list_item_settings`}
                                icon="settings"
                                onClick={() => checkKeys(model)}
                              />
                              <ActionIcon
                                id={`${model.id}_auth_list_item_trash`}
                                icon="trash"
                                onClick={() => deleteAuthKey(model.id)}
                              />
                            </div>
                          </li>
                        ))
                      : null}
                    {createKeyVisible && authListData?.length ? (
                      <li>
                        <hr className={shared.sidepanelTableBodyDivider} />{' '}
                      </li>
                    ) : null}
                    {createKeyVisible ? (
                      <li className={shared.sidepanelForm}>
                        <section className={shared.limitSection}>
                          <div className={shared.inputWrapper}>
                            <span className={shared.inputLabel}>Method</span>
                            <Select
                              list={[
                                { value: 'basic', title: 'Basic' },
                                { value: 'APIKey', title: 'API Key' },
                                { value: 'bearer', title: 'Bearer' },
                                { value: 'JWT', title: 'JWT' },
                              ]}
                              value={activeCategoryFilter}
                              placeholder="Select Method"
                              handleChange={(key, value) => {
                                setActiveCategoryFilter(value);
                                if (activeCategoryFilter !== value) setSelectedAuthKeyId(undefined);
                              }}
                              name="AddNewAuthKeyCategoryFilter"
                              disabled={viewRead || isReadOnly}
                            />
                          </div>
                          {activeCategoryFilter?.length ? (
                            <>
                              <div className={shared.inputWrapper}>
                                <span className={shared.inputLabel}>Auth Key</span>
                                <Select
                                  list={[
                                    ...authKeysList(authKeysData, activeCategoryFilter, authListData),
                                    {
                                      value: 'new',
                                      component: (
                                        <ActionText
                                          title="Create new auth key"
                                          target={`/${shortName}/auth-keys/create`}
                                        />
                                      ),
                                    },
                                  ]}
                                  value={selectedAuthKeyId}
                                  placeholder="Select auth key"
                                  handleToggleOptions={() => getAuthKeys({ id: activeProject?.id })}
                                  handleChange={(key, value) => setSelectedAuthKeyId(value)}
                                  name="createAuthKeyId"
                                  disabled={viewRead || isReadOnly}
                                />
                              </div>
                              <div className={shared.inputWrapper}>
                                <Button
                                  disabled={!selectedAuthKeyId || viewRead || isReadOnly}
                                  title="Add auth key"
                                  onClick={() => saveAddAuthKey(selectedAuthKeyId)}
                                />
                              </div>
                            </>
                          ) : null}
                        </section>
                      </li>
                    ) : null}
                  </ul>
                </div>
              </div>
            )}
            {subroute === SUBROUTING.HISTORY && (
              <div className={shared.sidepanelTable}>
                <div className={shared.sidepanelTableHeading}>
                  <div className={shared.lineHeader}>
                    <div>Version</div>
                    <div>Status</div>
                    <div>Time</div>
                  </div>
                </div>
                <div className={shared.sidepanelTableBodyContainer}>
                  <ul className={shared.sidepanelTableBody}>
                    {historyList.parents?.map(({ data, number, created, author, id }, idx, arr) => {
                      const isLastVersion = arr.length - idx === 1;
                      const isLatestVersion = idx === 0;
                      return (
                        <li key={data.id + idx} ref={openedHistory?.[id] === author ? outsideRef : { current: null }}>
                          <div
                            className={cn(shared.historyLine, {
                              [shared.activeVersion]: openedHistory?.[id] === author,
                            })}
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              if (!areHandlersBlocked) {
                                handlerCheckDetails(id, author, id);
                              }
                            }}
                            onKeyDown={() => !areHandlersBlocked && handlerCheckDetails(id, author, id)}
                            role="button"
                            tabIndex={0}
                          >
                            <div title={data.name}>
                              <span className={cn({ [shared.bold]: openedHistory?.[id] === author })}>
                                {isLatestVersion ? 'Latest' : `Version ${arr.length - idx}`}
                              </span>
                            </div>
                            <div>
                              <ChipsStatus title={capitalize(data.status)} type={ChipsMap[data.status] || 'status'} />
                            </div>
                            <div>{formatISODate(new Date(created))}</div>
                            <div
                              className={cn({
                                [shared.arrowUp]: openedHistory?.[id] === author,
                                [shared.arrowDown]: openedHistory?.[id] !== author,
                              })}
                            >
                              <ArrowIcon />
                            </div>
                          </div>
                          {openedHistory?.[id] === author && (
                            <div className={shared.commitSection}>
                              {!isLatestVersion && (
                                <div className={shared.commitActiveBtn}>
                                  <Button
                                    style={{ width: 366 }}
                                    title="Activate this version"
                                    onClick={() => onOpenWarningModal(ConfigsEnum.ACTIVATION, arr.length - idx, id)}
                                  />
                                </div>
                              )}
                              {historyList.children
                                ?.filter((historyItem) => historyItem.father === id)
                                .map((commit, index, arr) => {
                                  const shouldIgnoreDiffRequest = isLastVersion && commit.id === arr[arr.length - 1].id;
                                  return (
                                    <li
                                      key={commit.id}
                                      onClick={() => handleShowCommit(commit.id, shouldIgnoreDiffRequest)}
                                    >
                                      <div
                                        className={cn(shared.historyLine, {
                                          [shared.activeCommit]: commit.id === commitId,
                                        })}
                                      >
                                        <div>{commit?.userName || ''}</div>
                                        <div>
                                          <ChipsStatus title="Change" type="user" />
                                        </div>
                                        <div>{formatISODate(new Date(commit.created))}</div>
                                        <div style={{ cursor: 'pointer' }}>
                                          {commit.id === commitId ? <BlueInfoIcon /> : <InfoIcon />}
                                        </div>
                                      </div>
                                      {commit.id === commitId && commit.diff && <CommitDiff data={commit.diff} />}
                                      {arr[arr.length - 1].id !== commit.id && <hr />}
                                    </li>
                                  );
                                })}
                            </div>
                          )}
                          <hr />
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            )}
            {subroute === SUBROUTING.LIMITS && (
              <div className={shared.sidepanelForm}>
                <div className={cn(shared.limitSection)}>
                  <div style={{ color: "#93939F", borderBottom: "1px solid #E8E9ED", paddingBottom: 10 }}> 
                    <span>API Limits</span>
                  </div>
                  <div className={shared.inputWrapper}>
                    <span className={cn(shared.inputLabel, shared.spanWrapper)}>Requests / second (RPS)</span>
                    <Input
                      maxLength={15}
                      placeholder={placeholders.RPS}
                      handleChange={handleChangeNumber}
                      value={values.RPS}
                      onBlur={handleBlurLimits}
                      name="RPS"
                      id="RPS"
                      error={!!errors.RPS}
                      disabled={viewRead || isReadOnly}
                    />
                    {(!!errors.RPS) ? (
                      <span className={shared.errorPlaceholder}>{errors.RPS}</span>
                    ) : null}
                  </div>
                  <div className={shared.inputWrapper}>
                      <span className={cn(
                        shared.inputLabel, shared.spanWrapper
                        )}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          flexDirection: 'row',
                          whiteSpace: 'nowrap',
                          overflow: 'visible',
                        }}
                        >
                          <span style={{ 
                            display: 'flex',
                            alignItems: 'center'
                          }}>
                          <span>Requests / month (RPM)</span>
                          {values.activeRpmType === 'cmon' ? (
                            <ActionText
                              title={
                                <ActionIcon
                                  id="rpmonth_tooltip1-cmon"
                                  icon="info"
                                  style={{ marginLeft: '8px' }}
                                  tooltip={{
                                    children: (
                                      <div style={{ textAlign: 'left' }}>
                                        The limit is checked by counting requests <br />
                                        starting from the <span style={{ fontWeight: 600 }}>first day of the month</span>. <br />
                                        <span style={{ fontStyle: 'italic'}}>
                                        Example: <br />
                                        Today is May 10th, 09:30 AM. < br />
                                        When a new request is received, requests will <br />
                                        be counted in the period from May 1, 12:00 AM <br />
                                        to the current moment.
                                        </span>

                                      </div>
                                    ),
                                    place: 'top',
                                    openOnClick: false,
                                    clickable: false,
                                  }}
                                />
                              }
                          />
                         ) : 
                         (
                          <ActionText
                          title={
                            <ActionIcon
                              id="rpmonth_tooltip1-mon"
                              icon="info"
                              style={{ marginLeft: '8px' }}
                              tooltip={{
                                children: (
                                  <div style={{ textAlign: 'left' }}>
                                    The limit is checked by counting requests <br/>
                                    over the <span style={{ fontWeight: 600}}>last 30 days</span>. <br />
                                    <span style={{ fontStyle: 'italic'}}>
                                    Example: <br />
                                    Today is May 10th, 09:30 AM. < br />
                                    When a new request is received, requests will <br />
                                    
                                     be counted in the period from April 10, 09:30 AM <br />
                                     to the current moment.
                                    </span>
                                  </div>
                                ),
                                place: 'top',
                                openOnClick: false,
                                clickable: false,
                              }}
                            />
                          }
                      />
                         )}
                          </span>
                          <span>
                            <Select
                            type="textColor"
                            list={[
                              { value: 'mon', title: 'Sliding window' },
                              { value: 'cmon', title: 'Calendar' },
                            ]}
                            value={values.activeRpmType}
                            handleChange={(key, value) => {
                              setFieldValueSkipCheck(key, value);
                            }}
                            name="activeRpmType"
                            disabled={viewRead || isReadOnly}
                          />
                          </span>
                      </span>
                      {values.activeRpmType === 'mon' ? (
                        <>
                          <Input
                            maxLength={15}
                            placeholder={placeholders.RPMONTH}
                            handleChange={handleChangeNumber}
                            onBlur={handleBlurLimits}
                            value={values.RPMONTH}
                            name="RPMONTH"
                            disabled={viewRead || isReadOnly}
                            error={!!errors.RPMONTH}
                          />
                          {(!!errors.RPMONTH) ? (
                            <span className={shared.errorPlaceholder}>{errors.RPMONTH}</span>
                          ) : null}
                        </>
                      ) : (
                        <>
                        <Input
                          maxLength={15}
                          placeholder={placeholders.RPMONTHCALENDAR}
                          handleChange={handleChangeNumber}
                          onBlur={handleBlurLimits}
                          value={values.RPMONTHCALENDAR}
                          name="RPMONTHCALENDAR"
                          disabled={viewRead || isReadOnly}
                          error={!!errors.RPMONTHCALENDAR}
                        />
                        {(!!errors.RPMONTHCALENDAR) ? (
                            <span className={shared.errorPlaceholder}>{errors.RPMONTHCALENDAR}</span>
                          ) : null}
                        </>
                      )}
                  </div>
                  {authListData?.length ? (
                    <div style={{ color: "#93939F", borderBottom: "1px solid #E8E9ED", paddingBottom: 10  }}>
                      <span>API Limits per Auth Key </span>
                    </div>
                  ) : null}
                  <div style={{ display: 'flex', flexDirection: 'column', gap: '20px'}}>
                {authListData?.length ? authListData.map((model) => (
                        <Collapsible 
                          key={model.id}
                          type="withIcon"
                          id={model.id} 
                          title={model.name}
                          iconLink={`${activeProject?.shortName}/auth-keys/${model.id}`}
                          onClick={() => { isLimitsTriggeredRef.current = true; }}
                        >
                  <div className={shared.inputWrapper}>
                    <span className={cn(shared.inputLabel, shared.spanWrapper)}>Requests / second (RPS)</span>
                    <Input
                      maxLength={15}
                      placeholder={placeholders[`RPS-${model.id}`] || 'Unlimited'}
                      handleChange={handleChangeNumber}
                      onBlur={handleBlurLimits}
                      value={values[`RPS-${model.id}`]}
                      name={`RPS-${model.id}`}
                      id={`RPS-${model.id}`}
                      disabled={viewRead || isReadOnly}
                      error={!!errors[`RPS-${model.id}`]}
                    />
                          {(!!errors[`RPS-${model.id}`]) ? (
                            <span className={shared.errorPlaceholder}>{errors[`RPS-${model.id}`]}</span>
                          ) : null}
                  </div>

                  <div className={shared.inputWrapper}>
                      <span className={cn(
                        shared.inputLabel, shared.spanWrapper
                        )}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          flexDirection: 'row',
                          whiteSpace: 'nowrap',
                          overflow: 'visible',
                        }}
                        >
                          <span style={{ 
                            display: 'flex',
                            alignItems: 'center'
                          }}>
                          <span>Requests / month (RPM)</span>
                          {values[`activeRpmType-${model.id}`] === 'cmon' ? (
                            <ActionText
                              title={
                                <ActionIcon
                                  id={`rpmonth_tooltip1-cmon-${model.id}`}
                                  icon="info"
                                  style={{ marginLeft: '8px' }}
                                  tooltip={{
                                    children: (
                                      <div style={{ textAlign: 'left' }}>
                                        The limit is checked by counting requests <br />
                                        starting from the <span style={{ fontWeight: 600 }}>first day of the month</span>. <br />
                                        <span style={{ fontStyle: 'italic'}}>
                                        Example: <br />
                                        Today is May 10th, 09:30 AM. < br />
                                        When a new request is received, requests will <br />
                                        be counted in the period from May 1, 12:00 AM <br />
                                        to the current moment.
                                        </span>

                                      </div>
                                    ),
                                    place: 'top',
                                    openOnClick: false,
                                    clickable: false,
                                  }}
                                />
                              }
                          />
                         ) : 
                         (
                          <ActionText
                          title={
                            <ActionIcon
                              id={`rpmonth_tooltip1-mon-${model.id}`}
                              icon="info"
                              style={{ marginLeft: '8px' }}
                              tooltip={{
                                children: (
                                  <div style={{ textAlign: 'left' }}>
                                    The limit is checked by counting requests <br/>
                                    over the <span style={{ fontWeight: 600}}>last 30 days</span>. <br />
                                    <span style={{ fontStyle: 'italic'}}>
                                    Example: <br />
                                    Today is May 10th, 09:30 AM. < br />
                                    When a new request is received, requests will <br />
                                    
                                     be counted in the period from April 10, 09:30 AM <br />
                                     to the current moment.
                                    </span>
                                  </div>
                                ),
                                place: 'top',
                                openOnClick: false,
                                clickable: false,
                              }}
                            />
                          }
                      />
                         )}
                          </span>
                          <span>
                            <Select
                            type="textColor"
                            list={[
                              { value: 'mon', title: 'Sliding window' },
                              { value: 'cmon', title: 'Calendar' },
                            ]}
                            value={values[`activeRpmType-${model.id}`]}
                            handleChange={(key, value) => {
                              setFieldValueSkipCheck(key, value);
                            }}
                            name={`activeRpmType-${model.id}`}
                          />
                          </span>
                      </span>
                      {values[`activeRpmType-${model.id}`] === 'mon' ? (
                        <>
                        <Input
                          maxLength={15}
                          placeholder={placeholders[`RPMONTH-${model.id}`] || 'Unlimited'}
                          handleChange={handleChangeNumber}
                          onBlur={handleBlurLimits}
                          value={values[`RPMONTH-${model.id}`]}
                          name={`RPMONTH-${model.id}`}
                          disabled={viewRead || isReadOnly}
                          error={!!errors[`RPMONTH-${model.id}`]}
                        />
                          {(!!errors[`RPMONTH-${model.id}`]) ? (
                            <span className={shared.errorPlaceholder}>{errors[`RPMONTH-${model.id}`]}</span>
                          ) : null}
                        </>

                      ) : (
                        <>
                        <Input
                          maxLength={15}
                          placeholder={placeholders[`RPMONTHCALENDAR-${model.id}`] || 'Unlimited'}
                          handleChange={handleChangeNumber}
                          onBlur={handleBlurLimits}
                          value={values[`RPMONTHCALENDAR-${model.id}`]}
                          name={`RPMONTHCALENDAR-${model.id}`}
                          disabled={viewRead || isReadOnly}
                          error={!!errors[`RPMONTHCALENDAR-${model.id}`]}
                        />
                          {(!!errors[`RPMONTHCALENDAR-${model.id}`]) ? (
                            <span className={shared.errorPlaceholder}>{errors[`RPMONTHCALENDAR-${model.id}`]}</span>
                          ) : null}
                        </>
                      )}
                  </div>
                        </Collapsible>
                      )) : null}
                  </div>
                </div>
              </div>
            )}
            {subroute === SUBROUTING.ACCESS && (
              <div className={shared.sidepanelTable}>
                <div className={shared.sidepanelTableHeading}>
                  <div className={shared.lineHeader}>
                    <div>Name</div>
                    <div />
                    <div>Role</div>
                  </div>
                </div>
                <div className={shared.sidepanelTableBodyContainer}>
                  <ul className={shared.sidepanelTableBody}>
                    {inviteList?.map((item) => (
                      <li className={shared.historyLine} key={item.email}>
                        <div title={item.email}>
                          {item.email === currentUser.email ? (
                            <span>{`${item.firstName} ${item.lastName}`}</span>
                          ) : item.firstName || item.lastName ? (
                            <span>{`${item.firstName} ${item.lastName} (${item.email})`}</span>
                          ) : (
                            <span>{item.email}</span>
                          )}
                        </div>
                        <div />
                        <div>
                          {viewRead ? (
                            <ChipsStatus
                              title={listRolesName[item.role] || item.role}
                              type="input"
                              className={shared.chipsStatus}
                              style={{ width: '90px', justifyContent: 'left', paddingLeft: '12px' }}
                            />
                          ) : item.role === 'owner' ? (
                            <ChipsStatus
                              title="Owner"
                              type="input"
                              className={shared.chipsStatus}
                              style={{ width: '90px', justifyContent: 'left', paddingLeft: '12px' }}
                            />
                          ) : (
                            <Select
                              style={{ width: 90 }}
                              list={getRoles(item.role)}
                              value={item.role}
                              placeholder={item.role}
                              handleChange={(email, value) => handeChangeRole(item, value)}
                              name={item.email}
                            />
                          )}
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
          </div>
        </div>
        {shareModalOpened && (
          <InviteToResourceModal
            id={endpointId}
            onClose={() => setShareModalOpened(false)}
            onSubmit={saveInviteList}
            model={values}
            type="endpoint"
            inviteList={inviteList?.map((i) => i?.userEmail || i?.email) || []}
          />
        )}
        <Modal isOpen={createVersionModalOpened} onClose={toggleCreateVersionModal}>
          <WarningModal
            onCancel={toggleCreateVersionModal}
            onSave={getOnSaveWarningCallback()}
            headerText={WarningsConfigsMap[warningConfig].headerText}
            mainText={
              typeof WarningsConfigsMap[warningConfig].mainText === 'function'
                ? WarningsConfigsMap[warningConfig].mainText(
                    warningConfig === ConfigsEnum.CREATE ? historyList.parents?.length + 1 : versionToActivate,
                  )
                : WarningsConfigsMap[warningConfig].mainText
            }
            mainTextSub={WarningsConfigsMap[warningConfig].mainTextSub}
            closeBtnText={WarningsConfigsMap[warningConfig].closeBtnText}
            saveBtnText={
              typeof WarningsConfigsMap[warningConfig].saveBtnText === 'function'
                ? WarningsConfigsMap[warningConfig].saveBtnText(
                    warningConfig === ConfigsEnum.CREATE ? historyList.parents?.length + 1 : versionToActivate,
                  )
                : WarningsConfigsMap[warningConfig].saveBtnText
            }
          />
        </Modal>
        {removeModalOpened && (
          <RemoveAccessModal
            setIsOpen={setRemoveModalOpened}
            deleteAccessUser={deleteAccess}
            user={activeUser}
            resource="endpoint"
            id={endpointId}
          />
        )}
      </div>
    </div>
  );
}

const mapStateToProps = ({
  activeEndpoint: { activeEndpoint, inviteList, authKeysList, authKeysLimits },
  connections,
  activeProject,
  auth: { currentUser },
  directory,
  roles,
  history: { historyList },
  authKeys,
}) => ({
  roles,
  connectionTypes: directory?.connectionTypes,
  connections,
  activeProject,
  currentUser,
  activeEndpoint,
  connectLimit: directory?.connectLimit || 100,
  authorizationMethods: directory?.authorizationMethods,
  historyList,
  storedInviteList: inviteList,
  storedAuthKeysList: authKeysList,
  storedAuthKeysLimits: authKeysLimits,
  authKeys,
});

const mapDispatchToProps = (dispatch) => ({
  updateEndpoint: (data, callback) => dispatch(updateEndpointRequest(data, callback)),
  testEndpoint: (data, callback) => dispatch(testEndpointRequest(data, callback)),
  getConnections: (data) => dispatch(listConnectionsRequest(data)),
  getActiveEndpoint: (data, callback) => dispatch(activeEndpointRequest(data, callback)),
  createToast: (data, callback) => dispatch(createToastRequest(data, callback)),
  getAccessUsers: (data) => dispatch(getAccessUsersRequest(data)),
  addAccess: (data, callback) => dispatch(addAccessRequest(data, callback)),
  gptSendRequest: (data, callback) => dispatch(gptRequestData(data, callback)),
  getHistoryData: (data, callback) => dispatch(historyRequest(data, callback)),
  getUserData: (data, callback) => dispatch(userRequest(data, callback)),
  diffVersion: (data, callback) => dispatch(diffVersionRequest(data, callback)),
  createVersion: (data, callback) => dispatch(createVersionRequest(data, callback)),
  displayVersion: (data) => dispatch(displayVersion(data)),
  activateVersion: (data, callback) => dispatch(overrideVersionRequest(data, callback)),
  activateOriginVersion: () => dispatch(activateOriginVersion()),
  getAuthKeys: (data) => dispatch(listAuthKeysRequest(data)),
  getConnectedAuthKeys: (data, callback) => dispatch(getEndpointConnectedAuthKeysRequest(data, callback)),
  getConnectedLimits: (data, callback) => dispatch(getEndpointConnectedAuthKeysLimitRequest(data, callback)),
  setConnectedLimits: (data, callback) => dispatch(modifyEndpointConnectedAuthKeysLimitRequest(data, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(isRoleAction(ApisModify));

const FtpTypesEnum = {
  search: 'search',
  getList: 'getList',
  getTree: 'getTree',
  update: 'update',
  rename: 'rename',
  upload: 'upload',
  delete: 'delete'
};

const FtpTitlesEnum = {
  [FtpTypesEnum.search]: 'Search',
  [FtpTypesEnum.getList]: 'Get list',
  [FtpTypesEnum.getTree]: 'Get tree',
  [FtpTypesEnum.update]: 'Update',
  [FtpTypesEnum.rename]: 'Rename',
  [FtpTypesEnum.upload]: 'Upload',
  [FtpTypesEnum.delete]: 'Delete',
};

export const FtpMethodTypeMap = {
  get: FtpTypesEnum.GetFilesAndFolders,
  post: FtpTypesEnum.UploadFile,
  delete: FtpTypesEnum.DeleteFile,
  put: FtpTypesEnum.Rename,
  patch: FtpTypesEnum.FindFilesFolders,
};

export const getCorrespondingMethod = (ftpType) => {
  switch (ftpType) {
    case FtpTypesEnum.search:
    case FtpTypesEnum.getList:
    case FtpTypesEnum.getTree:
      return 'get';
    case FtpTypesEnum.rename:
      return 'put';
    case FtpTypesEnum.upload:
    case FtpTypesEnum.update:
      return 'post';
    case FtpTypesEnum.delete:
      return 'delete';
    default:
      return null;
  }
};

export const ftpTypesList = [
  { value: FtpTypesEnum.search, title: FtpTitlesEnum[FtpTypesEnum.search] },
  { value: FtpTypesEnum.getList, title: FtpTitlesEnum[FtpTypesEnum.getList] },
  { value: FtpTypesEnum.getTree, title: FtpTitlesEnum[FtpTypesEnum.getTree] },
  { value: FtpTypesEnum.update, title: FtpTitlesEnum[FtpTypesEnum.update] },
  { value: FtpTypesEnum.rename, title: FtpTitlesEnum[FtpTypesEnum.rename] },
  { value: FtpTypesEnum.upload, title: FtpTitlesEnum[FtpTypesEnum.upload] },
  { value: FtpTypesEnum.delete, title: FtpTitlesEnum[FtpTypesEnum.delete] },
];

import React from 'react';
import { NavLink } from 'react-router-dom';
import * as style from './NewProjectWelcome.module.scss';
import Sidepanel from '../../containers/Sidepanel/Sidepanel';

function NewProjectWelcome() {
  return (
    <div className={style.main}>
      <Sidepanel />
      <div className={style.container}>
        <div className={style.reason}>You have no projects yet</div>
        <div className={style.advice}>Please create your first project to continue.</div>
        <NavLink to="/projects/create" className={style.link} end>
          Click here to create project
        </NavLink>
      </div>
    </div>
  );
}

export default NewProjectWelcome;

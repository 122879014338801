@use "sass:map";
@import '../../styles/variables.scss';

.wrapCollabsible {
    margin-bottom: 1.2rem 0;
  }
  
  input[type='checkbox'] {
    display: none;
  }
  
  .lblToggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0px;
    text-align: left;

    &.lblWithIcon {
      font-weight: 400;

      > span {
        cursor: pointer;
      }
    }

    .hoverIcon {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &:hover {
        svg * {
          stroke: map.get($colors-basic, "primaryBlue");
        }
      }

    }

    &:not(.lblWithIcon) {
      cursor: pointer;
    }

    transition: all 0.25s ease-out;

    .arrow {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
  
      svg {
        transform: rotate(-90deg);
        transition: all .25s ease-in-out;
      }
    }
  }
  
  .toggle:checked + .lblToggle {
    &.lblWithIcon {
      font-weight: 600;
    }
    svg {
      transform: rotate(0);
      transition: all .25s ease-in-out;
    }
  } 
  
  .collapsibleContent {
    max-height: 0px;
    overflow: hidden;
    transition: max-height .25s ease-in-out;
  }
  
  .toggle:checked + .lblToggle + .collapsibleContent {
    max-height: 100vh;
    overflow: visible;
  }
  
  .toggle:checked + .lblToggle {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  
  .collapsibleContent .contentInner {
    margin-top: 20px;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
  }

  .withIconContent {
    border-bottom: 1px solid #E8E9ED;
    padding-bottom: 20px;
  }
/* eslint-disable max-len */
import { ActiveVariable } from './constants';

export const activeVariableRequest = (data) => ({ type: ActiveVariable.activeVariableRequest, data });
export const activeVariableSuccess = (data) => ({ type: ActiveVariable.activeVariableSuccess, data });
export const activeVariableError = () => ({ type: ActiveVariable.activeVariableError });

export const getAccessUsersVariableSuccess = (data) => ({ type: ActiveVariable.getAccessUsersVariableSuccess, data });

export const getConnectedModelsToVariablesRequest = (data) => ({ type: ActiveVariable.getConnectedModelsToVariablesRequest, data });
export const getConnectedModelsToVariablesSuccess = (data) => ({ type: ActiveVariable.getConnectedModelsToVariablesSuccess, data });

/* eslint-disable max-len */
import { ActiveAuthKey } from './constants';

export const activeAuthKeyRequest = (data) => ({ type: ActiveAuthKey.activeAuthKeyRequest, data });
export const activeAuthKeySuccess = (data) => ({ type: ActiveAuthKey.activeAuthKeySuccess, data });
export const activeAuthKeyError = () => ({ type: ActiveAuthKey.activeAuthKeyError });

export const getAccessUsersAuthKeySuccess = (data) => ({ type: ActiveAuthKey.getAccessUsersAuthKeySuccess, data });

export const getConnectedEndpointsToAuthKeysRequest = (data) => ({ type: ActiveAuthKey.getConnectedEndpointsToAuthKeysRequest, data });
export const getConnectedEndpointsToAuthKeysSuccess = (data) => ({ type: ActiveAuthKey.getConnectedEndpointsToAuthKeysSuccess, data });

export const TypesMap = {
  Apis: 'apis',
  Connections: 'connections',
  Keys: 'keys',
  Variables: 'variables',
};

export const SingularVariableMap = {
  toBe: 'was',
  title: 'Variable',
  have: 'has',
  conflict: 'conflict',
};

export const PluralVariableMap = {
  toBe: 'were',
  title: 'Variables',
  have: 'have',
  conflict: 'conflicts',
};

export const SingularApiMap = {
  toBe: 'was',
  title: 'API',
  have: 'has',
  conflict: 'conflict',
};

export const PluralApiMap = {
  toBe: 'were',
  title: 'APIs',
  have: 'have',
  conflict: 'conflicts',
};

export const SingularKeyMap = {
  toBe: 'was',
  title: 'Auth Key',
  have: 'has',
  conflict: 'conflict',
};

export const PluralKeyMap = {
  toBe: 'were',
  title: 'Auth Keys',
  have: 'have',
  conflict: 'conflicts',
};

export const SingularConnectionsMap = {
  toBe: 'was',
  title: 'Connection',
  have: 'has',
  conflict: 'conflict',
};

export const PluralConnectionsMap = {
  toBe: 'were',
  title: 'Connections',
  have: 'have',
  conflict: 'conflicts',
};

export const KindBasedMap = {
  [TypesMap.Apis]: {
    singular: SingularApiMap,
    plural: PluralApiMap,
  },
  [TypesMap.Connections]: {
    singular: SingularConnectionsMap,
    plural: PluralConnectionsMap,
  },
  [TypesMap.Keys]: {
    singular: SingularKeyMap,
    plural: PluralKeyMap,
  },
  [TypesMap.Variables]: {
    singular: SingularVariableMap,
    plural: PluralVariableMap,
  },
};

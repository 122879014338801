/* eslint-disable import/prefer-default-export */
export const Variables = {
  listVariablesRequest: 'LIST_VARIABLES_PENDING',
  listVariablesSuccess: 'LIST_VARIABLES_SUCCESS',
  listVariablesError: 'LIST_VARIABLES_ERROR',

  searchVariablesRequest: 'SEARCH_VARIABLES_PENDING',
  searchVariablesSuccess: 'SEARCH_VARIABLES_SUCCESS',
  searchVariablesError: 'SEARCH_VARIABLES_ERROR',

  createVariableRequest: 'CREATE_VARIABLE_PENDING',
  createVariableSuccess: 'CREATE_VARIABLE_SUCCESS',
  createVariableError: 'CREATE_VARIABLE_ERROR',

  removeVariablesListRequest: 'REMOVE_VARIABLES_LIST_PENDING',
  removeVariablesListSuccess: 'REMOVE_VARIABLES_LIST_SUCCESS',
  removeVariablesListError: 'REMOVE_VARIABLES_LIST_ERROR',

  updateVariableRequest: 'UPDATE_VARIABLE_PENDING',
  updateVariableSuccess: 'UPDATE_VARIABLE_SUCCESS',
  updateVariableError: 'UPDATE_VARIABLE_ERROR',

  copyVariableRequest: 'COPY_VARIABLE_PENDING',
  copyVariableSuccess: 'COPY_VARIABLE_SUCCESS',
  copyVariableError: 'COPY_VARIABLE_ERROR',

  exportVariableRequest: 'EXPORT_VARIABLE_PENDING',
  exportVariableSuccess: 'EXPORT_VARIABLE_SUCCESS',
  exportVariableError: 'EXPORT_VARIABLE_ERROR',

  importVariableRequest: 'IMPORT_VARIABLE_PENDING',
  importVariableSuccess: 'IMPORT_VARIABLE_SUCCESS',
  importVariableError: 'IMPORT_VARIABLE_ERROR',

  getVariableFolderContentRequest: 'GET_VARIABLE_FOLDER_CONTENT_REQUEST',
  getVariableFolderContentSuccess: 'GET_VARIABLE_FOLDER_CONTENT_SUCCESS',
};

/* eslint-disable max-len */
/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Checkbox from '../../../../components/Checkbox/Checkbox';
import ActionIcon from '../../../../components/ActionIcon/ActionIcon';
import ChipsStatus from '../../../../components/ChipsStatus/ChipsStatus';
import {
  formatTimestamp,
  getVariableModelValue
} from '../../../../helpers/utils';
// import * as styles from './Directory.module.scss';



import * as shared from '../../../../styles/shared.module.scss';
import { ContextMenu, contextConfig, prepareConfig } from '../../../../components/ContextMenu';

function Directory({
  authKeysData,
  selectedItems,
  handleContextMenu,
  setContextMenuModelActive,
  contextMenuModelActive,
  contextMenuTop,
  contextMenuLeft,
  modifyAuthKey,
  selectOne,
  clickShare,
  isRoleAction,
  exportOne,
  removeOne,
  copyCon,
  clipboardCopy,
  depth = 0,
}) {
  const [sortBy, setSortBy] = useState('name');

  const sortAuthKeys = (authKeysList) => authKeysList;

  const blockEvent = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  return (
    <>
      {sortAuthKeys(authKeysData).map((model, index) => (
        <React.Fragment key={`variable_${model.id}`}>
          <tr
            style={{ position: 'relative' }}
            className={cn(
              shared.keysTableRowKeys,
              (selectedItems.includes(model.id) && shared.selected),
            )}
            role="button"
            tabIndex={0}
            onContextMenu={(e) => handleContextMenu(e, model.id, false)}
            onDoubleClick={() => modifyAuthKey(model.id)}
            onClick={() => setContextMenuModelActive(false)}
          >
            <td>
              <Checkbox
                handleChange={(isCheked) => selectOne(isCheked, model.id)}
                value={selectedItems.includes(model.id)}
                size="small"
                style={{ marginRight: '0' }}
              />
            </td>
            <td style={{ marginLeft: `calc(${depth + 1} * 32px)`, gap: '8px' }}>
              <span>
                {model.name}
              </span>
              {model.description
            && (
            <div className={shared.controlIcons}>
              <ActionIcon
                id={`${index}_${model.id}_tooltip`}
                icon="info"
                style={{ marginRight: '8px' }}
                onClick={() => {}}
                tooltip={{
                  content: model.description,
                  place: 'right',
                }}
              />
            </div>
            )}
            </td>
            <td>
              <span>{getVariableModelValue(model)}</span>
              <div className={shared.controlIcons}>
                <ActionIcon
                  icon="copy"
                  style={{ marginRight: '8px' }}
                  onClick={() => {
                    clipboardCopy(getVariableModelValue(model))
                  }}
                />
              </div>
            </td>
            <td>
              <span>{model.owner ? `${model.owner.firstName} ${model.owner.lastName}` : ''}</span>
            </td>
            <td style={{ overflow: 'visible' }}><ChipsStatus title="Active" type="success" /></td>
            <td style={{ minWith: '400px' }}>
              <div className={shared.controlIcons} onDoubleClick={blockEvent}>
                <ActionIcon
                  id={`${index}_${model.id}_edit`}
                  icon="edit"
                  onClick={() => modifyAuthKey(model.id)}
                  hidden={!isRoleAction({ key: 'variable', role: 'update', modelRole: model.role })}
                  tooltip={{
                    content: 'Edit',
                  }}
                />
                <ActionIcon
                  id={`${index}_${model.id}_copy`}
                  icon="copy"
                  onClick={() => copyCon(model)}
                  hidden={!isRoleAction({ key: 'variable', role: 'create', modelRole: model.role })}
                  tooltip={{
                    content: 'Copy',
                  }}
                />
                <ActionIcon
                  id={`${index}_${model.id}_addUser`}
                  icon="addUser"
                  onClick={() => clickShare(model)}
                  hidden={!isRoleAction({ key: 'variable', role: 'share', modelRole: model.role })}
                  tooltip={{
                    content: 'Share',
                  }}
                />
                <ActionIcon
                  id={`${index}_${model.id}_export`}
                  icon="export"
                  hiddenFill
                  onClick={() => exportOne('variable', model)}
                  tooltip={{
                    content: 'Export',
                  }}
                />
                <ActionIcon
                  id={`${index}_${model.id}_delete`}
                  icon="trash"
                  onClick={() => removeOne('variable', model)}
                  hidden={!isRoleAction({ key: 'variable', role: 'delete', modelRole: model.role })}
                  tooltip={{
                    content: 'Delete',
                  }}
                />
              </div>
              <div className={shared.controlIconsHideList}>
                <span>{model.createdAt ? formatTimestamp(model.createdAt) : ''}</span>
              </div>
            </td>
          </tr>
          <ContextMenu
            contextConfig={prepareConfig(contextConfig, {
              edit: () => modifyAuthKey(model.id),
              copy: () => copyCon(model),
              share: () => clickShare(model),
              export: () => exportOne('variable', model),
              delete: () => removeOne('variable', model),
            }, 'itemSupport')}
            contextMenuTop={contextMenuTop}
            contextMenuLeft={contextMenuLeft}
            isOpen={contextMenuModelActive === model.id}
          />
        </React.Fragment>
      ))}
    </>
  );
}

Directory.propTypes = {
  selectedItems: PropTypes.array,
  authKeysData: PropTypes.array,
};

export default Directory;

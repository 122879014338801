/* eslint-disable react/jsx-no-useless-fragment */
import React, { useEffect, useMemo } from 'react';
import { ThreeDots } from 'react-loader-spinner';
import { connect } from 'react-redux';
import { useParams, Navigate, useLocation } from 'react-router-dom';
import { createToastRequest } from '../../store/toasts/actions';
import { meRequest, meInProjectRequest } from '../../store/auth/actions';
import { listProjectsRequest } from '../../store/projects/actions';
import { listDirectoryRequest } from '../../store/directory/actions';
import { listRolesRequest } from '../../store/roles/actions';

import * as style from './ProtectedRoute.module.scss';

function Protected({
  currentUser, projects, children, getProjects,
  getMe, getDirectory, meInProject, getRoles,
  createToast, directory
}) {
  const { shortName } = useParams();
  const { pathname } = useLocation();
  const currentActive = useMemo(
    () => projects?.some((p) => p.shortName === shortName),
    [projects, shortName],
  );

  useEffect(() => {
    getProjects({ shortName }, (list) => {
        getMe(null, (user) => {
          const activeProject = list?.find((el) => el.shortName === shortName);
          if (!activeProject?.id) return;
          if (!user?.id) return;
    
          getDirectory({ projectId: activeProject.id });
          getRoles({ id: activeProject.id });
          meInProject({
            id: user.id,
            projectId: activeProject.id,
          });
        });
    });
  }, [shortName]);

  if (projects && !projects?.length && pathname !== '/projects/create') {
    return <Navigate to="/new-project" replace />;
  }

  if (projects && projects?.length && (!shortName || !currentActive)) {
    if (shortName) {
      createToast({
        type: 'error',
        text: `You do not have access to the project <strong style="font-weight: 600;">${shortName}</strong>, redirecting to <strong style="font-weight: 600;">${projects[0].shortName}</strong>`,
      });
    }
    return <Navigate to={`/${projects[0].shortName}/endpoints`} replace />;
  }

  return (
    <>
      {currentUser && projects && projects.length && directory ? (
        children
      ) : (
        <div className={style.loaderWrapper}>
          <ThreeDots
            height="100"
            width="100"
            radius="8"
            color="#4149F7"
            ariaLabel="three-dots-loading"
            visible
          />
        </div>
      )}
    </>
  );
}

const mapStateToProps = ({ auth: { currentUser }, projects, directory }) => ({
  currentUser,
  projects,
  directory
});

const mapDispatchToProps = (dispatch) => ({
  getProjects: (data, callback) => dispatch(listProjectsRequest(data, callback)),
  getRoles: (data) => dispatch(listRolesRequest(data)),
  getMe: (data, callback) => dispatch(meRequest(data, callback)),
  getDirectory: (data) => dispatch(listDirectoryRequest(data)),
  meInProject: (id) => dispatch(meInProjectRequest(id)),
  createToast: (data) => dispatch(createToastRequest(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Protected);

/* eslint-disable no-extra-boolean-cast */
/* eslint-disable max-len */
import React, { useEffect, useState, memo } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import debounce from 'lodash.debounce';
import * as Yup from 'yup';
import cn from 'classnames';
import { DndProvider } from 'react-dnd';
import { uid } from 'uid';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useCopyToClipboard } from '../../hooks/hooks';
import Button from '../../components/Button/Button';
import CodeEditor from '../../components/CodeEditor';
import RequestParameterRows from '../../components/RequestParameterRows';
import ActionText from '../../components/ActionText/ActionText';
import ActionIcon from '../../components/ActionIcon/ActionIcon';
import ChipsStatus from '../../components/ChipsStatus/ChipsStatus';
import ValidationErrorList from '../../components/ValidationErrorList/ValidationErrorList';
import Input from '../../components/Input/Input';
import Select from '../../components/Select/Select';
import RemoveAccessModal from '../../components/RemoveAccessModal/RemoveAccessModal';
import ConnectedAPI from '../../components/ConnectedAPI';
import InviteToResourceModal from '../../components/InviteToResourceModal/InviteToResourceModal';
import { getProjectRoles, calcPlaceholder, stringifyIfJSON,
  limitsInit, formatNumber, checkEnvExistense } from '../../helpers/utils';
import {
  loadStatus, typesAPI, listRolesName,
} from '../../helpers/const';
import { isRoleAction } from '../../helpers/hocs';
import { updateConnectionRequest } from '../../store/connections/actions';
import { activeConnectionRequest, testActiveConnectionRequest, getConnectedEndpointsRequest } from '../../store/activeConnection/actions';
import { getAccessUsersRequest } from '../../store/users/actions';
import { addAccessRequest } from '../../store/invites/actions';
import { createToastRequest } from '../../store/toasts/actions';
import DBIcon from '../../components/DBIcon/DBIcon';
import Checkbox from '../../components/Checkbox/Checkbox';
import Collapsible from '../../components/Collapsible';

import * as shared from '../../styles/shared.module.scss';
import { removeTrashItemsRequest, restoreTrashItemsRequest } from '../../store/trash/actions';
import { useMonaco, useMonacoErrors, useVariables } from '../../hooks/useMonaco';
import { transformValueWithVariables } from '../../helpers/tokenizeAutocompleteUtils';

const maxAllowedLength = 64;

const MemoizedCodeEditor = memo((props) => 
  // Your component logic here
   (
    <CodeEditor
      value={props.value}
      handleChange={props.handleChange}
      handleBlurCallback={props.handleBlurCallback}
      name={props.name}
      id={props.id}
      type={props.type}
      placeholder={props.placeholder}
      error={props.error}
      style={props.style}
      height={props.height}
    />
  )
);

const emptyParam = {
  name: '',
  description: '',
  value: '',
  id: uid(8),
  required: 'no',
};

const getHeaderTitleLabel = (title) => {
  if (title === 'query') return 'Query parameter name';
  if (title === 'header') return 'Request header prefix';
  if (title === 'body') return 'Request body parameter';
  return 'Request header prefix';
};

const getHeaderTitlePlaceholder = (title) => {
  if (title === 'query') return 'Enter name';
  if (title === 'header') return 'Enter prefix';
  if (title === 'body') return 'Enter parameter';
  return 'Enter prefix';
};

const addToParamApiKeys =
  [
    { value: 'header', title: 'Header' },
    { value: 'query', title: 'Query parameters' },
  ];

const addToParamJWT =
  [
    { value: 'header', title: 'Header' },
    { value: 'query', title: 'Query parameters' },
    { value: 'body', title: 'Body' },
  ];

const SUBROUTING = {
  ACCESS: 'access',
  AUTHORIZATION: 'authorization',
  HISTORY: 'history',
  LIMITS: 'limits',
};

const subrouteMapping = {
  'authorizationMethod': SUBROUTING.AUTHORIZATION,
  'authBasicLogin': SUBROUTING.AUTHORIZATION,
  'authBasicPassword': SUBROUTING.AUTHORIZATION,
  'authAPIKeyHeaderName': SUBROUTING.AUTHORIZATION,
  'authAPIKeyToken': SUBROUTING.AUTHORIZATION,
  'authAPIKeyAddTo': SUBROUTING.AUTHORIZATION,
  'authBearerToken': SUBROUTING.AUTHORIZATION,
  'authJwtType': SUBROUTING.AUTHORIZATION,
  'authJwtServerAuthUrl': SUBROUTING.AUTHORIZATION,
  'authJwtServerPayload': SUBROUTING.AUTHORIZATION,
  'authJwtClientAddTo': SUBROUTING.AUTHORIZATION,
  'authJwtClientAlgorithm': SUBROUTING.AUTHORIZATION,
  'authJwtClientPrivateKey': SUBROUTING.AUTHORIZATION,
  'authJwtClientPayload': SUBROUTING.AUTHORIZATION,
}

const connectionRequestTypeList = [
  {
    value: 'synchronous',
    title: 'Synchronous',
  },
  {
    value: 'asynchronous',
    title: 'Asynchronous',
  },
];

const ConnectionCreateSchema = (params) => Yup.object().shape({
  name: Yup.string()
    .min(2, 'Name must be at least 2 characters')
    .max(64, 'Name reached limit 64 characters')
    .matches(/^(?!\s)(?!.* {2})/, 'Name regexpr validation failed')
    .required('Name required'),
  dbType: Yup.string().required('Type required'),
  RPS: Yup.number()
  .test('rps-connect-limit', `Max. system RPS limit: ${formatNumber(params.connectLimit)}`, (value) => {
    if (value > params.connectLimit) {
      return false;
    }
    return true;
  }),
  RPMONTH: Yup.number()
      .when('activeRpmType', {
        is: 'mon',
        then: Yup.number()
          .test('rps-value-limit-month', function (value) {
            const { RPS } = this.parent;
            const calculatedLimit = RPS * 60 * 60 * 24 * 30;
            if (value > calculatedLimit) {
              return this.createError({ message: `Max. RPM are: ${formatNumber(calculatedLimit)}` });
            }
            return true;
          })
          .test('rps-connect-limit-month', `Max. system RPM are: ${formatNumber(params.connectLimit * 60 * 60 * 24 * 30)}`, (value) => {
            if (value > (params.connectLimit * 60 * 60 * 24 * 30)) {
              return false;
            } 
            return true;
          })
          ,
      }),
  RPMONTHCALENDAR: Yup.number()
    .when('activeRpmType', {
      is: 'cmon',
      then: Yup.number()
        .test('rps-value-limit-month-calendar', function (value) {
          const { RPS } = this.parent;
          const calculatedLimit = RPS * 60 * 60 * 24 * 30;
          if (value > calculatedLimit) {
            return this.createError({ message: `Max. RPM are: ${formatNumber(calculatedLimit)}` });
          }
          return true;
        })
        .test('rps-connect-limit-month-calendar', `Max. system RPM are: ${formatNumber(params.connectLimit * 60 * 60 * 24 * 30)}`, (value) => {
          if (value > (params.connectLimit * 60 * 60 * 24 * 30)) {
            return false;
          } 
          return true;
        })
        ,
    }),
  maxConnections: 
    Yup.number().when('dbType', {
        is: (dbType) => dbType && dbType !== 'corezoid' && dbType !== 'aws' && dbType !== 'chatGpt' && dbType !== 'ftp' && dbType !== 'apiCall',
        then: Yup.number()
              .required('maxConnections required')
              .typeError('maxConnections must be a number')
              .test('isInteger', 'Not integer value provided', (value) => Number.isInteger(value)),
      }),
  timeout: 
    Yup.number().when('dbType', {
        is: (dbType) => dbType && dbType !== 'corezoid' && dbType !== 'aws' && dbType !== 'chatGpt' && dbType !== 'ftp',
        then: Yup.number()
              .required('timeout required')
              .typeError('timeout must be a number')
              .test('isInteger', 'Not integer value provided', (value) => Number.isInteger(value)),
      }),
  ftpLogin: Yup.string()
    .when(['dbType', 'dbPassword'], {
      is: (dbType, dbPassword) => dbType === 'ftp' && dbPassword?.length,
      then: Yup.string().required('Username required'),
    }),
  dbUsername: Yup.string()
    .when('dbType', {
      is: (dbType) => dbType && dbType !== 'corezoid' && dbType !== 'aws' && dbType !== 'chatGpt' && dbType !== 'ftp' && dbType !== 'apiCall',
      then: Yup.string().required('User required'),
    }),
  dbPassword: Yup.string()
    .when(['dbType', 'ftpLogin'], {
      is: (dbType, ftpLogin) => (dbType && dbType !== 'corezoid' && dbType !== 'aws' && dbType !== 'chatGpt' && dbType !== 'ftp' && dbType !== 'apiCall') || (dbType === 'ftp' && ftpLogin?.length),
      then: Yup.string().required('Password required'),
    }),
  dbHost: Yup.string()
    .when('dbType', {
      is: (dbType) => dbType && dbType !== 'corezoid' && dbType !== 'aws' && dbType !== 'chatGpt' && dbType !== 'apiCall',
      then: Yup.string().required('Host required'),
    }),
  dbName: Yup.string()
    .when('dbType', {
      is: (dbType) => dbType && dbType !== 'corezoid' && dbType !== 'aws' && dbType !== 'chatGpt' && dbType !== 'ftp' && dbType !== 'apiCall',
      then: Yup.string().required('Database Name required'),
    }),
  dbPort: Yup.string()
    .when('dbType', {
      is: (dbType) => dbType !== 'corezoid' && dbType !== 'aws' && dbType !== 'chatGpt' && dbType !== 'apiCall',
      then: Yup.string().required('Port required'),
    }),
  // mssql
  encrypt: Yup.string()
    .when('dbType', {
      is: (dbType) => dbType === 'mssql',
      then: Yup.string().required('Encrypt required'),
    }),
  trustServerCertificate: Yup.string()
    .when('dbType', {
      is: (dbType) => dbType === 'mssql',
      then: Yup.string().required('Trust Server Certificate required'),
    }),
  // corezoid
  requestType: Yup.string()
    .when('dbType', {
      is: (dbType) => dbType === 'corezoid',
      then: Yup.string().required('Corezoid Request Type required'),
    }),
  corezoidApiLogin: Yup.string()
    .when(['dbType', 'requestType'], {
      is: (dbType, requestType) => dbType === 'corezoid' && requestType === 'synchronous',
      then: Yup.string().required('Corezoid Api Login required'),
    }),
  corezoidApiKey: Yup.string()
    .when(['dbType', 'requestType'], {
      is: (dbType, requestType) => dbType === 'corezoid' && requestType === 'synchronous',
      then: Yup.string().required('Corezoid Api Key required'),
    }),
  corezoidUrl: Yup.string()
    .when('dbType', {
      is: (dbType) => dbType === 'corezoid',
      then: Yup.string().required('Corezoid Sync API URL required'),
    }),
  // AWS
  accessKey: Yup.string()
    .when('dbType', {
      is: (dbType) => dbType === 'aws',
      then: Yup.string().required('aws Access Key required'),
    }),
  secretKey: Yup.string()
    .when('dbType', {
      is: (dbType) => dbType === 'aws',
      then: Yup.string().required('aws Secret Key required'),
    }),
  region: Yup.string()
    .when('dbType', {
      is: (dbType) => dbType === 'aws',
      then: Yup.string().required('Region required'),
    }),
  version: Yup.string()
    .when('dbType', {
      is: (dbType) => dbType === 'chatGpt',
      then: Yup.string().required('Version required'),
    }),
  organization: Yup.string()
    .when('dbType', {
      is: (dbType) => dbType === 'chatGpt',
      then: Yup.string().required('Organization required'),
    }),
  secret: Yup.string()
    .when('dbType', {
      is: (dbType) => dbType === 'chatGpt',
      then: Yup.string().required('Secret required'),
    }),
  maximumFileSize: Yup.number().nullable(true)
    .when('dbType', {
      is: (dbType) => dbType === 'ftp',
      then: Yup.number()
         .required('maximumFileSize required')
         .max((params.ftpFileSizeLimit) * 1000, `Maximum file size limit: ${formatNumber((params.ftpFileSizeLimit) * 1000)} KB`),
    }),
  apiUrl: Yup.string()
    .when('dbType', {
      is: (dbType) => dbType === 'apiCall',
      then: 
        Yup.string()
          .test('env-exists', 'All env variables must exist', checkEnvExistense(params.variables))
          .required('Api Url required')
          // .test('is-valid-url', 'Invalid URL', (value) => isUrlWithPlaceholder(value)),
    }),
  apiRequestMethod: Yup.string()
    .when('dbType', {
      is: (dbType) => dbType === 'apiCall',
      then: Yup.string().required('Request method required'),
    }),
  requestFormat: Yup.string()
    .when('dbType', {
      is: (dbType) => dbType === 'apiCall',
      then: Yup.string().required('Request format required'),
    }),
  authorizationMethod: Yup.string()
    .when('dbType', {
      is: (dbType) => dbType === 'apiCall',
      then: Yup.string().required('AuthMethod required'),
    }),
  authBasicLogin: Yup.string().when(['dbType', 'authorizationMethod'], {
      is: (dbType, authorizationMethod) => dbType === 'apiCall' && authorizationMethod === 'basic',
      then: Yup.string()
        .test('env-exists', 'All env variables must exist', checkEnvExistense(params.variables))
        .required('Login required'),
    }),
  authBasicPassword: Yup.string().when(['dbType', 'authorizationMethod'], {
    is: (dbType, authorizationMethod) => dbType === 'apiCall' && authorizationMethod === 'basic',
    then: Yup.string()
      .test('env-exists', 'All env variables must exist', checkEnvExistense(params.variables))
      .required('Password required'),
  }),
  authAPIKeyHeaderName: Yup.string().when(['dbType', 'authorizationMethod'], {
    is: (dbType, authorizationMethod) => dbType === 'apiCall' && authorizationMethod === 'APIKey',
    then: Yup.string()
      .test('env-exists', 'All env variables must exist', checkEnvExistense(params.variables))
      .required('HeaderName required'),
  }),
  authAPIKeyToken: Yup.string().when(['dbType', 'authorizationMethod'], {
    is: (dbType, authorizationMethod) => dbType === 'apiCall' && authorizationMethod === 'APIKey',
    then: 
     Yup.string()
     .test('env-exists', 'All env variables must exist', checkEnvExistense(params.variables))
     .required('Token required'),
  }),
  authAPIKeyAddTo: Yup.string().when(['dbType', 'authorizationMethod'], {
    is: (dbType, authorizationMethod) => dbType === 'apiCall' && authorizationMethod === 'APIKey',
    then: Yup.string().required('Add to required'),
  }),
  authBearerToken: Yup.string().when(['dbType', 'authorizationMethod'], {
    is: (dbType, authorizationMethod) => dbType === 'apiCall' && authorizationMethod === 'bearer',
    then: Yup.string()
      .test('env-exists', 'All env variables must exist', checkEnvExistense(params.variables))
      .required('Token required'),
  }),
  authJwtType: Yup.string().when(['dbType', 'authorizationMethod'], {
    is: (dbType, authorizationMethod) => dbType === 'apiCall' && authorizationMethod === 'jwt',
    then: Yup.string().required('JWT type required'),
  }),
  authJwtServerAuthUrl: Yup.string().when(['dbType', 'authorizationMethod', 'authJwtType'], {
    is: (dbType, authorizationMethod, authJwtType) => dbType === 'apiCall' && authorizationMethod === 'jwt' && authJwtType === 'serverJWT',
    then: 
      Yup.string()
        .test('env-exists', 'All env variables must exist', checkEnvExistense(params.variables))
        .required('Auth Url required')
        // .test('is-valid-url', 'Invalid URL', (value) => isUrlWithPlaceholder(value)),
  }),
  authJwtServerPayload: Yup.string().when(['dbType', 'authorizationMethod', 'authJwtType'], {
    is: (dbType, authorizationMethod, authJwtType) => dbType === 'apiCall' && authorizationMethod === 'jwt' && authJwtType === 'serverJWT',
    then: Yup.string()
      .test('env-exists', 'All env variables must exist', checkEnvExistense(params.variables))
      .test('is-json', 'Value must be a valid non Array JSON object', (value) => {
        if (!value || value === '' || /^"{0,1}\{\{env\..*?\}\}"{0,1}$/g.test(value)) return true;
        try {
          const result = JSON.parse(value);
          return result && typeof result === 'object' && !Array.isArray(result) && result !== null;
        } catch {
          return false;
        }
      })
      .required('Payload required'),
  }),
  authJwtClientAddTo: Yup.string().when(['dbType', 'authorizationMethod', 'authJwtType'], {
    is: (dbType, authorizationMethod, authJwtType) => dbType === 'apiCall' && authorizationMethod === 'jwt' && authJwtType === 'JWT',
    then: Yup.string().required('Add to required'),
  }),
  authJwtClientAlgorithm: Yup.string().when(['dbType', 'authorizationMethod', 'authJwtType'], {
    is: (dbType, authorizationMethod, authJwtType) => dbType === 'apiCall' && authorizationMethod === 'jwt' && authJwtType === 'JWT',
    then: Yup.string().required('Algorithm required'),
  }),
  authJwtClientHeaderPrefix: Yup.string().when(['dbType', 'authorizationMethod', 'authJwtType'], {
    is: (dbType, authorizationMethod, authJwtType) => dbType === 'apiCall' && authorizationMethod === 'jwt' && authJwtType === 'JWT',
    then: Yup.string()
      .test('env-exists', 'All env variables must exist', checkEnvExistense(params.variables)),
  }),
  authJwtClientHeaders: Yup.string().when(['dbType', 'authorizationMethod', 'authJwtType'], {
    is: (dbType, authorizationMethod, authJwtType) => dbType === 'apiCall' && authorizationMethod === 'jwt' && authJwtType === 'JWT',
    then: Yup.string()
      .test('env-exists', 'All env variables must exist', checkEnvExistense(params.variables))
      .test('is-json', 'Value must be a valid non Array JSON object', (value) => {
        if (!value || value === '' || /^"{0,1}\{\{env\..*?\}\}"{0,1}$/g.test(value)) return true;
        try {
          const result = JSON.parse(value);
          return result && typeof result === 'object' && !Array.isArray(result) && result !== null;
        } catch {
          return false;
        }
      }),
  }),
  authJwtClientPrivateKey: Yup.string().when(['dbType', 'authorizationMethod', 'authJwtType'], {
    is: (dbType, authorizationMethod, authJwtType) => dbType === 'apiCall' && authorizationMethod === 'jwt' && authJwtType === 'JWT',
    then: Yup.string()
      .test('env-exists', 'All env variables must exist', checkEnvExistense(params.variables))
      .required('Private Key required'),
  }),
  authJwtClientPayload: Yup.string().when(['dbType', 'authorizationMethod', 'authJwtType'], {
    is: (dbType, authorizationMethod, authJwtType) => dbType === 'apiCall' && authorizationMethod === 'jwt' && authJwtType === 'JWT',
    then: Yup.string()
      .test('env-exists', 'All env variables must exist', checkEnvExistense(params.variables))
      .test('is-json', 'Value must be a valid non Array JSON object', (value) => {
        if (!value || value === '' || /^"{0,1}\{\{env\..*?\}\}"{0,1}$/g.test(value)) return true;
        try {
          const result = JSON.parse(value);
          return result && typeof result === 'object' && !Array.isArray(result) && result !== null;
        } catch {
          return false;
        }
      })
      .required('Payload required'),
  }),
  requestParams: Yup.array().when('dbType', {
    is: 'apiCall',
    then: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required('Name required'),
        value: Yup.string()
          .required('Value required')
          .test('env-exists', 'All env variables must exist', checkEnvExistense(params.variables))
      }).test('unique-name', 'Name must be unique', function (item) {
        const { path, createError } = this;
        const items = this.parent;
        const duplicate = items.filter(i => i.name === item.name);
        if (duplicate.length > 1) {
          return createError({ path: `${path}.name`, message: 'The parameter must include unique Key name' });
        }
        return true;
      }),
    ),
  }),
  headerParams: Yup.array().when('dbType', {
    is: 'apiCall',
    then: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required('Name required'),
        value: Yup.string()
          .required('Value required')
          .test('env-exists', 'All env variables must exist', checkEnvExistense(params.variables))
      }).test('unique-name', 'Name must be unique', function (item) {
        const { path, createError } = this;
        const items = this.parent;
        const duplicate = items.filter(i => i.name === item.name);
        if (duplicate.length > 1) {
          return createError({ path: `${path}.name`, message: 'The parameter must include unique Key name' });
        }
        return true;
      }),
    ),
  }),
  ftpLogin: Yup.string(),
});

function ConnectionModify({
  connectedEndpoints,
  getConnectedEndpoints,
  updateConnection,
  getActiveConnection,
  testConnection,
  activeConnection,
  currentUser,
  createToast,
  connectionTypes,
  activeProject,
  getAccessUsers,
  addAccess,
  roles,
  isRoleAction,
  storedInviteList,
  JWTAlgorithm,
  connectLimit
}) {
  const {showMonacoErrors} = useMonacoErrors();
  const {variables} = useVariables();
  useMonaco();
  const navigate = useNavigate();
  const [testStatus, setTestStatus] = useState(loadStatus.INITIAL);
  const [subroute, setSubroute] = useState(SUBROUTING.AUTHORIZATION);
  const [placeholders, setPlaceholders] = useState(limitsInit);
  const [authVisible, setAuthVisible] = useState(false);
  const { shortName, resourceId } = useParams();
  const { state } = useLocation();
  const { redirectFolder } = state || {};
  const [shareModalOpened, setShareModalOpened] = useState(false);
  const [inviteList, setInviteList] = useState([]);
  const [removeModalOpened, setRemoveModalOpened] = useState(false);
  const [activeUser, setActiveUser] = useState({});
  const [, copyText] = useCopyToClipboard();
  const [submitTriggered, setSubmitTriggered] = useState(false);

  const [isChange, setIsChange] = useState(false);
  const isDeleted = Boolean(activeConnection?.deleted);
  const viewRead = !isRoleAction({ key: 'connection', role: 'update', modelRole: activeConnection?.role }) || isDeleted;
  const dispatch = useDispatch();
  const algorithms = JWTAlgorithm ? JWTAlgorithm.map(v => ({ value: v, title: v })) : [];

  const AWSRegions = connectionTypes?.find(c =>c.type === 'aws')?.regions;
  const chatGptVersions = connectionTypes?.find(c =>c.type === 'chatGpt')?.versions;
  const ftpFileSizeLimit = connectionTypes?.find(c =>c.type === 'ftp')?.maximumFileSizeLimit;

  useEffect(() => {
    getAccessUsers({
      id: +resourceId,
      resource: 'connection',
    });
  }, []);

  useEffect(() => {
      setInviteList(storedInviteList || []);
  }, [storedInviteList]);

  const {
    submitForm, handleChange: handleChange2, setFieldValue: setFieldValue2, validateForm, values, errors, setValues,
  } = useFormik({
    initialValues: {
      authorizationMethod: 'none',
      authJwtClientKeyBase64Encoded: false,
      requestFormat: 'json',
      name: '',
      description: '',
      dbType: '',
      dbName: '',
      dbUsername: '',
      dbHost: '',
      dbPassword: '',
      dbPort: '',
      dbStatus: 'active',
      corezoidUrl: '',
      corezoidApiLogin: '',
      corezoidApiKey: '',
      accessKey: '',
      secretKey: '',
      region: '',
      encrypt: 'true',
      trustServerCertificate: 'true',
      ftpLogin: '',
      maximumFileSize: null,
      maxConnections: 5,
      timeout: 30,
      requestType: 'synchronous',
      requestParams: [],
      headerParams: []
    },
    validationSchema: ConnectionCreateSchema({ 
      ftpFileSizeLimit, 
      variables: variables?.data,
      connectLimit,
     }),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (v, { setFieldValue }) => {
      const req = {
        folderId: activeConnection?.folderId || 0,
        instanceName: v.name,
        description: v.description,
        id: +resourceId,
        type: v.dbType,
        status: v.dbStatus,
        limit: {
          sec: +v.RPS || 0,
          mon: v.activeRpmType === 'mon' ? (+v.RPMONTH || 0) : 0,
          cmon: v.activeRpmType === 'cmon' ? (+v.RPMONTHCALENDAR || 0) : 0,
        },
        connectionSettings: {
          database: v.dbName,
          user: v.dbUsername,
          host: v.dbHost,
          password: v.dbPassword,
          port: v.dbPort,
          timeout: +v.timeout * 1000,
          connectionCount: +v.maxConnections,
        },
      };

      if (v.dbType === 'mssql') {
        req.connectionSettings.encrypt = Boolean(v.encrypt === 'true');
        req.connectionSettings.trustServerCertificate = Boolean(v.trustServerCertificate === 'true');
      }

      if (v.dbType === 'corezoid') {
        req.connectionSettings = {
          url: v.corezoidUrl,
          apiKey: v.requestType === 'synchronous' ? v.corezoidApiLogin : '',
          apiSecret: v.requestType === 'synchronous' ? v.corezoidApiKey :  '',
        };
        req.requestType = v.requestType
      }

      if (v.dbType === 'aws') {
        req.connectionSettings = {
          accessKey: v.accessKey,
          secretKey: v.secretKey,
          region: v.region,
        };
      }

      if (v.dbType === 'chatGpt') {
        req.connectionSettings = {
          organization: v.organization,
          secret: v.secret,
          version: v.version,
        };
      }

      if (v.dbType === 'ftp') {
        req.connectionSettings = {
          host: v.dbHost,
          port: v.dbPort,
          user: v.ftpLogin,
          password: v.dbPassword,
          maximumFileSize: v.maximumFileSize,
        };
      }

      if (v.dbType === 'apiCall') {
        req.connectionSettings = {
          url: v.apiUrl,
          method: v.apiRequestMethod,
          format: v.requestFormat,
          input: {
            parameters: v.requestParams?.map((p) => ({
              name: p.name,
              description: p.description,
              value: p.value,
              type: p.type,
              required: p.required === 'yes',
            })),
            headers: v.headerParams?.map((p) => ({
              name: p.name,
              description: p.description,
              value: p.value,
              type: p.type,
              required: p.required === 'yes',
            })),
          },
          timeout: +v.timeout * 1000,
          apiAuthorization: {
            authorizationType: v.authorizationMethod
          },
        };

        if (v.authorizationMethod === 'basic') {
          req.connectionSettings.apiAuthorization = {
            ...req.connectionSettings.apiAuthorization,
            login: v.authBasicLogin,
            password: v.authBasicPassword,
          }
        }

        if (v.authorizationMethod === 'APIKey') {
          req.connectionSettings.apiAuthorization = {
            ...req.connectionSettings.apiAuthorization,
            headerName: v.authAPIKeyHeaderName,
            token: v.authAPIKeyToken,
            addTo: v.authAPIKeyAddTo,
          }
        }

        if (v.authorizationMethod === 'bearer') {
          req.connectionSettings.apiAuthorization = {
            ...req.connectionSettings.apiAuthorization,
            token: v.authBearerToken,
          }
        }

        if (v.authorizationMethod === 'jwt' && v.authJwtType === 'serverJWT') {
          req.connectionSettings.apiAuthorization = {
            authorizationType: v.authJwtType,
            payload: v.authJwtServerPayload,
            authorizationUrl: v.authJwtServerAuthUrl
          }
        }

        if (v.authorizationMethod === 'jwt' && v.authJwtType === 'JWT') {
          req.connectionSettings.apiAuthorization = {
            authorizationType: v.authJwtType,
            addTo: v.authJwtClientAddTo,
            algorithm: v.authJwtClientAlgorithm,
            key: v.authJwtClientPrivateKey,
            isBase64Key: v.authJwtClientKeyBase64Encoded,
            payload: v.authJwtClientPayload,
            headerPrefix: v.authJwtClientHeaderPrefix,
            headers: v.authJwtClientHeaders
          }
        }
      }

      updateConnection(req, () => {
        setIsChange(false);
        if (!(+v.RPS)) setFieldValue('RPS', '');
      });
    },
  });

  useEffect(() => {
    getActiveConnection({ id: resourceId });
  }, [resourceId]);

  useEffect(() => {
    if (values.dbType === 'apiCall') {
      setAuthVisible(true);
      setSubroute(SUBROUTING.AUTHORIZATION);
    } else {
      setAuthVisible(false);
      setSubroute(SUBROUTING.LIMITS);
    }
  }, [values.dbType]);

  useEffect(() => {
    if (activeConnection && activeConnection.deleted === false) {
      getConnectedEndpoints( {id: resourceId });
    }
  }, [activeConnection]);

  useEffect(() => {
    if (activeConnection && variables?.data) {
      const {
        instanceName,
        description,
        type,
        connectionSettings,
        status,
        requestType,
        limit = {
          sec: 0, min: 0, hour: 0, day: 0, mon: 0,
        },
      } = activeConnection || {};

      const { timeout, connectionCount = 5 } = connectionSettings || {};

      const baseParam = {
        RPS: limit.sec || '',
        RPMONTH: limit.mon || '',
        RPMONTHCALENDAR: limit.cmon || '',
        name: instanceName,
        description,
        dbType: type,
        activeRpmType: limit.mon ? 'mon' : 'cmon',
      }

      if (type === 'chatGpt') {
        setValues({
          ...baseParam,
          version: connectionSettings.version,
          secret: connectionSettings.secret,
          organization: connectionSettings.organization,
          dbStatus: status || 'active',
        });
      } else if (type === 'aws') {
        setValues({
          ...baseParam,
          accessKey: connectionSettings.accessKey,
          secretKey: connectionSettings.secretKey,
          region: connectionSettings.region,
          dbStatus: status || 'active',
        });
      } else if (type === 'corezoid') {
        setValues({
          ...baseParam,
          requestType,
          corezoidUrl: connectionSettings.url,
          corezoidApiLogin: connectionSettings.apiKey,
          corezoidApiKey: connectionSettings.apiSecret,
          dbStatus: status || 'active',
        });
      } else if (type === 'mssql') {
        setValues({
          ...baseParam,
          timeout: (timeout || timeout === 0) ? Math.round(timeout / 1000) : '',
          maxConnections: connectionCount,
          dbName: connectionSettings.database,
          dbUsername: connectionSettings.user,
          dbHost: connectionSettings.host,
          dbPassword: connectionSettings.password,
          dbPort: connectionSettings.port,
          dbStatus: status || 'active',
          encrypt: connectionSettings.encrypt ? 'true' : 'false',
          trustServerCertificate: connectionSettings.trustServerCertificate ? 'true' : 'false',
        });
      } else if (type === 'ftp') {
        setValues({
          ...baseParam,
          dbHost: connectionSettings.host,
          dbPassword: connectionSettings.password,
          dbPort: connectionSettings.port,
          ftpLogin: connectionSettings.user,
          maximumFileSize: connectionSettings.maximumFileSize,
          dbStatus: status || 'active',
        });
      } else if (type === 'apiCall') {
        setValues({
          ...baseParam,
          timeout: (timeout || timeout === 0) ? Math.round(timeout / 1000) : '',
          dbStatus: status,
          apiUrl: connectionSettings.url,
          apiRequestMethod: connectionSettings.method,
          requestFormat: connectionSettings.format,
          authorizationMethod: 
            ['JWT', 'serverJWT'].includes(connectionSettings.apiAuthorization.authorizationType) ? 
             'jwt' :
             connectionSettings.apiAuthorization.authorizationType,
          requestParams: connectionSettings?.input?.parameters?.map((f) => ({ ...f, id: uid(8), required: f.required ? 'yes' : 'no' })) || [],
          headerParams: connectionSettings?.input?.headers?.map((f) => ({ ...f, id: uid(8), required: f.required ? 'yes' : 'no' })) || [],
          authBasicLogin: connectionSettings.apiAuthorization.login,
          authBasicPassword: connectionSettings.apiAuthorization.password,
          authAPIKeyHeaderName: connectionSettings.apiAuthorization.headerName,
          authAPIKeyToken: connectionSettings.apiAuthorization.token,
          authAPIKeyAddTo: connectionSettings.apiAuthorization.addTo,
          authBearerToken: connectionSettings.apiAuthorization.token,
          authJwtType: connectionSettings.apiAuthorization.authorizationType,
          authJwtServerAuthUrl: connectionSettings.apiAuthorization.authorizationUrl,
          authJwtServerPayload: connectionSettings.apiAuthorization.payload,
          authJwtClientAddTo: connectionSettings.apiAuthorization.addTo,
          authJwtClientAlgorithm: connectionSettings.apiAuthorization.algorithm,
          authJwtClientPrivateKey: connectionSettings.apiAuthorization.key,
          authJwtClientKeyBase64Encoded: connectionSettings.apiAuthorization.isBase64Key,
          authJwtClientPayload: connectionSettings.apiAuthorization.payload,
          authJwtClientHeaderPrefix: connectionSettings.apiAuthorization.headerPrefix,
          authJwtClientHeaders: 
            typeof connectionSettings.apiAuthorization.headers === 'object' 
            ? stringifyIfJSON(connectionSettings.apiAuthorization.headers)
            : connectionSettings.apiAuthorization.headers
        });
      } else {
        setValues({
          ...baseParam,
          timeout: (timeout || timeout === 0) ? Math.round(timeout / 1000) : '',
          maxConnections: connectionCount,
          dbName: connectionSettings?.database,
          dbUsername: connectionSettings?.user,
          dbHost: connectionSettings?.host,
          dbPassword: connectionSettings?.password,
          dbPort: connectionSettings?.port,
          dbStatus: status || 'active',
        });
      }

      setPlaceholders(calcPlaceholder(baseParam));
    }
  }, [activeConnection, variables?.data]);

  const debouncedValidateForm = debounce(() => {
    validateForm();
  }, 100);

  const setFieldValue = (...args) => {
    setFieldValue2(...args);
    if (submitTriggered) {
      debouncedValidateForm();
    }
  }

  const handleChange = (...args) => {
    handleChange2(...args);
    if (submitTriggered) {
      debouncedValidateForm();
    }
  }

  const handleSubmitWithCallback = (e) => {
    if(showMonacoErrors()) return
    e.preventDefault();
    setSubmitTriggered(true);
    submitForm(e);
  };

  const redirectToList = (redFolder, parentFolder) => {
    const isNumber = typeof redFolder === 'number';
    if (isNumber) {
      if (redFolder) navigate(`/${shortName}/connections/folder/${redFolder}`);
      if (!redFolder) navigate(`/${shortName}/connections`);
    } else {
      if (parentFolder) navigate(`/${shortName}/connections/folder/${parentFolder}`);
      if (!parentFolder) navigate(`/${shortName}/connections`);
    }
  };

  const redirectToTrash = (redFolder, parentFolder) => {
    const isNumber = typeof redFolder === 'number';
    if (isNumber) {
      if (redFolder) navigate(`/${shortName}/trash/connections/folder/${redFolder}`);
      if (!redFolder) navigate(`/${shortName}/trash`);
    } else {
      if (parentFolder) navigate(`/${shortName}/trash/connections/folder/${parentFolder}`);
      if (!parentFolder) navigate(`/${shortName}/trash`);
    }
  };

  const listAPI = () => redirectToList(redirectFolder, activeConnection?.folder);
  const backToTrash = () => redirectToTrash(redirectFolder, activeConnection?.folder);

  const handleChangeProxy = (...arg) => {
    if (testStatus !== loadStatus.INITIAL) setTestStatus(loadStatus.INITIAL);
    setIsChange(true);
    return handleChange(...arg);
  };

  const handleChangeNumberWithLength = (maxLength) => (...arg) => {
    if (testStatus !== loadStatus.INITIAL) {
      setTestStatus(loadStatus.INITIAL);
    }
    setIsChange(true);
    const { value } = arg[0].target;
    const numberRegex = /^[0-9]+$/;
    const noSpaceRegex = /^\S*$/;
    const isEmpty = value === '';
    const passedRegexes = numberRegex.test(value) && noSpaceRegex.test(value);
    const lengthLimitReached = maxLength ? value.length > maxLength : false;
    if ((passedRegexes || isEmpty) && !lengthLimitReached) {
      handleChange(...arg);
    }
  };

  const handleChangeNumber = (e) => {
    const { value, name } = e.target;
    if (Number.isNaN(+value) || !Number.isInteger(+value) || (+value) < 0) return;
    setIsChange(true);
    setFieldValue(name, value ? Math.round(+value) : value);
    setTestStatus(loadStatus.INITIAL);
  };

  const setFieldValueProxy = (...arg) => {
    if (testStatus !== loadStatus.INITIAL) setTestStatus(loadStatus.INITIAL);
    setIsChange(true);
    return setFieldValue(...arg);
  };

  const setFieldValueSkipCheck = (...arg) => {
    setFieldValue(...arg);
  };

  const deleteAccess = (user) => {
    const newInviteList = inviteList.filter((el) => el.email !== user.email);
    setInviteList(newInviteList);
    setRemoveModalOpened(false);
    setActiveUser({});
  };

  const generateList = (regions) => {
    if (regions) {
      return [...regions.map((r) => ({
        value: r,
        title: r,
      }))];
    }
    return [];
  };

  const handleBlurLimits = (e) => {
    const { value, name } = e.target;
    if (Number.isNaN(+value) || !Number.isInteger(+value) || (+value) < 0) {
      return;
    }
    switch (name) {
      case 'RPS': {
        setFieldValue(name, value);
        if (+values.RPMONTH && +value && +values.RPMONTH < +value) setFieldValue('RPMONTH', value);
        if (+values.RPMONTHCALENDAR && +value && +values.RPMONTHCALENDAR < +value) setFieldValue('RPMONTHCALENDAR', value);
        break;
      }
      case 'RPMONTH': {
        if (+values.RPS && +value && +values.RPS > +value) setFieldValue('RPS', value);
        setFieldValue(name, value);
        break;
      }
      case 'RPMONTHCALENDAR': {
        if (+values.RPS && +value && +values.RPS > +value) setFieldValue('RPS', value);
        setFieldValue(name, value);
        break;
      }
      default: {
        setFieldValue(name, value);
      }
    }

    setPlaceholders(calcPlaceholder({ ...values, [name]: value }));
  };

  const handeChangeRole = (user, role) => {
    if (role === 'delete') {
      setRemoveModalOpened(true);
      setActiveUser(user);
      return;
    }

    const newInviteList = inviteList.map((el) => (el.email === user.email ? { ...el, role } : el));
    addAccess({
      id: +resourceId,
      resource: 'connection',
      projectId: +activeProject.id,
      role,
      userId: user.id,
    }, () => {
      setInviteList(newInviteList);
    });
  };

  const addRequestParameter = () => {
    setFieldValueProxy('requestParams', [...(values.requestParams || []), { ...emptyParam, id: uid(8) }]);
  };

  const addHeaderParameter = () => {
    setFieldValueProxy('headerParams', [...(values.headerParams || []), { ...emptyParam, id: uid(8) }]);
  };

  const testConnectionConnection = () => {
    validateForm(values).then((errors) => {
      if (Object.keys(errors).length) return;

      const req = {
        instanceName: values.name,
        description: values.description,
        projectId: +activeProject.id,
        type: values.dbType,
        status: values.dbStatus,
        folder: activeConnection?.folder || 0,
        connectionSettings: {
          database: values.dbName,
          user: values.dbUsername,
          host: values.dbHost,
          password: values.dbPassword,
          port: values.dbPort,
          timeout: (typeof +values.timeout === 'number' ? +values.timeout : 30) * 1000,
          connectionCount: typeof +values.maxConnections === 'number' ? +values.maxConnections : 5,
        },
      };

      if (values.dbType === 'mssql') {
        req.connectionSettings.encrypt = Boolean(values.encrypt === 'true');
        req.connectionSettings.trustServerCertificate = Boolean(values.trustServerCertificate === 'true');
      }

      if (values.dbType === 'aws') {
        req.connectionSettings = {
          accessKey: values.accessKey,
          secretKey: values.secretKey,
          region: values.region,
        };
      }

      if (values.dbType === 'chatGpt') {
        req.connectionSettings = {
          organization: values.organization,
          secret: values.secret,
          version: values.version,
        };
      }

      if (values.dbType === 'ftp') {
        req.connectionSettings = {
          host: values.dbHost,
          port: values.dbPort,
          user: values.ftpLogin,
          password: values.dbPassword,
          maximumFileSize: values.maximumFileSize,
        };
      }

      if (values.dbType === 'corezoid') {
        req.connectionSettings = {
          url: values.corezoidUrl,
          apiKey: values.corezoidApiLogin,
          apiSecret: values.corezoidApiKey,
        };
        req.requestType = values.requestType
      }

      if (values.dbType === 'apiCall') {
        req.connectionSettings = {
          url: values.apiUrl,
          method: values.apiRequestMethod,
          format: values.requestFormat,
          input: {
            parameters: values.requestParams?.map((p) => ({
              name: p.name,
              description: p.description,
              value: p.value,
              type: p.type,
              required: p.required === 'yes',
            })),
            headers: values.headerParams?.map((p) => ({
              name: p.name,
              description: p.description,
              value: p.value,
              type: p.type,
              required: p.required === 'yes',
            })),
          },
          timeout: +values.timeout * 1000,
          apiAuthorization: {
            authorizationType: values.authorizationMethod
          },
        };

        if (values.authorizationMethod === 'basic') {
          req.connectionSettings.apiAuthorization = {
            ...req.connectionSettings.apiAuthorization,
            login: values.authBasicLogin,
            password: values.authBasicPassword,
          }
        }

        if (values.authorizationMethod === 'APIKey') {
          req.connectionSettings.apiAuthorization = {
            ...req.connectionSettings.apiAuthorization,
            headerName: values.authAPIKeyHeaderName,
            token: values.authAPIKeyToken,
            addTo: values.authAPIKeyAddTo,
          }
        }

        if (values.authorizationMethod === 'bearer') {
          req.connectionSettings.apiAuthorization = {
            ...req.connectionSettings.apiAuthorization,
            token: values.authBearerToken,
          }
        }

        if (values.authorizationMethod === 'jwt' && values.authJwtType === 'serverJWT') {
          req.connectionSettings.apiAuthorization = {
            authorizationType: values.authJwtType,
            payload: values.authJwtServerPayload,
            authorizationUrl: values.authJwtServerAuthUrl
          }
        }

        if (values.authorizationMethod === 'jwt' && values.authJwtType === 'JWT') {
          req.connectionSettings.apiAuthorization = {
            authorizationType: values.authJwtType,
            addTo: values.authJwtClientAddTo,
            algorithm: values.authJwtClientAlgorithm,
            key: values.authJwtClientPrivateKey,
            isBase64Key: values.authJwtClientKeyBase64Encoded,
            payload: values.authJwtClientPayload,
            headerPrefix: values.authJwtClientHeaderPrefix,
            headers: values.authJwtClientHeaders
          }
        }
      }

      setTestStatus(loadStatus.LOAD);

      testConnection(req, (err) => {
        if (err) setTestStatus(loadStatus.ERROR);
        if (!err) setTestStatus(loadStatus.SUCCESS);
      });
    });
  };

  const renderTestConnectionButton = (st) => {
    switch (st) {
      case loadStatus.INITIAL:
        return <Button title="Test connection" onClick={testConnectionConnection} />;
      case loadStatus.SUCCESS:
        return <Button title="Connection valid" style={{ gap: 8 }} color="green" type="secondary" iconRight='checked' onClick={testConnectionConnection} />;
      case loadStatus.ERROR:
        return <Button title="Connection error" style={{ gap: 8 }} color="red" type="secondary" iconRight='alert' onClick={testConnectionConnection} />;
      case loadStatus.LOAD:
        return <Button disabled title="Connecting..." loading onClick={testConnectionConnection} />;
      default:
        return <Button title="Test connection" onClick={testConnectionConnection} />;
    }
  };

  const addUserOfApiKey = () => {
    setShareModalOpened(true);
  };

  const getRoles = () => {
    const rolesList = [];
    const filteredRoles = getProjectRoles(roles, activeProject.id).filter((r) => r.name !== 'owner');
    if (isRoleAction({ key: 'connection', role: 'share', modelRole: activeConnection?.role })) {
      rolesList.push(...filteredRoles.map((r) => ({ value: r.name, title: listRolesName[r.name] || r.name })));
    } else {
      rolesList.push(...filteredRoles.map((r) => ({ value: r.name, title: listRolesName[r.name] || r.name, disabled: true })));
    }
    if (isRoleAction({ key: 'connection', role: 'delete', modelRole: activeConnection?.role })) {
      rolesList.push({ value: 'delete', title: 'Delete', hidden: currentUser.role === 'viewer', red: true  });
    }
    return rolesList;
  };

  const saveInviteList = (data) => {
    const filtered = data.filter((el) => el.id);
    const unique = Object.values(
      [...inviteList, ...filtered].reduce((acc, obj) => ({ ...acc, [obj.email]: obj }), {}),
    );
    setInviteList(unique);
    setShareModalOpened(false);
  };

  const handleChangeName = ({ target }) => {
    const regex = /^(?!\s)(?!.* {2})/;
    if (regex.test(target.value) && target.value.length <= maxAllowedLength) {
      setIsChange(true);
      setFieldValue(target.name, target.value);
    }
  };

  const handleRemoveConnection = () => {
    dispatch(removeTrashItemsRequest({ type: 'connections', ids: [activeConnection?.id] }, ({ errors, successList }) => {
      if (errors && errors.length > 0) {
        errors.forEach((error) => dispatch(createToastRequest({ type: 'error', text: error.error })));
      }
      if (successList && successList.length > 0) {
        dispatch(createToastRequest({ type: 'success', title: activeConnection?.instanceName, text: 'connection has been deleted successfully' }));
        backToTrash();
      }
    }));
  };

  const handleRestoreConnection = () => {
    dispatch(restoreTrashItemsRequest({ type: 'connections', items: [{ ...activeConnection, deleted: false }] }, ({ errors, successList }) => {
      if (errors && errors.length > 0) {
        errors.forEach((error) => dispatch(createToastRequest({ type: 'error', text: error.error })));
      }
      if (successList && successList.length > 0) {
        dispatch(createToastRequest({ type: 'success', title: activeConnection?.instanceName, text: 'connection has been restored successfully' }));
        backToTrash();
      }
    }));
  };

  console.log(values.name);

  return (
    <div className={shared.page} style={{ overflow: 'auto' }}>
      <div className={cn(shared.header, shared.borderBottom)} style={{ position: 'sticky', top: 0, zIndex: 9999, backgroundColor: '#fff' }}>
        <div className={shared.headerTitleGroup}>
          <h1 className={shared.headerTitle}>Edit Connection</h1>
          <p className={shared.headerDescription}>{isDeleted ? 'Read only' : ''}</p>
        </div>
        <div className={shared.headerButtonGroup}>
          {!isDeleted && (
            <>
              <ValidationErrorList 
                errors={errors} 
                subrouteMapping={subrouteMapping}
                setSubroute={setSubroute}
              />
              <Button title="Cancel" type="secondary" onClick={listAPI} />
              <Button title="Edit connection" onClick={handleSubmitWithCallback} disabled={viewRead || !isChange} />
            </>
          )}
          {isDeleted && (
            <>
              <Button title="Cancel" type="secondary" onClick={backToTrash} />
              <Button title="Delete" type="red" onClick={handleRemoveConnection} />
              <Button title="Restore" onClick={handleRestoreConnection} />
            </>
          )}
        </div>
      </div>
      <div className={cn(shared.body, shared.compactLayout)} style={{ border: 0 }}>
        <div className={cn(shared.mainContent, shared.compactLayout)}>
          <section className={shared.section}>
            <h4 className={shared.sectionHeader}>Instance Settings</h4>
            <div className={shared.sectionContainer}>
              <div className={shared.inputWrapper}>
                <span className={shared.inputLabel}>Instance name</span>
                <Input
                  placeholder="Enter instance name"
                  handleChange={handleChangeName}
                  value={values.name}
                  name="name"
                  id="name"
                  autoComplete="off"
                  error={!!errors.name}
                  disabled={viewRead}
                />
              </div>
              <div className={shared.inputWrapper}>
                <span className={shared.inputLabel}>
                  Description 
                  {' '}
                  <span className={shared.inputLabelDetails}> (optional)</span>
                </span>
                <Input
                  placeholder="Enter description"
                  handleChange={handleChangeProxy}
                  value={values.description}
                  name="description"
                  id="description"
                  disabled={viewRead}
                />
              </div>
            </div>
          </section>
          <hr />
          <section className={shared.section}>
            <h4 className={shared.sectionHeader}>Connection Settings</h4>
            <div className={shared.sectionContainer}>
              <div className={shared.inputWrapper}>
                <span className={shared.inputLabel}>Type</span>
                <Select
                  list={connectionTypes?.map((db) => ({
                    value: db?.type,
                    title: typesAPI[db?.type] || db?.type,
                    icon: <DBIcon type={db?.type} style={{ marginRight: '12px', width: '24px', height: '24px' }} />,
                  }))}
                  value={values.dbType}
                  handleChange={setFieldValueProxy}
                  name="dbType"
                  id="dbType"
                  error={!!errors.dbType}
                  disabled={viewRead}
                />
              </div>
              <div className={shared.inputWrapper} style={{ display: (values.dbType === 'corezoid' || values.dbType !== 'ftp') ? 'none' : 'flex'}} />
              {values.dbType === 'aws' ? (
                <>
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>
                      <span>Region</span>
                      <ActionText
                        title={(
                          <ActionIcon
                            id="region_tooltip"
                            icon="info"
                            style={{ marginLeft: '8px' }}
                            tooltip={{
                              children:  
                                <span style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column'}}>
                                  AWS Region where your AWS S3 bucket is hosted.<br/>
                                  <a target="_blank" rel="noopener noreferrer" href="https://docs.aws.amazon.com/general/latest/gr/s3.html">Learn more</a>
                                </span>,
                              place: 'right',
                              openOnClick: true,
                              clickable: true,
                            }}
                          />
                        )}
                      />
                    </span>
                    <Select
                      list={[
                        ...generateList(AWSRegions),
                      ]}
                      value={values.region}
                      handleChange={(key, value) => {
                        setFieldValueProxy(key, value);
                      }}
                      name="region"
                      id="region"
                      placeholder="Select region"
                      error={!!errors.region}
                      disabled={viewRead}
                    />
                  </div>
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>
                        <span>AWS Access Key</span>
                        <ActionText
                          title={(
                            <ActionIcon
                              id="accessKey_tooltip"
                              icon="info"
                              style={{ marginLeft: '8px' }}
                              tooltip={{
                                children:  
                                  <span style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column'}}>
                                    AWS Access Key that has access to the AWS S3.<br/>
                                    <a target="_blank" rel="noopener noreferrer" href="https://docs.aws.amazon.com/IAM/latest/UserGuide/id_credentials_access-keys.html">Learn more</a>
                                  </span>,
                                place: 'right',
                                openOnClick: true,
                                clickable: true,
                              }}
                            />
                          )}
                        />
                    </span>
                    <Input
                      placeholder="AWS Access Key"
                      handleChange={handleChangeProxy}
                      value={values.accessKey}
                      type="password"
                      isPassword
                      autoComplete="new-password"
                      name="accessKey"
                      id="accessKey"
                      error={!!errors.accessKey}
                      disabled={viewRead}
                    />
                  </div>
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>
                        <span>AWS Secret Key</span>
                        <ActionText
                          title={(
                            <ActionIcon
                              id="secretKey_tooltip"
                              icon="info"
                              style={{ marginLeft: '8px' }}
                              tooltip={{
                                children:  
                                  <span style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column'}}>
                                    AWS Secret Key that has access to AWS S3.<br/>
                                    <a target="_blank" rel="noopener noreferrer" href="https://docs.aws.amazon.com/IAM/latest/UserGuide/id_credentials_access-keys.html">Learn more</a>
                                  </span>,
                                place: 'right',
                                openOnClick: true,
                                clickable: true,
                              }}
                            />
                          )}
                        />
                    </span>
                    <Input
                      placeholder="AWS Secret Key"
                      handleChange={handleChangeProxy}
                      value={values.secretKey}
                      name="secretKey"
                      id="secretKey"
                      type="password"
                      isPassword
                      autoComplete="new-password"
                      error={!!errors.secretKey}
                      disabled={viewRead}
                    />
                  </div>
                </>
              ) : null}
              {values.dbType === 'chatGpt' ? (
                <>
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>
                      <span>Version</span>
                    </span>
                    <Select
                      list={[
                        ...generateList(chatGptVersions),
                      ]}
                      placeholder="Select version"
                      value={values.version}
                      handleChange={setFieldValueProxy}
                      name="version"
                      id="version"
                      error={!!errors.version}
                      disabled={viewRead}
                    />
                  </div>
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>
                      <span>Secret Key</span>
                    </span>
                    <Input
                      placeholder="Secret Key"
                      handleChange={handleChangeProxy}
                      value={values.secret}
                      name="secret"
                      id="secret"
                      type="password"
                      isPassword
                      autoComplete="new-password"
                      error={!!errors.secret}
                      disabled={viewRead}
                      iconRight={values.secret && 'copy'}
                      handleAction={() => {
                        copyText(values.secret) && createToast({ type: 'success', text: 'Copied' });
                      }}
                    />
                  </div>
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>
                      <span>Organization</span>
                    </span>
                    <Input
                      placeholder="Organization"
                      handleChange={handleChangeProxy}
                      value={values.organization}
                      name="organization"
                      id="organization"
                      error={!!errors.organization}
                      disabled={viewRead}
                      iconRight={values.organization && 'copy'}
                      handleAction={() => {
                        copyText(values.organization) && createToast({ type: 'success', text: 'Copied' });
                      }}
                    />
                  </div>
                </>
              ) : null}
              {(values.dbType === 'corezoid') ? (
                <>
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>
                      <span>Request type</span>
                      <ActionText
                        title={(
                          <ActionIcon
                            id="czRequestTYpe_tooltip"
                            icon="info"
                            style={{ marginLeft: '8px' }}
                            tooltip={{
                              children:  
                                <div style={{ maxWidth: '220px', textAlign: 'left'}}>
                                  Corezoid support 
                                  {' '}
                                  <a target="_blank" rel="noopener noreferrer" href="https://doc.corezoid.com/docs/start#from-a-webhook">asynchronous</a>
                                  {' '}
                                  and
                                  {' '}
                                  <a target="_blank" rel="noopener noreferrer" href="https://doc.corezoid.com/docs/sync-api">synchronous</a>
                                  {' '}
                                  calls.
                                </div>,
                              place: 'right',
                              openOnClick: true,
                              clickable: true,
                            }}
                          />
                        )}
                      />
                    </span>
                      <Select
                        list={connectionRequestTypeList}
                        placeholder="Select request type"
                        value={values.requestType}
                        handleChange={setFieldValueProxy}
                        name="requestType"
                        id="requestType"
                        error={!!errors.requestType}
                        disabled={viewRead}
                      />
                  </div>
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>
                      <span>{
                       values.requestType === 'synchronous' ? 'Corezoid Sync API URL' : 'Corezoid Webhook URL'
                      }
                      </span>
                      {values.requestType === 'synchronous' ? (
                        <ActionText
                          title={(
                            <ActionIcon
                              id="czSyncAPIUrl_tooltip"
                              icon="info"
                              style={{ marginLeft: '8px' }}
                              tooltip={{
                                children:  
                                  <div style={{ maxWidth: '240px', textAlign: 'left'}}>
                                    Corezoid Sync API URL that will be used to send synchronous requests to Corezoid process.
                                    {' '}
                                    <a target="_blank" rel="noopener noreferrer" href="https://doc.corezoid.com/docs/sync-api">Learn more.</a>
                                    <br />
                                    Example: <a target="_blank" rel="noopener noreferrer" href="https://sync-api.corezoid.com/">https://sync-api.corezoid.com/</a>
                                  </div>,
                                place: 'right',
                                openOnClick: true,
                                clickable: true,
                              }}
                            />
                          )}
                        />
                      ) : (
                        <ActionText
                          title={(
                            <ActionIcon
                              id="czaAsyncAPIUrl_tooltip"
                              icon="info"
                              style={{ marginLeft: '8px' }}
                              tooltip={{
                                children:  
                                  <div style={{ maxWidth: '240px', textAlign: 'left'}}>
                                    URL that will be used to send webhooks to Corezoid process.
                                    {' '}
                                    <a target="_blank" rel="noopener noreferrer" href="https://doc.corezoid.com/docs/corezoid-api">Learn more.</a>
                                    <br />
                                    Example: <br/>
                                    <a target="_blank" rel="noopener noreferrer" href="https://www.corezoid.com/api/1/json/public/0060000/*8b568f9c430f045b0c207ff8221">https://www.corezoid.com/api/1/json/publ...</a>
                                  </div>,
                                place: 'right',
                                openOnClick: true,
                                clickable: true,
                              }}
                            />
                          )}
                        />
                      )}
                    </span>
                    <Input
                      placeholder={values.requestType === 'synchronous' ? 'Enter Corezoid Sync API URL' : 'Enter Corezoid Webhook URL'}
                      handleChange={handleChangeProxy}
                      value={values.corezoidUrl}
                      name="corezoidUrl"
                      id="corezoidUrl"
                      iconRight={values.corezoidUrl && 'copy'}
                      handleAction={() => {
                        copyText(values.corezoidUrl) && createToast({ type: 'success', text: 'Copied' });
                      }}
                      error={!!errors.corezoidUrl}
                      disabled={viewRead}
                    />
                  </div>
                  {values.requestType === 'synchronous' ? (
                    <div className={shared.inputWrapper}>
                      <span className={shared.inputLabel}>
                        <span>Corezoid API Login</span>
                        <ActionText
                          title={(
                            <ActionIcon
                              id="czAPILogin_tooltip"
                              icon="info"
                              style={{ marginLeft: '8px' }}
                              tooltip={{
                                children:  
                                  <div style={{ maxWidth: '200px', textAlign: 'left'}}>
                                    Login from API Key that has access to Corezoid process you want to call.
                                    {' '}
                                    <a target="_blank" rel="noopener noreferrer" href="https://doc.corezoid.com/docs/api-keys-1">Learn more.</a>
                                  </div>,
                                place: 'right',
                                openOnClick: true,
                                clickable: true,
                              }}
                            />
                          )}
                        />
                      </span>
                      <Input
                        placeholder="Enter Corezoid API Login"
                        autoComplete="off"
                        handleChange={handleChangeProxy}
                        value={values.corezoidApiLogin}
                        name="corezoidApiLogin"
                        id="corezoidApiLogin"
                        iconRight={values.corezoidApiLogin && 'copy'}
                        handleAction={() => {
                          copyText(values.corezoidApiLogin) && createToast({ type: 'success', text: 'Copied' });
                        }}
                        error={!!errors.corezoidApiLogin}
                        disabled={viewRead}
                      />
                    </div>
                  ) : null}
                  {values.requestType === 'synchronous' ? (
                    <div className={shared.inputWrapper}>
                      <span className={shared.inputLabel}>
                        <span>Corezoid API Key</span>
                        <ActionText
                          title={(
                            <ActionIcon
                              id="czAPIKey_tooltip"
                              icon="info"
                              style={{ marginLeft: '8px' }}
                              tooltip={{
                                children:  
                                  <div style={{ maxWidth: '200px', textAlign: 'left'}}>
                                    Secret key from API Key that has access to Corezoid process you want to call.
                                    {' '}
                                    <a target="_blank" rel="noopener noreferrer" href="https://doc.corezoid.com/docs/api-keys-1">Learn more.</a>
                                  </div>,
                                place: 'right',
                                openOnClick: true,
                                clickable: true,
                              }}
                            />
                          )}
                        />
                      </span>
                      <Input
                        placeholder="Enter Corezoid API Key"
                        autoComplete="new-password"
                        handleChange={handleChangeProxy}
                        value={values.corezoidApiKey}
                        name="corezoidApiKey"
                        id="corezoidApiKey"
                        type="password"
                        isPassword
                        iconRight={values.corezoidApiKey && 'copy'}
                        handleAction={() => {
                          copyText(values.corezoidApiKey) && createToast({ type: 'success', text: 'Copied' });
                        }}
                        error={!!errors.corezoidApiKey}
                        disabled={viewRead}
                      />
                    </div>
                  ) : null}
                </>
              ) : null}
              {values.dbType === 'ftp' && (
                <>
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>Host</span>
                    <Input
                      placeholder="Enter host"
                      handleChange={handleChangeProxy}
                      value={values.dbHost}
                      name="dbHost"
                      id="dbHost"
                      error={!!errors.dbHost}
                      disabled={viewRead}
                    />
                  </div>
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>Port</span>
                    <Input
                      placeholder="Enter port"
                      handleChange={handleChangeProxy}
                      value={values.dbPort}
                      name="dbPort"
                      id="dbPort"
                      error={!!errors.dbPort}
                      disabled={viewRead}
                    />
                  </div>
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>Username</span>
                    <Input
                      placeholder="Enter username"
                      handleChange={handleChangeProxy}
                      value={values.ftpLogin}
                      name="ftpLogin"
                      id="ftpLogin"
                      autoComplete="off"
                      iconRight={values.ftpLogin && 'copy'}
                      handleAction={() => {
                        copyText(values.ftpLogin) && createToast({ type: 'success', text: 'Copied' });
                      }}
                      error={!!errors.ftpLogin}
                      disabled={viewRead}
                    />
                  </div>
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>Password</span>
                    <Input
                      placeholder="Enter password"
                      handleChange={handleChangeProxy}
                      value={values.dbPassword}
                      name="dbPassword"
                      id="dbPassword"
                      type="password"
                      autoComplete="new-password"
                      iconRight={values.dbPassword && 'copy'}
                      handleAction={() => {
                        copyText(values.dbPassword) && createToast({ type: 'success', text: 'Copied' });
                      }}
                      isPassword
                      error={!!errors.dbPassword}
                      disabled={viewRead}
                    />
                  </div>
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>
                        <span>Maximum file size (KB)</span>
                        <ActionText
                          title={(
                            <ActionIcon
                              id="maxFileSize_tooltip"
                              icon="info"
                              style={{ marginLeft: '8px' }}
                              tooltip={{
                                children:  
                                  <span style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column'}}>
                                    The maximum file size (KB) that can be sent via API.
                                  </span>,
                                place: 'right',
                                openOnClick: true,
                                clickable: true,
                              }}
                            />
                          )}
                        />
                    </span>
                    <Input
                      placeholder="1024"
                      handleChange={handleChangeNumberWithLength()}
                      value={values.maximumFileSize}
                      name="maximumFileSize"
                      id="maximumFileSize"
                      disabled={viewRead}
                      error={!!errors.maximumFileSize}
                    />
                    {(!!errors.maximumFileSize) ? (
                      <span className={shared.errorPlaceholder}>{errors.maximumFileSize}</span>
                    ) : null}
                  </div>
                </>
              )}
              {values.dbType === 'apiCall' ? (
                <>
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>URL</span>
                    <Input
                      placeholder="Enter API URL"
                      handleChange={(e)=>handleChangeProxy({target: {name: 'apiUrl', value: e.target.value}})}
                      value={values.apiUrl}
                      name="apiUrl"
                      id="apiUrl"
                      error={!!errors.apiUrl}
                      iconRight={values.apiUrl && 'copy'}
                      handleAction={() => {
                        copyText(transformValueWithVariables(values.apiUrl, variables.data, 'value', true)) && createToast({ type: 'success', text: 'Copied' });
                      }}
                      disabled={viewRead}
                      withVariables
                    />
                  </div>
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>Request method</span>
                    <Select
                      list={[
                        { value: 'get', title: 'GET' },
                        { value: 'post', title: 'POST' },
                        { value: 'put', title: 'PUT' },
                        { value: 'patch', title: 'PATCH' },
                        { value: 'delete', title: 'DELETE' },
                      ]}
                      value={values.apiRequestMethod}
                      handleChange={setFieldValueProxy}
                      name="apiRequestMethod"
                      id="apiRequestMethod"
                      placeholder="Select method"
                      error={!!errors.apiRequestMethod}
                      disabled={viewRead}
                    />
                  </div>
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>Request format</span>
                    <Select
                      list={[
                        { value: 'json', title: 'JSON' },
                        { value: 'form', title: 'Form' }
                      ]}
                      value={values.requestFormat}
                      placeholder="Select format"
                      handleChange={setFieldValueProxy}
                      name="requestFormat"
                      id="requestFormat"
                      disabled={viewRead}
                      error={!!errors.requestFormat}
                    />
                  </div>
                </>
              ) : null}
              {(values.dbType && values.dbType !== 'aws' && values.dbType !== 'corezoid' && 
                  values.dbType !== 'chatGpt' && values.dbType !== 'ftp' && values.dbType !== 'apiCall') ? (
                <>
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>Database name</span>
                    <Input
                      placeholder="Enter database name"
                      handleChange={handleChangeProxy}
                      value={values.dbName}
                      name="dbName"
                      id="dbName"
                      error={!!errors.dbName}
                      disabled={viewRead}
                    />
                  </div>
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>Host</span>
                    <Input
                      placeholder="Enter database host"
                      handleChange={handleChangeProxy}
                      value={values.dbHost}
                      name="dbHost"
                      id="dbHost"
                      error={!!errors.dbHost}
                      disabled={viewRead}
                    />
                  </div>
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>Port</span>
                    <Input
                      placeholder="Enter database port"
                      handleChange={handleChangeProxy}
                      value={values.dbPort}
                      name="dbPort"
                      id="dbPort"
                      error={!!errors.dbPort}
                      disabled={viewRead}
                    />
                  </div>
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>Username</span>
                    <Input
                      placeholder="Enter database username"
                      handleChange={handleChangeProxy}
                      value={values.dbUsername}
                      name="dbUsername"
                      id="dbUsername"
                      autoComplete="off"
                      iconRight={values.dbUsername && 'copy'}
                      handleAction={() => {
                        copyText(values.dbUsername) && createToast({ type: 'success', text: 'Copied' });
                      }}
                      error={!!errors.dbUsername}
                      disabled={viewRead}
                    />
                  </div>
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>Password</span>
                    <Input
                      placeholder="Enter database password"
                      handleChange={handleChangeProxy}
                      value={values.dbPassword}
                      name="dbPassword"
                      id="dbPassword"
                      type="password"
                      autoComplete="new-password"
                      iconRight={values.dbPassword && 'copy'}
                      handleAction={() => {
                        copyText(values.dbPassword) && createToast({ type: 'success', text: 'Copied' });
                      }}
                      isPassword
                      error={!!errors.dbPassword}
                      disabled={viewRead}
                    />
                  </div>
                  {values.dbType === 'mssql' && (
                    <>
                      <div className={shared.inputWrapper}>
                        <span className={shared.inputLabel}>Encrypted connection</span>
                        <Select
                          list={[
                            { value: 'true', title: 'True' },
                            { value: 'false', title: 'False' },
                          ]}
                          value={values.encrypt}
                          handleChange={setFieldValueProxy}
                          name="encrypt"
                          id="encrypt"
                          error={!!errors.encrypt}
                          disabled={viewRead}
                        />
                      </div>
                      <div className={shared.inputWrapper}>
                        <span className={shared.inputLabel}>Trust Server Certificate</span>
                        <Select
                          list={[
                            { value: 'true', title: 'True' },
                            { value: 'false', title: 'False' },
                          ]}
                          value={values.trustServerCertificate}
                          handleChange={setFieldValueProxy}
                          name="trustServerCertificate"
                          id="trustServerCertificate"
                          error={!!errors.trustServerCertificate}
                          disabled={viewRead}
                        />
                      </div>
                    </>
                  )}
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>Maximum connections</span>
                    <Input
                      placeholder="5"
                      handleChange={handleChangeNumberWithLength(10)}
                      value={values.maxConnections}
                      name="maxConnections"
                      id="maxConnections"
                      error={!!errors.maxConnections}
                      disabled={viewRead}
                    />
                  </div>
                </>
              ) : null}
              {(values.dbType && values.dbType !== 'aws' && values.dbType !== 'corezoid' && 
                 values.dbType !== 'chatGpt' && values.dbType !== 'ftp') ? (
                    <div className={shared.inputWrapper}>
                      <span className={shared.inputLabel}>Timeout, seconds</span>
                      <Input
                        placeholder="30"
                        handleChange={handleChangeNumberWithLength(7)}
                        value={values.timeout}
                        name="timeout"
                        id="timeout"
                        error={!!errors.timeout}
                        disabled={viewRead}
                      />
                    </div>
                 ) : null}
              {values.dbType
                && (
                <div className={shared.inputWrapper}>
                  <span className={shared.inputLabel}>Status</span>
                  <Select
                    list={[
                      { value: 'active', title: 'Active' },
                      { value: 'inactive', title: 'Inactive' },
                    ]}
                    value={values.dbStatus}
                    handleChange={setFieldValueProxy}
                    name="dbStatus"
                    id="dbStatus"
                    disabled
                  />
                </div>
                )}
              {(values.dbType && (values.dbType !== 'corezoid' && values.dbType !== 'apiCall')) && (
                <div className={shared.testConnectionBtn} style={{ marginTop: values.dbType !== 'ftp' ? 27 : 0 }}>
                  {renderTestConnectionButton(testStatus)}
                </div>
              )}
              {(values.dbType && values.dbType === 'corezoid' && values.requestType === 'asynchronous') && (
                <>
                  <div className={shared.testConnectionBtn} />
                  <div className={shared.testConnectionBtn} style={{ marginTop: values.requestType === 'asynchronous' ? 27 : 0 }}>
                     {renderTestConnectionButton(testStatus)}
                  </div>
                </>
              )}
            </div>
          </section>
          {values.dbType === 'apiCall' ? (
            <>
              <hr />
              <section className={shared.section}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <h4 className={shared.sectionHeader}>Request Parameters</h4>
                  <ActionText title="Add parameter" onClick={addRequestParameter} />
                </div>
                {values?.requestParams?.length > 0 && (
                  <div className={cn(shared.sectionContainer, shared.tripleAlternate)} style={{ marginBottom: '-20px' }}>
                    <div className={shared.inputWrapper}>
                      <span className={shared.inputLabel}>Key</span>
                    </div>
                    <div className={shared.inputWrapper}>
                      <span className={shared.inputLabel}>Value</span>
                    </div>
                    <div className={shared.inputWrapper}>
                      <span className={shared.inputLabel}>Description</span>
                    </div>
                    <div className={shared.inputWrapper}>
                      <span className={shared.inputLabel}>Required</span>
                    </div>
                    <div className={cn(shared.inputWrapper, shared.btn)} />
                  </div>
                )}
                {values.requestParams && (
                  <DndProvider backend={HTML5Backend}>
                    <RequestParameterRows
                      params={values.requestParams}
                      errors={errors}
                      viewRead={false}
                      modelKey="requestParams"
                      handleUpdate={(key, value) => {
                        setFieldValue(key, value);
                        setIsChange(true);
                      }}
                    />
                  </DndProvider>
                )}
                <hr />
              </section>
              <section className={shared.section} style={{ marginBottom: 40 }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <h4 className={shared.sectionHeader}>Headers</h4>
                  <ActionText title="Add parameter" onClick={addHeaderParameter} />
                </div>
                {values?.headerParams?.length > 0 && (
                  <div className={cn(shared.sectionContainer, shared.tripleAlternate)} style={{ marginBottom: '-20px' }}>
                    <div className={shared.inputWrapper}>
                      <span className={shared.inputLabel}>Key</span>
                    </div>
                    <div className={shared.inputWrapper}>
                      <span className={shared.inputLabel}>Value</span>
                    </div>
                    <div className={shared.inputWrapper}>
                      <span className={shared.inputLabel}>Description</span>
                    </div>
                    <div className={shared.inputWrapper}>
                      <span className={shared.inputLabel}>Required</span>
                    </div>
                    <div className={cn(shared.inputWrapper, shared.btn)} />
                  </div>
                )}
                {values.headerParams && (
                  <DndProvider backend={HTML5Backend}>
                    <RequestParameterRows
                      params={values.headerParams}
                      errors={errors}
                      viewRead={viewRead}
                      modelKey="headerParams"
                      handleUpdate={(key, value) => {
                        setFieldValue(key, value);
                        setIsChange(true);
                      }}
                    />
                  </DndProvider>
                )}
              </section>
            </>
          ) : null}
        </div>
        <div className={cn(shared.sidepanel, shared.compactLayout)}>
          <div className={shared.sidepanelGroup}>
          <div className={shared.sidepanelHeading}>
          <div className={shared.subHeaders}>
                {authVisible ? (
                    <h4
                      onClick={() => setSubroute(SUBROUTING.AUTHORIZATION)}
                      className={cn(
                        shared.sectionHeader,
                        { [shared.clickable]: true },
                        { [shared.passive]: subroute !== SUBROUTING.AUTHORIZATION },
                      )}
                    >
                      Auth
                    </h4>
                  ) : null}
              <h4
                onClick={() => setSubroute(SUBROUTING.LIMITS)}
                className={
                  cn(shared.sectionHeader, 
                    { [shared.clickable]: true }, 
                    { [shared.passive]: subroute !== SUBROUTING.LIMITS }
                  )}
              >
                Limits
              </h4>
              <h4
                onClick={() => setSubroute(SUBROUTING.ACCESS)}
                className={
                  cn(shared.sectionHeader,
                    { [shared.clickable]: true },
                    { [shared.passive]: subroute !== SUBROUTING.ACCESS }
                  )}
              >
                Access
              </h4>
              {subroute === SUBROUTING.ACCESS && !isDeleted && isRoleAction({ key: 'connection', role: 'share', modelRole: activeConnection?.role })
                && (
                  <div className={shared.textAction}>
                    <ActionText title="Share access" onClick={addUserOfApiKey} />
                  </div>
                )}
            </div>
          </div>
            {subroute === SUBROUTING.AUTHORIZATION && (
                <div className={shared.sidepanelForm} id="sidepanelFormId">
                  <section className={shared.limitSection}>
                    <div className={shared.inputWrapper}>
                      <span className={shared.inputLabel}>Authorization method</span>
                      <Select
                        list={[
                          { value: 'basic', title: 'Basic' },
                          { value: 'APIKey', title: 'API Key' },
                          { value: 'bearer', title: 'Bearer Token' },
                          { value: 'jwt', title: 'JWT' },
                          { value: 'none', title: 'None' },
                        ]}
                        value={values.authorizationMethod}
                        handleChange={setFieldValueProxy}
                        placeholder="Select authorization method"
                        name="authorizationMethod"
                        id="authorizationMethod"
                        error={!!errors.authorizationMethod}
                        disabled={viewRead}
                      />
                    </div>
                    {values.authorizationMethod === 'basic' ? (
                      <>
                        <div className={shared.inputWrapper}>
                          <span className={shared.inputLabel}>Login</span>
                          <Input
                            placeholder="Enter login"
                            handleChange={(e)=>handleChangeProxy({target: {name: 'authBasicLogin', value: e.target.value}})}
                            value={values.authBasicLogin}
                            iconRight={values.authBasicLogin && 'copy'}
                            handleAction={() => {
                              copyText(transformValueWithVariables(values.authBasicLogin, variables.data, 'value', true)) && createToast({ type: 'success', text: 'Copied' });
                            }}
                            name="authBasicLogin"
                            id="authBasicLogin"
                            autoComplete="none"
                            error={!!errors.authBasicLogin}
                            disabled={viewRead}
                            withVariables
                          />
                        </div>
                        <div className={shared.inputWrapper}>
                          <span className={shared.inputLabel}>Password</span>
                          <Input
                            placeholder="Enter password"
                            handleChange={(e)=>handleChangeProxy({target: {name: 'authBasicPassword', value: e.target.value}})}
                            value={values.authBasicPassword}
                            iconRight={values.authBasicPassword && 'copy'}
                            handleAction={() => {
                              copyText(transformValueWithVariables(values.authBasicPassword, variables.data, 'value', true)) && createToast({ type: 'success', text: 'Copied' });
                            }}
                            name="authBasicPassword"
                            id="authBasicPassword"
                            autoComplete="none"
                            error={!!errors.authBasicPassword}
                            disabled={viewRead}
                            withVariables
                          />
                        </div>
                      </>
                    ) : null}
                    {values.authorizationMethod === 'APIKey' ? (
                      <>
                        <div className={shared.inputWrapper}>
                          <span className={shared.inputLabel}>Key</span>
                          <Input
                            placeholder="Enter key"
                            handleChange={(e)=>handleChangeProxy({target: {name: 'authAPIKeyHeaderName', value: e.target.value}})}
                            value={values.authAPIKeyHeaderName}
                            iconRight={values.authAPIKeyHeaderName && 'copy'}
                            handleAction={() => {
                              copyText(transformValueWithVariables(values.authAPIKeyHeaderName, variables.data, 'value', true)) && createToast({ type: 'success', text: 'Copied' });
                            }}
                            name="authAPIKeyHeaderName"
                            id="authAPIKeyHeaderName"
                            autoComplete="none"
                            error={!!errors.authAPIKeyHeaderName}
                            disabled={viewRead}
                            withVariables
                          />
                        </div>
                        <div className={shared.inputWrapper}>
                          <span className={shared.inputLabel}>Value</span>
                          <Input
                            placeholder="Enter value"
                            handleChange={(e)=>handleChangeProxy({target: {name: 'authAPIKeyToken', value: e.target.value}})}
                            value={values.authAPIKeyToken}
                            iconRight={values.authAPIKeyToken && 'copy'}
                            handleAction={() => {
                              copyText(transformValueWithVariables(values.authAPIKeyToken, variables.data, 'value', true)) && createToast({ type: 'success', text: 'Copied' });
                            }}
                            name="authAPIKeyToken"
                            id="authAPIKeyToken"
                            autoComplete="none"
                            error={!!errors.authAPIKeyToken}
                            disabled={viewRead}
                            withVariables
                          />
                        </div>
                        <div className={shared.inputWrapper}>
                          <span className={shared.inputLabel}>Add API Key to</span>
                          <Select
                            list={addToParamApiKeys}
                            value={values.authAPIKeyAddTo}
                            handleChange={setFieldValueProxy}
                            placeholder="Select where to place key"
                            name="authAPIKeyAddTo"
                            id="authAPIKeyAddTo"
                            error={!!errors.authAPIKeyAddTo}
                            disabled={viewRead}
                          />
                        </div>
                      </>
                    ) : null}
                    {values.authorizationMethod === 'bearer' ? (
                        <div className={shared.inputWrapper}>
                          <span className={shared.inputLabel}>Token</span>
                          <Input
                            placeholder="Enter token"
                            handleChange={(e)=>handleChangeProxy({target: {name: 'authBearerToken', value: e.target.value}})}
                            value={values.authBearerToken}
                            iconRight={values.authBearerToken && 'copy'}
                            handleAction={() => {
                              copyText(transformValueWithVariables(values.authBearerToken, variables.data, 'value', true)) && createToast({ type: 'success', text: 'Copied' });
                            }}
                            name="authBearerToken"
                            id="authBearerToken"
                            autoComplete="none"
                            error={!!errors.authBearerToken}
                            disabled={viewRead}
                            withVariables
                          />
                        </div>
                    ) : null}
                    {values.authorizationMethod === 'jwt' ? (
                        <div className={shared.inputWrapper}>
                          <span className={shared.inputLabel}>JWT Type</span>
                          <Select
                            list={[
                              { value: 'JWT', title: 'Client-side' },
                              { value: 'serverJWT', title: 'Server-side' },
                            ]}
                            value={values.authJwtType}
                            handleChange={setFieldValueProxy}
                            placeholder="Select JWT type"
                            name="authJwtType"
                            id="authJwtType"
                            error={!!errors.authJwtType}
                            disabled={viewRead}
                          />
                        </div>
                    ) : null}
                    {values.authorizationMethod === 'jwt' && values.authJwtType === 'serverJWT' ? (
                        <>
                          <div className={shared.inputWrapper}>
                            <span className={shared.inputLabel}>Authorization URL</span>
                            <Input
                              placeholder="https://..."
                              handleChange={(e)=>handleChangeProxy({target: {name: 'authJwtServerAuthUrl', value: e.target.value}})}
                              value={values.authJwtServerAuthUrl}
                              iconRight={values.authJwtServerAuthUrl && 'copy'}
                              handleAction={() => {
                                copyText(transformValueWithVariables(values.authJwtServerAuthUrl, variables.data, 'value', true)) && createToast({ type: 'success', text: 'Copied' });
                              }}
                              name="authJwtServerAuthUrl"
                              id="authJwtServerAuthUrl"
                              autoComplete="none"
                              error={!!errors.authJwtServerAuthUrl}
                              disabled={viewRead}
                              withVariables
                            />
                          </div>
                          <div className={shared.inputWrapper}>
                          <span className={shared.inputLabel}>Payload</span>
                            <MemoizedCodeEditor
                                  value={values.authJwtServerPayload}
                                  handleChange={(value) => setFieldValueProxy('authJwtServerPayload', value)}
                                  name="authJwtServerPayload"
                                  id="authJwtServerPayload"
                                  type={/^"{0,1}\{\{env\..*?\}\}"{0,1}$/g.test(values.authJwtServerPayload) ? 'string' : 'json'}
                                  placeholder="{}"
                                  error={!!errors.authJwtServerPayload}
                                  height={104}
                                  readOnly={viewRead}
                            />
                        </div>
                        </>
                    ) : null}
                    {values.authorizationMethod === 'jwt' && values.authJwtType === 'JWT' ? (
                        <>
                          <div className={shared.inputWrapper}>
                            <span className={shared.inputLabel}>Add JWT to</span>
                            <Select
                              list={addToParamJWT}
                              value={values.authJwtClientAddTo}
                              handleChange={setFieldValueProxy}
                              placeholder="Select where to add JWT"
                              name="authJwtClientAddTo"
                              id="authJwtClientAddTo"
                              error={!!errors.authJwtClientAddTo}
                              disabled={viewRead}
                            />
                          </div>
                          <div className={shared.inputWrapper}>
                            <span className={shared.inputLabel}>Algorithm</span>
                            <Select
                              list={algorithms}
                              value={values.authJwtClientAlgorithm}
                              handleChange={setFieldValueProxy}
                              placeholder="Select algorithm"
                              name="authJwtClientAlgorithm"
                              id="authJwtClientAlgorithm"
                              error={!!errors.authJwtClientAlgorithm}
                              disabled={viewRead}
                            />
                          </div>
                          <div className={shared.inputWrapper}>
                              <span className={shared.inputLabel}>
                                {(values.authJwtClientAlgorithm || '').startsWith('HS') ? (
                                    'Secret Key'
                                  ) : (
                                    'Private Key'
                                )}
                              </span>
                              {(values.authJwtClientAlgorithm || '').startsWith('HS') ? (
                                  <Input
                                      placeholder="Paste Secret Key"
                                      handleChange={(e)=>handleChangeProxy({target: {name: 'authJwtClientPrivateKey', value: e.target.value}})}
                                      value={values.authJwtClientPrivateKey}
                                      iconRight={values.authJwtClientPrivateKey && 'copy'}
                                      handleAction={() => {
                                        copyText(transformValueWithVariables(values.authJwtClientPrivateKey, variables.data, 'value', true)) && createToast({ type: 'success', text: 'Copied' });
                                      }}
                                      name="authJwtClientPrivateKey"
                                      id="authJwtClientPrivateKey"
                                      autoComplete="none"
                                      error={!!errors.authJwtClientPrivateKey}
                                      disabled={viewRead}
                                      withVariables
                                  />
                                ) : (
                                <MemoizedCodeEditor
                                  placeholder={`- - BEGIN PRIVATE KEY - - \nprivate key in PKCS#8 PEM format\n- - END PRIVATE KEY - - `}
                                  handleChange={(value) => setFieldValueProxy('authJwtClientPrivateKey', value)}
                                  value={values.authJwtClientPrivateKey}
                                  name="authJwtClientPrivateKey"
                                  id="authJwtClientPrivateKey"
                                  type="text"
                                  error={!!errors.authJwtClientPrivateKey}
                                  height={104}
                                  readOnly={viewRead}
                                />
                                )}
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                              <Checkbox
                                name="authJwtClientKeyBase64Encoded"
                                handleChange={(val) => setFieldValueProxy('authJwtClientKeyBase64Encoded', val)}
                                size="small"
                                value={values.authJwtClientKeyBase64Encoded}
                                style={{ marginRight: '0' }}
                                label="Key Base64 encoded"
                                disabled={viewRead}
                              />
                        </div>
                        <div className={shared.inputWrapper}>
                              <span className={shared.inputLabel}>Payload</span>
                              <MemoizedCodeEditor
                                value={values.authJwtClientPayload}
                                handleChange={(value) => setFieldValueProxy('authJwtClientPayload', value)}
                                name="authJwtClientPayload"
                                id="authJwtClientPayload"
                                placeholder="{}"
                                type={/^"{0,1}\{\{env\..*?\}\}"{0,1}$/g.test(values.authJwtClientPayload) ? 'string' : 'json'}
                                height={104}
                                readOnly={viewRead}
                              />
                        </div>
                        <Collapsible id="advancedSettings" title="Advanced settings">
                          <>
                            <div className={shared.inputWrapper}>
                              <span className={shared.inputLabel}>
                              {getHeaderTitleLabel(values.authJwtClientAddTo)}
                              </span>
                              <Input
                                placeholder={getHeaderTitlePlaceholder(values.authJwtClientAddTo)}
                                handleChange={(e)=>handleChangeProxy({target: {name: 'authJwtClientHeaderPrefix', value: e.target.value}})}
                                value={values.authJwtClientHeaderPrefix}
                                iconRight={values.authJwtClientHeaderPrefix && 'copy'}
                                handleAction={() => {
                                  copyText(transformValueWithVariables(values.authJwtClientHeaderPrefix, variables.data, 'value', true)) && createToast({ type: 'success', text: 'Copied' });
                                }}
                                name="authJwtClientHeaderPrefix"
                                id="authJwtClientHeaderPrefix"
                                autoComplete="none"
                                error={!!errors.authJwtClientHeaderPrefix}
                                disabled={viewRead}
                                withVariables
                              />
                            </div>
                            <div className={shared.inputWrapper}>
                              <span className={shared.inputLabel}>JWT headers</span>
                              <MemoizedCodeEditor
                                value={values.authJwtClientHeaders}
                                handleChange={(value) => setFieldValueProxy('authJwtClientHeaders', value)}
                                name="authJwtClientHeaders"
                                id="authJwtClientHeaders"
                                type={/^"{0,1}\{\{env\..*?\}\}"{0,1}$/g.test(values.authJwtClientHeaders) ? 'string' : 'json'}
                                placeholder="{}"
                                error={!!errors.authJwtClientHeaders}
                                height={104}
                                readOnly={viewRead}
                              />
                            </div>
                          </>
                        </Collapsible>
                        </>
                    ) : null}
                  </section>
                </div>
            )}
            {subroute === SUBROUTING.LIMITS && (
              <div className={shared.sidepanelForm}>
                  <div className={cn(shared.limitSection)}>
                    <div className={shared.inputWrapper}>
                      <span className={cn(shared.inputLabel, shared.spanWrapper)}>Requests / second (RPS)</span>
                      <Input
                        maxLength={15}
                        placeholder={placeholders.RPS}
                        handleChange={handleChangeNumber}
                        onBlur={handleBlurLimits}
                        value={values.RPS}
                        name="RPS"
                        id="RPS"
                        disabled={viewRead}
                        error={!!errors.RPS}
                      />
                      {(!!errors.RPS) ? (
                      <span className={shared.errorPlaceholder}>{errors.RPS}</span>
                     ) : null}
                    </div>

                    <div className={shared.inputWrapper}>
                      <span className={cn(
                        shared.inputLabel, shared.spanWrapper
                        )}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          flexDirection: 'row',
                          whiteSpace: 'nowrap',
                          overflow: 'visible',
                        }}
                        >
                          <span style={{ 
                            display: 'flex',
                            alignItems: 'center'
                          }}>
                          <span>Requests / month (RPM)</span>
                          {values.activeRpmType === 'cmon' ? (
                            <ActionText
                              title={
                                <ActionIcon
                                  id="rpmonth_tooltip1-cmon"
                                  icon="info"
                                  style={{ marginLeft: '8px' }}
                                  tooltip={{
                                    children: (
                                      <div style={{ textAlign: 'left' }}>
                                        The limit is checked by counting requests <br />
                                        starting from the <span style={{ fontWeight: 600 }}>first day of the month</span>. <br />
                                        <span style={{ fontStyle: 'italic'}}>
                                        Example: <br />
                                        Today is May 10th, 09:30 AM. < br />
                                        When a new request is received, requests will <br />
                                        be counted in the period from May 1, 12:00 AM <br />
                                        to the current moment.
                                        </span>

                                      </div>
                                    ),
                                    place: 'top',
                                    openOnClick: false,
                                    clickable: false,
                                  }}
                                />
                              }
                          />
                         ) : 
                         (
                          <ActionText
                          title={
                            <ActionIcon
                              id="rpmonth_tooltip1-mon"
                              icon="info"
                              style={{ marginLeft: '8px' }}
                              tooltip={{
                                children: (
                                  <div style={{ textAlign: 'left' }}>
                                    The limit is checked by counting requests <br/>
                                    over the <span style={{ fontWeight: 600}}>last 30 days</span>. <br />
                                    <span style={{ fontStyle: 'italic'}}>
                                    Example: <br />
                                    Today is May 10th, 09:30 AM. < br />
                                    When a new request is received, requests will <br />
                                    
                                     be counted in the period from April 10, 09:30 AM <br />
                                     to the current moment.
                                    </span>
                                  </div>
                                ),
                                place: 'top',
                                openOnClick: false,
                                clickable: false,
                              }}
                            />
                          }
                      />
                         )}
                          </span>
                          <span>
                            <Select
                            type="textColor"
                            list={[
                              { value: 'mon', title: 'Sliding window' },
                              { value: 'cmon', title: 'Calendar' },
                            ]}
                            value={values.activeRpmType}
                            handleChange={(key, value) => {
                              setFieldValueSkipCheck(key, value);
                            }}
                            name="activeRpmType"
                          />
                          </span>
                      </span>
                      {values.activeRpmType === 'mon' ? (
                        <>
                          <Input
                            maxLength={15}
                            placeholder={placeholders.RPMONTH}
                            handleChange={handleChangeNumber}
                            onBlur={handleBlurLimits}
                            value={values.RPMONTH}
                            name="RPMONTH"
                            disabled={viewRead}
                            error={!!errors.RPMONTH}
                          />
                          {(!!errors.RPMONTH) ? (
                            <span className={shared.errorPlaceholder}>{errors.RPMONTH}</span>
                          ) : null}
                        </>
                      ) : (
                        <>
                        <Input
                          maxLength={15}
                          placeholder={placeholders.RPMONTHCALENDAR}
                          handleChange={handleChangeNumber}
                          onBlur={handleBlurLimits}
                          value={values.RPMONTHCALENDAR}
                          name="RPMONTHCALENDAR"
                          disabled={viewRead}
                          error={!!errors.RPMONTHCALENDAR}
                        />
                        {(!!errors.RPMONTHCALENDAR) ? (
                            <span className={shared.errorPlaceholder}>{errors.RPMONTHCALENDAR}</span>
                          ) : null}
                        </>
                      )}
                    </div>
                  </div>
              </div>
            )}
            {subroute === SUBROUTING.ACCESS && (
              <div className={shared.sidepanelTable}>
                <div className={shared.sidepanelTableHeading}>
                  <div className={shared.lineHeader}>
                    <div>Name</div>
                    <div />
                    <div>Role</div>
                  </div>
                </div>
                <div className={shared.sidepanelTableBodyContainer}>
                    <ul className={shared.sidepanelTableBody}>
                      {inviteList?.map((item) => (
                        <li className={shared.historyLine} key={item.email}>
                          <div title={item.email}>
                            {item.email === currentUser.email ? (
                              <span>{`${item.firstName} ${item.lastName}`}</span>
                            ) : item.firstName || item.lastName ? (
                              <span>{`${item.firstName} ${item.lastName} (${item.email})`}</span>
                            ) : (
                              <span>{item.email}</span>
                            )}
                          </div>
                          <div />
                          <div>
                            {viewRead ? (
                              <ChipsStatus title={listRolesName[item.role] || item.role} type="input" style={{ width: '90px', justifyContent: 'left', paddingLeft: '12px' }} />
                            ) : item.role === 'owner' ? (
                              <ChipsStatus title="Owner" type="input" style={{ width: '90px', justifyContent: 'left', paddingLeft: '12px' }} />
                            ) : (
                              <Select
                                style={{ width: 90 }}
                                list={getRoles(item.role)}
                                value={item.role}
                                placeholder={item.role}
                                handleChange={(email, value) => handeChangeRole(item, value)}
                                name={item.email}
                              />
                            )}
                          </div>
                        </li>
                      ))}
                    </ul>
                </div>

              </div>
            )}
          </div>
        </div>
        {shareModalOpened && (
          <InviteToResourceModal
            id={resourceId}
            onClose={() => setShareModalOpened(false)}
            onSubmit={saveInviteList}
            model={values}
            type="connection"
            inviteList={inviteList?.map((i) => i.email) || []}
          />
        )}
        {removeModalOpened && (
          <RemoveAccessModal
            setIsOpen={setRemoveModalOpened}
            deleteAccessUser={deleteAccess}
            user={activeUser}
            resource="connection"
            id={resourceId}
          />
        )}
      </div>
      <ConnectedAPI list={connectedEndpoints} activeProject={activeProject} activeConnection={values} hideWithoutList />
    </div>
  );
}

const mapStateToProps = ({
  activeConnection,
  auth: { currentUser },
  directory,
  activeProject,
  roles,
}) => ({
  roles,
  activeConnection: activeConnection?.activeConnection,
  connectedEndpoints: activeConnection?.connectedEndpoints,
  storedInviteList: activeConnection?.inviteList,
  activeProject,
  currentUser,
  connectionTypes: directory?.connectionTypes,
  JWTAlgorithm: directory?.JWTAlgorithm,
  connectLimit: directory?.connectLimit || 100,
});

const mapDispatchToProps = (dispatch) => ({
  updateConnection: (data, callback) => dispatch(updateConnectionRequest(data, callback)),
  getActiveConnection: (data) => dispatch(activeConnectionRequest(data)),
  getConnectedEndpoints: (data) => dispatch(getConnectedEndpointsRequest(data)),
  testConnection: (data, callback) => dispatch(testActiveConnectionRequest(data, callback)),
  getAccessUsers: (data) => dispatch(getAccessUsersRequest(data)),
  addAccess: (data, callback) => dispatch(addAccessRequest(data, callback)),
  createToast: (data, callback) => dispatch(createToastRequest(data, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(isRoleAction(ConnectionModify));

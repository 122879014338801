@use "sass:map";
@import '../../styles/variables.scss';

.toastsContainer {
    position: fixed;
    display: flex;
    flex-direction: column;
    gap: 8px;
    top: 18px;
    left: 50%;
    transform: translateX(-50%);
    max-height: 100vh;
    max-width: 480px;
    width: 100%;
    z-index: 99999;
    box-sizing: border-box;
}

.toast {
    box-sizing: border-box;
    width: 100%;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 12px 9px 12px;
    border: 1px solid map.get($colors-green, 100);
    background-color: map.get($colors-green, 10);

    .group {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 12px;

        svg {
            min-width: 16px;
        }

        .groupContent {
            margin-right: 12px;
            white-space: pre-wrap;
            word-break: break-word;
            overflow-wrap: break-word;
        }
    }

    .iconWrapper {
        align-self: flex-start;
        justify-content: center;
        align-items: center;
        display: flex;
        min-height: 24px;
        min-width: 24px;
    }

    &.error {
        border: 1px solid map.get($colors-red, 100);
        background-color: map.get($colors-red, 10);
    }

    .title {
        font-weight: 600;
    }

}
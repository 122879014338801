import EditIcon from '../../pictures/edit.svg';
import CopyIcon from '../../pictures/copy.svg';
import ShareIcon from '../../pictures/add-user.svg';
import ExportIcon from '../../pictures/export.svg';
import ImportIcon from '../../pictures/import.svg';
import DeleteIcon from '../../pictures/trash.svg';

export const contextConfig = [
  {
    id: 'edit',
    title: 'Edit',
    onClick: () => null,
    hasDivider: false,
    foldersSupport: true,
    itemSupport: true,
    Icon: EditIcon,
  },
  {
    id: 'copy',
    title: 'Copy',
    onClick: () => null,
    hasDivider: false,
    foldersSupport: true,
    itemSupport: true,
    Icon: CopyIcon,
  },
  {
    id: 'share',
    title: 'Share',
    onClick: () => null,
    hasDivider: false,
    foldersSupport: true,
    itemSupport: true,
    Icon: ShareIcon,
  },
  {
    id: 'export',
    title: 'Export',
    onClick: () => null,
    hasDivider: false,
    foldersSupport: true,
    itemSupport: true,
    Icon: ExportIcon,
  },
  {
    id: 'delete',
    title: 'Delete',
    onClick: () => null,
    hasDivider: true,
    foldersSupport: true,
    itemSupport: true,
    Icon: DeleteIcon,
  },
];

export const projectContextConfig = [
  {
    id: 'edit',
    title: 'Edit',
    onClick: () => null,
    hasDivider: false,
    foldersSupport: true,
    itemSupport: true,
    Icon: EditIcon,
  },

  {
    id: 'share',
    title: 'Share',
    onClick: () => null,
    hasDivider: false,
    foldersSupport: true,
    itemSupport: true,
    Icon: ShareIcon,
  },
  // {
  //   id: 'open',
  //   title: 'Jump into',
  //   onClick: () => null,
  //   hasDivider: false,
  //   foldersSupport: false,
  //   itemSupport: true,
  //   Icon: ImportIcon,
  // },
  // {
  //   id: 'delete',
  //   title: 'Delete',
  //   onClick: () => null,
  //   hasDivider: true,
  //   foldersSupport: true,
  //   itemSupport: true,
  //   Icon: DeleteIcon,
  // },
];

export const projectsHeightPixels = projectContextConfig.reduce((acc, val) => (val.itemSupport ? acc + 36 : acc), 0);
export const itemsHeightPixels = contextConfig.reduce((acc, val) => (val.itemSupport ? acc + 36 : acc), 0);
export const folderHeightPixels = contextConfig.reduce((acc, val) => (val.foldersSupport ? acc + 36 : acc), 0);
export const itemsWidthPixels = 118.8;

export const prepareConfig = (actions, callbacks, supportKey) => actions.reduce((acc, action) => {
  if (action[supportKey]) {
    return [...acc, { ...action, onClick: callbacks[action.id] || action.onClick }];
  }
  return acc;
}, []);

/* eslint-disable max-len */
import { Projects } from './constants';

export const listProjectsRequest = (data, callback) => ({ type: Projects.listProjectsRequest, data, callback });
export const listProjectsSuccess = (data) => ({ type: Projects.listProjectsSuccess, data });
export const listProjectsError = () => ({ type: Projects.listProjectsError });

export const createProjectRequest = (data, callback) => ({ type: Projects.createProjectRequest, data, callback });
export const createProjectSuccess = (data) => ({ type: Projects.createProjectSuccess, data });
export const createProjectError = () => ({ type: Projects.createProjectError });

export const removeProjectRequest = (data, callback) => ({ type: Projects.removeProjectRequest, data, callback });
export const removeProjectSuccess = (data) => ({ type: Projects.removeProjectSuccess, data });
export const removeProjectError = () => ({ type: Projects.removeProjectError });

/* eslint-disable react/forbid-prop-types */
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Search from '../../pictures/search.svg';
import Close from '../../pictures/close.svg';
import Copy from '../../pictures/copy.svg';
import Eye from '../../pictures/eye.svg';
import EyeNot from '../../pictures/eyeNot.svg';
import * as style from './Input.module.scss';
import InputWithVariables from '../InputWithVariables/InputWithVariables';

const iconEnum = {
  search: Search,
  close: Close,
  copy: Copy,
};

const rightType = {
  action: 'action',
  status: 'status',
};


function Input(props) {
  const {
    isPassword,
    name,
    value,
    type = 'text',
    handleAction,
    handleChange,
    onBlur = () => {},
    handleKeyDown = () => {},
    placeholder,
    iconRight,
    iconRightType = rightType.action,
    iconLeft,
    disabled,
    style: customStyle = {},
    size,
    error,
    id,
    autoFocus = false,
    multiline,
    autoComplete,
    maxLength,
    realPassword,
    withVariables = false,
  } = props;
  const getRightIcon = (icon) => {
    if (icon && typeof icon === 'string') return iconEnum[icon];
    if (icon && typeof icon !== 'string') return icon;
    return null;
  };
  const [showPassword, setShowPassword] = useState(false);
  const RightIcon = getRightIcon(iconRight);
  const PasswordTrue = showPassword ? EyeNot : Eye;
  const LeftIcon = iconLeft && iconEnum[iconLeft];
  const InputElement = useMemo(() => {
    if (withVariables) {
      return (
        <InputWithVariables 
          placeholder={placeholder} 
          value={value} 
          handleChange={handleChange}
          error={error}
          iconRight={iconRight}
        />
      );
    }
  
    if (multiline) {
      return (
        <textarea
          rows={5}
          id={id}
          key={name}
          style={{
            resize: 'none',
            ...customStyle,
          }}
          className={cn(style.input, {
            [style.leftPadding]: !!iconLeft,
            [style.rightPadding]: !!iconRight,
            [style.filled]: !!value,
            [style.error]: error,
          })}
          placeholder={placeholder || ''}
          value={value}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          onBlur={onBlur}
          name={name}
          disabled={disabled}
          maxLength={maxLength}
        />
      );
    }
    return (
      <input
        id={id}
        key={name}
        style={
          isPassword
            ? {
                ...customStyle,
                paddingRight: isPassword && RightIcon ? '68px' : '36px',
              }
            : {
                ...customStyle,
              }
        }
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        className={cn(style.input, {
          [style.leftPadding]: !!iconLeft,
          [style.rightPadding]: !!iconRight,
          [style.filled]: !!value,
          [style.error]: error,
          [style.fakePassword]: !showPassword && !realPassword && type === 'password',
        })}
        type={
          showPassword && type === 'password' ? 'text' : realPassword ? type : type === 'number' ? 'number' : 'text'
        }
        placeholder={placeholder || ''}
        value={value}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onBlur={onBlur}
        name={name}
        disabled={disabled}
        maxLength={maxLength}
      />
    );
  }, [props, showPassword, realPassword, RightIcon, LeftIcon, ]);

  return (
    <div className={
      cn(style.inputWrapper, {
        [style.inputVariables]: withVariables,
      })
    }>
      {InputElement}
      {iconLeft ? (
        <div className={style.iconLeft}>
          <LeftIcon />
        </div>
      ) : null}
      {isPassword ? (
        <div
          className={style.isPassword}
          style={{ right: RightIcon ? '41px' : '11px' }}
          role="button"
          onClick={() => setShowPassword(!showPassword)}
        >
          <PasswordTrue />
        </div>
      ) : null}
      {(iconRight && iconRightType === rightType.action) ? (
        <div
          className={cn(style.iconRight, {
            [style.iconRightMultiline]: multiline,
          })}
          role="button"
          onClick={handleAction}
          onKeyDown={handleAction}
        >
          <RightIcon />
        </div>
      ) : null}
      {(iconRight && iconRightType === rightType.status) ? (
        <div className={style.iconRightStatus}>
          <RightIcon />
        </div>
      ) : null}
    </div>
  );
}

Input.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  handleKeyDown: PropTypes.func,
  handleChange: PropTypes.func.isRequired,
  handleAction: PropTypes.func,
  style: PropTypes.object,
  placeholder: PropTypes.string,
  iconRight: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
  iconLeft: PropTypes.string,
  size: PropTypes.string,
  error: PropTypes.bool,
  maxLength: PropTypes.number,
};

export default Input;

/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { connect } from 'react-redux';
import debounce from 'lodash.debounce';
import * as Yup from 'yup';
import cn from 'classnames';
import ValidationErrorList from '../../components/ValidationErrorList/ValidationErrorList';
import ActionText from '../../components/ActionText/ActionText';
import { updateSearchProjectRequest } from '../../store/searchProjects/actions';
import { 
  activeSearchProjectRequest, 
  getConnectedConnectionsToProjectRequest,
  getConnectedEndpointsToProjectRequest
 } from '../../store/activeSearchProject/actions';
 import {
   listRolesName,
} from '../../helpers/const';
import { getProjectRoles } from '../../helpers/utils';
import { getAccessUsersRequest } from '../../store/users/actions';
import { addAccessRequest } from '../../store/invites/actions';
import { createToastRequest } from '../../store/toasts/actions';
import RemoveAccessModal from '../../components/RemoveAccessModal/RemoveAccessModal';
import Button from '../../components/Button/Button';
import ChipsStatus from '../../components/ChipsStatus/ChipsStatus';
import Select from '../../components/Select/Select';
import InviteToResourceModal from '../../components/InviteToResourceModal/InviteToResourceModal';
import { isRoleAction } from '../../helpers/hocs';
import Input from '../../components/Input/Input';

import * as shared from '../../styles/shared.module.scss';

const SUBROUTING = {
  ACCESS: 'access',
};

const subrouteMapping = {};

const validationRegex = /^(?!\s)(?!.* {2})/;

const ProjectModifySchema = Yup.object().shape({
  name: Yup.string()
    .required('Project Name is required')
    .matches(
      validationRegex,
      'Project Name is required',
    )
    .min(2, 'Project Name min length 2')
    .max(64),
  shortName: Yup.string().required('shortName required'),
});

function ProjectModify({ 
  updateProject, 
  getActiveProject,
  activeProject,
  addAccess,
  currentUser,
  roles,
  endpointsList,
  connectionsList,
  getConnectedConnections,
  getConnectedEndpoints,
  storedInviteList,
  isRoleAction,
  getAccessUsers
 }) {
  const [submitTriggered, setSubmitTriggered] = useState(false);
  const [isChange, setIsChange] = useState(false);
  const [inviteList, setInviteList] = useState([]);
  const [shareModalOpened, setShareModalOpened] = useState(false);
  const [removeModalOpened, setRemoveModalOpened] = useState(false);
  const [activeUser, setActiveUser] = useState({});
  const [subroute, setSubroute] = useState(SUBROUTING.ACCESS);
  const { state } = useLocation();
  const navigate = useNavigate();
  const { secure } = state || {};
  const { projectId } = useParams();
  const viewRead = !isRoleAction({ key: 'project', role: 'update', modelRole: activeProject?.role });

  const countEndpoints = endpointsList ? endpointsList.length : 0; 
  const countConnections = connectionsList ? connectionsList.length : 0; 

  useEffect(() => {
    getActiveProject({ id: projectId });
  }, [projectId]);

  useEffect(() => {
    getAccessUsers({
      id: +projectId,
      resource: 'project',
    });
  }, []);

  useEffect(() => {
    if (currentUser) {
      setInviteList([{
        userFirstName: currentUser.firstName,
        userLastName: currentUser.lastName,
        userEmail: currentUser.email,
        roleId: 2,
      }]);
    }
  }, [currentUser]);

  useEffect(() => {
    if (activeProject && activeProject?.id === Number(projectId) && activeProject.deleted === false) {
      getConnectedEndpoints({id: Number(projectId) });
      getConnectedConnections({id: Number(projectId) })
    }
  }, [activeProject]);

  const secureGoBack = () => {
    if (secure) navigate(-1);
    if (!secure) navigate('/');
  };

  const saveInviteList = (data) => {
    const filtered = data.filter((el) => el.id);
    const unique = Object.values(
      [...inviteList, ...filtered].reduce((acc, obj) => ({ ...acc, [obj.email]: obj }), {}),
    );
    setInviteList(unique);
    setShareModalOpened(false);
  };

  const getRoles = () => {
    const rolesList = [];
    const filteredRoles = getProjectRoles(roles, activeProject.id).filter((r) => r.name !== 'owner');
    if (isRoleAction({ key: 'project', role: 'share', modelRole: activeProject?.role })) {
      rolesList.push(...filteredRoles.map((r) => ({ value: r.name, title: listRolesName[r.name] || r.name })));
    } else {
      rolesList.push(...filteredRoles.map((r) => ({ value: r.name, title: listRolesName[r.name] || r.name, disabled: true })));
    }
    if (isRoleAction({ key: 'project', role: 'delete', modelRole: activeProject?.role })) {
      rolesList.push({ value: 'delete', title: 'Delete', hidden: currentUser.role === 'viewer', red: true  });
    }
    return rolesList;
  };

  const {
    submitForm,
    handleChange: handleChange2,
    values: { name, shortName, description, status }, errors,
    setValues, validateForm
  } = useFormik({
    initialValues: {
      status: 'active'
    },
    validationSchema: ProjectModifySchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (v) => {
      updateProject({ 
        name: v.name, 
        description: v.description,
        projectId: Number(projectId)
      }, () => {
        setIsChange(false);
      });
    },
  });

  const debouncedValidateForm = debounce(() => {
    validateForm();
  }, 100);

  const handleChange = (...args) => {
    handleChange2(...args);
    if (submitTriggered) {
      debouncedValidateForm();
    }
  }

  const handleSubmitWithCallback = (e) => {
    e.preventDefault();
    setSubmitTriggered(true);
    submitForm(e);
  };

  useEffect(() => {
    if (activeProject) {
      const {
        name,
        description,
        shortName,
      } = activeProject || {};

      setValues({
        name,
        shortName,
        description,
        status: 'active'
      });
    }
  }, [activeProject]);

  const deleteAccess = (user) => {
    const newInviteList = inviteList.filter((el) => el.email !== user.email);
    setInviteList(newInviteList);
    setRemoveModalOpened(false);
    setActiveUser({});
  };

  useEffect(() => {
    setInviteList(storedInviteList || []);
}, [storedInviteList]);

  const handleChangeProxy = (...arg) => {
    setIsChange(true);
    return handleChange(...arg);
  }

  const addUserOfApiKey = () => {
    setShareModalOpened(true);
  };

  const handeChangeRole = (user, role) => {
    if (role === 'delete') {
      setRemoveModalOpened(true);
      setActiveUser(user);
      return;
    }

    const newInviteList = inviteList.map((el) => (el.email === user.email ? { ...el, role } : el));
    addAccess({
      id: +projectId,
      resource: 'project',
      projectId: +activeProject.id,
      role,
      userId: user.id,
    }, () => {
      setInviteList(newInviteList);
    });
  };

  const goToApiList = () => navigate(`/${shortName}/endpoints`);
  const goToConnectionsList = () => navigate(`/${shortName}/connections`);

  const JSXToRender = (
    <div className={shared.page}>
      <div className={shared.header}>
        <div className={shared.headerTitleGroup}>
          <h1 className={shared.headerTitle}>Edit Project</h1>
        </div>
        <div className={shared.headerButtonGroup}>
          <ValidationErrorList 
            errors={errors} 
            subrouteMapping={subrouteMapping}
            setSubroute={setSubroute}
          />
          <Button title="Cancel" type="secondary" onClick={secureGoBack} />
          <Button title="Modify project" onClick={handleSubmitWithCallback} disabled={!isChange} />
        </div>
      </div>
      <div className={shared.body}>
        <div className={shared.mainContent}>
          <section className={shared.section}>
            <h4 className={shared.sectionHeader}>Project Settings</h4>
            <div className={shared.sectionContainer}>
              <div className={shared.inputWrapper}>
                <span className={shared.inputLabel}>Name</span>
                <Input
                  placeholder="Enter name"
                  handleChange={handleChangeProxy}
                  value={name}
                  name="name"
                  id="name"
                  autoComplete="off"
                  error={!!errors.name}
                />
              </div>
              <div className={shared.inputWrapper}>
                <span className={shared.inputLabel}>
                  Description <span className={shared.inputLabelDetails}> (optional)</span>
                </span>
                <Input
                  placeholder="Enter description"
                  handleChange={handleChangeProxy}
                  value={description}
                  name="description"
                  id="description"
                  autoComplete="off"
                  error={!!errors.description}
                />
              </div>
              <div className={shared.inputWrapper}>
                <span className={shared.inputLabel}>Short Name</span>
                <Input
                  placeholder="enter-short-name"
                  handleChange={handleChangeProxy}
                  value={shortName}
                  name="shortName"
                  id="shortName"
                  autoComplete="off"
                  disabled
                  error={!!errors.shortName}
                />
              </div>
              <div className={shared.inputWrapper}>
                <span className={shared.inputLabel}>Status</span>
                <Select
                  list={[
                    { value: 'active', title: 'Active' },
                    { value: 'inactive', title: 'Inactive' },
                  ]}
                  value={status}
                  handleChange={handleChangeProxy}
                  name="status"
                  id="status"
                  disabled
                />
              </div>
            </div>
            <hr />
          </section>
          <section className={shared.section}>
            <div className={shared.subHeaders} style={{ justifyContent: 'space-between'}}>
              <h4 className={shared.sectionHeader}>
                <span>APIs</span>
                <span>({countEndpoints})</span>
              </h4>
              <div className={shared.textAction}>
                    <ActionText title="Open API List" onClick={goToApiList} />
              </div>
            </div>
            <hr />
          </section>
          <section className={shared.section}>
            <div className={shared.subHeaders} style={{ justifyContent: 'space-between'}}>
              <h4 className={shared.sectionHeader}>
                <span>Connections</span>
                <span>({countConnections})</span>
              </h4>
              <div className={shared.textAction}>
                    <ActionText title="Open Connections List" onClick={goToConnectionsList} />
              </div>
            </div>
          </section>
        </div>
        <div className={shared.sidepanel}>
          <div className={shared.sidepanelGroup}>
              <div className={shared.sidepanelHeading}>
                <div className={shared.subHeaders}>
                  <h4
                    onClick={() => setSubroute(SUBROUTING.ACCESS)}
                    onKeyDown={() => setSubroute(SUBROUTING.ACCESS)}
                    role="button"
                    className={cn(
                      shared.sectionHeader, 
                      { [shared.clickable]: false },
                      { [shared.passive]: subroute !== SUBROUTING.ACCESS })}
                  >
                    Access
                  </h4>
                  {subroute === SUBROUTING.ACCESS && isRoleAction({ key: 'project', role: 'share', modelRole: activeProject?.role }) && (
                    <div className={shared.textAction}>
                      <ActionText title="Share access" onClick={addUserOfApiKey} />
                    </div>
                  )}
                </div>
              </div>
              {subroute === SUBROUTING.ACCESS && (
                <div className={shared.sidepanelTable}>
                  <div className={shared.sidepanelTableHeading}>
                    <div className={shared.lineHeader}>
                      <div>Name</div>
                      <div />
                      <div>Role</div>
                    </div>
                  </div>
                  <div className={shared.sidepanelTableBodyContainer}>
                      <ul className={shared.sidepanelTableBody}>
                            {inviteList?.map((item) => (
                              <li className={shared.historyLine} key={item.email}>
                                <div title={item.email}>
                                  {item.email === currentUser.email ? (
                                    <span>{`${item.firstName} ${item.lastName}`}</span>
                                  ) : item.firstName || item.lastName ? (
                                    <span>{`${item.firstName} ${item.lastName} (${item.email})`}</span>
                                  ) : (
                                    <span>{item.email}</span>
                                  )}
                                </div>
                                <div />
                                <div>
                                  {viewRead ? (
                                    <ChipsStatus
                                      title={listRolesName[item.role] || item.role}
                                      type="input"
                                      className={shared.chipsStatus}
                                      style={{ width: '90px', justifyContent: 'left', paddingLeft: '12px' }}
                                    />
                                  ) : item.role === 'owner' ? (
                                    <ChipsStatus
                                      title="Owner"
                                      type="input"
                                      className={shared.chipsStatus}
                                      style={{ width: '90px', justifyContent: 'left', paddingLeft: '12px' }}
                                    />
                                  ) : (
                                    <Select
                                      style={{ width: 90 }}
                                      list={activeProject ? getRoles(item.role) : []} 
                                      value={item.role}
                                      placeholder={item.role}
                                      handleChange={(email, value) => handeChangeRole(item, value)}
                                      name={item.email}
                                    />
                                  )}
                                </div>
                              </li>
                            ))}
                      </ul>
                  </div>
                </div>
            )}
          </div>
        </div>
        {shareModalOpened && (
          <InviteToResourceModal
            id={projectId}
            onClose={() => setShareModalOpened(false)}
            onSubmit={saveInviteList}
            type="project"
            inviteList={inviteList?.map((i) => i.email) || []}
          />
        )}
        {removeModalOpened && (
          <RemoveAccessModal
            setIsOpen={setRemoveModalOpened}
            deleteAccessUser={deleteAccess}
            user={activeUser}
            resource="project"
            id={projectId}
          />
        )}
      </div>
    </div>
  );

  return JSXToRender;
}

const mapStateToProps = ({ 
  auth: { currentUser },
  activeSearchProject,
  roles,
}) => ({
  currentUser,
  roles,
  activeProject: activeSearchProject?.active,
  endpointsList: activeSearchProject?.endpointsList,
  connectionsList: activeSearchProject?.connectionsList,
  storedInviteList: activeSearchProject?.inviteList,
});

const mapDispatchToProps = (dispatch) => ({
  getConnectedConnections: (data, callback) => dispatch(getConnectedConnectionsToProjectRequest(data, callback)),
  getConnectedEndpoints: (data, callback) => dispatch(getConnectedEndpointsToProjectRequest(data, callback)),
  updateProject: (data, callback) => dispatch(updateSearchProjectRequest(data, callback)),
  getActiveProject: (data, callback) => dispatch(activeSearchProjectRequest(data, callback)),
  // getConnectedEndpoints: (data) => dispatch(getConnectedEndpointsToAuthKeysRequest(data)),
  getAccessUsers: (data) => dispatch(getAccessUsersRequest(data)),
  addAccess: (data, callback) => dispatch(addAccessRequest(data, callback)),
  createToast: (data, callback) => dispatch(createToastRequest(data, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(isRoleAction(ProjectModify));

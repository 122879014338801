/* eslint-disable no-extra-boolean-cast */
/* eslint-disable max-len */
import React, { useEffect, useState, memo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import debounce from 'lodash.debounce';
import { useFormik } from 'formik';
import cn from 'classnames';
import * as Yup from 'yup';
import ActionText from '../../components/ActionText/ActionText';
import ActionIcon from '../../components/ActionIcon/ActionIcon';
import ValidationErrorList from '../../components/ValidationErrorList/ValidationErrorList';
import { useCopyToClipboard } from '../../hooks/hooks';
import Button from '../../components/Button/Button';
import CodeEditor from '../../components/CodeEditor';
import InviteToResourceModal from '../../components/InviteToResourceModal/InviteToResourceModal';
import Checkbox from '../../components/Checkbox/Checkbox';
import Input from '../../components/Input/Input';
import Select from '../../components/Select/Select';

import { loadStatus } from '../../helpers/const';
import { roleMapper, calcPlaceholder, limitsInit, parseIfJSON, formatNumber } from '../../helpers/utils';
import { createAuthKeyRequest } from '../../store/authKeys/actions';
import { createToastRequest } from '../../store/toasts/actions';

import * as shared from '../../styles/shared.module.scss';
import { useMonaco, useMonacoErrors } from '../../hooks/useMonaco';

const MemoizedCodeEditor = memo((props) => 
  // Your component logic here
   (
    <CodeEditor
      value={props.value}
      handleChange={props.handleChange}
      handleBlurCallback={props.handleBlurCallback}
      id={props.id}
      name={props.name}
      type={props.type}
      placeholder={props.placeholder}
      error={props.error}
      style={props.style}
      height={props.height}
      withVariables={props.withVariables}
    />
  )
);

const maxAllowedLength = 64;

const addToParam =
  [
    { value: 'header', title: 'Header' },
    { value: 'query', title: 'Query parameters' },
  ];

const SUBROUTING = {
  ACCESS: 'access',
  LIMITS: 'limits',
};

const subrouteMapping = {};

const AuthSchema = ({ connectLimit }) => Yup.object().shape({
  name: Yup.string().min(2, 'Name length should be at least 2 characters').max(64).matches(/^(?!\s)(?!.* {2})/)
    .required('Name required'),
  authorizationMethod: Yup.string().required('AuthMethod required'),
  RPS: Yup.number()
    .test('rps-connect-limit', `Max. system RPS limit: ${formatNumber(connectLimit)}`, (value) => {
      if (value > connectLimit) {
        return false;
      }
      return true;
    }),
  RPMONTH: Yup.number()
    .when('activeRpmType', {
      is: 'mon',
      then: Yup.number()
        .test('rps-value-limit-month', function (value) {
          const { RPS } = this.parent;
          const calculatedLimit = RPS * 60 * 60 * 24 * 30;
          if (value > calculatedLimit) {
            return this.createError({ message: `Max. RPM are: ${formatNumber(calculatedLimit)}` });
          }
          return true;
        })
        .test('rps-connect-limit-month', `Max. system RPM are: ${formatNumber(connectLimit * 60 * 60 * 24 * 30)}`, (value) => {
          if (value > (connectLimit * 60 * 60 * 24 * 30)) {
            return false;
          } 
          return true;
        })
        ,
    }),
  RPMONTHCALENDAR: Yup.number()
    .when('activeRpmType', {
      is: 'cmon',
      then: Yup.number()
        .test('rps-value-limit-month-calendar', function (value) {
          const { RPS } = this.parent;
          const calculatedLimit = RPS * 60 * 60 * 24 * 30;
          if (value > calculatedLimit) {
            return this.createError({ message: `Max. RPM are: ${formatNumber(calculatedLimit)}` });
          }
          return true;
        })
        .test('rps-connect-limit-month-calendar', `Max. system RPM are: ${formatNumber(connectLimit * 60 * 60 * 24 * 30)}`, (value) => {
          if (value > (connectLimit * 60 * 60 * 24 * 30)) {
            return false;
          } 
          return true;
        })
        ,
    }),
  authBasicLogin: Yup.string().when(['authorizationMethod'], {
    is: (authorizationMethod) => authorizationMethod === 'basic',
    then: Yup.string().required('Login required'),
  }),
  authBasicPassword: Yup.string().when(['authorizationMethod'], {
    is: (authorizationMethod) => authorizationMethod === 'basic',
    then: Yup.string().required('Password required'),
  }),
  authAPIKeyHeaderName: Yup.string().when(['authorizationMethod'], {
    is: (authorizationMethod) => authorizationMethod === 'APIKey',
    then: Yup.string().required('HeaderName required'),
  }),
  authAPIKeyToken: Yup.string().when(['authorizationMethod'], {
    is: (authorizationMethod) => authorizationMethod === 'APIKey',
    then: Yup.string().required('Token required'),
  }),
  authAPIKeyAddTo: Yup.string().when(['authorizationMethod'], {
    is: (authorizationMethod) => authorizationMethod === 'APIKey',
    then: Yup.string().required('Add to required'),
  }),
  authBearerToken: Yup.string().when(['authorizationMethod'], {
    is: (authorizationMethod) => authorizationMethod === 'bearer',
    then: Yup.string().required('Token required'),
  }),
  authJwtClientAddTo: Yup.string().when(['authorizationMethod'], {
    is: (authorizationMethod) => authorizationMethod === 'JWT',
    then: Yup.string().required('Add to required'),
  }),
  authJwtClientAlgorithm: Yup.string().when(['authorizationMethod'], {
    is: (authorizationMethod) => authorizationMethod === 'JWT',
    then: Yup.string().required('Algorithm required'),
  }),
  authJwtClientPrivateKey: Yup.string().when(['authorizationMethod'], {
    is: (authorizationMethod) => authorizationMethod === 'JWT',
    then: Yup.string().required('Private Key required'),
  }),
  authJwtClientPayload: Yup.string().when(['authorizationMethod'], {
    is: (authorizationMethod) => authorizationMethod === 'JWT',
    then: Yup.string().required('Payload required'),
  }),
});

function AuthKeyCreate({
  createAuthKey,
  currentUser,
  activeProject,
  createToast,
  JWTAlgorithm,
  connectLimit
}) {
  const {showMonacoErrors} = useMonacoErrors();
  useMonaco();
  const [testStatus, setTestStatus] = useState(loadStatus.LOAD);
  const [subroute, setSubroute] = useState(SUBROUTING.LIMITS);
  const [shareModalOpened, setShareModalOpened] = useState(false);
  const [inviteList, setInviteList] = useState([]);
  const [placeholders, setPlaceholders] = useState(limitsInit);
  const navigate = useNavigate();
  const { shortName, folderId } = useParams();
  const algorithms = JWTAlgorithm ? JWTAlgorithm.map(v => ({ value: v, title: v })) : [];
  const listAPI = () => (folderId ? navigate(`/${shortName}/auth-keys/folder/${folderId}`) : navigate(`/${shortName}/auth-keys`));
  const [, copyText] = useCopyToClipboard();
  const [submitTriggered, setSubmitTriggered] = useState(false);

  useEffect(() => {
    if (currentUser) {
      setInviteList([{
        userFirstName: currentUser.firstName,
        userLastName: currentUser.lastName,
        userEmail: currentUser.email,
        roleId: 2,
      }]);
    }
  }, [currentUser]);

  const {
    submitForm, handleChange: handleChange2, setFieldValue: setFieldValue2, values, errors, validateForm
  } = useFormik({
    initialValues: {
      authorizationMethod: '',
      authJwtClientKeyBase64Encoded: false,
      name: '',
      description: '',
      RPS: '',
      RPM: '',
      RPH: '',
      RPD: '',
      RPMONTH: '',
      RPMONTHCALENDAR: '',
      activeRpmType: 'cmon',
    },
    validationSchema: AuthSchema({
      connectLimit
    }),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (v) => {
      const req = {
        name: v.name,
        description: v.description,
        method: v.authorizationMethod,
        projectId: +activeProject.id,
        folderId: folderId ? +folderId : 0,
        data: {},
        limit: {
          sec: +v.RPS || 0,
          mon: v.activeRpmType === 'mon' ? (+v.RPMONTH || 0) : 0,
          cmon: v.activeRpmType === 'cmon' ? (+v.RPMONTHCALENDAR || 0) : 0,
        },
      };

        if (v.authorizationMethod === 'basic') {
          req.data = {
            login: v.authBasicLogin,
            password: v.authBasicPassword,
          }
        }

        if (v.authorizationMethod === 'APIKey') {
          req.data = {
            headerName: v.authAPIKeyHeaderName,
            token: v.authAPIKeyToken,
            addTo: v.authAPIKeyAddTo,
          }
        }

        if (v.authorizationMethod === 'bearer') {
          req.data = {
            token: v.authBearerToken,
          }
        }

        if (v.authorizationMethod === 'JWT') {
          req.data = {
            addTo: v.authJwtClientAddTo,
            algorithm: v.authJwtClientAlgorithm,
            key: v.authJwtClientPrivateKey,
            isBase64Key: v.authJwtClientKeyBase64Encoded,
            payload: v.authJwtClientPayload,
            headerPrefix: v.authJwtClientHeaderPrefix,
            headers: parseIfJSON(v.authJwtClientHeaders)
          }
      }

      createAuthKey(req, () => (folderId ? navigate(`/${shortName}/auth-keys/folder/${folderId}`) : navigate(`/${shortName}/auth-keys`)));
    },
    onChange: () => {
      setTestStatus(loadStatus.INITIAL);
    },
  });

  const debouncedValidateForm = debounce(() => {
    validateForm();
  }, 100);

  const setFieldValue = (...args) => {
    setFieldValue2(...args);
    if (submitTriggered) {
      debouncedValidateForm();
    }
  }

  const handleChange = (...args) => {
    handleChange2(...args);
    if (submitTriggered) {
      debouncedValidateForm();
    }
  }

  const handleSubmitWithCallback = (e) => {
    if(showMonacoErrors()) return
    e.preventDefault();
    setSubmitTriggered(true);
    submitForm(e);
  };

  const handleChangeProxy = (...arg) => {
    if (testStatus !== loadStatus.INITIAL) setTestStatus(loadStatus.INITIAL);
    return handleChange(...arg);
  };

  const handleBlurLimits = (e) => {
    const { value, name } = e.target;
    if (Number.isNaN(+value) || !Number.isInteger(+value) || (+value) < 0) {
      return;
    }
    switch (name) {
      case 'RPS': {
        setFieldValue(name, value);
        if (+values.RPMONTH && +value && +values.RPMONTH < +value) setFieldValue('RPMONTH', value);
        if (+values.RPMONTHCALENDAR && +value && +values.RPMONTHCALENDAR < +value) setFieldValue('RPMONTHCALENDAR', value);
        break;
      }
      case 'RPMONTH': {
        if (+values.RPS && +value && +values.RPS > +value) setFieldValue('RPS', value);
        setFieldValue(name, value);
        break;
      }
      case 'RPMONTHCALENDAR': {
        if (+values.RPS && +value && +values.RPS > +value) setFieldValue('RPS', value);
        setFieldValue(name, value);
        break;
      }
      default: {
        setFieldValue(name, value);
      }
    }

    setPlaceholders(calcPlaceholder({ ...values, [name]: value }));
  };

  const handleChangeNumber = (e) => {
    const { value, name } = e.target;
    if (Number.isNaN(+value) || !Number.isInteger(+value)) return;
    setFieldValue(name, value ? Math.round(+value) : value);
    setTestStatus(loadStatus.INITIAL);
  };

  const setFieldValueProxy = (...arg) => {
    if (testStatus !== loadStatus.INITIAL) setTestStatus(loadStatus.INITIAL);
    return setFieldValue(...arg);
  };

  const handeChangeRole = (email, role) => {
    const newInviteList = inviteList.map((el) => (el.userEmail === email ? { ...el, roleId: role } : el));
    setInviteList(newInviteList);
  };

  const saveInviteList = (data) => {
    const filtered = data.accessList.filter((el) => el.userEmail !== currentUser.email);
    const unique = Object.values(
      [...inviteList, ...filtered].reduce((acc, obj) => ({ ...acc, [obj.userEmail]: obj }), {}),
    );
    setInviteList(unique);
    setShareModalOpened(false);
  };

  const handleChangeName = ({ target }) => {
    const regex = /^(?!\s)(?!.* {2})/;
    if (regex.test(target.value) && target.value.length <= maxAllowedLength) {
      setFieldValue(target.name, target.value);
    }
  };

  return (
    <div className={shared.page}>
      <div className={shared.header}>
        <div className={shared.headerTitleGroup}>
          <h1 className={shared.headerTitle}>Create Auth Key</h1>
        </div>
        <div className={shared.headerButtonGroup}>
          <ValidationErrorList 
            errors={errors} 
            subrouteMapping={subrouteMapping}
            setSubroute={setSubroute}
          />
          <Button title="Cancel" type="secondary" onClick={listAPI} />
          <Button title="Create auth key" onClick={handleSubmitWithCallback} />
        </div>
      </div>
      <div className={shared.body}>
        <div className={shared.mainContent}>
          <section className={shared.section}>
            <h4 className={shared.sectionHeader}>General Settings</h4>
            <div className={shared.sectionContainer}>
              <div className={shared.inputWrapper}>
                <span className={shared.inputLabel}>Name</span>
                <Input
                  placeholder="Enter name"
                  handleChange={handleChangeName}
                  value={values.name}
                  name="name"
                  id="name"
                  error={!!errors.name}
                />
              </div>
              <div className={shared.inputWrapper}>
                <span className={shared.inputLabel}>
                  Description 
                  {' '}
                  <span className={shared.inputLabelDetails}> (optional)</span>
                </span>
                <Input
                  placeholder="Enter description"
                  handleChange={handleChangeProxy}
                  value={values.description}
                  name="description"
                  id="description"
                />
              </div>
            </div>
          </section>
          <hr />
          <section className={shared.section}>
            <h4 className={shared.sectionHeader}>Authorization Settings</h4>
            <div className={shared.sectionContainer}>
              <div className={shared.inputWrapper}>
                        <span className={shared.inputLabel}>Authorization method</span>
                        <Select
                          list={[
                            { value: 'basic', title: 'Basic' },
                            { value: 'APIKey', title: 'API Key' },
                            { value: 'bearer', title: 'Bearer' },
                            { value: 'JWT', title: 'JWT' },
                          ]}
                          value={values.authorizationMethod}
                          handleChange={setFieldValueProxy}
                          placeholder="Select authorization method"
                          name="authorizationMethod"
                          id="authorizationMethod"
                          error={!!errors.authorizationMethod}
                        />
                      </div>
                      {values.authorizationMethod === 'basic' ? (
                        <>
                          <div className={shared.inputWrapper} />
                          <div className={shared.inputWrapper}>
                            <span className={shared.inputLabel}>Login</span>
                            <Input
                              placeholder="Enter login"
                              handleChange={handleChangeProxy}
                              value={values.authBasicLogin}
                              iconRight={values.authBasicLogin && 'copy'}
                              handleAction={() => {
                                copyText(values.authBasicLogin) && createToast({ type: 'success', text: 'Copied' });
                              }}
                              name="authBasicLogin"
                              id="authBasicLogin"
                              autoComplete="none"
                              error={!!errors.authBasicLogin}
                            />
                          </div>
                          <div className={shared.inputWrapper}>
                            <span className={shared.inputLabel}>Password</span>
                            <Input
                              placeholder="Enter password"
                              handleChange={handleChangeProxy}
                              value={values.authBasicPassword}
                              iconRight={values.authBasicPassword && 'copy'}
                              isPassword
                              handleAction={() => {
                                copyText(values.authBasicPassword) && createToast({ type: 'success', text: 'Copied' });
                              }}
                              name="authBasicPassword"
                              id="authBasicPassword"
                              type="password"
                              autoComplete="new-password"
                              error={!!errors.authBasicPassword}
                            />
                          </div>
                        </>
                      ) : null}
                      {values.authorizationMethod === 'APIKey' ? (
                        <>
                          <div className={shared.inputWrapper}>
                            <span className={shared.inputLabel}>Add API Key to</span>
                            <Select
                              list={addToParam}
                              value={values.authAPIKeyAddTo}
                              handleChange={setFieldValueProxy}
                              placeholder="Select where to place key"
                              name="authAPIKeyAddTo"
                              id="authAPIKeyAddTo"
                              error={!!errors.authAPIKeyAddTo}
                            />
                          </div>
                          <div className={shared.inputWrapper}>
                            <span className={shared.inputLabel}>Key</span>
                            <Input
                              placeholder="Enter key"
                              handleChange={handleChangeProxy}
                              value={values.authAPIKeyHeaderName}
                              iconRight={values.authAPIKeyHeaderName && 'copy'}
                              handleAction={() => {
                                copyText(values.authAPIKeyHeaderName) && createToast({ type: 'success', text: 'Copied' });
                              }}
                              name="authAPIKeyHeaderName"
                              id="authAPIKeyHeaderName"
                              autoComplete="none"
                              error={!!errors.authAPIKeyHeaderName}
                            />
                          </div>
                          <div className={shared.inputWrapper}>
                            <span className={shared.inputLabel}>Value</span>
                            <Input
                              placeholder="Enter value"
                              handleChange={handleChangeProxy}
                              value={values.authAPIKeyToken}
                              iconRight={values.authAPIKeyToken && 'copy'}
                              handleAction={() => {
                                copyText(values.authAPIKeyToken) && createToast({ type: 'success', text: 'Copied' });
                              }}
                              name="authAPIKeyToken"
                              id="authAPIKeyToken"
                              autoComplete="none"
                              error={!!errors.authAPIKeyToken}
                            />
                          </div>
                        </>
                      ) : null}
                      {values.authorizationMethod === 'bearer' ? (
                          <div className={shared.inputWrapper}>
                            <span className={shared.inputLabel}>Token</span>
                            <Input
                              placeholder="Enter token"
                              handleChange={handleChangeProxy}
                              value={values.authBearerToken}
                              iconRight={values.authBearerToken && 'copy'}
                              handleAction={() => {
                                copyText(values.authBearerToken) && createToast({ type: 'success', text: 'Copied' });
                              }}
                              name="authBearerToken"
                              id="authBearerToken"
                              autoComplete="none"
                              error={!!errors.authBearerToken}
                            />
                          </div>
                      ) : null}
                      {values.authorizationMethod === 'JWT' ? (
                        <>
                          <div className={shared.inputWrapper} />
                          <div className={shared.inputWrapper}>
                              <span className={shared.inputLabel}>Add JWT to</span>
                              <Select
                                list={addToParam}
                                value={values.authJwtClientAddTo}
                                handleChange={setFieldValueProxy}
                                placeholder="Select where to add JWT"
                                name="authJwtClientAddTo"
                                id="authJwtClientAddTo"
                                error={!!errors.authJwtClientAddTo}
                              />
                          </div>
                          <div className={shared.inputWrapper}>
                            <span className={shared.inputLabel}>Algorithm</span>
                            <Select
                              list={algorithms}
                              value={values.authJwtClientAlgorithm}
                              handleChange={setFieldValueProxy}
                              placeholder="Select algorithm"
                              name="authJwtClientAlgorithm"
                              id="authJwtClientAlgorithm"
                              error={!!errors.authJwtClientAlgorithm}
                            />
                          </div>
                          <div className={shared.inputWrapper} />
                        </>
                      ) : null}
            </div>
            {values.authorizationMethod === 'JWT' ? (
                <div className={cn(shared.sectionContainer, shared.wide)} style={{ marginTop: 0 }}>
                    <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>
                      {(values.authJwtClientAlgorithm || '').startsWith('HS') ? (
                        'Secret Key'
                      ) : (
                        'Private Key'
                      )}
                    </span>
                        {(values.authJwtClientAlgorithm || '').startsWith('HS') ? (
                          <Input
                            placeholder="Paste Secret Key"
                            handleChange={handleChangeProxy}
                            value={values.authJwtClientPrivateKey}
                            iconRight={values.authJwtClientPrivateKey && 'copy'}
                            handleAction={() => {
                              copyText(values.authJwtClientPrivateKey) && createToast({ type: 'success', text: 'Copied' });
                            }}
                            name="authJwtClientPrivateKey"
                            id="authJwtClientPrivateKey"
                            autoComplete="none"
                            error={!!errors.authJwtClientPrivateKey}
                        />
                        ) : (
                        <Input
                          placeholder={`- - - - - BEGIN PRIVATE KEY - - - - -\nprivate key in PKCS#8 PEM format\n- - - - - END PRIVATE KEY - - - - `}
                          handleChange={handleChangeProxy}
                          value={values.authJwtClientPrivateKey}
                          iconRight={values.authJwtClientPrivateKey && 'copy'}
                          handleAction={() => {
                            copyText(values.authJwtClientPrivateKey) && createToast({ type: 'success', text: 'Copied' });
                          }}
                          name="authJwtClientPrivateKey"
                          id="authJwtClientPrivateKey"
                          multiline
                          autoComplete="none"
                          error={!!errors.authJwtClientPrivateKey}
                          style={{ lineHeight: '20px', paddingTop: '10px', paddingBottom: '10px' }}
                      />
                        )}
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <Checkbox
                            name="authJwtClientKeyBase64Encoded"
                            id="authJwtClientKeyBase64Encoded"
                            handleChange={(val) => setFieldValueProxy('authJwtClientKeyBase64Encoded', val)}
                            size="small"
                            value={values.authJwtClientKeyBase64Encoded}
                            style={{ marginRight: '0' }}
                            label="Key Base64 encoded"
                          />
                    </div>
                    <div className={shared.inputWrapper}>
                          <span className={shared.inputLabel}>Payload</span>
                          <MemoizedCodeEditor
                            value={values.authJwtClientPayload}
                            handleChange={(value) => setFieldValueProxy('authJwtClientPayload', value)}
                            name="authJwtClientPayload"
                            id="authJwtClientPayload"
                            type='json'
                            placeholder="{}"
                            error={!!errors.authJwtClientPayload}
                            style={{ maxWidth: 'calc(100vw - 651px + 48px)', minWidth: '400px' }}
                            height={104}
                            withVariables={false}
                          />
                    </div>
                </div>
            ) : null}
          </section>
          {
           values.authorizationMethod === 'JWT' ? (
            <>
              <hr />
              <section className={shared.section}>
                <h4 className={shared.sectionHeader}>Advanced Settings</h4>
                <div className={shared.sectionContainer}>
                  <div className={shared.inputWrapper}>
                                      <span className={shared.inputLabel}>
                                        {values.authJwtClientAddTo === 'query' ? 'Query parameter name' : 'Request header prefix'}
                                      </span>
                                      <Input
                                        placeholder={values.authJwtClientAddTo === 'query' ? 'Enter name' : 'Enter prefix'}
                                        handleChange={handleChangeProxy}
                                        value={values.authJwtClientHeaderPrefix}
                                        iconRight={values.authJwtClientHeaderPrefix && 'copy'}
                                        handleAction={() => {
                                          copyText(values.authJwtClientHeaderPrefix) && createToast({ type: 'success', text: 'Copied' });
                                        }}
                                        name="authJwtClientHeaderPrefix"
                                        id="authJwtClientHeaderPrefix"
                                        autoComplete="none"
                                        error={!!errors.authJwtClientHeaderPrefix}
                                      />
                  </div>
                  <div className={shared.inputWrapper} />
                </div>
                <div className={cn(shared.sectionContainer, shared.wide)}>
                    <div className={shared.inputWrapper}>
                        <span className={shared.inputLabel}>JWT headers</span>
                        <MemoizedCodeEditor
                          value={values.authJwtClientHeaders}
                          handleChange={(value) => setFieldValueProxy('authJwtClientHeaders', value)}
                          name="authJwtClientHeaders"
                          id="authJwtClientHeaders"
                          type='json'
                          placeholder="{}"
                          error={!!errors.authJwtClientHeaders}
                          style={{ maxWidth: 'calc(100vw - 651px + 48px)', minWidth: '400px' }}
                          height={104}
                          withVariables={false}
                        />
                    </div>
                </div>
              </section>
            </>
           ) : null
          }

        </div>
        <div className={shared.sidepanel}>
          <div className={shared.sidepanelGroup}>
              <div className={shared.sidepanelHeading}>
                <div className={shared.subHeaders}>
                  <h4
                    onClick={() => setSubroute(SUBROUTING.LIMITS)}
                    className={cn(
                      shared.sectionHeader,
                      { [shared.clickable]: true },
                      { [shared.passive]: subroute !== SUBROUTING.LIMITS },
                    )}
                  >
                    Limits
                  </h4>
                  <h4
                    onClick={() => setSubroute(SUBROUTING.ACCESS)}
                    className={cn(
                      shared.sectionHeader, 
                      { [shared.clickable]: true },
                      { [shared.passive]: subroute !== SUBROUTING.ACCESS })}
                  >
                    Access
                  </h4>
                </div>
              </div>
              {subroute === SUBROUTING.LIMITS && (
                <div className={shared.sidepanelForm}>
                  <div className={cn(shared.limitSection)}>
                    <div className={shared.inputWrapper}>
                      <span className={cn(shared.inputLabel, shared.spanWrapper)}>Requests / second (RPS)</span>
                      <Input
                        maxLength={15}
                        placeholder={placeholders.RPS}
                        handleChange={handleChangeNumber}
                        onBlur={handleBlurLimits}
                        value={values.RPS}
                        name="RPS"
                        id="RPS"
                        error={!!errors.RPS}
                      />
                    {(!!errors.RPS) ? (
                      <span className={shared.errorPlaceholder}>{errors.RPS}</span>
                    ) : null}
                    </div>

                    <div className={shared.inputWrapper}>
                      <span className={cn(
                        shared.inputLabel, shared.spanWrapper
                        )}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          flexDirection: 'row',
                          whiteSpace: 'nowrap',
                          overflow: 'visible',
                        }}
                        >
                          <span style={{ 
                            display: 'flex',
                            alignItems: 'center'
                          }}>
                          <span>Requests / month (RPM)</span>
                          {values.activeRpmType === 'cmon' ? (
                            <ActionText
                              title={
                                <ActionIcon
                                  id="rpmonth_tooltip1-cmon"
                                  icon="info"
                                  style={{ marginLeft: '8px' }}
                                  tooltip={{
                                    children: (
                                      <div style={{ textAlign: 'left' }}>
                                        The limit is checked by counting requests <br />
                                        starting from the <span style={{ fontWeight: 600 }}>first day of the month</span>. <br />
                                        <span style={{ fontStyle: 'italic'}}>
                                        Example: <br />
                                        Today is May 10th, 09:30 AM. < br />
                                        When a new request is received, requests will <br />
                                        be counted in the period from May 1, 12:00 AM <br />
                                        to the current moment.
                                        </span>

                                      </div>
                                    ),
                                    place: 'top',
                                    openOnClick: false,
                                    clickable: false,
                                  }}
                                />
                              }
                          />
                         ) : 
                         (
                          <ActionText
                          title={
                            <ActionIcon
                              id="rpmonth_tooltip1-mon"
                              icon="info"
                              style={{ marginLeft: '8px' }}
                              tooltip={{
                                children: (
                                  <div style={{ textAlign: 'left' }}>
                                    The limit is checked by counting requests <br/>
                                    over the <span style={{ fontWeight: 600}}>last 30 days</span>. <br />
                                    <span style={{ fontStyle: 'italic'}}>
                                    Example: <br />
                                    Today is May 10th, 09:30 AM. < br />
                                    When a new request is received, requests will <br />
                                    
                                     be counted in the period from April 10, 09:30 AM <br />
                                     to the current moment.
                                    </span>
                                  </div>
                                ),
                                place: 'top',
                                openOnClick: false,
                                clickable: false,
                              }}
                            />
                          }
                      />
                         )}
                          </span>
                          <span>
                            <Select
                            type="textColor"
                            list={[
                              { value: 'mon', title: 'Sliding window' },
                              { value: 'cmon', title: 'Calendar' },
                            ]}
                            value={values.activeRpmType}
                            handleChange={(key, value) => {
                              setFieldValueProxy(key, value);
                            }}
                            name="activeRpmType"
                          />
                          </span>
                      </span>
                      {values.activeRpmType === 'mon' ? (
                        <>
                          <Input
                            maxLength={15}
                            placeholder={placeholders.RPMONTH}
                            handleChange={handleChangeNumber}
                            onBlur={handleBlurLimits}
                            value={values.RPMONTH}
                            name="RPMONTH"
                            error={!!errors.RPMONTH}
                          />
                          {(!!errors.RPMONTH) ? (
                            <span className={shared.errorPlaceholder}>{errors.RPMONTH}</span>
                          ) : null}
                          </>
                      ) : (
                        <>
                          <Input
                            maxLength={15}
                            placeholder={placeholders.RPMONTHCALENDAR}
                            handleChange={handleChangeNumber}
                            onBlur={handleBlurLimits}
                            value={values.RPMONTHCALENDAR}
                            name="RPMONTHCALENDAR"
                            error={!!errors.RPMONTHCALENDAR}
                          />
                          {(!!errors.RPMONTHCALENDAR) ? (
                            <span className={shared.errorPlaceholder}>{errors.RPMONTHCALENDAR}</span>
                          ) : null}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {subroute === SUBROUTING.ACCESS && (
                <div className={shared.sidepanelTable}>
                  <div className={shared.sidepanelTableHeading}>
                    <div className={shared.lineHeader}>
                      <div>Name</div>
                      <div />
                      <div>Role</div>
                    </div>
                  </div>
                  <div className={shared.sidepanelTableBodyContainer}>
                  <ul className={shared.sidepanelTableBody}>
                      {inviteList &&
                        inviteList.map((item) => (
                          <li className={shared.historyLine} key={item.userEmail}>
                            <div title={item.userEmail}>
                              {item.userEmail === currentUser.email ? (
                                <span>{`${item.userFirstName} ${item.userLastName}`}</span>
                              ) : (
                                <span>{item.userEmail}</span>
                              )}
                            </div>
                            <div />
                            <div>
                              {item.userEmail === currentUser.email ? (
                                <Select
                                  list={[{ value: 2, title: roleMapper(2) }]}
                                  style={{ width: 90 }}
                                  value={item.roleId}
                                  handleChange={(email, value) => handeChangeRole(email, value)}
                                  name={item.userEmail}
                                  disabled
                                />
                              ) : (
                                <Select
                                  list={[
                                    { value: 3, title: roleMapper(3) },
                                    { value: 4, title: roleMapper(4) },
                                  ]}
                                  style={{ width: 90 }}
                                  value={item.roleId}
                                  handleChange={(email, value) => handeChangeRole(email, value)}
                                  name={item.userEmail}
                                />
                              )}
                            </div>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              )}
          </div>
        </div>
        {shareModalOpened && (
          <InviteToResourceModal
            onClose={() => setShareModalOpened(false)}
            onSubmit={saveInviteList}
            model={values}
          />
        )}
      </div>
    </div>
  );
}

const mapStateToProps = ({ auth: { currentUser }, directory, activeProject }) => ({
  activeProject,
  currentUser,
  JWTAlgorithm: directory?.JWTAlgorithm,
  connectLimit: directory?.connectLimit || 100,
});

const mapDispatchToProps = (dispatch) => ({
  createAuthKey: (data, callback) => dispatch(createAuthKeyRequest(data, callback)),
  createToast: (data, callback) => dispatch(createToastRequest(data, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthKeyCreate);

import React, { useState, useEffect } from 'react';
import RequestParameterRow from '../RequestParameterRow';
import { debounce } from '../../helpers/utils';

export default function ParamentRows({
  params,
  modelKey,
  errors,
  viewRead,
  handleUpdate,
}) {
  const [list, setList] = useState(params);

  useEffect(() => {
    if (params) setList(params);
  }, [params]);

  const removeParameter = (parameter) => {
    const newParams = list.filter((el) => el.id !== parameter.id);
    setList(newParams);
    debounce('handleChangeProxy', () => {
      handleUpdate(modelKey, newParams);
    }, 0);
  };

  const handleChangeProxy = (e) => {
    const { value, name } = e.target;
    const [field, idX] = name.split('.');
    const newParams = list.map((el) => (el.id === idX ? (({
      ...el,
      [field]: value,
    })) : el));

    setList(newParams);
    debounce('handleChangeProxy', () => {
      handleUpdate(modelKey, newParams);
    }, 500);
  };

  const moveParam = (dragIndex, hoverIndex) => {
    const swap = (arr = [], start = 0, end = 0) => {
      const newArr = arr.slice(0);
      const startItem = arr[start];
      const endItem = arr[end];
      newArr[end] = startItem;
      newArr[start] = endItem;
      return newArr;
    };
    const newList = swap(list, dragIndex, hoverIndex);
    setList(newList);
    debounce('moveParam', () => {
      handleUpdate(modelKey, newList);
    }, 500);
  };

  const setFieldValueProxy = (f, type) => {
    const [field, idX] = f.split('.');

    const newParams = params.map((param) => (param.id === idX ? {
      ...param,
      [field]: type,
    } : param));

    setList(newParams);
    debounce('setFieldValueProxy', () => {
      handleUpdate(modelKey, newParams);
    }, 500);
  };

  return (
    <>
      {list.map((param, i) => ((
        <RequestParameterRow
          key={param.id}
          index={i}
          id={param.id}
          param={param}
          modelKey={modelKey}
          errors={errors}
          viewRead={viewRead}
          moveParam={moveParam}
          removeParameter={removeParameter}
          handleChangeProxy={handleChangeProxy}
          setFieldValueProxy={setFieldValueProxy}
        />
      )))}
    </>
  );
}

import React, { useEffect } from 'react';
import cn from 'classnames';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Button from '../../components/Button/Button';
import Input from '../../components/Input/Input';
import Checkbox from '../../components/Checkbox/Checkbox';
import { listRolesName } from '../../helpers/const';

import { modifyRoleRequest } from '../../store/roles/actions';

import * as shared from '../../styles/shared.module.scss';

const Schema = Yup.object().shape({
  name: Yup.string().required('Name required'),
});

function RolesModify({ modifyRole, roles }) {
  const navigate = useNavigate();
  const { shortName, resourceId } = useParams();
  const listRoles = () => navigate(`/${shortName}/roles`);

  const {
    submitForm, handleChange, setFieldValue, setValues, values, errors,
  } = useFormik({
    initialValues: {
      name: '',
      description: '',
      rights: {
        endpoint: ['read'],
        connection: ['read'],
        project: ['read']
      },
    },
    validationSchema: Schema,
    onSubmit: (v) => {
      modifyRole({
        role: {
          id: +resourceId,
          system: v.system,
          projects: v.projects,
          rights: v.rights,
          name: v.name,
          description: v.description
        },
      },
      () => listRoles())
    },
  });

  useEffect(() => {
    if (roles?.length > 0) {
      const acteveRole = roles.find(r => r.id === +resourceId);
      if (!acteveRole?.rights?.endpoint) {
        acteveRole.rights.endpoint = [];
      }
      if (!acteveRole?.rights?.connection) {
        acteveRole.rights.connection = [];
      }
      if (!acteveRole?.rights?.project) {
        acteveRole.rights.project = [];
      }
      setValues(acteveRole)
    }
  }, [roles]);

  const chengeRights = (key, name) => {
    const newRights = values.rights || {};
    if (values.rights[key]?.includes(name)) {
      newRights[key] = newRights[key]?.filter(v => v !== name)
      setFieldValue('rights', newRights)
    } else {
      newRights[key] = [...newRights[key], name]
      setFieldValue('rights', newRights)
    }
  } 

  const listRights = [
    {
      key: 'endpoint',
      title: 'APIs',
      list: [
        {name: 'read', lable: 'Read'},
        {name: 'share', lable: 'Share'},
        {name: 'create', lable: 'Create'},
        {name: 'update', lable: 'Update'},
        {name: 'delete', lable: 'Delete'}
      ]
    },
    {
      key: 'connection',
      title: 'Connections',
      list: [
        {name: 'read', lable: 'Read'},
        {name: 'share', lable: 'Share'},
        {name: 'create', lable: 'Create'},
        {name: 'update', lable: 'Update'},
        {name: 'delete', lable: 'Delete'}
      ]
    },
    {
      key: 'project',
      title: 'Users',
      list: [
        {name: 'read', lable: 'Read'},
        {name: 'share', lable: 'Share'},
        {name: 'create', lable: 'Create'},
        {name: 'update', lable: 'Update'},
        {name: 'delete', lable: 'Delete'}
      ]
    }
  ]

  return (
    <div className={shared.page}>
      <div className={shared.header}>
        <div className={shared.headerTitleGroup}>
          <h1 className={shared.headerTitle}>Edit role</h1>
        </div>
        <div className={shared.headerButtonGroup}>
          <Button title="Cancel" type="secondary" onClick={listRoles} />
          <Button title="Modify role" onClick={() => submitForm()} />
        </div>
      </div>
      <div className={cn(shared.body, shared.vertical)}>
        <div className={cn(shared.mainContent, shared.mainContentUsers)}>
          <section className={shared.section}>
            <h4 className={shared.sectionHeader}>Role Settings</h4>
            <div className={shared.sectionContainer}>
              <div className={shared.inputWrapper}>
                <span className={shared.inputLabel}>Name</span>
                <Input
                  placeholder="Enter name"
                  name="name"
                  value={listRolesName[values.name] || values.name}
                  handleChange={handleChange}
                  error={!!errors.name && !values.name}
                  disabled={values.system}
                />
              </div>
              <div className={shared.inputWrapper}>
                <span className={shared.inputLabel}>
                  Description 
                  {' '}
                  <span className={shared.inputLabelDetails}> (optional)</span>
                </span>
                <Input
                  placeholder="Description"
                  name="description"
                  value={values.description}
                  handleChange={handleChange}
                  error={!!errors.description && !values.description}
                  disabled={values.system}
                />
              </div>
            </div>
          </section>
          <section className={cn(shared.section, shared.sectionRightsWrap)}>
            <h4 className={shared.sectionHeader}>Permissions</h4>
            <div className={shared.sectionRights}>
              {values.rights && listRights.map(lr => (
                <div className={shared.inputWrapper} key={lr.key}>
                  <span className={shared.inputLabel}>{lr.title}</span>
                  <div className={shared.rightsList}>
                    {console.log('values.rights', values.rights, values.rights[lr.key])}
                    {lr.list.map(l => (
                      <Checkbox
                        keyItem={`${lr.key}_${l.name}`} 
                        handleChange={() => chengeRights(lr.key, l.name)}
                        label={l.lable}
                        value={values.rights[lr.key]?.includes(l.name)}
                        type="colored"
                      />
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = ({ auth: { currentUser }, activeProject, roles }) => ({
  currentUser,
  roles,
  activeProject
});

const mapDispatchToProps = (dispatch) => ({
  modifyRole: (data, callback) => dispatch(modifyRoleRequest(data, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RolesModify);

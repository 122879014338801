@use "sass:map";
@import '../../styles/variables.scss';

.projectSelection {
    position: relative;
    overflow: visible;
    width: 100%;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 16px 0;
}

.group {
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
    width: 100%;
    cursor: pointer;

    &.openedList {
        background-color: map.get($colors-blue, 10);

        &:before {
            content:"";
            position: absolute;
            left: 0;
            bottom: 0;
            background: map.get($colors-basic, "primaryBlue");
            width: 2px;
            height: 100%;
            border-radius: 1px;
        }
    }
}

.iconGroup {
    display: flex;
    flex-direction: row;
    overflow: hidden;
}

.icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto 12px;
}

.titleGroup {
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.actionGroup {
    margin: auto 6px;
}

.heading {
    font-size: map.get($font-sizes, "caption");
    color: map.get($colors-basic, "textGray");
}

.title {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.altTitle {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: map.get($colors-basic, "primaryBlue");
}

.centered {
  align-self: center;
}

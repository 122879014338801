/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import ArrowIcon from '../../pictures/icon-arrow.svg';
import ConnectionsImg from '../../pictures/connection.svg';
import Key from '../../pictures/dbtypes/key.svg';
import ForeignKeyRelation from '../../pictures/dbtypes/foreign-key-relation.svg';
import ForeignKey from '../../pictures/dbtypes/foreign-key.svg';
import IntImg from '../../pictures/dbtypes/int.svg';
import JSONImg from '../../pictures/dbtypes/json.svg';
import XmlImg from '../../pictures/dbtypes/xml.svg';
import ArrImg from '../../pictures/dbtypes/array.svg';
import BoolImg from '../../pictures/dbtypes/boolean.svg';
import TextImg from '../../pictures/dbtypes/text.svg';
import TimestampImg from '../../pictures/dbtypes/timestamp.svg';

import * as style from './DatabaseSchemeComponent.module.scss';

const databaseTypeIcon = {
  INT: IntImg,
  SERIAL: IntImg,
  JSON: JSONImg,
  VAR: TextImg,
  
  BIT: BoolImg,
  TINYINT: IntImg,
  SMALLINT: IntImg,
  INTEGER: IntImg,
  BIGINT: IntImg,
  FLOAT: IntImg,
  REAL: IntImg,
  DOUBLE: IntImg,
  NUMERIC: IntImg,
  DECIMAL: IntImg,
  CHAR: TextImg,
  VARCHAR: TextImg,
  LONGVARCHAR: TextImg,
  DATE: TimestampImg,
  TIME: TimestampImg,
  TIMESTAMP: TimestampImg,
  BINARY: IntImg,
  VARBINARY: IntImg,
  LONGVARBINARY: IntImg,
  NULL: IntImg,
  OTHER: ArrImg,
  JAVA_OBJECT: JSONImg,
  DISTINCT: ArrImg,
  STRUCT: ArrImg,
  ARRAY: ArrImg,
  BLOB: IntImg,
  CLOB: TextImg,
  REF: TextImg,
  DATALINK: TextImg,
  BOOLEAN: BoolImg,
  ROWID: TextImg,
  NCHAR: TextImg,
  NVARCHAR: TextImg,
  LONGNVARCHAR: TextImg,
  NCLOB: IntImg,
  SQLXML: XmlImg,
  REF_CURSOR: ArrImg,
  TIME_WITH_TIMEZONE: TimestampImg,
  TIMESTAMP_WITH_TIMEZONE: TimestampImg,
  UNKNOWN: ArrImg
}

function DatabaseSchemeComponent({ data, setExpandStatus, expanded }) {
  const { name, fields } = data || {};

  const scrollToRelated = (name, rowId) => {
    setExpandStatus(name, true);
    setTimeout(() => {
      const targetRow = document.getElementById(rowId);

      if (targetRow) {
        targetRow.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  
        targetRow.classList.add(style.highlighted);
  
        setTimeout(() => {
          targetRow.classList.remove(style.highlighted);
        }, 3000);
      }
    }, 100);

  };

  const renderRows = (fields) => {
    if (!fields) return null;
    return (
      <ul className={style.collapsibleTable}>
        {fields.map((f) => {
          const Icon = databaseTypeIcon[f.group];
          const relatedTable = f.foreignKey ? f.foreignKey.split('.') : [];
          const relatedId = relatedTable[0];
          return (
            <React.Fragment key={`${name}_${f.name}`}>
              <li id={`${name}.${f.name}`}>
                {f.primaryKey ? <div className={style.primaryKey}><Key /></div> : null}
                <span>{f.name}</span>
                <div className={cn(style.typeGroup, {
                  [style.activeLink]: f.foreignKey
                })}>
                  {f.foreignKey ? <span>foreign key</span> : <span>{f.type}</span> }
                  {f.foreignKey ? (
                    <ForeignKey />
                  ) : (
                    // eslint-disable-next-line react/jsx-no-useless-fragment
                    <>
                      {databaseTypeIcon[f.group] ? <Icon /> : <div style={{ width: 16, height: 16 }} />}
                    </>
                  )}
                </div>
              </li>
              {f.foreignKey ? (
                <li 
                 key={`${name}_${f.name}`} 
                 className={style.foreignKeyItem} 
                 role="button"
                 tabIndex="0"
                 onClick={() => scrollToRelated(relatedId, f.foreignKey)}
                 onKeyDown={() => scrollToRelated(relatedId, f.foreignKey)}
                >
                  <div className={style.foreignKeyItemGroup}>
                    <ForeignKeyRelation />
                    <span>{f.foreignKey}</span>
                  </div>
                  <div className={style.typeGroup}>
                    <span>{f.type}</span>
                    {databaseTypeIcon[f.group] ? <Icon /> : <div style={{ width: 16, height: 16 }} />}
                  </div>
                </li>
              ) : null}
            </React.Fragment>) 
        })}
      </ul>
    );
  }

  return (
    <div
      className={cn(style.collapsible)}
    >
      <div 
       className={style.collapsibleHeader} 
       onClick={() => setExpandStatus(name, !expanded)} 
       onKeyDown={() => setExpandStatus(name, !expanded)} 
       role="button" 
       tabIndex="0"
      >
        <div className={style.iconGroup}>
          <div className={cn(style.arrowAngle, {
            [style.openAngle]: !expanded,
          })}>
              <ArrowIcon />
          </div>
          <ConnectionsImg />
        </div>
        <span>{name}</span>
      </div>
      <div className={cn(style.collapsibleContent, {
          [style.collapsed]: !expanded,
      })}>{renderRows(fields)}</div>
    </div>
  );
}

DatabaseSchemeComponent.propTypes = {
  data: PropTypes.shape({
    fields: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  setExpandStatus: PropTypes.func,
  expanded: PropTypes.bool
};

export default DatabaseSchemeComponent;

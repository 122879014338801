import {
  put, takeEvery, all, fork, select, call
} from 'redux-saga/effects';
import {
  activeSearchProjectRequest, activeSearchProjectSuccess,
  getConnectedConnectionsToProjectRequest,
  getConnectedConnectionsToProjectSuccess,
  getConnectedEndpointsToProjectRequest,
  getConnectedEndpointsToProjectSuccess
} from './actions';
import { api } from '../../helpers/api';
import {
  refreshRequest,
} from '../auth/actions';
import { createToastRequest } from '../toasts/actions';
import { ActiveSearchProject } from './constants';

import history from '../../history';

const getAuth = (state) => state.auth?.auth;

export function* fetchActiveSearchProject(ops) {
  const authData = yield select(getAuth);
  const { data, callback } = ops || {};
  const { id } = data || {};

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(api, `/projects/${id}`, 'GET', null, authData.access_token);
      if (response.ok) {
        const parsed = yield response.json();
        yield put(activeSearchProjectSuccess(parsed));
      } else if (response.status === 401) {
        yield put(refreshRequest(activeSearchProjectRequest, data, callback));
      } else {
        const parsed = yield response.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
      }
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error [fetchActiveSearchProject]' }));
  }
}

export function* fetchConnectedConnections(ops) {
  const { data, callback } = ops;
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(api, `/connections?projectId=${data.id}`, 'GET', null, authData.access_token);
      if (response.ok) {
        const parsed = yield response.json();
        yield put(getConnectedConnectionsToProjectSuccess(parsed.items));
        if (callback) callback();
      } else if (response.status === 401) {
        yield put(refreshRequest(getConnectedConnectionsToProjectRequest, data, callback));
      } else {
        const parsed = yield response.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
        if (callback) callback(true);
      }
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* fetchConnectedEndpoints(ops) {
  const { data, callback } = ops;
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(api, `/endpoints?projectId=${data.id}`, 'GET', null, authData.access_token);
      if (response.ok) {
        const parsed = yield response.json();
        yield put(getConnectedEndpointsToProjectSuccess(parsed.items));
        if (callback) callback();
      } else if (response.status === 401) {
        yield put(refreshRequest(getConnectedEndpointsToProjectRequest, data));
      } else {
        const parsed = yield response.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
      }
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

function* activeSearchProjectSaga() {
  yield takeEvery(ActiveSearchProject.activeSearchProjectRequest, fetchActiveSearchProject);
  yield takeEvery(ActiveSearchProject.getConnectedConnectionsToProjectRequest, fetchConnectedConnections);
  yield takeEvery(ActiveSearchProject.getConnectedEndpointsToProjectRequest, fetchConnectedEndpoints);
}

function* configSaga() {
  yield all([fork(activeSearchProjectSaga)]);
}

export default configSaga;

/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-autofocus */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Button from '../Button/Button';
import { 
  removeVariablesListRequest,
} from '../../store/variables/actions';

import * as styles from './RemoveVariablesModal.module.scss';

function RemoveVariablesModal({
  onClose,
  onSubmit,
  model,
  collection,
}) {
  const dispatch = useDispatch();
  const { variables = []  } = model || {};
  const { data: variablesData = [] } = collection || {};

  const [submitting, setSubmitting] = useState(false);

  const sendDeleteRequest = (data) => {
    const { variables: vari = [] } = data || {};
    const transformedVariables = vari.map(f => variablesData.find(a => a.id === f)).filter(el => el);

      setSubmitting(true);
      dispatch(removeVariablesListRequest({ 
       variables: transformedVariables,
       folders: []
      }, (result) => {
        const { successVariables } = result || {};
        if (result && (successVariables?.length)) {
          setSubmitting(false);
          onSubmit();
        } else {
          setSubmitting(false);
        }
      }));
  };

  return (
    <div className={styles.modal}>
      <div className={styles.modalContent}>
        <div className={styles.header}>
          <div className={styles.title}>Delete Items</div>
        </div>
        <div className={styles.body}>
          <div className={styles.mainContent}>
            <div>
              <div>
                <span className={styles.modelTitle}>
                    {variables.length}
                    {' '}
                    {variables.length > 1 ? 'Variables' : 'Variable'}
                  </span>
                {' '}
                will be deleted completely, and cannot be restored. Are you sure?
              </div>
            </div>
          </div>
          <div className={styles.buttonGroup}>
              <Button style={{ width: 85 }} title="Cancel" type="secondary" onClick={onClose} />
              <Button
                style={{ width: 120, marginLeft: 12 }}
                type="red"
                title="Delete"
                disabled={submitting}
                onClick={() => {
                  sendDeleteRequest({ folders: [], variables });
                }}
              />
          </div>
        </div>
      </div>
    </div>
  );
}

export default RemoveVariablesModal;

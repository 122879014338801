/* eslint-disable import/prefer-default-export */
export const Connections = {
  listConnectionsRequest: 'LIST_CONNECTIONS_PENDING',
  listConnectionsSuccess: 'LIST_CONNECTIONS_SUCCESS',
  listConnectionsError: 'LIST_CONNECTIONS_ERROR',

  searchConnectionsRequest: 'SEARCH_CONNECTIONS_PENDING',
  searchConnectionsSuccess: 'SEARCH_CONNECTIONS_SUCCESS',
  searchConnectionsError: 'SEARCH_CONNECTIONS_ERROR',

  createConnectionRequest: 'CREATE_CONNECTION_PENDING',
  createConnectionSuccess: 'CREATE_CONNECTION_SUCCESS',
  createConnectionError: 'CREATE_CONNECTION_ERROR',

  removeConnectionsListRequest: 'REMOVE_CONNECTIONS_LIST_PENDING',
  removeConnectionsListSuccess: 'REMOVE_CONNECTIONS_LIST_SUCCESS',
  removeConnectionsListError: 'REMOVE_CONNECTIONS_LIST_ERROR',

  updateConnectionRequest: 'UPDATE_CONNECTION_PENDING',
  updateConnectionSuccess: 'UPDATE_CONNECTION_SUCCESS',
  updateConnectionError: 'UPDATE_CONNECTION_ERROR',

  copyConnectionRequest: 'COPY_CONNECTION_PENDING',
  copyConnectionSuccess: 'COPY_CONNECTION_SUCCESS',
  copyConnectionError: 'COPY_CONNECTION_ERROR',

  exportConnectionRequest: 'EXPORT_CONNECTION_PENDING',
  exportConnectionSuccess: 'EXPORT_CONNECTION_SUCCESS',
  exportConnectionError: 'EXPORT_CONNECTION_ERROR',

  importConnectionRequest: 'IMPORT_CONNECTION_PENDING',
  importConnectionSuccess: 'IMPORT_CONNECTION_SUCCESS',
  importConnectionError: 'IMPORT_CONNECTION_ERROR',

  configConnectionsRequest: 'CONFIG_CONNECTIONS_PENDING',
  configConnectionsSuccess: 'CONFIG_CONNECTIONS_SUCCESS',
  configConnectionsError: 'CONFIG_CONNECTIONS_ERROR',

  getConnectionFolderContentRequest: 'GET_CONNECTION_FOLDER_CONTENT_REQUEST',
  getConnectionFolderContentSuccess: 'GET_CONNECTION_FOLDER_CONTENT_SUCCESS',

  createConnectionFolderRequest: 'CREATE_CONNECTION_FOLDER_REQUEST',
  createConnectionFolderSuccess: 'CREATE_CONNECTION_FOLDER_SUCCESS',

  updateConnectionFolderRequest: 'UPDATE__CONNECTION_FOLDER_REQUEST',
  updateConnectionFolderSuccess: 'UPDATE_CONNECTION_FOLDER_SUCCESS',

  removeConnectionFolderRequest: 'REMOVE_CONNECTION_FOLDER_REQUEST',
  removeConnectionFolderSuccess: 'REMOVE_CONNECTION_FOLDER_SUCCESS',

  moveConnectionFolderRequest: 'MOVE_CONNECTION_FOLDER_REQUEST',
  moveConnectionFolderSuccess: 'MOVE_CONNECTION_FOLDER_SUCCESS',

  copyConnectionFolderRequest: 'COPY_CONNECTION_FOLDER_REQUEST',
  copyConnectionFolderSuccess: 'COPY_CONNECTION_FOLDER_SUCCESS',

  moveConnectionRequest: 'MOVE_CONNECTION_REQUEST',
  moveConnectionSuccess: 'MOVE_CONNECTION_SUCCESS',

  getConnectionFolderTreeRequest: 'GET_CONNECTION_FOLDER_TREE_REQUEST',
  getConnectionFolderTreeSuccess: 'GET_CONNECTION_FOLDER_TREE_SUCCESS',
};

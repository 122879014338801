export function api(route, method, data, token) {
  const headers = {
    'Content-Type': 'application/json',
  };
  const authHeader = token ? `Bearer ${token}` : undefined;
  if (authHeader) {
    headers.Authorization = authHeader;
  }
  return fetch(`/internal${route}`, {
    method: method || 'GET',
    headers,
    body: data ? JSON.stringify(data) : undefined,
  })
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
}

export function apiWorker(route, method, data, token) {
  const headers = {
    'Content-Type': 'application/json',
  };
  const body = data ? JSON.stringify(data) : undefined;
  const authHeader = token ? `Bearer ${token}` : undefined;

  if (authHeader) {
    headers.Authorization = authHeader;
  }

  return fetch(`/worker${route}`, {
    method: method || 'GET',
    headers,
    body,
  })
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
}

export function apiWorkerFile(route, token, file, name) {
  return new Promise((resolve) => {
    const authHeader = token ? `Bearer ${token}` : undefined;
    const API_ENDPOINT = `/worker${route}`;
    const xhr = new XMLHttpRequest();
    const formData = new FormData();

    xhr.open('POST', API_ENDPOINT, true);
    xhr.setRequestHeader('Authorization', authHeader);
    if(name){
      formData.append('file', file, name);
    }else{
      formData.append('file', file);
    }
    xhr.onload = function () { resolve(xhr.response); };
    xhr.onerror = function () { resolve(xhr.response); };
    xhr.send(formData);
  });
}

export function debugApi(url, method, data, auth) {
  const { authType, authParams } = auth || {};
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  };
  const authHeader = authType === 'basic' ? `Basic ${btoa(`${authParams.user}:${authParams.password}`)}` : undefined;
  if (authHeader) {
    headers.Authorization = authHeader;
  }
  return fetch(url, {
    method: method || 'GET',
    headers,
    body: data ? JSON.stringify(data) : undefined,
  })
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
}

export function isMessageObjectLike(obj) {
  return obj && obj.msg && typeof obj.msg === 'object' && Object.keys(obj.msg).length;
}

export function getFirstCode(obj) {
  return obj && obj.msg && typeof obj.msg === 'object' && Object.keys(obj.msg).length ? Object.keys(obj.msg)[0] : null;
}

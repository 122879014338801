/* eslint-disable import/prefer-default-export */
export const AuthKeys = {
  listAuthKeysRequest: 'LIST_AUTH_KEYS_PENDING',
  listAuthKeysSuccess: 'LIST_AUTH_KEYS_SUCCESS',
  listAuthKeysError: 'LIST_AUTH_KEYS_ERROR',

  searchAuthKeysRequest: 'SEARCH_AUTH_KEYS_PENDING',
  searchAuthKeysSuccess: 'SEARCH_AUTH_KEYS_SUCCESS',
  searchAuthKeysError: 'SEARCH_AUTH_KEYS_ERROR',

  createAuthKeyRequest: 'CREATE_AUTH_KEY_PENDING',
  createAuthKeySuccess: 'CREATE_AUTH_KEY_SUCCESS',
  createAuthKeyError: 'CREATE_AUTH_KEY_ERROR',

  removeAuthKeysListRequest: 'REMOVE_AUTH_KEYS_LIST_PENDING',
  removeAuthKeysListSuccess: 'REMOVE_AUTH_KEYS_LIST_SUCCESS',
  removeAuthKeysListError: 'REMOVE_AUTH_KEYS_LIST_ERROR',

  updateAuthKeyRequest: 'UPDATE_AUTH_KEY_PENDING',
  updateAuthKeySuccess: 'UPDATE_AUTH_KEY_SUCCESS',
  updateAuthKeyError: 'UPDATE_AUTH_KEY_ERROR',

  copyAuthKeyRequest: 'COPY_AUTH_KEY_PENDING',
  copyAuthKeySuccess: 'COPY_AUTH_KEY_SUCCESS',
  copyAuthKeyError: 'COPY_AUTH_KEY_ERROR',

  exportAuthKeyRequest: 'EXPORT_AUTH_KEY_PENDING',
  exportAuthKeySuccess: 'EXPORT_AUTH_KEY_SUCCESS',
  exportAuthKeyError: 'EXPORT_AUTH_KEY_ERROR',

  importAuthKeyRequest: 'IMPORT_AUTH_KEY_PENDING',
  importAuthKeySuccess: 'IMPORT_AUTH_KEY_SUCCESS',
  importAuthKeyError: 'IMPORT_AUTH_KEY_ERROR',

  configAuthKeysRequest: 'CONFIG_AUTH_KEYS_PENDING',
  configAuthKeysSuccess: 'CONFIG_AUTH_KEYS_SUCCESS',
  configAuthKeysError: 'CONFIG_AUTH_KEYS_ERROR',

  getAuthKeyFolderContentRequest: 'GET_AUTH_KEY_FOLDER_CONTENT_REQUEST',
  getAuthKeyFolderContentSuccess: 'GET_AUTH_KEY_FOLDER_CONTENT_SUCCESS',

  createAuthKeyFolderRequest: 'CREATE_AUTH_KEY_FOLDER_REQUEST',
  createAuthKeyFolderSuccess: 'CREATE_AUTH_KEY_FOLDER_SUCCESS',

  updateAuthKeyFolderRequest: 'UPDATE__AUTH_KEY_FOLDER_REQUEST',
  updateAuthKeyFolderSuccess: 'UPDATE_AUTH_KEY_FOLDER_SUCCESS',

  removeAuthKeyFolderRequest: 'REMOVE_AUTH_KEY_FOLDER_REQUEST',
  removeAuthKeyFolderSuccess: 'REMOVE_AUTH_KEY_FOLDER_SUCCESS',

  moveAuthKeyFolderRequest: 'MOVE_AUTH_KEY_FOLDER_REQUEST',
  moveAuthKeyFolderSuccess: 'MOVE_AUTH_KEY_FOLDER_SUCCESS',

  copyAuthKeyFolderRequest: 'COPY_AUTH_KEY_FOLDER_REQUEST',
  copyAuthKeyFolderSuccess: 'COPY_AUTH_KEY_FOLDER_SUCCESS',

  moveAuthKeyRequest: 'MOVE_AUTH_KEY_REQUEST',
  moveAuthKeySuccess: 'MOVE_AUTH_KEY_SUCCESS',

  getAuthKeyFolderTreeRequest: 'GET_AUTH_KEY_FOLDER_TREE_REQUEST',
  getAuthKeyFolderTreeSuccess: 'GET_AUTH_KEY_FOLDER_TREE_SUCCESS',
};

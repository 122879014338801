import React from 'react';
import { Outlet } from 'react-router-dom';
import Layout from '../Layout/Layout';

function Main() {
  return (
    <Layout>
      <Outlet />
    </Layout>
  );
}

export default Main;

/* eslint-disable max-len */
import { AuthKeys } from './constants';

export const listAuthKeysRequest = (data, callback) => ({ type: AuthKeys.listAuthKeysRequest, data, callback });
export const listAuthKeysSuccess = (data) => ({ type: AuthKeys.listAuthKeysSuccess, data });
export const listAuthKeysError = () => ({ type: AuthKeys.listAuthKeysError });

export const searchAuthKeysRequest = (data, callback) => ({ type: AuthKeys.searchAuthKeysRequest, data, callback });
export const searchAuthKeysSuccess = (data) => ({ type: AuthKeys.searchAuthKeysSuccess, data });
export const searchAuthKeysError = () => ({ type: AuthKeys.searchAuthKeysError });

export const createAuthKeyRequest = (data, callback) => ({ type: AuthKeys.createAuthKeyRequest, data, callback });
export const createAuthKeySuccess = (data) => ({ type: AuthKeys.createAuthKeySuccess, data });
export const createAuthKeyError = () => ({ type: AuthKeys.createAuthKeyError });

export const removeAuthKeysListRequest = (data, callback) => ({ type: AuthKeys.removeAuthKeysListRequest, data, callback });
export const removeAuthKeysListSuccess = (data) => ({ type: AuthKeys.removeAuthKeysListSuccess, data });

export const updateAuthKeyRequest = (data, callback) => ({ type: AuthKeys.updateAuthKeyRequest, data, callback });
export const updateAuthKeySuccess = (data) => ({ type: AuthKeys.updateAuthKeySuccess, data });
export const updateAuthKeyError = () => ({ type: AuthKeys.updateAuthKeyError });

export const copyAuthKeyRequest = (data, callback) => ({ type: AuthKeys.copyAuthKeyRequest, data, callback });
export const copyAuthKeySuccess = (data) => ({ type: AuthKeys.copyAuthKeySuccess, data });
export const copyAuthKeyError = () => ({ type: AuthKeys.copyAuthKeyError });

export const exportAuthKeyRequest = (data, callback) => ({ type: AuthKeys.exportAuthKeyRequest, data, callback });
export const exportAuthKeySuccess = (data) => ({ type: AuthKeys.exportAuthKeySuccess, data });
export const exportAuthKeyError = () => ({ type: AuthKeys.exportAuthKeyError });

export const importAuthKeyRequest = (data, callback) => ({ type: AuthKeys.importAuthKeyRequest, data, callback });
export const importAuthKeySuccess = (data) => ({ type: AuthKeys.importAuthKeySuccess, data });
export const importAuthKeyError = () => ({ type: AuthKeys.importAuthKeyError });

export const configAuthKeysRequest = () => ({ type: AuthKeys.configAuthKeysRequest });
export const configAuthKeysSuccess = (data) => ({ type: AuthKeys.configAuthKeysSuccess, data });
export const configAuthKeysError = () => ({ type: AuthKeys.configAuthKeysError });

export const createAuthKeyFolderRequest = (data, callback) => ({ type: AuthKeys.createAuthKeyFolderRequest, data, callback });
export const createAuthKeyFolderSuccess = (data) => ({ type: AuthKeys.createAuthKeyFolderSuccess, data });

export const updateAuthKeyFolderRequest = (data, callback) => ({ type: AuthKeys.updateAuthKeyFolderRequest, data, callback });
export const updateAuthKeyFolderSuccess = (data) => ({ type: AuthKeys.updateAuthKeyFolderSuccess, data });

export const removeAuthKeyFolderRequest = (data, callback) => ({ type: AuthKeys.removeAuthKeyFolderRequest, data, callback });
export const removeAuthKeyFolderSuccess = (data) => ({ type: AuthKeys.removeAuthKeyFolderSuccess, data });

export const getAuthKeyFolderContentRequest = (data, callback) => ({ type: AuthKeys.getAuthKeyFolderContentRequest, data, callback });
export const getAuthKeyFolderContentSuccess = (data) => ({ type: AuthKeys.getAuthKeyFolderContentSuccess, data });

export const moveAuthKeyFolderRequest = (data, callback) => ({ type: AuthKeys.moveAuthKeyFolderRequest, data, callback });
export const moveAuthKeyFolderSuccess = (data) => ({ type: AuthKeys.moveAuthKeyFolderSuccess, data });

export const copyAuthKeyFolderRequest = (data, callback) => ({ type: AuthKeys.copyAuthKeyFolderRequest, data, callback });
export const copyAuthKeyFolderSuccess = (data) => ({ type: AuthKeys.copyAuthKeyFolderSuccess, data });

export const moveAuthKeyRequest = (data, callback) => ({ type: AuthKeys.moveAuthKeyRequest, data, callback });
export const moveAuthKeySuccess = (data) => ({ type: AuthKeys.moveAuthKeySuccess, data });

export const getAuthKeyFolderTreeRequest = (data, callback) => ({ type: AuthKeys.getAuthKeyFolderTreeRequest, data, callback });
export const getAuthKeyFolderTreeSuccess = (data) => ({ type: AuthKeys.getAuthKeyFolderTreeSuccess, data });

/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useState, useEffect, useCallback,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import JSZip from 'jszip';
import ActionIcon from '../../components/ActionIcon/ActionIcon';
import ActionText from '../../components/ActionText/ActionText';
import DBIcon from '../../components/DBIcon/DBIcon';
import ChipsStatus from '../../components/ChipsStatus/ChipsStatus';
import Button from '../../components/Button/Button';
import { createToastRequest } from '../../store/toasts/actions';
import {
  importConnectionRequest,
  listConnectionsRequest,
} from '../../store/connections/actions';
import { shortenText, convertOldNames } from '../../helpers/utils';
import { typesAPI } from '../../helpers/const';

import SortIcon from '../../pictures/sort.svg';
import Import from '../../pictures/import.svg';

import 'react-tooltip/dist/react-tooltip.css';
import * as shared from '../../styles/shared.module.scss';
import Checkbox from '../../components/Checkbox/Checkbox';
import ImportModal from '../../components/ImportModal/ImportModal';

const headers = {
  checkbox: { title: '', width: '3%' },
  id: { title: 'ID', width: '9%' },
  instanceName: { title: 'Instance name', width: '16%' },
  connectionName: { title: 'Connection name', width: '17%' },
  connectionType: { title: 'Connection type', width: '17%' },
  username: { title: 'Username', width: '16%' },
  status: { title: 'Status', width: '9%' },
  action: { title: 'Actions', width: '10%' },
};

const allowedZipFormats = ['application/zip', 'application/x-zip-compressed'];

function ConnectionList({
  activeProject,
  getConnections,
  importConnection,
  createToast,
  connections,
}) {
  const { data: connectionsData = [] } = connections || {};
  const [importConnectionModalOpened, setImportConnectionModalOpened] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedEndpoints, setSelectedEndpoints] = useState([]);
  const [selectedVariables, setSelectedVariables] = useState([]);
  const [connectionsIm, setConnectionsIm] = useState([]);
  const [endpointsIm, setEndpointsIm] = useState([]);
  const [variablesIm, setVariablesIm] = useState([]);
  const [file, setFile] = useState(null);
  const { shortName } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    activeProject?.id && getConnections({ id: activeProject.id });
  }, [activeProject]);

  const getChipByStatus = (status) => {
    if (status === 'conflicted') {
      return <ChipsStatus title="Conflict" type="error" />;
    } if (status === 'draft') {
      return <ChipsStatus title="Draft" type="draft" />;
    } 
      return <ChipsStatus title="New" type="success" />;
  };

  const listConnection = () => navigate(`/${shortName}/connections/`);

  const onDrop = useCallback(async (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();

    reader.onabort = () => console.log('file reading was aborted');
    reader.onerror = () => console.log('file reading has failed');
    reader.readAsArrayBuffer(file);
    if (!allowedZipFormats.includes(file.type)) {
      createToast({ type: 'error', text: 'File format must be zip' });
      return;
    }
    setFile(file);

    const zip = new JSZip();
    const extractedFiles = await zip.loadAsync(file);
    extractedFiles.forEach(async (relativePath, file) => {
      const f = await file.async('string');
      const parsedFile = JSON.parse(f);
      if (!parsedFile?.connections || parsedFile?.connections?.length === 0) {
        createToast({ type: 'error', text: 'connections not found' });
        return;
      }
      if (parsedFile?.endpoints && parsedFile.endpoints?.length > 0) {
        const listEndpoints = parsedFile.endpoints
          .map((c) => ({ ...c, status: connectionsData.find((con) => con.id === c.id) ? 'conflicted' : 'new' }));
        setEndpointsIm(listEndpoints);
      }
      const listConntetions = parsedFile.connections
        .map((c) => ({ ...c, status: connectionsData.find((con) => con.id === c.id) ? 'conflicted' : 'new' }));

      setConnectionsIm(listConntetions);

      if (parsedFile?.variables && parsedFile.variables?.length > 0) {
        const listVariables = parsedFile.variables.map((c) => ({
          ...c,
          status: connectionsData.find((con) => con.id === c.id) ? 'conflicted' : 'new',
        }));

        setVariablesIm(listVariables);
      }
      // save the file in the desired location
    });
  }, [connectionsData]);

  const { getRootProps, getInputProps, open } = useDropzone({ onDrop, noClick: true });

  const selectAll = (isChecked) => {
    setSelectedItems(isChecked ? connectionsIm : []);
    setSelectedEndpoints(isChecked ? endpointsIm : []);
    setSelectedVariables(isChecked ? variablesIm : []);
  };

  const handleChangePickedEndpoints = (newConnection) => {
    setSelectedEndpoints(() => endpointsIm.reduce((acc, val) => {
      if (newConnection.some((connectionItem) => val.connectionId === connectionItem.id)) {
        return [...acc, val];
      }
      return acc;
    }, []));
  };

  const selectOne = (isChecked, model) => {
    setSelectedItems((prev) => {
      const newConnections = isChecked ? [...prev, model] : prev.filter((c) => c.id !== model.id);
      handleChangePickedEndpoints(newConnections);
      setSelectedVariables(newConnections.length ? variablesIm : []);
      return newConnections;
    });
  };

  const importC = () => {
    setImportConnectionModalOpened(true);
  };

  const importConnections = (linkApi, linkVariables) => {
    let connectionsList = '';
    selectedItems.forEach((item) => connectionsList += connectionsList ? `,${item.id}` : `${item.id}`);
    importConnection({
      file,
      connections: connectionsList,
      linkApi,
      linkVariables,
      projectId: activeProject.id,
    }, listConnection);
  };

  return (
    <div className={shared.page}>
      <div className={shared.header}>
        <div className={shared.headerTitleGroup}>
          <h1 className={shared.headerTitle}>Import Connections</h1>
        </div>
        <div className={shared.headerButtonGroup}>
          <Button title="Import Connection" onClick={importC} disabled={selectedItems.length === 0} />
        </div>
      </div>
      <div className={shared.table}>
        <table className={shared.connectionTable}>
          <thead>
            <tr>
              <th key="checkbox" width={headers.checkbox.width}>
                <Checkbox
                  handleChange={selectAll}
                  size="small"
                  value={selectedItems.length > 0 && selectedItems.length === connectionsIm?.length}
                />
              </th>
              {Object.keys(headers).map((h, i, arr) => h !== 'checkbox' && (
                <th key={headers[h].title} width={headers[h].width}>
                  <span>{headers[h].title}</span>
                  {' '}
                  {i < arr.length - 1 && <SortIcon />}
                </th>
              ))}
            </tr>
          </thead>
          {connectionsIm?.length ? (
            <tbody>
              {connectionsIm.map((model, index) => (
                <tr
                  key={model.id}
                  className={`${selectedItems.find((s) => s.id === model.id) && shared.selected}`}
                  role="button"
                  tabIndex={0}
                >
                  <td width={headers.checkbox.width}>
                    <Checkbox
                      handleChange={(isCheked) => selectOne(isCheked, model)}
                      value={selectedItems.find((s) => s.id === model.id)}
                      size="small"
                    />
                  </td>
                  <td width={headers.id.width}><span>{shortenText(model.id)}</span></td>
                  <td width={headers.instanceName.width}>
                    <span>{model.instanceName}</span>
                    {model.description
                      && (
                      <div className={shared.controlIcons}>
                        <ActionIcon
                          id={`${index}_${model.id}_tooltip`}
                          icon="info"
                          style={{ marginRight: '8px' }}
                          onClick={() => {}}
                          tooltip={{
                            content: model.description,
                          }}
                        />
                      </div>
                      )}
                  </td>
                  <td width={headers.connectionName.width}>
                    <span>{model.connectionSettings.database}</span>
                  </td>
                  <td width={headers.connectionType.width}>
                    <DBIcon type={convertOldNames(model.type)} style={{ marginRight: '12px' }} />
                    <span>{typesAPI[convertOldNames(model.type)]}</span>
                  </td>
                  <td width={headers.username.width}>
                    <span>
                      {model.connectionSettings.user}
                    </span>
                  </td>
                  <td width={headers.status.width}>
                    {getChipByStatus(model.status)}
                  </td>
                  <td width={headers.action.width} />
                </tr>
              ))}
            </tbody>
          ) : null}
        </table>
        {!connectionsIm?.length && (
          <div className={shared.emptyContent} {...getRootProps()}>
            <input {...getInputProps()} type="file" style={{ display: 'none' }} accept=".zip" />
            <div
              className={shared.iconWrap}
              role="button"
              onClick={open}
              onKeyDown={open}
              tabIndex="0"
            >
              <Import />
            </div>
            <p>
              Drop your .zip file with Connections here to start import process
            </p>
            <p>
              or
              {' '}
              <div className={shared.action} style={{ display: 'inline' }}>
                <ActionText title="Select file" onClick={open} />
              </div>
            </p>
          </div>
        )}
        {importConnectionModalOpened && (
          <ImportModal
            onClose={() => setImportConnectionModalOpened(false)}
            onSubmit={importConnections}
            list={{ 
              apis: selectedEndpoints, 
              connections: selectedItems, 
              keys: [],
              variables: selectedVariables 
            }}
            typeImport="connections"
          />
        )}
      </div>
    </div>
  );
}

const mapStateToProps = ({ activeProject, connections }) => ({
  activeProject,
  connections,
});

const mapDispatchToProps = (dispatch) => ({
  getConnections: (data) => dispatch(listConnectionsRequest(data)),
  importConnection: (data, callback) => dispatch(importConnectionRequest(data, callback)),
  createToast: (data) => dispatch(createToastRequest(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConnectionList);

/* eslint-disable max-len */
/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Checkbox from '../../../../components/Checkbox/Checkbox';
import ActionIcon from '../../../../components/ActionIcon/ActionIcon';
import DBIcon from '../../../../components/DBIcon/DBIcon';
import ChipsStatus from '../../../../components/ChipsStatus/ChipsStatus';
import {
  formatTimestamp
} from '../../../../helpers/utils';

import ApiGraphIcon from '../../../../pictures/api-graph.svg';
import FolderIcon from '../../../../pictures/folder.svg';
import * as shared from '../../../../styles/shared.module.scss';
import { ContextMenu, contextConfig, prepareConfig } from '../../../../components/ContextMenu';
import { replaceTextWithVariables } from '../../../../helpers/tokenizeAutocompleteUtils';

function Directory({
  folderId,
  foldersData,
  endpointsData,
  selectedFolders,
  selectedItems,
  handleContextMenu,
  setContextMenuModelActive,
  openFolderAction,
  selectOneFolder,
  copyFolder,
  selectItems,
  contextMenuModelActive,
  contextMenuTop,
  contextMenuLeft,
  modifyEndpoint,
  copyEndpoint,
  selectOne,
  renameFolder,
  clickShare,
  isRoleAction,
  exportOne,
  removeOne,
  getFolder,
  depth = 0,
  moveFolder,
  moveObject,
  copyCon,
  activeProject,
  searchActive,
}) {
  const [sortBy, setSortBy] = useState('name');
  const [expanded, setExpanded] = useState({});
  const [activeTarget, setActiveTarget] = useState(null);

  // const sortEndpoints = (endpointsList) => endpointsList.sort((a, b) => a[sortBy].localeCompare(b[sortBy]));
  // const sortFolders = (foldersList) => foldersList.sort((a, b) => a[sortBy].localeCompare(b[sortBy]));

  const sortEndpoints = (endpointsList) => endpointsList;
  const sortFolders = (foldersList) => foldersList;

  const openDirectory = (id, f) => {
    if (f) {
      getFolder({ folder: id || 0, merge: true, projectId: activeProject?.id }, (res) => {
        const { folders, data } = res || {};
        const foldersIds = folders.map((fld) => fld.id);
        const dataIds = data.map((d) => d.id);
        const currentIdSelected = selectedFolders?.find((el) => el === (id || 0));

        if (currentIdSelected) selectItems(foldersIds, dataIds);
      });
    }
    setExpanded({ ...expanded, [id]: f });
  };

  const handleDragStart = (e, model) => {
    e.dataTransfer.setData('model', JSON.stringify(model));
  };

  const handleDragEnter = (e, model) => {
    setActiveTarget(model?.id);
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragOver = (e, model) => {
    setActiveTarget(model?.id);
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDragLeave = (e, model) => {
    setActiveTarget(null);
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDrop = (e, model, isObj) => {
    const destinationId = isObj ? model.folderId : model.id;
    setActiveTarget(null);
    e.preventDefault();
    e.stopPropagation();
    const data = e.dataTransfer.getData('model');
    const parsed = JSON.parse(data);

    if (parsed.type === 'endpoints' && parsed.id !== destinationId && parsed.parentId !== destinationId && parsed.folderId !== destinationId) {
      moveFolder(parsed, destinationId);
    } else if (parsed.id !== destinationId && parsed.parentId !== destinationId && parsed.folderId !== destinationId) {
      moveObject(parsed, destinationId);
    }
  };

  const blockEvent = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  return (
    <>
      {sortFolders(foldersData).map((model) => (
        <React.Fragment key={`folder_${model.id}`}>
          <tr
            className={cn(
              shared.connectionTableRowEndpoints,
              (selectedFolders.includes(model.id) && shared.selected),
            )}
            style={{
              backgroundColor: activeTarget === model.id ? '#DBD4F9' : undefined,
            }}
            role="button"
            tabIndex={0}
            onContextMenu={(e) => handleContextMenu(e, model.id, true)}
            onClick={() => setContextMenuModelActive(false)}
            onDoubleClick={() => openFolderAction(model.id)}
            draggable={!searchActive}
            onDrop={(e) => handleDrop(e, model)}
            onDragStart={(e) => handleDragStart(e, model)}
            onDragEnter={(e) => handleDragEnter(e, model)}
            onDragOver={(e) => handleDragOver(e, model)}
            onDragLeave={(e) => handleDragLeave(e, model)}
          >
            <td>
              <div onDoubleClick={blockEvent}>
                <Checkbox
                  handleChange={(isChecked) => selectOneFolder(isChecked, model.id)}
                  value={selectedFolders.includes(model.id)}
                  size="small"
                />
              </div>
            </td>
            <td style={{ position: 'relative', gap: '8px' }}>
              <div
                onDoubleClick={blockEvent}
                style={{ marginLeft: `calc(${depth} * 32px)`, transform: !expanded[model.id] ? 'rotate(0)' : 'rotate(90deg)', transition: '.3s all', minWidth: 24 }}
              >
                {!searchActive && (
                  <ActionIcon
                    large
                    icon="openArrow"
                    onClick={() => openDirectory(model.id, !expanded[model.id])}
                  />
                )}
              </div>
              <FolderIcon />
              <span>
                {model.name}
              </span>
            </td>
            <td />
            <td />
            <td />
            <td />
            <td />
            <td>
              <div className={shared.controlIcons} onDoubleClick={blockEvent}>
                <ActionIcon
                  id={`folder_${model.id}_edit`}
                  icon="edit"
                  onClick={() => renameFolder(model)}
                  hidden={!isRoleAction({ key: 'endpoint', role: 'update', modelRole: model.role })}
                  tooltip={{
                    content: 'Rename',
                  }}
                />
                <ActionIcon
                  id={`folder_${model.id}_copy`}
                  icon="copy"
                  onClick={() => copyFolder(model)}
                  hidden={!isRoleAction({ key: 'endpoint', role: 'create', modelRole: model.role })}
                  tooltip={{
                    content: 'Copy',
                  }}
                />
                <ActionIcon
                  id={`folder_${model.id}_addUser`}
                  icon="addUser"
                  onClick={() => clickShare(model)}
                  hidden={!isRoleAction({ key: 'endpoint', role: 'share', modelRole: model.role })}
                  tooltip={{
                    content: 'Share',
                  }}
                />
                <ActionIcon
                  id={`folder_${model.id}_export`}
                  icon="export"
                  hiddenFill
                  onClick={() => exportOne('folder', model)}
                  tooltip={{
                    content: 'Export',
                  }}
                />
                <ActionIcon
                  id={`folder_${model.id}_delete`}
                  icon="trash"
                  onClick={() => removeOne('folder', model)}
                  hidden={!isRoleAction({ key: 'endpoint', role: 'delete', modelRole: model.role })}
                  tooltip={{
                    content: 'Delete',
                  }}
                />
              </div>
              <div className={shared.controlIconsHideList}>
                <span>{model.createdAt ? formatTimestamp(model.createdAt) : ''}</span>
              </div>
            </td>
          </tr>
          <ContextMenu
            contextConfig={prepareConfig(contextConfig, {
              edit: () => renameFolder(model),
              copy: () => copyFolder(model),
              share: () => clickShare(model),
              export: () => exportOne('folder', model),
              delete: () => removeOne('folder', model),
            }, 'foldersSupport')}
            contextMenuLeft={contextMenuLeft}
            contextMenuTop={contextMenuTop}
            isOpen={contextMenuModelActive === model.id}
          />
          {expanded[model.id] && model.items && (
          <Directory
            folderId={model.id}
            key={`directory__${model.id}`}
            foldersData={model.items.foldersData}
            endpointsData={model.items.data}
            selectedFolders={selectedFolders}
            selectedItems={selectedItems}
            handleContextMenu={handleContextMenu}
            setContextMenuModelActive={setContextMenuModelActive}
            openFolderAction={openFolderAction}
            selectOneFolder={selectOneFolder}
            copyFolder={copyFolder}
            selectItems={selectItems}
            contextMenuModelActive={contextMenuModelActive}
            contextMenuTop={contextMenuTop}
            contextMenuLeft={contextMenuLeft}
            modifyEndpoint={modifyEndpoint}
            copyEndpoint={copyEndpoint}
            selectOne={selectOne}
            renameFolder={renameFolder}
            clickShare={clickShare}
            isRoleAction={isRoleAction}
            exportOne={exportOne}
            removeOne={removeOne}
            getFolder={getFolder}
            moveFolder={moveFolder}
            moveObject={moveObject}
            copyCon={copyCon}
            activeProject={activeProject}
            depth={depth + 1}
            searchActive={searchActive}
            merge
          />
          )}
        </React.Fragment>
      ))}
      {sortEndpoints(endpointsData).map((model, index) => (
        <React.Fragment key={`endpoint_${model.id}`}>
          <tr
            style={{ position: 'relative' }}
            key={`endpoint_${model.id}`}
            className={cn(
              shared.connectionTableRowEndpoints,
              (selectedItems.includes(model.id) && shared.selected),
            )}
            draggable
            role="button"
            tabIndex={0}
            onDoubleClick={() => modifyEndpoint(model.id)}
            onClick={() => setContextMenuModelActive(false)}
            onContextMenu={(e) => handleContextMenu(e, model.id, false)}
            onDrop={(e) => handleDrop(e, model, true)}
            onDragStart={(e) => handleDragStart(e, model)}
            onDragEnter={(e) => handleDragEnter(e, model)}
            onDragOver={(e) => handleDragOver(e, model)}
            onDragLeave={(e) => handleDragLeave(e, model)}
          >
            <td>
              <Checkbox
                handleChange={(isCheked) => selectOne(isCheked, model.id)}
                value={selectedItems.includes(model.id)}
                size="small"
                style={{ marginRight: '0' }}
              />
            </td>
            <td style={{ marginLeft: `calc(${depth + 1} * 32px)`, gap: '8px' }}>
              <ApiGraphIcon />
              <span>
                {model.name}
              </span>
              {model.description && (
                <div className={shared.controlIcons}>
                  <ActionIcon
                    id={`${index}_${model.id}_tooltip`}
                    icon="info"
                    style={{ marginRight: '8px' }}
                    onClick={() => {}}
                    tooltip={{
                      content: model.description,
                      place: 'right',
                    }}
                  />
                </div>
              )}
            </td>
            <td>
              <span className={shared.uppercased}>{model.method}</span>
            </td>
            <td>
              <span>{replaceTextWithVariables(model.path, model.variables, 'value') }</span>
              <div className={shared.controlIcons}>
                <ActionIcon
                  icon="copy"
                  style={{ marginRight: '8px' }}
                  onClick={() => {
                    const transformedPath = replaceTextWithVariables(model.path, model.variables, 'value')
                    copyCon(transformedPath)
                  }}
                />
              </div>
            </td>
            <td>
              {model.connectionType && <DBIcon type={model.connectionType} style={{ marginRight: '12px', minWidth: '32px' }} />}
              <span>{model.connectionName}</span>
            </td>
            <td>
              <span>{model.owner ? `${model.owner.firstName} ${model.owner.lastName}` : ''}</span>
            </td>
            <td style={{ overflow: 'visible'}}>{model.status === 'active' ? <ChipsStatus title="Active" type="success" /> : <ChipsStatus title="Draft" type="draft" />}</td>
            <td>
              <div className={shared.controlIcons} onDoubleClick={blockEvent}>
                <ActionIcon
                  id={`${index}_${model.id}_edit`}
                  icon="edit"
                  onClick={() => modifyEndpoint(model.id)}
                  hidden={!isRoleAction({ key: 'endpoint', role: 'update', modelRole: model.role })}
                  tooltip={{
                    content: 'Edit',
                  }}
                />
                <ActionIcon
                  id={`${index}_${model.id}_copy`}
                  icon="copy"
                  onClick={() => copyEndpoint(model)}
                  hidden={!isRoleAction({ key: 'endpoint', role: 'create', modelRole: model.role })}
                  tooltip={{
                    content: 'Copy',
                  }}
                />
                <ActionIcon
                  id={`${index}_${model.id}_addUser`}
                  icon="addUser"
                  onClick={() => clickShare(model)}
                  hidden={!isRoleAction({ key: 'endpoint', role: 'share', modelRole: model.role })}
                  tooltip={{
                    content: 'Share',
                  }}
                />
                <ActionIcon
                  id={`${index}_${model.id}_export`}
                  icon="export"
                  hiddenFill
                  onClick={() => exportOne('endpoint', model)}
                  tooltip={{
                    content: 'Export',
                  }}
                />
                <ActionIcon
                  id={`${index}_${model.id}_delete`}
                  icon="trash"
                  onClick={() => removeOne('endpoint', model)}
                  hidden={!isRoleAction({ key: 'endpoint', role: 'delete', modelRole: model.role })}
                  tooltip={{
                    content: 'Delete',
                  }}
                />
              </div>
              <div className={shared.controlIconsHideList}>
                <span>{model.createdAt ? formatTimestamp(model.createdAt) : ''}</span>
              </div>
            </td>
          </tr>
          <ContextMenu
            contextConfig={prepareConfig(contextConfig, {
              edit: () => modifyEndpoint(model.id),
              copy: () => copyEndpoint(model),
              share: () => clickShare(model),
              export: () => exportOne('endpoint', model),
              delete: () => removeOne('endpoint', model),
            }, 'itemSupport')}
            contextMenuTop={contextMenuTop}
            contextMenuLeft={contextMenuLeft}
            isOpen={contextMenuModelActive === model.id}
          />
        </React.Fragment>
      ))}
    </>
  );
}

Directory.propTypes = {
  selectedFolders: PropTypes.array,
  selectedItems: PropTypes.array,
  foldersData: PropTypes.array,
  endpointsData: PropTypes.array,
};

export default Directory;

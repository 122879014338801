/* eslint-disable import/prefer-default-export */
export const ActiveEndpoint = {
  activeEndpointRequest: 'ACTIVE_ENDPOINT_PENDING',
  activeEndpointSuccess: 'ACTIVE_ENDPOINT_SUCCESS',
  activeEndpointError: 'ACTIVE_ENDPOINT_ERROR',

  getEndpointConnectedAuthKeysRequest: 'GET_ENDPOINT_CONNECTED_AUTH_KEYS_REQUEST',
  getEndpointConnectedAuthKeysSuccess: 'GET_ENDPOINT_CONNECTED_AUTH_KEYS_SUCCESS',

  getAccessUsersEndpointSuccess: 'GET_ACCESS_USERS_ENDPOINT_SUCCESS',
  activeDisplayVersion: 'ACTIVE_DISPLAY_VERSION',
  activateOriginVersion: 'ACTIVATE_ORIGIN_VERSION',

  getEndpointConnectedAuthKeysLimitRequest: 'GET_ENDPOINT_CONNECTED_AUTH_KEYS_LIMIT_REQUEST',
  getEndpointConnectedAuthKeysLimitSuccess: 'GET_ENDPOINT_CONNECTED_AUTH_KEYS_LIMIT_SUCCESS',

  modifyEndpointConnectedAuthKeysLimitRequest: 'MODIFY_ENDPOINT_CONNECTED_AUTH_KEYS_LIMIT_REQUEST',
  modifyEndpointConnectedAuthKeysLimitSuccess: 'MODIFY_ENDPOINT_CONNECTED_AUTH_KEYS_LIMIT_SUCCESS',
};

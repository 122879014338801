/* eslint-disable max-len */
import { ActiveEndpoint } from './constants';

export const activeEndpointRequest = (data, callback) => ({ type: ActiveEndpoint.activeEndpointRequest, data, callback });
export const activeEndpointSuccess = (data) => ({ type: ActiveEndpoint.activeEndpointSuccess, data });
export const activeEndpointError = () => ({ type: ActiveEndpoint.activeEndpointError });

export const getEndpointConnectedAuthKeysRequest = (data, callback) => ({ type: ActiveEndpoint.getEndpointConnectedAuthKeysRequest, data, callback });
export const getEndpointConnectedAuthKeysSuccess = (data) => ({ type: ActiveEndpoint.getEndpointConnectedAuthKeysSuccess, data });

export const getAccessUsersEndpointSuccess = (data) => ({ type: ActiveEndpoint.getAccessUsersEndpointSuccess, data });
export const displayVersion = (data) => ({ type: ActiveEndpoint.activeDisplayVersion, data });
export const activateOriginVersion = () => ({ type: ActiveEndpoint.activateOriginVersion });

export const getEndpointConnectedAuthKeysLimitRequest = (data, callback) => ({ type: ActiveEndpoint.getEndpointConnectedAuthKeysLimitRequest, data, callback });
export const getEndpointConnectedAuthKeysLimitSuccess = (data) => ({ type: ActiveEndpoint.getEndpointConnectedAuthKeysLimitSuccess, data });

export const modifyEndpointConnectedAuthKeysLimitRequest = (data, callback) => ({ type: ActiveEndpoint.modifyEndpointConnectedAuthKeysLimitRequest, data, callback });
export const modifyEndpointConnectedAuthKeysLimitSuccess = (data) => ({ type: ActiveEndpoint.modifyEndpointConnectedAuthKeysLimitSuccess, data });

import { ActiveAuthKey } from './constants';

const initialState = {
  activeAuthKey: null,
  connectedEndpoints: [],
  inviteList: [],
};

export const activeAuthKeyReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case ActiveAuthKey.activeAuthKeySuccess:
      return {
        ...state,
        activeAuthKey: action.data
      }
    case ActiveAuthKey.getAccessUsersAuthKeySuccess:
      return {
        ...state,
        inviteList: action.data,
      }
    case ActiveAuthKey.getConnectedEndpointsToAuthKeysSuccess:
      return {
        ...state,
        connectedEndpoints: action.data
      }
    default:
      return state;
  }
};

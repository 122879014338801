/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useState, useEffect, useCallback,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import JSZip from 'jszip';
import ActionIcon from '../../components/ActionIcon/ActionIcon';
import ActionText from '../../components/ActionText/ActionText';
import ChipsStatus from '../../components/ChipsStatus/ChipsStatus';
import Button from '../../components/Button/Button';
import { createToastRequest } from '../../store/toasts/actions';
import {
  importAuthKeyRequest,
  listAuthKeysRequest,
} from '../../store/authKeys/actions';
import { shortenText } from '../../helpers/utils';

import SortIcon from '../../pictures/sort.svg';
import Import from '../../pictures/import.svg';

import 'react-tooltip/dist/react-tooltip.css';
import * as shared from '../../styles/shared.module.scss';
import Checkbox from '../../components/Checkbox/Checkbox';
import ImportModal from '../../components/ImportModal/ImportModal';

const headers = {
  checkbox: { title: '', width: '3%' },
  id: { title: 'ID', width: '8%' },
  name: { title: 'Name', width: '32%' },
  type: { title: 'Type', width: '32%' },
  status: { title: 'Status', width: '16%' },
  action: { title: 'Actions', width: '10%' },
};

const allowedZipFormats = ['application/zip', 'application/x-zip-compressed'];

function List({
  activeProject,
  getAuthKeys,
  importAuthKey,
  createToast,
  authKeys,
}) {
  const { data: keysData = [] } = authKeys || {};
  const [importAuthKeyModalOpened, setImportAuthKeyModalOpened] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedEndpoints, setSelectedEndpoints] = useState([]);
  const [keysIm, setKeysIm] = useState([]);
  const [endpointsIm, setEndpointsIm] = useState([]);
  const [file, setFile] = useState(null);
  const { shortName } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    activeProject?.id && getAuthKeys({ id: activeProject.id });
  }, [activeProject]);

  const getChipByStatus = (status) => {
    if (status === 'conflicted') {
      return <ChipsStatus title="Conflict" type="error" />;
    } if (status === 'draft') {
      return <ChipsStatus title="Draft" type="draft" />;
    } 
      return <ChipsStatus title="New" type="success" />;
  };

  const listAuthKey = () => navigate(`/${shortName}/auth-keys/`);

  const onDrop = useCallback(async (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();

    reader.onabort = () => console.log('file reading was aborted');
    reader.onerror = () => console.log('file reading has failed');
    reader.readAsArrayBuffer(file);
    if (!allowedZipFormats.includes(file.type)) {
      createToast({ type: 'error', text: 'File format must be zip' });
      return;
    }
    setFile(file);

    const zip = new JSZip();
    const extractedFiles = await zip.loadAsync(file);
    extractedFiles.forEach(async (relativePath, file) => {
      const f = await file.async('string');
      const parsedFile = JSON.parse(f);
      if (!parsedFile?.keys || parsedFile?.keys?.length === 0) {
        createToast({ type: 'error', text: 'auth keys not found' });
        return;
      }
      if (parsedFile?.endpoints && parsedFile.endpoints?.length > 0) {
        const listEndpoints = parsedFile.endpoints
          .map((c) => ({ ...c, status: keysData.find((con) => con.id === c.id) ? 'conflicted' : 'new' }));
        setEndpointsIm(listEndpoints);
      }
      const listKeys = parsedFile.keys
        .map((c) => ({ ...c, status: keysData.find((con) => con.id === c.id) ? 'conflicted' : 'new' }));

      setKeysIm(listKeys);
    });
  }, [keysData]);

  const { getRootProps, getInputProps, open } = useDropzone({ onDrop, noClick: true });

  const selectAll = (isChecked) => {
    setSelectedItems(isChecked ? keysIm : []);
    setSelectedEndpoints(isChecked ? endpointsIm : []);
  };

  const handleChangePickedEndpoints = (newKey) => {
    setSelectedEndpoints(() => endpointsIm.reduce((acc, val) => {
      if (newKey.some((keyItem) => val.connectionId === keyItem.id)) {
        return [...acc, val];
      }
      return acc;
    }, []));
  };

  const selectOne = (isChecked, model) => {
    setSelectedItems((prev) => {
      const newKeys = isChecked ? [...prev, model] : prev.filter((c) => c.id !== model.id);
      setSelectedEndpoints(newKeys.length ? endpointsIm : []);
      return newKeys;
    });
  };

  const importC = () => {
    setImportAuthKeyModalOpened(true);
  };

  const importAuthKeys = (linkApi) => {
    let keysList = '';
    selectedItems.forEach((item) => keysList += keysList ? `,${item.id}` : `${item.id}`);
    importAuthKey({
      file,
      authKeys: keysList,
      linkApi,
      projectId: activeProject.id,
    }, listAuthKey);
  };

  return (
    <div className={shared.page}>
      <div className={shared.header}>
        <div className={shared.headerTitleGroup}>
          <h1 className={shared.headerTitle}>Import Auth Keys</h1>
        </div>
        <div className={shared.headerButtonGroup}>
          <Button title="Import Auth Key" onClick={importC} disabled={selectedItems.length === 0} />
        </div>
      </div>
      <div className={shared.table}>
        <table className={shared.connectionTable}>
          <thead>
            <tr>
              <th key="checkbox" width={headers.checkbox.width}>
                <Checkbox
                  handleChange={selectAll}
                  size="small"
                  value={selectedItems.length > 0 && selectedItems.length === keysIm?.length}
                />
              </th>
              {Object.keys(headers).map((h, i, arr) => h !== 'checkbox' && (
                <th key={headers[h].title} width={headers[h].width}>
                  <span>{headers[h].title}</span>
                  {' '}
                  {i < arr.length - 1 && <SortIcon />}
                </th>
              ))}
            </tr>
          </thead>
          {keysIm?.length ? (
            <tbody>
              {keysIm.map((model, index) => (
                <tr
                  key={model.id}
                  className={`${selectedItems.find((s) => s.id === model.id) && shared.selected}`}
                  role="button"
                  tabIndex={0}
                >
                  <td width={headers.checkbox.width}>
                    <Checkbox
                      handleChange={(isCheked) => selectOne(isCheked, model)}
                      value={selectedItems.find((s) => s.id === model.id)}
                      size="small"
                    />
                  </td>
                  <td width={headers.id.width}><span>{shortenText(model.id)}</span></td>
                  <td width={headers.name.width}>
                    <span>{model.name}</span>
                    {model.description
                      && (
                      <div className={shared.controlIcons}>
                        <ActionIcon
                          id={`${index}_${model.id}_tooltip`}
                          icon="info"
                          style={{ marginRight: '8px' }}
                          onClick={() => {}}
                          tooltip={{
                            content: model.description,
                          }}
                        />
                      </div>
                      )}
                  </td>
                  <td width={headers.type.width}>
                    <span>{model.type}</span>
                  </td>
                  <td width={headers.status.width}>
                    {getChipByStatus(model.status)}
                  </td>
                  <td width={headers.action.width} />
                </tr>
              ))}
            </tbody>
          ) : null}
        </table>
        {!keysIm?.length && (
          <div className={shared.emptyContent} {...getRootProps()}>
            <input {...getInputProps()} type="file" style={{ display: 'none' }} accept=".zip" />
            <div
              className={shared.iconWrap}
              role="button"
              onClick={open}
              onKeyDown={open}
              tabIndex="0"
            >
              <Import />
            </div>
            <p>
              Drop your .zip file with Auth Keys here to start import process
            </p>
            <p>
              or
              {' '}
              <div className={shared.action} style={{ display: 'inline' }}>
                <ActionText title="Select file" onClick={open} />
              </div>
            </p>
          </div>
        )}
        {importAuthKeyModalOpened && (
          <ImportModal
            onClose={() => setImportAuthKeyModalOpened(false)}
            onSubmit={importAuthKeys}
            list={{ apis: selectedEndpoints, keys: selectedItems, connections: [], variables: [] }}
            typeImport="keys"
          />
        )}
      </div>
    </div>
  );
}

const mapStateToProps = ({ activeProject, authKeys }) => ({
  activeProject,
  authKeys,
});

const mapDispatchToProps = (dispatch) => ({
  getAuthKeys: (data) => dispatch(listAuthKeysRequest(data)),
  importAuthKey: (data, callback) => dispatch(importAuthKeyRequest(data, callback)),
  createToast: (data) => dispatch(createToastRequest(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(List);

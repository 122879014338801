/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-autofocus */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Button from '../Button/Button';
import { 
  removeProjectRequest
} from '../../store/projects/actions';

import * as styles from './RemoveProjectsModal.module.scss';

function RemoveProjectsModal({
  onClose,
  onSubmit,
  model,
  collection,
}) {
  const dispatch = useDispatch();
  const { projects: filteredProjects = [] } = model || {};

  const [submitting, setSubmitting] = useState(false);

  const sendDeleteRequest = (data) => {
    const { authKeys } = data || {};
    const [el] = authKeys || {};

      setSubmitting(true);
      dispatch(removeProjectRequest({ 
       id: el,
      }, (result) => {
        const { successAuthKeys } = result || {};
        if (result && (successAuthKeys?.length)) {
          setSubmitting(false);
          onSubmit();
        } else {
          setSubmitting(false);
        }
      }));
  };

  const numfilteredProjects = filteredProjects.length;

  return (
    <div className={styles.modal}>
      <div className={styles.modalContent}>
        <div className={styles.header}>
          <div className={styles.title}>Delete Items</div>
        </div>
        <div className={styles.body}>
          <div className={styles.mainContent}>
            <div>
              <div>

                {numfilteredProjects ? (
                  <span className={styles.modelTitle}>
                    {numfilteredProjects}
                    {' '}
                    {numfilteredProjects > 1 ? 'Projects' : 'Project'}
                  </span>
                ) : null}
                {' '}
                will be deleted. Are you sure?
              </div>
            </div>
          </div>
          <div className={styles.buttonGroup}>
              <Button style={{ width: 85 }} title="Cancel" type="secondary" onClick={onClose} />
              <Button
                style={{ width: 120, marginLeft: 12 }}
                type="red"
                title="Delete"
                disabled={submitting}
                onClick={() => {
                  sendDeleteRequest({ projects: filteredProjects });
                }}
              />
          </div>
        </div>
      </div>
    </div>
  );
}

export default RemoveProjectsModal;

/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-autofocus */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ThreeDots } from 'react-loader-spinner';
import Button from '../Button/Button';
import { 
  getFolderPath, getDataPath, filterItemsByExclude,
  getAllfoldersDataObjects, getAllDataObjects, isSafari,
  getFoldersDataInFolder, getDataInFolder
 } from '../../helpers/utils';
import { 
  getConnectionFolderTreeRequest,
  removeConnectionsListRequest,
  removeConnectionFolderSuccess, 
  removeConnectionsListSuccess
} from '../../store/connections/actions';

import * as styles from './RemoveConnectionsModal.module.scss';

function RemoveConnectionsModal({
  onClose,
  onSubmit,
  model,
  collection,
}) {
  const dispatch = useDispatch();
  const { connections = [], folders = [] } = model || {};
  const [filteredFolders, setFilteredFolders] = useState([]);
  const [filteredConnections, setFilteredConnections] = useState([]);
  const [nestedFolders, setNestedFolders] = useState([]);
  const [nestedConnections, setNestedConnections] = useState([]);

  const [loadDetails, setLoadDetails] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    const calcPaths = () => {
      const f = folders.map((el) => ({ id: el, path: getFolderPath(collection, el) }));
      const c = connections.map((el) => ({ id: el, path: getDataPath(collection, el) }));

      const fltF = filterItemsByExclude(f, folders) || [];
      const fltC = filterItemsByExclude(c, folders) || [];

      setFilteredFolders(fltF.map((el) => el.id));
      setFilteredConnections(fltC.map((el) => el.id));
    };

    calcPaths();
  }, [model, collection, folders, connections]);

  useEffect(() => {
    setLoadDetails(true);

    const fetchData = () => {
      if (!filteredFolders.length) {
        setLoadDetails(false);
        setNestedConnections([]);
        setNestedFolders([]);
      } else {
        dispatch(getConnectionFolderTreeRequest({ ids: filteredFolders }, (result) => {
          setLoadDetails(false);
          if (result) {
            const f = result.filter((el) => el.folders).map((el) => el.folders).flat() || [];
            const c = result.filter((el) => el.connections).map((el) => el.connections).flat() || [];
            setNestedConnections(c);
            setNestedFolders(f);
          } else {
            setNestedConnections([]);
            setNestedFolders([]);
          }
        }));
      }
    };

    fetchData();
  }, [filteredFolders, dispatch]);

  const sendDeleteRequest = (data) => {
    const { folders: fld = [], connections: conn = [] } = data || {};
    const allFolders = getAllfoldersDataObjects(collection);
    const allConnections = getAllDataObjects(collection);

    let transformedFolders = [];
    let transformedConnections = [];

    if (fld?.length) {
      transformedFolders = fld.map(f => allFolders.find(a => a.id === f)).filter(el => el);
    }

    if (conn?.length) {
      transformedConnections = conn.map(f => allConnections.find(a => a.id === f)).filter(el => el);
    }

    const excludedFolders = folders.filter(f => !fld.includes(f));
    const excludedConnections = connections.filter(c => !conn.includes(c));

    if (transformedFolders?.length || transformedConnections?.length) {
      setSubmitting(true);
      dispatch(removeConnectionsListRequest({ 
       connections: transformedConnections,
       folders: transformedFolders
      }, (result) => {
        const { successFolders, successConnections } = result || {};
        if (result && (successFolders?.length || successConnections?.length)) {
          if (successFolders?.length) {
            successFolders.forEach((f) => {
              const nestedFolders = getFoldersDataInFolder(collection, f.id);
              const nestedData = getDataInFolder(collection, f.id);
  
              excludedFolders?.forEach(f => {
                if (nestedFolders.includes(f)) dispatch(removeConnectionFolderSuccess({ id: f }));
              });
              excludedConnections?.forEach(c => {
                if (nestedData.includes(c)) dispatch(removeConnectionsListSuccess({ id: c }));
              });
            });
          }
          setSubmitting(false);
          onSubmit();
        } else {
          setSubmitting(false);
        }
      }));
    }
  };

  const numFilteredFolders = nestedFolders.length || filteredFolders.length;
  const numFilteredConnections = filteredConnections.length + nestedConnections.length;

  return (
    <div className={styles.modal}>
      <div className={styles.modalContent}>
        <div className={styles.header}>
          <div className={styles.title}>Delete Items</div>
        </div>
        <div className={styles.body}>
          <div className={styles.mainContent}>
            <div>
              <div>
              {loadDetails && !isSafari() && (
                  <span className={styles.modelTitle}
                  style={{ marginRight: '12px' }}
                  >
                  <ThreeDots
                    height="13"
                    width="13"
                    color="#4149F7"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{
                      display: 'inline',
                      transform: 'translateY(-20px)'
                    }}
                    visible
                  />
                  </span>
                )}
                {(numFilteredFolders && !loadDetails) ? (
                  <span className={styles.modelTitle}>
                    {numFilteredFolders}
                    {' '}
                    {numFilteredFolders > 1 ? 'Folders' : 'Folder'}
                  </span>
                ) : null}

                {(numFilteredConnections && numFilteredFolders && !loadDetails) ? ', ' : null}

                {(numFilteredConnections && !loadDetails) ? (
                  <span className={styles.modelTitle}>
                    {numFilteredConnections}
                    {' '}
                    {numFilteredConnections > 1 ? 'Connections' : 'Connection'}
                  </span>
                ) : null}
                {' '}
                will be deleted. Are you sure?
              </div>
            </div>
          </div>
          <div className={styles.buttonGroup}>
              <Button style={{ width: 85 }} title="Cancel" type="secondary" onClick={onClose} />
              <Button
                style={{ width: 120, marginLeft: 12 }}
                type="red"
                title="Delete"
                disabled={submitting}
                onClick={() => {
                  sendDeleteRequest({ folders: filteredFolders, connections: filteredConnections });
                }}
              />
          </div>
        </div>
      </div>
    </div>
  );
}

export default RemoveConnectionsModal;

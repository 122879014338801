import React, { memo } from 'react';
import cn from 'classnames';
import * as styles from './ContextMenu.module.scss';
import ContextMenuProvider from './ContextMenuProvider';

function ContextMenu({
  contextMenuTop,
  contextMenuLeft,
  contextConfig,
  isOpen,
}) {
  return (
    <ContextMenuProvider isOpen={isOpen}>
      <div
        className={styles.contextMenu}
        style={{
          display: contextMenuTop !== 0 ? 'flex' : 'none',
          top: contextMenuTop,
          left: contextMenuLeft,
        }}
      >
        <ul className={styles.contextMenuList}>
          {contextConfig.map(({
            id, title, onClick, Icon, hasDivider,
          }, idx, arr) => {
            const isFirstAction = idx === 0;
            const isLastAction = arr.length - idx === 1;
            return (
              <li
                className={cn(styles.contextMenuItem, {
                  [styles.borderAlignTop]: isFirstAction,
                  [styles.borderAlignBottom]: isLastAction,
                })}
                key={id}
              >
                {hasDivider && <hr className={styles.divider} />}
                <button
                  type="button"
                  className={cn(styles.contextMenuButton, {
                    [styles.borderAlignTop]: isFirstAction,
                    [styles.borderAlignBottom]: isLastAction,
                  })}
                  onClick={onClick}
                >
                  <div className={styles.iconContainer}>
                    <Icon />
                  </div>
                  <span>{title}</span>
                </button>
              </li>
            );
          })}
        </ul>
      </div>
    </ContextMenuProvider>
  );
}

export default memo(ContextMenu);

/* eslint-disable arrow-body-style */
/* eslint-disable no-param-reassign */
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { uid } from 'uid';
import { stringifyIfJSON } from './utils';
import { VariableToken } from '../components/VariableToken/VariableToken';

const insert = (str, index, value) => str.substr(0, index) + value + str.substr(index);

const searchSuggestions = (suggestions, searchValue) =>
suggestions.filter(
  (suggestion) => suggestion.name.slice(0, searchValue.length) === searchValue 
    && suggestion.name.length >= searchValue.length
);

const transformTextWithVariables = (text, variables, to = 'name', useBrackets = true, toggleDesignationBrackets = false) => {
  if (!text) return '';
  if(!variables) return text;
  let result = String(text);
  
  variables.forEach((item) => {
    if (to === 'name') {
      if(useBrackets){
        result = result.replaceAll(`{{env.${item.id}}}`, toggleDesignationBrackets ? `env.${item[to]}` : `{{env.${item[to]}}}`);
      }
      else{
        result = result.replace(new RegExp(`env.${item.id}(?!\\w)`, 'g'), toggleDesignationBrackets ? `{{env.${item[to]}}}` : `env.${item[to]}`);
      }
    } else if (to === 'id') {
      if(useBrackets){
        result = result.replaceAll(`{{env.${item.name}}}`, toggleDesignationBrackets ? `env.${item[to]}` : `{{env.${item[to]}}}`);
      }
      else{
        result = result.replace(new RegExp(`env.${item.name}(?!\\w)`, 'g'), toggleDesignationBrackets ? `{{env.${item[to]}}}` : `env.${item[to]}`);
      }
    } else {
      result = result.replaceAll(`{{env.${item.name}}}`, `${item[to]}`);
      result = result.replaceAll(`{{env.${item.id}}}`, `${item[to]}`);
    }
  });
  return result;
};

const transformIfJSON = (text) => {
  if (typeof text !== 'object') return String(text);
  try {
    return stringifyIfJSON(text, 0);
  } catch {
    return text;
  }
}

export const transformValueWithVariables = (text, variables, to = 'name', useBrackets = true) => {
  if (!text) return '';
  if (!variables) return text;
  let result = String(text);

  variables.forEach((item) => {
    if (to === 'value') {
      if (useBrackets) {
        result = result.replaceAll(`{{env.${item.id}}}`, transformIfJSON(item[to]));
      }
      else {
        result = result.replace(new RegExp(`env.${item.id}(?!\\w)`, 'g'), transformIfJSON(item[to]));
      }
    } else {
      result = result.replaceAll(`{{env.${item.name}}}`, `${item[to]}`);
      result = result.replaceAll(`{{env.${item.id}}}`, `${item[to]}`);
    }
  });
  return result;
}

export const replaceTextWithVariables = (text, variables = {}, to = 'name') => {
  return text.replace(/\{\{env\.(\w+)\}\}/g, (match, id) => {
    const variable = variables[id];
    const result = variable ? variable[to] : match;
    return typeof result === 'object' ? stringifyIfJSON(result, 0) : String(result);
  });
}

const getCursorPosition = (el) => {
  const selection = window.getSelection();
  if (selection.rangeCount > 0) {
    const range = selection.getRangeAt(0);
    const rect = range.getBoundingClientRect();
    const { top, left } = rect;
    const contentBlockRect = el.parentNode.getBoundingClientRect();
    const relativeTop = top - contentBlockRect.top + 20;
    const relativeLeft = left - contentBlockRect.left;
    return { top: relativeTop, left: relativeLeft };
  }
  return { top: 0, left: 0 };
};

const updateSuggestionsNodePosition = (node) => {
  const { top, left } = getCursorPosition(node);
  node.style.top = `${top}px`;
  node.style.left = `${left}px`;
};


function getCaretPosition() {
  if (window.getSelection && window.getSelection().getRangeAt) {
    const selectedObj = window.getSelection();
    if (selectedObj.rangeCount === 0) {
      return 0;
    }
    const range = window.getSelection().getRangeAt(0);

    let rangeCount = 0;
    const { childNodes } = selectedObj.anchorNode.parentNode;
    for (let i = 0; i < childNodes.length; i += 1) {
      if (childNodes[i] === selectedObj.anchorNode) {
        break;
      }
      if (childNodes[i].outerHTML) {
        rangeCount += childNodes[i].outerHTML.length;
      } else if (childNodes[i].nodeType === 3) {
        rangeCount += childNodes[i].textContent.length;
      }
    }
    return range.startOffset + rangeCount;
  }
  return -1;
}

const getWithRemovedToken = (input, id) => {
  const regex = new RegExp(`<span\\s+id="${id}"[^>]*>.*?</span>`, 'g');
  const newString = input.replace(regex, '');
  return newString;
};

const getTextFromHtml = (htmlNode) => {
  if (!htmlNode) return '';
  const clonedNode = htmlNode.cloneNode(true);
  const spanElements = clonedNode.querySelectorAll('span');
  spanElements.forEach((span) => {
    const replacementText = span.innerText;
    const textNode = document.createTextNode(`${replacementText}`);
    span.parentNode.replaceChild(textNode, span);
  });
  const range = document.createRange();
  range.selectNodeContents(clonedNode);
  return range.toString();
};

const createTokenHtml = (text) => {
  const id = uid(5);
  const htmlString = ReactDOMServer.renderToString(<VariableToken id={id} text={text} />);
  // const iTagIndex = htmlString.indexOf('<i');
  // const withRemove = insert(
  //   htmlString,
  //   iTagIndex + 3,
  //   `onClick="document.dispatchEvent(new CustomEvent('rmToken', { detail: { id: '${id}' } }))" `,
  // );
  return htmlString;
};

const includesToken = (input) => {
  const regex = /{{env.*?}}/;
  return regex.test(input);
};

const getTokinized = (input, variables) => {
  // ----------TEMPORARY DISABLE TOKENIZATION-------------
  return input

  // if (!includesToken(input)) return input;
  // let res = input;
  // variables.forEach((variable) => {
  //   const regex = new RegExp(`{{env.${variable.name}}}`, 'g');
  //   if (!regex.test(res)) return;
  //   res = res.replace(regex, createTokenHtml(`env.${variable.name}`));
  // });
  // return res;
};

export {
  insert,
  getTokinized,
  getWithRemovedToken,
  createTokenHtml,
  includesToken,
  getCaretPosition,
  getCursorPosition,
  getTextFromHtml,
  transformTextWithVariables,
  searchSuggestions,
  updateSuggestionsNodePosition
};

import React, { useState, useEffect, useRef } from 'react';
import { ParamentRow } from '../ParametrRow/ParamentRow';
import { debounce, convertValue, parseIfJSON } from '../../helpers/utils';

export function ParamentRows({
  params,
  errors,
  viewRead,
  parameterType,
  typesList,
  handleUpdate,
  activeMethod
}) {
  const [list, setList] = useState(params);
  const listRef = useRef(null);
  listRef.current = list;
  useEffect(() => {
    if (params) {
      setList(params);
    }
  }, [params]);

  const removeParameter = (parameter) => {
    const newParams = list.filter((el) => el.id !== parameter.id);
    setList(newParams);
    debounce('handleChangeProxy', () => {
      handleUpdate('params', newParams);
    }, 500);
  };
  
  const handleChangeProxy = (e) => {
    const { value, name } = e.target;
    const [field, idX] = name.split('.');
    const newParams = listRef.current.map((el) => (el.id === idX ? (({
      ...el,
      [field]: value,
    })) : el));

    handleUpdate('params', newParams);
    setList(newParams);
  };

  const moveParam = (dragIndex, hoverIndex) => {
    const swap = (arr = [], start = 0, end = 0) => {
      const newArr = arr.slice(0);
      const startItem = arr[start];
      const endItem = arr[end];
      newArr[end] = startItem;
      newArr[start] = endItem;
      return newArr;
    };
    const newList = swap(list, dragIndex, hoverIndex);
    setList(newList);
    debounce('moveParam', () => {
      handleUpdate('params', newList);
    }, 500);
  };

  const setFieldValueProxy = (f, type) => {
    const [field, idX] = f.split('.');
    const newParams = params.map((param) => {
      if (param.id === idX) {
        const updatedParam = {
          ...param,
          [field]: type,
        };
    
        if (field === 'source' && type === 'path') {
          updatedParam.required = 'yes';
        }
        if (field === 'source' && type !== 'body') {
          updatedParam.type = 'string';
          updatedParam.defaultValue = convertValue(updatedParam.defaultValue, 'string');
        }
        if (field === 'type') {
          if (!/\{\{env\..*?\}\}/g.test(updatedParam.defaultValue)) {
            updatedParam.defaultValue =
              ['array', 'object'].includes(type)
              ? JSON.stringify(convertValue(parseIfJSON(updatedParam.defaultValue), type))
              : convertValue(updatedParam.defaultValue, type)
          }

        }
    
        return updatedParam;
      }
    
      return param;
    });

    debounce('setFieldValueProxy', () => {
      handleUpdate('params', newParams);
    }, 500);
    setList(newParams);
  };

  return (
    <>
      {list.map((param, i) => ((
        <ParamentRow
          key={param.id}
          index={i}
          id={param.id}
          param={param}
          errors={errors}
          viewRead={viewRead}
          parameterType={parameterType}
          moveParam={moveParam}
          removeParameter={removeParameter}
          handleChangeProxy={handleChangeProxy}
          setFieldValueProxy={setFieldValueProxy}
          typesList={typesList}
          activeMethod={activeMethod}
        />
      )))}
    </>
  );
}

/* eslint-disable no-useless-escape */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import cn from 'classnames';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useFormik } from 'formik';
import ProjectDefault from '../../pictures/project-default.svg';
import { inviteRequest, checkHashRequest } from '../../store/auth/actions';
import Input from '../../components/Input/Input';

import * as styles from './Auth.module.scss';

const Schema = Yup.object().shape({
  first_name: Yup.string().required('Name required'),
  last_name: Yup.string().required('Last name required'),
  password: Yup.string().required('Password required').matches(
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&_+=();:.,\/><-^])[A-Za-z\d@$!%*#?&_+=();:.,\/><-^]{8,}$/,
    'Use 8 or more chars with a mix of letters, digits & symbols',
  ),
  confirm_password: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match').required('Confirm password required'),
});

function Invitation({ invitation, checkInviteHash }) {
  const navigate = useNavigate();
  const [inviteCode, setCode] = useState('');
  const [email, setEmail] = useState('');
  const [allowInvite, setAllowInvite] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    handleSubmit, handleChange, values, errors, touched, isSubmitting,
  } = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      password: '',
      confirm_password: '',
    },
    validationSchema: Schema,
    onSubmit: (v, { setSubmitting }) => {
      invitation(
        {
          first_name: v.first_name,
          last_name: v.last_name,
          code: inviteCode,
          password: v.password,
          email,
        },
        (status) => {
          if (status) navigate('/invite/success');
          if (!status) setSubmitting(false);
        },
      );
    },
  });

  useEffect(() => {
    const params = Object.fromEntries(searchParams.entries());
    const { code } = params || {};

    if (code) {
      checkInviteHash(
        { code },
        (obj) => {
          if (obj.status === 'success') {
            navigate('/login');
            return;
          }
          if (obj && obj.email) {
            setAllowInvite(true);
            setCode(code);
            setEmail(obj.email);
          }
          if (!obj) setAllowInvite(false);
        },
      );
    } else {
      setAllowInvite(false);
    }
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.box}>
        <div className={styles.logoBox}>
          <ProjectDefault />
          <h2>Trigger API Gateway</h2>
        </div>
        <div className={styles.titleBox}>
          <h3>Invitation</h3>
          <div />
        </div>
        {allowInvite === true && (
        <form onSubmit={handleSubmit}>
          <div className={styles.row}>
            <input
              className={cn({ [styles.errorInput]: errors.first_name && touched.first_name })}
              autoComplete="username"
              type="text"
              name="first_name"
              id="first_name"
              placeholder="First name"
              onChange={handleChange}
              value={values.first_name}
            />
            {errors.first_name && touched.first_name && (
            <span className={styles.errorMessage}>{errors.first_name}</span>
            )}
          </div>

          <div className={styles.row}>
            <input
              className={cn({ [styles.errorInput]: errors.last_name && touched.last_name })}
              type="text"
              name="last_name"
              id="last_name"
              placeholder="Last name"
              onChange={handleChange}
              value={values.last_name}
            />
            {errors.last_name && touched.last_name && (
            <span className={styles.errorMessage}>{errors.last_name}</span>
            )}
          </div>

          <div className={styles.row}>
            <Input
              type="password"
              name="password"
              id="password"
              placeholder="Password"
              isPassword
              realPassword
              handleChange={handleChange}
              value={values.password}
              autoComplete="new-password"
              error={errors.password && touched.password}
            />
            {errors.password && touched.password && (
            <span className={styles.errorMessage}>{errors.password}</span>
            )}
          </div>

          <div className={styles.row}>
            <Input
              type="password"
              name="confirm_password"
              id="confirm_password"
              placeholder="Confirm password"
              isPassword
              realPassword
              handleChange={handleChange}
              value={values.confirm_password}
              error={errors.confirm_password && touched.confirm_password}
            />
            {errors.confirm_password && touched.confirm_password && (
            <span className={styles.errorMessage}>{errors.confirm_password}</span>
            )}
          </div>

          <div className={styles.row}>
            <button
              type="submit"
              className={cn({ [styles.buttonSubmitting]: isSubmitting })}
            >
              Confirm invitation
            </button>
          </div>
        </form>
        )}
        {allowInvite === false && (
        <p>
          Oops.., we were unable to validate your code
        </p>
        )}
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  invitation: (data, callback) => dispatch(inviteRequest(data, callback)),
  checkInviteHash: (data, callback) => dispatch(checkHashRequest(data, callback)),
});

export default connect(null, mapDispatchToProps)(Invitation);

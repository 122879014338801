/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import debounce from 'lodash.debounce';
import { useFormik } from 'formik';
import cn from 'classnames';
import * as Yup from 'yup';
import ValidationErrorList from '../../components/ValidationErrorList/ValidationErrorList';
import { useCopyToClipboard } from '../../hooks/hooks';
import Button from '../../components/Button/Button';
import InviteToResourceModal from '../../components/InviteToResourceModal/InviteToResourceModal';
import Input from '../../components/Input/Input';
import Select from '../../components/Select/Select';
import { parseIfJSON, roleMapper, convertValue } from '../../helpers/utils';

import { createVariableRequest } from '../../store/variables/actions';
import { createToastRequest } from '../../store/toasts/actions';

import * as shared from '../../styles/shared.module.scss';
import CodeEditor from '../../components/CodeEditor';
import { useMonaco, useMonacoErrors } from '../../hooks/useMonaco';
import { ViewModes } from '../../components/CodeEditor/codeEditorConstants';

const maxAllowedLength = 64;

const SUBROUTING = {
  ACCESS: 'access',
};

const booleanList = [
  { value: 'true', title: 'true' },
  { value: 'false', title: 'false' },
];

const varTypes = [
  { value: 'string', title: 'String' },
  { value: 'number', title: 'Number' },
  { value: 'boolean', title: 'Boolean' },
  { value: 'array', title: 'Array' },
  { value: 'object', title: 'Object' },
];

const subrouteMapping = {};

const AuthSchema = () =>
  Yup.object().shape({
    name: Yup.string()
      .min(2, 'Name length should be at least 2 characters')
      .max(64)
      .matches(/^[a-zA-Z0-9$_]+$/, 'Name can only contain alphanumeric characters, $ and _')
      .required('Name required'),
    type: Yup.string().required('Type required'),
    value: Yup.mixed().when('type', {
      is: 'string',
      then: Yup.string().required('Value required'),
      otherwise: Yup.mixed().when('type', {
        is: 'number',
        then: Yup.number('Provide valid number value').required('Value required'),
        otherwise: Yup.mixed().when('type', {
          is: 'object',
          then: Yup.string()
            .required('Value required')
            .test('is-json', 'Value must be a valid non Array JSON object', (value) => {
              try {
                const result = JSON.parse(value);
                return result && typeof result === 'object' && !Array.isArray(result) && result !== null;
              } catch {
                return false;
              }
            }),
          otherwise: Yup.mixed().when('type', {
            is: 'array',
            then: Yup.string()
              .required('Value required')
              .test('is-json', 'Value must be a valid JSON array', (value) => {
                try {
                  const result = JSON.parse(value);
                  return Array.isArray(result);
                } catch {
                  return false;
                }
              }),
            otherwise: Yup.mixed().when('type', {
              is: 'boolean',
              then: Yup.boolean('Provide valid boolean value').required('Value required'),
              otherwise: Yup.mixed().notRequired(),
            }),
          }),
        }),
      }),
    }),
  });

function VariablesCreate({ createVariable, currentUser, activeProject, createToast }) {
  useMonaco({ hideVariables: true });
  const { showMonacoErrors } = useMonacoErrors();
  const [subroute, setSubroute] = useState(SUBROUTING.ACCESS);
  const [shareModalOpened, setShareModalOpened] = useState(false);
  const [inviteList, setInviteList] = useState([]);
  const navigate = useNavigate();
  const { shortName } = useParams();
  const listAPI = () => navigate(`/${shortName}/variables`);
  const [, copyText] = useCopyToClipboard();
  const [submitTriggered, setSubmitTriggered] = useState(false);

  useEffect(() => {
    if (currentUser) {
      setInviteList([
        {
          userFirstName: currentUser.firstName,
          userLastName: currentUser.lastName,
          userEmail: currentUser.email,
          roleId: 2,
        },
      ]);
    }
  }, [currentUser]);

  const {
    submitForm,
    handleChange: handleChange2,
    setFieldValue: setFieldValue2,
    values,
    errors,
    validateForm,
  } = useFormik({
    initialValues: {
      name: '',
      description: '',
      type: 'string',
      value: '',
    },
    validationSchema: AuthSchema(),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (v) => {
      const req = {
        name: v.name,
        description: v.description,
        type: v.type,
        value: v.value,
        projectId: activeProject?.id,
      };

      createVariable(req, () => navigate(`/${shortName}/variables`));
    },
  });

  const debouncedValidateForm = debounce(() => {
    validateForm();
  }, 100);

  const setFieldValue = (...args) => {
    setFieldValue2(...args);
    if (submitTriggered) {
      debouncedValidateForm();
    }
  };

  const handleChange = (...args) => {
    handleChange2(...args);
    if (submitTriggered) {
      debouncedValidateForm();
    }
  };

  const handleSubmitWithCallback = (e) => {
    if (showMonacoErrors()) return;
    e.preventDefault();
    setSubmitTriggered(true);
    submitForm(e);
  };

  const handleChangeProxy = (...arg) => handleChange(...arg);

  const setFieldValueProxy = (...arg) => setFieldValue(...arg);

  const handeChangeRole = (email, role) => {
    const newInviteList = inviteList.map((el) => (el.userEmail === email ? { ...el, roleId: role } : el));
    setInviteList(newInviteList);
  };

  const saveInviteList = (data) => {
    const filtered = data.accessList.filter((el) => el.userEmail !== currentUser.email);
    const unique = Object.values(
      [...inviteList, ...filtered].reduce((acc, obj) => ({ ...acc, [obj.userEmail]: obj }), {}),
    );
    setInviteList(unique);
    setShareModalOpened(false);
  };

  const handleChangeName = ({ target }) => {
    const regex = /^(?!\s)(?!.* {2})/;
    if (regex.test(target.value) && target.value.length <= maxAllowedLength) {
      setFieldValue(target.name, target.value);
    }
  };

  return (
    <div className={shared.page}>
      <div className={shared.header}>
        <div className={shared.headerTitleGroup}>
          <h1 className={shared.headerTitle}>Create Variable</h1>
        </div>
        <div className={shared.headerButtonGroup}>
          <ValidationErrorList errors={errors} subrouteMapping={subrouteMapping} setSubroute={setSubroute} />
          <Button title="Cancel" type="secondary" onClick={listAPI} />
          <Button title="Create variable" onClick={handleSubmitWithCallback} />
        </div>
      </div>
      <div className={shared.body}>
        <div className={shared.mainContent}>
          <section className={shared.section}>
            <h4 className={shared.sectionHeader}>Variable Settings</h4>
            <div className={shared.sectionContainer}>
              <div className={shared.inputWrapper}>
                <span className={shared.inputLabel}>Name</span>
                <Input
                  placeholder="Enter name"
                  handleChange={handleChangeName}
                  autoComplete="off"
                  value={values.name}
                  name="name"
                  id="name"
                  error={!!errors.name}
                />
              </div>
              <div className={shared.inputWrapper}>
                <span className={shared.inputLabel}>
                  Description <span className={shared.inputLabelDetails}> (optional)</span>
                </span>
                <Input
                  placeholder="Enter description"
                  handleChange={handleChangeProxy}
                  value={values.description}
                  name="description"
                  id="description"
                />
              </div>

              <div className={shared.inputWrapper}>
                <span className={shared.inputLabel}>Type</span>
                <Select
                  list={varTypes}
                  value={values.type}
                  handleChange={(...e) => {
                    setFieldValueProxy(...e);
                    setFieldValueProxy(
                      'value',
                      ['array', 'object'].includes(e[1])
                        ? JSON.stringify(convertValue(parseIfJSON(values.value), e[1]))
                        : convertValue(values.value, e[1]),
                    );
                  }}
                  placeholder="Select type"
                  name="type"
                  id="type"
                  error={!!errors.type}
                />
              </div>

              <div className={shared.inputWrapper}>
                <span className={shared.inputLabel}>Value</span>
                {values.type === 'boolean' ? (
                  <Select
                    list={booleanList}
                    value={values.value}
                    handleChange={setFieldValueProxy}
                    name="value"
                    id="value"
                    placeholder="Select value"
                    error={!!errors.value}
                  />
                ) : values.type === 'object' || values.type === 'array' ? (
                  <div className={cn(shared.codeEditorWrap, shared.codeEditorWrapInline)}>
                    <CodeEditor
                      value={values.value}
                      handleBlurCallback={(value) => {
                        const name = 'value';
                        handleChangeProxy({ target: { value, name } });
                      }}
                      name="value"
                      id="value"
                      type="json"
                      error={!!errors.value}
                      viewMode={ViewModes.Inline}
                      withVariables={false}
                    />
                  </div>
                ) : (
                  <Input
                    placeholder="Initial value"
                    handleChange={handleChangeProxy}
                    value={values.value}
                    name="value"
                    id="value"
                    type={values.type === 'number' ? 'number' : 'text'}
                    autoComplete="off"
                    iconRight={values.value && 'copy'}
                    error={!!errors.value}
                    handleAction={() => {
                      copyText(values.value);
                      createToast({ type: 'success', text: 'Copied' });
                    }}
                  />
                )}
              </div>
            </div>
          </section>
        </div>
        <div className={shared.sidepanel}>
          <div className={shared.sidepanelGroup}>
            <div className={shared.sidepanelHeading}>
              <div className={shared.subHeaders}>
                <h4
                  onClick={() => setSubroute(SUBROUTING.ACCESS)}
                  className={cn(
                    shared.sectionHeader,
                    { [shared.clickable]: true },
                    { [shared.passive]: subroute !== SUBROUTING.ACCESS },
                  )}
                >
                  Access
                </h4>
              </div>
            </div>
            {subroute === SUBROUTING.ACCESS && (
              <div className={shared.sidepanelTable}>
                <div className={shared.sidepanelTableHeading}>
                  <div className={shared.lineHeader}>
                    <div>Name</div>
                    <div />
                    <div>Role</div>
                  </div>
                </div>
                <div className={shared.sidepanelTableBodyContainer}>
                  <ul className={shared.sidepanelTableBody}>
                    {inviteList &&
                      inviteList.map((item) => (
                        <li className={shared.historyLine} key={item.userEmail}>
                          <div title={item.userEmail}>
                            {item.userEmail === currentUser.email ? (
                              <span>{`${item.userFirstName} ${item.userLastName}`}</span>
                            ) : (
                              <span>{item.userEmail}</span>
                            )}
                          </div>
                          <div />
                          <div>
                            {item.userEmail === currentUser.email ? (
                              <Select
                                list={[{ value: 2, title: roleMapper(2) }]}
                                style={{ width: 90 }}
                                value={item.roleId}
                                handleChange={(email, value) => handeChangeRole(email, value)}
                                name={item.userEmail}
                                disabled
                              />
                            ) : (
                              <Select
                                list={[
                                  { value: 3, title: roleMapper(3) },
                                  { value: 4, title: roleMapper(4) },
                                ]}
                                style={{ width: 90 }}
                                value={item.roleId}
                                handleChange={(email, value) => handeChangeRole(email, value)}
                                name={item.userEmail}
                              />
                            )}
                          </div>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            )}
          </div>
        </div>
        {shareModalOpened && (
          <InviteToResourceModal onClose={() => setShareModalOpened(false)} onSubmit={saveInviteList} model={values} />
        )}
      </div>
    </div>
  );
}

const mapStateToProps = ({ auth: { currentUser }, activeProject }) => ({
  activeProject,
  currentUser,
});

const mapDispatchToProps = (dispatch) => ({
  createVariable: (data, callback) => dispatch(createVariableRequest(data, callback)),
  createToast: (data, callback) => dispatch(createToastRequest(data, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VariablesCreate);

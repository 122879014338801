import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import cn from 'classnames';
import { useNavigate } from 'react-router-dom';
import { capitalize } from '../../helpers/utils';
import Button from '../../components/Button/Button';
import { removeToastRequest } from '../../store/toasts/actions';
import { listErrorsRequest } from '../../store/errors/actions';
import ActionText from '../../components/ActionText/ActionText';
import ActionIcon from '../../components/ActionIcon/ActionIcon';
import IconChecked from '../../pictures/icon-checked.svg';
import IconAlert from '../../pictures/icon-alert.svg';
import * as style from './Toasts.module.scss';

const icon = {
  action: IconAlert,
  success: IconChecked,
  error: IconAlert,
};

function Toast({
  item, action, errors, shortName,
}) {
  const navigate = useNavigate();
  // type = success, error, action
  const {
    type = 'success', text, title, code, id, linkTitle,
    submit,
  } = item;
  const dispatch = useDispatch();

  const Icon = icon[type];

  const getText = (type) => {
    let final;
    if (code && errors) {
      final =  errors.find((e) => e.code === code)?.msg?.en || text;
    } else {
      final = text;
    }

    return type === 'error' && typeof final === 'string' ? capitalize(final) : final;
  };

  const getTitle = () => {
    if (linkTitle) {
      const action = () => navigate(`/${shortName}${linkTitle}`);
      return (
        <>
          <ActionText title={title} onClick={action} type="black" />
          {' '}
        </>
      );
    }
    return (
      <span className={style.title}>
        {title}
        {' '}
      </span>
    );
  };

  return (
    <div className={cn(style.toast, {
      [style.error]: type === 'error' || type === 'action',
    })}
    >
      <div className={style.group}>
        <div className={style.iconWrapper}>
          <Icon />
        </div>
        <div className={style.groupContent}>
          {(type === 'error' || type === 'action') && (
            <span className={style.title}>Error: </span>
          )}
          {(type === 'action') && (
            <span className={style.title}>
              APIs cannot be imported from Postman because there
            are not unique combination of HTTP method and API
            endpoints: 
            </span>
          )}
          {type === 'success' && title && getTitle(title)}
          {(type !== 'action') ? <span dangerouslySetInnerHTML={{ __html: getText(type) }} /> : null}
          {type === 'action' ? (
            <span className={style.title}>
              <br />
              <br />
              You can either use 
              {' '}
              <ActionText type="bold" title='Force Upload' onClick={() => {
                action(id);
                submit(dispatch);
              }} /> to override the duplicated 
              APIs or fix your Postman Collection and try again.
              <br />
              <br />
            </span>
          ) : null}
          {type === 'action' ? (
            <Button
              onClick={() => {
                action(id);
                submit(dispatch);
              }}
              type="primary"
              size="M"
              title="Force Upload"
            />
          ) : null}
        </div>
      </div>
      <div className={style.iconWrapper}>
        <ActionIcon
          icon="close"
          hoverDisabled
          onClick={() => action(id)}
        />
      </div>
    </div>
  );
}

function Toasts({
  toasts, removeToast, getErrors, errors, activeProject,
}) {
  const handleAction = (id) => {
    removeToast({ id });
  };

  useEffect(() => {
    if (!errors) {
      getErrors();
    }
  }, [errors, window.location.href]);

  return (
    <div className={style.toastsContainer}>
      {toasts && toasts.length ? toasts.map((t) => (
        <Toast
          key={t.key}
          item={t}
          errors={errors}
          action={handleAction}
          shortName={activeProject?.shortName}
        />
      )) : (
        <div />
      )}
    </div>
  );
}

const mapStateToProps = ({ toasts, errors, activeProject }) => ({
  toasts,
  errors,
  activeProject,
});

const mapDispatchToProps = (dispatch) => ({
  removeToast: (data) => dispatch(removeToastRequest(data)),
  getErrors: () => dispatch(listErrorsRequest()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Toasts);

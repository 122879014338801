/* eslint-disable max-len */
import { ActiveConnection } from './constants';

export const activeConnectionRequest = (data) => ({ type: ActiveConnection.activeConnectionRequest, data });
export const activeConnectionSuccess = (data) => ({ type: ActiveConnection.activeConnectionSuccess, data });
export const activeConnectionError = () => ({ type: ActiveConnection.activeConnectionError });

export const getAccessUsersConnectionSuccess = (data) => ({ type: ActiveConnection.getAccessUsersConnectionSuccess, data });

export const testActiveConnectionRequest = (data, callback) => ({ type: ActiveConnection.testActiveConnectionRequest, data, callback });
export const testActiveConnectionSuccess = () => ({ type: ActiveConnection.testActiveConnectionSuccess });
export const testActiveConnectionError = () => ({ type: ActiveConnection.testActiveConnectionError });

export const getConnectedEndpointsRequest = (data) => ({ type: ActiveConnection.getConnectedEndpointsRequest, data });
export const getConnectedEndpointsSuccess = (data) => ({ type: ActiveConnection.getConnectedEndpointsSuccess, data });

import { DatabaseScheme } from './constants';

const initialState = {
  data: {},
};

export const databaseSchemeReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case DatabaseScheme.databaseSchemeSuccess:
      return { ...state, data: action.data };
    default:
      return state;
  }
};

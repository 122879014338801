@use "sass:map";
@import '../../styles/variables.scss';

.main {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  .container {
    margin: auto;
    width: 50%;
    padding: 10px;
    display: flex;
    gap: 8px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .reason {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      text-align: center;
    }
    .advice {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      text-align: center;
      color: map.get($colors-basic, "textBlack");
    }
    .link {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      text-align: center;
      color: map.get($colors-basic, "primaryBlue");
      text-decoration: none;
      &:hover {
        cursor: pointer;
      }
    }
  }
}

import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootSaga from './rootSaga';
import rootReducer from './rootReducer';
import { initialState as trashInitialState } from './trash/reducer';

const authInitial = localStorage.getItem('auth')
  ? { auth: JSON.parse(localStorage.getItem('auth')), currentUser: null }
  : { auth: null, currentUser: null };
// we need an initialState otherwise , store will freak out
const initialState = {
  auth: authInitial,
  users: null,
  roles: [],
  projects: null,
  searchProjects: [],
  activeSearchProject: null,
  endpoints: {},
  connections: [],
  activeConnection: null,
  activeEndpoint: {
    activeEndpoint: null,
    original: null,
  },
  databaseScheme: {},
  history: {
    historyList: {
      parents: [],
      children: [],
    },
    status: 'idle',
  },
  activeUser: null,
  activeProject: null,
  toasts: null,
  invites: null,
  directory: null,
  errors: null,
  trash: trashInitialState,
};

export default function configureStore() {
  const composeEnhancers = composeWithDevTools({});
  const sagaMiddleware = createSagaMiddleware();

  // we'll be passing from our entry point.
  const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(sagaMiddleware)),
  );

  // Don't forget to run the root saga, and return the store object.
  sagaMiddleware.run(rootSaga);
  return store;
}

/* eslint-disable import/prefer-default-export */
export const ActiveVariable = {
  activeVariableRequest: 'ACTIVE_VARIABLE_PENDING',
  activeVariableSuccess: 'ACTIVE_VARIABLE_SUCCESS',
  activeVariableError: 'ACTIVE_VARIABLE_ERROR',
  
  getAccessUsersVariableSuccess: 'GET_ACCESS_USERS_VARIABLE_SUCCESS',

  getConnectedModelsToVariablesRequest: 'GET_CONNECTED_MODELS_TO_VARIABLES_REQUEST',
  getConnectedModelsToVariablesSuccess: 'GET_CONNECTED_MODELS_TO_VARIABLES_SUCCESS',
};

/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useState, useEffect, useCallback,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import JSZip from 'jszip';
import ActionIcon from '../../components/ActionIcon/ActionIcon';
import ActionText from '../../components/ActionText/ActionText';
import ChipsStatus from '../../components/ChipsStatus/ChipsStatus';
import Button from '../../components/Button/Button';
import { createToastRequest } from '../../store/toasts/actions';
import {
  importVariableRequest,
  listVariablesRequest,
} from '../../store/variables/actions';
import { shortenText, getVariableModelValue } from '../../helpers/utils';

import SortIcon from '../../pictures/sort.svg';
import Import from '../../pictures/import.svg';

import 'react-tooltip/dist/react-tooltip.css';
import * as shared from '../../styles/shared.module.scss';
import Checkbox from '../../components/Checkbox/Checkbox';
import ImportModal from '../../components/ImportModal/ImportModal';

const headers = {
  checkbox: { title: '', width: '3%' },
  id: { title: 'ID', width: '8%' },
  name: { title: 'Name', width: '32%' },
  type: { title: 'Value', width: '32%' },
  status: { title: 'Status', width: '16%' },
  action: { title: 'Actions', width: '10%' },
};

const allowedZipFormats = ['application/zip', 'application/x-zip-compressed'];

function List({
  activeProject,
  getVariables,
  importVariable,
  createToast,
  variables,
}) {
  const { data: variablesData = [] } = variables || {};
  const [importVariableModalOpened, setImportVariableModalOpened] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedEndpoints, setSelectedEndpoints] = useState([]);
  const [selectedConnections, setSelectedConnections] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);


  const [variablesIm, setVariablesIm] = useState([]);
  const [endpointsIm, setEndpointsIm] = useState([]);
  const [connectionsIm, setConnectionsIm] = useState([]);
  const [keysIm, setKeysIm] = useState([]);
  const [file, setFile] = useState(null);
  const { shortName } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (activeProject?.id) getVariables({ id: activeProject.id });
  }, [activeProject]);

  const getChipByStatus = (status) => {
    if (status === 'conflicted') {
      return <ChipsStatus title="Conflict" type="error" />;
    } if (status === 'draft') {
      return <ChipsStatus title="Draft" type="draft" />;
    } 
      return <ChipsStatus title="New" type="success" />;
  };

  const listVariable = () => navigate(`/${shortName}/variables/`);

  const onDrop = useCallback(async (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();

    reader.onabort = () => console.log('file reading was aborted');
    reader.onerror = () => console.log('file reading has failed');
    reader.readAsArrayBuffer(file);
    if (!allowedZipFormats.includes(file.type)) {
      createToast({ type: 'error', text: 'File format must be zip' });
      return;
    }
    setFile(file);

    const zip = new JSZip();
    const extractedFiles = await zip.loadAsync(file);
    extractedFiles.forEach(async (relativePath, file) => {
      const f = await file.async('string');
      const parsedFile = JSON.parse(f);
      if (!parsedFile?.variables || parsedFile?.variables?.length === 0) {
        createToast({ type: 'error', text: 'variables not found' });
        return;
      }
      if (parsedFile?.endpoints && parsedFile.endpoints?.length > 0) {
        const listEndpoints = parsedFile.endpoints
          .map((c) => ({ ...c, status: variablesData.find((con) => con.id === c.id) ? 'conflicted' : 'new' }));
        setEndpointsIm(listEndpoints);
      }
      if (parsedFile?.connections && parsedFile.connections?.length > 0) {
        const listConnections = parsedFile.connections
          .map((c) => ({ ...c, status: variablesData.find((con) => con.id === c.id) ? 'conflicted' : 'new' }));
        setConnectionsIm(listConnections);
      }
      if (parsedFile?.keys && parsedFile.keys?.length > 0) {
        const listKeys = parsedFile.keys
          .map((c) => ({ ...c, status: variablesData.find((con) => con.id === c.id) ? 'conflicted' : 'new' }));
        setKeysIm(listKeys);
      }
  
      const listVariables = parsedFile.variables
        .map((c) => ({ ...c, status: variablesData.find((con) => con.id === c.id) ? 'conflicted' : 'new' }));

      setVariablesIm(listVariables);
    });
  }, [variablesData]);

  const { getRootProps, getInputProps, open } = useDropzone({ onDrop, noClick: true });

  const selectAll = (isChecked) => {
    setSelectedItems(isChecked ? variablesIm : []);
    setSelectedEndpoints(isChecked ? endpointsIm : []);
    setSelectedConnections(isChecked ? connectionsIm : []);
    setSelectedKeys(isChecked ? keysIm : []);
  };

  const handleChangePickedEndpoints = (newKey) => {
    setSelectedEndpoints(() => endpointsIm.reduce((acc, val) => {
      if (newKey.some((keyItem) => val.connectionId === keyItem.id)) {
        return [...acc, val];
      }
      return acc;
    }, []));
  };

  const selectOne = (isChecked, model) => {
    setSelectedItems((prev) => {
      const newVars = isChecked ? [...prev, model] : prev.filter((c) => c.id !== model.id);
      setSelectedEndpoints(newVars.length ? endpointsIm : []);
      setSelectedConnections(newVars.length ? connectionsIm : []);
      setSelectedKeys(newVars.length ? keysIm : []);
      return newVars;
    });
  };

  const importC = () => {
    setImportVariableModalOpened(true);
  };

  const importVariables = (linkApi, linkConnections, linkKeys) => {
    let varList = '';
    selectedItems.forEach((item) => varList += varList ? `,${item.id}` : `${item.id}`);
    importVariable({
      file,
      variables: varList,
      linkApi,
      linkConnections,
      linkKeys,
      projectId: activeProject.id,
    }, listVariable);
  };

  return (
    <div className={shared.page}>
      <div className={shared.header}>
        <div className={shared.headerTitleGroup}>
          <h1 className={shared.headerTitle}>Import Variables</h1>
        </div>
        <div className={shared.headerButtonGroup}>
          <Button title="Import variables" onClick={importC} disabled={selectedItems.length === 0} />
        </div>
      </div>
      <div className={shared.table}>
        <table className={shared.connectionTable}>
          <thead>
            <tr>
              <th key="checkbox" width={headers.checkbox.width}>
                <Checkbox
                  handleChange={selectAll}
                  size="small"
                  value={selectedItems.length > 0 && selectedItems.length === variablesIm?.length}
                />
              </th>
              {Object.keys(headers).map((h, i, arr) => h !== 'checkbox' && (
                <th key={headers[h].title} width={headers[h].width}>
                  <span>{headers[h].title}</span>
                  {' '}
                  {i < arr.length - 1 && <SortIcon />}
                </th>
              ))}
            </tr>
          </thead>
          {variablesIm?.length ? (
            <tbody>
              {variablesIm.map((model, index) => (
                <tr
                  key={model.id}
                  className={`${selectedItems.find((s) => s.id === model.id) && shared.selected}`}
                  role="button"
                  tabIndex={0}
                >
                  <td width={headers.checkbox.width}>
                    <Checkbox
                      handleChange={(isCheked) => selectOne(isCheked, model)}
                      value={selectedItems.find((s) => s.id === model.id)}
                      size="small"
                    />
                  </td>
                  <td width={headers.id.width}><span>{shortenText(model.id)}</span></td>
                  <td width={headers.name.width}>
                    <span>{model.name}</span>
                    {model.description
                      && (
                      <div className={shared.controlIcons}>
                        <ActionIcon
                          id={`${index}_${model.id}_tooltip`}
                          icon="info"
                          style={{ marginRight: '8px' }}
                          onClick={() => {}}
                          tooltip={{
                            content: model.description,
                          }}
                        />
                      </div>
                      )}
                  </td>
                  <td width={headers.type.width}>
                    <span>{getVariableModelValue(model)}</span>
                  </td>
                  <td width={headers.status.width}>
                    {getChipByStatus(model.status)}
                  </td>
                  <td width={headers.action.width} />
                </tr>
              ))}
            </tbody>
          ) : null}
        </table>
        {!variablesIm?.length && (
          <div className={shared.emptyContent} {...getRootProps()}>
            <input {...getInputProps()} type="file" style={{ display: 'none' }} accept=".zip" />
            <div
              className={shared.iconWrap}
              role="button"
              onClick={open}
              onKeyDown={open}
              tabIndex="0"
            >
              <Import />
            </div>
            <p>
              Drop your .zip file with Variables here to start import process
            </p>
            <p>
              or
              {' '}
              <div className={shared.action} style={{ display: 'inline' }}>
                <ActionText title="Select file" onClick={open} />
              </div>
            </p>
          </div>
        )}
        {importVariableModalOpened && (
          <ImportModal
            onClose={() => setImportVariableModalOpened(false)}
            onSubmit={importVariables}
            list={{ 
              apis: selectedEndpoints, 
              variables: selectedItems, 
              connections: selectedConnections, 
              keys: selectedKeys 
            }}
            typeImport="variables"
          />
        )}
      </div>
    </div>
  );
}

const mapStateToProps = ({ activeProject, variables }) => ({
  activeProject,
  variables,
});

const mapDispatchToProps = (dispatch) => ({
  getVariables: (data) => dispatch(listVariablesRequest(data)),
  importVariable: (data, callback) => dispatch(importVariableRequest(data, callback)),
  createToast: (data) => dispatch(createToastRequest(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(List);

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ProjectDefault from '../../pictures/project-default.svg';
import ActionText from '../../components/ActionText/ActionText';

import * as styles from './Auth.module.scss';

// types register, invitation, resetPassword, changePassword

function SuccessMessage({ type }) {
  const navigate = useNavigate();

  const goToLogin = () => {
    navigate('/login');
  };

  useEffect(() => {
    if (type === 'invitation') {
      setTimeout(() => {
        navigate('/');
      }, 3000);
    }
  }, [type]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.box}>
        <div className={styles.logoBox}>
          <ProjectDefault />
          <h2>Trigger API Gateway</h2>
        </div>
        {type === 'register' && (
        <div className={styles.successBox}>
          <h3>Success!</h3>
          <p>
            Please check your email address to complete registration process.
          </p>
        </div>
        )}
        {type === 'invitation' && (
        <div className={styles.successBox}>
          <h3>Success!</h3>
          <p>
            You will be redirected shortly...
          </p>
        </div>
        )}
        {type === 'resetPassword' && (
        <div className={styles.successBox}>
          <h3>Success!</h3>
          <p>
            Please check your email address to complete password reset.
          </p>
        </div>
        )}
        {type === 'changePassword' && (
        <div className={styles.successBox}>
          <h3>Success!</h3>
          <p>
            Please login to continue
          </p>
          <div>
            <ActionText title="Login" onClick={goToLogin} />
          </div>
        </div>
        )}
      </div>
    </div>
  );
}

export default SuccessMessage;

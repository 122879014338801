import {
  call, put, takeEvery, all, fork, select,
} from 'redux-saga/effects';
import { api } from '../../helpers/api';
import {
  activeEndpointRequest, activeEndpointSuccess,
  getEndpointConnectedAuthKeysRequest, getEndpointConnectedAuthKeysSuccess,
  getEndpointConnectedAuthKeysLimitRequest, getEndpointConnectedAuthKeysLimitSuccess,
  modifyEndpointConnectedAuthKeysLimitRequest, modifyEndpointConnectedAuthKeysLimitSuccess,
} from './actions';
import { createToastRequest } from '../toasts/actions';
import {
  refreshRequest,
} from '../auth/actions';
import { ActiveEndpoint } from './constants';
import history from '../../history';

const getAuth = (state) => state.auth?.auth;

export function* fetchActiveEndpoint(ops) {
  const { data, callback } = ops;
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(api, `/endpoints/${data.id}`, 'GET', null, authData.access_token);
      if (response.ok) {
        const parsed = yield response.json();
        yield put(activeEndpointSuccess(parsed));
        callback?.();
      } else if (response.status === 401) {
        yield put(refreshRequest(activeEndpointRequest, data));
      } else {
        const parsed = yield response.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
      }
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* fetchConnectedAuthKey(ops) {
  const { data, callback } = ops;
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(api, `/auth-keys-link?endpointId=${data.id}`, 'GET', null, authData.access_token);
      if (response.ok) {
        const parsed = yield response.json();
        const { items = [] } = parsed || {};
        yield put(getEndpointConnectedAuthKeysSuccess(items));
        callback?.();
      } else if (response.status === 401) {
        yield put(refreshRequest(getEndpointConnectedAuthKeysRequest, data, callback));
      } else {
        const parsed = yield response.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
      }
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* fetchConnectedAuthKeyLimits(ops) {
  const { data, callback } = ops;
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(api, `/link-limit?endpointId=${data.id}`, 'GET', null, authData.access_token);
      if (response.ok) {
        const parsed = yield response.json();
        yield put(getEndpointConnectedAuthKeysLimitSuccess(parsed));
        callback?.();
      } else if (response.status === 401) {
        yield put(refreshRequest(getEndpointConnectedAuthKeysLimitRequest, data, callback));
      } else {
        const parsed = yield response.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
      }
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* modifyConnectedAuthKeyLimits(ops) {
  const { data, callback } = ops;
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(api, `/link-limit`, 'POST', { items: data }, authData.access_token);
      if (response.ok) {
        yield put(modifyEndpointConnectedAuthKeysLimitSuccess(data));
        callback?.();
      } else if (response.status === 401) {
        yield put(refreshRequest(modifyEndpointConnectedAuthKeysLimitRequest, data, callback));
      } else {
        const parsed = yield response.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
      }
    }
  } catch (e) {
    console.log(e);
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

function* activeEndpointSaga() {
  yield takeEvery(ActiveEndpoint.activeEndpointRequest, fetchActiveEndpoint);
  yield takeEvery(ActiveEndpoint.getEndpointConnectedAuthKeysRequest, fetchConnectedAuthKey);
  yield takeEvery(ActiveEndpoint.getEndpointConnectedAuthKeysLimitRequest, fetchConnectedAuthKeyLimits);
  yield takeEvery(ActiveEndpoint.modifyEndpointConnectedAuthKeysLimitRequest, modifyConnectedAuthKeyLimits);
}

function* configSaga() {
  yield all([fork(activeEndpointSaga)]);
}

export default configSaga;

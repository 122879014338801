/* eslint-disable max-len */
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import cn from 'classnames';
import CreateFolderModal from '../../components/CreateFolderModal';
import RenameFolderModal from '../../components/RenameFolderModal';
import Breadscrumbs from '../../components/Breadscrumbs';
import ActionIcon from '../../components/ActionIcon/ActionIcon';
import ActionText from '../../components/ActionText/ActionText';
import RemoveModal from '../../components/RemoveAuthKeysModal';
import InviteToResourceModal from '../../components/InviteToResourceModal/InviteToResourceModal';
import ExportModal from '../../components/ExportAuthKeyModal';
import Directory from './atoms/Directory/Directory';
import Loader from '../../components/Loader';
import Button from '../../components/Button/Button';
import Input from '../../components/Input/Input';
import Checkbox from '../../components/Checkbox/Checkbox';
import {
  searchAuthKeysRequest,
  copyAuthKeyRequest,
  exportAuthKeyRequest,
  getAuthKeyFolderContentRequest,
  moveAuthKeyFolderRequest,
  moveAuthKeyRequest,
  copyAuthKeyFolderRequest,
} from '../../store/authKeys/actions';
import { invitesRequest, addAccessRequest } from '../../store/invites/actions';
import {
  getAllData, getAllfoldersData,
  getFoldersDataInFolder, getDataInFolder,
  checker, getDataPath, getFolderPath
} from '../../helpers/utils';
import { isRoleAction } from '../../helpers/hocs';

import SortIcon from '../../pictures/sort.svg';

import 'react-tooltip/dist/react-tooltip.css';
import * as shared from '../../styles/shared.module.scss';
import { itemsHeightPixels, folderHeightPixels, itemsWidthPixels } from '../../components/ContextMenu';

const headers = {
  checkbox: { title: '' },
  name: { title: 'Name' },
  authKeyName: { title: 'Type' },
  owner: { title: 'Owner' },
  status: { title: 'Status' },
  action: { title: 'Created' },
};

let searchTimeout = null;

function AuthKeysList({
  getFolder,
  copyFolder,
  searchAuthKeys,
  authKeys,
  activeProject,
  isRoleAction,
  copyAuthKey,
  exportAuthKey,
  addAccess,
  currentUser,
  moveAuthKeyFolder,
  moveAuthKey,
}) {
  const [loading, setLoading] = useState(true);
  const { data: authKeysData = [], foldersData = [], breadscrumbData = [] } = authKeys || {};
  const [activeModel, setActiveModel] = useState({});
  const [inputVisible, setInputVisible] = useState(false);

  const [createFolderModalOpened, setCreateFolderModalOpened] = useState(false);
  const [renameFolderModalOpened, setRenameFolderModalOpened] = useState(false);

  const [searchValue, setSearchValue] = useState('');
  const [seachActive, setSearchActive] = useState(false);
  const [removeModalOpened, setRemoveModalOpened] = useState(false);
  const [shareModalOpened, setShareModalOpened] = useState(false);
  const [exportModalOpened, setExportModalOpened] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedFolders, setSelectedFolders] = useState([]);
  const [sortBy, setSortBy] = useState('name');
  const ref = useRef(null);
  const tableRef = useRef(null);
  const { shortName, folderId = 0 } = useParams();
  const navigate = useNavigate();

  const [contextMenuModelActive, setContextMenuModelActive] = useState(false);
  const [contextMenuTop, setContextMenuTop] = useState(0);
  const [contextMenuLeft, setContextMenuLeft] = useState(0);

  const handleContextMenu = (event, id, isFolder) => {
    event.preventDefault();

    setContextMenuModelActive(id);

    const { clientX, clientY } = event;

    const neededHeight = isFolder ? folderHeightPixels : itemsHeightPixels;
    const neededWidth = itemsWidthPixels;

    const w = window.innerWidth;
    const h = window.innerHeight;

    const isHeightFit = (h - clientY) > neededHeight;
    const isWidthFit = (w - clientX) > neededWidth;

    setContextMenuTop(isHeightFit ? clientY : clientY - neededHeight);
    setContextMenuLeft(isWidthFit ? clientX : clientX - neededWidth);
  };

  const createFolder = () => setCreateFolderModalOpened(true);

  useEffect(() => {
    setLoading(true);
    if (activeProject?.id) {
      setSelectedItems([]);
      setSelectedFolders([]);

      getFolder({
        folder: folderId || 0,
        projectId: activeProject?.id,
      }, () => {
        setLoading(false);
      });
    }
  }, [activeProject?.id, folderId, shortName]);

  const createAuthKey = () => (folderId ? navigate(`/${shortName}/auth-keys/folder/${folderId}/create`) : navigate(`/${shortName}/auth-keys/create`));
  const importAuthKey = () => navigate(`/${shortName}/auth-keys/import`);
  const modifyAuthKey = (authKeyId) => navigate(`/${shortName}/auth-keys/${authKeyId}`, { state: { redirectFolder: +folderId || 0 } });
  const openFolder = (f) => navigate(`/${shortName}/auth-keys/folder/${f}`);

  const moveFolder = (f, destination) => {
    setSelectedItems([]);
    moveAuthKeyFolder({
      ...f,
      projectId: activeProject?.id,
      parentId: destination,
      rootAction: +destination === +folderId,
    });
  };

  const moveObject = (o, destination) => {
    setSelectedItems([]);
    moveAuthKey({
      ...o,
      folderId: destination,
      rootAction: +destination === +folderId,
    });
  };

  const changeSearch = (e) => {
    setSearchValue(e.target.value);
    if (searchTimeout) clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => {
      setLoading(true);
      setSelectedItems([]);
      setSelectedFolders([]);
      if (e.target.value) {
        searchAuthKeys({
          query: e.target.value,
          projectId: activeProject.id,
        },() => {
          setLoading(false);
          setSearchActive(true);
        });
      } else {
        getFolder({
          folder: folderId || 0,
          projectId: activeProject?.id,
        }, () => {
          setLoading(false);
          setSearchActive(false);
        });
      }
    }, 400);
  };

  const copyCon = (model) => {
    copyAuthKey(
      {
        ...model,
        projectId: activeProject.id,
        rootAction: +model.folderId === +folderId,
      },
      (access, id) => {
        console.log('access', access);
      },
    );
  };

  const copyFolderAction = (model) => {
    copyFolder({
      ...model,
      rootAction: +model.parentId === +folderId,
    });
  };

  const clear = () => {
    setSearchValue('');
    setLoading(true);
    setSelectedItems([]);
    setSelectedFolders([]);
    getFolder({
      folder: folderId || 0,
      projectId: activeProject?.id,
    },() => {
      setLoading(false);
      setSearchActive(false);
    });
    setInputVisible(false);
  };

  const removeOne = (type, model) => {
    setActiveModel({
      authKeys: type === 'authKey' ? [model.id] : [],
      folders: type === 'folder' ? [model.id] : [],
    });
    setRemoveModalOpened(true);
  };

  const removeSelected = () => {
    setActiveModel({
      authKeys: selectedItems,
      folders: selectedFolders,
    });
    setRemoveModalOpened(true);
  };

  const clickShare = (model) => {
    setActiveModel(model);
    setShareModalOpened(true);
  };

  const exportSelected = () => {
    setActiveModel({
      authKeys: selectedItems,
      folders: selectedFolders,
    });
    setExportModalOpened(true);
  };

  const exportOne = (type, model) => {
    setActiveModel({
      authKeys: type === 'authKey' ? [model.id] : [],
      folders: type === 'folder' ? [model.id] : [],
    });
    setExportModalOpened(true);
  };

  const renameFolder = (model) => {
    setContextMenuModelActive(false);
    setActiveModel(model);
    setRenameFolderModalOpened(true);
  };

  const handleClickOutside = (event) => {
    if (ref && ref.current && !ref.current.contains(event.target)) {
      const input = ref.current.getElementsByTagName('input')[0];
      if (input.value) return;
      setInputVisible(false);
    }

    if (tableRef && tableRef.current && !tableRef.current.contains(event.target)) {
      setContextMenuModelActive(false);
    }
  };

  const sendInviteAction = (data) => {
    // sendInvite(data, () => {
    //   setShareModalOpened(false);
    // });
  };

  const exportC = (list, linkApi = false, linkConnections = false, linkVariables = false) => {
    const { authKeys: keys, folders: fld } = list || {};
    exportAuthKey({
      projectId: activeProject?.id,
      linkApi,
      linkConnections,
      linkVariables,
      resource: 'key',
      objects: [
        ...keys.map((el) => ({ id: el, type: 'key' })),
        ...fld.map((el) => ({ id: el, type: 'folder' })),
      ],
    }, (props) => {
      const { file, name } = props || {};
      const url = window.URL.createObjectURL(file);
      const a = document.createElement('a');
      a.href = url;
      a.download = name;
      a.click();
    });
  };

  const selectAll = (isChecked) => {
    if (isChecked) {
      const allIdsAuthKeys = getAllData(authKeys);
      const allIdsFolders = getAllfoldersData(authKeys);
      setSelectedItems(allIdsAuthKeys);
      setSelectedFolders(allIdsFolders);
    } else {
      setSelectedItems([]);
      setSelectedFolders([]);
    }
  };

  const selectOne = (isChecked, id) => {
    if (isChecked) {
      setSelectedItems([...selectedItems, id]);
    } else {
      const path = getDataPath(authKeys, id) || [];
      setSelectedItems(selectedItems.filter((c) => c !== id));
      setSelectedFolders(selectedFolders.filter((c) => !path.includes(c)));
    }
  };

  const openFolderAction = (id) => {
    setSearchValue('');
    setSearchActive(false);
    setInputVisible(false);
    selectAll(false);
    openFolder(id);
  };

  const selectOneFolder = (isChecked, id) => {
    const nestedFolders = getFoldersDataInFolder(authKeys, id) || [];
    const nestedObjs = getDataInFolder(authKeys, id) || [];

    if (isChecked) {
      setSelectedFolders([...selectedFolders, ...[id, ...nestedFolders]]);
      setSelectedItems([...selectedItems, ...nestedObjs]);
    } else {
      const path = getFolderPath(authKeys, id) || [];
      setSelectedFolders(selectedFolders.filter((c) => !nestedFolders.includes(c) && c !== id && !path.includes(c)));
      setSelectedItems(selectedItems.filter((c) => !nestedObjs.includes(c)));
    }
  };

  const selectItems = (folders, items) => {
    setSelectedFolders([...selectedFolders, ...folders]);
    setSelectedItems([...selectedItems, ...items]);
  };

  const renderHeader = (h, i, arr) => {
    if (h === 'action' && (selectedItems.length > 0 || selectedFolders.length > 0)) {
      return (
        <th key={headers[h].title} style={{ justifyContent: 'flex-end' }}>
          <ActionIcon
            id="exportSelected"
            icon="export"
            style={{ width: '20px', marginRight: '16px' }}
            hiddenFill
            onClick={exportSelected}
            tooltip={{
              content: 'Export',
            }}
          />
          <ActionIcon
            id="deleteSelected"
            icon="trash"
            onClick={removeSelected}
            tooltip={{
              content: 'Delete',
            }}
          />
        </th>
      );
    } if (h !== 'checkbox') {
      return (
        <th key={headers[h].title}>
          <span>{headers[h].title}</span>
          {' '}
          <SortIcon />
        </th>
      );
    }
    return null;
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const handleDropRoot = (e) => {
    const destinationId = +folderId || 0;
    e.preventDefault();
    e.stopPropagation();
    const data = e.dataTransfer.getData('model');
    const parsed = JSON.parse(data);

    if (parsed.type === 'keys' && parsed.id !== destinationId && parsed.parentId !== destinationId && parsed.folder !== destinationId) {
      moveFolder(parsed, destinationId);
    } else if (parsed.id !== destinationId && parsed.parentId !== destinationId && parsed.folder !== destinationId) {
      moveObject(parsed, destinationId);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <div className={shared.page}>
      <div className={shared.header}>
        {!folderId ? (
          <div className={shared.headerTitleGroup}>
            <h1 className={shared.headerTitle}>Auth Key List</h1>
            <p className={shared.headerDescription}>Manage Auth Keys</p>
          </div>
        ) : <Breadscrumbs folders={breadscrumbData} type="authKeys" />}
        <div className={shared.headerButtonGroup}>
          {inputVisible ? (
            <div style={{
                flexGrow: 1,
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <div ref={ref} style={{ width: '100%', maxWidth: '426px'}}>
              <Input
                placeholder="Search"
                iconLeft="search"
                autoFocus
                iconRight={searchValue && 'close'}
                handleChange={changeSearch}
                value={searchValue}
                handleAction={clear}
              />
              </div>
            </div>
          ) : (
            <Button type="secondary" iconLeft="search" onClick={() => setInputVisible(true)} />
          )}
          <Button
            id="import_btn"
            type="secondary"
            title="Import"
            onClick={importAuthKey}
            tooltip={{
              content: 'Import',
            }}
          />
          <Button
            id="export_btn"
            type="secondary"
            title="Export"
            onClick={() => (selectedItems.length > 0 || selectedFolders.length > 0) && exportSelected()}
            style={{ opacity: (selectedItems.length > 0 || selectedFolders.length > 0) ? '1' : '0.4' }}
            tooltip={{
              content: 'Export',
            }}
            disabled={!(selectedItems.length > 0 || selectedFolders.length > 0)}
          />
          <Button
            id="folder_btn"
            type="secondary"
            iconLeft="folder"
            onClick={createFolder}
            style={{ width: '36px', padding: '0px' }}
            tooltip={{
              content: 'Create folder',
            }}
          />
          {isRoleAction({ key: 'key', role: 'create' })
            && <Button title="Create auth key" onClick={createAuthKey} />}
        </div>
      </div>
      <div className={shared.table}>
        <table className={shared.connectionTable} onDrop={(e) => handleDropRoot(e)}>
          <thead>
            <tr className={shared.keysTableRowKeys}>
              <th key="checkbox">
                <Checkbox
                  handleChange={selectAll}
                  size="small"
                  value={
                    (authKeysData?.length || foldersData?.length)
                    && checker(selectedItems, authKeysData)
                    && checker(selectedFolders, foldersData)
                  }
                  style={{ marginRight: '0' }}
                  disabled={!(authKeysData?.length || foldersData?.length)}
                />
              </th>
              {Object.keys(headers).map((h, i, arr) => renderHeader(h, i, arr))}
            </tr>
          </thead>
          {(foldersData?.length || authKeysData?.length) && !loading ? (
            <tbody
              ref={tableRef}
              onDrop={(e) => handleDropRoot(e)}
              onDragOver={(e) => handleDragOver(e)}
              onClick={() => setContextMenuModelActive(false)}
              className={cn({ [shared.hideScroll]: contextMenuModelActive })}
            >
              <Directory
                foldersData={foldersData}
                authKeysData={authKeysData}
                selectedFolders={selectedFolders}
                selectedItems={selectedItems}
                handleContextMenu={handleContextMenu}
                setContextMenuModelActive={setContextMenuModelActive}
                openFolderAction={openFolderAction}
                selectOneFolder={selectOneFolder}
                copyFolder={copyFolderAction}
                selectItems={selectItems}
                contextMenuModelActive={contextMenuModelActive}
                contextMenuTop={contextMenuTop}
                contextMenuLeft={contextMenuLeft}
                modifyAuthKey={modifyAuthKey}
                selectOne={selectOne}
                renameFolder={renameFolder}
                clickShare={clickShare}
                isRoleAction={isRoleAction}
                exportOne={exportOne}
                removeOne={removeOne}
                copyCon={copyCon}
                getFolder={getFolder}
                moveFolder={moveFolder}
                moveObject={moveObject}
                activeProject={activeProject}
                searchActive={seachActive}
              />
            </tbody>
          ) : null}
        </table>
        {loading && (
        <div className={shared.emptyContent}>
          <Loader size="large" />
        </div>
        )}
        {(!authKeysData?.length && !foldersData?.length) && searchValue && !loading && (
          <div className={shared.emptyContent}>
            <h3 className={shared.caption}>No auth keys found</h3>
            <p>
              Please change your search query and try again
            </p>
          </div>
        )}
        {(!authKeysData?.length && !foldersData?.length) && !searchValue && !loading && (
          <div className={shared.emptyContent}>
            {folderId ? (
              <>
                <h3 className={shared.caption}>This folder is empty</h3>
                <p>
                   The available objects will be displayed here.
                </p>
              </>
            ) : (
              <>
                <h3 className={shared.caption}>This project has no Auth Keys yet</h3>
                <p>
                   Please create your auth key to continue.
                </p>
                <div className={shared.action}>
                  <ActionText
                    title="Click here to create auth key"
                    onClick={createAuthKey}
                  />
                </div>
              </>
            )}
          </div>
        )}
        {createFolderModalOpened && (
          <CreateFolderModal
            setIsOpen={(status) => {
              setCreateFolderModalOpened(status);
            }}
            parent={Number(folderId)}
            activeProject={activeProject}
            type="keys"
          />
        )}
        {renameFolderModalOpened && (
          <RenameFolderModal
            setIsOpen={(status) => {
              setRenameFolderModalOpened(status);
            }}
            model={activeModel}
            activeProject={activeProject}
            type="keys"
          />
        )}
        {removeModalOpened && (
          <RemoveModal
            onClose={() => setRemoveModalOpened(false)}
            onSubmit={() => {
              setRemoveModalOpened(false);
              setSelectedItems([]);
              setSelectedFolders([]);
            }}
            model={activeModel}
            collection={authKeys}
          />
        )}
        {shareModalOpened && (
          <InviteToResourceModal
            id={activeModel.id}
            onClose={() => setShareModalOpened(false)}
            onSubmit={sendInviteAction}
            model={activeModel}
            type={activeModel?.type === 'keys' ? 'folder' : 'key'}
            inviteList={[]}
          />
        )}
        {exportModalOpened && (
          <ExportModal
            onClose={() => setExportModalOpened(false)}
            onSubmit={exportC}
            model={activeModel}
            collection={authKeys}
          />
        )}
      </div>
    </div>
  );
}

const mapStateToProps = ({
  authKeys, activeProject, auth: { currentUser }, roles,
}) => ({
  activeProject,
  currentUser,
  roles,
  authKeys,
});

const mapDispatchToProps = (dispatch) => ({
  getFolder: (data, callback) => dispatch(getAuthKeyFolderContentRequest(data, callback)),
  addAccess: (data, callback) => dispatch(addAccessRequest(data, callback)),
  copyAuthKey: (data, callback) => dispatch(copyAuthKeyRequest(data, callback)),
  copyFolder: (data, callback) => dispatch(copyAuthKeyFolderRequest(data, callback)),
  exportAuthKey: (data, callback) => dispatch(exportAuthKeyRequest(data, callback)),
  searchAuthKeys: (data, callback) => dispatch(searchAuthKeysRequest(data, callback)),
  sendInvite: (data, callback) => dispatch(invitesRequest(data, callback)),
  moveAuthKeyFolder: (data, callback) => dispatch(moveAuthKeyFolderRequest(data, callback)),
  moveAuthKey: (data, callback) => dispatch(moveAuthKeyRequest(data, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(isRoleAction(AuthKeysList));

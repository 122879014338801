@use "sass:map";
@import '../../styles/variables.scss';

.layout {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  background-color: map.get($colors-basic, "inputGrey");
  flex-grow: 1;
  padding: 8px 0px;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 4px;
  overflow: auto;
  height: 100%;
  position: absolute;
  width: 100%;
  min-height: 100%;
  margin-bottom: 19px;
}

.loaderWrapper, .errorWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  margin-bottom: 19px;
  gap: 8px;
  padding: 0 8px;
}

.errorIcon {
  margin-bottom: 4px;
  height: 16px;
}

@use "sass:map";
@import '../../styles/variables.scss';

.actionIcon {
    box-sizing: border-box;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    height: 16px;
    width: 17px;
    cursor: pointer;

    &:hover, &:active {
        svg * {
            fill: map.get($colors-basic, "primaryBlue");
        }
    }

    &.active {
        svg * {
            fill: map.get($colors-basic, "primaryBlue");
        }
    }

    &.small {
        width: 12px;
        height: 12px;
    }

    &.large {
        width: 24px;
        height: 24px;
    }

    &.hoverDisabled {
        &:hover {
            svg * {
                fill: map.get($colors-basic, "textBlack");
            }
        }
    }
    
    svg * {
        fill: map.get($colors-basic, "textBlack");
    }

    &.speedometer {
        svg * {
            fill: #fff;
            stroke: map.get($colors-basic, "primaryBlue");
        }
        &:hover, &:active {
            svg * {
                fill: #fff;
                stroke: map.get($colors-basic, "primaryBlue");
            }
        }

        &.active {
            svg * {
                fill: #fff;
                stroke: map.get($colors-basic, "primaryBlue");
            }
        }

        &.hoverDisabled {
            &:hover {
                svg * {
                    fill: #fff;
                    stroke: map.get($colors-basic, "textBlack");
                }
            }
        }
    }
    
    &.hiddenFill {
        svg * {
            fill: none;
        }
    }
}

.tooltipStyled {
    *:hover {
        cursor: default !important;
    }
    a, a:active, a:visited {
        color: white;
        text-decoration: underline;
    }

    a:hover {
        cursor: pointer !important;
    }
}
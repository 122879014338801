/* eslint-disable jsx-a11y/no-autofocus */
import React, {
  useState, useEffect, useRef, useMemo,
} from 'react';
import { connect, useDispatch } from 'react-redux';
import cn from 'classnames';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { shortenEmail, getProjectRoles } from '../../helpers/utils';
import ChipsAction from '../ChipsAction';
import Button from '../Button/Button';
import Select from '../Select/Select';
import ChipsStatus from '../ChipsStatus/ChipsStatus';
import { listUsersRequest, searchUsersRequest, sendInviteRequest } from '../../store/users/actions';
import { addAccessRequest } from '../../store/invites/actions';
import { listRolesName } from '../../helpers/const';
import * as styles from './InviteToResourceModal.module.scss';
import { createToastRequest } from '../../store/toasts/actions';

const CreateProjectModalSchema = Yup.object().shape({
  email: Yup.string().email('Use valid email address'),
});

let searchTimeout = null;

function InviteToResourceModal({
  id,
  model,
  onClose,
  onSubmit,
  sendInvite,
  type = 'project',
  users = [],
  getUsers,
  searchUsers,
  inviteList,
  activeProject,
  addAccess,
  roles,
  currentUser,
}) {
  const [inputFocused, changeInputFocused] = useState(false);
  const [filteredUser, setFilteredUser] = useState([]);
  const listContainerRef = useRef(null);
  const prevListRef = useRef(null);
  const dispatch = useDispatch();

  const usersWithoutMe = useMemo(() => users?.filter((u) => u.id !== currentUser?.id), [users, currentUser?.id]);

  useEffect(() => {
    if (activeProject?.id) getUsers({ id: activeProject.id });
  }, [activeProject?.id]);

  const {
    submitForm, handleChange, setFieldValue, values, errors,
  } = useFormik({
    initialValues: {
      email: '',
      role: 'viewer',
      list: [],
    },
    validationSchema: CreateProjectModalSchema,
    onSubmit: (v) => {
      if (v.list.length === 0) {
        onClose();
        return;
      }
      values.list?.forEach((user) => {
        if (user.id) {
          const sendUser = {
            id: +id,
            resource: type,
            projectId: activeProject.id,
            role: v.role,
            userId: user.id,
          };
          addAccess(sendUser, () => {
            onSubmit?.([sendUser]);
            dispatch(createToastRequest({ type: 'success', title: `${user.firstName} ${user.lastName}`, text: 'has been invited' }));
            onClose();
          });
        } else {
          const sendUser = {
            id: +id,
            resource: type,
            role: v.role,
            projectId: activeProject.id,
            email: user.email,
          };
          sendInvite(sendUser, () => {
            onSubmit?.([sendUser]);
            onClose();
          });
        }
      });
    },
  });

  const onSearch = (e) => {
    handleChange(e);
    if (searchTimeout) clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => {
      if (e.target.value) {
        searchUsers({
          query: e.target.value,
          projectId: activeProject.id,
        });
      } else {
        getUsers({
          id: activeProject?.id,
        });
      }
    }, 400);
  };

  useEffect(() => {
    const renderEmails = values.list.map((i) => i.email);
    const filteredList = 
     usersWithoutMe?.filter((u) => !renderEmails.includes(u.email) && !inviteList.includes(u.email));
    setFilteredUser(filteredList);
  }, [usersWithoutMe, values.list?.length]);

  useEffect(() => {
    if (prevListRef.current < values.list?.length) {
      listContainerRef.current.scrollTop = listContainerRef.current.scrollHeight;
    }
    prevListRef.current = values.list?.length || 0;
  }, [values.list]);

  const saveEmail = (e) => {
    if (e.keyCode === 13 && !errors.email && values.email) {
      let idDublicate = false;
      values.list.forEach((item) => {
        if (item.email === values.email) {
          idDublicate = true;
        }
      });

      if (idDublicate) {
        setFieldValue('email', '');
        return;
      }

      let newUser = {
        firstName: '',
        lastName: '',
        type: 'email',
        email: values.email,
      };

      const foundUser = usersWithoutMe?.find((u) => u.email === values.email);
      if (foundUser?.email) {
        newUser = { ...foundUser, type: 'name' };
      }

      setFieldValue('email', '');
      setFieldValue('list', [...values.list, newUser]);

      getUsers({
        id: activeProject?.id,
      });
    }
  };

  const addUser = (user) => {
    setFieldValue('email', '');
    setFieldValue('list', [...values.list, { ...user, type: 'name' }]);
  };

  const setOuterStyle = () => {
    changeInputFocused(true);
  };

  const removeOuterStyle = () => {
    saveEmail({ keyCode: 13 });
    changeInputFocused(false);
  };

  const clearChip = (user) => {
    const newChips = values.list.filter((item) => item.email !== user.email);
    setFieldValue('list', newChips);

    if (!values.email?.length) {
      getUsers({
        id: activeProject?.id,
      });
    }
  };

  return (
    <div className={styles.modal}>
      <div className={styles.modalContent}>
        <div className={styles.header}>
          <div className={styles.title}>Share access</div>
        </div>
        <div className={styles.body}>
          <div className={styles.mainContent}>
            <div
              className={cn(styles.inputWrapper, {
                [styles.inputFocused]: inputFocused,
                [styles.withControls]: true,
              })}
              ref={listContainerRef}
            >
              <span className={styles.inviteLabel}>To:</span>
              {values.list.map((item) => {
                const title = item.firstName || item.lastName ? `${item.firstName} ${item.lastName}` : item.email;
                return (
                  <ChipsAction
                    title={shortenEmail(title, 50)}
                    type={item.type}
                    onClose={() => clearChip(item)}
                  />
                );
              })}
              <input
                onKeyDown={saveEmail}
                autoComplete="off"
                type="text"
                name="email"
                autoFocus
                placeholder="Enter email"
                onChange={onSearch}
                value={values.email}
                onFocus={setOuterStyle}
                onBlur={removeOuterStyle}
              />
              <div className={styles.selectRole}>
                <Select
                  list={getProjectRoles(roles, activeProject.id).filter((r) => r.name !== 'owner').map((r) => ({ value: r.name, title: listRolesName[r.name] || r.name }))}
                  value={values.role}
                  handleChange={setFieldValue}
                  name="role"
                />
              </div>
            </div>
            {filteredUser?.length > 0 && (
            <>
              <span className={styles.listTitle}>Search results:</span>
              <div className={styles.listWrapper}>
                {filteredUser.map((u) => (
                  <div 
                    role="button"
                    tabIndex={0}
                    key={`${u.email}${u.id}`} 
                    className={styles.listItem} 
                    onClick={() => addUser(u)}
                    onKeyDown={() => addUser(u)}
                  >
                    <div className={styles.listChip}>
                      <ChipsStatus title="User" type="user" />
                    </div>
                    <span>{`${u.firstName} ${u.lastName} (${u.email})`}</span>
                  </div>
                ))}
              </div>
            </>
            )}
          </div>
          <div className={styles.buttonGroupCol}>
            <Button style={{ width: 85 }} title="Cancel" type="secondary" onClick={onClose} />
            <Button
              style={{ width: 154, marginLeft: 12 }}
              title="Send invitations"
              onClick={() => submitForm()}
              disabled={values.list.length === 0}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = ({
  users, activeProject, roles, auth: { currentUser },
}) => ({
  users,
  roles,
  activeProject,
  currentUser,
});

const mapDispatchToProps = (dispatch) => ({
  getUsers: (data) => dispatch(listUsersRequest(data)),
  searchUsers: (data) => dispatch(searchUsersRequest(data)),
  addAccess: (data, callback) => dispatch(addAccessRequest(data, callback)),
  sendInvite: (data, callback) => dispatch(sendInviteRequest(data, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InviteToResourceModal);

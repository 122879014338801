/* eslint-disable max-len */
import React from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import cn from 'classnames';
import { useNavigate, useSearchParams} from 'react-router-dom';
import { useFormik } from 'formik';
import ProjectDefault from '../../pictures/project-default.svg';
import { changePasswordRequest } from '../../store/auth/actions';
import Input from '../../components/Input/Input';

import * as styles from './Auth.module.scss';

const Schema = Yup.object().shape({
  password: Yup.string().required('Password required').matches(
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&_+=();:.,\/><-^])[A-Za-z\d@$!%*#?&_+=();:.,\/><-^]{8,}$/,
    'Use 8 or more chars with a mix of letters, digits & symbols',
  ),
  confirm_password: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match').required('Confirm password required'),
});


function ChangePassword({ changePassword }) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const {
    handleSubmit, handleChange, values, errors, touched, isSubmitting,
  } = useFormik({
    initialValues: {
      password: '',
      confirm_password: '',
    },
    validationSchema: Schema,
    onSubmit: (v) => {
      const code = searchParams.get('code');
      changePassword({ code, password: v.password }, (isRedirect) => {
        if (isRedirect) navigate('/change-password/success');
      })
    },
  });

  return (
    <div className={styles.wrapper}>
      <div className={styles.box}>
        <div className={styles.logoBox}>
          <ProjectDefault />
          <h2>Trigger API Gateway</h2>
        </div>
        <div className={styles.titleBox}>
          <h3>Change password</h3>
          <div />
        </div>
        <form onSubmit={handleSubmit}>
          <div className={styles.row}>
            <Input
              type="password"
              name="password"
              id="password"
              placeholder="Password"
              isPassword
              realPassword
              handleChange={handleChange}
              value={values.password}
              autoComplete="new-password"
              error={errors.password && touched.password}
            />
            {errors.password && touched.password && (
              <span className={styles.errorMessage}>{errors.password}</span>
            )}
          </div>

          <div className={styles.row}>
            <Input
              type="password"
              name="confirm_password"
              id="confirm_password"
              placeholder="Confirm password"
              isPassword
              realPassword
              handleChange={handleChange}
              value={values.confirm_password}
              error={errors.confirm_password && touched.confirm_password}
            />
            {errors.confirm_password && touched.confirm_password && (
              <span className={styles.errorMessage}>{errors.confirm_password}</span>
            )}
          </div>

          <div className={styles.row}>
            <button
              type="submit"
              className={cn({ [styles.buttonSubmitting]: isSubmitting })}
            >
              Change password
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  changePassword: (data, callback) => dispatch(changePasswordRequest(data, callback)),
});

export default connect(null, mapDispatchToProps)(ChangePassword);

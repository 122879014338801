/* eslint-disable no-restricted-syntax */
import {
  call, put, takeEvery, all, fork, select, takeLatest,
} from 'redux-saga/effects';
import { api, apiWorker, apiWorkerFile, getFirstCode, isMessageObjectLike } from '../../helpers/api';
import {
  listEndpointsRequest, listEndpointsSuccess,
  searchEndpointsRequest, searchEndpointsSuccess,
  createEndpointRequest, createEndpointSuccess,
  removeEndpointsListRequest, removeEndpointsListSuccess,
  updateEndpointRequest, updateEndpointSuccess,
  testEndpointRequest, importEndpointRequest,
  exportEndpointRequest, gptRequest,
  updateEndpointFolderRequest, updateEndpointFolderSuccess,
  removeEndpointFolderRequest, removeEndpointFolderSuccess,
  createEndpointFolderRequest, createEndpointFolderSuccess,
  getEndpointFolderContentRequest, getEndpointFolderContentSuccess,
  moveEndpointFolderRequest, moveEndpointFolderSuccess,
  moveEndpointRequest, moveEndpointSuccess,
  copyEndpointFolderRequest, copyEndpointFolderSuccess,
  getEndpointFolderTreeRequest, getEndpointFolderTreeSuccess,
  connectAuthKeyToEndpointRequest, connectAuthKeyToEndpointSuccess,
  disconnectAuthKeyToEndpointRequest, disconnectAuthKeyToEndpointSuccess, copyEndpointRequest,
} from './actions';
import { createToastRequest } from '../toasts/actions';
import {
  refreshRequest,
} from '../auth/actions';
import { Endpoints } from './constants';
import history from '../../history';

const getAuth = (state) => state.auth?.auth;

const getTitle = (type) => {
  switch (type) {
    case 'swagger':
      return 'Swagger uploaded:';
    case 'json':
      return 'JSON uploaded:';
    case 'postman':
      return 'JSON uploaded:';
    default:
      return 'JSON uploaded:';
  }
}

export function* fetchEndpoints(ops) {
  const { data } = ops;
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(api, `/endpoints?projectId=${data.id}`, 'GET', null, authData.access_token);
      if (response.ok) {
        const parsed = yield response.json();
        yield put(listEndpointsSuccess(parsed.items));
      } else if (response.status === 401) {
        yield put(refreshRequest(listEndpointsRequest, data));
      } else {
        const parsed = yield response.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
      }
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* searchEndpoints(ops) {
  const { data, callback } = ops;
  const { projectId, query } = data;
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(api, `/search?projectId=${projectId}&query=${query}&deleted=false&filter=folderId,endpoint&folderType=endpoints`, 'GET', null, authData.access_token);
      if (response.ok) {
        const parsed = yield response.json();
        yield put(searchEndpointsSuccess(parsed));
        if (callback) callback(true);
      } else if (response.status === 401) {
        yield put(refreshRequest(searchEndpointsRequest, data, callback));
      } else {
        const parsed = yield response.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
        if (callback) callback(false);
      }
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
    if (callback) callback(false);
  }
}

export function* createEndpoint(ops) {
  const { data, callback, options } = ops;
  const { rootAction, connectedAuthKeys, ...otherData } = data || {};
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(api, '/endpoints', 'POST', otherData, authData.access_token);
      if (response.ok) {
        const parsed = yield response.json();
        parsed.role = 'owner';
        parsed.connectionName = data.connectionName;

        yield put(createEndpointSuccess({ ...parsed, rootAction }));
        if (connectedAuthKeys?.length) {
          yield put(connectAuthKeyToEndpointRequest({
            endpointId: parsed.id,
            keyId: connectedAuthKeys
          }, () => {
            if (callback) callback(parsed);
          }))
        } else if (callback) callback(parsed);

        yield put(createToastRequest({
          type: 'success',
          title: parsed.name,
          text: 'endpoint has been created successfully',
          linkTitle: `/endpoints/${parsed.id}`,
        }));
      } else if (response.status === 401) {
        yield put(refreshRequest(createEndpointRequest, data, callback, options));
      } else {
        const parsed = yield response.json();
        if (options?.type === 'copy' && options?.step < 5) {
          yield put(createEndpointRequest(
            {
              ...data,
              name: `${data.name}(copy)`,
              path: `${data.path}-copy`,
            },
            () => {
              if (callback) callback();
            },
            {
              step: options.step + 1,
              type: 'copy',
            },
          ));
        } else {
          yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
        }
      }
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* removeEndpointsList(ops) {
  const { data, callback } = ops;
  const { endpoints: endp, folders: fld } = data || {};
  const authData = yield select(getAuth);
  let successFolders;
  let successEndpoints;

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const [folders, endpoints] = yield all([
        fld?.length ? call(api, '/folders', 'PUT', { items: fld.map(f => ({ ...f, deleted: true, items: undefined })) }, authData.access_token) : null,
        endp?.length ? call(api, '/endpoints', 'PUT', { items: endp.map(c => ({ ...c, deleted: true })) }, authData.access_token) : null,
      ]);
      if (folders || endpoints) {
        if (folders?.ok) {
          const parsed = yield folders.json();
          successFolders = fld;
          if (parsed.errors && parsed.errors.length > 0) {
            for (const e of parsed.errors) {
              successFolders = successFolders.filter((folder) => folder.id !== e.id);
              yield put(createToastRequest({ type: 'error', text: e.error }));
            }
          }
          if (successFolders && successFolders.length) {
            for (const el of successFolders) {
              yield put(removeEndpointFolderSuccess(el));
            }

            if (successFolders.length > 1) {
              yield put(createToastRequest({ type: 'success', title: successFolders.length, text: 'folders were removed' }));
            } else {
              yield put(createToastRequest({ type: 'success', title: successFolders[0].name, text: 'folder was removed' }));
            }
          }
        }
        if (endpoints?.ok) {
          const parsed = yield endpoints.json();
          successEndpoints = endp;
          if (parsed.errors && parsed.errors.length > 0) {
            for (const e of parsed.errors) {
              successEndpoints = successEndpoints.filter((endpoint) => endpoint.id !== e.id);
              yield put(createToastRequest({ type: 'error', text: e.error }));
            }
          }
          if (successEndpoints && successEndpoints.length) {
            for (const el of successEndpoints) {
              yield put(removeEndpointsListSuccess(el));
            }
            if (successEndpoints.length > 1) {
              yield put(createToastRequest({ type: 'success', title: successEndpoints.length, text: 'endpoints were removed' }));
            } else {
              yield put(createToastRequest({ type: 'success', title: successEndpoints[0].name, text: 'endpoint was removed' }));
            }
          }
        }
        if (callback) callback({ successFolders, successEndpoints });
      } else if (folders?.status === 401 || endpoints?.status === 401) {
        yield put(refreshRequest(removeEndpointsListRequest, data, callback));
      } else {
        const parsedFolders = yield folders ? folders.json() : null;
        const parsedEndpoints = yield endpoints ? endpoints.json() : null;

        if (callback) callback(false);
        if (parsedFolders) yield put(createToastRequest({ type: 'error', text: parsedFolders?.error, code: parsedFolders?.code }));
        if (parsedEndpoints) yield put(createToastRequest({ type: 'error', text: parsedEndpoints?.error, code: parsedEndpoints?.code }));
      }
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* updateEndpoint(ops) {
  const { data, callback } = ops;
  const { connectedAuthKeys, disconnectedAuthKeys, ...otherData } = data || {};
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(api, '/endpoints', 'PUT', otherData, authData.access_token);
      if (response.ok) {
        const parsed = yield response.json();
        yield put(createToastRequest({
          type: 'success',
          title: data.name,
          text: 'endpoint was updated',
          linkTitle: `/endpoints/${data.id}`,
        }));
        yield put(updateEndpointSuccess(parsed));
        if (connectedAuthKeys && connectedAuthKeys.length) {
          yield put(connectAuthKeyToEndpointRequest({
            endpointId: data.id,
            keyId: connectedAuthKeys
          }));
        }
        if (disconnectedAuthKeys && disconnectedAuthKeys.length) {
          yield put(disconnectAuthKeyToEndpointRequest({
            endpointId: data.id,
            keyId: disconnectedAuthKeys
          }));
        }
        if (callback) callback();
      } else if (response.status === 401) {
        yield put(refreshRequest(updateEndpointRequest, data, callback));
      } else {
        const parsed = yield response.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
      }
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* copyEndpoint(ops) {
  const { data, callback } = ops;
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      // get Access and connected auth Keys
      const [response, connectedKeysResponse] = yield all([
        call(api, `/access/users?resource=endpoint&id=${data.id}`, 'GET', null, authData.access_token),
        call(api, `/auth-keys-link?endpointId=${data.id}`, 'GET', null, authData.access_token),
      ]);
      if (response.ok && connectedKeysResponse.ok) {
        const parsed = yield connectedKeysResponse.json();
        const { items = [] } = parsed || {};
        const connectedAuthKeys = items?.length ? items.map(i => i.id) : [];
        // create endpoint
        yield put(createEndpointRequest(
          {
            ...data,
            name: `${data.name}(copy)`,
            path: `${data.path}-copy`,
            connectedAuthKeys
          },
          () => {
            callback && callback();
          },
          {
            step: 1,
            type: 'copy',
          },
        ));
      } else if (response.status === 401 || connectedKeysResponse === 401) {
        yield put(refreshRequest(copyEndpointRequest, data, callback));
      } else {
        const parsed = yield response.json();
        const parsedKeys = yield connectedKeysResponse.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error || parsedKeys?.error, code: parsed?.code || parsedKeys?.code }));
      }
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* exportEndpoint(ops) {
  const { data, callback } = ops;
  const authData = yield select(getAuth);
  const exportUrl = data.type === 'json' ? "/export" : `/export/${data.type}`;

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(apiWorker, exportUrl, 'POST', data, authData.access_token);
      if (response.ok) {
        const parsedAccess = yield response.json();
        if (parsedAccess.file) {
          yield put(createToastRequest({ type: 'success', text: 'Export in progress... Don\'t refresh this page.' }));
          const file = yield call(apiWorker, `/export/file/${parsedAccess.file}`, 'GET', null, authData.access_token);
          const contentDisposition = file.headers.get('Content-Disposition');
          const filename = contentDisposition.match(/filename="(.+)"/)[1];
          file.blob().then((blob) => {
            callback && callback({file: blob, name: filename });
          });
        } else {
          yield put(createToastRequest({ type: 'error', text: 'Error export on first step' }));
        }
      } else if (response.status === 401) {
        yield put(refreshRequest(exportEndpointRequest, data));
      } else {
        const parsed = yield response.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
      }
    }
  } catch (e) {
    console.log('e', e);
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* importEndpoint(ops) {
  const { data, callback } = ops;
  const {
    projectId, linkConnections, linkKeys, linkVariables,
    endpoints, file, onlyFile = false, type = 'json',
    force,
  } = data;
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      let response;
      if(onlyFile){
        if(type === 'swagger'){
          response = yield call(apiWorkerFile, `/import/swagger?projectId=${projectId}&linkConnections=${linkConnections}${force ? `&force=true` : ''}`, authData.access_token, file, 'swagger.yaml');
        }
        if (type === 'postman') {
          response = yield call(apiWorkerFile, `/import/postman?projectId=${projectId}&linkConnections=${linkConnections}&linkKeys=${linkKeys}&linkVariables=${linkVariables}${force ? `&force=true` : ''}`, authData.access_token, file, 'postman.json');
        }
      } else {
         response = yield call(apiWorkerFile, `/import/file?projectId=${projectId}&linkConnections=${linkConnections}&linkKeys=${linkKeys}&linkVariables=${linkVariables}&id=${endpoints}&resource=endpoint${force ? `&force=true` : ''}`, authData.access_token, file);
      }
      const parsed = JSON.parse(response);
      if (parsed.status === 'success') {
        yield put(createToastRequest({ 
          type: 'success', 
          title: getTitle(type), 
          text: 'endpoints have been added to the list' 
        }));
        if (callback) callback(parsed);
      } else if (parsed.status === 401) {
        yield put(refreshRequest(importEndpointRequest, data, callback));
      } else {
          yield put(createToastRequest({ 
            type: type === 'postman' && isMessageObjectLike(parsed) && getFirstCode(parsed) === '37' ? 'action' : 'error',
            code: (isMessageObjectLike(parsed) && getFirstCode(parsed)) ? getFirstCode(parsed) : null,
            text: isMessageObjectLike(parsed) ? null : parsed.msg,
            submit: (dispatch) => {
              dispatch(importEndpointRequest({ ...data, force: true }, callback));
            }
          }));
        }
    }
  } catch (e) {
    console.log('e', e);
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* testEndpoint(ops) {
  const { data, callback } = ops;
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(api, '/endpoints/test', 'POST', data, authData.access_token);
      if (response.ok) {
        const parsed = yield response.json();
        if (callback) callback('SUCCESS', parsed);
      } else if (response.status === 401) {
        yield put(refreshRequest(testEndpointRequest, data, callback));
      } else {
        const parsed = yield response.json();
        if (callback) callback('ERROR', parsed?.error);
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
      }
    }
  } catch (e) {
    console.log('e', e);
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* gptSuggestion(ops) {
  const { data, callback } = ops;
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(api, '/chat', 'POST', data, authData.access_token);
      if (response.ok) {
        const parsed = yield response.json();
        if (callback) callback('SUCCESS', parsed.response);
      } else if (response.status === 401) {
        yield put(refreshRequest(gptRequest, data, callback));
      } else {
        const parsed = yield response.json();
        if (callback) callback('ERROR', parsed?.error);
      }
    }
  } catch (e) {
    console.log('e', e);
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* createEndpointFolder(ops) {
  const { data, callback } = ops;
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(api, '/folders', 'POST', data, authData.access_token);
      if (response.ok) {
        const parsed = yield response.json();

        yield put(createEndpointFolderSuccess(parsed));

        yield put(createToastRequest({
          type: 'success',
          title: parsed.name,
          text: 'folder has been created successfully',
        }));
        if (callback) callback(parsed);
      } else if (response.status === 401) {
        yield put(refreshRequest(createEndpointFolderRequest, data, callback));
      } else {
        const parsed = yield response.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
      }
    }
  } catch (e) {
    console.log('e', e);
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* getEndpointFolder(ops) {
  const { data, callback } = ops;
  const { merge } = data || {};
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const [folders, endpoints] = yield all([
        call(api, `/folders?folderId=${data.folder}&type=endpoints&projectId=${data.projectId}`, 'GET', null, authData.access_token),
        call(api, `/endpoints?folderId=${data.folder}&projectId=${data.projectId}`, 'GET', null, authData.access_token),
      ]);
      if (folders.ok && endpoints.ok) {
        const parsedFolders = yield folders.json();
        const parsedEndpoints = yield endpoints.json();

        yield put(getEndpointFolderContentSuccess({
          merge,
          folderId: data.folder,
          folders: parsedFolders.next,
          data: parsedEndpoints.items,
          breadscrumbs: parsedFolders.prev,
        }));

        if (callback) {
          callback({
            merge,
            folderId: data.folder,
            folders: parsedFolders.next,
            data: parsedEndpoints.items,
            breadscrumbs: parsedFolders.prev,
          });
        }
      } else if (folders.status === 401 || endpoints.status === 401) {
        yield put(refreshRequest(getEndpointFolderContentRequest, data, callback));
      } else {
        const parsedFolders = yield folders.json();
        const parsedEndpoints = yield endpoints.json();
        yield put(createToastRequest({
          type: 'error',
          text: parsedFolders?.error || parsedEndpoints?.error,
          code: parsedFolders?.code || parsedEndpoints?.error,
        }));
      }
    }
  } catch (e) {
    console.log('e', e);
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* updateEndpointFolder(ops) {
  const { data, callback } = ops;
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(api, '/folders', 'PUT', {
        id: data.id,
        projectId: data.projectId,
        name: data.name,
        type: data.type,
        parentId: data.parentId,
      }, authData.access_token);
      if (response.ok) {
        yield put(createToastRequest({
          type: 'success',
          title: data.name,
          text: 'folder was updated',
        }));
        yield put(updateEndpointFolderSuccess(data));
        if (callback) callback();
      } else if (response.status === 401) {
        yield put(refreshRequest(updateEndpointFolderRequest, data, callback));
      } else {
        const parsed = yield response.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
      }
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* moveEndpointFolder(ops) {
  const { data, callback } = ops;
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(api, '/folders', 'PUT', {
        id: data.id,
        projectId: data.projectId,
        name: data.name,
        type: data.type,
        parentId: data.parentId,
      }, authData.access_token);
      if (response.ok) {
        yield put(createToastRequest({
          type: 'success',
          title: data.name,
          text: 'folder was moved',
        }));
        yield put(moveEndpointFolderSuccess(data));
        if (callback) callback();
      } else if (response.status === 401) {
        yield put(refreshRequest(moveEndpointFolderRequest, data, callback));
      } else {
        const parsed = yield response.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
      }
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* moveEndpoint(ops) {
  const { data, callback } = ops;
  const { rootAction, ...otherData } = data || {};
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(api, '/endpoints', 'PUT', otherData, authData.access_token);
      if (response.ok) {
        yield put(moveEndpointSuccess({ ...otherData, rootAction }));
        yield put(createToastRequest({
          type: 'success',
          title: otherData.name,
          text: 'endpoint was moved',
        }));
        if (callback) callback();
      } else if (response.status === 401) {
        yield put(refreshRequest(moveEndpointRequest, data, callback));
      } else {
        const parsed = yield response.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
      }
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* copyEndpointFolder(ops) {
  const { data, callback } = ops;
  const { rootAction } = data || {};
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(api, '/folders/copy', 'POST', {
        folderId: data.id,
        toFolderId: data.parentId,
        name: `${data.name}(copy)`,
      }, authData.access_token);
      if (response.ok) {
        const parsed = yield response.json();
        yield put(createToastRequest({
          type: 'success',
          title: data.name,
          text: 'folder was copied',
        }));
        yield put(copyEndpointFolderSuccess({ ...parsed.folderId, rootAction }));
        if (callback) callback();
      } else if (response.status === 401) {
        yield put(refreshRequest(copyEndpointFolderRequest, data, callback));
      } else {
        const parsed = yield response.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
      }
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* getTree(ops) {
  const { data, callback } = ops;
  const { ids } = data || {};
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const parsedResponses = [];

      for (const id of ids) {
        const response = yield call(api, `/tree?id=${id}`, 'GET', null, authData.access_token);

        if (response.ok) {
          const parsed = yield response.json();
          parsedResponses.push(parsed);
          yield put(getEndpointFolderTreeSuccess(parsed));
        } else if (response.status === 401) {
          yield put(refreshRequest(getEndpointFolderTreeRequest, data, callback));
          break;
        } else {
          const parsed = yield response.json();
          yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
          if (callback) callback();
          break;
        }
      }

      if (ids.length === parsedResponses.length && callback) {
        if (callback) callback(parsedResponses);
      } else if (callback) callback();
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
    if (callback) callback();
  }
}

export function* connectAuthKeyToEndpoint(ops) {
  const { data, callback } = ops;
  const { endpointId, keyId } = data || {};
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(api, '/auth-keys-link', 'POST', {
        endpointId,
        keyId
      }, authData.access_token);
      if (response.ok) {
        yield put(connectAuthKeyToEndpointSuccess());
        if (callback) callback();
      } else if (response.status === 401) {
        yield put(refreshRequest(connectAuthKeyToEndpointRequest, data, callback));
      } else {
        const parsed = yield response.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
        if (callback) callback();
      }
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
    if (callback) callback();
  }
}

export function* disconnectAuthKeyToEndpoint(ops) {
  const { data, callback } = ops;
  const { endpointId, keyId } = data || {};
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(api, '/auth-keys-link', 'DELETE', {
        endpointId,
        keyId
      }, authData.access_token);
      if (response.ok) {
        yield put(disconnectAuthKeyToEndpointSuccess());
        if (callback) callback();
      } else if (response.status === 401) {
        yield put(refreshRequest(disconnectAuthKeyToEndpointRequest, data, callback));
      } else {
        const parsed = yield response.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
        if (callback) callback();
      }
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
    if (callback) callback();
  }
}

function* endpointsSaga() {
  yield takeEvery(Endpoints.listEndpointsRequest, fetchEndpoints);
  yield takeEvery(Endpoints.searchEndpointsRequest, searchEndpoints);
  yield takeEvery(Endpoints.createEndpointRequest, createEndpoint);
  yield takeEvery(Endpoints.removeEndpointsListRequest, removeEndpointsList);
  yield takeEvery(Endpoints.updateEndpointRequest, updateEndpoint);
  yield takeEvery(Endpoints.copyEndpointRequest, copyEndpoint);
  yield takeEvery(Endpoints.exportEndpointRequest, exportEndpoint);
  yield takeEvery(Endpoints.importEndpointRequest, importEndpoint);
  yield takeEvery(Endpoints.testEndpointRequest, testEndpoint);
  yield takeEvery(Endpoints.gptRequest, gptSuggestion);
  yield takeEvery(Endpoints.createEndpointFolderRequest, createEndpointFolder);
  yield takeEvery(Endpoints.updateEndpointFolderRequest, updateEndpointFolder);
  yield takeLatest(Endpoints.getEndpointFolderContentRequest, getEndpointFolder);
  yield takeEvery(Endpoints.moveEndpointFolderRequest, moveEndpointFolder);
  yield takeEvery(Endpoints.moveEndpointRequest, moveEndpoint);
  yield takeEvery(Endpoints.copyEndpointFolderRequest, copyEndpointFolder);
  yield takeEvery(Endpoints.getEndpointFolderTreeRequest, getTree);
  yield takeEvery(Endpoints.connectAuthKeyToEndpointRequest, connectAuthKeyToEndpoint);
  yield takeEvery(Endpoints.disconnectAuthKeyToEndpointRequest, disconnectAuthKeyToEndpoint);
}

function* configSaga() {
  yield all([fork(endpointsSaga)]);
}

export default configSaga;

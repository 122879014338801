/* eslint-disable no-unreachable */
/* eslint-disable consistent-return */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState, useCallback, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import Editor, { useMonaco } from '@monaco-editor/react';
import { uid } from 'uid';
import ActionIcon from '../ActionIcon';
// eslint-disable-next-line import/no-cycle
import CodeEditorModal from './CodeEditorModal';
import { ViewModes, InlineClasses, codeEditorOptions } from './codeEditorConstants';
import { notFocusedTheme } from './CodeEditorHelper';
import './CodeEditor.scss';
import * as styles from './CodeEditor.module.scss';
import { transformTextWithVariables } from '../../helpers/tokenizeAutocompleteUtils';

// const variables ={data:  [{id: 1, name: 'asd'}, {id: 2, name: 'qwe'}]};
function CodeEditor({
  value,
  handleChange,
  handleBlurCallback,
  name,
  id,
  style = {},
  error,
  readOnly,
  expand = true,
  placeholder,
  type = 'json',
  height = '180px',
  viewMode = ViewModes.Multiline,
  withVariables = true,
}) {
  const monaco = useMonaco();

  // console.log('type', {type, placeholder, value});
  // const editorRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const uuid = useMemo(() => uid(8));
  const placeholderTopStype = { top: type === 'json' ? '8px' : '9.5px' };
  const readOnlyRef = useRef(readOnly);
  readOnlyRef.current = readOnly;
  const [valueEdit, setValueEdit] = useState(null);
  const valueEditRef = useRef(null);
  valueEditRef.current = valueEdit;
  const [focusStyle, setFocusStyle] = useState(false);
  const [modal, setModal] = useState(false);

  const { variables: v } = useSelector(({ variables }) => ({
    variables,
  }));
  const variables = useMemo(() =>{
    if(withVariables){
      return v;
    }
    return {data: []}
  }, [withVariables, v]);

  const transformVariables = (text)=> {
    const transformed = transformTextWithVariables(text, variables?.data || [], 'name', type!=="javascript", false);
    setValueEdit(transformed);
  }

  useEffect(() => {
    if (!withVariables || value === null || !variables?.data) {
      setValueEdit(value);
    } else {
      const toTransform = value;
      transformVariables(toTransform);
    }
  }, [value, variables, withVariables]);

  useEffect(() => {
    if(!withVariables || !value || !variables.data) return;
    transformVariables(value)
  }, [type, value, withVariables]);

  useEffect(() => {
    const placeholder = document.querySelector(`.monaco-placeholder-${uuid}`);
    if (!placeholder) return;
    if (!valueEdit) {
      placeholder.style.display = 'block';
    } else {
      placeholder.style.display = 'none';
    }
  }, [value, valueEdit, loading]);

  useEffect(() => {
    if(monaco && valueEdit !== null && variables.data) setTimeout(() => setLoading(false), 0); 
  }, [monaco, valueEdit]);

  const onChange = useCallback(
    (v) => {
      setValueEdit(v);
      const transformed = transformTextWithVariables(v, variables.data || [], 'id', type!=="javascript", false);
      return handleChange?.(transformed);
    },
    [handleChange, variables],
  );



  const onExpand = () => {
    setModal(true);
  };

  const handleEditorLoad =(editor, monaco) => {
    editor.onDidFocusEditorText(() => {
      if (readOnlyRef.current) return;
      setFocusStyle(true);
    });
    editor.onDidBlurEditorText(() => {
      if (!readOnlyRef.current) {
        setFocusStyle(false);
      }
      if (handleBlurCallback) handleBlurCallback(valueEditRef.current);
    });

    monaco?.editor?.defineTheme('notFocused', notFocusedTheme.params);
    monaco?.editor?.setTheme('notFocused');
    monaco.languages.register({ id: 'text' });

    editor.updateOptions({ fontSize: 14, fontFamily: 'Menlo, monospace', lineHeight: 23 });
    document.fonts.ready.then(() => {
      monaco.editor.remeasureFonts();
    });

    // editorRef.current = editor;
    if (type !== 'json') return;
    editor.onKeyUp(() => {
      const position = editor.getPosition();
      const text = editor.getModel().getLineContent(position.lineNumber);
      const cursorIndex = position.column - 2;
      if (text[cursorIndex] === '{' && text[cursorIndex - 1] === '{') {
        editor.trigger('', 'editor.action.triggerSuggest', '');
      }
    });
  };

  const isMultilined = typeof valueEdit === 'string' && valueEdit.includes('\n');

  return (
    <div
      className={cn(styles.codeEditor, {
        [styles.error]: error,
        [styles.scrollConfigInline]: viewMode === ViewModes.Inline,
        [styles.focusedEditor]: focusStyle,
      })}
      id={id}
      style={{
        overflow: isMultilined ? undefined : 'hidden',
        ...style
      }}
    >
      <Editor
        theme="notFocused"
        className={cn(InlineClasses[viewMode], { 'ace-custom-focused': focusStyle, error_content: error })}
        onChange={onChange}
        value={valueEdit}
        height={height}
        width="100%"
        language={type}
        onMount={handleEditorLoad}
        options={{ readOnly, ...codeEditorOptions }}
      />
      <div className={`monaco-placeholder monaco-placeholder-${uuid}`} style={placeholderTopStype}>
        {placeholder}
      </div>
      {expand && (
        <div className={styles.expandWrapper}>
          <ActionIcon icon="expand" onClick={onExpand} />
        </div>
      )}
      {modal && (
        <CodeEditorModal
          value={value}
          height="calc(100vh - 280px)"
          handleChange={(valueEdit) => {
            if (handleBlurCallback) handleBlurCallback(valueEdit);
            if (handleChange) handleChange(valueEdit);
          }}
          name={name}
          error={error}
          readOnly={readOnly}
          expand={false}
          placeholder={placeholder}
          type={type}
          setIsOpen={setModal}
        />
      )}
    </div>
  );
}

CodeEditor.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  handleChange: PropTypes.func,
  style: PropTypes.object,
  name: PropTypes.string,
  handleBlurCallback: PropTypes.func,
  readOnly: PropTypes.bool,
  type: PropTypes.string,
};

export default CodeEditor;

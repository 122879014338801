/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import debounce from 'lodash.debounce';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import cn from 'classnames';
import ValidationErrorList from '../../components/ValidationErrorList/ValidationErrorList';
import { createProjectRequest } from '../../store/projects/actions';
import { invitesRequest } from '../../store/invites/actions';
import Button from '../../components/Button/Button';
import Select from '../../components/Select/Select';
import InviteToResourceModal from '../../components/InviteToResourceModal/InviteToResourceModal';
import { shorNameGenerator, roleMapper } from '../../helpers/utils';
import Input from '../../components/Input/Input';

import * as shared from '../../styles/shared.module.scss';
import Layout from '../../containers/Layout/Layout';

const subrouteMapping = {};

const SUBROUTING = {
  ACCESS: 'access',
};

const validationRegex = /^(?!\s)(?!.* {2})/;

const ProjectCreateSchema = Yup.object().shape({
  name: Yup.string()
    .required('Project Name is required')
    .matches(
      validationRegex,
      'Project Name is required',
    )
    .min(2, 'Project Name min length 2')
    .max(64),
  shortName: Yup.string().required('shortName required'),
});

function ProjectsCreate({ createProject, currentUser }) {
  const [submitTriggered, setSubmitTriggered] = useState(false);
  const [inviteList, setInviteList] = useState([]);
  const [shareModalOpened, setShareModalOpened] = useState(false);
  const [subroute, setSubroute] = useState(SUBROUTING.ACCESS);
  const { state } = useLocation();
  const navigate = useNavigate();
  const { secure } = state || {};
  const { shortName: hasShortName } = useParams();

  useEffect(() => {
    if (currentUser) {
      setInviteList([{
        userFirstName: currentUser.firstName,
        userLastName: currentUser.lastName,
        userEmail: currentUser.email,
        roleId: 2,
      }]);
    }
  }, [currentUser]);

  const secureGoBack = () => {
    if (secure) navigate(-1);
    if (!secure) navigate('/');
  };

  const saveInviteList = (data) => {
    const filtered = data.accessList.filter((el) => el.userEmail !== currentUser?.email);
    const unique = Object.values(
      [...inviteList, ...filtered].reduce((acc, obj) => ({ ...acc, [obj.userEmail]: obj }), {}),
    );
    setInviteList(unique);
    setShareModalOpened(false);
  };

  const {
    submitForm, handleChange: handleChange2, setFieldValue: setFieldValue2,
    values: { name, shortName, description, status }, errors, validateForm
  } = useFormik({
    initialValues: {
      name: '',
      shortName: '',
      status: 'active'
    },
    validationSchema: ProjectCreateSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (v) => {
      createProject({ 
        name: v.name, 
        shortName: v.shortName,
        description: v.description 
      }, (shortname) => {
        navigate(`/${shortname}/endpoints`);
      });
    },
  });
  
  const debouncedValidateForm = debounce(() => {
    validateForm();
  }, 100);

  const setFieldValue = (...args) => {
    setFieldValue2(...args);
    if (submitTriggered) {
      debouncedValidateForm();
    }
  }

  const handleChange = (...args) => {
    handleChange2(...args);
    if (submitTriggered) {
      debouncedValidateForm();
    }
  }

  const handleSubmitWithCallback = (e) => {
    e.preventDefault();
    setSubmitTriggered(true);
    submitForm(e);
  };

  const handleChangeShortname = (e) => {
    const { value } = e.target;
    const transformed = shorNameGenerator(value);
    setFieldValue('shortName', transformed);
  };

  const handleChangeProxy = (...arg) => handleChange(...arg);

  useEffect(() => {
    setFieldValue('shortName', shorNameGenerator(name));
  }, [name]);

  const handleChangeProjectName = (e) => {
    if (validationRegex.test(e.target.value) || e.target.value === '') {
      setFieldValue(e.target.name, e.target.value);
    }
  };

  const handeChangeRole = (email, role) => {
    const newInviteList = inviteList.map((el) => (el.userEmail === email ? { ...el, roleId: role } : el));
    setInviteList(newInviteList);
  };

  const JSXToRender = (
    <div className={shared.page}>
      <div className={shared.header}>
        <div className={shared.headerTitleGroup}>
          <h1 className={shared.headerTitle}>Create Project</h1>
        </div>
        <div className={shared.headerButtonGroup}>
          <ValidationErrorList 
            errors={errors} 
            subrouteMapping={subrouteMapping}
            setSubroute={setSubroute}
          />
          <Button title="Cancel" type="secondary" onClick={secureGoBack} />
          <Button title="Create project" onClick={handleSubmitWithCallback} />
        </div>
      </div>
      <div className={shared.body}>
        <div className={shared.mainContent}>
          <section className={shared.section}>
            <h4 className={shared.sectionHeader}>Project Settings</h4>
            <div className={shared.sectionContainer}>
              <div className={shared.inputWrapper}>
                <span className={shared.inputLabel}>Name</span>
                <Input
                  placeholder="Enter name"
                  handleChange={handleChangeProjectName}
                  value={name}
                  name="name"
                  id="name"
                  autoComplete="off"
                  error={!!errors.name}
                />
              </div>
              <div className={shared.inputWrapper}>
                <span className={shared.inputLabel}>
                  Description <span className={shared.inputLabelDetails}> (optional)</span>
                </span>
                <Input
                  placeholder="Enter description"
                  handleChange={handleChangeProxy}
                  value={description}
                  name="description"
                  id="description"
                  autoComplete="off"
                  error={!!errors.description}
                />
              </div>
              <div className={shared.inputWrapper}>
                <span className={shared.inputLabel}>Short Name</span>
                <Input
                  placeholder="enter-short-name"
                  handleChange={handleChangeShortname}
                  value={shortName}
                  name="shortName"
                  id="shortName"
                  autoComplete="off"
                  error={errors.shortName}
                />
              </div>
              <div className={shared.inputWrapper}>
                <span className={shared.inputLabel}>Status</span>
                <Select
                  list={[
                    { value: 'active', title: 'Active' },
                    { value: 'inactive', title: 'Inactive' },
                  ]}
                  value={status}
                  handleChange={handleChangeProxy}
                  name="status"
                  disabled
                />
              </div>
            </div>
          </section>
        </div>
        <div className={shared.sidepanel}>
          <div className={shared.sidepanelGroup}>
              <div className={shared.sidepanelHeading}>
                <div className={shared.subHeaders}>
                  <h4
                    onClick={() => setSubroute(SUBROUTING.ACCESS)}
                    onKeyDown={() => setSubroute(SUBROUTING.ACCESS)}
                    role="button"
                    className={cn(
                      shared.sectionHeader, 
                      { [shared.clickable]: false },
                      { [shared.passive]: subroute !== SUBROUTING.ACCESS })}
                  >
                    Access
                  </h4>
                </div>
              </div>
              {subroute === SUBROUTING.ACCESS && (
                <div className={shared.sidepanelTable}>
                  <div className={shared.sidepanelTableHeading}>
                    <div className={shared.lineHeader}>
                      <div>Name</div>
                      <div />
                      <div>Role</div>
                    </div>
                  </div>
                  <div className={shared.sidepanelTableBodyContainer}>
                  <ul className={shared.sidepanelTableBody}>
                      {inviteList &&
                        inviteList.map((item) => (
                          <li className={shared.historyLine} key={item.userEmail}>
                            <div title={item.userEmail}>
                              {item.userEmail === currentUser.email ? (
                                <span>{`${item.userFirstName} ${item.userLastName}`}</span>
                              ) : (
                                <span>{item.userEmail}</span>
                              )}
                            </div>
                            <div />
                            <div>
                              {item.userEmail === currentUser.email ? (
                                <Select
                                  list={[{ value: 2, title: roleMapper(2) }]}
                                  style={{ width: 90 }}
                                  value={item.roleId}
                                  handleChange={(email, value) => handeChangeRole(email, value)}
                                  name={item.userEmail}
                                  disabled
                                />
                              ) : (
                                <Select
                                  list={[
                                    { value: 3, title: roleMapper(3) },
                                    { value: 4, title: roleMapper(4) },
                                  ]}
                                  style={{ width: 90 }}
                                  value={item.roleId}
                                  handleChange={(email, value) => handeChangeRole(email, value)}
                                  name={item.userEmail}
                                />
                              )}
                            </div>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              )}
          </div>
        </div>
        {shareModalOpened && (
          <InviteToResourceModal
            onClose={() => setShareModalOpened(false)}
            onSubmit={saveInviteList}
            model={{ name, shortName }}
          />
        )}
      </div>
    </div>
  );

  return hasShortName ? JSXToRender : <Layout>{JSXToRender}</Layout>;
}

const mapStateToProps = ({ auth: { currentUser } }) => ({
  currentUser,
});

const mapDispatchToProps = (dispatch) => ({
  createProject: (data, callback) => dispatch(createProjectRequest(data, callback)),
  sendInvite: (data, callback) => dispatch(invitesRequest(data, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsCreate);

/* eslint-disable import/prefer-default-export */
export const ActiveSearchProject = {
  activeSearchProjectRequest: 'ACTIVE_SEARCH_PROJECT_PENDING',
  activeSearchProjectSuccess: 'ACTIVE_SEARCH_PROJECT_SUCCESS',

  getAccessUsersProjectSuccess: 'GET_ACCESS_USERS_PROJECT_SUCCESS',

  getConnectedConnectionsToProjectRequest: 'GET_CONNECTED_CONNECTIONS_TO_PROJECT_REQUEST',
  getConnectedConnectionsToProjectSuccess: 'GET_CONNECTED_CONNECTIONS_TO_PROJECT_SUCCESS',

  getConnectedEndpointsToProjectRequest: 'GET_CONNECTED_ENDPOINTS_TO_PROJECT_REQUEST',
  getConnectedEndpointsToProjectSuccess: 'GET_CONNECTED_ENDPOINTS_TO_PROJECT_SUCCESS',
};

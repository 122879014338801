/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-autofocus */
import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { ThreeDots } from 'react-loader-spinner';
import Button from '../Button/Button';
import { getFolderPath, getDataPath, filterItemsByExclude, isSafari } from '../../helpers/utils';
import { getEndpointFolderTreeRequest } from '../../store/endpoints/actions';
import * as styles from './ExportEndpointModal.module.scss';
import * as shared from '../../styles/shared.module.scss';
import Checkbox from '../Checkbox/Checkbox';
import PostmanImg from '../../pictures/export/postman.svg';
import SwaggerImg from '../../pictures/export/swagger.svg';
import JSONImg from '../../pictures/export/json.svg';

function ExportEndpointModal({
  onClose,
  onSubmit,
  model,
  collection,
  type = 'json',
}) {
  const dispatch = useDispatch();
  const { endpoints = [], folders = [] } = model || {};
  const isPostman = type === 'postman';
  const [linkConnections, setLinkConnections] = useState(false);
  const [linkVariables, setLinkVariables] = useState(isPostman);
  const [linkAuthKeys, setLinkAuthKeys] = useState(isPostman);
  const [filteredFolders, setFilteredFolders] = useState([]);
  const [filteredEndpoints, setFilteredEndpoints] = useState([]);
  const [nestedFolders, setNestedFolders] = useState([]);
  const [nestedEndpoints, setNestedEndpoints] = useState([]);
  const [loadDetails, setLoadDetails] = useState(false);

  const typeData = useMemo(() => {
    switch (type) {
      case 'json':
        return{
          title: "Export APIs",
          iconTitle: <JSONImg />,
          to: "JSON",
          format: '.json'
        }
      case 'postman':
        return{
          title: "Export APIs to Postman",
          iconTitle: <PostmanImg />,
          to: "Postman",
          format: '.json'
        }
      case 'swagger':
        return{
          title: "Export APIs to Swagger",
          iconTitle: <SwaggerImg />,
          to: "Swagger",
          format: '.yaml'
        }
      default:
        return {}
    }
  }, [type]);

  useEffect(() => {
    const calcPaths = () => {
      const f = folders.map((el) => ({ id: el, path: getFolderPath(collection, el) }));
      const c = endpoints.map((el) => ({ id: el, path: getDataPath(collection, el) }));

      const fltF = filterItemsByExclude(f, folders) || [];
      const fltC = filterItemsByExclude(c, folders) || [];

      setFilteredFolders(fltF.map((el) => el.id));
      setFilteredEndpoints(fltC.map((el) => el.id));
    };

    calcPaths();
  }, [model, collection, folders, endpoints]);

  useEffect(() => {
    setLoadDetails(true);

    const fetchData = () => {
      if (!filteredFolders.length) {
        setLoadDetails(false);
        setNestedEndpoints([]);
        setNestedFolders([]);
      } else {
        dispatch(getEndpointFolderTreeRequest({ ids: filteredFolders }, (result) => {
          setLoadDetails(false);
          if (result) {
            const f = result.filter((el) => el.folders).map((el) => el.folders).flat() || [];
            const c = result.filter((el) => el.endpoints).map((el) => el.endpoints).flat() || [];
            setNestedEndpoints(c);
            setNestedFolders(f);
          } else {
            setNestedEndpoints([]);
            setNestedFolders([]);
          }
        }));
      }
    };

    fetchData();
  }, [filteredFolders, dispatch]);

  const numFilteredFolders = nestedFolders.length || filteredFolders.length;
  const numFilteredEndpoints = filteredEndpoints.length + nestedEndpoints.length;

  return (
    <div className={styles.modal}>
      <div className={styles.modalContent}>
        <div className={styles.header}>
          <div className={styles.title}>{typeData.iconTitle} {typeData.title}</div>
        </div>
        <div className={styles.body}>
          <div className={styles.mainContent}>
            <div>
              <div>
                {loadDetails && !isSafari() && (
                  <span className={styles.modelTitle}
                  style={{ marginRight: '12px' }}
                  >
                  <ThreeDots
                    height="13"
                    width="13"
                    color="#4149F7"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{
                      display: 'inline',
                      transform: 'translateY(-20px)'
                    }}
                    visible
                  />
                  </span>
                )}
                {(numFilteredFolders && !loadDetails) ? (
                  <span className={styles.modelTitle}>
                    {numFilteredFolders}
                    {' '}
                    {numFilteredFolders > 1 ? 'Folders' : 'Folder'}
                  </span>
                ) : null}

                {(numFilteredEndpoints && numFilteredFolders && !loadDetails) ? ', ' : null}

                {(numFilteredEndpoints && !loadDetails) ? (
                  <span className={styles.modelTitle}>
                    {numFilteredEndpoints}
                    {' '}
                    {numFilteredEndpoints > 1 ? 'APIs' : 'API'}
                  </span>
                ) : null}
                {' '}
                will be scheduled for exporting to <span className={styles.modelTitle}>{typeData.to}</span>.
              </div><br/>
              Once export process is completed, you`ll receive an email with the
              link to download the <span className={styles.modelTitle}>{typeData.format}</span> file with all APIs.
            </div>
          </div>
         <div className={styles.buttonGroup}>
         {type !== 'swagger' ?  <div className={styles.buttonGroup}>
              <div className={styles.checkboxGroupTitle}>
                <strong>
                  Export linked:
                </strong>
              </div>
              {type !== 'postman' ? (
                <Checkbox
                  handleChange={setLinkConnections}
                  label={(
                    <div>
                      Connections
                    </div>
                  )}
                  size="small"
                  value={linkConnections}
                  style={{ marginRight: 14 }}
                />
              ) : null}
              <Checkbox
                handleChange={setLinkAuthKeys}
                label={(
                  <div>
                    Auth Keys
                  </div>
              )}
                size="small"
                value={linkAuthKeys}
                style={{ marginRight: 14 }}
              />

              <Checkbox
                handleChange={setLinkVariables}
                label={(
                  <div>
                    Variables
                  </div>
                )}
                size="small"
                value={linkVariables}
                style={{ marginRight: 14 }}
              />
            </div>
  : <div className={styles.buttonGroup} />}
            <div className={styles.buttonGroup}>
              <Button style={{ width: 85 }} title="Cancel" type="secondary" onClick={onClose} />
              <Button
                style={{ width: 160, marginLeft: 12 }}
                title="Export APIs"
                onClick={() => {
                  onSubmit({ folders: filteredFolders, endpoints: filteredEndpoints }, linkConnections, linkAuthKeys, linkVariables, type);
                  onClose();
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExportEndpointModal;

@use "sass:map";
@import '../../styles/variables.scss';

.navigation {
    width: 100%;
    background-color: transparent;

    a {
        text-decoration: none;
        color: map.get($colors-basic, "textBlack");
    }
}

.navItem {
    padding: 8px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;


    .icon {
        margin: auto 20px;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
            width: 16px;
            height: 16px;
        }
    }

    &.active {
        background-color: map.get($colors-basic, "selectedOption");
        color: map.get($colors-basic, "primaryBlue");
        position: relative;

        &:before {
            content:"";
            position: absolute;
            left: 0;
            bottom: 0;
            background: map.get($colors-basic, "primaryBlue");
            width: 2px;
            height: 100%;
            border-radius: 1px;
        }

        .icon {
            svg {
                * {
                    fill: map.get($colors-basic, "primaryBlue");
                }
            }
        }

        .icon.typeStroke {
            svg {
                * {
                    stroke: map.get($colors-basic, "primaryBlue");
                    fill: unset;
                }
            }
        }
    }

    &.disabled {
      color: map.get($colors-basic, "textGray");
      cursor: not-allowed;

        .icon.typeStroke {
            svg {
                * {
                    stroke: map.get($colors-basic, "textGray");
                    fill: unset;
                }
            }
        }
    
      svg {
        * {
          fill: map.get($colors-basic, "textGray");
        }
      }
    }
}

.roles {
    &.active {
        svg {
            * {
                fill: none;
                stroke: map.get($colors-basic, "primaryBlue");
            }
        }
    }
}

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import ProjectDefault from '../../pictures/project-default.svg';
import ActionIcon from '../ActionIcon/ActionIcon';
import * as style from './ProjectSelection.module.scss';

function ProjectSelection({ projects = [] }) {
  const { shortName } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location || {};
  const activeProject = projects.find((el) => el.shortName === shortName);

  const goProjectList = () => {
    navigate(`${shortName ? `/${shortName}/projects` : '/projects/create'}`, { state: { secure: true } });
  };

  const isCurrentActive = pathname.startsWith(`/${shortName}/projects`);

  return (
    <div className={style.projectSelection}>
      <div className={cn(style.group, {[style.openedList]: isCurrentActive })} onClick={() => goProjectList()}>
        <div className={style.iconGroup}>
          <div className={style.icon}>
            <ProjectDefault />
          </div>
          <div className={cn(style.titleGroup, { [style.centered]: !shortName })}>
            <div className={style.heading}>{shortName ? 'Selected Project' : ''}</div>
            <div className={style.title}>{activeProject?.name}</div>
            {!shortName && <div className={style.altTitle}>Create project</div>}
          </div>
        </div>
        <div className={style.actionGroup}>
          {isCurrentActive ? (
            null
          ) : (
            <ActionIcon
              icon="options"
              active={false}
            />
          )}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = ({ projects }) => ({
  projects: projects || [],
});

export default connect(mapStateToProps)(ProjectSelection);

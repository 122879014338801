import React from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import cn from 'classnames';
import * as style from './Navigation.module.scss';

import ConnectionsImg from '../../pictures/connection.svg';
import KeyImg from '../../pictures/key.svg';
import VarImg from '../../pictures/variable.svg';
import GraphImg from '../../pictures/graph.svg';
import UsersImg from '../../pictures/users.svg';
import RolesImg from '../../pictures/roles.svg';
import SettingsImg from '../../pictures/settings.svg';
import TrashImg from '../../pictures/trash.svg';

function Navigation({ currentUser }) {
  const navItems = [
    {
      key: 'endpoints',
      title: 'API',
      route: '',
      icon: GraphImg,
      active: true,
    },
    {
      key: 'connections',
      title: 'Connections',
      route: '',
      icon: ConnectionsImg,
      active: true,
    },
    {
      key: 'auth-keys',
      title: 'Keys',
      route: '',
      icon: KeyImg,
      active: true,
    },
    {
      key: 'variables',
      title: 'Variables',
      route: '',
      icon: VarImg,
      typeStroke: true,
      active: true,
    },
    {
      key: 'users',
      title: 'Users',
      route: '',
      icon: UsersImg,
      active: true,
    },
    {
      key: 'trash',
      title: 'Trash',
      route: '',
      icon: TrashImg,
      active: true,
    },
    {
      key: 'roles',
      title: 'Roles',
      route: '',
      icon: RolesImg,
      active: currentUser?.admin,
    },
    {
      key: 'settings',
      title: 'Settings',
      route: '',
      icon: SettingsImg,
      active: false,
    },
  ];

  const { shortName } = useParams();
  const activeNavItems = navItems.filter((n) => n.active);

  return (
    <nav className={style.navigation}>
      <ul>
        {activeNavItems.map(({
          key, title, icon: Icon, typeStroke
        }) => (
          <li key={key}>
            {shortName ? (
              <NavLink
                to={`${shortName}/${key}`}
                className={({ isActive }) => `${style.navItem} ${style[key]}${isActive ? ` ${style.active}` : ''}`}
              >
                <div className={cn(style.icon, { [style.typeStroke]: typeStroke })}>
                  <Icon />
                </div>
                {title}
              </NavLink>
            ) : (
              <div className={cn(style.navItem, style[key], style.disabled)}>
                <div className={cn(style.icon, { [style.typeStroke]: typeStroke })}>
                  <Icon />
                </div>
                {title}
              </div>
            )}
          </li>
        ))}
      </ul>
    </nav>
  );
}

const mapStateToProps = ({ auth: { currentUser } }) => ({
  currentUser,
});

export default connect(mapStateToProps)(Navigation);

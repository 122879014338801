/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-autofocus */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ThreeDots } from 'react-loader-spinner';
import Button from '../Button/Button';
import Checkbox from '../Checkbox/Checkbox';
import JSONImg from '../../pictures/export/json.svg';
import { getFolderPath, getDataPath, filterItemsByExclude, isSafari } from '../../helpers/utils';
import { getAuthKeyFolderTreeRequest } from '../../store/authKeys/actions';

import * as styles from './ExportAuthKeyModal.module.scss';

function ExportAuthKeyModal({
  onClose,
  onSubmit,
  model,
  collection,
}) {
  const dispatch = useDispatch();
  const { authKeys = [], folders = [] } = model || {};
  const [linkApi, setLinkApi] = useState(false);
  const [linkConnections, setLinkConnections] = useState(false);
  const [linkVariables, setLinkVariables] = useState(false);
  const [filteredFolders, setFilteredFolders] = useState([]);
  const [filteredAuthKeys, setFilteredAuthKeys] = useState([]);
  const [nestedFolders, setNestedFolders] = useState([]);
  const [nestedAuthKeys, setNestedAuthKeys] = useState([]);
  const [loadDetails, setLoadDetails] = useState(false);

  useEffect(() => {
    const calcPaths = () => {
      const f = folders.map((el) => ({ id: el, path: getFolderPath(collection, el) }));
      const c = authKeys.map((el) => ({ id: el, path: getDataPath(collection, el) }));

      const fltF = filterItemsByExclude(f, folders) || [];
      const fltC = filterItemsByExclude(c, folders) || [];

      setFilteredFolders(fltF.map((el) => el.id));
      setFilteredAuthKeys(fltC.map((el) => el.id));
    };

    calcPaths();
  }, [model, collection, folders, authKeys]);

  useEffect(() => {
    setLoadDetails(true);

    const fetchData = () => {
      if (!filteredFolders.length) {
        setLoadDetails(false);
        setNestedAuthKeys([]);
        setNestedFolders([]);
      } else {
        dispatch(getAuthKeyFolderTreeRequest({ ids: filteredFolders }, (result) => {
          setLoadDetails(false);
          if (result) {
            const f = result.filter((el) => el.folders).map((el) => el.folders).flat() || [];
            const c = result.filter((el) => el.authKeys).map((el) => el.authKeys).flat() || [];
            setNestedAuthKeys(c);
            setNestedFolders(f);
          } else {
            setNestedAuthKeys([]);
            setNestedFolders([]);
          }
        }));
      }
    };

    fetchData();
  }, [filteredFolders, dispatch]);

  const numFilteredFolders = nestedFolders.length || filteredFolders.length;
  const numFilteredAuthKeys = filteredAuthKeys.length + nestedAuthKeys.length;

  return (
    <div className={styles.modal}>
      <div className={styles.modalContent}>
        <div className={styles.header}>
          <div className={styles.title}><JSONImg /> Export Auth Keys</div>
        </div>
        <div className={styles.body}>
          <div className={styles.mainContent}>
            <div>
              <div>
              {loadDetails && !isSafari() && (
                  <span className={styles.modelTitle}
                  style={{ marginRight: '12px' }}
                  >
                  <ThreeDots
                    height="13"
                    width="13"
                    color="#4149F7"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{
                      display: 'inline',
                      transform: 'translateY(-20px)'
                    }}
                    visible
                  />
                  </span>
                )}
                {(numFilteredFolders && !loadDetails) ? (
                  <span className={styles.modelTitle}>
                    {numFilteredFolders}
                    {' '}
                    {numFilteredFolders > 1 ? 'Folders' : 'Folder'}
                  </span>
                ) : null}

                {(numFilteredAuthKeys && numFilteredFolders && !loadDetails) ? ', ' : null}

                {(numFilteredAuthKeys && !loadDetails) ? (
                  <span className={styles.modelTitle}>
                    {numFilteredAuthKeys}
                    {' '}
                    {numFilteredAuthKeys > 1 ? 'Auth Keys' : 'Auth Key'}
                  </span>
                ) : null}
                {' '}
                will be scheduled for exporting.
              </div>
              <br />
              Once exporting process is completed, you`ll receive an email
              with the link to export the <span className={styles.modelTitle}>.json</span> file with all AuthKeys.
            </div>
          </div>
          <div className={styles.buttonGroup}>
            <div className={styles.buttonGroup}>
                <div className={styles.checkboxGroupTitle}>
                    <strong>
                      Export linked:
                    </strong>
                </div>
                <Checkbox
                  handleChange={(v) => {
                    setLinkApi(v);
                    if (!v) setLinkConnections(false);
                  }}
                  label={(
                    <div>
                      APIs
                    </div>
                  )}
                  size="small"
                  value={linkApi}
                  style={{ marginRight: 14 }}
                />

              <Checkbox
                handleChange={setLinkConnections}
                label={(
                  <div>
                    Connections
                  </div>
              )}
                size="small"
                disabled={!linkApi}
                value={linkConnections}
                style={{ marginRight: 14 }}
              />

            </div>
            <div className={styles.buttonGroup}>
              <Button style={{ width: 85 }} title="Cancel" type="secondary" onClick={onClose} />
              <Button
                style={{ width: 200, marginLeft: 12 }}
                title="Export Auth Keys"
                onClick={() => {
                  onSubmit({ folders: filteredFolders, authKeys: filteredAuthKeys }, linkApi, linkConnections, linkVariables);
                  onClose();
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExportAuthKeyModal;

import React from 'react';
import cn from 'classnames';
import { useDispatch } from 'react-redux';

import ActionIcon from '../ActionIcon/ActionIcon';
import ChipsStatus from '../ChipsStatus/ChipsStatus';
import DBIcon from '../DBIcon/DBIcon';
import { createToastRequest } from '../../store/toasts/actions';
import { shortenText, convertOldNames, formatTimestamp } from '../../helpers/utils';
import { backendHost } from '../../helpers/const';
import { useCopyToClipboard } from '../../hooks/hooks';
import { isRoleAction } from '../../helpers/hocs';

import SortIcon from '../../pictures/sort.svg';

import 'react-tooltip/dist/react-tooltip.css';

import * as shared from '../../styles/shared.module.scss';
import { useVariables } from '../../hooks/useMonaco';
import { transformValueWithVariables } from '../../helpers/tokenizeAutocompleteUtils';

const headers = {
  id: { title: 'ID', width: '8%' },
  name: { title: 'Name', width: '18%' },
  method: { title: 'Method', width: '15%' },
  endpoint: { title: 'Endpoint', width: '15%' },
  connection: { title: 'Connection', width: '17%' },
  status: { title: 'Status', width: '12%' },
  created: { title: 'Created', width: '11%' },
  action: { title: '', width: '0%' },
};

function ConnectedAPI({ activeProject, createToast, list, activeConnection, hideWithoutList = false }) {
  const { variables } = useVariables();
  const { name } = activeConnection || {};
  const dispatch = useDispatch();
  const successToast = () => dispatch(createToastRequest({ type: 'success', text: 'Copied' }));
  const [copiedText, copyText] = useCopyToClipboard();
  
  const openNewTabWithNoreferrer = (model) => {
    const { id } = model || {};
    const newTabUrl = `${window.location.origin}/${activeProject.shortName}/endpoints/${id}`;

    const newTab = window.open(newTabUrl, '_blank', 'noopener,noreferrer');
    
    if (newTab) {
      newTab.focus();
    }
  }

  const itemsExist = list && list.length;
  
  return (
    <div style={{ 
      height: '0px', 
      display: 'flex', 
      flexDirection: 'column',
      overflow: itemsExist ? 'visible' : 'hidden'
    }}>
      <div className={cn(shared.header, shared.borderTop)} style={{ padding: 20 }}>
        <div className={shared.headerTitleGroup} style={{ gap: 8 }}>
          <h1 className={shared.headerSubTitle}>Connected API</h1>
          {list ? <span className={shared.headerSubTitleCounter}>{`(${list.length})`}</span> : null}
        </div>
      </div>
      {!itemsExist && hideWithoutList ? null : (
      <div className={shared.table}>
        <table className={shared.apiTable} style={{ height: 320, overflow: 'hidden' }}>
          <thead>
            <tr>
              {Object.keys(headers).map(
                (h, i, arr) =>
                    <th key={h} width={headers[h].width}>
                        <span>{headers[h].title}</span> {i < arr.length - 1 && <SortIcon />}
                    </th>
              )}
            </tr>
          </thead>
          {list?.length ? (
            <tbody style={{ height: 320 }}>
              {list.map((model, index) => (
                <tr
                  key={`${index}_${model.id}`}
                  role="button"
                  tabIndex={0}
                  onClick={() => openNewTabWithNoreferrer(model)}
                >
                  <td width={headers.id.width}>
                    <span>{shortenText(model.id)}</span>
                  </td>
                  <td width={headers.name.width}>
                    <span>{model.name}</span>
                    {model.description && (
                      <div className={shared.controlIcons}>
                        <ActionIcon
                          id={`${index}_${model.id}_tooltip`}
                          icon="info"
                          style={{ marginRight: '8px' }}
                          onClick={() => {}}
                          tooltip={{
                            content: model.description,
                          }}
                        />
                      </div>
                    )}
                  </td>
                  <td width={headers.method.width}>
                    <span className={shared.uppercased}>{model.method}</span>
                  </td>
                  <td width={headers.endpoint.width}>
                    <span>{transformValueWithVariables(model.path, variables.data, 'value', true)}</span>
                    <div className={shared.controlIcons}>
                      <ActionIcon
                        icon="copy"
                        style={{ marginRight: '8px' }}
                        onClick={(e) => {
                          e.stopPropagation();
                          copyText(`${backendHost}/${activeProject.shortName}${transformValueWithVariables(model.path, variables.data, 'value', true) }`);
                          successToast();
                        }}
                      />
                    </div>
                  </td>
                  <td width={headers.connection.width}>
                    <DBIcon
                      type={convertOldNames(model.connectionType)}
                      style={{ marginRight: '12px', minWidth: '32px' }}
                    />
                    <span>{name}</span>
                  </td>
                  <td width={headers.status.width}>
                    <ChipsStatus title="Active" type="success" />
                  </td>
                  <td width={headers.created.width}>
                     {model.createdAt ? formatTimestamp(model.createdAt) : ''}
                  </td>
                  <td width={headers.action.width} />
                </tr>
              ))}
            </tbody>
          ) : null}
        </table>
      </div>
      )}
    </div>
  );
}

export default isRoleAction(ConnectedAPI);

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { animatedScrollToBottom } from '../../helpers/utils';
import Arrow from '../../pictures/icon-arrow.svg';
import Setings from '../../pictures/settings-02.svg';

import * as styles from './Collapsible.module.scss';

// style default | withIcon

function Collapsible({ title, children, id, type, iconLink, onClick: onClickProp }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const expandedContentRef = useRef(null);
  const arrowRef = useRef(null);
  const labelRef = useRef(null);

  const handleToggle = (event) => {
    if (onClickProp) onClickProp(event);
    if (type === 'withIcon' && (arrowRef.current.contains(event.target) || labelRef.current.contains(event.target))) {
      setIsExpanded((prevExpanded) => !prevExpanded);
    } else if (type !== 'withIcon') {
      setIsExpanded((prevExpanded) => !prevExpanded);
    }
  };

  const handleClick = (event) => {
    if (type === 'withIcon') {
      handleToggle(event);
    }
    return null;
  };

  useEffect(() => {
    if (isExpanded) {
      const container = document.getElementById("sidepanelFormId");
      if (container) animatedScrollToBottom(container, 200);
    }
  }, [isExpanded]);

  const openLink = (link) => {
    const newTabUrl = `${window.location.origin}/${link}`;

    const newTab = window.open(newTabUrl, '_blank', 'noopener,noreferrer');

    if (newTab) {
      newTab.focus();
    }
  };

  return (
    <div className={styles.wrapCollabsible}>
      <input
        id={id}
        className={styles.toggle}
        type="checkbox"
        checked={isExpanded}
        onChange={handleToggle}
      />
      <label 
        ref={labelRef }
        htmlFor={id} className={cn(styles.lblToggle, {
        [styles.lblWithIcon]: type === 'withIcon',
      })}>
        <span ref={labelRef } onClick={handleClick}>{title}</span>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '12px' }}>
        {type === 'withIcon' ? <div className={styles.hoverIcon} onClick={() => openLink(iconLink)}><Setings /></div> : null}
          <div 
            className={styles.arrow} 
            ref={arrowRef} 
            onClick={handleClick}
            role='button'
          >
            <Arrow />
          </div>
        </div>
      </label>
      <div
        className={cn(styles.collapsibleContent)}
      >
        <div ref={expandedContentRef} 
        className={cn(styles.contentInner, {
          [styles.withIconContent]: type === 'withIcon',
        })}
        >
          {children}
        </div>
      </div>
    </div>
  );
}

Collapsible.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  id: PropTypes.string,
  iconLink: PropTypes.string,
  onClick: PropTypes.func,
};

export default Collapsible;

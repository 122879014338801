/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-autofocus */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ThreeDots } from 'react-loader-spinner';
import Button from '../Button/Button';
import { getFolderPath, getDataPath, filterItemsByExclude, isSafari } from '../../helpers/utils';
import { getConnectionFolderTreeRequest } from '../../store/connections/actions';
import * as styles from './ExportConnectionModal.module.scss';
import Checkbox from '../Checkbox/Checkbox';

function ExportConnectionModal({
  onClose,
  onSubmit,
  model,
  collection,
}) {
  const dispatch = useDispatch();
  const { connections = [], folders = [] } = model || {};
  const [linkApi, setLinkApi] = useState(false);
  const [linkVariables, setLinkVariables] = useState(false);
  const [linkAuthKeys, setLinkAuthKeys] = useState(false);
  const [filteredFolders, setFilteredFolders] = useState([]);
  const [filteredConnections, setFilteredConnections] = useState([]);
  const [nestedFolders, setNestedFolders] = useState([]);
  const [nestedConnections, setNestedConnections] = useState([]);
  const [loadDetails, setLoadDetails] = useState(false);

  useEffect(() => {
    const calcPaths = () => {
      const f = folders.map((el) => ({ id: el, path: getFolderPath(collection, el) }));
      const c = connections.map((el) => ({ id: el, path: getDataPath(collection, el) }));

      const fltF = filterItemsByExclude(f, folders) || [];
      const fltC = filterItemsByExclude(c, folders) || [];

      setFilteredFolders(fltF.map((el) => el.id));
      setFilteredConnections(fltC.map((el) => el.id));
    };

    calcPaths();
  }, [model, collection, folders, connections]);

  useEffect(() => {
    setLoadDetails(true);

    const fetchData = () => {
      if (!filteredFolders.length) {
        setLoadDetails(false);
        setNestedConnections([]);
        setNestedFolders([]);
      } else {
        dispatch(getConnectionFolderTreeRequest({ ids: filteredFolders }, (result) => {
          setLoadDetails(false);
          if (result) {
            const f = result.filter((el) => el.folders).map((el) => el.folders).flat() || [];
            const c = result.filter((el) => el.connections).map((el) => el.connections).flat() || [];
            setNestedConnections(c);
            setNestedFolders(f);
          } else {
            setNestedConnections([]);
            setNestedFolders([]);
          }
        }));
      }
    };

    fetchData();
  }, [filteredFolders, dispatch]);

  const numFilteredFolders = nestedFolders.length || filteredFolders.length;
  const numFilteredConnections = filteredConnections.length + nestedConnections.length;

  return (
    <div className={styles.modal}>
      <div className={styles.modalContent}>
        <div className={styles.header}>
          <div className={styles.title}>Export Connections</div>
        </div>
        <div className={styles.body}>
          <div className={styles.mainContent}>
            <div>
              <div>
              {loadDetails && !isSafari() && (
                  <span className={styles.modelTitle}
                  style={{ marginRight: '12px' }}
                  >
                  <ThreeDots
                    height="13"
                    width="13"
                    color="#4149F7"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{
                      display: 'inline',
                      transform: 'translateY(-20px)'
                    }}
                    visible
                  />
                  </span>
                )}
                {(numFilteredFolders && !loadDetails) ? (
                  <span className={styles.modelTitle}>
                    {numFilteredFolders}
                    {' '}
                    {numFilteredFolders > 1 ? 'Folders' : 'Folder'}
                  </span>
                ) : null}

                {(numFilteredConnections && numFilteredFolders && !loadDetails) ? ', ' : null}

                {(numFilteredConnections && !loadDetails) ? (
                  <span className={styles.modelTitle}>
                    {numFilteredConnections}
                    {' '}
                    {numFilteredConnections > 1 ? 'Connections' : 'Connection'}
                  </span>
                ) : null}
                {' '}
                will be scheduled for exporting.
              </div>
              <br />
              Once exporting process is completed, you`ll receive an email
              with the link to export the <span className={styles.modelTitle}>.json</span> file with all Connections.
            </div>
          </div>
          <div className={styles.buttonGroup}>
            <div className={styles.buttonGroup}>
                <div className={styles.checkboxGroupTitle}>
                    <strong>
                      Export linked:
                    </strong>
                </div>
              <Checkbox
                handleChange={(v) => {
                  setLinkApi(v);
                  if (!v) setLinkAuthKeys(false);
                }}
                label={(
                  <div>
                    APIs
                  </div>
                )}
                size="small"
                value={linkApi}
                style={{ marginRight: 14 }}
              />
              <Checkbox
                handleChange={setLinkAuthKeys}
                label={(
                  <div>
                    Auth Keys
                  </div>
                )}
                size="small"
                disabled={!linkApi}
                value={linkAuthKeys}
                style={{ marginRight: 14 }}
              />

              <Checkbox
                handleChange={setLinkVariables}
                label={(
                  <div>
                    Variables
                  </div>
                )}
                size="small"
                value={linkVariables}
                style={{ marginRight: 14 }}
              />
            </div>
            <div className={styles.buttonGroup}>
              <Button style={{ width: 85 }} title="Cancel" type="secondary" onClick={onClose} />
              <Button
                style={{ width: 200, marginLeft: 12 }}
                title="Export Connections"
                onClick={() => {
                  onSubmit({ folders: filteredFolders, connections: filteredConnections }, linkApi, linkAuthKeys, linkVariables);
                  onClose();
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExportConnectionModal;

import { call, put, takeEvery, all, fork, select } from 'redux-saga/effects';
import { api } from '../../helpers/api';
import {
  searchProjectsSuccess,
  searchProjectsRequest,
  updateSearchProjectRequest,
  updateSearchProjectSuccess,
} from './actions';
import { createToastRequest } from '../toasts/actions';
import { refreshRequest } from '../auth/actions';
import { SearchProjects } from './constants';
import history from '../../history';

const getAuth = (state) => state.auth?.auth;

export function* searchProjects(ops) {
  const authData = yield select(getAuth);
  const { data, callback } = ops;
  const { query } = data || {};

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(
        api,
        `/projects/search?deleted=false${query ? `&query=${query}` : ''}`,
        'GET',
        null,
        authData.access_token,
      );
      if (response.ok) {
        const parsed = yield response.json();
        yield put(searchProjectsSuccess(parsed.projects));
        if (callback) callback(parsed.projects);
      } else if (response.status === 401) {
        yield put(refreshRequest(searchProjectsRequest, data, callback));
      } else {
        const parsed = yield response.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
      }
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error [searchProjects]' }));
  }
}

export function* updateSearchProjects(ops) {
  const { data, callback } = ops;
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(api, '/projects', 'PUT', data, authData.access_token);
      if (response.ok) {
        const parsed = yield response.json();
        yield put(updateSearchProjectSuccess(parsed));
        yield put(
          createToastRequest({
            type: 'success',
            title: data.name,
            text: 'project was updated',
          }),
        );
        if (callback) callback();
      } else if (response.status === 401) {
        yield put(refreshRequest(updateSearchProjectRequest, data, callback));
      } else {
        const parsed = yield response.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
      }
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error [updateSearchProjects]' }));
  }
}

function* projectsSaga() {
  yield takeEvery(SearchProjects.searchProjectsRequest, searchProjects);
  yield takeEvery(SearchProjects.updateSearchProjectRequest, updateSearchProjects);
}

function* configSaga() {
  yield all([fork(projectsSaga)]);
}

export default configSaga;

/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { loadStatus } from '../../helpers/const';
import Loader from '../../components/Loader';
import ActionText from '../../components/ActionText/ActionText';
import IconAlert from '../../pictures/icon-alert.svg';
import DatabaseSchemeComponent from '../../components/DatabaseSchemeComponent/DatabaseSchemeComponent';
import {
  databaseSchemeRequest
} from '../../store/databaseScheme/actions';

import * as styles from './DatabaseSchemeLayout.module.scss';

function DatabaseSchemeLayout({ connectionId, databaseScheme, getDatabaseScheme, activeProject }) {
  const { shortName } = activeProject || {};
  const { data } = databaseScheme || {};
  const { tables } = data || {};
  const [loading, setLoading] = useState(loadStatus.INITIAL);
  const [expandedObject, setExpandedObject] = useState({});

  const openConnection = () => {
    const url = `${window.location.origin}/${shortName}/connections/${connectionId}`;
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const setExpandStatus = (id, status) => {
    setExpandedObject(prev => ({
     ...prev,
     [id]: status 
    }));
  };

  useEffect(() => {
    if (connectionId) {
      setLoading(loadStatus.LOAD);

      getDatabaseScheme({ connectionId }, (status, responseConnectionId) => {
        setLoading(prevLoading => {
          if (status === 'ERROR' && prevLoading === loadStatus.LOAD && connectionId === responseConnectionId) {
            return loadStatus.ERROR;
          }
          if (status !== 'ERROR' && prevLoading === loadStatus.LOAD && connectionId === responseConnectionId) {
            return loadStatus.SUCCESS;
          }
          return prevLoading;
        });
      })
    }
  }, [connectionId]);

  useEffect(() => {
    setExpandedObject({});
  }, [tables]);

  return (
    <div className={styles.layout}>
      {loading === loadStatus.SUCCESS && tables?.length ? (
        tables.map(t => (
          <DatabaseSchemeComponent 
            key={t.name} 
            data={t} 
            setExpandStatus={setExpandStatus}
            expanded={!!expandedObject[t.name]}
          />
        ))
      ) : null}
      {loading === loadStatus.LOAD ? (
          <div className={styles.loaderWrapper}>
            <Loader color="black" />
            <span>Loading...</span>
          </div>
      ) : null}
      {loading === loadStatus.ERROR ? (
        <div className={styles.errorWrapper}>
          <div className={styles.errorIcon}><IconAlert /></div>
          <span>Failed to load the database structure</span>
          <ActionText
            title="Check Connection settings"
            onClick={openConnection}
          />
        </div>
      ) : null}
    </div>
  );
}

DatabaseSchemeLayout.propTypes = {
  connectionId: PropTypes.number,
  databaseScheme: PropTypes.object,
  getDatabaseScheme: PropTypes.func.isRequired
};

const mapStateToProps = ({
  databaseScheme,
}) => ({
  databaseScheme
});

const mapDispatchToProps = (dispatch) => ({
  getDatabaseScheme: (data, callback) => dispatch(databaseSchemeRequest(data, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DatabaseSchemeLayout)

/* eslint-disable react/jsx-no-useless-fragment */
import React, { useRef, useEffect } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import cn from 'classnames';
import ActionIcon from '../ActionIcon';
import Input from '../Input/Input';
import Select from '../Select/Select';
import CodeEditor from '../CodeEditor';

import * as shared from '../../styles/shared.module.scss';
import { ViewModes } from '../CodeEditor/codeEditorConstants';

const mainList = [
  { value: 'body', title: 'Body' },
  { value: 'header', title: 'Header' },
  { value: 'query', title: 'Query' },
  { value: 'path', title: 'Path' },
];
const shortList = [
  { value: 'header', title: 'Header' },
  { value: 'query', title: 'Query' },
  { value: 'path', title: 'Path' },
];

export function ParamentRow({
  id,
  param,
  index,
  moveParam,
  handleChangeProxy,
  setFieldValueProxy,
  removeParameter,
  errors,
  parameterType,
  typesList,
  viewRead = false,
  activeMethod
}) {
  const ref = useRef(null);

  const allowDragRef = React.useRef(false);

  const [{ handlerId }, drop] = useDrop({
    accept: 'card',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      moveParam(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: 'card',
    item: () => ({ id, index }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: () => allowDragRef.current,
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  const handleClick = () => {
    allowDragRef.current = false;
  }

  useEffect(() => {
    document.addEventListener('dragend', handleClick);
  
    return () => {
      document.removeEventListener('dragend', handleClick);
    };
  }, []);

  return (
    <div
      className={cn(shared.sectionContainer, shared.triple)}
      style={{ opacity, zIndex: 2}}
      ref={ref}
    >
      <div className={shared.inputWrapper}>
        <Select
          list={activeMethod === 'get' ? shortList : mainList}
          value={param.source}
          handleChange={setFieldValueProxy}
          name={`source.${param.id}`}
          id={`params.${index}.source`}
          placeholder="Select"
          type="top"
          error={errors?.params && errors.params[index]?.source}
          disabled={viewRead}
        />
      </div>
      <div className={shared.inputWrapper}>
        <Input
          placeholder="Name"
          handleChange={handleChangeProxy}
          value={param.name}
          name={`name.${param.id}`}
          id={`params.${index}.name`}
          error={errors?.params && errors.params[index]?.name}
          disabled={viewRead}
        />
      </div>
      <div className={shared.inputWrapper}>
        {parameterType === 'desription' ? (
          <Input
            placeholder="Description"
            handleChange={handleChangeProxy}
            value={param.description}
            name={`description.${param.id}`}
            disabled={viewRead}
          />
        ) : (
          <>
            {['object', 'array'].includes(param.type) ? (
              <div className={cn(shared.codeEditorWrap, shared.codeEditorWrapInline)}>
                <CodeEditor
                  value={param.defaultValue}
                  handleChange={
                    (value) => {
                      handleChangeProxy({ target: { 
                        value, 
                        name: `defaultValue.${param.id}`
                      } });
                    }
                  }
                  name={`defaultValue.${param.id}`}
                  id={`params.${index}.defaultValue`}
                  type={/\{\{env\..*?\}\}/g.test(param.defaultValue) ? 'string' : 'json'}
                  placeholder="Default value"
                  disabled={viewRead}
                  error={errors?.params && errors.params[index]?.defaultValue}
                  viewMode={ViewModes.Inline}
                />
              </div>
            ) : (
              <Input
                placeholder="Default value"
                handleChange={(e) => {
                  handleChangeProxy({
                    target: {
                      value: e.target.value,
                      name: `defaultValue.${param.id}`,
                    },
                  });
                }}
                value={param.defaultValue}
                name={`defaultValue.${param.id}`}
                id={`params.${index}.defaultValue`}
                type="text"
                disabled={viewRead}
                error={errors?.params && errors.params[index]?.defaultValue}
                withVariables
              />
            )}
          </>
        )}
      </div>
      <div className={shared.inputWrapper}>
        <Select
          list={typesList}
          value={param.type}
          handleChange={setFieldValueProxy}
          name={`type.${param.id}`}
          id={`params.${index}.type`}
          placeholder="Select"
          type="top"
          error={errors?.params && errors.params[index]?.type}
          disabled={viewRead || param.source !== 'body'}
        />
      </div>
      <div className={shared.inputWrapper}>
        <Select
          list={[
            { value: 'yes', title: 'Yes' },
            { value: 'no', title: 'No' },
          ]}
          value={param.required}
          handleChange={setFieldValueProxy}
          name={`required.${param.id}`}
          id={`params.${index}.required`}
          placeholder="Select"
          type="top"
          error={errors?.params && errors.params[index]?.required}
          disabled={viewRead || param.source === 'path'}
        />
      </div>
      <div className={cn(shared.inputWrapper, shared.btn)}>
        <div
          role="button"
          tabIndex={0}
          data-handler-id={handlerId}
          onMouseDown={() => {
            allowDragRef.current = true;
          }}
        >
          <ActionIcon
            icon="list"
            style={{ marginRight: '16px', cursor: 'move' }}
          />
        </div>
        {!viewRead && (
          <ActionIcon
            icon="trash"
            onClick={() => removeParameter(param)}
          />
        )}
      </div>
    </div>
  );
}

import React, { useState, useRef, useEffect, useCallback, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import useSWR from 'swr';
import { logoutRequest, meRequest } from '../../store/auth/actions';
import ActionIcon from '../ActionIcon/ActionIcon';
import Logout from '../../pictures/logout.svg';
import avatar from '../../pictures/default-avatar.webp';
import * as style from './UserSelection.module.scss';
import { fetchAppVersions } from '../../api/fetchAppVersions';
import Loader from '../Loader';

const items = [
  {
    key: 'logout',
    title: 'Logout',
    icon: Logout,
  },
];

function PopUpMenu({ handleChange, handleClickMenu }) {
  const ref = useRef(null);

  const handleClickOutside = (event) => {
    if (ref && ref.current && !ref.current.contains(event.target)) {
      handleChange(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return (
    <div className={style.popupMenu} ref={ref}>
      <ul>
        {items.map(({ key, title, icon: Icon }) => (
          <li key={key} role="button" onClick={() => handleClickMenu(key)}>
            <div className={style.icon}>
              <Icon />
            </div>
            {title}
          </li>
        ))}
      </ul>
    </div>
  );
}

PopUpMenu.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleClickMenu: PropTypes.func.isRequired,
};

function UserSelection() {
  const { data, isLoading } = useSWR('/app-versions', fetchAppVersions, { dedupingInterval: 60 * 60 * 1000 });
  const [menuOpened, setMenuOpened] = useState(false);
  const currentUser = useSelector((state) => state.auth.currentUser);

  const { firstName = '', lastName = '', email = '' } = currentUser || {};
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!currentUser) {
      dispatch(meRequest());
    }
  }, [currentUser, dispatch]);

  const menuClickAction = useCallback(
    (item) => {
      if (item === 'logout') {
        setMenuOpened(false);
        dispatch(logoutRequest(() => navigate('/login')));
      }
    },
    [dispatch, navigate],
  );

  return (
    <div className={style.projectSelection}>
      <div className={style.group}>
        <div className={style.iconGroup}>
          <div className={style.icon}>
            <img className={style.logo} src={avatar} alt="users avatar" />
          </div>
          <div className={style.titleGroup}>
            <div className={style.heading}>{email}</div>
            <div className={style.title}>{`${firstName} ${lastName}`}</div>
          </div>
        </div>
        <div className={style.actionGroup}>
          <ActionIcon icon="options" onClick={() => setMenuOpened(!menuOpened)} active={menuOpened} />
        </div>
      </div>
      {menuOpened && <PopUpMenu handleChange={setMenuOpened} handleClickMenu={menuClickAction} />}
      <div className={style.bottom}>
        <div className={style.version}>
          {isLoading && <Loader size="small" />}
          {data && <>v{data?.versions?.backend}</>}
        </div>
        <div className={style.sendFeedback} />
      </div>
    </div>
  );
}

export default memo(UserSelection);

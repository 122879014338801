import {
  call, put, takeEvery, all, fork, select,
} from 'redux-saga/effects';
import { api } from '../../helpers/api';
import {
  listProjectsRequest, listProjectsSuccess,
  createProjectRequest, createProjectSuccess,
  removeProjectRequest, removeProjectSuccess,
} from './actions';
import { createToastRequest } from '../toasts/actions';
import { activeProjectSuccess } from '../activeProject/actions';
import {
  refreshRequest,
} from '../auth/actions';
import { Projects } from './constants';
import history from '../../history';

const getAuth = (state) => state.auth?.auth;

export function* fetchProjects(ops) {
  const authData = yield select(getAuth);
  const { data, callback } = ops;
  const { shortName: activateProject } = data || {};

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(api, '/projects/search/deleted=false', 'GET', null, authData.access_token);
      if (response.ok) {
        const parsed = yield response.json();
        yield put(listProjectsSuccess(parsed.projects));
        if (callback) callback(parsed.projects);
        if (activateProject && parsed?.projects?.length) {
          const activeProject = parsed.projects.find((el) => el.shortName === activateProject);
          yield put(activeProjectSuccess(activeProject));
        }
      } else if (response.status === 401) {
        yield put(refreshRequest(listProjectsRequest, data, callback));
      } else {
        const parsed = yield response.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
      }
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error [fetchProjects]' }));
  }
}

export function* createProject(ops) {
  const { data, callback } = ops;
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(api, '/projects', 'POST', data, authData.access_token);
      if (response.ok) {
        const parsed = yield response.json();
        yield put(createProjectSuccess(parsed));
        yield put(createToastRequest({ type: 'success', title: parsed.name, text: 'project has been created sucessfully' }));
        if (callback) callback(parsed.shortName);
      } else if (response.status === 401) {
        yield put(refreshRequest(createProjectRequest, data, callback));
      } else {
        const parsed = yield response.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
      }
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* removeProject(ops) {
  const { data, callback } = ops;
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(api, `/projects/${data.id}`, 'DELETE', null, authData.access_token);
      if (response.ok) {
        yield put(removeProjectSuccess(data.id));
        yield put(createToastRequest({ type: 'success', title: data.id, text: 'project has been removed sucessfully' }));
        if (callback) callback(true);
      } else if (response.status === 401) {
        yield put(refreshRequest(removeProjectRequest, data, callback));
      } else {
        const parsed = yield response.json();
        if (callback) callback(false);
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
      }
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

function* projectsSaga() {
  yield takeEvery(Projects.listProjectsRequest, fetchProjects);
  yield takeEvery(Projects.createProjectRequest, createProject);
  yield takeEvery(Projects.removeProjectRequest, removeProject);
}

function* configSaga() {
  yield all([fork(projectsSaga)]);
}

export default configSaga;

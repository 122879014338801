/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import Options from '../../pictures/options.svg';
import Import from '../../pictures/import.svg';
import Close from '../../pictures/close.svg';
import Trash from '../../pictures/trash.svg';
import AddUser from '../../pictures/add-user.svg';
import Edit from '../../pictures/edit.svg';
import Expand from '../../pictures/icon-expand.svg';
import Copy from '../../pictures/copy.svg';
import Info from '../../pictures/info.svg';
import Export from '../../pictures/export.svg';
import List from '../../pictures/list.svg';
import Speedometer from '../../pictures/speedometer.svg';
import OpenArrow from '../../pictures/open-arrow.svg';
import Settings from '../../pictures/settings.svg';
import * as styles from './ActionIcon.module.scss';

const iconEnum = {
  settings: Settings,
  options: Options,
  close: Close,
  trash: Trash,
  addUser: AddUser,
  edit: Edit,
  expand: Expand,
  copy: Copy,
  info: Info,
  export: Export,
  list: List,
  openArrow: OpenArrow,
  speedometer: Speedometer,
  import: Import
};

function ActionIcon({
  id,
  onClick,
  icon,
  active,
  hoverDisabled,
  small,
  large,
  hidden,
  style = {},
  hiddenFill,
  tooltip = null,
}) {
  const Icon = icon && iconEnum[icon];
  const isClickable = tooltip && tooltip.clickable;
  if (hidden) {
    return null;
  }

  return (
    <button
      id={id}
      className={cn(
        styles.actionIcon,
        {
          [styles.active]: active,
          [styles.hoverDisabled]: hoverDisabled,
          [styles.small]: small,
          [styles.large]: large,
          [styles.hiddenFill]: hiddenFill,
          [styles.speedometer]: icon === 'speedometer'
        },
      )}
      onClick={onClick}
      type="button"
      style={style}
    >
      {icon && (
      <Icon />
      )}
      {tooltip && (
      <ReactTooltip
        className={styles.tooltipStyled}
        anchorId={id}
        data-for={isClickable ? id : undefined}
        data-event={isClickable ? 'click' : undefined}
        place="bottom"
        variant="info"
        style={{
          backgroundColor: '#4149f7',
          color: '#fff',
          maxWidth: '350px',
          zIndex: '9999',
        }}
        {...tooltip}
      />
      )}
    </button>
  );
}

ActionIcon.propTypes = {
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
  active: PropTypes.bool,
  style: PropTypes.object,
  hoverDisabled: PropTypes.bool,
  small: PropTypes.bool,
  large: PropTypes.bool,
};

export default ActionIcon;

import { ActiveEndpoint } from './constants';

const initialState = {
  original: null,
  activeEndpoint: null,
  inviteList: [],
  authKeysList: [],
  authKeysLimits: {},
};

export const activeEndpointReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case ActiveEndpoint.activeEndpointSuccess:
      return {
        ...state,
        activeEndpoint: action.data,
        original: action.data,
      };
    case ActiveEndpoint.getAccessUsersEndpointSuccess:
      return {
        ...state,
        inviteList: action.data,
      };
    case ActiveEndpoint.getEndpointConnectedAuthKeysSuccess:
      return {
        ...state,
        authKeysList: action.data,
      };
    case ActiveEndpoint.getEndpointConnectedAuthKeysLimitSuccess:
      return {
        ...state,
        authKeysLimits: action.data,
      };
    case ActiveEndpoint.activeDisplayVersion:
      return {
        ...state,
        activeEndpoint: action.data,
      };
    case ActiveEndpoint.activateOriginVersion:
      return {
        ...state,
        activeEndpoint: state.original,
      };
    default:
      return state;
  }
};

import React, { useState } from 'react';
import cn from 'classnames';
import { connect } from 'react-redux';
import debounce from 'lodash.debounce';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ValidationErrorList from '../../components/ValidationErrorList/ValidationErrorList';
import Button from '../../components/Button/Button';
import Input from '../../components/Input/Input';
import Select from '../../components/Select/Select';
import { listRolesName } from '../../helpers/const';

import { sendInviteRequest } from '../../store/users/actions';

import * as shared from '../../styles/shared.module.scss';

const subrouteMapping = {};

const Schema = Yup.object().shape({
  email: Yup.string()
    .email('Use valid email address')
    .required('Email required'),
  role: Yup.string().required('Role required'),
});

function UsersCreate({ sendInvite, roles, activeProject }) {
  const [, setSubroute] = useState('');
  const [submitTriggered, setSubmitTriggered] = useState(false);
  const navigate = useNavigate();
  const { shortName } = useParams();
  const listUsers = () => navigate(`/${shortName}/users`);

  const {
    submitForm, handleChange: handleChange2, setFieldValue: setFieldValue2, values, errors, validateForm
  } = useFormik({
    initialValues: {
      email: '',
      role: 'viewer',
    },
    validationSchema: Schema,
    onSubmit: (v) => {
      sendInvite(
        {
          email: v.email,
          role: v.role,
          id: activeProject.id,
          resource: 'project',
        },
        () => navigate(`/${shortName}/users`),
      );
    },
  });

  const debouncedValidateForm = debounce(() => {
    validateForm();
  }, 100);

  const setFieldValue = (...args) => {
    setFieldValue2(...args);
    if (submitTriggered) {
      debouncedValidateForm();
    }
  }

  const handleChange = (...args) => {
    handleChange2(...args);
    if (submitTriggered) {
      debouncedValidateForm();
    }
  }

  const handleSubmitWithCallback = (e) => {
    e.preventDefault();
    setSubmitTriggered(true);
    submitForm(e);
  };

  return (
    <div className={shared.page}>
      <div className={shared.header}>
        <div className={shared.headerTitleGroup}>
          <h1 className={shared.headerTitle}>Add user</h1>
        </div>
        <div className={shared.headerButtonGroup}>
          <ValidationErrorList 
            errors={errors} 
            subrouteMapping={subrouteMapping}
            setSubroute={setSubroute}
          />
          <Button title="Cancel" type="secondary" onClick={listUsers} />
          <Button title="Add user" onClick={handleSubmitWithCallback} />
        </div>
      </div>
      <div className={cn(shared.body, shared.vertical)}>
        <div className={cn(shared.mainContent, shared.mainContentUsers)}>
          <section className={shared.section}>
            <h4 className={shared.sectionHeader}>User details</h4>
            <div className={shared.sectionContainer}>
              <div className={shared.inputWrapper}>
                <span className={shared.inputLabel}>Email</span>
                <Input
                  placeholder="Enter email"
                  name="email"
                  id="email"
                  value={values.email}
                  handleChange={handleChange}
                  error={!!errors.email}
                />
              </div>
              <div className={shared.inputWrapper}>
                <span className={shared.inputLabel}>Role</span>
                <Select
                  list={roles.filter(r => r.name !== 'owner').map(r => ({ value: r.name, title: listRolesName[r.name] || r.name }))}
                  name="role"
                  id="role"
                  value={values.role}
                  handleChange={setFieldValue}
                  error={!!errors.role}
                />
              </div>
            </div>
          </section>
        </div>
        <div className={shared.bottomPanel}>
          <section className={shared.section}>
            <div className={shared.sidepanelHeading}>
              <h4 className={shared.sectionHeader}>API access</h4>
            </div> 
            <div className={shared.table}>
              <div className={shared.emptyContent} style={{ marginTop: '60px' }}>
                <h3 className={shared.caption}>This user doesn&apos;t have APIs yet</h3>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <p>Please create user to share API.</p>
                </div>
              </div>
            </div>
          </section>
          <div className={shared.vertical} />
          <section className={shared.section}>
            <div className={shared.sidepanelHeading}>
              <h4 className={shared.sectionHeader}>Connections access</h4>
            </div>
            <div className={shared.table}>
              <div className={shared.emptyContent} style={{ marginTop: '60px' }}>
                <h3 className={shared.caption}>This user doesn&apos;t have Connections yet</h3>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <p>Please create user to share Connections.</p>
                </div>
              </div>
            </div>
          </section>
        </div >
      </div>
    </div>
  );
}

const mapStateToProps = ({ auth: { currentUser }, roles, activeProject }) => ({
  currentUser,
  roles,
  activeProject
});

const mapDispatchToProps = (dispatch) => ({
  sendInvite: (data, callback) => dispatch(sendInviteRequest(data, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UsersCreate);

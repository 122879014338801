@use "sass:map";
@import '../../styles/variables.scss';

.commitDiff {
  margin: 12px 20px;
  position: relative;
  padding: 8px 12px;
  background-color: #FFFFFF;
  border-radius: 4px;

  .table {
    margin: 0;
    border-collapse: separate;
    border-spacing: 12px;
    width: 100%;

    .extendedText td {
      overflow: initial;
      text-overflow: unset;
      overflow-wrap: break-word;
      white-space: unset;
    }

    td, th {
      max-width: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    th {
      font-size: map.get($font-sizes, "caption");
      font-weight: 700;
      font-family: 'Open Sans', sans-serif;
      color: map.get($colors-basic, "textBlack")
    }

    tbody {
      tr {
        td {
          font-size: map.get($font-sizes, "caption");
          font-weight: 400;
          font-family: 'Open Sans', sans-serif;

          &:nth-child(1) {
            color: map.get($colors-basic, "textBlack");
          }

          &:nth-child(2) {
            color: map.get($colors-basic, "textGray");
          }

          &:nth-child(3) {
            color: map.get($colors-green, 100);
          }
        }
      }
    }
  }
}

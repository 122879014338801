import {
  call, put, takeEvery, all, fork, select,
} from 'redux-saga/effects';
import { api } from '../../helpers/api';
import {
  databaseSchemeRequest,
  databaseSchemeSuccess,
} from './actions';
import {
  refreshRequest,
} from '../auth/actions';
import { createToastRequest } from '../toasts/actions';
import { DatabaseScheme } from './constants';
import history from '../../history';

const getAuth = (state) => state.auth?.auth;

export function* fetchDatabaseScheme(ops) {
  const { data, callback } = ops;
  const { connectionId } = data || {};
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(api, '/db-schema', 'POST', { connectionId }, authData.access_token);
      if (response.ok) {
        const parsed = yield response.json();
        yield put(databaseSchemeSuccess(parsed));
        if (callback) callback('SUCCESS', connectionId);
      } else if (response.status === 401) {
        yield put(refreshRequest(databaseSchemeRequest, data, callback));
      } else if (callback) callback('ERROR', connectionId);
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

function* databaseSchemeSaga() {
  yield takeEvery(DatabaseScheme.databaseSchemeRequest, fetchDatabaseScheme);
}

function* configSaga() {
  yield all([fork(databaseSchemeSaga)]);
}

export default configSaga;

import React from 'react';
import PropTypes from 'prop-types';
import Sidepanel from '../Sidepanel/Sidepanel';
import * as styles from './Layout.module.scss';

function Layout({ children }) {
  return (
    <div className={styles.main}>
      <Sidepanel />
      {children}
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.element,
};

export default Layout;

/* eslint-disable max-len */
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import Button from '../../components/Button/Button';
import ActionIcon from '../../components/ActionIcon/ActionIcon';
import ChipsStatus from '../../components/ChipsStatus/ChipsStatus';
import RemoveRoleModal from '../../components/RemoveRoleModal/RemoveRoleModal';
import Input from '../../components/Input/Input';
import { listRolesRequest } from '../../store/roles/actions';
import { shortenText } from '../../helpers/utils';
import { listRolesName } from '../../helpers/const';

import SortIcon from '../../pictures/sort.svg';

import * as shared from '../../styles/shared.module.scss';

const headers = {
  id: { title: 'ID', width: '5%' },
  name: { title: 'Name', width: '70%' },
  type: { title: 'Type', width: '10%' },
  action: { title: 'Actions', width: '11%' }
}

let searchTimeout = null;

function RolesList({ getRoles, roles, activeProject }) {
  const [activeModel, setActiveModel] = useState({});
  const [removeModalOpened, setRemoveModalOpened] = useState(false);
  const [rolesList, setRolesList] = useState([]);
  const [inputVisible, setInputVisible] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const ref = useRef(null);
  const navigate = useNavigate();
  const { shortName } = useParams();

  useEffect(() => {
    activeProject?.id && getRoles({ id: activeProject.id });
  }, [activeProject?.id]);

  useEffect(() => {
    setRolesList(roles);
  }, [roles])

  const createRole = () => navigate(`/${shortName}/roles/create`);

  const modifyRole = (userId) => navigate(`/${shortName}/roles/${userId}`);

  const deleteRole = (model) => {
    setActiveModel(model);
    setRemoveModalOpened(true);
  }

  const handleClickOutside = (event) => {
    if (ref && ref.current && !ref.current.contains(event.target)) {
      const input = ref.current.getElementsByTagName('input')[0];
      if (input.value) return;
      setInputVisible(false);
    }
  };

  const changeSearch = (e) => {
    setSearchValue(e.target.value);
    if (searchTimeout) clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => {
      const newRolesList = roles.filter(r => r?.name?.includes(e.target.value))
      setRolesList(newRolesList)
    }, 400);
  };

  const clear = () => {
    setSearchValue('');
    setRolesList(roles)
    setInputVisible(false);
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return (
    <div className={shared.page}>
      <div className={shared.header}>
        <div className={shared.headerTitleGroup}>
          <h1 className={shared.headerTitle}>Roles</h1>
        </div>
        <div className={shared.headerButtonGroup}>
        {inputVisible ? (
            <div style={{
                flexGrow: 1,
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <div ref={ref} style={{ width: '100%', maxWidth: '426px'}}>
              <Input
                placeholder="Search"
                iconLeft="search"
                autoFocus
                iconRight={searchValue && 'close'}
                handleChange={changeSearch}
                value={searchValue}
                handleAction={clear}
              />
              </div>
            </div>
          ) : (
            <Button type="secondary" iconLeft="search" onClick={() => setInputVisible(true)} />
          )}
          <Button title="Create role" onClick={createRole} />
        </div>
      </div>
      <div className={shared.table}>
        <table className={shared.usersTable}>
          <thead>
            <tr>
              {Object.keys(headers).map((h, i, arr) => (
                <th key={h} width={headers[h].width}>
                  <span>{headers[h].title}</span>
                  {' '}
                  {i < arr.length - 1 && <SortIcon />}
                </th>
              ))}
            </tr>
          </thead>
          {rolesList?.length ? (
            <tbody>
              {rolesList.map((model) => (
                <tr key={model.id} role="button" tabIndex={0} onDoubleClick={() => modifyRole(model.id)}>
                  <td width={headers.id.width}><span title={model.id}>{shortenText(model.id)}</span></td>
                  <td width={headers.name.width}><span>{listRolesName[model.name] || model.name}</span></td>
                  <td width={headers.type.width}>
                    {model.system ? (
                      <ChipsStatus title="System" type="success" />
                    ) : (
                      <ChipsStatus title="Custom" type="error" />
                    )}
                  </td>
                  <td width={headers.action.width}>
                    <div className={shared.controlIcons}>
                      <ActionIcon
                        icon="edit"
                        onClick={() => modifyRole(model.id)}
                      />
                      <ActionIcon
                        icon="trash"
                        onClick={() => deleteRole(model)}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          ) : <></>}
        </table>
        {!rolesList?.length && searchValue && (
          <div className={shared.emptyContent}>
            <h3 className={shared.caption}>No roles found</h3>
            <p>
              Please change your search query and try again
            </p>
          </div>
        )}
        {removeModalOpened && (
          <RemoveRoleModal
            setIsOpen={setRemoveModalOpened}
            roles={roles}
            role={{
              id: activeModel.id,
              name: activeModel.name,
            }}
          />
        )}
      </div>
    </div>
  );
}

const mapStateToProps = ({ roles, auth: { currentRole }, activeProject }) => ({
  roles,
  currentRole,
  activeProject
});

const mapDispatchToProps = (dispatch) => ({
  getRoles: (data) => dispatch(listRolesRequest(data)),
  searchRoles: (data) => dispatch(searchRolesRequest(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RolesList);

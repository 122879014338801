/* eslint-disable max-len */
/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Checkbox from '../../../../components/Checkbox/Checkbox';
import ActionIcon from '../../../../components/ActionIcon/ActionIcon';
import ChipsStatus from '../../../../components/ChipsStatus/ChipsStatus';
import {
  formatTimestamp
} from '../../../../helpers/utils';

import * as shared from '../../../../styles/shared.module.scss';
import { ContextMenu, projectContextConfig, prepareConfig } from '../../../../components/ContextMenu';

function Directory({
  modifyProject,
  activeProject,
  projectsData,
  selectedItems,
  handleContextMenu,
  setContextMenuModelActive,
  contextMenuModelActive,
  contextMenuTop,
  contextMenuLeft,
  openProject,
  selectOne,
  clickShare,
  isRoleAction,
  removeOne,
  depth = 0,
  copyShortname
}) {
  const [sortBy, setSortBy] = useState('name');
  const activeShortname = activeProject ? activeProject.shortName : '';
  // const sortProjects = (pList) => pList?.sort((a, b) => a[sortBy].localeCompare(b[sortBy]));

  const sortProjects = (pList) => pList;

  const blockEvent = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  return (
    <>
      {sortProjects(projectsData).map((model, index) => (
        <React.Fragment key={`authKey_${model.id}`}>
          <tr
            style={{ position: 'relative' }}
            className={cn(
              shared.projectsTableRowKeys,
              { [shared.activated]: activeShortname === model.shortName },
              (selectedItems.includes(model.id) && shared.selected),
            )}
            role="button"
            tabIndex={0}
            onContextMenu={(e) => handleContextMenu(e, model.id, false)}
            onDoubleClick={() => openProject(model.shortName)}
            onClick={() => setContextMenuModelActive(false)}
          >
            {/* <td>
              <Checkbox
                handleChange={(isCheked) => selectOne(isCheked, model.id)}
                value={selectedItems.includes(model.id)}
                size="small"
                style={{ marginRight: '0' }}
              />
            </td> */}
            <td style={{ gap: '8px' }}>
              <span>
                {model.name}
              </span>
              {model.description
                  && (
                  <div className={shared.controlIcons}>
                    <ActionIcon
                      id={`${index}_${model.id}_tooltip`}
                      icon="info"
                      style={{ marginRight: '8px' }}
                      onClick={() => {}}
                      tooltip={{
                        content: model.description,
                        place: 'right',
                      }}
                    />
                  </div>
                  )}
            </td>
            <td>
              <span>{model.shortName}</span>
              <div className={shared.controlIcons}>
                <ActionIcon
                  icon="copy"
                  style={{ marginRight: '8px' }}
                  onClick={() => copyShortname(model.shortName)}
                />
              </div>
            </td>
            <td>
              <span>{model.owner ? `${model.owner.firstName} ${model.owner.lastName}` : ''}</span>
            </td>
            <td style={{ overflow: 'visible' }}><ChipsStatus title="Active" type="success" /></td>
            <td style={{ minWith: '400px' }}>
              <div className={shared.controlIcons} onDoubleClick={blockEvent}>
                <ActionIcon
                  id={`${index}_${model.id}_edit`}
                  icon="edit"
                  onClick={() => modifyProject(model.id)}
                  hidden={!isRoleAction({ key: 'project', role: 'update', modelRole: model.role })}
                  tooltip={{
                    content: 'Edit',
                  }}
                />
                <ActionIcon
                  id={`${index}_${model.id}_addUser`}
                  icon="addUser"
                  onClick={() => clickShare(model)}
                  hidden={!isRoleAction({ key: 'project', role: 'share', modelRole: model.role })}
                  tooltip={{
                    content: 'Share',
                  }}
                />
              </div>
              <div className={shared.controlIconsHideList}>
                <span>{model.createdAt ? formatTimestamp(model.createdAt) : ''}</span>
              </div>
            </td>
          </tr>
          <ContextMenu
            contextConfig={prepareConfig(projectContextConfig, {
              edit: () => modifyProject(model.id),
              share: () => clickShare(model),
            }, 'itemSupport')}
            contextMenuTop={contextMenuTop}
            contextMenuLeft={contextMenuLeft}
            isOpen={contextMenuModelActive === model.id}
          />
        </React.Fragment>
      ))}
    </>
  );
}

Directory.propTypes = {
  selectedItems: PropTypes.array,
  projectsData: PropTypes.array,
};

export default Directory;

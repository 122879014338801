import { Trash } from './constants';

export const getTrashRequest = (data, callback) => ({
  type: Trash.getTrashRequest, data, callback,
});
export const getTrashSuccess = (data) => ({ type: Trash.getTrashSuccess, data });

export const getTrashFolderRequest = (data, callback) => ({
  type: Trash.getTrashFolderRequest, data, callback,
});
export const getTrashFolderSuccess = (data) => ({ type: Trash.getTrashFolderSuccess, data });

export const removeTrashItemsRequest = (data, callback) => ({
  type: Trash.removeTrashItemsRequest, data, callback,
});
export const removeEndpointListSuccess = (data) => ({
  type: Trash.removeTrashItemsSuccess, data,
});

export const removeTrashFolderRequest = (data, callback) => ({
  type: Trash.removeTrashFolderRequest, data, callback,
});
export const removeTrashFolderSuccess = (data) => ({ type: Trash.removeTrashFolderSuccess, data });

export const restoreTrashItemsRequest = (data, callback) => ({
  type: Trash.restoreTrashItemsRequest, data, callback,
});

export const restoreTrashFoldersRequest = (data, callback) => ({
  type: Trash.restoreTrashFoldersRequest, data, callback,
});

export const searchTrashRequest = (data, callback) => ({ type: Trash.searchTrashRequest, data, callback });
export const searchTrashSuccess = (data) => ({ type: Trash.searchTrashSuccess, data });
